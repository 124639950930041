// 9fbef606107a605d69c0edbcd8029e5d

/*
 *
 * getOffers actions
 *
 */

import { createAction } from "redux-actions";
import {
  GET_OFFERS_REQUEST_SENT,
  GET_OFFERS_REQUEST_SUCCEEDED,
  GET_OFFERS_REQUEST_FAILED,
  UPDATE_SHIPPING_DATE,
  RESET_QUOTE_RESULTS,
  SORT_OFFERS,
  LOCAL_SHIPMENT_DETECTED,
} from "./constants";

export const getOffersRequestSent = createAction(GET_OFFERS_REQUEST_SENT);
export const getOffersRequestSucceeded = createAction(
  GET_OFFERS_REQUEST_SUCCEEDED
);
export const getOffersRequestFailed = createAction(GET_OFFERS_REQUEST_FAILED);
export const updateShippingDate = createAction(UPDATE_SHIPPING_DATE);
export const resetQuoteResults = createAction(RESET_QUOTE_RESULTS);
export const sortOffers = createAction(SORT_OFFERS);
export const localShipmentDetected = createAction(LOCAL_SHIPMENT_DETECTED);
