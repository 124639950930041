// 9fbef606107a605d69c0edbcd8029e5d

/*
 *
 * MicroFrontend actions
 *
 */

import { createAction } from "redux-actions";
import { SET_MICRO_FRONTEND_ID } from "./constants";

export const setMicroFrontendId = createAction(SET_MICRO_FRONTEND_ID);
