// 9fbef606107a605d69c0edbcd8029e5d

import reducer from "./reducer";
import container from "./container";
import * as actions from "./actions";
import * as selectors from "./selectors";
import * as constants from "./constants";

export default {
  actions,
  Component: container,
  constants,
  reducer,
  selectors,
};
