// 9fbef606107a605d69c0edbcd8029e5d

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import { RemoteData } from "root/libs/core-libs/src";
import { makeSelectLocale, selectMessages } from "./selectors";

const Component = ({ children, errorPage, locale, remoteMessages }) => {
  const childrenWithIntlProvider = (providerLocale, messages) => (
    <IntlProvider
      key={providerLocale}
      locale={providerLocale}
      messages={messages}
    >
      {React.Children.only(children)}
    </IntlProvider>
  );

  return RemoteData.case(
    {
      success: (messages) => childrenWithIntlProvider(locale, messages),
      error: () => errorPage,
      _: () => null,
    },
    remoteMessages
  );
};

Component.propTypes = {
  children: PropTypes.element.isRequired,
  remoteMessages: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  remoteMessages: selectMessages(state),
  locale: makeSelectLocale()(state),
});

export default connect(mapStateToProps)(Component);
