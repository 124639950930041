// 9fbef606107a605d69c0edbcd8029e5d

// Becauese CusomEvent is not supported in IE
// this is a polyfill to add CustomEvent to window
export default function customEventPolyfill() {
  if (typeof window.CustomEvent === "function") return false;

  function CustomEvent(event, params) {
    const newParam = params || {
      bubbles: false,
      cancelable: false,
      detail: null,
    };
    const evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(
      event,
      newParam.bubbles,
      newParam.cancelable,
      newParam.detail
    );
    return evt;
  }

  window.CustomEvent = CustomEvent;
  return true;
}
