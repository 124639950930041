// 9fbef606107a605d69c0edbcd8029e5d

const defaultZipCodeRules = (
  countriesWithoutZipCode,
  isBusiness,
  countryName
) => ({
  pattern: "[A-Za-z0-9\\s\\-\\/]{1,10}",
  required: isBusiness
    ? typeof countriesWithoutZipCode[countryName] === "undefined"
    : false,
});

const defaultCityRules = (isBusiness, hasPostalCode) => ({
  required: isBusiness && !hasPostalCode,
});

const validationRules = {
  originCountry: {
    type: "string",
    defaultRule: {
      pattern: "^([A-zÀ-ÿ0-9’.-]+\\s)*[\\(\\)A-zÀ-ÿ0-9’.-]+$",
      required: true,
    },
  },

  originZip: {
    type: "string",
    getDefaultRule: defaultZipCodeRules,
  },

  originCity: {
    type: "string",
    getDefaultRule: defaultCityRules,
  },

  destinationCountry: {
    type: "string",
    defaultRule: {
      pattern: "^([A-zÀ-ÿ0-9’.-]+\\s)*[\\(\\)A-zÀ-ÿ0-9’.-]+$",
      required: true,
    },
  },

  destinationZip: {
    type: "string",
    getDefaultRule: defaultZipCodeRules,
  },

  destinationCity: {
    type: "string",
    getDefaultRule: defaultCityRules,
  },
};

export default validationRules;
