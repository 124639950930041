// 9fbef606107a605d69c0edbcd8029e5d

import styled, { css } from "styled-components";
import { Button } from "@dhl-official/react-ui-library";
import { breakpoints, sizes, grid } from "root/libs/ui-styleguide/src";
import { Headline } from "root/libs/ui-components/src";

export const ieWeightContent = css`
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: inline-block;
    width: 19%;
  }

  @supports (display: grid) {
    @media screen and (min-width: ${breakpoints.tablet}) {
      display: flex;
      width: 100%;
    }
  }
`;

export const WeightContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 20px 0 0;
  margin-left: ${grid.columns.width.s};
  margin-right: ${grid.columns.width.s};
  ${ieWeightContent}

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-bottom: 0;
    padding-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  @supports (display: grid) {
    grid-row: weightRow;
    grid-column: 1 / span 12;

    @media screen and (min-width: ${breakpoints.tablet}) {
      grid-area: weightCol;
      padding-right: 52px;
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      grid-row: dimensionsRow;
      grid-area: weightCol;
      padding-right: 0;
    }
  }

  @media print {
    width: 30%;
    padding: 0px;
  }
`;

export const StyledHeadline = styled(Headline)`
  margin-bottom: 12px;
`;

export const WeightInputWrapper = styled.div`
  max-width: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    max-width: 200px;
  }
`;

export const StyledFeedbackMessageButton = styled(Button)`
  margin-top: ${sizes.inputSidePaddings};
  font-size: 1.3rem;
  text-decoration: underline;
  padding: 0;
  text-align: left;
  font-weight: 400;
`;
