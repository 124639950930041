// 9fbef606107a605d69c0edbcd8029e5d

import styled from "styled-components";
import { key } from "styled-theme";
import Link from "../Link";

export const StyledLink = styled(Link)`
  display: flex;
  justify-content: ${(props) => props.linkTextAlignment};
  align-items: center;
  font-weight: 700;
  ${(props) => props.extraStyles}

  .icon {
    /* Force to center to the text */
    height: 16px;
  }

  &:focus {
    background-color: ${key("colors.fontColor")};
    color: ${key("colors.fontColorInverded")};
    padding: 4px 3px 1px;
    text-decoration: underline;

    svg {
      fill: ${key("colors.fontColorInverded")};
    }
  }
`;

export const StyledText = styled.span`
  padding-right: 8px;
`;
