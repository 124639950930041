// 9fbef606107a605d69c0edbcd8029e5d

import { connect } from "react-redux";
import TradeLaneComponent from "one-time-shipment/src/components/Location/TradeLaneComponent";
import { selectTrackingKey } from "one-time-shipment/src/containers/Tracking/selectors";
import { FormField } from "root/libs/core-libs/src";
import { Tracking, Localization, Utf } from "root/libs/ui-containers/src";
import * as shipmentLaneActions from "one-time-shipment/src/containers/ShipmentLane/actions";
import * as actions from "./actions";
import * as selectors from "./selectors";

const {
  selectors: { selectPageName },
} = Utf;

const {
  selectors: { selectLocalization },
} = Localization;

const {
  actions: { trackEvent },
} = Tracking;

const mapStateToProps = (state, ownProps) => {
  const isOriginDestinationFlipped = selectors.selectIsOriginDestinationFlipped(
    state
  );

  const destinationFields = isOriginDestinationFlipped
    ? selectors.selectOriginFields(state)
    : selectors.selectDestinationFields(state);

  const isDestinationCityValid = isOriginDestinationFlipped
    ? selectors.selectIsOriginCityValid(state)
    : selectors.selectIsDestinationCityValid(state);

  const isDestinationPostalCodeValid = isOriginDestinationFlipped
    ? selectors.selectIsOriginPostalCodeValid(state)
    : selectors.selectIsDestinationPostalCodeValid(state);

  const originFields = isOriginDestinationFlipped
    ? selectors.selectDestinationFields(state)
    : selectors.selectOriginFields(state);

  const isOriginCityValid = isOriginDestinationFlipped
    ? selectors.selectIsDestinationCityValid(state)
    : selectors.selectIsOriginCityValid(state);

  const isOriginPostalCodeValid = isOriginDestinationFlipped
    ? selectors.selectIsDestinationPostalCodeValid(state)
    : selectors.selectIsOriginPostalCodeValid(state);

  const abTestRunning = selectors.abTestRunning(state);

  const rtlEnabled = selectors.rtlEnabled(state);

  const canaryEnabled = selectors.canaryEnabled(state);

  const canaryVersion = selectors.canaryVersion(state);

  const countriesWithoutPostalCode = selectors.selectCountriesWithoutPostalCode(
    state
  );

  return {
    canaryEnabled,
    canaryVersion,
    abTestRunning,
    rtlEnabled,
    buttonDataTrackingKeyPath: selectTrackingKey(
      "spotShipment.interactions.continueToShipmentClicked"
    ),
    country: selectLocalization(state).country,
    destinationAddressType: FormField.getValue(
      selectors.selectDestinationAddressType(state)
    ),
    destinationFields,
    isDestinationCityValid,
    isDestinationPostalCodeValid,
    isFormValid: selectors.selectIsFormValid(state, ownProps.isBusiness),
    isLocationApiAvailable: selectors.selectIsLocationApiAvailable(state),
    isOriginCityValid,
    isOriginDestinationFlipped,
    isOriginPostalCodeValid,
    originFields,
    wrongCountryLinkPath: selectTrackingKey(
      "spotShipment.interactions.wrongCountry"
    ),
    isDomesticShipmentException: selectors.selectIsDomesticShipmentException(
      state
    ),
    countriesWithoutPostalCode,
    pageName: selectPageName(state),
  };
};

const mapDispatchToProps = {
  flipOriginDestination: actions.flipOriginDestination,
  queryCountry: actions.queryCountry,
  selectCity: actions.selectCity,
  selectCountry: actions.selectCountry,
  selectPostalCode: actions.selectPostalCode,
  showShipmentLane: shipmentLaneActions.showShipmentLane,
  hideShipmentLane: shipmentLaneActions.hideShipmentLane,
  trackEvent,
  updateCity: actions.updateCity,
  updateDestinationAddressType: actions.updateDestinationAddressType,
  updatePostalCode: actions.updatePostalCode,
  validateCountryField: actions.validateCountryField,
  validateFormField: actions.validateFormField,
  validateLocation: actions.checkLocationValidation,
  toggleDomesticShipmentException: actions.toggleDomesticShipmentException,
};

export default connect(mapStateToProps, mapDispatchToProps)(TradeLaneComponent);
