// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * OfferPriceAndPaymentInfo
 *
 */

import React from "react";
import {
  businessUnits,
  P2PLinkTypes,
  showCurrencyAsCode,
} from "root/libs/constants/src";
import {
  IntlPropType,
  GetCountryCode,
  trackConversionInteraction,
} from "root/libs/core-libs/src";
import PropTypes from "prop-types";
import { Button, Text, TEXT_SIZES } from "@dhl-official/react-ui-library";
import { Link } from "root/libs/ui-components/src";
import { CanaryVersions } from "root/libs/ui-containers/src";
import { selectTrackingKey } from "one-time-shipment/src/containers/Tracking/selectors";
import OfferPaymentIcons from "one-time-shipment/src/components/Offers/OfferPaymentIcons";
import ResponsiveRender from "one-time-shipment/src/components/Layout/ResponsiveRender";
import {
  CardButtonWrapper,
  CardPriceWrapper,
  CardPriceHeadline,
  DisclaimerStar,
  StyledVatInfoMessage,
  CardPriceDiscount,
} from "./styled";

const COPY_ID_PREFIX = "OfferPriceAndPaymentInfo";
const CARD_AND_SUMMARY_COPY_ID_PREFIX = "CardAndSummary";

const {
  constants: { TYPES_OF_CANARY_VERSIONS },
} = CanaryVersions;

export default class OfferPriceAndPaymentInfo extends React.PureComponent {
  static propTypes = {
    /** An object that contains all the information required of an offer */
    offer: PropTypes.shape({
      businessUnit: PropTypes.string,
      /** id of the offer */
      key: PropTypes.string,
      id: PropTypes.string,
      /** An array that contains the accepted payment methods */
      acceptedPaymentMethods: PropTypes.arrayOf(PropTypes.string),
      /** Link for Book now button */
      pathToPurchaseLink: PropTypes.string,
      /** Price of the offer */
      price: PropTypes.shape({
        /** A number that contains the price
         */
        amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        /** A valid currency code */
        currencyCode: PropTypes.string,
        tax: PropTypes.string,
      }),
      priceWithoutDiscount: PropTypes.shape({
        /** A number that contains the price
         */
        amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        /** A valid currency code */
        currencyCode: PropTypes.string,
        tax: PropTypes.string,
      }),
      /** If set will trigger the display of a special scenario card in DGF */
      specialOffers: PropTypes.string,
      /** Contains the type of the CTA button */
      pathToPurchaseLinkType: PropTypes.oneOf(Object.values(P2PLinkTypes)),
    }).isRequired,
    /** Internationalization library */
    intl: IntlPropType.isRequired,
    /** function to add values to the digitalLayer for tracking purposes.
     * Receives an object, the key is the property that will recieve the data in the digitalLayer
     * and the value is the data that it needs */
    trackEvent: PropTypes.func,
    /** Function to hand over for controlling the display of the Mobile Express PTP modal */
    toggleModalExpress: PropTypes.func,
    /** Function to hand over for controlling the display of the Booking not allowed PTP modal */
    toggleBookingNotAllowedModal: PropTypes.func,
    /** Function to hand over for controlling the display of the Freight P2P modal */
    toggleFreightP2PModal: PropTypes.func,
    isBusiness: PropTypes.bool,
    showCurrencyIsoCodeForPrices: PropTypes.bool,
    nativeLanguageCode: PropTypes.string,
    formatCurrencyWithNativeLocale: PropTypes.bool,
    utfDataObject: PropTypes.object,
    isExpressAllowedOnMobile: PropTypes.bool,
    canaryEnabled: PropTypes.bool,
    canaryVersion: PropTypes.string,
  };

  static defaultProps = {
    trackEvent: () => {},
    toggleModalExpress: () => {},
    toggleFreightP2PModal: () => {},
    toggleBookingNotAllowedModal: () => {},
    isBusiness: false,
    showCurrencyIsoCodeForPrices: false,
    nativeLanguageCode: "",
    formatCurrencyWithNativeLocale: false,
    utfDataObject: {},
    isExpressAllowedOnMobile: false,
  };

  constructor(props) {
    super(props);
    const trackingKeySelector = selectTrackingKey;
    this.bookNowOfferClickedTrackingKey = trackingKeySelector(
      "spotShipment.interactions.offer.bookNowOfferClicked"
    );
  }

  onClick = () => {
    const { trackEvent, offer, utfDataObject } = this.props;

    trackConversionInteraction(utfDataObject);
    trackEvent({ bookNowOfferClicked: offer.key });
  };

  onKeyDown = (event) => {
    /* Since it is being rendered in accordion so we need to added this event
    becasue accordion has onKeyDown function */
    event.stopPropagation();
  };

  clickForModal = (event) => {
    const {
      trackEvent,
      offer,
      toggleFreightP2PModal,
      toggleBookingNotAllowedModal,
      utfDataObject,
    } = this.props;

    event.stopPropagation();

    switch (offer.pathToPurchaseLinkType) {
      case P2PLinkTypes.MODAL_FREIGHT:
        toggleFreightP2PModal(offer);
        break;
      case P2PLinkTypes.MODAL_EXPRESS:
        toggleBookingNotAllowedModal(offer);
        break;
      default:
        break;
    }
    trackConversionInteraction(utfDataObject);
    trackEvent({ bookNowOfferClicked: offer.key });
  };

  onExpressClick = (event) => {
    const { trackEvent, offer, toggleModalExpress, utfDataObject } = this.props;

    toggleModalExpress();

    trackConversionInteraction(utfDataObject);
    trackEvent({ bookNowOfferClicked: offer.key });

    event.stopPropagation();
  };

  isOfferExpress = () => {
    const {
      offer: { key },
    } = this.props;

    let servicePart = key.split("_");
    servicePart = servicePart[0].toUpperCase();
    return servicePart === businessUnits.EXPRESS;
  };

  getButtonText = () => {
    const { offer } = this.props;

    if (
      offer.specialOffers &&
      this.nullChecker(`${offer.specialOffers}_Button`)
    ) {
      return this.getCopy(
        `${offer.specialOffers}_Button`,
        undefined,
        CARD_AND_SUMMARY_COPY_ID_PREFIX
      );
    }

    return this.getCopy(
      "bookNowButton",
      undefined,
      CARD_AND_SUMMARY_COPY_ID_PREFIX
    );
  };

  getButtonLink = () => {
    const { offer } = this.props;

    const { pathToPurchaseLink } = offer;

    if (
      !pathToPurchaseLink &&
      offer.specialOffers &&
      this.nullChecker(`${offer.specialOffers}_Link`)
    ) {
      return this.getCopy(
        `${offer.specialOffers}_Link`,
        undefined,
        CARD_AND_SUMMARY_COPY_ID_PREFIX
      );
    }

    return pathToPurchaseLink;
  };

  getOfferPrice = () => {
    const { offer } = this.props;

    if (!("price" in offer)) {
      return null;
    }

    if (typeof offer.price.amount !== "undefined" && offer.price.amount > 0) {
      return offer.price.amount;
    }
    return offer.price.amount;
  };

  getOfferCurrency = () => {
    const { offer } = this.props;

    if (!("price" in offer)) {
      return null;
    }

    if (typeof offer.price.currencyCode !== "undefined") {
      return offer.price.currencyCode;
    }
    return null;
  };

  getPriceLabel = () => {
    const { offer, isBusiness } = this.props;

    if (!isBusiness && "price" in offer) {
      switch (offer.price.tax) {
        case "WITH":
          return this.getCopy("priceWithVat");
        case "OPTIONAL":
          return this.getCopy("priceOptionalVat");
        default:
          return this.getCopy("priceWithoutVat");
      }
    }

    return this.getCopy("priceFrom");
  };

  nullChecker = (id) => {
    const { intl } = this.props;
    return !!intl.messages[`${CARD_AND_SUMMARY_COPY_ID_PREFIX}.${id}`];
  };

  getCopy = (id, values, prefix = COPY_ID_PREFIX) => {
    const { intl } = this.props;
    return intl.formatMessage({ id: `${prefix}.${id}` }, values);
  };

  render() {
    const {
      intl,
      offer,
      isBusiness,
      showCurrencyIsoCodeForPrices,
      nativeLanguageCode,
      formatCurrencyWithNativeLocale,
      isExpressAllowedOnMobile,
      canaryVersion,
      canaryEnabled,
    } = this.props;

    const buttonText = this.getButtonText();
    const buttonLink = this.getButtonLink();
    const offerPrice = this.getOfferPrice();
    const priceLabel = this.getPriceLabel();
    const currencyCode = this.getOfferCurrency();
    const isModalCTA =
      offer.pathToPurchaseLinkType === P2PLinkTypes.MODAL_FREIGHT ||
      offer.pathToPurchaseLinkType === P2PLinkTypes.MODAL_EXPRESS;
    const buttonTestId = "offer-cta-button";
    const countryCode = GetCountryCode(intl.locale);
    const priceFormatLocale = formatCurrencyWithNativeLocale
      ? `${nativeLanguageCode}-${countryCode}`
      : intl.locale;
    const isCurrencyCodeAvailable =
      currencyCode !== "" && currencyCode !== null;

    const priceWithoutDiscount = offer?.priceWithoutDiscount?.amount;

    return (
      <React.Fragment>
        {!(offerPrice === null || typeof offerPrice === "undefined") && (
          <React.Fragment>
            {isBusiness && <StyledVatInfoMessage offerKey={offer.key} />}
            <span
              id={`${offer.key}-starBehindPrice`}
              className="visually-hidden"
            >
              {". "}
              {this.getCopy("screenReaderLabel_starBehindPrice")}
            </span>
            {canaryEnabled && (
              <CanaryVersions.Component
                version={canaryVersion}
                componentToRender={() =>
                  priceWithoutDiscount && (
                    <CardPriceDiscount
                      dataTestid="offer-price-discount"
                      data-testvalue={priceWithoutDiscount}
                      priority={6}
                      weight="700"
                    >
                      {showCurrencyIsoCodeForPrices &&
                        isCurrencyCodeAvailable &&
                        `${currencyCode} ${intl.formatNumber(
                          priceWithoutDiscount,
                          {
                            minimumFractionDigits: 2,
                          }
                        )}`}
                      {!showCurrencyIsoCodeForPrices &&
                        isCurrencyCodeAvailable &&
                        new Intl.NumberFormat(priceFormatLocale, {
                          style: "currency",
                          currency: currencyCode,
                          currencyDisplay: showCurrencyAsCode.includes(
                            currencyCode
                          )
                            ? "code"
                            : "symbol",
                        }).format(priceWithoutDiscount)}
                      {currencyCode === "" &&
                        intl.formatNumber(priceWithoutDiscount, {
                          minimumFractionDigits: 2,
                        })}
                    </CardPriceDiscount>
                  )
                }
                versionsToRender={TYPES_OF_CANARY_VERSIONS.strikethrough}
                fallback={() => {}}
              />
            )}
            <CardPriceWrapper id={`${offer.key}-price`}>
              <Text className="price-label" size={TEXT_SIZES.TINY} isParagraph>
                {priceLabel}
              </Text>
              <span className="visually-hidden">,</span>
              <CardPriceHeadline
                dataTestid="offer-price"
                data-testvalue={offerPrice}
                priority={4}
                weight="700"
                bottomMargin="7px"
              >
                {showCurrencyIsoCodeForPrices &&
                  isCurrencyCodeAvailable &&
                  `${currencyCode} ${intl.formatNumber(offerPrice, {
                    minimumFractionDigits: 2,
                  })}`}
                {!showCurrencyIsoCodeForPrices &&
                  isCurrencyCodeAvailable &&
                  new Intl.NumberFormat(priceFormatLocale, {
                    style: "currency",
                    currency: currencyCode,
                    currencyDisplay: showCurrencyAsCode.includes(currencyCode)
                      ? "code"
                      : "symbol",
                  }).format(offerPrice)}
                {currencyCode === "" &&
                  intl.formatNumber(offerPrice, {
                    minimumFractionDigits: 2,
                  })}
                <DisclaimerStar aria-hidden="true">&nbsp;*</DisclaimerStar>
              </CardPriceHeadline>
            </CardPriceWrapper>
          </React.Fragment>
        )}

        {isModalCTA ? (
          <CardButtonWrapper>
            <Button
              name="modal-button"
              type="button"
              onClick={this.clickForModal}
              onKeyDown={this.onKeyDown}
              dataTracking={this.bookNowOfferClickedTrackingKey}
              dataTestid={buttonTestId}
              ariaDescribedBy={`${offer.key}-price ${offer.key}-businessVat`}
            >
              {intl.formatMessage({
                id: `${CARD_AND_SUMMARY_COPY_ID_PREFIX}.bookNowButton`,
              })}
            </Button>
          </CardButtonWrapper>
        ) : (
          <ResponsiveRender
            render={(isMobile) =>
              isMobile && this.isOfferExpress() && !isExpressAllowedOnMobile ? (
                <CardButtonWrapper>
                  <Button
                    name="no-express-on-mobile"
                    type="button"
                    onClick={this.onExpressClick}
                    dataTracking={this.bookNowOfferClickedTrackingKey}
                    dataTestid={buttonTestId}
                  >
                    {intl.formatMessage({
                      id: `${CARD_AND_SUMMARY_COPY_ID_PREFIX}.bookNowButton`,
                    })}
                  </Button>
                </CardButtonWrapper>
              ) : (
                <CardButtonWrapper>
                  <Link
                    href={buttonLink}
                    linkType="cta primary"
                    target="_blank"
                    disableMinWidth
                    dataTracking={this.bookNowOfferClickedTrackingKey}
                    onClick={this.onClick}
                    dataTestid={buttonTestId}
                    ariaDescribedBy={`${offer.key}-price ${offer.key}-businessVat`}
                  >
                    {buttonText}
                  </Link>
                </CardButtonWrapper>
              )
            }
          />
        )}
        <OfferPaymentIcons intl={intl} offer={offer} />
      </React.Fragment>
    );
  }
}
