// 9fbef606107a605d69c0edbcd8029e5d

/**
 * Source of the patterns: http://html5pattern.com/
 */

export default {
  alphaNumeric: "[a-zA-Z0-9]+",
  numberWithAndWithoutDecimal: "^-?[0-9]{1,}?[.,]?[0-9]+",
  numberTwoDecimals: "^-?[0-9]{1,}?((,|\\.)+[0-9]{1,2})?",
  numberNoDecimals: "^-?[0-9]{1,}?",
  twoChars: "[A-Za-z]{2}",
  zip: "[A-Za-z0-9]{1,5}",
  email: "[^@]+@[^@]+\\.[a-zA-Z]{2,6}",
};
