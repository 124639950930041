// 9fbef606107a605d69c0edbcd8029e5d

import { put, fork, takeLatest, all, call, select } from "redux-saga/effects";
import http from "one-time-shipment/src/lib/http";
import { Localization } from "root/libs/ui-containers/src";
import {
  toggleModalSuccess as toggleModalSuccessAction,
  toggleModalError as toggleModalErrorAction,
  getFreightP2PModalRequestCompleted as getFreightP2PModalRequestCompletedAction,
  toggleFreightP2PModal as toggleFreightP2PModalAction,
} from "./actions";
import { selectFreightP2PModalData } from "./selectors";

import { GET_FREIGHT_P2P_MODAL_REQUEST_SENT } from "./constants";

import { selectFunnel } from "../Personalization/selectors";

import { selectTradeLaneInitialSet } from "../TradeLane/selectors";

import { makeShipmentLaneInitialSetSelection } from "../ShipmentLane/selectors";

import { selectSelectedSegment } from "../SpotShipmentPage/selectors";

const {
  selectors: { selectCountry, selectLanguage },
} = Localization;

const selectOfferData = (quoteState, offerId) =>
  quoteState.offers.find((offer) => offer.quotationId === offerId);

export function* getFreightP2PModalRequestSent() {
  try {
    const freightP2PModalData = yield select(selectFreightP2PModalData);
    const tradeLaneData = yield select(selectTradeLaneInitialSet);
    const selectedSegment = yield select(selectSelectedSegment);
    const shipmentLaneData = yield select(
      makeShipmentLaneInitialSetSelection(selectedSegment)()
    );
    const selectedOfferId = yield select(
      (state) => state.offersLane.selectedFreightOfferId
    );
    const quoteStateObj = yield select((state) => state.getQuote);
    const selectedOfferData = selectOfferData(quoteStateObj, selectedOfferId);
    /** Collect meta information like current site url and current UI country and language */
    const language = yield select(selectLanguage);
    const marketCountry = yield select(selectCountry);
    const sourceUrl = window && window.location ? window.location.href : "";
    const funnel = yield select(selectFunnel);
    const metaData = {
      meta: {
        language,
        marketCountry,
        sourceUrl,
        funnel,
      },
    };
    const {
      id,
      quotationId,
      internalProductCode,
      freightModal,
    } = selectedOfferData;

    const freightProductInfo = {
      freightProductInformation: {
        countryFrom: tradeLaneData.originCountry,
        zipCodeFrom: tradeLaneData.originZip,
        countryTo: tradeLaneData.destinationCountry,
        zipCodeTo: tradeLaneData.destinationZip,
        isDangerousGoods: shipmentLaneData.settingsDangerousGoods || false,
        quotationId,
        productCode: internalProductCode,
        pickupDate: freightModal.pickUpDate,
        estimatedDeliveryDate: freightModal.deliveryDate,
        currency: freightModal.currencyCode,
        price: freightModal.amount,
        offerId: id,
      },
      shipmentItems: [...shipmentLaneData.items],
    };

    const formData = {
      ...metaData,
      freightP2PModal: freightP2PModalData,
      ...freightProductInfo,
    };

    const response = yield call(http.post, {
      endpoint: "freightP2PModal",
      options: {
        payload: formData,
        adapter: "quote",
      },
    });
    const responseData = response.data;
    const { success } = responseData;

    yield put(getFreightP2PModalRequestCompletedAction(!!success));

    if (success) {
      yield put(toggleFreightP2PModalAction());
      yield put(toggleModalSuccessAction());
    } else {
      yield put(toggleModalErrorAction());
    }
  } catch (error) {
    yield put(getFreightP2PModalRequestCompletedAction(false));
    yield put(toggleModalErrorAction());
  }
}

export function* watchFetch() {
  yield takeLatest(
    GET_FREIGHT_P2P_MODAL_REQUEST_SENT,
    getFreightP2PModalRequestSent
  );
}

export default function* sagas() {
  yield all([fork(watchFetch)]);
}
