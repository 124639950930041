// 9fbef606107a605d69c0edbcd8029e5d

import { all, call, fork } from "redux-saga/effects";
import {
  CountrySettings,
  Localization,
  Tracking,
} from "root/libs/ui-containers/src";
import http from "../lib/http";
import getQuote from "../containers/GetQuote/sagas";
import tradeLane from "../containers/TradeLane/sagas";
import personalization from "../containers/Personalization/sagas";
import spotShipment from "../containers/SpotShipmentPage/sagas";
import offersLane from "../containers/OffersLane/sagas";
import trackingContract from "../containers/Tracking/tracking-contract";
import { appShortName } from "../config/bootstrapConfig";

const SETTINGS_CACHE_NAMESPACE = `country-settings-${appShortName}`;
const MESSAGES_CACHE_NAMESPACE = `messages-${appShortName}`;
const POSTAL_LOCATION_CACHE_NAMESPACE = `countries-${appShortName}`;

export default function* sagas() {
  try {
    yield all([
      fork(getQuote),
      fork(
        tradeLane(
          http.getPostalLocationCountries,
          POSTAL_LOCATION_CACHE_NAMESPACE
        )
      ),
      fork(Tracking.createSagas(trackingContract)),
      fork(personalization),
      fork(spotShipment),
      fork(offersLane),
      fork(
        Localization.createSagas(
          http.setupTranslations(),
          MESSAGES_CACHE_NAMESPACE
        )
      ),
      fork(
        CountrySettings.createSagas(
          http.getCountrySettings,
          SETTINGS_CACHE_NAMESPACE
        )
      ),
    ]);
  } catch (error) {
    yield call(
      console.log, // eslint-disable-line
      error
    );
  }
}
