// 9fbef606107a605d69c0edbcd8029e5d

import { createSelector } from "reselect";

const selectAbTestRunning = (state) => state.abTestExperiment.abTestRunning;

const abTestExperimentSelector = (state) => state.abTestExperiment;

const selectAbTestVersion = createSelector(
  abTestExperimentSelector,
  (abTestExperiment) => abTestExperiment.showThisVersion
);

export { selectAbTestRunning, abTestExperimentSelector, selectAbTestVersion };
