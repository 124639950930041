// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * OfferDeliveryInfo
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { IntlPropType } from "root/libs/core-libs/src";
import { CanaryVersions } from "root/libs/ui-containers/src";
import isEmpty from "lodash/isEmpty";
import { businessUnits } from "root/libs/constants/src";
import { Icon } from "root/libs/ui-components/src";
import SpecialOfferInfo from "one-time-shipment/src/components/Offers/SpecialOfferInfo";
import GfOceanIcon from "iconsTwoColors/cx/DHL_OceanFreight.svg";
import GfAirIcon from "iconsTwoColors/cx/DHL_AirFreight.svg";
import GfOceanAirIcon from "iconsTwoColors/cx/DHL_OceanAirFreight.svg";
import freightIcon from "iconsTwoColors/cx/DHL_RoadFreight.svg";
import expressIcon from "iconsTwoColors/cx/DHL_Express.svg";
import PnPOffersIcon from "iconsTwoColors/cx/DHL_PnPOffers.svg";
import parcelIcon from "iconsTwoColors/cx/DHL_Parcel_reduced.svg";
import infoIcon from "iconsTwoColors/cx/info_filled.svg";
import EstimatedDeliveryInfo from "one-time-shipment/src/components/Offers/EstimatedDeliveryInfo";
import OfferPromotedLine from "one-time-shipment/src/components/Offers/OfferPromotedLine";
import { TEXT_SIZES } from "@dhl-official/react-ui-library";
import { TYPES_OF_OFFERS } from "one-time-shipment/src/containers/GetQuote/constants";
import {
  DeliveryInfoWrapper,
  ContentWrapper,
  StyledAdditionalInfo,
  StyledMappedLocation,
  InformationTextWrapper,
  BusinessUnitName,
} from "./styled";

const COPY_ID_PREFIX = "OfferDeliveryInfo";

const {
  constants: { TYPES_OF_CANARY_VERSIONS },
} = CanaryVersions;

const OfferDeliveryInfo = ({
  offer,
  intl,
  is12HourTimeFormat,
  nativeDateTimeFormat,
  nativeLanguageCode,
  offerType,
  canaryEnabled,
  canaryVersion,
}) => {
  const getCopy = (id, replaceStrObj) => {
    return intl.formatMessage({ id }, { ...replaceStrObj });
  };

  const getPromotedProperty = () => {
    if (Array.isArray(offer.properties)) {
      return offer.properties
        .filter((property) => property.promoted === true)
        .pop();
    }
    return undefined;
  };

  const isMappedLocation = () =>
    !!(
      offer.locationMappingInformation &&
      (offer.locationMappingInformation.originLocation.changed ||
        offer.locationMappingInformation.destinationLocation.changed)
    );

  function getMappedLocation() {
    let key;
    let strObj;

    if (
      offer.locationMappingInformation.originLocation.changed &&
      offer.locationMappingInformation.destinationLocation.changed
    ) {
      key = "closestAlternativeOriginAndDestination";
      strObj = {
        originLocation:
          offer.locationMappingInformation.originLocation.location,
        destinationLocation:
          offer.locationMappingInformation.destinationLocation.location,
      };
    } else if (
      offer.locationMappingInformation.originLocation.changed &&
      !offer.locationMappingInformation.destinationLocation.changed
    ) {
      key = "closestAlternativeOrigin";
      strObj = {
        location: offer.locationMappingInformation.originLocation.location,
      };
    } else if (
      !offer.locationMappingInformation.originLocation.changed &&
      offer.locationMappingInformation.destinationLocation.changed
    ) {
      key = "closestAlternativeDestination";
      strObj = {
        location: offer.locationMappingInformation.destinationLocation.location,
      };
    }
    strObj = {
      ...strObj,
      b: (chunks) => <strong>{chunks}</strong>,
    };
    return getCopy(`OfferCardHorizontal.${key}`, strObj);
  }

  const getOfferTypeIcon = (key, businessUnit) => {
    // Fallback when businessUnit is not provided.
    if (
      typeof businessUnit === "undefined" &&
      key.includes(businessUnits.EXPRESS)
    ) {
      return expressIcon;
    }

    switch (businessUnit) {
      case businessUnits.DGF:
        if (key.includes("OFR")) {
          return GfOceanIcon;
        }
        if (key.includes("AFR")) {
          return GfAirIcon;
        }
        return GfOceanAirIcon;
      case businessUnits.FREIGHT:
        return freightIcon;
      case businessUnits.EXPRESS:
        return expressIcon;
      case businessUnits.PNP:
        return PnPOffersIcon;
      default:
        return parcelIcon;
    }
  };

  const getBusinessUnitCopy = (key, businessUnit) => {
    const businessUnitMap = {
      [businessUnits.DGF]: "QuoteValidity.DGF",
      [businessUnits.FREIGHT]: "QuoteValidity.FRE",
      [businessUnits.ECS]: "QuoteValidity.ECS",
      [businessUnits.EXPRESS]: "QuoteValidity.EXP",
    };

    // Determine if the fallback should be used
    if (
      typeof businessUnit === "undefined" &&
      key.includes(businessUnits.EXPRESS)
    ) {
      return "QuoteValidity.EXP";
    }

    return businessUnitMap[businessUnit] || "QuoteValidity.EXP";
  };

  const isSpecialOffer = () =>
    !!(
      typeof offer.specialOffers !== "undefined" &&
      offer.specialOffers.length > 0
    );

  const {
    estimatedDeliveryTime,
    estimatedDeliveryTimeDisplayType,
    cutOffDateTime,
    features,
  } = offer;

  const promotedProperty = getPromotedProperty();
  const offerTypeIcon = getOfferTypeIcon(offer.key, offer.businessUnit);
  const businessUnitName = getBusinessUnitCopy(offer.key, offer.businessUnit);

  return (
    <DeliveryInfoWrapper
      className={`${
        offerType === TYPES_OF_OFFERS.AGGREGATED ? "wcc-aggregated-card" : ""
      }`}
    >
      <Icon
        icon={offerTypeIcon}
        width={{
          mobile: "72px",
          desktop: "72px",
        }}
        height={{
          mobile: "52px",
          desktop: "52px",
        }}
        useAs="decorative"
      />
      <ContentWrapper>
        {isSpecialOffer() ? (
          <SpecialOfferInfo offer={offer} />
        ) : (
          <React.Fragment>
            {
              <EstimatedDeliveryInfo
                intl={intl}
                offerKey={offer.key}
                estimatedDeliveryTime={estimatedDeliveryTime}
                estimatedDeliveryTimeDisplayType={
                  estimatedDeliveryTimeDisplayType
                }
                deliveryType={offer.deliveryType}
                is12HourTimeFormat={is12HourTimeFormat}
                features={features}
                nativeDateTimeFormat={nativeDateTimeFormat}
                nativeLanguageCode={nativeLanguageCode}
              />
            }
            {!isEmpty(promotedProperty) && (
              <OfferPromotedLine
                showInViewport="desktop"
                offerKey={offer.key}
                cutOffDateTime={cutOffDateTime}
                intl={intl}
                promotedProperty={promotedProperty}
                pickUpDate={offer.pickUpDate}
                is12HourTimeFormat={is12HourTimeFormat}
                nativeDateTimeFormat={nativeDateTimeFormat}
                nativeLanguageCode={nativeLanguageCode}
              />
            )}
            {!isEmpty(promotedProperty) && (
              <OfferPromotedLine
                showInViewport="mobile"
                offerKey={offer.key}
                cutOffDateTime={cutOffDateTime}
                intl={intl}
                promotedProperty={promotedProperty}
                pickUpDate={offer.pickUpDate}
                is12HourTimeFormat={is12HourTimeFormat}
                nativeDateTimeFormat={nativeDateTimeFormat}
                nativeLanguageCode={nativeLanguageCode}
              />
            )}
            {canaryEnabled && (
              <CanaryVersions.Component
                version={canaryVersion}
                componentToRender={() => (
                  <BusinessUnitName>
                    {intl.formatMessage(
                      { id: "QuoteValidity.operatedBy" },
                      {
                        businessUnit: intl.formatMessage({
                          id: businessUnitName,
                        }),
                      }
                    )}
                  </BusinessUnitName>
                )}
                versionsToRender={TYPES_OF_CANARY_VERSIONS.strikethrough}
                fallback={() => {}}
              />
            )}
          </React.Fragment>
        )}
        {offer.information &&
          offer.information.find((element) => element.category === "INFO") &&
          offer.information.map((item) => (
            <InformationTextWrapper key={item.header}>
              {item.header === "PALLET_REQUIRED_INFORMATION" && (
                <Icon useAs="decorative" size="small" icon={infoIcon} />
              )}
              <StyledAdditionalInfo
                size={TEXT_SIZES.SMALL}
                id={`${offer.key}-additional-info`}
                isParagraph
              >
                {intl.formatMessage(
                  {
                    id: `${COPY_ID_PREFIX}.${item.header}_additional_info`,
                  },
                  {
                    br: <br key="br" />,
                  }
                )}
              </StyledAdditionalInfo>
            </InformationTextWrapper>
          ))}
        {isMappedLocation() && (
          <InformationTextWrapper>
            <Icon useAs="decorative" size="small" icon={infoIcon} />
            <StyledMappedLocation
              size={TEXT_SIZES.SMALL}
              id="info-alternative-location"
              data-testid="info-alternative-location"
              isParagraph
            >
              {getMappedLocation()}
            </StyledMappedLocation>
          </InformationTextWrapper>
        )}
      </ContentWrapper>
    </DeliveryInfoWrapper>
  );
};

const offersPropTypes = {
  /** business unit of the offer */
  businessUnit: PropTypes.string,
  /** id of the offer */
  key: PropTypes.string,
  id: PropTypes.string,
  estimatedDeliveryTime: PropTypes.instanceOf(Date),
  /** Estimated delivery information if no concrete time could be identified */
  estimatedDeliveryTransit: PropTypes.shape({
    minDays: PropTypes.number,
    maxDays: PropTypes.number.isRequired,
  }),
  cutOffDateTime: PropTypes.instanceOf(Date),
  pickUpDate: PropTypes.instanceOf(Date),
  deliveryType: PropTypes.oneOf(["COMMITTED", "ESTIMATED"]),
  properties: PropTypes.array,
  estimatedDeliveryTimeDisplayType: PropTypes.string,
  specialOffers: PropTypes.string,
  information: PropTypes.array,
  locationMappingInformation: PropTypes.shape({
    originLocation: PropTypes.shape({
      countryCode: PropTypes.string,
      location: PropTypes.string,
      changed: PropTypes.bool,
    }),
    destinationLocation: PropTypes.shape({
      countryCode: PropTypes.string,
      location: PropTypes.string,
      changed: PropTypes.bool,
    }),
  }),
};

OfferDeliveryInfo.propTypes = {
  /** Internationalization library */
  intl: IntlPropType.isRequired,
  /** An object that contains all the information required of an offer */
  offer: PropTypes.shape({
    ...offersPropTypes,
  }).isRequired,
  is12HourTimeFormat: PropTypes.bool.isRequired,
  nativeDateTimeFormat: PropTypes.string,
  nativeLanguageCode: PropTypes.string.isRequired,
  offerType: PropTypes.oneOf([
    TYPES_OF_OFFERS.AGGREGATED,
    TYPES_OF_OFFERS.SIMPLE,
  ]),
  canaryEnabled: PropTypes.bool,
  canaryVersion: PropTypes.string,
};

OfferDeliveryInfo.defaultProps = {
  locationMappingInformation: {
    originLocation: {
      countryCode: "",
      location: "",
      changed: false,
    },
    destinationLocation: {
      countryCode: "",
      location: "",
      changed: false,
    },
  },
};

export default OfferDeliveryInfo;
