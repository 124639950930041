// 9fbef606107a605d69c0edbcd8029e5d


/**
 * Represents products in `window.dataLayer.search.products`
 * @param {array} products - array of product objects - refer to the payload documented on confluence
 * @example updateProducts([{
    name: "Signup",
    type: "Button",
    interaction: "Click",
    position: "Registration popup",
    context: "Signup flow",
    attributes: {
      key1: "value1",
      key2: "value2",
      key3: "value3"
    }
  }])
 */
export const updateProducts = (products) => {
  if (window?.dataLayer) {
    Object.assign(window.dataLayer, {
      search: {
        products,
      },
    });
  }
};

/**
 *
 * @param {object} content - contains content interaction key, values pairs - refer to the payload documented on confluence
 * @example trackContentInteraction({
    name: "Signup",
    type: "Button",
    interaction: "Click",
    position: "Registration popup",
    context: "Signup flow",
    attributes: {
      key1: "value1",
      key2: "value2"
    }
  });
 */
  export const trackContentInteraction = (content) => {
    document.dispatchEvent(
      new CustomEvent("dhl_utf_contentInteraction", {
        detail: {
          content,
        },
      })
    );
  };

/**
 *
 * @param {object} payload - contains conversion interaction key, values pairs - refer to the payload documented on confluence
 * @example trackConversionInteraction({
    conversion: {
      name: "Booking Intent",
      type: "Handover",
      detail: "WALK"  // or "CLICK" or "CALL"
    },
    content: {
      name: "Continue to Booking",  // or "Complete parcel details now", ...
      type: "Button",
      interaction: "Click",
      position: "Product Card",
      context: "business"
    }
  });
 */
  export const trackConversionInteraction = (payload) => {
    const {conversion = null, products = null, content = null} = payload;
    document.dispatchEvent(
      new CustomEvent("dhl_utf_conversionInteraction", {
        bubbles: true,
        detail: {
          conversion,
          products,
          content
        },
      })
    );
  };

/**
 * Represents `dhl_utf_errorpageload` UTF event
 * @param {object} valuesObject - contains, section which needs to be set under pageName string
 * @example trackErrorPageLoad("{page: { name: "Error Page", category: "Error" }, error: { statusCode: "500" }});
 */
export const trackErrorPageLoad = (valuesObject) => {
  document.dispatchEvent(new CustomEvent("dhl_utf_errorpageload", { bubbles: true, detail: valuesObject }));
};

/**
 * Represents `dhl_utf_engagementInteraction` UTF event
 * @param {object} payload - consists of `content` and `funnel` keys
 * @example trackEngagementInteraction({
    content = {
        context: "private",  // or "business"
    };
  
    funnel = {
        name: "Get a Quote",
        type: "Customer Journey",
        step: "Product Lane",  //  or Trade Lane / Shipment Lane
        progress: "Load"
    };
  });
 */
export const trackEngagementInteraction = (payload) => {
  document.dispatchEvent(new CustomEvent("dhl_utf_engagementInteraction", { bubbles: true, detail: payload}));
};

/**
 * Represents `dhl_utf_engagementInteraction` UTF event with the Product details
 * @param {object} payload - consists of `content`, `funnel` and `products` keys
 * @example trackEngagementAndProductInteraction({
    content = {
        context: "private",  // or "business"
        attributes: {
            layoutVersion: "singleBU"
        }
    };
  
    funnel = {
        name: "Get a Quote",
        type: "Customer Journey",
        step: "Product Lane",  //  or Trade Lane / Shipment Lane
        progress: "Load"
    };

    products: [
      {
        id: "27bfeaa5-fe0d-49a4-801e-4d6c1aa9cb80",
        businessUnit: "EXP",
        key: "EXP_INTERNATIONAL_ENDOFDAY_NONDOC",
        price: "52.25",
        currencyCode: "EUR",
        shippingModes: [{key: "GO_GREEN"}, {key: "INSURANCE"}],
      },
    ],
  });
 */
  export const trackEngagementAndProductInteraction = (payload) => {
    updateProducts(payload.products);
    document.dispatchEvent(new CustomEvent("dhl_utf_engagementInteraction", { bubbles: true, detail: payload}));
  };

export * from "./getUtfContractStrings";
