// 9fbef606107a605d69c0edbcd8029e5d

import { colors } from "../variables";

export default {
  postYellow: colors.yellowSunFlower,
  dhlRed: colors.redScarlet,
  dhlBlack: colors.blackMineShaft,
  successColor: colors.greenApple,
};
