// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for OfferCard
 *
 */

import styled from "styled-components";
import {
  breakpoints,
  colors,
  grid,
  dhlVarColors,
} from "root/libs/ui-styleguide/src";
import {
  Text,
  Accordion,
  IconDoubleChevron,
} from "@dhl-official/react-ui-library";
import { IconButtonTooltip } from "root/libs/ui-components/src";
import QuoteValidity from "one-time-shipment/src/components/Offers/QuoteValidity";


export const StyledOfferCard = styled.div`
  position: relative;
  width: 100%;
  background-color: ${colors.white};
  box-shadow: 0 3px 8px 0 ${dhlVarColors.shadowLightDefault};
  transition: ease-in-out 200ms box-shadow;
  border-radius: 4px;

  > .accordion {
    width: 100%;
    margin: 0 0 30px 0;
    padding: 0;
  }

  .offerCollapsibleContent {
    margin-bottom: 0;
    padding: 0;

    &.expanded {
      padding: 2.1rem 2.1rem 30px 2.1rem;
    }
  }

  &:hover {
    box-shadow: 0 3px 8px 0 ${dhlVarColors.shadowDarkFocus};

    .accordion {
      & > div:not(.accordionPanel) {
        button {
          box-shadow: 0 8px 5px -5px ${dhlVarColors.shadowDarkFocus};
        }
      }
    }
  }

  &:focus,
  &:focus-within {
    box-shadow: 0 2px 7px 1px ${dhlVarColors.shadowLightHover};
    outline: 2px solid transparent;

    .accordion {
      & > div:not(.accordionPanel) {
        button {
          box-shadow: 0 8px 5px -5px ${dhlVarColors.shadowLightHover};
        }
      }
    }
  }

  @media print {
    padding: 20px;
    page-break-after: always;
    page-break-inside: avoid;
    margin-top: 30px;

    & .accordion > dd {
      height: auto;
    }
  }
`;

const CardContentPaddingMobile = "20px 10px 30px";
const CardContentPaddingTablet = "30px 20px";
const CardContentPaddingDesktop = "25px 30px";

export const OfferCardContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 190px;
  padding: ${CardContentPaddingMobile};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${CardContentPaddingTablet};
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding: ${CardContentPaddingDesktop};
  }
`;

export const DeliveryInfoContent = styled.div`
  margin-bottom: 30px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-basis: 48rem;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-bottom: 0;
    flex-basis: 58rem;
  }

  @media all and (-ms-high-contrast: none) {
    width: 66%;
  }
`;

export const PaymentInfoContent = styled.div`
  display: block;

  @media screen and (min-width: ${breakpoints.tablet}) {
    min-width: 280px;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    width: 280px;
  }
`;

const lightBorder = `1px solid ${dhlVarColors.lightBorderColor};`;

export const StyledAccordion = styled(Accordion)`
  position: relative;
  padding: 0;

  .accordion {
    width: 100%;

    & > div > button:focus {
      outline: 1px dotted ${colors.silverDark};
    }

    & > div:not(.accordionPanel) {
      button {
        position: absolute;
        bottom: -20px;
        background: #fff;
        box-shadow: rgb(229 229 229) 0 9px 6px -5px;
        transition: ease-in-out 200ms box-shadow;
      }
    }
  }
`;

export const IconChevronUp = styled(IconDoubleChevron)`
  transform: rotate(180deg);
`;

export const IconChevronDown = styled(IconDoubleChevron)`
  transform: rotate(0deg);
`;

export const OfferCardDetailsContent = styled.div`
  padding-top: 30px;
  padding-bottom: 0;
  margin-left: 10px;
  margin-right: 10px;
  border-top: ${lightBorder};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const DeliveryInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > .icon {
    order: 2;
    height: 100%;
    width: 100%;
    text-align: right;
    max-width: 72px;
    align-self: flex-end;
    margin-bottom: auto;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    align-items: center;

    > .icon {
      margin-right: 30px;
      margin-bottom: 0;
      order: 1;
      text-align: left;
      height: auto;
      width: auto;
      max-width: unset;
      align-self: unset;
    }
  }
`;

export const StyledEstimatedDeliveryText = styled(Text)`
  margin: 0;
  margin-bottom: 2px;
`;

export const StyledEstimatedDeliveryInfo = styled(Text)`
  margin-top: 0px;
`;

export const StyledTextAndTooltip = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  padding: 0 10px;
  position: relative;
`;

export const StyledIconButtonTooltip = styled(IconButtonTooltip)`
  position: absolute;
  right: 0;
  top: 0;
`;

export const StyledPriceContainer = styled.div`
  display: block;

  @media screen and (min-width: ${breakpoints.tablet}) {
    min-width: 280px;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    width: 280px;
  }
`;

export const StyledContainer = styled.div`
  padding: 10px 20px 20px 20px;
`;

export const StyledCTAWrapper = styled.div`
  display: flex;
  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-right: 50px;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-right: 0;
    align-items: inherit;

    .icon {
      margin-top: 5px;
    }
  }
`;

export const StyledAdditionalOfferDetails = styled.div`
  padding-bottom: 0;
  margin-left: 10px;
  margin-right: 10px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-bottom: 0;
    margin-left: 20px;
    margin-right: 20px;
  }
`;

export const ContentWrapper = styled.div`
  order: 1;
  width: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    order: 2;
    padding-right: 30px;
  }
`;

export const OfferWrapper = styled.div`
  margin-bottom: 50px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: 45px;
    padding: 0 ${grid.gutter.m};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-bottom: 35px;
    padding: 0 0;
    max-width: ${grid.container.maxWidthInnerContainer}px;
  }
`;

export const StyledQuoteValidity = styled(QuoteValidity)`
  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-left: 30px;
  }
`;
