// 9fbef606107a605d69c0edbcd8029e5d

/**
 * This object contains general CSS grid settings
 * that define the grid.
 * @type {Object}
 */

export default {
  gutter: {
    s: "11px",
    m: "20px",
    l: "30px",
  },
  gutterUnitless: {
    s: 11,
    m: 20,
    l: 30,
  },
  columns: {
    width: {
      s: "13px",
      m: "40px",
      l: "73px",
    },
    number: 12,
  },
  container: {
    maxWidth: "1365px",
    maxWidthWithGutters: 1206,
    maxWidthNumber: 1365,
    maxWidthInnerContainer: 1000, // Only for tablet+ viewports: 10* column width (73px) + 9* gutter size (13px)
    paddings: {
      s: "20px",
      m: "30px",
      l: "80px",
    },
  },
};
