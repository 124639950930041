// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for EstimatedDeliveryInfo
 *
 */

import styled from "styled-components";
import { key } from "styled-theme";
import { Text, THEME } from "@dhl-official/react-ui-library";
import { breakpoints } from "root/libs/ui-styleguide/src";
import { Headline } from "root/libs/ui-components/src";

export const DeliveryTimeText = styled(Text)`
  margin-top: calc(${THEME.getUnit("regular")} / 2);

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-top: 0;
    padding-left: 10px;
    margin-left: 10px;
    border-left: 2px solid ${key("colors.lightBorderColor")};

    &.text--giga.text--giga {
      line-height: 1;
    }
  }
`;

export const OfferFeaturesWrapper = styled.div`
  margin-top: 10px;

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-top: 0;
    padding-left: 10px;
    margin-left: 10px;
    border-left: 2px solid ${key("colors.lightBorderColor")};
  }
`;

export const EstimatedDeliveryText = styled(Text)`
  color: ${key("colors.headerColor")};
  margin: 0;
  margin-bottom: 2px;
`;

export const DeliveryDateHeadline = styled(Headline)`
  margin: 0;
  white-space: normal;

  @media screen and (min-width: ${breakpoints.tablet}) {
    max-width: 33.5rem;
  }
`;

export const DateTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 15px;

  @media screen and (min-width: ${breakpoints.desktop}) {
    line-height: 24px;
    flex-direction: row;
    display: inline-flex;
    margin-bottom: 8px;
  }
`;
