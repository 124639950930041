// 9fbef606107a605d69c0edbcd8029e5d

import { css } from "styled-components";
import colors from "../themes/dhl/vars.colors";

export const focusedFormControlStyle = `1px solid ${colors.focusBorderColor}`;

export const focusedState = {
  noOutline: css`
    &:focus,
    &:active {
      outline: 2px solid transparent;
    }
  `,
  focusWithinBottomAndSides: css`
    &:focus-within {
      border-bottom: ${focusedFormControlStyle};

      &::after,
      &::before {
        border-color: ${colors.focusBorderColor};
        border-width: 2px;
      }

      &::after {
        bottom: -2px;
      }
    }
  `,
  focusAllBorders: css`
    &:focus {
      outline: 1px solid transparent;
      border-color: transparent;
      box-sizing: border-box;
      border: ${focusedFormControlStyle};
    }
  `,
  focusWithinAllBorders: css`
    &:focus-within {
      outline: 1px solid transparent;
      border-color: transparent;
      box-sizing: content-box;
      border: ${focusedFormControlStyle};
    }
  `,
  focus: css`
    &:focus,
    &:active {
      border: ${focusedFormControlStyle};
      outline: 1px solid tranparent;
    }
  `,
  outlineAllBorders: css`
    &:focus {
      outline: ${focusedFormControlStyle};
    }
  `,
  lightGray: css`
    &:focus,
    &:active {
      border: 0;
      outline: 2px solid ${colors.globalBorderColor};
    }
  `,
  transparentBorderAndFocus: css`
    border: 2px solid transparent;

    &:focus,
    &:active {
      border: ${focusedFormControlStyle};
      outline: 1px solid transparent;
    }
  `,
  radioCheckBoxFocus: css`
    &:focus,
    &:active {
      outline: 1px solid transparent;

      &::before {
        border: ${focusedFormControlStyle};
        outline: 1px solid transparent;
      }
    }
  `,
};
