// 9fbef606107a605d69c0edbcd8029e5d

/*
 *
 * getOffers actions
 *
 */

import { createAction } from "redux-actions";
import {
  SHOW_SHIPMENT_LANE,
  HIDE_SHIPMENT_LANE,
  VALIDATE_FORM_FIELDS,
  CLEAR_DIMENSION_FIELDS_VALIDATION_ERRORS,
  RESET_SCROLLING,
  RESET_ITEM,
  UPDATE_ITEM,
  PREFILL_INITIAL_SET,
  PREFILL_SHIPMENT_LANE_FROM_URL,
  ON_CHECKBOX_CHANGE,
  UPDATE_VALIDITY,
  ADD_SHIPMENT_LINE_ITEM,
  REMOVE_SHIPMENT_LINE_ITEM,
  COPY_SELECTED_UNIT_SYSTEM_FROM_COUNTRY_SETTINGS,
  SET_UNIT_SYSTEM,
} from "./constants";

export const resetItem = createAction(RESET_ITEM);
export const updateItem = createAction(UPDATE_ITEM);
export const showShipmentLane = createAction(SHOW_SHIPMENT_LANE);
export const hideShipmentLane = createAction(HIDE_SHIPMENT_LANE);
export const validateFormFields = createAction(VALIDATE_FORM_FIELDS);
export const clearDimensionFieldsValidationErrors = createAction(
  CLEAR_DIMENSION_FIELDS_VALIDATION_ERRORS
);
export const resetScrolling = createAction(RESET_SCROLLING);
export const prefillInitialSet = createAction(PREFILL_INITIAL_SET);
export const prefillShipmentLaneFromUrl = createAction(
  PREFILL_SHIPMENT_LANE_FROM_URL
);
export const onCheckboxChange = createAction(ON_CHECKBOX_CHANGE);
export const updateValidity = createAction(UPDATE_VALIDITY);
export const addShipmentLaneItem = createAction(ADD_SHIPMENT_LINE_ITEM);
export const removeShipmentLaneItem = createAction(REMOVE_SHIPMENT_LINE_ITEM);
export const copySelectedUnitSystem = createAction(
  COPY_SELECTED_UNIT_SYSTEM_FROM_COUNTRY_SETTINGS
);
export const setUnitSystem = createAction(SET_UNIT_SYSTEM);
