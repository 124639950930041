// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * ErrorBoundary
 *
 */

import React from "react";
import PropTypes from "prop-types";
// import styled from 'styled-components';

export default class ErrorBoundary extends React.PureComponent {
  static propTypes = {
    children: PropTypes.any,
  };

  static defaultProps = {
    children: "",
  };

  constructor(props) {
    super(props);
    this.state = {
      error: false,
      message: "",
    };
  }

  componentDidCatch(error, info) {
    this.setState({ error: true });
    this.setState({ message: info });
  }

  render() {
    const { children } = this.props;

    const { error, message } = this.state;

    let errorMessage;

    if (error) {
      errorMessage = `Error: ${message.componentStack}`;
    } else {
      errorMessage = children;
    }

    return errorMessage;
  }
}
