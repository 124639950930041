// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * WCCMultiBUOfferDeliveryInfo
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { TEXT_SIZES } from "@dhl-official/react-ui-library";
import {
  IntlPropType,
  getDeliveryDate,
  getTimeOfDay,
  getDeliveryTime,
} from "root/libs/core-libs/src";
import {
  EstimatedDeliveryContainer,
  DeliveryTimeText,
  DeliveryDateHeadline,
} from "./styled";

export default function WCCMultiBUOfferDeliveryInfo({
  intl,
  offerIndex,
  estimatedDeliveryTime,
  nativeDateTimeFormat,
  estimatedDeliveryTimeDisplayType,
  is12HourTimeFormat,
  nativeLanguageCode,
  offer,
}) {
  const getOfferTimes = () => {
    const deliveryDate = getDeliveryDate(
      intl,
      estimatedDeliveryTime,
      nativeDateTimeFormat,
      nativeLanguageCode
    );

    let deliveryTime = "";

    if (estimatedDeliveryTime) {
      const timeOfDay = getTimeOfDay(
        intl,
        estimatedDeliveryTime,
        estimatedDeliveryTimeDisplayType,
        is12HourTimeFormat
      );
      deliveryTime = getDeliveryTime(
        intl,
        timeOfDay,
        estimatedDeliveryTimeDisplayType
      );
    }

    return {
      deliveryDate,
      deliveryTime,
    };
  };

  const { deliveryDate, deliveryTime } = getOfferTimes();

  return (
    <React.Fragment>
        <EstimatedDeliveryContainer
          data-testid={`wcc-offer-card-estimated-delivery-container-${offerIndex}`}
        >
          <DeliveryDateHeadline
            weight="800"
            inlined
            priority={4}
            className="horizontal-card-headline"
            dataTestid={`wcc-offer-card-estimated-delivery-date-${offerIndex}`}
          >
            {deliveryDate}
          </DeliveryDateHeadline>
          {!!deliveryTime && (
            <DeliveryTimeText
              weight={200}
              size={TEXT_SIZES.LARGE}
              dataTestid={`wcc-offer-card-estimated-delivery-guarantee-${offerIndex}`}
            >
              {deliveryTime}
            </DeliveryTimeText>
          )}
        </EstimatedDeliveryContainer>
    </React.Fragment>
  );
}

WCCMultiBUOfferDeliveryInfo.propTypes = {
  /** Internationalization library */
  intl: IntlPropType.isRequired,
  offer: PropTypes.object,
  offerIndex: PropTypes.string,
  estimatedDeliveryTime: PropTypes.instanceOf(Date),
  nativeDateTimeFormat: PropTypes.string,
  estimatedDeliveryTimeDisplayType: PropTypes.string,
  is12HourTimeFormat: PropTypes.bool.isRequired,
  nativeLanguageCode: PropTypes.string,
};
