// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for TabTitle
 *
 */

import styled, { css } from "styled-components";
import { key } from "styled-theme";
import { breakpoints, colors } from "root/libs/ui-styleguide/src";

export const ieTabListItem = css`
  /* Only for IE11 */
  @media all and (-ms-high-contrast: none) {
    width: 25%;

    @media screen and (min-width: ${breakpoints.tablet}) {
      width: inherit;
    }
  }
`;

export const TabListItem = styled.li`
  list-style: none;
  flex: 1 0 ${(props) => 100 / props.numberOfTabs}%;
  cursor: pointer;
  position: relative;
  border-top: 1px solid ${key("colors.shadowDarkHover")};
  transition: border-top 100ms ease-in-out;
  ${ieTabListItem}

  &.is-selected {
    border-top-color: transparent;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    min-width: 205px;
    flex: inherit;
  }

  &:last-child {
    border-left: 0;
  }

  @media print {
    display: none;
  }
`;

export const TabLinkStyled = styled.a`
  border-top: 5px solid transparent;
  transition: border-top 100ms ease-in-out;
  outline: none;
  width: 100%;
  height: 100%;
  text-align: ${(props) => props.headlineAlignment};
  font-weight: ${(props) => props.headlineFontWeight};
  text-decoration: none;
  color: ${key("colors.fontColor")};
  padding: 12px 6px;
  display: inline-flex;
  align-items: center;
  justify-content: ${(props) => props.headlineAlignment};
  font-size: ${key("fonts.mobile.tabs.size")};
  line-height: ${key("fonts.mobile.tabs.lineHeight")};
  background-color: ${colors.shadowHalfOpacity};

  &.is-selected {
    color: ${key("colors.primary")};
    border-top-color: ${key("colors.primary")};
    background-color: ${key("colors.backgroundColorInverted")};
  }

  .tabLinkText {
    padding: 4px 3px 1px;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    font-size: ${key("fonts.desktop.tabs.size")};
    line-height: ${key("fonts.desktop.tabs.lineHeight")};
    text-align: center;
    padding: 14px 20px;

    &:focus .tabLinkText {
      background-color: ${key("colors.primary")};
      color: ${key("colors.fontColorInverded")};
    }
  }

  ${(props) => props.extraStyles};
`;
