// 9fbef606107a605d69c0edbcd8029e5d

import { key } from "styled-theme";
import styled from "styled-components";
import { Text, SwitchField } from "@dhl-official/react-ui-library";
import { breakpoints, grid } from "root/libs/ui-styleguide/src";
import { IconButton, TextAndIcon } from "root/libs/ui-components/src";

export const TradeLaneWrapper = styled.div`
  background-color: ${key("colors.backgroundColorInverted")};
  box-shadow: 0 3px 8px 0 ${key("colors.shadowLightDefault")};
  border-radius: 4px;
  margin-bottom: 30px;
  padding: 20px 0 10px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: 50px;
  }

  @media print {
    box-shadow: none;
  }
`;

export const TradeLaneFormContentWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: 0 ${grid.columns.width.m} 0 ${grid.columns.width.m};
  }
`;

export const TradeLaneImportExportToggleWrapper = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: 0 ${grid.columns.width.m};
  }
`;

export const TradeLaneTooltipWrapper = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: 0 ${grid.columns.width.m};
  }
`;

export const DirectionParagraph = styled(Text)`
  margin-bottom: 8px;
`;

export const DirectionHeadlineWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (min-width: ${breakpoints.desktop}) {
    flex-wrap: nowrap;
  }

  @media print {
    line-height: 0px;
  }
`;

export const StyledTextAndLink = styled(Text)`
  width: 100%;
  margin-top: -9px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: auto;
    margin-top: 10px;
    margin-bottom: 0;
  }
`;

export const OriginContainer = styled.fieldset`
  display: block;
  width: 100%;
  order: 1;

  && {
    @media screen and (min-width: ${breakpoints.tablet}) {
      padding-bottom: 20px;
    }
  }
`;

export const FlipIconButtonWrapper = styled.div`
  order: 2;
  width: 100%;
`;

export const ToggleTextAndIcon = styled(TextAndIcon)`
  & > span {
    padding-right: 0;
  }
  @media screen and (max-width: ${breakpoints.tablet}) {
    display: flex;
    align-items: flex-start;

    // Block wrapping the SwitchField + Tooltip label
    & > span {
      width: 100%;
    }
  }
`;

export const ToggleField = styled(SwitchField)`
  /* RTL Fix */
  & > label > input {
    /*! @noflip */
    left: -9999px;
    /*! @noflip */
    right: unset;
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    // Block with the SwitchField wrapper
    & > label {
      padding: 0 0 1.4rem 0 !important;
      display: flex;
      flex-wrap: wrap;
    }

    // Block with the SwitchField control
    & > label > div {
      display: block;
      position: relative;
      order: 1;
      top: 0;
    }

    // Block with the SwitchField text
    & > label > span {
      display: block;
      width: 100%;
      padding: 0;
      margin: 0;
    }
  }

  & > label > div {
    // Transforms SwitchField in small pill for desktop
    @media screen and (min-width: ${breakpoints.tablet}) {
      transform: scale(0.7) translateX(5px) translateY(5px);
    }
  }
`;

export const StyledFlipIconButton = styled(IconButton)`
  transform: rotate(90deg);
  order: 2;
  position: relative;
  left: -39px;
  margin-bottom: 0;
  transition: top 0.4s ease-in-out;

  @media screen and (min-width: ${breakpoints.tablet}) {
    position: absolute;
    align-self: start;
    // Fix for flags displaying on top of flip-icon
    // This button will be removed once we finish the import/export test
    z-index: 3;
    left: 0;
    top: ${(props) => (props.isOriginValid ? "116px" : "140px")};
    margin-top: 10px;
    /* RTL aligment Fix */
    transform: rotate(90deg)
      translate(-50%, ${(props) => (props.rtlEnabled ? "-50%" : "50%")});
  }
`;

export const DestinationContainer = styled.fieldset`
  display: block;
  width: 100%;
  order: 3;
`;

export const FeedbackMessageContainer = styled.div`
  margin-top: 20px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-left: ${grid.columns.width.m};
    margin-right: ${grid.columns.width.m};
    margin-top: 30px;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-left: ${grid.columns.width.l};
    margin-right: ${grid.columns.width.l};
  }
`;
