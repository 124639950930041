// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * ResponsiveRender
 *
 */

import React, { useEffect, useState } from "react";
import throttle from "lodash/throttle";
import PropTypes from "prop-types";
import { uiConfig } from "root/libs/ui-styleguide/src";

export default function ResponsiveRender({ render, match }) {
  const [deviceRatio, setDeviceRatio] = useState({
    isMobile: window.innerWidth < uiConfig.smallViewportBreak,
    isTablet: window.innerWidth <= uiConfig.mediumViewportBreak,
  });

  const calculateViewport = () => {
    const isMobile = window.innerWidth < uiConfig.smallViewportBreak;
    const isTablet = window.innerWidth < uiConfig.mediumViewportBreak;
    setDeviceRatio({ isMobile, isTablet });
  };

  const throttledHandleWindowResize = () => throttle(calculateViewport, 200);

  useEffect(() => {
    window.addEventListener("resize", throttledHandleWindowResize());

    return () => {
      // Cleaning on Unmount
      window.removeEventListener("resize", throttledHandleWindowResize());
    };
  }, []);

  const { isMobile, isTablet } = deviceRatio;

  // Decides which viewport size is relevant to render the content.
  const matchViewport = match === "mobile" ? isMobile : isTablet;

  return <React.Fragment>{render(matchViewport)}</React.Fragment>;
}

ResponsiveRender.propTypes = {
  /** render prop is a function prop that a
   * component uses to know what to render.
   * Here you send a function that receives the param we send below to your component */
  render: PropTypes.func.isRequired,
  /* Decide since which viewport size the component should be rendered */
  match: PropTypes.oneOf(["mobile", "tablet"]),
};

ResponsiveRender.defaultProps = {
  match: "mobile",
};
