// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for BillingCountryCard
 *
 */

import styled from "styled-components";
import { THEME, Card, Button } from "@dhl-official/react-ui-library";
import { breakpoints } from "root/libs/ui-styleguide/src";
import { Flag } from "root/libs/ui-components/src";

export const DisabledCountryCard = styled(Card)`
  border: solid 1px transparent;
  display: block;
  padding: calc(${THEME.getUnit("regular")} + 0.5rem)
    ${THEME.getUnit("regular")} ${THEME.getUnit("regular")};
  position: relative;
  transition: box-shadow ${THEME.getAnimationSpeed("regular")},
    border-color ${THEME.getAnimationSpeed("regular")};
  user-select: none;

  &:focus-within,
  &:hover {
    box-shadow: 0 2px 7px 1px ${THEME.getGrayColor("500")};
  }
`;

export const CountryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

export const CountryFlag = styled(Flag)`
  // Defines the scale of flag sprites
  --s: 0.5;
  display: inline-block;
  @media screen and (min-width: ${breakpoints.tablet}) {
    --s: 0.75;
  }
`;
export const CountryLabel = styled.p.attrs((props) => ({
  "aria-labelledby": props.ariaLabelledby,
  "data-testid": props.dataTestid,
  "data-countryid": props.dataCountryId,
  tabIndex: props.tabIndex,
}))`
  display: inline-block;
  margin: 0;
  padding: 0 0 0 ${THEME.getUnit("regular")};
  flex: 1;
`;

export const BillingCountryNote = styled.p`
  flex: 1 0 100%;
  border-top: 1px solid ${THEME.getGrayColor("300")};
  margin: ${THEME.getUnit("regular")} 0 0 0;
  padding: calc(${THEME.getUnit("regular")} / 2) 0 0 0;
  font-size: ${THEME.getFontSize("tiny")};
`;

export const ChangeCountryButton = styled(Button)`
  padding: 0;
  font-weight: normal;
  text-decoration: underline;
  white-space: nowrap;
`;
