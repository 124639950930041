// 9fbef606107a605d69c0edbcd8029e5d

import { createSelector } from "reselect";

const selectCanaryVersionEnabled = (state) => state?.canaryVersion.enabled;

const canaryVersionSelector = (state) => state?.canaryVersion;

const selectCanaryVersion = createSelector(
  canaryVersionSelector,
  (canaryVersion) => canaryVersion?.version
);

export { selectCanaryVersionEnabled, canaryVersionSelector, selectCanaryVersion };
