// 9fbef606107a605d69c0edbcd8029e5d

import styled, { css } from "styled-components";
import { key } from "styled-theme";
import { sizes, breakpoints, colors } from "root/libs/ui-styleguide/src";

const hoverButton = css`
  &:hover {
    outline: 1px solid transparent;
    border: 1px solid transparent;
  }
`;

const defaultButton = css`
  background-color: ${colors.silver};
  border: 1px solid transparent;
  line-height: ${key("fonts.desktop.button.lineHeight")};
  text-align: center;
  text-decoration: ${(props) =>
    props.buttonType === "link" ? "underline" : "none"};
  min-height: ${sizes.inputControlHeight};
  color: ${colors.white};
  min-width: ${sizes.buttonMinWidth};
  width: 100%;
  position: relative;
  font-weight: 700;
  padding: ${sizes.inputVerticalPaddingsBig} 5px;
  border-radius: 4px;
  font-size: ${key("fonts.mobile.button.size")};

  @media screen and (min-width: ${breakpoints.tablet}) {
    font-size: ${key("fonts.desktop.copy.size")};
    ${(props) => (props.fluidButton ? "width: 100%;" : "width: auto;")};
  }

  ${hoverButton}
`;

const primaryButton = css`
  background-color: ${key("colors.primary")};
  transition: ease-in-out 200ms background-color;

  &:focus {
    box-shadow: 0 0 0 4px white, 0 0 0 6px ${key("colors.primary")};
  }

  /* Leave this after the focus style to prevent the box-shadow to appear on hover of a focused button */
  &:hover {
    background-color: ${key("colors.primaryHover")};
    box-shadow: unset;
  }

  &:disabled {
    color: ${colors.white};
    border: 1px solid transparent;
    background-color: ${key("colors.lightBorderColor")};
  }
`;

const secondaryButton = css`
  background-color: ${key("colors.backgroundColorInverted")};
  color: ${key("colors.primary")};
  border: 1px solid ${key("colors.primary")};

  &:focus,
  &:active {
    box-shadow: 0 0 0 4px white, 0 0 0 6px ${key("colors.primary")};
    border-width: 2px;
    padding: calc(${sizes.inputVerticalPaddingsBig} - 1px) calc(5px - 1px);
  }

  /* Leave this after the focus style to prevent the box-shadow to appear on hover of a focused button */
  &:hover {
    border-color: ${key("colors.primaryHover")};
    color: ${key("colors.primaryHover")};
    box-shadow: unset;
  }

  &:disabled {
    background-color: ${key("colors.backgroundColorInverted")};
    color: ${colors.silver};
    border-color: ${key("colors.lightBorderColor")};
  }
`;

const successButton = css`
  background-color: ${key("colors.successColor")};

  &:focus {
    box-shadow: 0 0 0 4px white, 0 0 0 6px ${key("colors.successColor")};
    border-width: 2px;
  }

  /* Leave this after the focus style to prevent the box-shadow to appear on hover of a focused button */
  &:hover {
    background-color: ${key("colors.successHover")};
    box-shadow: unset;
  }
`;

const inlineStyles = css`
  font-weight: 700;
  font-size: ${key("fonts.mobile.copy.fontSize")};
  line-height: ${key("fonts.mobile.copy.lineHeight")};
  text-decoration: underline;

  &:focus {
    padding: 1px 3px 1px;
  }
`;

export const StyledLink = styled.a`
  color: inherit;
  text-decoration: none;

  &.inline {
    ${inlineStyles}
    color: ${key("colors.primary")};

    &:focus {
      background-color: ${key("colors.primary")};
      color: ${key("colors.fontColorInverded")};
    }
  }

  &.inline-black {
    ${inlineStyles}

    &:focus {
      background-color: ${key("colors.fontColor")};
      color: ${key("colors.fontColorInverded")};
    }
  }

  &.primary {
    color: ${key("colors.primary")};

    &:focus {
      text-decoration: underline;
    }
  }

  &.cta {
    width: 100%;
    display: block;
    ${defaultButton}
    ${(props) => (props.disableMinWidth ? "min-width: unset;" : "")}

    &:focus {
      text-decoration: none;
    }
  }

  &.cta.success {
    ${successButton}
  }

  &.cta.primary {
    ${primaryButton}
  }

  &.cta.secondary {
    ${secondaryButton}
  }
`;
