// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for WCCMultiBUOfferDeliveryInfo
 *
 */

import styled from "styled-components";
import { key } from "styled-theme";
import { Text, THEME } from "@dhl-official/react-ui-library";
import { breakpoints } from "root/libs/ui-styleguide/src";
import { Headline } from "root/libs/ui-components/src";

export const EstimatedDeliveryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 15px;

  @media screen and (min-width: ${breakpoints.desktop}) {
    line-height: 24px;
    flex-direction: row;
    display: inline-flex;
    margin-bottom: 8px;
  }
`;

export const EstimatedDeliveryContainerText = styled(Text)`
  margin-bottom: 10px;
`;

export const StyledAdditionalInfo = styled(Text)`
  display: inline;
`;

export const InformationTextWrapper = styled.div`
  margin-top: 10px;

  > .icon {
    display: inline;
    margin-right: 5px;
  }
`;

export const StyledMappedLocation = styled(Text)`
  display: inline;
  line-height: 20px;
`;

export const ContentWrapper = styled.div`
  order: 1;
  width: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    order: 2;
    padding-right: 30px;
  }
`;

export const DeliveryInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > .icon {
    order: 2;
    height: 100%;
    width: 100%;
    text-align: right;
    max-width: 72px;
    align-self: flex-end;
    margin-bottom: auto;
  }

  &.wcc-aggregated-card > .icon {
    order: 1;
    position: absolute;
    right: 1.5rem;
    top: 0.625rem;

    @media screen and (min-width: ${breakpoints.tablet}) {
      position: unset;
      top: unset;
      right: unset;
    }
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    align-items: center;

    > .icon {
      margin-right: 30px;
      margin-bottom: 0;
      order: 1;
      text-align: left;
      height: auto;
      width: auto;
      max-width: unset;
      align-self: unset;
    }
  }

  &.wcc-aggregated-card ${ContentWrapper} {
    margin-top: 0.4rem;
  }
`;

export const DeliveryDateHeadline = styled(Headline)`
  margin: 0;
  white-space: normal;

  @media screen and (min-width: ${breakpoints.tablet}) {
    max-width: 33.5rem;
  }
`;

export const DeliveryTimeText = styled(Text)`
  margin-top: calc(${THEME.getUnit("regular")} / 2);

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-top: 0;
    padding-left: 10px;
    margin-left: 10px;
    border-left: 2px solid ${key("colors.lightBorderColor")};

    &.text--giga.text--giga {
      line-height: 1;
    }
  }
`;
