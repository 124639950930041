// 9fbef606107a605d69c0edbcd8029e5d

import styled from "styled-components";
import { Headline } from "root/libs/ui-components/src";
import { colors } from "root/libs/ui-styleguide/src";
import QuantitySelector from "one-time-shipment/src/components/Forms/QuantitySelector";
import { grid } from "root/libs/ui-styleguide/src";

export const DimensionSelectorSmallWrapper = styled.div`
  display: flex;
  padding-top: 20px;
  flex-wrap: wrap;

  @supports (display: grid) {
    grid-row: dimensionsRow;
    grid-column: 1 / span 12;
  }

  .hideDimensionWrapper {
    display: none;
  }
`;

export const IconHeadlineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: ${grid.columns.width.s};
  margin-right: ${grid.columns.width.s};
`;

export const StyledHeadline = styled(Headline)`
  margin-top: 25px;
`;

export const CustomDimensionsWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 5px;
  width: 100%;
`;

export const WidthHeightLengthControls = styled.div`
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  margin-left: ${grid.columns.width.s};
  margin-right: ${grid.columns.width.s};

  & > div {
    width: 100%;
    margin-bottom: 20px;
  }

  .validation-info div[role="alert"] > div {
    color: ${colors.black};
    white-space: pre-line;
  }
`;

export const StyledQuantitySelectorWrapper = styled.div`
  width: 100%;
`;

export const StyledQuantitySelector = styled(QuantitySelector)`
  padding-top: 0;
  margin: 0 13px 5px;
`;
