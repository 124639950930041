// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for OfferCardWrapper
 *
 */

import styled from "styled-components";
import { breakpoints, grid } from "root/libs/ui-styleguide/src";


export const StyledMultiBUWrapper = styled.div`
  margin-bottom: 50px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: 45px;
    padding: 0 ${grid.gutter.m};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-bottom: 35px;
    padding: 0 0;
    max-width: ${grid.container.maxWidthInnerContainer}px;
  }
`;

export const StyledOfferCardWrapper = styled.div`
  position: relative;
  overflow: ${(props) => props.isHeaderOpen ? "visible" : "hidden"};
  max-height: ${(props) => props.isHeaderOpen ? "25500px" : "39px"};
  transition: max-height 200ms cubic-bezier(0.4, 0.18, 0.41, 0.87) 0s;
`;
