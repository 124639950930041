// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * ABTest
 * To learn how this works and how to setup: docs/Analytics/AB-testing.md
 */

import React from "react";
import PropTypes from "prop-types";

export default class ABTest extends React.PureComponent {
  static propTypes = {
    type: PropTypes.string.isRequired,
    componentToRender: PropTypes.func.isRequired,
    showThisVersion: PropTypes.string.isRequired,
  };

  render() {
    const { type, componentToRender, showThisVersion } = this.props;

    return (
      <React.Fragment>
        {showThisVersion === type && componentToRender()}
      </React.Fragment>
    );
  }
}
