// 9fbef606107a605d69c0edbcd8029e5d

import React, { memo } from "react";
import PropTypes from "prop-types";
import warningIcon from "iconsTwoColors/cx/Ic_Warning.svg";
import { brandPalette } from "root/libs/ui-styleguide/src";
import { BUTTON_VARIANTS } from "@dhl-official/react-ui-library";
import ContentModal from "../ContentModal";
import Icon from "../../Icons/Icon";
import {
  Content,
  ButtonsContainer,
  StyledButton,
  StyledWarningIconWrapper,
  StyledContentWrapper,
  StyledHeadline,
} from "./styled";

const RetryModal = ({
  cancelButtonLabel,
  children,
  closeButtonLabel,
  headline,
  hideCloseIcon,
  isRetrying,
  onClose,
  onRetry,
  retryButtonLabel,
  subHeadline,
  ...otherProps
}) => (
  <ContentModal
    closeButtonAriaLabel={closeButtonLabel}
    contentAriaLabel={`${headline} ${subHeadline}`}
    hideCloseIcon={hideCloseIcon}
    {...otherProps}
    smallCenteredModal
    onClose={onClose}
  >
    <Content>
      <StyledWarningIconWrapper>
        <Icon
          fillColor={brandPalette.dhlRed}
          icon={warningIcon}
          size="inbetweenLarge"
          useAs="decorative"
        />
      </StyledWarningIconWrapper>

      <StyledContentWrapper>
        <StyledHeadline
          dataTestid="retry-modal-headline"
          noMargin
          priority={3}
          weight="800"
        >
          {headline}
          <span>{subHeadline}</span>
        </StyledHeadline>

        {children}

        <ButtonsContainer>
          <StyledButton
            className="automation-errorModal-retry-button"
            dataTestid="retry-modal-retry-button"
            disabled={isRetrying}
            name="button"
            onClick={onRetry}
            type="submit"
          >
            {retryButtonLabel}
          </StyledButton>
          <StyledButton
            variant={BUTTON_VARIANTS.SECONDARY}
            className="automation-errorModal-close-button"
            dataTestid="retry-modal-cancel-button"
            name="button"
            onClick={onClose}
            type="submit"
          >
            {cancelButtonLabel}
          </StyledButton>
        </ButtonsContainer>
      </StyledContentWrapper>
    </Content>
  </ContentModal>
);

RetryModal.propTypes = {
  cancelButtonLabel: PropTypes.node.isRequired,
  children: PropTypes.node,
  closeButtonLabel: PropTypes.node.isRequired,
  headline: PropTypes.node.isRequired,
  hideCloseIcon: PropTypes.bool,
  isRetrying: PropTypes.bool,
  onClose: PropTypes.func,
  onRetry: PropTypes.func,
  retryButtonLabel: PropTypes.node.isRequired,
  subHeadline: PropTypes.node.isRequired,
};

RetryModal.defaultProps = {
  children: undefined,
  hideCloseIcon: true,
  isRetrying: false,
  onClose: Function.prototype,
  onRetry: Function.prototype,
};

export default memo(RetryModal);
