// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * WCCOfferDeliveryInfo
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { TEXT_SIZES } from "@dhl-official/react-ui-library";
import WCCOfferAdditionalTimings from "one-time-shipment/src/components/Offers/WCCOfferAdditionalTimings";
import {
  IntlPropType,
  getDeliveryDate,
  getTimeOfDay,
  getDeliveryTime,
} from "root/libs/core-libs/src";
import {
  EstimatedDeliveryContainer,
  EstimatedDeliveryContainerText,
} from "./styled";

export default function WCCOfferDeliveryInfo({
  intl,
  offerIndex,
  estimatedDeliveryTime,
  nativeDateTimeFormat,
  estimatedDeliveryTimeDisplayType,
  is12HourTimeFormat,
  nativeLanguageCode,
  offerId,
  metadataByPurchaseMethod,
}) {
  const getOfferTimes = () => {
    const deliveryDate = getDeliveryDate(
      intl,
      estimatedDeliveryTime,
      nativeDateTimeFormat,
      nativeLanguageCode
    );

    let deliveryTime = "";

    if (estimatedDeliveryTime) {
      const timeOfDay = getTimeOfDay(
        intl,
        estimatedDeliveryTime,
        estimatedDeliveryTimeDisplayType,
        is12HourTimeFormat
      );
      deliveryTime = getDeliveryTime(
        intl,
        timeOfDay,
        estimatedDeliveryTimeDisplayType
      );
    }

    return {
      deliveryDate,
      deliveryTime,
    };
  };

  const { deliveryDate, deliveryTime } = getOfferTimes();

  return (
    <React.Fragment>
      <EstimatedDeliveryContainer
        data-testid={`wcc-offer-card-estimated-delivery-container-${offerIndex}`}
      >
        <EstimatedDeliveryContainerText
          size={TEXT_SIZES.LARGE}
          weight={700}
          isParagraph
          dataTestid={`wcc-offer-card-estimated-delivery-date-${offerIndex}`}
        >
          {deliveryDate}
        </EstimatedDeliveryContainerText>
        <EstimatedDeliveryContainerText
          weight={700}
          dataTestid={`wcc-offer-card-estimated-delivery-guarantee-${offerIndex}`}
          isParagraph
        >
          {deliveryTime}
        </EstimatedDeliveryContainerText>
        <WCCOfferAdditionalTimings
          intl={intl}
          offerIndex={offerIndex}
          metadataByPurchaseMethod={metadataByPurchaseMethod}
          offerId={offerId}
          useFallback
          isMultiBU={false}
        />
      </EstimatedDeliveryContainer>
    </React.Fragment>
  );
}

WCCOfferDeliveryInfo.propTypes = {
  /** Internationalization library */
  intl: IntlPropType.isRequired,
  offerIndex: PropTypes.number,
  estimatedDeliveryTime: PropTypes.instanceOf(Date),
  nativeDateTimeFormat: PropTypes.string,
  estimatedDeliveryTimeDisplayType: PropTypes.string,
  is12HourTimeFormat: PropTypes.bool.isRequired,
  nativeLanguageCode: PropTypes.string,
  metadataByPurchaseMethod: PropTypes.object,
  offerId: PropTypes.string,
};
