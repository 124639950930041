// 9fbef606107a605d69c0edbcd8029e5d

import { createSelector } from "reselect";


const microFrontendIdSelector = (state) => state?.microFrontend;

const selectMicroFrontendId = createSelector(
  microFrontendIdSelector,
  (microFrontend) => microFrontend?.id
);

export { microFrontendIdSelector, selectMicroFrontendId };
