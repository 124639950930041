// 9fbef606107a605d69c0edbcd8029e5d

import brandPalette from "../brandPalette";
import colors from "../../variables/colors";

export default {
  primary: brandPalette.dhlRed,
  secondary: brandPalette.postYellow,
  secondaryHover: colors.postYellow,
  secondaryActive: colors.postYellow,

  fontColor: colors.black,
  fontColorLight: colors.greyishBrownEmperor,
  fontColorLighter: colors.greyWarm,
  fontColorInverded: colors.white,

  fontColorHeader: brandPalette.dhlRed,
  fontColorInverted: colors.white,
  errorColor: brandPalette.dhlRed,
  warningColor: colors.silverDark,
  warningBackgroundColor: colors.lightestGray,
  successColor: colors.greenJungle,

  globalBorderColor: colors.greyWarm,
  focusBorderColor: brandPalette.dhlBlack,
  inputFieldBorderColor: colors.silver,

  globalHighlightBackgroundColor: colors.alabasterGrey8,
  headerColor: colors.black,
  darkBorderColor: colors.black,
  lightBorderColor: colors.whiteTwoAlto,
  lighterBorderColor: colors.whiteGallery,
  backgroundColorInverted: colors.white,

  primaryHover: colors.redCherryHover,
  successHover: colors.greenEmeraldHover,

  shadowLightDefault: colors.whiteTwoAlto, // use with: box-shadow: 0 3px 8px 0
  shadowLightHover: colors.shadowLight100, // use with: box-shadow: 0 2px 7px 1px
  shadowDarkDefault: colors.shadowLight, // use with: box-shadow: 0 1px 4px 0
  shadowDarkFocus: colors.shadowDark, // use with: box-shadow: 0 3px 8px 0 or 0 6px 12px 1px
  shadowDarkHover: colors.greySilverChalice, // use with: box-shadow: 0 2px 7px 1px

  transparent: "transparent",
};
