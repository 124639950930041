// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for WCCOfferDeliveryInfo
 *
 */

import styled from "styled-components";
import { Text } from "@dhl-official/react-ui-library";
import { dhlVarColors } from "root/libs/ui-styleguide/src";

export const EstimatedDeliveryContainer = styled.div`
  box-shadow: 0 3px 8px 0 ${dhlVarColors.shadowLightDefault};
  padding: 20px;
`;

export const EstimatedDeliveryContainerText = styled(Text)`
  margin-bottom: 10px;
`;
