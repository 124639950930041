// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * ErrorPage
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";
import { getPageUrl, trackErrorPageLoad } from "root/libs/core-libs/src";
import { UTF_EVENT_PAGE_NAMES, UTF_EVENT_INTERACTIONS } from "root/libs/constants/src";
import { getTheme } from "root/libs/ui-styleguide/src";
import { TEXT_SIZES, TEXT_WEIGHTS } from "@dhl-official/react-ui-library";
import Link from "../../Buttons/Link";
import {
  PageWrapper,
  IllustrationWrapper,
  ContentWrapper,
  StyledHeadline,
  StyledSubline,
  StyledText,
  LinkWrapper,
} from "./styled";

export default class ErrorPage extends React.PureComponent {
  static propTypes = {
    trackEvent: PropTypes.func.isRequired,
    pageName: PropTypes.string.isRequired,
  };

  componentDidMount() {
    const { trackEvent } = this.props;
    const { ERROR_PAGE } = UTF_EVENT_PAGE_NAMES;
    const { ERROR } = UTF_EVENT_INTERACTIONS;

    trackErrorPageLoad({
      page: {
        name: ERROR_PAGE,
        category: ERROR,
      },
    })

    trackEvent({ systemErrorPageShown: true });
  }

  render() {
    const theme = getTheme(this.props);
    return (
      <ThemeProvider theme={theme}>
        <PageWrapper>
          <IllustrationWrapper>
            <svg
              aria-hidden="true"
              role="presentation"
              viewBox="0 0 406 443"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <defs>
                <linearGradient
                  x1="102%"
                  y1="90.741%"
                  x2="0%"
                  y2="10.058%"
                  id="a"
                >
                  <stop offset="0%" />
                  <stop stopColor="#4E3B68" offset="100%" />
                </linearGradient>
                <linearGradient x1="102%" y1="101%" x2="0%" y2="0%" id="b">
                  <stop stopColor="#161422" offset="0%" />
                  <stop stopColor="#9778C0" offset="100%" />
                </linearGradient>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="c">
                  <stop stopColor="#FF4949" offset="0%" />
                  <stop stopColor="#860000" offset="100%" />
                </linearGradient>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="d">
                  <stop stopColor="#BC99E8" offset="0%" />
                  <stop stopColor="#5B4B73" offset="100%" />
                </linearGradient>
                <linearGradient
                  x1="25.573%"
                  y1="21.766%"
                  x2="50%"
                  y2="66.001%"
                  id="f"
                >
                  <stop stopColor="#EDEDED" offset="0%" />
                  <stop stopColor="#FFF" offset="100%" />
                </linearGradient>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="i">
                  <stop stopColor="#EFEFEB" offset="0%" />
                  <stop stopColor="#FFF" offset="100%" />
                </linearGradient>
                <linearGradient
                  x1="59.207%"
                  y1="46.838%"
                  x2="41.999%"
                  y2="79.535%"
                  id="j"
                >
                  <stop stopColor="#E0E0DF" offset="0%" />
                  <stop stopColor="#BFBEBC" offset="100%" />
                </linearGradient>
                <linearGradient
                  x1="54.865%"
                  y1="50.531%"
                  x2="45.083%"
                  y2="33.524%"
                  id="l"
                >
                  <stop stopColor="#A3AEA4" offset="0%" />
                  <stop stopColor="#88918C" offset="100%" />
                </linearGradient>
                <linearGradient
                  x1="37.339%"
                  y1="27.871%"
                  x2="68.499%"
                  y2="50%"
                  id="n"
                >
                  <stop stopColor="#FFF0B0" offset="0%" />
                  <stop stopColor="#FFCF00" offset="100%" />
                </linearGradient>
                <linearGradient
                  x1="51.61%"
                  y1="74.303%"
                  x2="47.661%"
                  y2="64.623%"
                  id="o"
                >
                  <stop stopColor="#FFC632" offset="0%" />
                  <stop stopColor="#FFB300" offset="100%" />
                </linearGradient>
                <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="q">
                  <stop stopColor="#FFC900" offset="0%" />
                  <stop stopColor="#FFB300" offset="100%" />
                </linearGradient>
                <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id="r">
                  <stop stopColor="#FA000A" offset="0%" />
                  <stop stopColor="#AE000F" offset="100%" />
                </linearGradient>
                <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id="t">
                  <stop stopColor="#C8C8C8" offset="0%" />
                  <stop stopColor="#979797" offset="100%" />
                </linearGradient>
                <linearGradient x1="100%" y1="50%" x2="0%" y2="50%" id="w">
                  <stop stopColor="#FFE16C" offset="0%" />
                  <stop stopColor="#FFC900" offset="100%" />
                </linearGradient>
                <linearGradient
                  x1="74.514%"
                  y1="34.678%"
                  x2="61.446%"
                  y2="43.922%"
                  id="z"
                >
                  <stop stopColor="#894B0A" offset="0%" />
                  <stop stopColor="#E79200" offset="100%" />
                </linearGradient>
                <linearGradient
                  x1="51.561%"
                  y1="1.834%"
                  x2="51.561%"
                  y2="62.579%"
                  id="D"
                >
                  <stop stopColor="#E4E3E0" offset="0%" />
                  <stop stopColor="#FEFEF9" offset="100%" />
                </linearGradient>
                <linearGradient
                  x1="58.118%"
                  y1="50%"
                  x2="45.5%"
                  y2="43.737%"
                  id="G"
                >
                  <stop stopColor="#B4BCB6" offset="0%" />
                  <stop stopColor="#7D877F" offset="100%" />
                </linearGradient>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="I">
                  <stop stopColor="#C2C2C0" offset="0%" />
                  <stop stopColor="#DFDFDF" offset="100%" />
                </linearGradient>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="K">
                  <stop stopColor="#F4F4F4" offset="0%" />
                  <stop stopColor="#E5E5E5" offset="100%" />
                </linearGradient>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="L">
                  <stop stopColor="#C8C8C8" offset="0%" />
                  <stop stopColor="#979797" offset="100%" />
                </linearGradient>
                <linearGradient x1="100%" y1="50%" x2="1.483%" y2="50%" id="M">
                  <stop stopColor="#818181" offset="0%" />
                  <stop stopColor="#B1B1B1" offset="100%" />
                </linearGradient>
                <path
                  d="M15.92 9c-2.959-1.366-5.192-1.708-6.699-1.025C6.961 9 3.448 10.91 3.448 12.838c0 1.927-.047 3.677-.748 4.67C2 18.5-1.056 22.332.472 24.04 1.491 25.18 3.333 27.667 6 31.5c12.925-3.582 19.388-6.068 19.388-7.46 0-1.39.295-3.237.886-5.54L15.92 9z"
                  id="e"
                />
                <path
                  d="M14.07 27.67c13.4-.197 21.777.551 25.13 2.245 3.353 1.693 6.001 4.533 7.946 8.52 1.765 11.625 1.765 27.105 0 46.438-2.648 29-3.141 43.643-4.694 46.5-1.552 2.857-9.694 6.967-13.347 6.967-2.436 0-6.781.546-13.036 1.637L0 62.598 14.07 27.67z"
                  id="h"
                />
                <path
                  d="M34.084 17.554C31.621 33.27 28.882 45.751 25.865 55 21.34 68.873 12.51 83.584 10.827 94.295c-1.122 7.14-1.815 15.29-2.077 24.445-4.833 2.92-7.5 5.643-8 8.167S0 131.88 0 134.25c2.865 2.346 6.786 3.52 11.763 3.52 4.976 0 13.886-.34 26.731-1.02.52-5.1.52-9.146 0-12.14-.779-4.491-.98-15.449 2.19-22.882 3.169-7.433 9.566-15.754 12.566-15.754s3 5.71 3 12.578c0 6.87-1.137 12.327 0 18.694a111.405 111.405 0 011.528 12.461c-5.676 1.466-8.85 3.379-9.52 5.738a566.61 566.61 0 00-2.008 7.237c2.402 2.616 8.414 3.924 18.037 3.924s19.071-.49 28.346-1.468c-.508-6.357-1.07-13.57-1.684-21.64-.923-12.102-.802-21.525 0-33.663.801-12.138 4.338-36.458 3.011-48.154C93.075 33.883 89.127 19.989 82.116 0L34.084 17.554z"
                  id="m"
                />
                <path
                  d="M107.088 286.016c89.312-44.438 135.644-65.81 138.997-64.116 3.353 1.693-8.537-62.935-35.67-193.884 1.765 11.626 1.765 27.105 0 46.438-2.647 29-5.152 44.691-6.704 47.549-1.553 2.857-10.939 6.798-14.592 6.798-2.436 0-5.696.252-9.78.758L36.594 124.93l70.494 161.085z"
                  id="s"
                />
                <path
                  d="M.75 43.5c10.333-3 21.667-7.167 34-12.5 18.5-8 41.24-32.882 44.24-29.882 3 3 1.76 24.882-21.24 42.382-15.333 11.667-33.333 19.667-54 24-1-5.667-2-10.333-3-14s-1-7 0-10z"
                  id="v"
                />
                <path
                  d="M16.2 88.388l.5-.466c9.708-.162 18.484-1.055 26.327-2.679 7.844-1.624 14.946-8.226 21.306-19.806a174.046 174.046 0 01-1.938-10.363c-.573-3.645.807-7.878 4.14-12.698 0-1.234-.309-2.874-.928-4.92-.618-2.045-2.77-7.589-6.454-16.631-9.153-4.558-18.07-7.254-26.752-8.088-8.682-.835-19.482-.835-32.401 0l.046-.09C8.216 4.829 19.297.026 31.5.026c25.129 0 45.5 20.37 45.5 45.5 0 25.129-20.371 45.5-45.5 45.5-5.368 0-10.52-.93-15.3-2.637z"
                  id="C"
                />
                <path
                  d="M9.006 3.634C12.416 1.21 15.368 0 17.859 0c2.49 0 6.07 1.026 10.736 3.079 4 3.24 6 6.381 6 9.421 0 3.04-.333 7.706-1 14-5.124 4.748-9.764 7.453-13.922 8.113-4.158.661-8.984-.908-14.479-4.706C.98 23.21-.456 18.25.889 15.03c2.017-4.83 7.18-6.011 9.558-7.21-2.93-1.684-3.41-3.08-1.441-4.185z"
                  id="H"
                />
                <radialGradient
                  cx="50%"
                  cy="50%"
                  fx="50%"
                  fy="50%"
                  r="48.163%"
                  id="y"
                >
                  <stop stopColor="#EEFBFF" offset="0%" />
                  <stop stopColor="#AEE7FC" offset="86.444%" />
                  <stop stopColor="#89DEFA" offset="100%" />
                </radialGradient>
                <radialGradient
                  cx="79.758%"
                  cy="30.842%"
                  fx="79.758%"
                  fy="30.842%"
                  r="53.569%"
                  gradientTransform="scale(1 .84615) rotate(85.443 .767 .336)"
                  id="E"
                >
                  <stop stopColor="#FFF" offset="0%" />
                  <stop stopColor="#FFF" stopOpacity="0" offset="100%" />
                </radialGradient>
                <filter
                  x="0%"
                  y="0%"
                  width="100%"
                  height="100%"
                  filterUnits="objectBoundingBox"
                  id="A"
                >
                  <feGaussianBlur in="SourceGraphic" />
                </filter>
                <filter
                  x="0%"
                  y="0%"
                  width="100%"
                  height="100%"
                  filterUnits="objectBoundingBox"
                  id="B"
                >
                  <feGaussianBlur in="SourceGraphic" />
                </filter>
              </defs>
              <g fill="none" fillRule="evenodd">
                <g transform="translate(0 36)">
                  <path
                    d="M213 345c106.591 0 193-55.409 193-152C406 96.409 319.591 0 213 0S20 96.409 20 193s86.409 152 193 152z"
                    fill="url(#a)"
                  />
                  <circle fill="url(#b)" cx="56" cy="165" r="56" />
                  <circle fill="url(#c)" cx="213" cy="250" r="157" />
                  <path
                    d="M121.605 91.307l.79-.614c4.537 5.834 8.643 7.73 12.381 5.86 2.095-1.047 3.962-3.075 6.826-7.01-.1.136 1.627-2.255 2.093-2.882.783-1.054 1.427-1.863 2.041-2.546 1.55-1.724 2.895-2.615 4.264-2.615 2.299 0 5.289 1.292 7.33 3.077 2.301 2.014 2.908 4.245.97 5.698l-.6-.8c1.28-.96.864-2.489-1.03-4.146-1.868-1.635-4.632-2.829-6.67-2.829-1.836 0-2.894 1.177-7.59 7.63-2.959 4.067-4.898 6.173-7.186 7.317-4.262 2.13-8.823.026-13.619-6.14z"
                    fill="#9B7FC1"
                    fillRule="nonzero"
                  />
                  <path
                    d="M7.043 22.085C4.348 22.978 3 24.319 3 26.105c0 2.681 19.543 4.022 31.674 3.352 8.087-.447 11.681-2.234 10.783-5.362H49.5v-5.361h-4.043L47.478 6h-6.065l-4.717 11.814h-12.13c-1.798-4.302-4.943-6.452-9.436-6.452-4.492 0-7.188 3.574-8.087 10.723z"
                    fill="url(#d)"
                    transform="rotate(-14 331.068 -300.938)"
                  />
                  <path
                    fill="#A385CA"
                    d="M287 48.326l-2.939 2.72 1.678-3.636-3.494-1.955 3.976.472L287 42l.78 3.927 3.975-.472-3.494 1.955 1.678 3.635z"
                  />
                  <path
                    fill="#A385CA"
                    opacity=".5"
                    d="M331 87.326l-2.939 2.72 1.678-3.636-3.494-1.955 3.976.472L331 81l.78 3.927 3.975-.472-3.494 1.955 1.678 3.635z"
                  />
                  <path
                    fill="#DFC7FF"
                    d="M239 68.326l-2.939 2.72 1.678-3.636-3.494-1.955 3.976.472L239 62l.78 3.927 3.975-.472-3.494 1.955 1.678 3.635z"
                  />
                </g>
                <g transform="rotate(19 -481.735 854.367)">
                  <path
                    d="M27.334.43L14.59 9l11.684 9.5L27.334.43z"
                    fill="#FFCA00"
                  />
                  <mask id="g" fill="#fff">
                    <use xlinkHref="#e" />
                  </mask>
                  <use fill="url(#f)" xlinkHref="#e" />
                  <path
                    d="M20.962 11c.258 2.33-.74 3.814-2.992 4.454-3.38.96-4.775-.236-6.372 1.939C10 19.567 10.442 24.03 9.22 24.03 8 24.03 5.04 27.5 6 31.5c.64 2.667 7.398 3.211 20.274 1.633l4.691-11.883-3.63-7.807L20.961 11z"
                    fill="#E6E6E6"
                    mask="url(#g)"
                  />
                </g>
                <g transform="rotate(19 -295.965 1081.821)">
                  <path
                    d="M36.701 4.464a2 2 0 11.936.173l-2.415 25.736-1.924-.458L36.7 4.464z"
                    fill="#929E98"
                  />
                  <mask id="k" fill="#fff">
                    <use xlinkHref="#h" />
                  </mask>
                  <use fill="url(#i)" xlinkHref="#h" />
                  <path
                    d="M40.853 26c.993 26.332.993 44.614 0 54.848-1.49 15.35-1.815 27.553-6.784 33.025-3.313 3.648-9.466 8.268-18.46 13.86l-4.54 18.9h29.784l31.619-34.803-17.043-76.957L40.853 26z"
                    fill="url(#j)"
                    mask="url(#k)"
                  />
                  <path
                    d="M21.07 38.434l11.527 2.24c.934 20.466.934 38.282 0 53.449-1.4 22.75-1.571 33.51-3.492 35.38-1.281 1.247-6.134 2.408-14.559 3.484l-10.9-70.852 17.423-23.7z"
                    fill="url(#l)"
                    opacity=".9"
                    mask="url(#k)"
                  />
                </g>
                <g transform="rotate(19 -436.53 841.2)">
                  <mask id="p" fill="#fff">
                    <use xlinkHref="#m" />
                  </mask>
                  <use fill="url(#n)" xlinkHref="#m" />
                  <path
                    d="M76.447 8.07c7.291 18.537 10.059 33.393 8.303 44.569-2.634 16.763-8.303 41.418-8.303 57.39 0 10.647.758 23.647 2.274 39h17.41V67.343c0-9.803-.625-25.636-1.876-47.5L86.289-.802 76.447 8.07z"
                    fill="url(#o)"
                    mask="url(#p)"
                  />
                  <path
                    d="M55.258 87.76c-1.508-4.198-9.008-4.628-16.424 2.699-5.544 5.477-9.74 15.532-9.74 26.44 0 8.912.42 17.112 1.26 24.601l11.51-2.44c9.935-31.402 14.4-48.502 13.394-51.3z"
                    fill="url(#q)"
                    mask="url(#p)"
                  />
                  <path
                    d="M-1.818 130.235c3.811 2.05 8.018 3.075 12.62 3.075h30.12l-1.422 6.248H3.974c-5.117-3.588-7.675-5.67-7.675-6.248 0-.578.628-1.603 1.883-3.075zM46.633 138c4.143 3.108 8.515 4.662 13.117 4.662 3.667 0 14.112.491 26.54 0 2.113-.084 4.768-.471 7.965-1.162l.926 5.823H52.426c-5.117-3.587-7.676-5.67-7.676-6.248 0-.577.628-1.602 1.883-3.075z"
                    fill="#FB0008"
                    mask="url(#p)"
                  />
                  <path
                    d="M4.75 120.913c2.355-1.86 5.386-2.48 9.094-1.86 3.708.62 6.65 1.821 8.826 3.603-1.6-3.391-4.24-5.281-7.92-5.67-3.68-.388-6.56-.194-8.636.584l-1.364 3.343z"
                    fill="#FB0"
                    mask="url(#p)"
                  />
                  <path
                    d="M54.123 132.632c2.355-1.86 5.386-2.48 9.094-1.86 3.708.62 6.65 1.821 8.826 3.604-1.6-3.392-4.24-5.282-7.92-5.67-3.68-.389-6.56-.195-8.636.583l-1.364 3.343z"
                    fill="#FB0"
                    mask="url(#p)"
                    transform="rotate(10 63.083 131.443)"
                  />
                  <path
                    d="M9.45 97.801c5.855.005 10.756.354 14.704 1.045 5.922 1.038 15.603 3.615 17.771 3.615h2.825l-2.13-1.627c-6.11-1.88-11.948-3.293-17.517-4.237-5.569-.945-10.469-1.417-14.7-1.417l-.953 2.621z"
                    fill="#FF151D"
                    mask="url(#p)"
                  />
                  <path
                    d="M53.75 103.628c9.278.86 16.843 1.289 22.697 1.289 5.853 0 10.954.442 15.303 1.327v-2.616c-5.881-.778-10.982-1.167-15.303-1.167-4.321 0-11.551-.617-21.691-1.85l-1.006 3.017z"
                    fill="#F80606"
                    mask="url(#p)"
                  />
                  <path
                    d="M94.255 72c-22.338.785-36.811.785-43.42 0-6.608-.785-16.44-3.168-29.495-7.15L22.67 58c12.37 2.012 21.643 3.018 27.818 3.018 6.175 0 20.929-.32 44.262-.962L94.255 72z"
                    fill="url(#r)"
                    mask="url(#p)"
                  />
                </g>
                <g transform="translate(121.971 149.614)">
                  <path
                    d="M194.167 137.397l4.666-1.794c6.838 17.777 8.558 33.948 5.099 48.476-3.001 12.606-19.96 32.927-37.311 42.9-22.955 13.196-44.224 8.758-58.329-17.807-20.257-38.15-27.034-73.73-20.24-106.677l4.896 1.01c-6.54 31.719.017 66.14 19.76 103.323 12.694 23.906 30.783 27.68 51.421 15.817 16.23-9.329 32.282-28.565 34.939-39.724 3.208-13.472 1.595-28.634-4.901-45.524z"
                    fill="url(#t)"
                    fillRule="nonzero"
                  />
                </g>
                <path
                  d="M268.056 253.995l-6.247 12.599 17.532 9.165 6.153 1.06 5.952-11.529-3.989-4.118c-3.946-1.969-6.98-3.318-9.103-4.049-2.121-.73-5.554-1.773-10.298-3.128z"
                  fill="#EF000D"
                />
                <path
                  d="M268.786 255.731l-4.766 9.986c2.604 1.663 4.87 3.02 6.8 4.073 1.93 1.052 4.933 2.569 9.01 4.55l6.283-11.636c-2.99-2.123-6.02-3.712-9.088-4.769-3.068-1.056-5.815-1.79-8.239-2.204z"
                  fill="#590412"
                />
                <path
                  d="M270.931 258.84l-2.9 5.63 10.242 5.302 3.177-6.57c-2.169-1.241-3.956-2.102-5.35-2.582l-5.169-1.78z"
                  stroke="#F1000C"
                  strokeWidth="2"
                />
                <g transform="rotate(19 -449.975 838.95)">
                  <mask id="x" fill="#fff">
                    <use xlinkHref="#v" />
                  </mask>
                  <use fill="url(#w)" xlinkHref="#v" />
                  <path
                    d="M81-1.5C72.5 19.167 63.25 32.667 53.25 39c-10 6.333-23.5 12-40.5 17v11.5c24.667-5 41.667-12.167 51-21.5 14-14 18-25.5 18-32.5 0-4.667-.25-9.667-.75-15z"
                    fill="#FFBA00"
                    mask="url(#x)"
                  />
                  <path
                    d="M49.25 22.5c5.333 0 9.167 1.333 11.5 4s3.5 7.5 3.5 14.5l2-2.5c.333-5.667-.833-10-3.5-13s-6.333-4.667-11-5l-2.5 2z"
                    fill="#A12322"
                    mask="url(#x)"
                  />
                </g>
                <g transform="rotate(19 -233.729 877.44)">
                  <circle fill="url(#y)" cx="44" cy="44" r="44" />
                  <path
                    d="M29.89 86.255c-1.707-.656-2.928-3.824 0-6.49 1.95-1.776 5.576-3.825 10.874-6.147l6.489 13.411h-11c-3.105-.079-5.226-.337-6.364-.774zM22.565 16.084c-1.706-.655-10.725-3.271-7.798-5.936C16.717 8.37 20.343 6.322 25.642 4l6.488 13.411h-11c2.094-.447 2.572-.89 1.435-1.327z"
                    fill="#585F7B"
                  />
                  <path
                    d="M40.047 75.58c-4.443 2.018-7.504 3.799-9.148 5.295-1.768 1.61-1.519 3.577-.472 3.98.932.358 2.921.6 5.825.674h8.609l-4.814-9.949zM29.89 86.255c-1.706-.656-2.927-3.824 0-6.49 1.952-1.776 5.577-3.825 10.875-6.147l6.489 13.411h-11c-3.105-.079-5.226-.337-6.364-.774zM24.925 5.962c-4.444 2.018-7.505 3.799-9.149 5.295-.102.093-.08.043-.092-.06-.002-.02.062.082.254.261.568.53 1.67 1.133 3.152 1.752 1.147.478 4.337 1.598 4.013 1.474.527.203.918.436 1.213.83.096.128.17.261.227.397h5.195l-4.813-9.949zm-.497 11.45h-3.14l-.004-.034c1.963-.437 2.39-.868 1.281-1.294a17.484 17.484 0 00-.48-.173h2.458c.208.505.15 1.049-.115 1.5zm-2.513-.1a1.28 1.28 0 01-.052-.075c1.458-.388 1.692-.772.702-1.153a24.243 24.243 0 00-.69-.246c.048-.072.104-.138.166-.196.067-.062.008-.032-.134.017-.085.03-.181.06-.29.09-2.83-.977-9.41-3.27-6.85-5.601C16.717 8.37 20.343 6.322 25.642 4l6.488 13.411H21.997a1.199 1.199 0 01-.082-.099zm-.09.1h-.696l.424-.096.272.095z"
                    fill="#FFF"
                    fillRule="nonzero"
                  />
                </g>
                <g transform="translate(261.526 130.682)">
                  <path
                    d="M24.425 19.91c-5.13 7.223-7.394 12.26-7.441 17.003-.047 4.744-.24 8.379-1.055 10.742-.814 2.364-5.325 10.858-3.969 17.67 1.356 6.813 6.773 18.726 11.47 23.516 3.13 3.193 14.237 3.14 33.32-.16l10.216-5.543 10.516-23.807c3.83-14.193 2.716-24.271-3.339-30.234-9.083-8.945-7.959-10.673-21.049-14.123-13.09-3.45-23.538-2.287-28.669 4.936z"
                    fill="#FED2AD"
                  />
                  <path
                    d="M32.337 62.946c-6.91-3.88-8.91-10.077-4.486-10.33 1.488-.085 4.023.33 5.387-.155.738-.263 2.372-7.28 3.758-8.34.284-.217.376.029.277.737-.719 1.704-.949 4.94-1.949 7.144-.428.943-.864 2.075-1.478 2.39-.732.375-2.882-.52-3.911.137-2.024 1.29-.228 4.48 2.402 8.417z"
                    fill="#DFB994"
                    fillRule="nonzero"
                  />
                  <path
                    d="M24.747 18.6c-.449.766-7.02 1.807-7.42 2.555-2.552 4.782-2.048 11.07 1.513 18.865.67-4.667 1.314-7.619 1.934-8.855.62-1.237.93-1.534.93-.892.996.255 2.014 1.13 3.054 2.628.594.857 5.823-5.749 7.76-.125 1.291 3.75 4.66 5.658 10.106 5.725l-2.053-5.964c3.62.705 8.315.779 14.084.22 5.266-.51 11.345-1.013 18.236-1.508-1.893 2.319-2.458 3.915-1.695 4.786 3.037 3.469 6.608 10.603 10.713 21.403 4.807-9.671 4.246-18.19-1.684-25.557-8.895-11.05-8.206-17.39-19.55-18.516-11.343-1.125-28.797-6.92-35.928 5.235z"
                    fill="url(#z)"
                    transform="rotate(19 50.433 34.219)"
                  />
                  <path
                    d="M17.088 36.326c3.848.66 6.763 1.053 8.744 1.177 2.972.186 2.774.593 4.455-1.781 1.121-1.583 1.121-3.086 0-4.511-1.843 1.346-3.715 2.204-5.615 2.573-1.9.37-4.427.37-7.584 0v2.542zM61.04 43.503c-4.287-1.216-7.44-2.179-9.46-2.887-3.03-1.062-3.233-1.073-4.239-3.503-.67-1.62-.333-3.05 1.011-4.29.595 1.661 1.728 3.168 3.399 4.521 1.67 1.353 5.222 2.633 10.655 3.84l-1.367 2.32z"
                    fill="#8E3A34"
                  />
                  <ellipse
                    fill="#3B3B3B"
                    transform="rotate(2 39.484 72.862)"
                    cx="39.484"
                    cy="72.862"
                    rx="2.5"
                    ry="4"
                  />
                  <ellipse
                    fill="#5F2F03"
                    filter="url(#A)"
                    transform="rotate(6 51.155 50.272)"
                    cx="51.155"
                    cy="50.272"
                    rx="1.5"
                    ry="2.5"
                  />
                  <ellipse
                    fill="#5F2F03"
                    filter="url(#B)"
                    transform="rotate(6 28.585 46.638)"
                    cx="28.585"
                    cy="46.638"
                    rx="1.5"
                    ry="2.5"
                  />
                </g>
                <path
                  d="M277.369 147.18c2.365-1.623 4.788-2.114 6.54-1.061 3.077 1.848 2.92 7.76-.352 13.205-3.27 5.444-8.417 8.358-11.494 6.51-1.325-.797-2.05-2.347-2.186-4.288a40.052 40.052 0 00-4.682 9.397 39.822 39.822 0 00-1.641 19.608c.474 2.824 2.134 6.678 1.66 8.646-5.29-9.564-6.663-21.245-2.824-32.393a39.82 39.82 0 0114.84-19.72l.139.096z"
                  fill="#FFF"
                />
                <g transform="rotate(19 -234.68 920.283)">
                  <mask id="F" fill="#fff">
                    <use xlinkHref="#C" />
                  </mask>
                  <use fill="url(#D)" xlinkHref="#C" />
                  <use fill="url(#E)" xlinkHref="#C" />
                  <path
                    d="M15.326 92.05c3.125.639 6.36.975 9.674.975 26.51 0 48-21.49 48-48C73 24.378 59.964 6.775 41.674 0 63.547 4.476 80 23.829 80 47.025c0 26.51-21.49 48-48 48a47.901 47.901 0 01-16.674-2.976z"
                    fill="#DCDCDA"
                    mask="url(#F)"
                  />
                </g>
                <g transform="rotate(19 -380.333 1093.06)">
                  <path
                    d="M9.581 30.087c3.535.623 7.49-5.044 8.832-12.659 1.343-7.614-.434-14.292-3.97-14.916-2.466-.434-6.842-1.796-8.832 2.234C3.18 9.67 1.68 17.542 1.68 22.541c0 8.196 4.365 6.923 7.9 7.546z"
                    fill="#DCDCDA"
                  />
                  <path
                    d="M3.18 9.36c3.808-3.342 6.232-4.457 7.272-3.342 1.56 1.671 2.036 19.182-.638 20.738-1.783 1.037-4.664.711-8.643-.976C.208 22.123-.16 19.377.064 17.542c.224-1.835 1.263-4.562 3.116-8.181z"
                    fill="#FBFBF6"
                  />
                  <ellipse
                    fill="#DCDCDA"
                    transform="rotate(10 12.012 16.3)"
                    cx="12.012"
                    cy="16.3"
                    rx="6.5"
                    ry="14"
                  />
                  <ellipse
                    fill="url(#G)"
                    transform="rotate(10 12.505 16.387)"
                    cx="12.505"
                    cy="16.387"
                    rx="3"
                    ry="10"
                  />
                </g>
                <g transform="rotate(19 -557.556 729.275)">
                  <mask id="J" fill="#fff">
                    <use xlinkHref="#H" />
                  </mask>
                  <use fill="#FCFCFC" xlinkHref="#H" />
                  <path
                    d="M-.884 24.84c6.895.678 11.199.678 12.911 0 2.568-1.014 1.884-3.178 5.726-3.178s6.642.53 9.742-1.868c2.067-1.599 5.258-3.616 9.572-6.053l-1.196 15.942-16.608 7.893-13.66-1.949L-2 32.195l1.116-7.354z"
                    fill="url(#I)"
                    mask="url(#J)"
                  />
                  <path
                    d="M11.263 11.457L22.21 13.17c-1.075-2.426-2.137-3.887-3.185-4.384-1.048-.497-3.635.394-7.76 2.672z"
                    fill="#CBCBCA"
                    opacity=".6"
                    mask="url(#J)"
                  />
                </g>
                <g transform="translate(164.37 187.568)">
                  <path
                    d="M22.28 2.94C16.09.07 9.994-.699 3.994.634c-9 2 4.5 23 11.5 19 4.666-2.666 7.693-6.788 9.079-12.364L22.28 2.94z"
                    fill="url(#K)"
                  />
                  <path
                    d="M57.673 52.277l-5.346-2.725c8.157-16 8.157-28.026.44-36.635C45.01 4.267 35.78 2.68 24.283 8.126l-2.568-5.423C35.552-3.85 47.656-1.77 57.234 8.912c9.615 10.725 9.615 25.366.439 43.365z"
                    fill="url(#L)"
                    fillRule="nonzero"
                  />
                  <ellipse
                    fill="url(#M)"
                    transform="rotate(-38 8.578 11.303)"
                    cx="8.578"
                    cy="11.303"
                    rx="3.27"
                    ry="8.993"
                  />
                </g>
                <rect fill="#D40511" x="167" width="72" height="72" rx="36" />
                <g fill="#FFF">
                  <path d="M198.77 35.995l-12.892-12.907 4.244-4.24 12.89 12.903L215.88 18.88l4.243 4.242-12.87 12.875 12.87 12.884-4.244 4.24-12.867-12.88-12.876 12.88-4.243-4.24 12.879-12.885z" />
                </g>
              </g>
            </svg>
          </IllustrationWrapper>
          <ContentWrapper>
            <StyledHeadline priority={2} left className="customFontSize">
              We are sorry!
            </StyledHeadline>
            <StyledSubline
              size={TEXT_SIZES.BIG}
              weight={TEXT_WEIGHTS[200]}
              className="customFontSize"
            >
              Unfortunately this service isn&apos;t available at the moment.
            </StyledSubline>
            <StyledText className="customFontSize">
              Please try again later!
            </StyledText>
            <LinkWrapper>
              <Link href={getPageUrl.url} linkType="cta primary">
                Retry
              </Link>
            </LinkWrapper>
          </ContentWrapper>
        </PageWrapper>
      </ThemeProvider>
    );
  }
}
