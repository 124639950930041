// 9fbef606107a605d69c0edbcd8029e5d

/* eslint-disable no-param-reassign */

import { handleActions } from "redux-actions";
import produce from "immer";
import * as actions from "./actions";

const INITIAL_STATE = {
  abTesting: "",
  analyticsUserId: "",
  funnel: "",
  isRegularUser: false,
  storeInitialSetAction: "", // Possible values: set, get, remove or empty
  wannaRunClientStorageAction: false,
  canaryVersion: "",
  experience: "",
};

const clearClientStorageActions = (draft) => {
  draft.analyticsUserId = "";
  draft.storeInitialSetAction = "";
  draft.wannaRunClientStorageAction = false;
};

const setPersonalizationToggles = (draft, { payload }) => {
  draft.abTesting = payload.abTesting;
  draft.analyticsUserId = payload.analyticsUserId;
  draft.funnel = payload.funnel;
  draft.isRegularUser = payload.isRegularUser;
  draft.storeInitialSetAction = payload.storeInitialSetAction;
  draft.canaryVersion = payload.canaryVersion;
  draft.experience = payload.experience;
};

const runClientStorageAction = (draft, { payload }) => {
  draft.wannaRunClientStorageAction = payload;
};

const storeInitialSet = (draft, { payload: { storeUserInitialSet } }) => {
  draft.storeInitialSetAction = storeUserInitialSet ? "set" : "";
  draft.wannaRunClientStorageAction = false;
};

const restoreInitialSet = (draft, { payload: { restoreUserIntialSet } }) => {
  draft.storeInitialSetAction = restoreUserIntialSet ? "get" : "";
  draft.wannaRunClientStorageAction = true;
};

const deleteInitialSet = (draft, { payload: { deleteUserInitialSet } }) => {
  draft.storeInitialSetAction = deleteUserInitialSet ? "remove" : "";
  draft.wannaRunClientStorageAction = true;
};

const toggleRegularShipmentTeaser = (draft, { payload: { isRegularUser } }) => {
  draft.isRegularUser = isRegularUser;
};

export default handleActions(
  {
    [actions.clearClientStorageActions]: produce(clearClientStorageActions),
    [actions.deleteInitialSet]: produce(deleteInitialSet),
    [actions.restoreInitialSet]: produce(restoreInitialSet),
    [actions.runClientStorageAction]: produce(runClientStorageAction),
    [actions.setPersonalizationToggles]: produce(setPersonalizationToggles),
    [actions.storeInitialSet]: produce(storeInitialSet),
    [actions.toggleRegularShipmentTeaser]: produce(toggleRegularShipmentTeaser),
  },
  INITIAL_STATE
);
