// 9fbef606107a605d69c0edbcd8029e5d

/**
 * Why is this here and not in constants?
 * Because the application needs this to start working.
 * And depending on the locale, the specific features will be confirugred
 * for it.
 *
 */

module.exports = {
  default: "en-IT",
  country: "IT",
  language: "en",
  pilotLocales: [
    // Default locale
    "en",
    // real pilot countries
    "en-US",
    "en-AU",
    "en-IT",
    "it-IT",
    "en-MX",
    "es-MX",
  ],
  rtlSupport: ["ar-EG", "he-IL", "ar-SA", "ar-LB"],
};
