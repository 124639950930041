// 9fbef606107a605d69c0edbcd8029e5d

import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import successIcon from "icons/cx/success.svg";
import { brandPalette } from "root/libs/ui-styleguide/src";
import { ContentModal, Headline, Icon } from "root/libs/ui-components/src";

import { ContentFreightP2PModal, TextInfo } from "./styled";

const COPY_ID_PREFIX = "ModalSuccess";

const ModalSuccess = ({ intl, onClose, isOpen }) => {
  return (
    <ContentModal
      closeButtonAriaLabel={intl.formatMessage({ id: "SS.ContentModal.close" })}
      contentAriaLabel={`${intl.formatMessage({
        id: `${COPY_ID_PREFIX}.subheadingFreightP2PModal`,
      })}`}
      isOpen={isOpen}
      onClose={onClose}
      smallCenteredModal
    >
      <ContentFreightP2PModal>
        <div>
          <Icon
            icon={successIcon}
            useAs="decorative"
            fillColor={brandPalette.successColor}
            width={{
              mobile: "52px",
              desktop: "72px",
            }}
          />
        </div>
        <div>
          <Headline
            priority={2}
            weight="800"
            dataTestid="success-modal-headline"
          >
            {intl.formatMessage({
              id: `${COPY_ID_PREFIX}.headlineFreightP2PModal`,
            })}
          </Headline>
          <TextInfo isParagraph className="subheading" doubleBottomSpacing>
            {intl.formatMessage({
              id: `${COPY_ID_PREFIX}.subheadingFreightP2PModal`,
            })}
          </TextInfo>
          <TextInfo isParagraph>
            {intl.formatMessage({
              id: `${COPY_ID_PREFIX}.nextStepExplanationFreightP2PModal`,
            })}
          </TextInfo>
        </div>
      </ContentFreightP2PModal>
    </ContentModal>
  );
};

ModalSuccess.propTypes = {
  intl: PropTypes.any.isRequired,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
};

ModalSuccess.defaultProps = {
  onClose: () => {},
};

export default injectIntl(ModalSuccess);
