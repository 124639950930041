// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for TextAndIcon
 *
 */

import styled from "styled-components";
import { Text } from "@dhl-official/react-ui-library";

export const TextAndIconWrapper = styled.span`
  display: ${(props) => (props.inlined ? "inline-flex" : "flex")};
  align-items: center;
  cursor: pointer;
  position: relative;
  ${(props) => `margin-left: ${props.leftMargin};`}
  ${(props) => `margin-bottom: ${props.bottomMargin};`}
  ${(props) => (props.alignRight ? "justify-content: flex-end;" : "")}

  .icon {
    min-width: ${(props) => (props.hasTooltip ? "40px" : "auto")};
    justify-content: center;
  }
`;

export const SpanStyled = styled(Text)`
  padding-right: 10px;
  padding-top: 2px;
  line-height: 25px;
  position: relative;
`;
