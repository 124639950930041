// 9fbef606107a605d69c0edbcd8029e5d

export const CLEAR_CLIENT_STORAGE_ACTIONS =
  "app/Personalization/CLEAR_CLIENT_STORAGE_ACTIONS";
export const DELETE_USER_INITIAL_SET =
  "app/Personalization/DELETE_USER_INITIAL_SET";
export const RESTORE_USER_INITIAL_SET =
  "app/Personalization/RESTORE_USER_INITIAL_SET";
export const RUN_CLIENT_STORAGE_ACTION =
  "app/Personalization/CLIENT_STORAGE_ACTION_RUN";
export const SET_PERSONALIZATION_TOGGLES =
  "app/Personalization/SET_PERSONALIZATION_TOGGLES";
export const STORE_USER_INITIAL_SET =
  "app/Personalization/STORE_USER_INITIAL_SET";
export const TOGGLE_REGULAR_SHIPMENT_TEASER =
  "app/Personalization/TOGGLE_REGULAR_SHIPMENT_TEASER";
