// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * ShareButton
 *
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "@dhl-official/react-ui-library";
import { injectIntl } from "react-intl";
import { IntlPropType, trackContentInteraction } from "root/libs/core-libs/src";

const COPY_ID_PREFIX = "ShareButton";

const ShareButton = injectIntl(
  ({
    intl,
    trackEvent,
    trackingValue,
    dataTestid,
    dataTracking,
    shareUrl,
    utfDataObject,
  }) => {
    const [clickedButton, setClickedButton] = useState(false);

    const createMailtoLink = (_shareUrl) => {
      return `mailto:?subject=${encodeURIComponent(
        intl.formatMessage({ id: `${COPY_ID_PREFIX}.mailSubject` })
      )}&body=${encodeURIComponent(
        intl.formatMessage(
          { id: `${COPY_ID_PREFIX}.mailSubject` },
          { nl: "\n" }
        )
      )}%0D%0A%0D%0A${_shareUrl}`;
    };

    const onClick = () => {
      setClickedButton(true);
      trackEvent(trackingValue);
      trackContentInteraction(utfDataObject);
    };

    const mailtoLink = createMailtoLink(shareUrl.replace("=", "%3D"));

    return (
      <div>
        <Link
          withButtonStyle={{
            isBlock: false,
            size: "regular",
            variant: "secondary",
          }}
          href={mailtoLink}
          dataTestid={dataTestid}
          onClick={onClick}
          clickedButton={clickedButton}
          dataTracking={dataTracking}
          target="_blank"
        >
          {intl.formatMessage({ id: `${COPY_ID_PREFIX}.label` })}
        </Link>
      </div>
    );
  }
);

ShareButton.propTypes = {
  intl: IntlPropType.isRequired,
  dataTestid: PropTypes.string,
  dataTracking: PropTypes.string,
  trackEvent: PropTypes.func,
  trackingValue: PropTypes.object,
  shareUrl: PropTypes.string.isRequired,
  fallBackDataTracking: PropTypes.string,
  fallBackTrackingValue: PropTypes.object,
  utfDataObject: PropTypes.object,
};

ShareButton.defaultProps = {
  dataTestid: undefined,
  dataTracking: "",
  trackEvent: () => {},
  trackingValue: {},
  fallBackDataTracking: "",
  fallBackTrackingValue: {},
  utfDataObject: {},
};

export default ShareButton;
