// 9fbef606107a605d69c0edbcd8029e5d

import { key } from "styled-theme";
import styled, { css } from "styled-components";
import { breakpoints, focusedState } from "root/libs/ui-styleguide/src";

export const StyledQuantityField = styled.div`
  /* Number input fields should not change order of buttons */
  /* We force ltr here and add the noflip rule to maintain fixed... */
  /*! @noflip */
  direction: ltr;
  ${(props) =>
    props.marginBottom ? `margin-bottom: ${props.marginBottom};` : ""};
`;

export const StyledButtonWrapper = styled.div`
  position: relative;
`;

export const StyledWrapper = styled.div`
  /* ...but here we define direction again without noflip rule so it gets mirrored */
  direction: ltr;
  border: 1px solid
    ${(props) => (props.cssDisabled ? "transparent" : key(props.borderColor))};
  ${focusedState.focusWithinAllBorders};
  position: relative;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) =>
    props.cssDisabled ? key("colors.shadowLightDefault") : "transparent"};
  padding: 16px 30px 12px 30px;

  label {
    order: 1;
    padding-top: 10px;
    padding-bottom: 4px;
    line-height: 1;
  }

  input {
    .dhl-cj-main & {
      line-height: 17px;
    }

    order: 2;
    padding: 5px 10px 5px 10px;
    border-color: transparent;
    width: ${(props) => (props.preventTextAndIconOverlap ? "90%" : "100%")};
    text-overflow: ellipsis;
    text-align: center;
    font-weight: bold;

    &:focus {
      border-color: transparent;
    }
  }

  @supports (display: grid) {
    grid-column: 1 / span 2;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    @supports (display: grid) {
      grid-column: unset;
    }
  }
`;

export const numberButton = css`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 1px;
  width: 40px;
  height: 100%;
  line-height: 54px;
  font-size: 28px;
  background-color: transparent;
  text-align: center;
  cursor: pointer;
  user-select: none;
  padding: 0;
  border: 1px solid transparent;
  z-index: 1;
  outline: 1px solid transparent;
  border-color: transparent;
  box-sizing: border-box;
  ${focusedState.focusAllBorders};
`;

export const StyledMinusButton = styled.button`
  ${numberButton}
  left: 1px;
  margin: -1px 0 -1px -1px;
  border-radius: 4px 0 0 4px;
`;

export const StyledPlusButton = styled.button`
  ${numberButton}
  border-radius: 0 4px 4px 0;
  margin: -1px -1px -1px 0;
  right: 1px;
`;
