// 9fbef606107a605d69c0edbcd8029e5d

/**
 * OfferLaneDatePicker
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import globalConfiguration from "one-time-shipment/src/config/globalConfiguration";
import format from "date-fns/format";
import addDays from "date-fns/addDays";
import differenceInDays from "date-fns/differenceInDays";
import {
  DatePicker,
  INPUTFIELD_VALIDATION_TYPES,
} from "@dhl-official/react-ui-library";
import { NavigatorLib } from "root/libs/core-libs/src";

const OfferLaneDatePicker = ({
  getCopy,
  language,
  shippingDate,
  trackEvent,
  updateShippingDate,
}) => {
  const [shipmentDateValue, setShipmentDateValue] = useState(shippingDate);
  const [shipmentDateValidation, setShipmentDateValidation] = useState({
    type: INPUTFIELD_VALIDATION_TYPES.VALID,
    message: "",
  });

  const maxShipmentDate = addDays(
    new Date(),
    globalConfiguration.shipmentDayRange
  );

  const validateShipmentDate = (selectedDate) => {
    const dateDiff =
      differenceInDays(
        new Date(selectedDate),
        new Date(format(new Date(), "yyyy-MM-dd"))
      ) * 1;
    if (dateDiff <= globalConfiguration.shipmentDayRange && dateDiff >= 0) {
      setShipmentDateValidation({
        type: INPUTFIELD_VALIDATION_TYPES.VALID,
        message: "",
      });
      return true;
    }
    setShipmentDateValidation({
      type: INPUTFIELD_VALIDATION_TYPES.INVALID,
      message: getCopy(
        "daterangeOverflow",
        { days: globalConfiguration.shipmentDayRange },
        "SS.validations"
      ),
    });
    return false;
  };

  const onChangeShippingDate = (calendarObj) => {
    const shippingDateLocal = format(calendarObj.$d, "yyyy-MM-dd");

    setShipmentDateValue(shippingDateLocal);

    if (validateShipmentDate(shippingDateLocal)) {
      trackEvent({ shipmentDateChanged: shippingDateLocal });
      updateShippingDate({ shippingDate: shippingDateLocal });
    }
  };

  return (
    <DatePicker
      isBlock
      className="date-picker"
      date={shipmentDateValue}
      minDate={new Date()}
      maxDate={maxShipmentDate}
      id="date-picker"
      locale={NavigatorLib.overrideLocale(language)}
      displayFormat="DD MMM YYYY"
      onChange={onChangeShippingDate}
      variant={{
        label: getCopy("shippingDate"),
        type: "static",
      }}
      validation={shipmentDateValidation}
    />
  );
};

OfferLaneDatePicker.propTypes = {
  getCopy: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  shippingDate: PropTypes.string.isRequired,
  trackEvent: PropTypes.func.isRequired,
  updateShippingDate: PropTypes.func.isRequired,
};

export default injectIntl(OfferLaneDatePicker);
