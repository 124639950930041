// 9fbef606107a605d69c0edbcd8029e5d

import styled, { css } from "styled-components";
import { key } from "styled-theme";
import {
  colors,
  breakpoints,
  focusedState,
  focusedFormControlStyle,
} from "root/libs/ui-styleguide/src";
import Label from "../../Texts/Label";
import Icon from "../../Icons/Icon";

export const StyledRadioWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  ${(props) => (props.cssZIndex ? `z-index: ${props.cssZIndex};` : "0")};
  ${(props) => props.extraStyles};
`;

export const StyledInput = styled.input`
  display: none;

  & + label::after {
    content: none;
  }

  &:checked + label::after {
    content: "";
    border: ${focusedFormControlStyle};
  }

  &:checked + label::before,
  &:checked + label + .toggleIcon {
    opacity: 1;
  }
`;

const radioLabelCircle = css`
  margin: 2.5px 0;
  border: 2px solid transparent;
  padding: 2px 20px 2px 10px;
  margin-left: -10px;
  ${focusedState.noOutline};

  &::after {
    line-height: 22px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    border: 1px solid ${key("colors.focusBorderColor")};
    display: inline-flex;
    justify-content: center;
    left: 14px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    background: ${key("colors.focusBorderColor")};
  }

  &:focus,
  &:active {
    border: ${focusedFormControlStyle};
    margin: 0;
  }

  &::before {
    content: "";
    font-size: ${key("fonts.desktop.copy.fontSize")};
    color: ${key("colors.fontColor")};
    line-height: 22px;
    width: 21px;
    height: 21px;
    border-radius: 20px;

    /* Do not use here border.
      If you do when you change the styles on hover or focus
      will trigger a bug in FF that will make the
      size of the preset icon to increase forever
    */
    box-shadow: 0 0 0 1px ${key("colors.globalBorderColor")} inset;
    justify-content: center;
    margin-right: 8px;
  }

  &:hover,
  &:focus {
    margin-left: -10px;

    &::before {
      outline: 0;
      border-color: transparent;
      box-shadow: 0 0 0 2px ${colors.focusBorderColor} inset;
    }
  }
`;

const radioLabelCard = css`
  position: relative;
  transition: box-shadow 0.3s ease-out;
  width: 100%;
  border-radius: 4px;
  min-height: 110px;
  align-items: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    height: 75px;
    min-height: 75px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid ${key("colors.primary")};
    border-radius: 4px;
    opacity: 0;
    transition: opacity 0.3s ease-out;
    pointer-events: none;
  }

  &:hover,
  &:focus,
  &:focus-within {
    cursor: pointer;
    box-shadow: 0 2px 7px 1px ${key("colors.shadowLightHover")};
    outline: 0;
  }

  &::after {
    content: "";
    display: none;
  }
`;

export const StyledLabel = styled(Label)`
  display: flex;
  align-items: center;
  position: relative;

  /* If appearance is circle, the hover, focus and checked styles of the label will be applied here */
  ${(props) => props.appearance === "radio" && radioLabelCircle}

  /* If appearance is card, the hover, focus and checked styles of the label will be applied here */
  ${(props) => props.appearance === "card" && radioLabelCard}
`;

export const StyledIconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-right: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-top: 15px solid ${key("colors.primary")};
  border-left: 15px solid ${key("colors.primary")};
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s ease-out;
`;

export const StyledIcon = styled(Icon)`
  position: absolute;
  top: -12px;
  left: -11px;
  display: block;
`;
