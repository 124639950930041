// 9fbef606107a605d69c0edbcd8029e5d

/*
 * AdvancedSettings Messages
 *
 * This contains all the text for the AdvancedSettings component.
 */
import { defineMessages } from "react-intl";

const idPrefix = "AutocompleteField";

export default defineMessages({
  FS_screenReaderLabel_usageInstructions: {
    id: `FS.${idPrefix}.screenReaderLabel_usageInstructions`,
    defaultMessage:
      "When autocomplete results are available use up and down arrows to review and enter to select. Touch device users, explore by touch or with swipe gestures.",
  },

  SS_screenReaderLabel_usageInstructions: {
    id: `SS.${idPrefix}.screenReaderLabel_usageInstructions`,
    defaultMessage:
      "When autocomplete results are available use up and down arrows to review and enter to select. Touch device users, explore by touch or with swipe gestures.",
  },

  FS_screenReaderLabel_numberOfResults: {
    id: `FS.${idPrefix}.screenReaderLabel_numberOfResults`,
    defaultMessage: "available results below",
  },

  SS_screenReaderLabel_numberOfResults: {
    id: `SS.${idPrefix}.screenReaderLabel_numberOfResults`,
    defaultMessage: "available results below",
  },
});
