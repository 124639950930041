// 9fbef606107a605d69c0edbcd8029e5d

/**
 * The functions in this file will allow us
 * to extract from the html.lang attribute the country, locale and language
 */
import qs from "qs";
import environment from "../devTools/environment";

function getLocale(defaultLocale) {
  const params = window.location.search;
  const { isEmbedded } = environment;
  const queryLocale = !isEmbedded() ? qs.parse(params.slice(1)).locale : "";

  return queryLocale || document.documentElement.lang || defaultLocale;
}

function getCountry(locale, defaultCountry) {
  const [, country] = locale.split("-");

  return country || defaultCountry;
}

function getLanguage(locale, defaultLanguage) {
  const [language] = locale.split("-");

  return language || defaultLanguage;
}

export default {
  getLocale,
  getCountry,
  getLanguage,
};
