// 9fbef606107a605d69c0edbcd8029e5d

import { createAction } from "redux-actions";
import {
  CHECK_LOCATION_VALIDATION,
  FLIP_ORIGIN_DESTINATION,
  PREFILL_INITIAL_SET,
  PREFILL_TRADE_LANE_FROM_URL,
  QUERY_CITY_FAILURE,
  QUERY_CITY_SUCCESS,
  QUERY_CITY,
  QUERY_COUNTRY,
  QUERY_POSTAL_CODE_FAILURE,
  QUERY_POSTAL_CODE_SUCCESS,
  QUERY_POSTAL_CODE,
  RESET_DOCUMENT,
  RESET_DESTINATION_FIELDS,
  RESET_INVALID_FIELDS,
  RESET_SAVED_QUOTE,
  SELECT_CITY,
  SELECT_COUNTRY,
  SELECT_POSTAL_CODE,
  SET_DOCUMENTS_VALUE,
  SET_DOMESTIC_SHIPPING_AVAILABILITY,
  SET_INITIAL_COUNTRY,
  UPDATE_CITY,
  UPDATE_DESTINATION_ADDRESS_TYPE,
  UPDATE_DOCUMENT,
  UPDATE_POSTAL_CODE,
  VALIDATE_CITY_FAILURE,
  VALIDATE_CITY_SUCCESS,
  VALIDATE_CITY,
  VALIDATE_COUNTRY_FIELD,
  VALIDATE_FORM_FIELD,
  VALIDATE_LOCATION_FAILURE,
  VALIDATE_LOCATION_SUCCESS,
  VALIDATE_LOCATION,
  VALIDATE_POSTAL_CODE_FAILURE,
  VALIDATE_POSTAL_CODE_SUCCESS,
  VALIDATE_POSTAL_CODE,
  QUERY_POSTAL_CODE_PLACEHOLDER,
  QUERY_POSTAL_CODE_PLACEHOLDER_SUCCEEDED,
  SET_DEFAULT_DESTINATION,
  TOGGLE_DOMESTIC_SHIPMENT_EXCEPTION,
  LOAD_POSTAL_LOCATION_COUNTRIES_FAILURE,
  LOAD_POSTAL_LOCATION_COUNTRIES_SUCCESS,
  LOAD_POSTAL_LOCATION_COUNTRIES,
  SET_POSTAL_LOCATION_COUNTRIES_FROM_STORAGE,
  SET_COUNTRIES_WITHOUT_POSTAL_CODE,
} from "./constants";

export const checkLocationValidation = createAction(CHECK_LOCATION_VALIDATION);
export const flipOriginDestination = createAction(FLIP_ORIGIN_DESTINATION);
export const prefillInitialSet = createAction(PREFILL_INITIAL_SET);
export const prefillTradeLaneFromUrl = createAction(
  PREFILL_TRADE_LANE_FROM_URL
);
export const queryCity = createAction(QUERY_CITY);
export const queryCityFailure = createAction(QUERY_CITY_FAILURE);
export const queryCitySuccess = createAction(QUERY_CITY_SUCCESS);
export const queryCountry = createAction(QUERY_COUNTRY);
export const queryPostalCode = createAction(QUERY_POSTAL_CODE);
export const queryPostalCodeFailure = createAction(QUERY_POSTAL_CODE_FAILURE);
export const queryPostalCodeSuccess = createAction(QUERY_POSTAL_CODE_SUCCESS);
export const resetDocumentOptionsValue = createAction(RESET_DOCUMENT);
export const resetInvalidFields = createAction(RESET_INVALID_FIELDS);
export const resetDestinationFields = createAction(RESET_DESTINATION_FIELDS);
export const resetSavedQuote = createAction(RESET_SAVED_QUOTE);
export const selectCity = createAction(SELECT_CITY);
export const selectCountry = createAction(SELECT_COUNTRY);
export const selectPostalCode = createAction(SELECT_POSTAL_CODE);
export const setDocumentsValue = createAction(SET_DOCUMENTS_VALUE);
export const setDomesticShippingAvailability = createAction(
  SET_DOMESTIC_SHIPPING_AVAILABILITY
);
export const setInitialCountry = createAction(SET_INITIAL_COUNTRY);
export const updateCity = createAction(UPDATE_CITY);
export const updateDestinationAddressType = createAction(
  UPDATE_DESTINATION_ADDRESS_TYPE
);
export const updateDocument = createAction(UPDATE_DOCUMENT);
export const updatePostalCode = createAction(UPDATE_POSTAL_CODE);
export const validateCity = createAction(VALIDATE_CITY);
export const validateCityFailure = createAction(VALIDATE_CITY_FAILURE);
export const validateCitySuccess = createAction(VALIDATE_CITY_SUCCESS);
export const validateCountryField = createAction(VALIDATE_COUNTRY_FIELD);
export const validateFormField = createAction(VALIDATE_FORM_FIELD);
export const validateLocation = createAction(VALIDATE_LOCATION);
export const validateLocationFailure = createAction(VALIDATE_LOCATION_FAILURE);
export const validateLocationSuccess = createAction(VALIDATE_LOCATION_SUCCESS);
export const validatePostalCode = createAction(VALIDATE_POSTAL_CODE);
export const validatePostalCodeFailure = createAction(
  VALIDATE_POSTAL_CODE_FAILURE
);
export const validatePostalCodeSuccess = createAction(
  VALIDATE_POSTAL_CODE_SUCCESS
);
export const queryPostalCodePlaceholder = createAction(
  QUERY_POSTAL_CODE_PLACEHOLDER
);
export const queryPostalCodePlaceholderSucceeded = createAction(
  QUERY_POSTAL_CODE_PLACEHOLDER_SUCCEEDED
);
export const setDefaultDestination = createAction(SET_DEFAULT_DESTINATION);
export const toggleDomesticShipmentException = createAction(
  TOGGLE_DOMESTIC_SHIPMENT_EXCEPTION
);

export const loadPostalLocationCountries = createAction(
  LOAD_POSTAL_LOCATION_COUNTRIES
);
export const loadPostalLocationCountriesFailure = createAction(
  LOAD_POSTAL_LOCATION_COUNTRIES_FAILURE
);
export const loadPostalLocationCountriesSuccess = createAction(
  LOAD_POSTAL_LOCATION_COUNTRIES_SUCCESS
);
export const setPostalLocationCountriesFromStorage = createAction(
  SET_POSTAL_LOCATION_COUNTRIES_FROM_STORAGE
);
export const setCountriesWithoutPostalCode = createAction(
  SET_COUNTRIES_WITHOUT_POSTAL_CODE
);
