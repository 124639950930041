// 9fbef606107a605d69c0edbcd8029e5d

/*
 * Form
 *
 * This contains all the text for the Form component.
 */
import { defineMessages } from "react-intl";

const idPrefix = "Form";

export default defineMessages({
  FS_screenReaderLabel_formErrorMessage: {
    id: `FS.${idPrefix}.screenReaderLabel_formErrorMessage`,
    defaultMessage:
      "{numberOfErrors, plural, one {# error} other {# errors}} occurred when sending this form. Please revise the data and resend the form. You will automatically be set to the first field containing an error.",
  },

  SS_screenReaderLabel_formErrorMessage: {
    id: `SS.${idPrefix}.screenReaderLabel_formErrorMessage`,
    defaultMessage:
      "{numberOfErrors, plural, one {# error} other {# errors}} occurred when sending this form. Please revise the data and resend the form. You will automatically be set to the first field containing an error.",
  },
});
