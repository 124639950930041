// 9fbef606107a605d69c0edbcd8029e5d

import { createSelector } from "reselect";
import always from "lodash/fp/always";
import { DEFAULT_TYPE_OF_CUSTOMER } from "one-time-shipment/src/containers/GetQuote/constants";
import { RemoteData, AutoComplete, FormField } from "root/libs/core-libs/src";
import compose from "lodash/fp/compose";
import complement from "lodash/fp/complement";
import curry from "lodash/curry";

const tradeLaneSelector = (state) => state.tradeLane;

const getAutoCompleteFormFieldValue = compose(
  AutoComplete.getValue,
  FormField.getValue
);

const getFieldFeedback = FormField.case({
  invalid: (field, { errorAriaDescribedBy, ...feedback }) => ({
    ...feedback,
    feedbackMessageId: errorAriaDescribedBy || feedback.feedbackMessageId,
    value: typeof field === "string" ? field : AutoComplete.getValue(field),
  }),
  valid: (field) => ({
    hasError: false,
    isValid: true,
    feedbackMessageId: undefined,
    value: typeof field === "string" ? field : AutoComplete.getValue(field),
  }),
  _: always({}),
});

const getDestinationAddress = (isOriginDestinationFlipped, initialSet) => {
  if (isOriginDestinationFlipped) {
    return {
      city: initialSet.originCity,
      country: initialSet.originCountry,
      postalCode: initialSet.originZip,
      shouldUseFallback: initialSet.originUseFallback,
    };
  }

  return {
    city: initialSet.destinationCity,
    country: initialSet.destinationCountry,
    postalCode: initialSet.destinationZip,
    shouldUseFallback: initialSet.destinationUseFallback,
  };
};

const getOriginAddress = (isOriginDestinationFlipped, initialSet) =>
  getDestinationAddress(!isOriginDestinationFlipped, initialSet);

const decodeTradeLaneToState = (tradeLane) => {
  const { initialSet } = tradeLane;
  const { originDestinationFlipped } = tradeLane;

  const destinationData = getDestinationAddress(
    originDestinationFlipped,
    initialSet
  );
  const originData = getOriginAddress(originDestinationFlipped, initialSet);

  const destination = {
    fields: {
      city: FormField.valid(
        AutoComplete.unselected(destinationData.city, RemoteData.notAsked())
      ),
      country: FormField.valid(
        AutoComplete.selected(destinationData.country, [])
      ),
      postalCode: FormField.valid(
        AutoComplete.unselected(
          destinationData.postalCode,
          RemoteData.notAsked()
        )
      ),
    },
    isValidCity: RemoteData.success(!destinationData.shouldUseFallback),
    isValidPostalCode: RemoteData.success(!destinationData.shouldUseFallback),
  };

  const { isDomesticShippingAvailable } = tradeLane;
  const documentsOption = tradeLane.documentsOptionValue;

  const fields = {
    destinationAddressType: FormField.valid(initialSet.receiverAddressType),
  };

  const origin = {
    fields: {
      city: FormField.valid(
        AutoComplete.unselected(originData.city, RemoteData.notAsked())
      ),
      country: FormField.valid(AutoComplete.selected(originData.country, [])),
      postalCode: FormField.valid(
        AutoComplete.unselected(originData.postalCode, RemoteData.notAsked())
      ),
    },
    isValidCity: RemoteData.success(!originData.shouldUseFallback),
    isValidPostalCode: RemoteData.success(!originData.shouldUseFallback),
  };

  return {
    destination,
    documentsOption,
    fields,
    isDomesticShippingAvailable,
    isOriginDestinationFlipped: originDestinationFlipped,
    origin,
  };
};

const decodeStateToInitialSet = (state) => {
  const origin = state.isOriginDestinationFlipped
    ? state.destination
    : state.origin;
  const destination = state.isOriginDestinationFlipped
    ? state.origin
    : state.destination;
  const { destinationAddressType } = state.fields;

  const defaultToFalse = RemoteData.withDefault(false);

  const destinationCity = getAutoCompleteFormFieldValue(
    destination.fields.city
  ).trim();
  const destinationZip = destination.fields.postalCode
    ? getAutoCompleteFormFieldValue(destination.fields.postalCode).trim()
    : "";

  const destinationUseFallback = destination.fields.postalCode
    ? !defaultToFalse(destination.isValidPostalCode)
    : !defaultToFalse(destination.isValidCity);

  const originCity = getAutoCompleteFormFieldValue(origin.fields.city).trim();
  const originZip = origin.fields.postalCode
    ? getAutoCompleteFormFieldValue(origin.fields.postalCode).trim()
    : "";

  const originUseFallback = origin.fields.postalCode
    ? !defaultToFalse(origin.isValidPostalCode)
    : !defaultToFalse(origin.isValidCity);

  const receiverType = DEFAULT_TYPE_OF_CUSTOMER;
  const senderType = DEFAULT_TYPE_OF_CUSTOMER;

  return {
    destinationCity,
    destinationCitydisplayValue: destinationCity,
    destinationCountry: getAutoCompleteFormFieldValue(
      destination.fields.country
    ).trim(),
    destinationUseFallback,
    destinationZip,
    destinationZipdisplayValue: destinationZip,
    originCity,
    originCitydisplayValue: originCity,
    originCountry: getAutoCompleteFormFieldValue(origin.fields.country).trim(),
    originUseFallback,
    originZip,
    originZipdisplayValue: originZip,
    receiverAddressType: FormField.getValue(destinationAddressType),
    receiverType,
    senderType,
  };
};

const decodeStateToValidity = (state) => {
  const origin = state.isOriginDestinationFlipped
    ? state.destination
    : state.origin;
  const destination = state.isOriginDestinationFlipped
    ? state.origin
    : state.destination;

  const destinationZip = destination.fields.postalCode
    ? getFieldFeedback(destination.fields.postalCode)
    : {};

  const originZip = origin.fields.postalCode
    ? getFieldFeedback(origin.fields.postalCode)
    : {};

  return {
    destinationCity: getFieldFeedback(destination.fields.city),
    destinationCountry: getFieldFeedback(destination.fields.country),
    destinationZip,
    originCity: getFieldFeedback(origin.fields.city),
    originCountry: getFieldFeedback(origin.fields.country),
    originZip,
    receiverAddressType: getFieldFeedback(state.fields.destinationAddressType),
    receiverType: {},
    senderType: {},
  };
};

const selectTradeLaneInitialSet = createSelector(
  tradeLaneSelector,
  decodeStateToInitialSet
);

const selectTradeLaneValidity = createSelector(
  tradeLaneSelector,
  decodeStateToValidity
);

const selectOriginCountry = (state) => state.tradeLane.origin.fields.country;

const selectDestinationCountry = (state) =>
  state.tradeLane.destination.fields.country;

const hasAnyFieldWhere = curry((predicate, fields) => {
  const keys = Object.keys(fields);

  for (let i = 0; i < keys.length; i += 1) {
    if (typeof fields[keys[i]] !== "undefined" && predicate(fields[keys[i]])) {
      return true;
    }
  }

  return false;
});

const hasAnyFieldNotValid = hasAnyFieldWhere(complement(FormField.isValid));

const selectDocumentsOptionValue = (state) => state.tradeLane.documentsOption;

const selectIsOriginDestinationFlipped = (state) =>
  state.tradeLane.isOriginDestinationFlipped;

const selectOriginFields = (state) => state.tradeLane.origin.fields;

const selectDestinationFields = (state) => state.tradeLane.destination.fields;

const selectIsDestinationPostalCodeValid = (state) =>
  state.tradeLane.destination.isValidPostalCode;

const selectIsDestinationCityValid = (state) =>
  state.tradeLane.destination.isValidCity;

const selectIsOriginPostalCodeValid = (state) =>
  state.tradeLane.origin.isValidPostalCode;

const abTestRunning = (state) => state.abTestExperiment.abTestRunning;

const abTestVersion = (state) => state.abTestExperiment.showThisVersion;

const rtlEnabled = (state) => state.rtlSupport.enabled;

const canaryEnabled = (state) => state.canaryVersion.enabled;

const canaryVersion = (state) => state.canaryVersion.version;

const selectIsOriginCityValid = (state) => state.tradeLane.origin.isValidCity;

const selectIsFormValid = (state, isBusinessLane) => {
  const defaultToFalse = RemoteData.withDefault(false);
  const originFields = selectOriginFields(state);
  const destinationFields = selectDestinationFields(state);

  const areAllFieldsValid = !(
    hasAnyFieldNotValid(originFields) ||
    hasAnyFieldNotValid(destinationFields) ||
    hasAnyFieldNotValid(state.tradeLane.fields)
  );

  if (!isBusinessLane) {
    return areAllFieldsValid;
  }

  const isValidOriginLocation = originFields.postalCode
    ? defaultToFalse(state.tradeLane.origin.isValidPostalCode)
    : defaultToFalse(state.tradeLane.origin.isValidCity);

  const isValidDestinationLocation = destinationFields.postalCode
    ? defaultToFalse(state.tradeLane.destination.isValidPostalCode)
    : defaultToFalse(state.tradeLane.destination.isValidCity);

  return (
    areAllFieldsValid && isValidOriginLocation && isValidDestinationLocation
  );
};

const selectDestinationAddressType = (state) =>
  state.tradeLane.fields.destinationAddressType;

const selectIsLocationApiAvailable = (state) =>
  state.tradeLane.isLocationApiAvailable;

const selectIsTradeLaneDataLoadedFromUrl = (state) =>
  state.tradeLane.wasDataLoadedfromUrl;

const selectIsDomesticShipmentException = (state) =>
  state.tradeLane.domesticShipmentException;

const selectPostalLocationCountries = (state) =>
  state.tradeLane.postalLocationCountries;

const selectCountriesWithoutPostalCode = (state) =>
  state.tradeLane.countriesWithoutPostalCode;

export {
  decodeTradeLaneToState,
  selectDestinationAddressType,
  selectDestinationCountry,
  selectDestinationFields,
  selectDocumentsOptionValue,
  selectIsDestinationCityValid,
  selectIsDestinationPostalCodeValid,
  selectIsFormValid,
  selectIsLocationApiAvailable,
  selectIsOriginCityValid,
  selectIsOriginDestinationFlipped,
  selectIsOriginPostalCodeValid,
  selectIsTradeLaneDataLoadedFromUrl,
  selectOriginCountry,
  selectOriginFields,
  selectTradeLaneInitialSet,
  selectTradeLaneValidity,
  abTestRunning,
  abTestVersion,
  rtlEnabled,
  selectIsDomesticShipmentException,
  canaryEnabled,
  canaryVersion,
  selectPostalLocationCountries,
  selectCountriesWithoutPostalCode,
};
