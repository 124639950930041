// 9fbef606107a605d69c0edbcd8029e5d

import { featureFlags, perfKeys } from "root/libs/constants/src";

export const getUiMetrics = (navigationEntry) => {
  const {
    connectEnd,
    connectStart,
    decodedBodySize, // is the decompressed size of the resource (again, excluding HTTP headers)
    domainLookupEnd, // marks when a DNS lookup ends
    domainLookupStart, // marks when a DNS lookup starts
    domComplete, // is when the user agent sets the value of readiness of the current document to complete
    domContentLoadedEventEnd,
    domContentLoadedEventStart,
    domInteractive, // is when the user agent sets the value of readiness of the current document to interactive
    duration, // diff between loadEventEnd and startTime
    encodedBodySize, // is the compressed size of the resource excluding HTTP headers
    fetchStart, // marks when the browser starts to fetch a resource. (Before checks the cache)
    loadEventEnd, // when the load event of the current document is completed
    requestStart, // is when the browser issues the network request
    responseEnd, // is when the last byte of the response arrives
    responseStart, // is when the first byte of the response arrives
    secureConnectionStart, // for SSL connection stars
    transferSize, // is the total size of the resource including HTTP headers and response payload
  } = navigationEntry;

  const allMetrics = {
    /** ********************************
     * 1st - Measure DNS lookup duration
     * the total amount of time it took to translate a domain to an IP address.
     */
    dnsTime: Math.round(domainLookupEnd - domainLookupStart),
    /** ********************************
     * 2nd - Measure Connection durations
     * the time elapsed between the beginning of a request and the completion of getting the response
     * When a connection to a server is made, latency occurs as the client and server sort things out
     * prior to sending resources to the client.
     */
    connectTime: Math.round(connectEnd - connectStart),
    // for secured connection time
    tlsConnectTime:
      secureConnectionStart > 0 ? connectEnd - secureConnectionStart : 0,
    /** ********************************
     * 3rd - Measure many aspects of loading performance
     * After a domain's IP is looked up and a connection is established, the real fun starts.
     */
    // Calculate the fetch time, this considers the cache as well
    fetchTime: Math.round(responseEnd - fetchStart),
    // Calculate the Request time only (excluding unload, redirects, DNS, and connection time)
    requestTime: responseStart - requestStart,
    // Calculate the Response time only (download)
    responseTime: responseEnd - responseStart,
    // Calculate the total time taken for a request and response
    totalRequestResponseTime: Math.round(responseEnd - requestStart),
    /** ********************************
     * 4th - Measure Document and resource size
     * The size of a document or resource is undoubtedly influential on loading performance.
     */
    // Calculate the HTTP header size
    headerSize: Math.round(transferSize - encodedBodySize),
    // Calculate the Compression ratio
    compressionRatio: decodedBodySize / encodedBodySize,
    // This can be used to track over the period and see if our response payload is increasing
    encodedBodySize,
    /** ********************************
     * 5th - Measure document processing times
     * When HTML documents load, the browser takes time to process them
     */
    // domLoading is deprecated so I'm using the last timestamp
    domLoadingTime: domContentLoadedEventEnd - domContentLoadedEventStart,
    domComplete: Math.round(domComplete),
    domInteractive: Math.round(domInteractive),
    /** ********************************
     * 6th - Measure loading & rendering time
     * When a document and its resources have completely finished loading, the browser fires a load event
     */
    // Calculate the total page downloading time
    // we can also use loadEventEnd - startTime
    pageLoadTime: duration,
    // Calculate the amount of time taken to draw the first pixel
    timeToFirstByte: Math.round(responseStart - requestStart),
    // Calculate page render time
    domRenderTime: Math.round(domComplete - domContentLoadedEventEnd),
    // v1 entries - this will be depreciated for v2, same as pageLoadTime
    perceivedPageLoadTime: Math.round(loadEventEnd - fetchStart),
  };

  return featureFlags.trackPerformanceExtensively
    ? allMetrics
    : perfKeys.defaultMetrics.reduce((acc, key) => {
      acc[key] = allMetrics[key];
      return acc;
    }, {});
};
