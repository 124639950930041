// 9fbef606107a605d69c0edbcd8029e5d

import { key } from "styled-theme";
import styled from "styled-components";
import { breakpoints } from "root/libs/ui-styleguide/src";

export const StyledLabel = styled.label`
  font-size: ${key("fonts.mobile.label.size")};
  font-weight: ${(props) => (props.isBold ? "800" : "400")};
  color: ${(props) =>
    props.hasError ? key("colors.errorColor") : key("colors.fontColor")};
  ${(props) => props.extraStyles}

  @media screen and (min-width: ${breakpoints.tablet}) {
    font-size: ${(props) =>
    props.cssFontSize === "copy"
      ? key("fonts.desktop.copy.size")
      : key("fonts.desktop.label.size")};
  }
`;
