// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * TabPanel
 * Subcomponent of Accordion.
 * Subcomponent: In this context, sub-components are defined as
 * components which have their own definition declared within
 * another parent component, and can only be used in the
 * context of that parent component
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { PanelWrapper } from "./styled";

export default class TabPanel extends React.PureComponent {
  static propTypes = {
    /** JSX content of the accordion title. Here you can put any valid HTML content to display when the accordion is expanded */
    children: PropTypes.any,
    /** Id of the accordion. It will be cross-used in the AccordionTitle
     * by the attribute aria-controls to specify which element belong to that title */
    id: PropTypes.string.isRequired,
    /** Points to the accordion title. Its function is
     * to tell which element is the label of this content */
    labeledBy: PropTypes.string.isRequired,
    /** Will tell the component, wheter it should show its content or not. */
    isSelected: PropTypes.bool,
    /** Property for passing extra css styles in order to style accordion content as needed inside of parent components */
    extraStyles: PropTypes.array,
    className: PropTypes.string,
    /** If set the the tab panel will have a bottom padding of 40px */
    addBottomPadding: PropTypes.bool,
    /** id for automation */
    dataTestid: PropTypes.string,
  };

  static defaultProps = {
    children: "",
    isSelected: false,
    extraStyles: [],
    className: "",
    addBottomPadding: false,
    dataTestid: undefined,
  };

  getClassName = () => {
    const { isSelected, className } = this.props;
    return isSelected ? `${className} is-selected` : `${className}`;
  };

  render() {
    const {
      id,
      labeledBy,
      children,
      isSelected,
      extraStyles,
      addBottomPadding,
      dataTestid,
    } = this.props;

    return (
      <PanelWrapper
        id={id}
        role="tabpanel"
        aria-labelledby={labeledBy}
        className={this.getClassName()}
        extraStyles={extraStyles}
        aria-hidden={!isSelected}
        addBottomPadding={addBottomPadding}
        data-testid={dataTestid}
      >
        {children}
      </PanelWrapper>
    );
  }
}
