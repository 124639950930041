// 9fbef606107a605d69c0edbcd8029e5d

import React, { memo, useEffect } from "react";
import PropTypes from "prop-types";
import { Text } from "@dhl-official/react-ui-library";
import { trackErrorPageLoad } from "root/libs/core-libs/src";
import {
  UTF_EVENT_PAGE_NAMES,
  UTF_EVENT_INTERACTIONS,
} from "root/libs/constants/src";
import RetryModal from "../RetryModal";

const ModalError = ({
  bodyContentString,
  cancelButtonLabel,
  closeButtonLabel,
  headlineString,
  isOpen,
  isRetrying,
  onClose,
  onRetry,
  retryButtonLabel,
  sublineString,
}) => {
  useEffect(() => {
    const { ERROR_MODAL } = UTF_EVENT_PAGE_NAMES;
    const { ERROR } = UTF_EVENT_INTERACTIONS;

    trackErrorPageLoad({
      page: {
        name: ERROR_MODAL,
        category: ERROR,
      },
    });
  }, []);

  return (
    <RetryModal
      cancelButtonLabel={cancelButtonLabel}
      closeButtonLabel={closeButtonLabel}
      headline={headlineString}
      isOpen={isOpen}
      isRetrying={isRetrying}
      onClose={onClose}
      onRetry={onRetry}
      retryButtonLabel={retryButtonLabel}
      subHeadline={sublineString}
    >
      <Text>{bodyContentString}</Text>
    </RetryModal>
  );
};

ModalError.propTypes = {
  bodyContentString: PropTypes.node.isRequired,
  cancelButtonLabel: PropTypes.node.isRequired,
  closeButtonLabel: PropTypes.node.isRequired,
  headlineString: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  isRetrying: PropTypes.bool,
  onClose: PropTypes.func,
  onRetry: PropTypes.func,
  retryButtonLabel: PropTypes.node.isRequired,
  sublineString: PropTypes.node.isRequired,
};

ModalError.defaultProps = {
  isOpen: false,
  isRetrying: false,
  onClose: Function.prototype,
  onRetry: Function.prototype,
};

export default memo(ModalError);
