// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for OfferPriceAndPaymentInfo
 *
 */

import styled from "styled-components";
import { key } from "styled-theme";
import { breakpoints } from "root/libs/ui-styleguide/src";
import { Headline } from "root/libs/ui-components/src";

export const CardPriceHeadline = styled(Headline)`
  margin: ${(props) => props.bottomMargin};
  font-size: ${key("fonts.desktop.h1.size")} !important;
  line-height: ${key("fonts.desktop.h1.lineHeight")} !important;

  @media screen and (min-width: ${breakpoints.tablet}) {
    font-size: ${key("fonts.desktop.h3.size")} !important;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    font-size: ${key("fonts.desktop.h1.size")} !important;
  }
`;

export const CardPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.tablet}) {
    align-items: center;
  }

  @media print {
    width: 30%;
  }

  .price-label {
    color: ${key("colors.headerColor")};
    margin: 10px 0;

    @media screen and (min-width: ${breakpoints.tablet}) {
      margin-bottom: 3px;
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      margin-bottom: 0;
    }
  }
`;

export const CardButtonWrapper = styled.div`
  margin-bottom: 5px;

  button {
    width: 100%;
  }

  @media print {
    display: none;
  }
`;

export const DisclaimerStar = styled.span`
  font-weight: 200;
`;
