// 9fbef606107a605d69c0edbcd8029e5d

import styled, { css } from "styled-components";
import { key } from "styled-theme";
import {
  focusedState,
  focusedFormControlStyle,
  breakpoints,
} from "root/libs/ui-styleguide/src";

export const sizes = {
  small: 16,
  intermediate: 20,
  medium: 24,
  large: 32,
  mlarge: 40,
  xlarge: 48,
  xxlarge: 64,
  inbetweenLarge: 76,
  xxxlarge: 82,
};

const getSvgSize = (props, viewport = "mobile") => {
  const size = {
    width: !!props.iconWidth[viewport] !== "" ? props.iconWidth[viewport] : "",
    height:
      !!props.iconHeight[viewport] !== "" ? props.iconHeight[viewport] : "",
  };

  if (props.size !== "") {
    size.width = `${sizes[props.size]}px`;
    size.height = `${sizes[props.size]}px`;
  }

  if (props.isRelativeSvgSize) {
    size.width = "100%";
    size.height = "100%";
  }

  return size;
};

const iconStyles = css`
  display: inherit;
  line-height: 1;

  > svg {
    width: ${(props) => getSvgSize(props).width};
    min-width: ${(props) => getSvgSize(props).width};
    max-width: 100%;
    overflow: hidden;
    vertical-align: middle;
    fill: ${(props) => props.fillColor};

    @media screen and (min-width: ${breakpoints.tablet}) {
      width: ${(props) => getSvgSize(props, "desktop").width};
      min-width: ${(props) => getSvgSize(props, "desktop").width};
    }
  }
`;

export const IconSpan = styled.span`
  height: ${(props) => getSvgSize(props).height};
  width: ${(props) => getSvgSize(props).width};
  position: relative;
  ${iconStyles}
  ${(props) => props.extraStyles}

  @media screen and (min-width: ${breakpoints.tablet}) {
    height: ${(props) => getSvgSize(props, "desktop").height};
    width: ${(props) => getSvgSize(props, "desktop").width};
  }

  @media print {
    display: none;
  }
`;

export const IconButton = styled.button.attrs({ type: "button" })`
  ${iconStyles}
  ${(props) => props.extraStyles}
  position: relative;
  cursor: pointer;
  border: 0;
  height: auto;
  background-color: ${(props) =>
    key(`colors.${props.buttonBackgroundColor}`) || "transparent"};
  fill: ${(props) => props.fillColor};
  padding: 10px;
  text-align: left;

  &:hover,
  &:active {
    border: ${focusedFormControlStyle};
  }

  ${focusedState.transparentBorderAndFocus}
`;
