// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * CopyQuoteLinkButton
 *
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, BUTTON_VARIANTS } from "@dhl-official/react-ui-library";
import { injectIntl } from "react-intl";
import { IntlPropType, trackContentInteraction } from "root/libs/core-libs/src";

const COPY_ID_PREFIX = "ShareButton";

const CopyQuoteLinkButton = injectIntl(
  ({ intl, trackEvent, trackingValue, shareUrl, utfDataObject }) => {
    const [clickedButton, setClickedButton] = useState(false);

    const onClickCopyURL = (event) => {
      // prevent default link behavior, but enabling user to copy link with right-click
      event.preventDefault();
      navigator.clipboard.writeText(shareUrl);
      setClickedButton(true);
      trackEvent(trackingValue);
      trackContentInteraction(utfDataObject);
    };

    return (
      <div>
        <Link
          withButtonStyle={{
            isBlock: false,
            size: "regular",
            variant: clickedButton
              ? BUTTON_VARIANTS.PRIMARY
              : BUTTON_VARIANTS.SECONDARY,
          }}
          dataTestid="copy-quote"
          onClick={(e) => onClickCopyURL(e)}
          href={shareUrl}
        >
          {clickedButton
            ? intl.formatMessage({
                id: `${COPY_ID_PREFIX}.copyCTALabelClicked`,
              })
            : intl.formatMessage({
                id: `${COPY_ID_PREFIX}.copyCTALabel`,
              })}
        </Link>
      </div>
    );
  }
);

CopyQuoteLinkButton.propTypes = {
  intl: IntlPropType.isRequired,
  dataTestid: PropTypes.string,
  trackEvent: PropTypes.func,
  trackingValue: PropTypes.object,
  shareUrl: PropTypes.string.isRequired,
  utfDataObject: PropTypes.object,
};

CopyQuoteLinkButton.defaultProps = {
  dataTestid: undefined,
  trackEvent: () => {},
  trackingValue: {},
  utfDataObject: {},
};

export default CopyQuoteLinkButton;
