// 9fbef606107a605d69c0edbcd8029e5d

export default {
  extensiveEntryTypes: ["navigation", "paint", "longtask", "resource"],
  defaultEntryTypes: ["navigation", "paint", "longtask"],
  defaultMetrics: [
    "requestTime",
    "responseTime",
    "totalRequestResponseTime",
    "perceivedPageLoadTime",
  ],
};
