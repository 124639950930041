// 9fbef606107a605d69c0edbcd8029e5d

import format from "date-fns/format";
import { GetCountryCode } from "root/libs/core-libs/src";
import { dayPeriodOverwrites } from "root/libs/constants/src";
import { deliveryTimeDisplayTypes } from "one-time-shipment/src/containers/OffersLane/constants";
import localeUses24HourTime from "./localeUses24HourTime";

// Checks if langCode returns 1 or 2 digits for hours when time is 1 PM
// It is safer to check for hour digits than hour12 part because not all lang codes
// have this part in the resolvedOptions() response.
// e.g: en-GB response:
// {
//   "locale": "en-GB",
//   "calendar": "gregory",
//   "numberingSystem": "latn",
//   "timeZone": "Europe/Berlin",
//   "year": "numeric",
//   "month": "2-digit",
//   "day": "2-digit"
// }
// hour12 = undefined
// en-US would include this:
// {
//   "type": "dayPeriod",
//   "value": "PM"
// }
// hour12 = true

export default function getTimeOfDay(
  intl,
  estimatedDeliveryTime,
  estimatedDeliveryTimeDisplayType,
  is12HourTimeFormat
) {
  const countryCode = GetCountryCode(intl.locale);
  // IF locale uses 24 hour time AND is12HourTimeFormat === true
  // it will return an unsupported string and it needs to be overwritten
  // Otherwise it will show 00:00 PM instead of 12:00 PM
  // Since en-GB is by default 24 hour format, we use en-US for 12 hours with AM/PM
  const locale =
    localeUses24HourTime(intl.locale) && is12HourTimeFormat
      ? "en-US"
      : intl.locale;
  let timeOfDay;

  // If stakeholder desires specific day period format, then we have to use date-fns:
  if (countryCode in dayPeriodOverwrites) {
    timeOfDay = format(estimatedDeliveryTime, dayPeriodOverwrites[countryCode]);
  } else {
    timeOfDay = new Intl.DateTimeFormat(locale, {
      timeStyle: "short",
      hour12: is12HourTimeFormat,
    }).format(estimatedDeliveryTime);
  }

  const isEndOfDay =
    estimatedDeliveryTimeDisplayType === deliveryTimeDisplayTypes.endOfDay ||
    estimatedDeliveryTimeDisplayType ===
      deliveryTimeDisplayTypes.latestEndOfDay;

  if (isEndOfDay) {
    timeOfDay = intl.formatMessage({
      id: "CardAndSummary.endOfDay",
    });
  }

  return timeOfDay;
}
