// 9fbef606107a605d69c0edbcd8029e5d

import styled from "styled-components";
import { breakpoints } from "root/libs/ui-styleguide/src";
import { Button } from "@dhl-official/react-ui-library";
import Headline from "../../Texts/Headline";

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
  }

  @media screen and (-ms-high-contrast: none) {
    max-width: 480px;
  }
`;

export const StyledHeadline = styled(Headline)`
  margin-bottom: 10px;

  span {
    display: block;
    font-weight: 200;
  }
`;

export const ButtonsContainer = styled.div`
  margin-top: 25px;

  button {
    margin-bottom: 10px;
  }
`;

export const StyledButton = styled(Button)`
  max-width: 113px;
  min-width: 113px;

  &:first-child {
    margin-right: 10px;
  }
`;

export const StyledWarningIconWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-right: 30px;
  margin-bottom: 20px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: auto;
    margin-bottom: 0;
  }

  @media screen and (-ms-high-contrast: none) {
    display: block;
  }
`;

export const StyledContentWrapper = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-right: 70px;
  }
`;

export const StyledTextWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 15px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-top: 10px;
    padding-bottom: 5px;
  }
`;
