// 9fbef606107a605d69c0edbcd8029e5d

/*
 *
 * getOffers reducer
 *
 */

import { handleActions } from "redux-actions";
import each from "lodash/each";
import { v4 as uuidv4 } from "uuid";
import {
  validations,
  removeIndexSuffix,
  trackContentInteraction,
} from "root/libs/core-libs/src";
import {
  UTF_EVENT_CONTENT_NAMES,
  UTF_EVENT_TYPES,
  UTF_EVENT_INTERACTIONS,
  UTF_EVENT_CONTENT_POSITION,
} from "root/libs/constants/src";
import { SEGMENT_TYPES } from "../SpotShipmentPage/constants";
import {
  resetItem as resetItemAction,
  updateItem as updateItemAction,
  showShipmentLane as showShipmentLaneAction,
  hideShipmentLane as hideShipmentLaneAction,
  validateFormFields as validateFormFieldsAction,
  clearDimensionFieldsValidationErrors as clearDimensionFieldsValidationErrorsAction,
  resetScrolling as resetScrollingAction,
  prefillInitialSet as prefillInitialSetAction,
  prefillShipmentLaneFromUrl as prefillShipmentLaneFromUrlAction,
  onCheckboxChange as onCheckboxChangeAction,
  updateValidity as updateValidityAction,
  addShipmentLaneItem as addShipmentLaneItemAction,
  removeShipmentLaneItem as removeShipmentLaneItemAction,
  setUnitSystem as setUnitSystemAction,
} from "./actions";

const initialStateValidity = () => ({
  id: uuidv4(),
  weight: {},
  height: {},
  length: {},
  width: {},
  quantity: {},
});

const validityForPrefill = () => ({
  id: uuidv4(),
  weight: { isValid: true, hasError: false },
  height: { isValid: true, hasError: false },
  length: { isValid: true, hasError: false },
  width: { isValid: true, hasError: false },
  quantity: { isValid: true, hasError: false },
});

const initialStateItems = {
  weight: "",
  height: "",
  length: "",
  width: "",
  quantity: 1,
  presetSize: "",
  unitSystem: "",
  settingsPalletized: false,
  settingsNonStackable: false,
};

export const initialState = {
  isVisible: false,
  scrollToSection: false,
  selectedUnitSystem: "",
  initialSet: {
    items: [initialStateItems],
    settingsDangerousGoods: false,
  },
  validity: {
    items: [initialStateValidity()],
  },
};

const updateItem = (state, { payload: { itemIndex, newItemValues } }) => {
  const { initialSet } = state;
  // Create a new item array that is a clone of the original
  const newItemsArray = initialSet.items.slice(0);

  // Remove index suffixes
  const transformedItemValues = {};
  Object.keys(newItemValues).map((obj) => {
    transformedItemValues[removeIndexSuffix(obj)] = newItemValues[obj];
    return obj;
  });

  // Create a new item object from the present values and apply the new ones
  const newItem = {
    ...newItemsArray[itemIndex],
    ...transformedItemValues,
  };
  // Update it in its position of the items array
  newItemsArray[itemIndex] = {
    ...newItem,
  };

  // Retun the updated state
  return {
    ...state,
    initialSet: {
      ...state.initialSet,
      items: newItemsArray,
    },
  };
};

const resetItem = (state, { payload: { segment } }) => {
  if (typeof segment !== "undefined" && segment.id === SEGMENT_TYPES.business) {
    return state;
  }

  return {
    ...state,
    initialSet: {
      items: [initialStateItems],
    },
    validity: {
      items: [initialStateValidity()],
    },
  };
};

const setValidityToItems = (items) => {
  const validityArray = [];
  for (let loop = 0; loop < items.length; loop += 1) {
    validityArray.push(validityForPrefill());
  }
  return validityArray;
};

const prefillShipmentLaneFromUrl = (state, { payload: { shipmentLane } }) => {
  const validityArray = setValidityToItems(shipmentLane.initialSet.items);

  return {
    ...state,
    ...shipmentLane,
    isVisible: true,
    scrollToSection: false,
    initialSet: {
      ...shipmentLane.initialSet,
    },
    validity: {
      items: validityArray,
    },
  };
};

const prefillInitialSet = (
  state,
  { payload: { items, settingsDangerousGoods } }
) => {
  const validityArray = setValidityToItems(items);

  return {
    ...state,
    initialSet: {
      items,
      settingsDangerousGoods,
    },
    validity: {
      items: validityArray,
    },
  };
};

const showShipmentLane = (state, { payload: selectedSegment }) => {
  trackContentInteraction({
    name: UTF_EVENT_CONTENT_NAMES.FORM_SUBMIT,
    type: UTF_EVENT_TYPES.BUTTON,
    interaction: UTF_EVENT_INTERACTIONS.CLICK,
    position: UTF_EVENT_CONTENT_POSITION.TRADE_LANE,
    context: selectedSegment,
  });
  return { ...state, isVisible: true, scrollToSection: true };
};

const hideShipmentLane = (state) => ({
  ...state,
  isVisible: false,
});

const resetScrolling = (state) => ({
  ...state,
  scrollToSection: false,
});

const validateFormFields = (state, { payload: element }) => {
  const { itemIndex, isBusiness } = element;

  const elementValidityState = validations.validateElement({
    ...element,
    isBusiness,
    utfPosition: UTF_EVENT_CONTENT_POSITION.SHIPMENT_LANE,
  });
  let elementName = removeIndexSuffix(elementValidityState.name || "");

  if (elementName === undefined) {
    elementName = element.name;
  }

  const { items } = state.validity;

  let itemValidity = {};

  if (elementName) {
    itemValidity = {
      [elementName]: {
        hasError: elementValidityState.hasError,
        feedbackMessageId: elementValidityState.feedbackMessageId,
        isValid: elementValidityState.isValid,
        value: element.value,
      },
    };

    items[itemIndex] = {
      ...items[itemIndex],
      ...itemValidity,
    };
  }
  return {
    ...state,
    initialSet: {
      ...state.initialSet,
      items: state.initialSet.items,
    },
    validity: {
      ...state.validity,
      items,
    },
  };
};

const clearDimensionFieldsValidationErrors = (
  state,
  { payload: itemIndex }
) => {
  const newItemValidity = {};
  const { items } = state.validity;
  const item = items[itemIndex];

  each(item, (values, name) => {
    // Only check for length, width and height, as this function is called only when preset is selected
    if (["length", "width", "height"].includes(name)) {
      newItemValidity[name] = { ...values, hasError: false, isValid: true };
    }
  });

  items[itemIndex] = { ...items[itemIndex], ...newItemValidity };

  return {
    ...state,
    initialSet: {
      ...state.initialSet,
      items: state.initialSet.items,
    },
    validity: {
      items,
    },
  };
};

const onCheckboxChange = (state, { payload: { name, value } }) => ({
  ...state,
  initialSet: {
    ...state.initialSet,
    [name]: value,
  },
});

const updateValidity = (state, { payload: { validity } }) => ({
  ...state,
  validity: {
    ...validity,
  },
});

const addShipmentLaneItem = (state) => ({
  ...state,
  initialSet: {
    ...state.initialSet,
    items: [...state.initialSet.items, initialStateItems],
  },
  validity: {
    items: [...state.validity.items, initialStateValidity()],
  },
});

const removeShipmentLaneItem = (state, { payload: itemIndex }) => {
  if (itemIndex === 0) {
    return state;
  }

  const { items } = state.validity;

  const newInitialSetItems = state.initialSet.items.filter(
    (v, i) => i !== itemIndex
  );
  const newValidityState = items.filter((v, i) => i !== itemIndex);

  return {
    ...state,
    initialSet: {
      ...state.initialSet,
      items: newInitialSetItems,
    },
    validity: {
      items: newValidityState,
    },
  };
};

const setUnitSystem = (state, { payload: unitValue }) => {
  return {
    ...state,
    selectedUnitSystem: unitValue,
  };
};

export default handleActions(
  {
    [resetItemAction]: resetItem,
    [updateItemAction]: updateItem,
    [showShipmentLaneAction]: showShipmentLane,
    [hideShipmentLaneAction]: hideShipmentLane,
    [validateFormFieldsAction]: validateFormFields,
    [clearDimensionFieldsValidationErrorsAction]: clearDimensionFieldsValidationErrors,
    [resetScrollingAction]: resetScrolling,
    [prefillInitialSetAction]: prefillInitialSet,
    [prefillShipmentLaneFromUrlAction]: prefillShipmentLaneFromUrl,
    [onCheckboxChangeAction]: onCheckboxChange,
    [updateValidityAction]: updateValidity,
    [addShipmentLaneItemAction]: addShipmentLaneItem,
    [removeShipmentLaneItemAction]: removeShipmentLaneItem,
    [setUnitSystemAction]: setUnitSystem,
  },
  initialState
);
