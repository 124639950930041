// 9fbef606107a605d69c0edbcd8029e5d

/*
 * Textarea Messages
 *
 * This contains all the text for the Textarea component.
 */
import { defineMessages } from "react-intl";

const idPrefix = "Textarea";

export default defineMessages({
  FS_screenReaderLabel_successDescribedBy: {
    id: `FS.${idPrefix}.screenReaderLabel_successDescribedBy`,
    defaultMessage: "You entered valid data.",
  },

  SS_screenReaderLabel_successDescribedBy: {
    id: `SS.${idPrefix}.screenReaderLabel_successDescribedBy`,
    defaultMessage: "You entered valid data.",
  },
});
