// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * WCCOfferCardCTA
 *
 */

import React from "react";
import PropTypes from "prop-types";
import {
  IntlPropType,
  trackConversionInteraction,
} from "root/libs/core-libs/src";
import { Link } from "@dhl-official/react-ui-library";
import { offerPurchaseMethods } from "root/libs/constants/src";
import { dhlVarColors } from "root/libs/ui-styleguide/src";
import styled from "styled-components";

const StyledLink = styled(Link)`
  &.highlighted {
    color: ${dhlVarColors.fontColor};
    background-color: ${dhlVarColors.secondary};
    padding-top: 16px;
    padding-bottom: 16px;

    &:hover {
      background-color: ${dhlVarColors.secondaryHover};
    }
    &:active {
      background-color: ${dhlVarColors.secondaryActive};
    }
  }
`;

const COPY_ID_PREFIX = "WCCOfferCardCTA";
const CARD_AND_SUMMARY_COPY_ID_PREFIX = "CardAndSummary";

export default function WCCOfferCardCTA({
  purchaseMethod,
  displayOffer,
  intl,
  offerIndex,
  isHighlighted,
  variationType,
  utfDataObject,
}) {
  const { supportContactInformation } = displayOffer;

  function getCopy(id, values = {}) {
    return intl.formatMessage({ id }, values);
  }

  function nullChecker(id) {
    return !!intl.messages[`${CARD_AND_SUMMARY_COPY_ID_PREFIX}.${id}`];
  };

  function onClickHandler() {
    trackConversionInteraction(utfDataObject);
  }

  const COPY_ID =
    variationType === "WCCOfferCardCTA"
      ? COPY_ID_PREFIX
      : "WCCMultiBUOfferCardCTA";

  const renderCTA = (variant, href, content) => {
    return (
      <StyledLink
        dataTestid={`wcc-offer-card-primary-cta-${offerIndex}`}
        href={href}
        withButtonStyle={{
          isBlock: true,
          size: "regular",
          variant,
        }}
        onClick={onClickHandler}
        className={isHighlighted ? "highlighted" : ""}
        rel="follow"
        target="_blank"
      >
        {content}
      </StyledLink>
    );
  };

  const getButtonLink = () => {
    if (
      !displayOffer.pathToPurchaseLink &&
      displayOffer.specialOffers &&
      nullChecker(`${displayOffer.specialOffers}_Link`)
    ) {
      return getCopy(
        `${CARD_AND_SUMMARY_COPY_ID_PREFIX}.${displayOffer.specialOffers}_Link`
      );
    }

    return displayOffer.pathToPurchaseLink;
  };

  const getButtonText = () => {
    if (
      displayOffer.specialOffers &&
      nullChecker(`${displayOffer.specialOffers}_Button`)
    ) {
      return getCopy(
        `${CARD_AND_SUMMARY_COPY_ID_PREFIX}.${displayOffer.specialOffers}_Button`
      );
    }

    return getCopy(`${COPY_ID}.${purchaseMethod}_ctaPrimary`);
  };

  const renderPrimaryCTA = () => {
    return renderCTA("primary", getButtonLink(), getButtonText());
  };

  const renderSecondaryCTA = () => {
    if (
      !supportContactInformation ||
      !Object.keys(supportContactInformation).length
    ) {
      return renderPrimaryCTA(); // If supportContactInformation is empty, render primary CTA
    }

    const hasPhoneNumber =
      supportContactInformation.phoneNumbers &&
      supportContactInformation.phoneNumbers.length > 0;
    const hasWebsiteUrl = !!supportContactInformation.websiteUrl;

    if (!hasPhoneNumber && !hasWebsiteUrl) {
      return renderPrimaryCTA(); // If no phone numbers or website URL, render primary CTA. This is for when we have "email"
    }

    const linkHref = hasPhoneNumber
      ? `tel:${supportContactInformation.phoneNumbers[0]?.phoneNumber}`
      : supportContactInformation.websiteUrl;

    const content = hasPhoneNumber
      ? supportContactInformation.phoneNumbers[0]?.phoneNumber
      : getCopy(`${COPY_ID}.${purchaseMethod}_ctaPrimary`);

    return renderCTA(
      hasPhoneNumber ? "secondary" : "primary",
      linkHref,
      content
    );
  };

  return (
    <React.Fragment>
      {(purchaseMethod === offerPurchaseMethods.CLICK ||
        purchaseMethod === offerPurchaseMethods.WALK) &&
        renderPrimaryCTA()}
      {purchaseMethod === offerPurchaseMethods.CALL && renderSecondaryCTA()}
    </React.Fragment>
  );
}

WCCOfferCardCTA.defaultProps = {
  variationType: "WCCOfferCardCTA",
  utfDataObject: {},
};

WCCOfferCardCTA.propTypes = {
  /** Internationalization library */
  intl: IntlPropType.isRequired,
  /** The index of the offer */
  offerIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  displayOffer: PropTypes.object,
  isHighlighted: PropTypes.bool,
  /** The purchase method */
  purchaseMethod: PropTypes.oneOf(Object.values(offerPurchaseMethods))
    .isRequired,
  variationType: PropTypes.string,
  utfDataObject: PropTypes.object,
};
