// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * ExpandCollapseButton
 *
 */

import React from "react";
import PropTypes from "prop-types";
import arrowUpDouble from "icons/cx/arrow-up-double.svg";
import arrowDownDouble from "icons/cx/arrow-down-double.svg";
import { TEXT_SIZES, TEXT_WEIGHTS } from "@dhl-official/react-ui-library";
import { key } from "styled-theme";
import Icon from "../../Icons/Icon";
import { ExpandCollapseButtonBubble, StyledText } from "./styled";

export default class ExpandCollapseButton extends React.PureComponent {
  static propTypes = {
    dataTestid: PropTypes.string,
    isCollapsed: PropTypes.bool.isRequired,
    expandLabel: PropTypes.string,
    collapseLabel: PropTypes.string,
    expandAriaLabel: PropTypes.string,
    collapseAriaLabel: PropTypes.string,
    /** The bubble variation wraps the icon in a circle with shadows. The flat one is just the icon */
    variation: PropTypes.oneOf(["bubble", "flat", "bubbleWithText"]).isRequired,
    onClick: PropTypes.func,
    /** If true the button in desktop will have a negative margin that will make it overflow. */
    overflowBottomMarginDesktop: PropTypes.bool,
    isVisible: PropTypes.bool,
    ariaControls: PropTypes.string,
    dataTracking: PropTypes.string,
  };

  static defaultProps = {
    dataTestid: undefined,
    expandLabel: "",
    overflowBottomMarginDesktop: false,
    collapseLabel: "",
    onClick: () => {},
    isVisible: true,
    expandAriaLabel: "",
    collapseAriaLabel: "",
    ariaControls: "",
    dataTracking: "",
  };

  render() {
    const {
      dataTestid,
      isCollapsed,
      variation,
      expandLabel,
      collapseLabel,
      onClick,
      overflowBottomMarginDesktop,
      isVisible,
      ariaControls,
      expandAriaLabel,
      collapseAriaLabel,
      dataTracking,
    } = this.props;

    const iconAriaLabel = isCollapsed ? expandLabel : collapseLabel;
    const showBubbleWithText = variation === "bubbleWithText";
    const getExpandAriaLabel =
      expandAriaLabel === "" ? expandLabel : expandAriaLabel;
    const getCollapseAriaLabel =
      collapseAriaLabel === "" ? collapseLabel : collapseAriaLabel;

    return (
      <ExpandCollapseButtonBubble
        data-testid={dataTestid}
        data-testvalue={isCollapsed}
        overflowBottomMarginDesktop={overflowBottomMarginDesktop}
        onClick={onClick}
        variation={variation}
        className={`${isVisible ? "" : "visually-hidden"} expandCollapseButton`}
        tabIndex={isVisible ? "0" : "-1"}
        aria-expanded={!isCollapsed ? "true" : "false"}
        aria-label={isCollapsed ? getExpandAriaLabel : getCollapseAriaLabel}
        aria-controls={ariaControls}
        data-tracking={dataTracking}
        type="button"
      >
        {showBubbleWithText && (
          <StyledText
            inlined
            noMargin
            weight={TEXT_WEIGHTS[700]}
            size={TEXT_SIZES.SMALLPLUS}
          >
            {isCollapsed ? expandLabel : collapseLabel}
          </StyledText>
        )}
        <Icon
          useAs="decorative"
          label={iconAriaLabel}
          icon={isCollapsed ? arrowDownDouble : arrowUpDouble}
          size="small"
          fillColor={key("colors.primary")}
          data-testid={isCollapsed ? "arrowDownDouble" : "arrowUpDouble"}
        />
      </ExpandCollapseButtonBubble>
    );
  }
}
