// 9fbef606107a605d69c0edbcd8029e5d

/**
*
* GridContainer
Main Container providing the CSS grid used
*
*/

import React from "react";
import PropTypes from "prop-types";
import { StyledGridContainer } from "./styled";

export default class GridContainer extends React.PureComponent {
  static propTypes = {
    children: PropTypes.any.isRequired,
    /** If true will add the grid-maxWidth class, used to
     * add the container settings with max-width.
     */
    addMaxWidth: PropTypes.bool,
    /** If addMaxWidth is not true, then this can be used. It adds the padding settings
     * without adding the max-width
     */
    addFluidContainer: PropTypes.bool,
    dimension: PropTypes.oneOf(["uniDimensional", "biDimensional"]),
    halfGutterPadding: PropTypes.bool,
    /** First element: mobile, second: desktop */
    bottomSpacing: PropTypes.arrayOf(PropTypes.string),
    gridJustifyContent: PropTypes.string,
    /** Class attribute to allow the component styles to be extended by another component */
    className: PropTypes.string,
    /** id for automation */
    dataTestid: PropTypes.string,
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ])
  };

  static defaultProps = {
    dimension: "uniDimensional",
    className: "",
    id: "",
    addMaxWidth: false,
    addFluidContainer: false,
    halfGutterPadding: false,
    bottomSpacing: ["0", "0"],
    gridJustifyContent: "center",
    dataTestid: undefined,
  };

  render() {
    const {
      children,
      dimension,
      addMaxWidth,
      addFluidContainer,
      halfGutterPadding,
      bottomSpacing,
      gridJustifyContent,
      className,
      dataTestid,
      id,
    } = this.props;

    const cssClasses = [];

    if (addMaxWidth) {
      cssClasses.push("grid-maxWidth");
    } else if (addFluidContainer) {
      cssClasses.push("grid-fluid");
    }

    return (
      <StyledGridContainer
        bottomSpacing={bottomSpacing}
        gridDimension={dimension}
        halfGutterPadding={halfGutterPadding}
        className={`${className} ${cssClasses.slice(" ")}`}
        gridJustifyContent={gridJustifyContent}
        data-testid={dataTestid}
        id={id}
      >
        {children}
      </StyledGridContainer>
    );
  }
}
