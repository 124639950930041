// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * OfferCard
 *
 */

import React from "react";
import PropTypes from "prop-types";
import {
  IntlPropType,
  trackContentInteraction,
  getUtfAddOnString,
} from "root/libs/core-libs/src";
import {
  keyCodes,
  businessUnits,
  UTF_EVENT_CONTENT_NAMES,
  UTF_EVENT_TYPES,
  UTF_EVENT_INTERACTIONS,
  UTF_EVENT_CONTENT_POSITION,
  UTF_EVENT_CONVERSION_NAMES,
  UTF_EVENT_CONVERSION_TYPES,
  UTF_EVENT_CONVERSION_DETAIL,
} from "root/libs/constants/src";
import { selectTrackingKey } from "one-time-shipment/src/containers/Tracking/selectors";
import {
  Text,
  TEXT_SIZES,
  BUTTON_VARIANTS,
} from "@dhl-official/react-ui-library";
import { SEGMENT_TYPES } from "one-time-shipment/src/containers/SpotShipmentPage/constants";
import { CountrySettings } from "root/libs/ui-containers/src";
import { Icon, IconButtonTooltip } from "root/libs/ui-components/src";
import WCCMultiBUOfferPriceAndPaymentInfo from "one-time-shipment/src/components/Offers/WCCMultiBUOfferPriceAndPaymentInfo";
import WCCOfferAdditionalTimings from "one-time-shipment/src/components/Offers/WCCOfferAdditionalTimings";
import AdditionalInformation from "one-time-shipment/src/components/Offers/AdditionalInformation";
import OfferAddOns from "one-time-shipment/src/components/Offers/OfferAddOns";
import OfferProperties from "one-time-shipment/src/components/Offers/OfferProperties";
import OfferCardDropdown from "one-time-shipment/src/components/Offers/OfferCardDropdown";
import OfferPromotedLine from "one-time-shipment/src/components/Offers/OfferPromotedLine";
import WCCMultiBUOfferDeliveryInfo from "one-time-shipment/src/components/Offers/WCCMultiBUOfferDeliveryInfo";
import WCCMultiBUOfferPriceBreakdown from "one-time-shipment/src/components/Offers/WCCMultiBUOfferPriceBreakdown";
import SpecialOfferInfo from "one-time-shipment/src/components/Offers/SpecialOfferInfo";
import WCCOfferCardInformation from "one-time-shipment/src/components/Offers/WCCOfferCardInformation";
import GfOceanIcon from "iconsTwoColors/cx/DHL_OceanFreight.svg";
import GfAirIcon from "iconsTwoColors/cx/DHL_AirFreight.svg";
import GfOceanAirIcon from "iconsTwoColors/cx/DHL_OceanAirFreight.svg";
import freightIcon from "iconsTwoColors/cx/DHL_RoadFreight.svg";
import expressIcon from "iconsTwoColors/cx/DHL_Express.svg";
import PnPOffersIcon from "iconsTwoColors/cx/DHL_PnPOffers.svg";
import parcelIcon from "iconsTwoColors/cx/DHL_Parcel_reduced.svg";
import {
  StyledOfferCard,
  StyledPriceContainer,
  StyledCTAWrapper,
  StyledTextAndTooltip,
  StyledEstimatedDeliveryText,
  StyledEstimatedDeliveryInfo,
  OfferCardContent,
  StyledAccordion,
  IconChevronUp,
  IconChevronDown,
  OfferCardDetailsContent,
  DeliveryInfoContent,
  DeliveryInfoWrapper,
  StyledAdditionalOfferDetails,
  ContentWrapper,
  OfferWrapper,
  StyledQuoteValidity,
} from "./styled";

const { withCountry } = CountrySettings;

const COPY_ID_PREFIX = "WCCOfferCard";

export default class WCCOfferCard extends React.PureComponent {
  static propTypes = {
    /** Internationalization library */
    intl: IntlPropType.isRequired,
    purchaseMethod: PropTypes.string,
    isBusiness: PropTypes.bool,
    offerIndex: PropTypes.number,
    offer: PropTypes.object,
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
    nativeLanguageCode: PropTypes.string,
    is12HourTimeFormat: PropTypes.bool,
    nativeDateTimeFormat: PropTypes.string,
    isExpanded: PropTypes.bool,
    expandedCardIndex: PropTypes.number,
    onCardClick: PropTypes.func,
    trackEvent: PropTypes.func,
    indexLength: PropTypes.number,
    reference: PropTypes.object,
  };

  static defaultProps = {
    onCardClick: () => {},
    trackEvent: () => {},
    reference: { current: null },
  };

  constructor(props) {
    super(props);
    this.WCCMultiBUOfferPriceAndPaymentInfoWithCountry = withCountry(
      WCCMultiBUOfferPriceAndPaymentInfo
    );
    this.OfferPropertiesWithCountry = withCountry(OfferProperties);
    this.OfferAddOnsWithCountry = withCountry(OfferAddOns);
    this.OfferPromotedLineWithCountry = withCountry(OfferPromotedLine);
    this.OfferCardDropdownWithCountry = withCountry(OfferCardDropdown);
    this.trackingKeySelector = selectTrackingKey;
    this.idExploredOfferTrackingKeyPath = this.trackingKeySelector(
      "spotShipment.interactions.offer.idExploredOffer"
    );
    this.state = {
      isAccordionOpen: false,
    };
    this.priceBreakdown = React.createRef();
  }

  getCopy = (id) => {
    const { intl } = this.props;
    return intl.formatMessage(
      { id },
      {
        br: <br key="br" />,
      }
    );
  };

  getOfferTypeIcon = (key, businessUnit) => {
    // Fallback when businessUnit is not provided.
    if (
      typeof businessUnit === "undefined" &&
      key.includes(businessUnits.EXPRESS)
    ) {
      return expressIcon;
    }

    switch (businessUnit) {
      case businessUnits.DGF:
        if (key.includes("OFR")) {
          return GfOceanIcon;
        }
        if (key.includes("AFR")) {
          return GfAirIcon;
        }
        return GfOceanAirIcon;
      case businessUnits.FREIGHT:
        return freightIcon;
      case businessUnits.EXPRESS:
        return expressIcon;
      case businessUnits.PNP:
        return PnPOffersIcon;
      default:
        return parcelIcon;
    }
  };

  getPromotedProperty = (displayOffer) => {
    if (Array.isArray(displayOffer.properties)) {
      return displayOffer.properties
        .filter((property) => property.promoted === true)
        .pop();
    }
    return undefined;
  };

  showAdditionalInformation = () => {
    const { offer } = this.props;

    return (
      Array.isArray(offer.additionalInformation) &&
      offer.additionalInformation.length > 0
    );
  };

  showECSInfoMessage = () => {
    const { offer } = this.props;

    const { information } = offer;

    return (
      information &&
      information.find((element) => element.category === "INFO") &&
      information.find(
        (element) => element.header !== "PALLET_REQUIRED_INFORMATION"
      )
    );
  };

  onCardClick = () => {
    const { offer, onCardClick } = this.props;

    onCardClick({ ...offer });
  };

  onKeyPress = (event) => {
    const { keyCode } = event;
    if (keyCode === keyCodes.RETURN || keyCode === keyCodes.SPACE) {
      event.preventDefault();
      this.onCardClick();
    }
  };

  onClickOffersDetailsAccordion = () => {
    const { trackEvent, isBusiness, offer } = this.props;
    const { isAccordionOpen } = this.state;
    const key = offer?.key;

    trackEvent({ idExploredOffer: key });

    if (!isAccordionOpen) {
      trackContentInteraction({
        name: UTF_EVENT_CONTENT_NAMES.EXPAND_CARD,
        type: UTF_EVENT_TYPES.BUTTON,
        interaction: UTF_EVENT_INTERACTIONS.CLICK,
        position: UTF_EVENT_CONTENT_POSITION.PRODUCT_CARD,
        context: isBusiness ? SEGMENT_TYPES.business : SEGMENT_TYPES.private,
        attributes: { key },
      });
    }

    this.setState({ isAccordionOpen: !isAccordionOpen });
  };

  getRef = (ref) => {
    this.priceBreakdown = ref;
  };

  focusPriceBreakdown = () => {
    const { offerIndex, purchaseMethod } = this.props;
    const { isAccordionOpen } = this.state;
    if (this.priceBreakdown && this.priceBreakdown.current) {
      let timeout = 0;
      if (!isAccordionOpen) {
        document
          .querySelector(
            `button[data-testid=expand-collapse-${purchaseMethod.toLowerCase()}-${offerIndex}]`
          )
          .click();
        timeout = 300;
      }

      setTimeout(() => {
        const headerOffset = 300;
        const elementPosition = this.priceBreakdown.current.getBoundingClientRect()
          .top;
        const offsetPosition = elementPosition + window.scrollY - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }, timeout);
    }
  };

  render() {
    const {
      intl,
      purchaseMethod,
      isBusiness,
      offerIndex,
      offer,
      isFirst,
      isLast,
      nativeLanguageCode,
      is12HourTimeFormat,
      nativeDateTimeFormat,
      isExpanded,
      expandedCardIndex,
      indexLength,
      reference,
    } = this.props;

    const displayOffer = offer;
    const promotedProperty = this.getPromotedProperty(displayOffer);
    const additionalInfoECSMessage = this.showECSInfoMessage();
    const priceBreakdownId = `price-breakdown`;

    const multiBUId = `${purchaseMethod.toLowerCase()}-${offerIndex}`;

    const offerTypeIcon = this.getOfferTypeIcon(
      displayOffer.key,
      displayOffer.businessUnit
    );

    const ariaLabelledBy = `wcc-offer-card-headline-${offerIndex} wcc-offer-card-sub-headline-${offerIndex} ${displayOffer.key}-price ${displayOffer.key}-starBehindPrice ${displayOffer.key}-payment wcc-offer-card-wrapper-more-information`;
    const accordionId = `wcc-offer-card-accordion-${multiBUId}`;

    let finalAdditionalInfoList =
      Array.isArray(displayOffer.additionalInformation) &&
      displayOffer.additionalInformation.length > 0
        ? displayOffer.additionalInformation
        : [];

    if (additionalInfoECSMessage) {
      finalAdditionalInfoList = [
        ...finalAdditionalInfoList,
        ...[{ key: additionalInfoECSMessage.message }],
      ];
    }

    const shippingModesString = getUtfAddOnString(displayOffer.addOns);

    const deliveryInfoExists = !!intl.messages[
      `${COPY_ID_PREFIX}.${purchaseMethod}_deliveryInfo`
    ];

    const isSpecialOffer = 
    !!(
      typeof offer.specialOffers !== "undefined" &&
      offer.specialOffers.length > 0
    );

    return (
      <OfferWrapper className="grid-maxWidth">
        <StyledOfferCard
          key={`offer-${purchaseMethod}`}
          isFirst={isFirst}
          isLast={isLast}
          isExpanded={isExpanded}
          expandedCardIndex={expandedCardIndex}
          offerIndex={offerIndex}
          iAmExpanded={offerIndex === expandedCardIndex}
          data-testid={`wcc-offer-card-${multiBUId}`}
          aria-label={intl.formatMessage(
            { id: `${COPY_ID_PREFIX}.screenReaderLabel_offersAmount` },
            {
              currentOfferIndex: offerIndex + 1,
              totalOffersAmount: indexLength,
            }
          )}
          tabIndex={0}
          aria-labelledby={ariaLabelledBy}
        >
          <OfferCardContent
            iAmExpanded={offerIndex === expandedCardIndex}
            expandedCardIndex={expandedCardIndex}
          >
            <DeliveryInfoContent>
              <DeliveryInfoWrapper>
                <Icon
                  icon={offerTypeIcon}
                  width={{
                    mobile: "72px",
                    desktop: "72px",
                  }}
                  height={{
                    mobile: "52px",
                    desktop: "52px",
                  }}
                  useAs="decorative"
                />
                <ContentWrapper>
                  {isSpecialOffer ? (
                    <SpecialOfferInfo offer={offer} purchaseMethod={purchaseMethod} offerIndex={multiBUId} textSize={TEXT_SIZES.SMALLPLUS} />
                  ) : (
                    <React.Fragment>
                      <StyledEstimatedDeliveryText
                        isLast={isLast}
                        isFirst={isFirst}
                        expandedCardIndex={expandedCardIndex}
                        size={TEXT_SIZES.SMALLPLUS}
                        iAmExpanded={offerIndex === expandedCardIndex}
                        id={`${displayOffer.key}-firstline`}
                        isParagraph
                        dataTestid={`wcc-offer-card-estimated-delivery-text-${multiBUId}`}
                      >
                        {this.getCopy(
                          displayOffer.deliveryType === "COMMITTED"
                            ? "CardAndSummary.committedDelivery"
                            : "CardAndSummary.estimatedDelivery"
                        )}
                      </StyledEstimatedDeliveryText>
                      <WCCMultiBUOfferDeliveryInfo
                        intl={intl}
                        displayOffer={displayOffer}
                        offerIndex={multiBUId}
                        offerType={offerIndex}
                        offer={offer}
                        estimatedDeliveryTime={displayOffer.estimatedDeliveryTime}
                        nativeDateTimeFormat={nativeDateTimeFormat}
                        estimatedDeliveryTimeDisplayType={
                          displayOffer.estimatedDeliveryTimeDisplayType
                        }
                        is12HourTimeFormat={is12HourTimeFormat}
                        nativeLanguageCode={nativeLanguageCode}
                        offerId={displayOffer.id}
                        variationType="WCCMultiBUOfferCard"
                      />
                      {displayOffer.businessUnit === "EXP" && (
                        <WCCOfferAdditionalTimings
                          intl={intl}
                          offerIndex={multiBUId}
                          offerId={displayOffer.id}
                          useFallback
                          isMultiBU
                        />
                      )}
                    </React.Fragment>
                  )}
                  {deliveryInfoExists && (
                    <StyledEstimatedDeliveryInfo
                      isLast={isLast}
                      isFirst={isFirst}
                      expandedCardIndex={expandedCardIndex}
                      iAmExpanded={offerIndex === expandedCardIndex}
                      id={`${displayOffer.key}-delivery-info`}
                      isParagraph
                      dataTestid={`wcc-offer-card-estimated-delivery-info-${multiBUId}`}
                      size={TEXT_SIZES.TINY}
                    >
                      {this.getCopy(
                        `${COPY_ID_PREFIX}.${purchaseMethod}_deliveryInfo`
                      )}
                    </StyledEstimatedDeliveryInfo>
                  )}
                  {(promotedProperty && !isSpecialOffer) && (
                    <StyledCTAWrapper>
                      <this.OfferPromotedLineWithCountry
                        offerKey={displayOffer.key}
                        cutOffDateTime={displayOffer.cutOffDateTime}
                        intl={intl}
                        offerIndex={multiBUId}
                        promotedProperty={promotedProperty}
                        pickUpDate={displayOffer.pickUpDate}
                        iNeedTheseProps={[
                          "is12HourTimeFormat",
                          "nativeLanguageCode",
                          "nativeDateTimeFormat",
                        ]}
                        purchaseMethod={purchaseMethod}
                      />
                    </StyledCTAWrapper>
                  )}
                  <WCCOfferCardInformation
                    id={`${displayOffer.key}-wcc-information`}
                    offerKey={displayOffer.key}
                    businessUnit={offer.businessUnit}
                    purchaseMethod={purchaseMethod}
                    intl={intl}
                    offerIndex={multiBUId}
                    locatorLink={displayOffer.locatorLink}
                    isBusiness={isBusiness}
                    copyPrefix="WCCMultiBUOfferCardInformation"
                    offer={offer}
                  />
                </ContentWrapper>
              </DeliveryInfoWrapper>
            </DeliveryInfoContent>
            <StyledPriceContainer
              isFirst={isFirst}
              isLast={isLast}
              expandedCardIndex={expandedCardIndex}
              iAmExpanded={offerIndex === expandedCardIndex}
            >
              <this.WCCMultiBUOfferPriceAndPaymentInfoWithCountry
                priceBreakdownAnchor={this.focusPriceBreakdown}
                priceBreakdownRef={priceBreakdownId}
                accordionAnchor={`expand-collapse-${multiBUId}`}
                displayOffer={displayOffer}
                purchaseMethod={purchaseMethod}
                key={displayOffer.id}
                offerIndex={multiBUId}
                intl={intl}
                offer={displayOffer}
                isBusiness={isBusiness}
                showCurrencyIsoCodeForPrices={false}
                iNeedTheseProps={[
                  "formatCurrencyWithNativeLocale",
                  "isExpressAllowedOnMobile",
                ]}
                nativeLanguageCode={nativeLanguageCode}
                utfDataObject={{
                  conversion: {
                    name: UTF_EVENT_CONVERSION_NAMES.BOOKING_INTENT,
                    type: UTF_EVENT_CONVERSION_TYPES.HANDOVER,
                    detail: UTF_EVENT_CONVERSION_DETAIL[purchaseMethod],
                  },
                  products: [{
                    id: displayOffer.id,
                    key: displayOffer.key,
                    businessUnit: displayOffer.businessUnit,
                    price: displayOffer.price.amount,
                    currency: displayOffer.price.currencyCode,
                    shippingModes: shippingModesString,
                  },],
                  content: {
                    name: UTF_EVENT_CONTENT_NAMES.CONTINUE_TO_BOOKING,
                    type: UTF_EVENT_TYPES.BUTTON,
                    interaction: UTF_EVENT_INTERACTIONS.CLICK,
                    position: UTF_EVENT_CONTENT_POSITION.PRODUCT_CARD,
                    context: isBusiness
                      ? SEGMENT_TYPES.business
                      : SEGMENT_TYPES.private,
                  },
                }}
              />
            </StyledPriceContainer>
          </OfferCardContent>

          <span id={`${displayOffer.key}-moreInfo`} className="visually-hidden">
            {intl.formatMessage({
              id: `${COPY_ID_PREFIX}.screenReaderLabel_moreInformation`,
            })}
          </span>
          <StyledAccordion
            useAsCollapsible
            showExpandCollapseButton
            expandCollapseButtonVariation="bubbleWithText"
            expandCollapseButtonTestId={`expand-collapse-${multiBUId}`}
            expandButtonLabel={intl.formatMessage({
              id: `OfferCardHorizontal.exploreOffer`,
            })}
            collapseButtonLabel={intl.formatMessage({
              id: `OfferCardHorizontal.closeOffer`,
            })}
            expandButtonAriaLabel={intl.formatMessage({
              id: `OfferCardHorizontal.screenReaderLabel_ariaExploreOffer`,
            })}
            collapseButtonAriaLabel={intl.formatMessage({
              id: `OfferCardHorizontal.screenReaderLabel_ariaCloseOffer`,
            })}
            buttonVariant={BUTTON_VARIANTS.TEXT}
            expandButtonAriaControls={`${accordionId}-content`}
            expandButtonDataTracking={this.idExploredOfferTrackingKeyPath}
            onTitleClick={this.onClickOffersDetailsAccordion}
            collapseIcon={<IconChevronUp />}
            expandIcon={<IconChevronDown />}
          >
            <StyledAccordion.Title
              id={`${accordionId}-title`}
              ariaControls={`${accordionId}-content`}
              onKeyDown={this.onKeyPress}
              ref={reference}
              dataTracking={this.idExploredOfferTrackingKeyPath}
              trackingKeySelector={this.trackingKeySelector}
            />
            <StyledAccordion.Content
              id={`${accordionId}-content`}
              labeledBy={`${accordionId}-title`}
              className="offerCollapsibleContent"
            >
              <OfferCardDetailsContent>
                <div>
                  <this.OfferPropertiesWithCountry
                    properties={displayOffer.properties}
                    offerId={displayOffer.key}
                    estimatedDeliveryTime={displayOffer.estimatedDeliveryTime}
                    intl={intl}
                    iNeedTheseProps={["is12HourTimeFormat"]}
                    variationType="WCCMultiBUOfferCard"
                  />
                </div>
                <this.OfferAddOnsWithCountry
                  trackEvent={() => {}}
                  addOns={displayOffer.addOns}
                  offerKey={displayOffer.key}
                  intl={intl}
                  iNeedTheseProps={[
                    "nativeLanguageCode",
                    "formatCurrencyWithNativeLocale",
                  ]}
                  variationType="WCCMultiBUOfferCard"
                />
              </OfferCardDetailsContent>
              <StyledAdditionalOfferDetails>
                {(this.showAdditionalInformation() ||
                  additionalInfoECSMessage) && (
                  <AdditionalInformation
                    intl={intl}
                    additionalInformationList={finalAdditionalInfoList}
                  />
                )}
                <StyledTextAndTooltip>
                  <Text
                    dataTestid={`wcc-offer-card-text-and-tooltip-${multiBUId}`}
                  >
                    {this.getCopy(
                      `${COPY_ID_PREFIX}.${purchaseMethod}_parcelSendingInstructionText`
                    )}
                  </Text>
                  <IconButtonTooltip
                    isFirst={isFirst}
                    expandedCardIndex={expandedCardIndex}
                    id={`wcc-offer-card-text-and-tooltip-${offerIndex}`}
                    ariaLabel={this.getCopy(
                      `${COPY_ID_PREFIX}.${purchaseMethod}_parcelSendingInstructionText`
                    )}
                    name={`wcc-offer-card-text-and-tooltip-${offerIndex}`}
                    iconType="info"
                    tooltipContent={
                      <Text size={TEXT_SIZES.TINY}>
                        {this.getCopy(
                          `${COPY_ID_PREFIX}.${purchaseMethod}_parcelSendingTooltip`
                        )}
                      </Text>
                    }
                  />
                </StyledTextAndTooltip>
                {isBusiness && (
                  <WCCMultiBUOfferPriceBreakdown
                    id={priceBreakdownId}
                    offerIndex={multiBUId}
                    offer={displayOffer}
                    intl={intl}
                    showCurrencyIsoCodeForPrices={false}
                    nativeLanguageCode={nativeLanguageCode}
                    iNeedTheseProps={["formatCurrencyWithNativeLocale"]}
                    getRef={this.getRef}
                  />
                )}
              </StyledAdditionalOfferDetails>
              <StyledQuoteValidity
                businessUnit={displayOffer.businessUnit}
                variationType="WCCMultiBUOfferCard"
              />
            </StyledAccordion.Content>
          </StyledAccordion>
        </StyledOfferCard>
      </OfferWrapper>
    );
  }
}
