// 9fbef606107a605d69c0edbcd8029e5d

import { featureFlags } from "root/libs/constants/src";

export const defaultMetricKeys = {
  memoryMetrics: {
    jsHeapSizeLimit: 0,
    totalJSHeapSize: 0,
    usedJSHeapSize: 0,
  },
  uiMetrics: {
    requestTime: 0,
    responseTime: 0,
    totalRequestResponseTime: 0,
    perceivedPageLoadTime: 0,
    firstPaint: 0,
    firstConsistentlyInteractiveinMs: 0,
    longtask: 0,
  },
};
export const extensiveMetricKeys = {
  memoryMetrics: {
    jsHeapSizeLimit: 0,
    totalJSHeapSize: 0,
    usedJSHeapSize: 0,
  },
  uiMetrics: {
    dnsTime: 0,
    connectTime: 0,
    tlsConnectTime: 0,
    fetchTime: 0,
    requestTime: 0,
    responseTime: 0,
    totalRequestResponseTime: 0,
    compressionRatio: 0,
    encodedBodySize: 0,
    pageLoadTime: 0,
    headerSize: 0,
    timeToFirstByte: 0,
    domRenderTime: 0,
    domLoadingTime: 0,
    domComplete: 0,
    domInteractive: 0,
    perceivedPageLoadTime: 0,
    firstMeaningfulPaint: 0,
    firstPaint: 0,
    firstContentFullPaint: 0,
    firstConsistentlyInteractiveinMs: 0,
  },
  mediaResourceList: [],
  otherResourceList: [],
  apiResourceList: [],
};

export default {
  v1: {
    domLoadingTime: 0,
    firstConsistentlyInteractiveinMs: 0,
    firstMeaningfulPaint: 0,
    perceivedPageLoadTime: 0,

    // following are not populating at the moment
    firstPaint: 0,
    firstContentFullPaint: 0,
    domComplete: 0,
    domInteractive: 0,
  },
  v2: featureFlags.trackPerformanceExtensively
    ? extensiveMetricKeys
    : defaultMetricKeys,
};
