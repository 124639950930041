// 9fbef606107a605d69c0edbcd8029e5d

/**
 * Helper function to normalize and prepare a string for search/string comparison
 * by trimming, putting to lowercase and removing diacritical marks
 */
import deburr from "lodash/deburr";

export default (input, skipDeburrString) =>
  skipDeburrString
    ? input.trim().toLowerCase()
    : deburr(input.trim().toLowerCase());
