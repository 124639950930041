// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for WCCMultiBUOfferPriceAndPaymentInfo
 *
 */

import styled from "styled-components";
import { key } from "styled-theme";
import { breakpoints } from "root/libs/ui-styleguide/src";
import { Headline } from "root/libs/ui-components/src";
import VatInfoMessage from "one-time-shipment/src/components/Offers/VatInfoMessage";

export const CardPriceHeadline = styled(Headline)`
  margin: ${(props) => props.bottomMargin};

  @media screen and (max-width: ${breakpoints.tablet}) {
    font-size: ${key("fonts.mobile.h2.size")} !important;
    line-height: ${key("fonts.mobile.h2.lineHeight")} !important;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: 0;
  }
`;

export const CardPriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media screen and (min-width: ${breakpoints.tablet}) {
    align-items: center;
  }

  @media print {
    width: 30%;
  }

  .price-label {
    color: ${key("colors.headerColor")};
    margin: 10px 0;

    @media screen and (min-width: ${breakpoints.tablet}) {
      margin-bottom: 3px;
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      margin-bottom: 0;
    }
  }
`;

export const CardButtonWrapper = styled.div`
  margin-bottom: 5px;

  button {
    width: 100%;
  }

  @media print {
    display: none;
  }
`;

export const DisclaimerStar = styled.span`
  font-weight: 200;
`;

export const StyledVatInfoMessage = styled(VatInfoMessage)`
  top: 0;
  right: 10px;
  color: ${key("colors.primary")};
  background-color: ${key("colors.backgroundColorInverted")};
  border: 1px solid ${key("colors.primary")};
  padding: 4px 10px 4px;
  font-size: 10px;
  line-height: 1;

  @media screen and (min-width: 1150px) {
    top: 0;
    right: 0;
  }
`;

export const PaymentInfoAndVatMessageWrapper = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.isPaymentIcons ? "space-between" : "flex-end"};
  padding-top: ${(props) => (props.isInvoiceOnly ? "5px" : 0)};
  align-items: baseline;

  @media screen and (min-width: ${breakpoints.tablet}) {
    align-items: center;
  }

  @media print {
    width: 30%;
  }
`;
