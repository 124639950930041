// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for LanguageSelector
 *
 */

import styled from "styled-components";
import { breakpoints } from "root/libs/ui-styleguide/src";

export const LanguageSelectorWrapper = styled.div`
  @media screen and (min-width: ${breakpoints.desktop}) {
    width: 180px;
  }
`;
