// 9fbef606107a605d69c0edbcd8029e5d

import pickBy from "lodash/pickBy";
import isArray from "lodash/isArray";
import escapeRegExp from "lodash/escapeRegExp";
import {
  trackContentInteraction,
  getUtfValidityString,
} from "root/libs/core-libs/src";
import { SEGMENT_TYPES } from "one-time-shipment/src/containers/SpotShipmentPage/constants";
import {
  UTF_EVENT_TYPES,
  UTF_EVENT_INTERACTIONS,
  UTF_EVENT_CONTENT_POSITION,
} from "root/libs/constants/src";

export const isInteger = (value) => /[-+]?[0-9]/.test(value);
export const isFloat = (value) => /^\d(?:\.)\d{1,2}?$/.test(value);
export const isTrueString = (stringBoolean) => stringBoolean === "true";
export const normalizeDecimalSeparatorForValidation = (value) =>
  value.replace(",", ".");
export const getFirstTrueBoolKey = (object) =>
  Object.keys(pickBy(object, (value) => value === true))[0];
export const toArray = (input) => (input ? input.split(",") : []);

const cachedUTFEvents = new Set();

const fireUTFEvent = (field, failedRules) => {
  const checkIfUTFEventAlreadyFired = (data) => {
    return cachedUTFEvents.has(JSON.stringify(data));
  };

  // Regex is required, to remove the index suffix from the name (useful in shipment lane indexed fields)
  if (field?.name || field?.utfName) {
    const data = {      
      name:
        field?.utfName?.replace(/[-0-9]/g, "") ||
        field?.name?.replace(/[-0-9]/g, ""),
      type: UTF_EVENT_TYPES.INPUT_ERROR,
      interaction: UTF_EVENT_INTERACTIONS.VALIDATION,
      position: field?.utfPosition || UTF_EVENT_CONTENT_POSITION.GLOBAL,
      context: field?.isBusiness
        ? SEGMENT_TYPES.business
        : SEGMENT_TYPES.private,
      attributes: failedRules,
    };

    if (!checkIfUTFEventAlreadyFired(data)) {
      trackContentInteraction(data);
      cachedUTFEvents.add(JSON.stringify(data));
    }
  }
};

/** *
 * This function is called by our components to get from a DOM element
 * the object that is required by the validation library to work.
 * This object will be passed from react to redux via dispatcher
 */
const extractValidationProperties = (element) => {
  const {
    name,
    value,
    type,
    required,
    pattern,
    min,
    max,
    checked,
    options,
    selectedIndex,
    dataset,
  } = element;

  // Note: the spec says that within the dataset all data attr. with names containing a
  // dash will have no dash and the first letter of the next word will be upper case expression:
  // data-min-value becomes: dataset.minValue
  let validationAttributes = {
    required: isTrueString(required) || !!required,
    pattern,
  };

  if (dataset) {
    const {
      blacklistedValues,
      whitelistedValues,
      warRestrictedValues,
    } = dataset;

    validationAttributes = {
      ...validationAttributes,
      // refactor the or
      min: dataset.minValue || min,
      max: dataset.maxValue || max,
      validateDecimals: isTrueString(dataset.validateDecimals),
      isNumber: isTrueString(dataset.isNumber),
      /** An array of blacklisted values as strings */
      blacklistedValues: toArray(blacklistedValues),
      /** An array of whitelisted values as strings */
      whitelistedValues: toArray(whitelistedValues),
      /** An array of war restricted values as strings */
      warRestrictedValues: toArray(warRestrictedValues),
    };
  }

  const needsValidation =
    !!validationAttributes.min ||
    !!validationAttributes.max ||
    !!validationAttributes.isNumber ||
    !!validationAttributes.pattern ||
    !!validationAttributes.required ||
    !!validationAttributes.blacklistedValues ||
    !!validationAttributes.whitelistedValues ||
    !!validationAttributes.warRestrictedValues;
  return {
    name,
    value:
      dataset && dataset.valueForValidation
        ? dataset.valueForValidation
        : value,
    type,
    checked,
    options,
    selectedIndex,
    needsValidation,
    validationAttributes,
  };
};

/**
 * Receives an object with:
 * {
 *   name:          '[Required] fieldName',
 *   type:          '[Required]. type of the input or select'
 *   value:         '[Required] fieldValue'
 *   checked:       '[Optional] true or false if it is a checkbox or radio button'
 *   options:       '[Optional] the options of the select'
 *   selectedIndex  '[Optional] the selectedIndex of the select;
 *
 *
 *   validationAttributes: {
 *      required:            '[Optional] if the element is required or not'
 *      pattern:             '[Optional] the regexp pattern to use for validation'
 *      isNumber:            '[Optional] If true will validate numbers.'
 *      min:                 '[Optional] min value allowed for number fields'
 *      max:                 '[Optional] max value allowed for number fields'
 *      whitelistedValues    '[Optional] the value that we get, must be inside this array of strings'
 *      blacklistedValues     [Optional] the value that we get, can not be inside this array of strings'
 *      warRestrictedValues   [Optional] the value that we get, can not be inside the array of strings
 *   }
 * }
 *
 * Then will do the validation and will return an object, telling us if the element is valid or not.
 */
export const validate = (field) => {
  const {
    required,
    pattern,
    step,
    min,
    max,
    validateDecimals,
    isNumber,
    blacklistedValues,
    whitelistedValues,
    warRestrictedValues,
  } = field.validationAttributes;

  const { value, type, checked, options, selectedIndex } = field;

  const length = value ? value.trim().length : 0;
  const isNum = type === "number" || isNumber;
  const isDecimals = !!isNum && (!!min || !!max) && !!validateDecimals;

  let decimalsValue;
  const normalizedDecimalValue = value
    ? normalizeDecimalSeparatorForValidation(value)
    : null;
  if (isDecimals) {
    decimalsValue = parseFloat(normalizedDecimalValue);
  }

  const isDecimalsRangeOverflow =
    isDecimals && length >= 1 && decimalsValue > parseFloat(max);
  const isIntegerRangeOverflow =
    isNum && length >= 1 && parseInt(value, 10) > parseInt(max, 10);

  const isDecimalsRangeUnderflow =
    isDecimals && length >= 1 && decimalsValue < parseFloat(min);
  const isIntegerRangeUnderflow =
    isNum && length >= 1 && parseInt(value, 10) < parseInt(min, 10);

  const valueMatchInBlacklistRegexp = new RegExp(
    `^${escapeRegExp(value)}.*`,
    "i"
  );
  const valueMatchInWhitelistRegexp = new RegExp(
    `^${escapeRegExp(value)}$`,
    "i"
  );
  const valueMatchInWarRestrictedRegexp = new RegExp(
    `^${escapeRegExp(value)}.*`,
    "i"
  );

  const isInBlackList =
    blacklistedValues &&
    !!blacklistedValues.find((blacklisted) =>
      valueMatchInBlacklistRegexp.test(blacklisted)
    );
  const isInWhiteList =
    whitelistedValues &&
    !!whitelistedValues.find((whitelisted) =>
      valueMatchInWhitelistRegexp.test(whitelisted)
    );
  const isInWarRestricted =
    warRestrictedValues &&
    !!warRestrictedValues.find((warRestrictedList) =>
      valueMatchInWarRestrictedRegexp.test(warRestrictedList)
    );

  /* 
  This special logic is implemented because of conflicting countries, 
  which can be removed when we go back to normal.
   */
  const dynamicValidationKeys = [];
  if (warRestrictedValues) {
    warRestrictedValues.forEach((val) => {
      const keyName = `_${val}_restrictionMessage`;
      dynamicValidationKeys[keyName] =
        warRestrictedValues &&
        warRestrictedValues.length > 0 &&
        isInWarRestricted === true &&
        value === val;
    });
  }

  const validity = {
    ...dynamicValidationKeys,
    // value does not conform to the pattern
    patternMismatch:
      !!pattern && length > 0 && !new RegExp(`^(?:${pattern})$`).test(value),
    // value of a number field is higher than the max attribute
    rangeOverflow: !!max && (isIntegerRangeOverflow || isDecimalsRangeOverflow),
    // value of a number field is lower than the min attribute
    rangeUnderflow:
      !!min && (isIntegerRangeUnderflow || isDecimalsRangeUnderflow),
    // value of a number field does not conform to the step attribute
    stepMismatch:
      !!step &&
      step !== "any" &&
      isNum &&
      Number(value) % parseFloat(step) !== 0,
    // required field without a value
    valueMissing:
      !!required &&
      (((type === "checkbox" || type === "radio") && !checked) ||
        (type === "select" && options[selectedIndex].value < 1) ||
        (type !== "checkbox" &&
          type !== "radio" &&
          type !== "select" &&
          length < 1)),
    // If the entered value is in the blacklisted list or if it is not in the white list
    incorrectValue:
      (blacklistedValues &&
        blacklistedValues.length > 0 &&
        isInBlackList === true) ||
      (whitelistedValues &&
        whitelistedValues.length > 0 &&
        isInWhiteList === false),
  };

  const { valid, utfString } = getUtfValidityString(validity);
  const failedRules = {
    failedRules: utfString,
  };

  if (!valid) {
    fireUTFEvent(field, failedRules);
  }

  return {
    validity,
    valid,
  };
};

export const validateSingleElement = (element) => {
  const { needsValidation, name } = element;

  if (!needsValidation) {
    return {
      isValid: false,
      name,
    };
  }

  const validationResult = validate(element);
  const { validity, valid } = validationResult;

  return {
    feedbackMessageId: !valid ? getFirstTrueBoolKey(validity) : undefined,
    hasError: !valid,
    isValid: Boolean(valid),
    name,
  };
};

export const validateRequiredGroup = (elements) => {
  const elementErrors = elements.reduce((acc, cur) => {
    const validation = validateSingleElement(cur);

    return validation.hasError ? [...acc, validation] : acc;
  }, []);

  /**
   * Here we check for the number of errors in the Checkbox Group.
   * If they are the same number as the checkbox elements, it means no checkbox is checked at all,
   * which returns an invalid state.
   * If there are less errors, it means at least one checkbox is checked,
   * therefore the whole group has a valid state, which then gets returned.
   * In an invalid case, it's enough to just return one of the error instances.
   */
  if (elements.length === elementErrors.length) {
    return elementErrors[0];
  }

  return {
    name: elements[0].name,
    isValid: true,
  };
};

const validateElement = (whatToValidate) =>
  !isArray(whatToValidate)
    ? validateSingleElement(whatToValidate)
    : validateRequiredGroup(whatToValidate);

export default {
  validateElement,
  extractValidationProperties,
};
