// 9fbef606107a605d69c0edbcd8029e5d

import styled, { css } from "styled-components";
import { FeedbackMessage } from "root/libs/ui-components/src";
import { Text } from "@dhl-official/react-ui-library";
import { breakpoints, shakeAnimation } from "root/libs/ui-styleguide/src";
import { key } from "styled-theme";

const gap = "20px";

export const LocationSelectorWrapper = styled.div`
  .autocomplete {
    vertical-align: top;
    position: relative;

    > div:empty {
      display: none;
    }
  }

  @supports (display: grid) {
    display: grid;
    grid-template-areas:
      "country"
      "others";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-gap: ${gap};

    @media screen and (min-width: ${breakpoints.tablet}) {
      grid-template-areas: "country others";
      grid-template-columns: 1fr 1.5fr;
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media print {
    display: flex;
    flex-direction: row;
  }
`;

export const ieCountryAutocompleteWrapper = css`
  /* Only for IE11 */
  @media all and (-ms-high-contrast: none) {
    display: inline-block;
    min-width: inherit;
    width: 49%;
    float: left;
  }

  /* Only for EDGE */
  /* stylelint-disable selector-type-no-unknown */
  _:-ms-lang(x),
  _:-webkit-full-screen,
  & {
    display: inline-block;
    min-width: inherit;
    width: 49%;
    float: left;
    @supports (display: grid) {
      width: 100%;
    }
  }

  /* stylelint-enable selector-type-no-unknown */
`;

export const CountryAutocompleteWrapper = styled.div`
  display: block;

  .autocomplete {
    width: 100%;
    display: block !important;
  }

  ${ieCountryAutocompleteWrapper}
`;

export const ieOthersWrapper = css`
  /* Only for IE11 */
  @media all and (-ms-high-contrast: none) {
    display: inline-block;
    min-width: inherit;
    width: 49%;
    float: right;
  }

  /* Only for EDGE */
  /* stylelint-disable selector-type-no-unknown */
  _:-ms-lang(x),
  _:-webkit-full-screen,
  & {
    display: inline-block;
    min-width: inherit;
    width: 49%;
    float: right;
    @supports (display: grid) {
      width: 100%;
    }
  }
  /* stylelint-enable selector-type-no-unknown */

  @media screen and (min-width: ${breakpoints.tablet}) {
    > * {
      &:nth-child(odd) {
        width: ${(props) => (props.hasZipField ? "68%" : "100%")};
        padding-right: ${(props) => (props.hasZipField ? gap : 0)};
      }

      &:nth-child(even) {
        width: ${(props) => (props.hasZipField ? "30%" : "100%")};
      }

      &.text {
        width: 100%;
      }
    }
  }
`;

export const OthersWrapper = styled.div`
  margin-bottom: 20px;
  ${ieOthersWrapper}

  /* Only for IE11/browser not supporting grid */
  .autocomplete-mocks {
    display: inline-block;
  }

  @supports (display: grid) {
    .autocomplete-mocks {
      display: block;
    }
  }

  @supports (display: grid) {
    grid-area: others;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-gap: ${gap};

    > * {
      margin-top: 0;
    }
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: 0;

    .autocomplete:last-of-type,
    .autocomplete-mocks:last-of-type {
      min-width: ${(props) => (props.hasZipField ? "125px" : "initial")};
    }

    .autocomplete:first-of-type,
    .autocomplete-mocks:first-of-type {
      max-width: ${(props) => (props.hasZipField ? "400px" : "100%")};
    }

    @supports (display: grid) {
      grid-template-columns: ${(props) =>
        props.hasZipField ? "1fr 1fr" : "1fr"};
      grid-column-gap: ${(props) => (props.hasZipField ? gap : "0")};
      grid-row-gap: 15px;

      > * {
        &:nth-child(odd) {
          width: 100%;
          padding-right: 0;
        }

        &:nth-child(even) {
          width: 100%;
        }
      }
    }
  }

  @media print {
    display: flex;
    flex-direction: row;
  }
`;

export const feedbackMessageCSS = css`
  width: 100%;
  margin-bottom: 0;
  padding: 8px 15px;

  &:nth-child(odd) {
    padding-right: 14px;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    @supports (display: grid) {
      grid-column: 1 / span 2;
    }
  }
`;

export const StyledFeedbackMessage = styled(FeedbackMessage)`
  margin-top: ${({ variation }) =>
    variation === "error" ? "-8px" : undefined};
  margin-left: ${({ variation }) =>
    variation === "error" ? "-14px" : undefined};
  ${feedbackMessageCSS}
`;

export const StyledCrimeaMessage = styled(Text)`
  ${feedbackMessageCSS}
  color: ${key("colors.fontColor")};
  border: 2px solid ${key("colors.warningColor")};
  background-color: ${key("colors.warningBackgroundColor")};
  padding-left: 50px;
  position: relative;

  &::after {
    content: "!";
    position: absolute;
    width: 22px;
    height: 22px;
    border: 2px solid #000;
    border-radius: 50%;
    left: 15px;
    top: 9px;
    font-weight: 700;
    text-align: center;
    font-size: 1.2em;
    line-height: 1.2em;
  }

  span {
    display: block;
  }

  ${shakeAnimation}
`;
