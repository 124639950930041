// 9fbef606107a605d69c0edbcd8029e5d

import React from "react";
import { createRoot } from "react-dom/client";
import qs from "qs";
import { Provider } from "react-redux";
import compose from "lodash/fp/compose";
import { ThemeProvider } from "styled-components";
import { COMMON } from "root/libs/constants/src";
import {
  customEventPolyfill,
  environment,
  localization,
  perfMetrics,
} from "root/libs/core-libs/src";
import { GlobalStyles, getTheme } from "root/libs/ui-styleguide/src";
import { THEME } from "@dhl-official/react-ui-library";
import {
  AbTesting,
  RtlSupport,
  CanaryVersions,
  CountrySettings,
  Localization,
  Tracking,
  PostalLocation,
  Utf,
  MicroFrontend,
} from "root/libs/ui-containers/src";
import { ErrorPage } from "root/libs/ui-components/src";
import App from "one-time-shipment/src/containers/SpotShipmentPage";
import localesConfig from "./config/locales";
import personalizationToggles from "./config/personalizationToggles";
import EnvironmentSettings from "./containers/EnvironmentSettings";
import {
  setPersonalizationToggles,
  runClientStorageAction,
} from "./containers/Personalization/actions";
import {
  prefillTradeLaneFromUrl,
  loadPostalLocationCountries,
} from "./containers/TradeLane/actions";
import { prefillSegment } from "./containers/SpotShipmentPage/actions";
import { prefillShipmentLaneFromUrl } from "./containers/ShipmentLane/actions";
import {
  getOffersRequestSent,
  sortOffers,
  localShipmentDetected,
} from "./containers/GetQuote/actions";
import { showOffersLane } from "./containers/OffersLane/actions";
import { decodeTradeLaneToState } from "./containers/TradeLane/selectors";
import { mountPoint } from "./config/bootstrapConfig";
import configureStore from "./store/configureStore";
import TrackingComponent from "./containers/Tracking";

customEventPolyfill();

const {
  actions: { enableAbTesting, changeABVersion },
  constants: { TYPES_OF_ABTESTVERSIONS },
} = AbTesting;

const {
  actions: { enableCanaryVersion },
  constants: { TYPES_OF_CANARY_VERSIONS },
} = CanaryVersions;

const {
  actions: { loadCountrySettings },
} = CountrySettings;

const {
  actions: { setupLocaleData },
} = Localization;

const {
  actions: { initializeDigitalLayer, trackEvent },
} = Tracking;

const {
  actions: { enableRtlSupport },
} = RtlSupport;

const {
  actions: { setPageName },
} = Utf;

const {
  actions: { setMicroFrontendId },
} = MicroFrontend;

function handlePersonalization(store, country, payload) {
  const personalization =
    window.digitalLayer && window.digitalLayer.gaqPersonalization
      ? window.digitalLayer.gaqPersonalization
      : payload;

  if (personalization) {
    store.dispatch(setPersonalizationToggles(personalization));
    if (["get", "remove"].includes(personalization.storeInitialSetAction)) {
      store.dispatch(runClientStorageAction(true));
    }
  }

  if (personalization?.canaryVersion) {
    if (TYPES_OF_CANARY_VERSIONS[personalization.canaryVersion]) {
      store.dispatch(
        enableCanaryVersion(
          TYPES_OF_CANARY_VERSIONS[personalization.canaryVersion]
        )
      );
    }
  }

  if (personalization?.abTesting) {
    store.dispatch(enableAbTesting());

    store.dispatch(
      changeABVersion(TYPES_OF_ABTESTVERSIONS[personalization.abTesting] || "")
    );
  }

  if (personalization?.experience) {
    store.dispatch(
      loadCountrySettings({ country, experience: personalization.experience })
    );
  }

  if (personalization?.microFrontendId) {
    store.dispatch(setMicroFrontendId(personalization.microFrontendId));
  }
}

function handleRtlSupport(store, locale, direction) {
  // We need the localesConfig for now to load the arabic translation mock on dev and local
  // In the embedded version we only look up for the direction of the document to enable RTL
  if (localesConfig.rtlSupport.includes(locale) || direction === "rtl") {
    store.dispatch(store.dispatch(enableRtlSupport()));
  }
}

function getPageName() {
  return window?.dataLayer?.page?.pageInfo?.pageName || "";
}

function handleUtf(store) {
  const pageName = getPageName();
  store.dispatch(setPageName(pageName));
}

function initializeApp({
  country,
  language,
  locale,
  direction,
  mountNode,
  store,
}) {
  const gaqLoaded = new CustomEvent("gaq-loaded");
  const dispatchTrackEvent = compose(store.dispatch, trackEvent);
  handleUtf(store);

  mountNode.addEventListener("cjcx-personalize-tool", ({ detail }) => {
    handlePersonalization(store, country, detail.gaqPersonalization);
  });
  store.dispatch(loadPostalLocationCountries());

  handleRtlSupport(store, locale, direction);

  store.dispatch(initializeDigitalLayer());

  document.dispatchEvent(gaqLoaded);

  store.dispatch(setupLocaleData({ locale, country, language }));
  store.dispatch(loadCountrySettings({ country }));

  perfMetrics.init(dispatchTrackEvent);
}

function handleQuoteInformationFromUrl(store, country) {
  const params = window.location.search;
  const shareValue = qs.parse(params.slice(1)).share;
  const localStorageSavedShipment = window.localStorage.getItem(
    `${COMMON.SAVE_SHIPMENT_LOCAL_STORAGE}:${country}`
  );
  let sharedData = null;

  if (shareValue) {
    const decodedBase64 = atob(shareValue);
    const decodedUri = decodeURIComponent(decodedBase64);
    sharedData = JSON.parse(decodedUri);
  }

  if (localStorageSavedShipment && !shareValue) {
    // Trigger Modal
    store.dispatch(localShipmentDetected({ value: true }));
  }

  if (sharedData) {
    const { shipmentLane, tradeLane } = sharedData;
    if (!shipmentLane.selectedUnitSystem) {
      // Condition added to make shared url backward compatible for unitSystem
      shipmentLane.selectedUnitSystem =
        tradeLane.initialSet.originCountry === "US" ? "imperial" : "metric";
    }
    store.dispatch(
      prefillSegment({
        segment: { id: sharedData.spotShipmentPage.selectedSegment },
      })
    );
    store.dispatch(prefillTradeLaneFromUrl(decodeTradeLaneToState(tradeLane)));
    store.dispatch(prefillShipmentLaneFromUrl({ shipmentLane }));
    store.dispatch(showOffersLane());
    store.dispatch(getOffersRequestSent());
    /**
     * Check if GetQuote object is available to ensure APP
     * is not breaking and older quotes without it are
     * still working.
     */
    if (typeof sharedData.getQuote !== "undefined") {
      store.dispatch(
        sortOffers({ sortOffersBy: sharedData.getQuote.sortOffersBy })
      );
    }
  }
}

function extractParams() {
  const paramsObject = {};

  const searchParams = new URLSearchParams(window.location.search);
  COMMON.PARAM_KEYS.forEach((key) => {
    if (searchParams.has(key)) {
      paramsObject[key] = searchParams.get(key);
    }
  });

  const personalizationContainer = document.getElementById(
    COMMON.DATA_CONTAINER_ELEMENT
  );
  if (personalizationContainer) {
    COMMON.PARAM_KEYS.forEach((key) => {
      const dataAttr = COMMON.DATA_ATTRS[key];
      const value = personalizationContainer.dataset[dataAttr];
      if (value) {
        paramsObject[key] = value;
      }
    });
  }

  return paramsObject;
}

function render(store, country, locale, mountNode) {
  const theme = getTheme({});
  const pageName = getPageName();
  const errorPage = (
    <ErrorPage
      trackEvent={(data) => store.dispatch(trackEvent(data))}
      pageName={pageName}
    />
  );

  const root = createRoot(mountNode);

  root.render(
    <React.Fragment>
      <Provider store={store}>
        <PostalLocation.Component errorPage={errorPage}>
          <CountrySettings.Component errorPage={errorPage}>
            <Localization.Component errorPage={errorPage}>
              <RtlSupport.Component>
                <React.Fragment>
                  <GlobalStyles
                    locale={locale}
                    mountPointClassName={mountPoint}
                  />
                  <ThemeProvider
                    theme={{
                      ...theme,
                      ...THEME.create(10),
                    }}
                  >
                    <App />
                    {environment.enableEnvSettings() && (
                      <EnvironmentSettings
                        environment={
                          environment.isStaging() && environment.isEmbedded()
                            ? "Staging"
                            : "Development"
                        }
                        initialPayload={JSON.stringify(
                          personalizationToggles,
                          null,
                          " "
                        )}
                        locales={localesConfig.pilotLocales}
                        onNewEvent={(payload) => {
                          handlePersonalization(store, country, payload);
                        }}
                        version={process.env.VERSION}
                        availableAbTestingOptions={JSON.stringify(
                          Object.values(TYPES_OF_ABTESTVERSIONS)
                        )}
                        availableCanaryVersionsOptions={JSON.stringify(
                          Object.values(TYPES_OF_CANARY_VERSIONS)
                        )}
                      />
                    )}
                  </ThemeProvider>
                </React.Fragment>
              </RtlSupport.Component>
            </Localization.Component>
          </CountrySettings.Component>
        </PostalLocation.Component>
        <TrackingComponent />
      </Provider>
    </React.Fragment>
  );
}

function renderApplication() {
  const store = configureStore();
  const locale = localization.getLocale(localesConfig.default);
  const country = localization.getCountry(locale, localesConfig.country);
  const language = localization.getLanguage(locale, localesConfig.language);
  const mountNode = document.querySelector(`.${mountPoint}`);
  const personalizationObject = extractParams();
  const isParamsEmpty = !!(Object.keys(personalizationObject).length === 0);
  const direction = document.dir;
  const documentRoot = document.querySelector("html");

  initializeApp({
    country,
    language,
    locale,
    direction,
    mountNode,
    store,
  });

  handlePersonalization.apply(
    this,
    isParamsEmpty ? [store, country] : [store, country, personalizationObject]
  );
  handleQuoteInformationFromUrl(store, country);

  render(store, country, locale, mountNode);
}

renderApplication();
