// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * PresetCard
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { Icon } from "root/libs/ui-components/src";
import { TEXT_SIZES } from "@dhl-official/react-ui-library";
import checkIcon from "icons/cx/check.svg";
import {
  StyledCard,
  CardContent,
  BoxWrapper,
  StyledText,
  StyledTextSizes,
  ButtonItself,
  StyledIconWrapper,
  StyledIcon,
} from "./styled";

const PresetCard = ({
  appearance,
  preset,
  isChecked,
  onChange,
  iconSize,
  unitLabel,
  presetSizeTrackingKeyPath,
  presetIcon,
}) => {
  const onKeyUp = (event) => {
    if (event.keyCode === 13) {
      onChange(event);
    }
  };

  return (
    <StyledCard appearance={appearance} data-testid="preset-size-card">
      <CardContent>
        <ButtonItself
          name={preset.key}
          type="button"
          data-tracking={presetSizeTrackingKeyPath}
          onClick={onChange}
          onKeyUp={onKeyUp}
          className={isChecked ? "selected" : null}
          data-testid="preset-card-button"
        >
          <Icon
            className={`content-icon ${preset.key}`}
            icon={presetIcon}
            iconSize={iconSize}
            useAs="decorative"
            width={{
              mobile: "85px",
              desktop: "70px",
            }}
            height={{
              mobile: "50px",
            }}
          />
          <BoxWrapper>
            <StyledText size={TEXT_SIZES.SMALLPLUS} weight={700}>
              {preset.label}
            </StyledText>
            <StyledTextSizes
              size={TEXT_SIZES.SMALLPLUS}
              dataTestid="preset-card-units"
              weight={400}
            >
              {unitLabel}
            </StyledTextSizes>
          </BoxWrapper>
        </ButtonItself>
        <StyledIconWrapper className="toggleIcon">
          <StyledIcon
            icon={checkIcon}
            useAs="decorative"
            fillColor="white"
            width={{ desktop: "12px", mobile: "12px" }}
            height={{ desktop: "12px", mobile: "12px" }}
          />
        </StyledIconWrapper>
      </CardContent>
    </StyledCard>
  );
};

PresetCard.propTypes = {
  appearance: PropTypes.oneOf(["small", "default"]).isRequired,
  iconSize: PropTypes.oneOf(["large", "xlarge", "xxlarge", "inbetweenLarge"]),
  presetIcon: PropTypes.object.isRequired,
  preset: PropTypes.object.isRequired,
  unitLabel: PropTypes.string.isRequired,
  presetSizeTrackingKeyPath: PropTypes.string,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
};

PresetCard.defaultProps = {
  isChecked: false,
  onChange: () => {},
  presetSizeTrackingKeyPath: "",
  iconSize: "xlarge",
};

export default PresetCard;
