// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Input
 *
 */

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import { IntlPropType } from "root/libs/core-libs/src";
import { keyCodes } from "root/libs/constants/src";
import {
  InputField,
  INPUTFIELD_LABEL_TYPES,
  INPUTFIELD_VALIDATION_TYPES,
} from "@dhl-official/react-ui-library";
import patterns from "./patterns";

/* 
TODO: This StyledInputField can be replaced with actual DUIL component, 
once we update DUIL version. 
*/
const StyledInputField = styled(InputField)`
  & > input:focus {
    appearance: none;
  }

  @media print {
    & > input {
      border: none;
    }
    & i {
      display: none;
    }
  }
`;

class Input extends React.PureComponent {
  static propTypes = {
    /** Translations library  */
    intl: IntlPropType.isRequired,
    /** Input name */
    name: PropTypes.string.isRequired,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    step: PropTypes.string,
    acceptDecimals: PropTypes.bool,
    /** Input value. Accepts a number or a string, depending on the type of input you need */
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /** Pattern to use for validation or keyboard layouts in mobile
     * Allows the caller to define a specific pattern.
     * Should not be used, use instead: pattern
     */
    customPattern: PropTypes.string,
    /** Pattern to use for validation or keyboard layouts in mobile */
    pattern: PropTypes.oneOf([
      "alphaNumeric",
      "numberWithAndWithoutDecimal",
      "numberTwoDecimals",
      "numberNoDecimals",
      "twoChars",
      "zip",
      "email",
      "",
    ]),
    /** Class / classes added to the input element */
    className: PropTypes.string,
    /** Placeholder displayed inside of the input element when there is no value */
    placeholder: PropTypes.string,
    /** Flag that indicates whether input has or has not some error */
    hasError: PropTypes.bool,
    /** Define the type of the input element. Note: In order to have a number input field that
     * validates decimal values with commas or dots as a decimal separator, use "type=tel", and still
     * send the min and max value in your attributes.
     */
    type: PropTypes.oneOf([
      "text",
      "numeric",
      "number",
      "tel",
      "search",
      "email",
      "password",
      "checkbox",
    ]).isRequired,
    /** Define whether is the input disabled or not */
    disabled: PropTypes.bool,
    /** onBlur handler */
    onBlur: PropTypes.func,
    /** onFocus handler */
    onFocus: PropTypes.func,
    /** onChange handler */
    onChange: PropTypes.func,
    /** onKeyUp handler */
    onKeyUp: PropTypes.func,
    /** react reference used to get the element dom in the code */
    reference: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    /** Will be used to tell screen readers where to find an
     * error message if the input is invalid. Points to the prop errorMessageAriaId of FeedbackMessage */
    ariaDescribedBy: PropTypes.string,
    /** If this field is numeric */
    useNumericDefaultPlaceholder: PropTypes.bool,
    autoComplete: PropTypes.string,
    blurValueFormatter: PropTypes.func,
    dataTestid: PropTypes.string,
    label: PropTypes.string,
    interpolations: PropTypes.object,
    feedbackInfoMessage: PropTypes.shape({
      message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      isShowInfoMessage: PropTypes.bool,
    }),
    feedbackMessageId: PropTypes.string,
    /**
     * if for some reason we have to retain feedback messages when the field is valid.
     * e.g: formatted values for the user and need to indicate that the numbers changed */
    forceFeedbackMessage: PropTypes.bool,
    isSuccess: PropTypes.bool,
    copyNamespace: PropTypes.string,
    feedbackMessageIdPrefix: PropTypes.string,
    required: PropTypes.bool,
  };

  static defaultProps = {
    value: "",
    label: "",
    ariaDescribedBy: "",
    autoComplete: "off",
    disabled: false,
    acceptDecimals: false,
    placeholder: "",
    useNumericDefaultPlaceholder: false,
    hasError: false,
    onBlur: () => {},
    onFocus: () => {},
    onChange: () => {},
    onKeyUp: null,
    className: "",
    reference: () => {},
    pattern: "",
    customPattern: "",
    minValue: undefined,
    maxValue: undefined,
    step: undefined,
    blurValueFormatter: (event) => event.target.value,
    dataTestid: undefined,
    interpolations: {},
    feedbackInfoMessage: { message: undefined, isShowInfoMessage: false },
    forceFeedbackMessage: false,
    isSuccess: false,
    required: false,
  };

  onFocus = (event) => {
    const { onFocus } = this.props;
    if (onFocus) {
      onFocus(event);
    }
  };

  /**
   * By using this syntax: onKeyUp = (event) => {}
   * we dont need to bind (this.onKeyUp = this.onKeyUp.bind(this);)
   * the function in the constructor. Why? See:
   * https://babeljs.io/docs/plugins/transform-class-properties/
   */
  onKeyUp = (event) => {
    const { onBlur, onKeyUp } = this.props;

    if (event.keyCode === keyCodes.RETURN) {
      onBlur(event);
    }
    /* istanbul ignore else */
    if (onKeyUp) {
      onKeyUp(event);
    }
  };

  onBlur = (event) => {
    const { onBlur, blurValueFormatter, type } = this.props;

    if (onBlur) {
      /* We make a copy of the event here and pass it upwards to the parent component.
         Due to some bug in Chrome Dev Tools you will see only an empty object as an event, but the data are actually there.
         If you need to test this functionality, do it in Firefox.
       */
      let newEvent = event;
      if (type === "numeric") {
        const formattedValue = blurValueFormatter(event);
        newEvent = Object.create(event);
        newEvent.target.value = formattedValue;
      }
      onBlur(newEvent);
    }
  };

  getPattern = () => {
    const { pattern, customPattern, type, acceptDecimals } = this.props;

    let patternToUse =
      customPattern !== "" ? customPattern : patterns[pattern] || undefined;

    if (!patternToUse && type === "numeric") {
      patternToUse = acceptDecimals
        ? patterns.numberWithAndWithoutDecimal
        : patterns.numberNoDecimals;
    }
    return patternToUse;
  };

  getCopy = (id) => {
    const {
      intl,
      interpolations: values,
      feedbackInfoMessage,
      feedbackMessageId,
    } = this.props;

    if (feedbackMessageId) {
      /* Error case */
      return intl.formatMessage({ id }, values);
    }

    if (feedbackInfoMessage?.isShowInfoMessage) {
      return feedbackInfoMessage.message;
    }

    return "";
  };

  getValidationType = () => {
    const {
      isSuccess,
      hasError,
      feedbackMessageId,
      feedbackInfoMessage,
    } = this.props;

    if (isSuccess) {
      return INPUTFIELD_VALIDATION_TYPES.VALID;
    }
    if (feedbackInfoMessage?.isShowInfoMessage) {
      return INPUTFIELD_VALIDATION_TYPES.NOTE;
    }

    return !!hasError && feedbackMessageId
      ? INPUTFIELD_VALIDATION_TYPES.INVALID
      : "";
  };

  render() {
    const {
      reference,
      hasError,
      type,
      acceptDecimals,
      onChange,
      onFocus,
      onBlur,
      pattern,
      customPattern,
      minValue,
      maxValue,
      dataTestid,
      name,
      value,
      autoComplete,
      ariaDescribedBy,
      label,
      copyNamespace,
      feedbackMessageIdPrefix,
      feedbackMessageId,
      feedbackInfoMessage,
      forceFeedbackMessage,
      required,
      interpolations,
      isSuccess,
      ...extraProps
    } = this.props;

    const validationType = this.getValidationType();

    if (type === "numeric") {
      extraProps.type = "text";
      extraProps["data-is-number"] = true;

      if (acceptDecimals) {
        extraProps.inputMode = "decimal";
        extraProps["data-validate-decimals"] = true;
      } else {
        extraProps.inputMode = "numeric";
        extraProps["data-validate-decimals"] = false;
      }

      if (
        extraProps.placeholder === "" &&
        extraProps.useNumericDefaultPlaceholder
      ) {
        if (minValue !== undefined) {
          extraProps.placeholder = minValue;
        } else {
          extraProps.placeholder = !acceptDecimals ? "0" : "0,00";
        }
      }
    }

    if (pattern || customPattern) {
      extraProps.pattern = this.getPattern();
    }

    if (minValue !== undefined) {
      extraProps["data-min-value"] = minValue;
    }

    if (maxValue !== undefined) {
      extraProps["data-max-value"] = maxValue;
    }

    const ariaProps = {};
    if (hasError) {
      ariaProps["aria-invalid"] = true;
    }

    if (ariaDescribedBy) {
      ariaProps["aria-describedby"] = ariaDescribedBy;
    }

    return (
      <StyledInputField
        variant={{
          label,
          placeholder: "",
          type: INPUTFIELD_LABEL_TYPES.STATIC,
        }}
        validation={
          validationType
            ? {
                type: validationType,
                message:
                  validationType !== INPUTFIELD_VALIDATION_TYPES.VALID
                    ? this.getCopy(
                        `${copyNamespace}.validations.${feedbackMessageIdPrefix}${feedbackMessageId}`
                      )
                    : validationType === INPUTFIELD_VALIDATION_TYPES.VALID &&
                      forceFeedbackMessage &&
                      feedbackInfoMessage.message
                    ? feedbackInfoMessage.message
                    : undefined,
              }
            : undefined
        }
        name={name}
        id={name}
        data-testid={dataTestid}
        autoComplete={autoComplete}
        onBlur={this.onBlur}
        type={type}
        onChange={onChange}
        onFocus={this.onFocus}
        onKeyUp={this.onKeyUp}
        ref={reference}
        value={value}
        isBlock
        required={required}
        {...ariaProps}
        {...extraProps}
      />
    );
  }
}

export default injectIntl(Input);
