// 9fbef606107a605d69c0edbcd8029e5d

import { THEME } from "@dhl-official/react-ui-library";

export default {
  black: THEME.getWebColor("black")({}),
  blackMineShaft: THEME.getGrayColor("800")({}),

  white: THEME.getWebColor("white")({}),
  whiteTwoAlto: THEME.getGrayColor("300")({}),
  whiteGallery: THEME.getGrayColor("200")({}),

  /** begin old */
  silver: THEME.getGrayColor("400")({}),
  silverDark: THEME.getGrayColor("700")({}),
  alabasterGrey8: "#FBFBFB",
  dustyGray: THEME.getGrayColor("600")({}),
  /** end old */

  lightestGray: THEME.getGrayColor("100")({}),

  greyWarm: THEME.getGrayColor("600")({}),
  greySilverChalice: THEME.getGrayColor("500")({}),

  greenJungle: THEME.getSuccessColor("400")({}),
  greenEmeraldHover: "#029b48",
  greenApple: THEME.getSuccessColor("300")({}),

  redScarlet: THEME.getPrimaryColor("400")({}),
  redCherryHover: "#f9131f",

  yellowSunFlower: THEME.getSecondaryColor("400")({}),
  yellowSunFlower02: "rgba(255, 204, 0, 0.2)",
  greyishBrownEmperor: "#505050",

  shadowDark: "rgba(80,80,80,0.70)",
  shadowLight: "rgba(50, 50, 50, 0.5)",
  shadowHalfOpacity: "rgba(0, 0, 0, 0.05)",
  shadowLight100: THEME.getGrayColor("500")({}),

  /* Special color codes for Ukraine flag */
  ukraineFlagBlue: "#005bbb",
  ukraineFlagYellow: "#ffd500",
};
