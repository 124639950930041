// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * ContentModal
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { Modal } from "@dhl-official/react-ui-library";
import { injectIntl } from "react-intl";
import styled, { createGlobalStyle } from "styled-components";
import { breakpoints } from "root/libs/ui-styleguide/src";

const OverrideGlobalModalStyles = createGlobalStyle`
  ${(props) =>
    props.variant === "bottom"
      ? `
      .PL-Modal__Overlay {
        align-items: flex-end;
      }
      @media screen and (min-width: ${breakpoints.tablet}) {
        .PL-Modal__Overlay {
          align-items: center;
        }        
      }
      `
      : ""}
`;

const StyledModal = styled(Modal)`
  &.dhl-cj-Modal {
    width: ${(props) =>
      props.variant === "bottom" ? "100%" : "calc(100% - 20px)"};
    max-width: unset;
    padding: 30px 20px;
    line-height: 1.3em;
    min-height: auto;
    max-height: 90vh;

    &:focus {
      outline-color: transparent;
    }

    @media screen and (min-width: ${breakpoints.tablet}) {
      width: 100%;
      padding: 60px 60px 50px 60px;
      max-width: ${(props) => (props.smallCenteredModal ? "600px" : "80vw")};
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      max-width: ${(props) => (props.smallCenteredModal ? "600px" : "800px")};
    }
  }

  &.dhl-cj-Modal-scrollable {
    overflow-y: auto;
  }
`;

const ContentModal = (props) => {
  const {
    children,
    shouldShowCloseButton,
    closeButtonAriaLabel,
    contentAriaLabel,
    overlayColor,
    onClose,
    isOpen,
    isStatic,
    isScrollable,
    variant,
  } = props;

  return (
    <React.Fragment>
      <StyledModal
        {...props}
        shouldShowCloseButton={shouldShowCloseButton}
        closeButtonAriaLabel={closeButtonAriaLabel}
        contentAriaLabel={contentAriaLabel}
        overlayColor={overlayColor}
        onClose={onClose}
        isOpen={isOpen}
        isStatic={isStatic}
        className={`dhl-cj-Modal dhl-cj-main ${
          isScrollable && "dhl-cj-Modal-scrollable"
        }`}
      >
        {children}
      </StyledModal>
      <OverrideGlobalModalStyles variant={variant} />
    </React.Fragment>
  );
};

ContentModal.propTypes = {
  children: PropTypes.node,
  /** If this is set true, the close icon on top right will be shown */
  shouldShowCloseButton: PropTypes.bool,
  /** Optional background color of the modal overlay */
  overlayColor: PropTypes.string,
  smallCenteredModal: PropTypes.bool,
  closeButtonAriaLabel: PropTypes.string.isRequired,
  contentAriaLabel: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  isStatic: PropTypes.bool,
  isScrollable: PropTypes.bool,
  variant: PropTypes.oneOf(["default", "bottom"]),
};

ContentModal.defaultProps = {
  children: undefined,
  shouldShowCloseButton: true,
  overlayColor: "rgba(255, 204, 0, 0.9)",
  smallCenteredModal: false,
  isOpen: false,
  isStatic: false,
  isScrollable: false,
  variant: "default",
};

export default injectIntl(ContentModal);
