// 9fbef606107a605d69c0edbcd8029e5d

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { RemoteData } from "root/libs/core-libs/src";
import { compose } from "redux";
import { selectCountry, selectCountrySettings } from "./selectors";

/**
 * High Order Component to encapsulate specific country settings.
 * Wanna learn more?
 * https://medium.com/dailyjs/react-composing-higher-order-components-hocs-3a5288e78f55
 */
/**
 *
 * @param {*} WrappedComponent A react component that needs settings per country
 * @param {*} iNeedTheseProps a array of strings with the settings that the component needs.
 */
const withCountry = (WrappedComponent) =>
  class WithCountry extends PureComponent {
    static propTypes = {
      remoteCountrySettings: PropTypes.object.isRequired,
      /**
       * Any to the country settings that a component might need.
       * Accepts an array of strings. It will send these properties
       * to your component.
       */
      iNeedTheseProps: PropTypes.arrayOf(
        PropTypes.oneOf([
          "unitSystem",
          "hasResidencialAddress",
          "showModalExpressPhoneCta",
          "regularShipment",
          "offerCardVariation",
          "nativeLanguageCode",
          "nativeDateTimeFormat",
          "is12HourTimeFormat",
          "weightValidationRules",
          "quantityValidationRules",
          "lengthValidationRules",
          "heightValidationRules",
          "widthValidationRules",
          "showCurrencyIsoCodeForPrices",
          "formatCurrencyWithNativeLocale",
          "isDomesticShippingAvailable",
          "blacklistedDestinationCountries",
          "enableImportExportFeature",
          "shipmentScaleOptions",
          "shipmentProductOptions",
          "spotShipmentPresetSizes",
          "shipmentLaneCheckboxStatusWeightThreshold",
          "isFreightEnabled",
          "showTradeLaneBusinessShipmentAssistant",
          "changeCountryURI",
          "changeCountryURIBusiness",
          "swapCityPostalCode",
          "hideSegments",
          "defaultSelectedSegment",
          "segments",
          "isExpressAllowedOnMobile",
          "aggregatedOfferViewMode",
        ])
      ).isRequired,
    };

    render() {
      // Destructure this, because we dont want to send to the component the props: `iNeedTheseProps`, `remoteCountrySettings` and `defaultCountrySettings`
      const {
        remoteCountrySettings,
        iNeedTheseProps,
        ...componentProps
      } = this.props;

      const getCountrySettings = (countrySettings) =>
        iNeedTheseProps.reduce(
          (accumulator, prop) =>
            prop in countrySettings
              ? { ...accumulator, [prop]: countrySettings[prop] }
              : accumulator,
          {}
        );

      return RemoteData.case(
        {
          success: (countrySettings) => (
            <WrappedComponent
              {...getCountrySettings(countrySettings)}
              {...componentProps}
            />
          ),
          _: () => null,
        },
        remoteCountrySettings
      );
    }
  };

const mapStateToProps = (state) => ({
  country: selectCountry(state),
  remoteCountrySettings: selectCountrySettings(state),
});

export default compose(connect(mapStateToProps), withCountry);
