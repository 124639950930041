// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * WCCMultiBUOfferPriceBreakdown
 *
 */

import React from "react";
import styled from "styled-components";
import { key } from "styled-theme";
import { IntlPropType, GetCountryCode } from "root/libs/core-libs/src";
import { showCurrencyAsCode } from "root/libs/constants/src";
import PropTypes from "prop-types";
import { Headline } from "root/libs/ui-components/src";
import { dhlVarColors, breakpoints } from "root/libs/ui-styleguide/src";
import { Text } from "@dhl-official/react-ui-library";

const lightBorder = `1px solid ${dhlVarColors.lightBorderColor};`;
const innerBorder = `1px solid ${dhlVarColors.darkBorderColor};`;

const PriceBreakdownSegmentWrapper = styled.div`
  margin-bottom: 10px;
  border-top: ${lightBorder};
  padding: 15px 0px 15px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: 15px 15px;
  }
`;

const PriceBreakdownHeadlineWrapper = styled.div`
  padding: 0px 0px 10px;
  display: flex;
`;

const PriceBreakdownWrapper = styled.div`
  display: flex;
  padding: 10px 0px;
  border-top: ${innerBorder};
  border-bottom: ${innerBorder};
`;

const StyledHeadline = styled(Headline)`
  top: 10px;
  right: 10px;
  background-color: ${key("colors.backgroundColorInverted")};
  border: 1px solid ${key("colors.primary")};
  padding: 4px 10px 4px;

  @media screen and (min-width: 1150px) {
    top: 0;
    right: 0;
  }
`;

const StyledLabels = styled.th`
  text-align: left;
  padding: 5px 0;
`;

const StyledValues = styled.td`
  padding: 5px 0px 5px 10px;
`;

const StyledLabelText = styled(Text)`
  font-weight: bold;
`;

const COPY_ID_PREFIX = "WCCMultiBUOfferPriceBreakdown";
export default class WCCMultiBUOfferPriceBreakdown extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    offerIndex: PropTypes.any,
    /** An object that contains all the information required of an offer */
    offer: PropTypes.shape({
      businessUnit: PropTypes.string,
      /** id of the offer */
      key: PropTypes.string,
      id: PropTypes.string,
      /** Price of the offer */
      price: PropTypes.shape({
        /** A number that contains the price
         */
        amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        /** A valid currency code */
        currencyCode: PropTypes.string,
        tax: PropTypes.string,
        billingAmount: PropTypes.number,
        billingTaxAmount: PropTypes.number,
      }),
      /** If set will trigger the display of a special scenario card in DGF */
      specialOffers: PropTypes.string,
    }).isRequired,
    /** Internationalization library */
    intl: IntlPropType.isRequired,
    showCurrencyIsoCodeForPrices: PropTypes.bool,
    nativeLanguageCode: PropTypes.string,
    formatCurrencyWithNativeLocale: PropTypes.bool,
    getRef: PropTypes.func,
  };

  static defaultProps = {
    showCurrencyIsoCodeForPrices: false,
    nativeLanguageCode: "",
    formatCurrencyWithNativeLocale: false,
  };

  constructor(props) {
    super(props);
    this.priceBreakdownRef = React.createRef();
  }

  componentDidMount() {
    const { getRef } = this.props;
    if (getRef) {
      getRef(this.priceBreakdownRef);
    }
  }

  getCopy = (id) => {
    const { intl } = this.props;
    return intl.formatMessage({ id });
  };

  getOfferPrice = () => {
    const { offer } = this.props;

    if (!("price" in offer)) {
      return null;
    }

    const offerNet = offer.price.billingAmount || 0;
    const offerTax = offer.price.billingTaxAmount || 0;

    const offerGross = offerNet + offerTax;

    return { offerNet, offerTax, offerGross };
  };

  getOfferCurrency = () => {
    const { offer } = this.props;

    if (!("price" in offer)) {
      return null;
    }

    if (typeof offer.price.currencyCode !== "undefined") {
      return offer.price.currencyCode;
    }
    return null;
  };

  formatPrice = (value) => {
    const {
      intl,
      showCurrencyIsoCodeForPrices,
      nativeLanguageCode,
      formatCurrencyWithNativeLocale,
    } = this.props;

    const currencyCode = this.getOfferCurrency();
    const countryCode = GetCountryCode(intl.locale);
    const priceFormatLocale = formatCurrencyWithNativeLocale
      ? `${nativeLanguageCode}-${countryCode}`
      : intl.locale;
    const isCurrencyCodeAvailable =
      currencyCode !== "" && currencyCode !== null;

    if (showCurrencyIsoCodeForPrices && isCurrencyCodeAvailable) {
      return `${currencyCode} ${intl.formatNumber(value, {
        minimumFractionDigits: 2,
      })}`;
    }
    if (!showCurrencyIsoCodeForPrices && isCurrencyCodeAvailable) {
      return new Intl.NumberFormat(priceFormatLocale, {
        style: "currency",
        currency: currencyCode,
        currencyDisplay: showCurrencyAsCode.includes(currencyCode)
          ? "code"
          : "symbol",
      }).format(value);
    }
    if (currencyCode === "") {
      return intl.formatNumber(value, {
        minimumFractionDigits: 2,
      });
    }
  };

  render() {
    const { id, offerIndex, intl, offer } = this.props;
    const TITLE = intl.formatMessage({ id: `${COPY_ID_PREFIX}.Title` });
    const NETTEXT = intl.formatMessage({ id: `${COPY_ID_PREFIX}.NET` });
    const VATTEXT = intl.formatMessage({ id: `${COPY_ID_PREFIX}.VAT` });
    const GROSSTEXT = intl.formatMessage({ id: `${COPY_ID_PREFIX}.GROSS` });

    const { offerNet, offerTax, offerGross } = this.getOfferPrice();

    return (
      <PriceBreakdownSegmentWrapper
        id={`${id}-${offer.key}`}
        ref={this.priceBreakdownRef}
      >
        <PriceBreakdownHeadlineWrapper>
          <StyledHeadline
            color={key("colors.primary")}
            dataTestid={`${id}-headline-${offerIndex}`}
            id={`${id}-headline-${offerIndex}`}
          >
            {TITLE}
          </StyledHeadline>
        </PriceBreakdownHeadlineWrapper>
        <PriceBreakdownWrapper>
          <table>
            <tbody>
              <tr>
                <StyledLabels scope="row">
                  <StyledLabelText
                    id={`${id}-net-${offerIndex}-label`}
                    dataTestid={`${id}-net-label-${offerIndex}`}
                  >
                    {NETTEXT}
                  </StyledLabelText>
                </StyledLabels>
                <StyledValues>
                  <Text
                    id={`${id}-net-${offerIndex}-value`}
                    dataTestid={`${id}-net-value-${offerIndex}`}
                  >
                    {this.formatPrice(offerNet)}
                  </Text>
                </StyledValues>
              </tr>
              <tr>
                <StyledLabels scope="row">
                  <StyledLabelText
                    id={`${id}-vat-${offerIndex}-label`}
                    dataTestid={`${id}-vat-label-${offerIndex}`}
                  >
                    {VATTEXT}
                  </StyledLabelText>
                </StyledLabels>
                <StyledValues>
                  <Text
                    id={`${id}-vat-${offerIndex}-value`}
                    dataTestid={`${id}-vat-value-${offerIndex}`}
                  >
                    {this.formatPrice(offerTax)}
                  </Text>
                </StyledValues>
              </tr>
              <tr>
                <StyledLabels scope="row">
                  <StyledLabelText
                    id={`${id}-gross-${offerIndex}-label`}
                    dataTestid={`${id}-gross-label-${offerIndex}`}
                  >
                    {GROSSTEXT}
                  </StyledLabelText>
                </StyledLabels>
                <StyledValues>
                  <Text
                    id={`${id}-gross-${offerIndex}-value`}
                    dataTestid={`${id}-gross-value-${offerIndex}`}
                  >
                    {this.formatPrice(offerGross)}
                  </Text>
                </StyledValues>
              </tr>
            </tbody>
          </table>
        </PriceBreakdownWrapper>
      </PriceBreakdownSegmentWrapper>
    );
  }
}
