// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for Form
 *
 */

import styled from "styled-components";
import { breakpoints } from "root/libs/ui-styleguide/src";
import { Text } from "@dhl-official/react-ui-library";

export const StyledButtonHintText = styled(Text)`
  margin-bottom: 25px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: 15px;
  }
`;

export const StyledButtonWrapper = styled.div`
  text-align: ${(props) => props.buttonAlignment};
  margin: 20px auto;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: 35px auto 20px;
  }

  @media print {
    display: none;
  }
`;
