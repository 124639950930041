// 9fbef606107a605d69c0edbcd8029e5d

import ttiPolyfill from "tti-polyfill";
import merge from "lodash/merge";
import { featureFlags, perfKeys } from "root/libs/constants/src";
import entryTypeHandlers from "./entryTypeHandlers";

function registerObserver(trackEventDispatcher, entryTypes) {
  let observer = {};
  if (window.PerformanceObserver) {
    observer = new PerformanceObserver((list) => {
      let eventToDispatch = {};
      list.getEntries().forEach((entry) => {
        // call respective eventType Handler
        const entryEvents = entryTypeHandlers[entry.entryType](entry);
        if (entryEvents) {
          // collect all events
          eventToDispatch = merge(eventToDispatch, entryEvents);
        }
      });
      // call the trackEvent dispatcher for all the events
      trackEventDispatcher(eventToDispatch);
    });
    observer.observe(entryTypes);
  }
}

function getTimeToInteractive() {
  return ttiPolyfill.getFirstConsistentlyInteractive().then((tti) => ({
    uiMetrics: { firstConsistentlyInteractiveinMs: Math.round(tti) },
  }));
}

function init(trackEventDispatcher) {
  getTimeToInteractive().then(trackEventDispatcher);

  // get the values from performance api
  const entryTypes = featureFlags.trackPerformanceExtensively
    ? perfKeys.extensiveEntryTypes
    : perfKeys.defaultEntryTypes;

  registerObserver(trackEventDispatcher, { entryTypes });
}

export default {
  init,
};
