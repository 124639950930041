// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * TextAndIcon
 * Inlined text that has an icon on the right side.
 */

import React from "react";
import PropTypes from "prop-types";
import { TEXT_SIZES, TEXT_WEIGHTS } from "@dhl-official/react-ui-library";
import Icon from "../../Icons/Icon";
import IconButtonTooltip from "../../Tooltip/IconButtonTooltip";
import { SpanStyled, TextAndIconWrapper } from "./styled";

export default class TextAndIcon extends React.PureComponent {
  static propTypes = {
    label: PropTypes.any.isRequired,
    ariaLabel: PropTypes.string,
    iconName: PropTypes.string.isRequired,
    /**
     *  If set, an tooltip will added to the icon.
     *  If you leave this empty then just the icon will be rendered.
     *  Will be a non clickable icon.
     */
    tooltipContent: PropTypes.any,
    tooltipHeading: PropTypes.string,
    /**
     * Sets an ID to the tooltip to reference it for screen readers to the icon .
     * This ID is only required when a tooltip is used.
     */
    tooltipID: PropTypes.string,
    onClick: PropTypes.func,
    leftMargin: PropTypes.string,
    bottomMargin: PropTypes.string,
    /** Aria label to be used by a standalone icon.
     * see the icon documentation for more */
    iconLabel: PropTypes.string,
    tabIndex: PropTypes.string,
    /** If set will use a <span> instead of a <p>. Default: <p> */
    inlined: PropTypes.bool,
    className: PropTypes.string,
    /** Info is an i icon, and help is a question mark */
    iconType: PropTypes.oneOf(["info", "help", ""]),
    /** If set label and icon will aligned right */
    alignRight: PropTypes.bool,
    /** This icon will be used for cases when
     * you just need an Icon without the tooltip functionality */
    icon: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string,
        viewBox: PropTypes.string,
        url: PropTypes.string,
      }),
    ]),
    /** String with an alternative fill color for the icon -
     * Accepts a function when you get the
     * color from `import { key } from 'styled-theme';` */
    iconFillColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  };

  static defaultProps = {
    onClick: () => {},
    ariaLabel: "",
    leftMargin: "",
    bottomMargin: "",
    iconLabel: "",
    tabIndex: "0",
    inlined: false,
    className: "",
    tooltipContent: null,
    tooltipID: "",
    iconType: "info",
    alignRight: false,
    icon: null,
    iconFillColor: undefined,
    tooltipHeading: null,
  };

  onClick = (e) => {
    const { onClick } = this.props;
    e.preventDefault();
    onClick(e);
  };

  render() {
    const {
      ariaLabel,
      label,
      iconName,
      tooltipContent,
      tooltipID,
      leftMargin,
      bottomMargin,
      iconLabel,
      tabIndex,
      inlined,
      className,
      alignRight,
      iconType,
      icon,
      iconFillColor,
      tooltipHeading,
      ...extraProps
    } = this.props;

    return (
      <TextAndIconWrapper
        leftMargin={leftMargin}
        bottomMargin={bottomMargin}
        inlined={inlined}
        className={className}
        alignRight={alignRight}
        hasTooltip={!!tooltipContent}
      >
        <SpanStyled
          noMargin
          tabIndex={tabIndex}
          inlined
          weight={TEXT_WEIGHTS[400]}
          size={TEXT_SIZES.SMALLPLUS}
          {...extraProps}
        >
          {label}
        </SpanStyled>

        {!!tooltipContent && (
          <IconButtonTooltip
            id={tooltipID}
            iconType={iconType}
            onClick={this.onClick}
            ariaLabel={ariaLabel ? `${iconLabel}: ${ariaLabel}` : `${label} ${iconLabel}`}
            name={iconName}
            tooltipContent={tooltipContent}
            heading={!!tooltipHeading && tooltipHeading}
          />
        )}
        {!tooltipContent && (
          <Icon
            icon={icon}
            fillColor={iconFillColor}
            useAs="decorative"
            size="small"
          />
        )}
      </TextAndIconWrapper>
    );
  }
}
