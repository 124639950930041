// 9fbef606107a605d69c0edbcd8029e5d

/*
 *
 * Localization reducer
 *
 */

import { handleActions } from "redux-actions";
import { RemoteData } from "root/libs/core-libs/src";
import * as actions from "./actions";

export const initialState = {
  country: "",
  language: "",
  locale: "",
  messages: {
    remote: {},
  },
};

const loadCopyText = (state, { payload: { locale } }) => {
  const remoteMessages = state.messages.remote[locale];

  if (remoteMessages && RemoteData.isSuccess(remoteMessages)) {
    return state;
  }

  return {
    ...state,
    messages: {
      ...state.messages,
      remote: {
        ...state.messages.remote,
        [locale]: RemoteData.loading(),
      },
    },
  };
};

const loadCopyTextSuccess = (
  state,
  { payload: { locale, country, language, messages } }
) => ({
  ...state,
  country,
  language,
  locale,
  messages: {
    ...state.messages,
    remote: {
      ...state.messages.remote,
      [locale]: RemoteData.success(messages),
    },
  },
});

const setCopyTextFromStorage = (state, { payload: { locale, messages } }) => ({
  ...state,
  messages: {
    ...state.messages,
    remote: {
      ...state.messages.remote,
      [locale]: RemoteData.success(messages),
    },
  },
});

const setCountry = (state, { payload: country }) => ({
  ...state,
  country,
});

const setupLocaleData = (state, { payload: { locale, country, language } }) => {
  const remoteMessages = state.messages.remote[locale];

  return {
    ...state,
    locale,
    country,
    language,
    messages: {
      ...state.messages,
      remote: {
        ...state.messages.remote,
        [locale]: remoteMessages || RemoteData.loading(),
      },
    },
  };
};

const setRemoteMessagesFailure = (state, { payload: { locale, message } }) => {
  const remoteMessages = state.messages.remote[locale];

  // Do we already have a success state (via loading the copy from localstorage)?
  // Then take just that, use the localstorage copy and ignore the failed request

  if (remoteMessages && RemoteData.isSuccess(remoteMessages)) {
    return state;
  }

  return {
    ...state,
    messages: {
      ...state.messages,
      remote: {
        ...state.messages.remote,
        [locale]: RemoteData.error(message),
      },
    },
  };
};

export default handleActions(
  {
    [actions.loadCopyText]: loadCopyText,
    [actions.loadCopyTextFailure]: setRemoteMessagesFailure,
    [actions.loadCopyTextSuccess]: loadCopyTextSuccess,
    [actions.setCopyTextFromStorage]: setCopyTextFromStorage,
    [actions.setCountry]: setCountry,
    [actions.setupLocaleData]: setupLocaleData,
    [actions.setupLocaleDataFailure]: setRemoteMessagesFailure,
  },
  initialState
);
