// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for EstimatedDeliveryInfo
 *
 */

import styled from "styled-components";
import { key } from "styled-theme";
import { Text } from "@dhl-official/react-ui-library";
import { breakpoints } from "root/libs/ui-styleguide/src";

export const EstimatedDeliveryText = styled(Text)`
  color: ${key("colors.headerColor")};
  margin-top: 0.2rem;
  margin-bottom: 1.1rem;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: 0;
    margin-bottom: 2px;
  }
`;

export const DateTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 15px;

  @media screen and (min-width: ${breakpoints.desktop}) {
    line-height: 52px;
    display: block;
    margin-bottom: 8px;
  }
`;
