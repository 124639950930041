// 9fbef606107a605d69c0edbcd8029e5d

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { IntlPropType } from "root/libs/core-libs/src";
import styled from "styled-components";
import { Text } from "@dhl-official/react-ui-library";
import { colors } from "root/libs/ui-styleguide/src";
import Input from "../Input";
import Flag from "../../Flag";
import messages from "./messages";

const InputContainer = styled.div`
  width: 100%;
  ${({ hasFlags }) =>
    hasFlags &&
    `
    position: relative;

    label,
    input {
      padding-left: 55px;
    }
  `}
  ${({ feedbackLinkClick }) =>
    feedbackLinkClick &&
    `
    .clickable {
      text-decoration: underline;
      cursor: pointer;

      &:focus {
        background-color: ${colors.redScarlet};
        color: ${colors.white};
      }
    }
  `}
  /* TODO: Subject to be removed when the conflict gets over between countries */
  ${({ countryId }) =>
    countryId === "UA" &&
    `
    input, 
    input:focus {
      border-width: 2px;
      box-shadow: none;
      border-left-color: ${colors.ukraineFlagYellow};
      border-right-color: ${colors.ukraineFlagYellow};
      border-top-color: ${colors.ukraineFlagBlue};
      border-bottom-color: ${colors.ukraineFlagBlue};
    }
    div[role="alert"] span {
      color: ${colors.black};
    }
  `}
`;

const FlagContainer = styled.div`
  position: absolute;
  z-index: 2;
  top: 18px;
  left: 15px;
  line-height: 20px;
  height: 20px;
  pointer-events: none;

  @media print {
    display: none;
  }
`;

const TYPES = {
  TEXT: "text",
  NUMERIC: "numeric",
  EMAIL: "email",
  TEL: "tel",
  PASSWORD: "password",
};

const PATTERNS = {
  ALPHA_NUMERIC: "alphaNumeric",
  EMAIL: "email",
  NUMBER_NO_DECIMALS: "numberNoDecimals",
  NUMBER_TWO_DECIMALS: "numberTwoDecimals",
  NUMBER_WITH_AND_WITHOUT_DECIMAL: "numberWithAndWithoutDecimal",
  TWO_CHARS: "twoChars",
  ZIP: "zip",
};

class FormField extends React.PureComponent {
  static propTypes = {
    acceptDecimals: PropTypes.bool,
    ariaDescribedBy: PropTypes.string,
    /** If ariaLabel is provided this will be set instead of label */
    ariaLabel: PropTypes.string,
    autoComplete: PropTypes.string,
    bottomSpacing: PropTypes.string,
    className: PropTypes.string,
    /** Additional key to look up and display specific message variations */
    copyNamespace: PropTypes.string.isRequired,
    /** Country ID to render correct flag */
    countryId: PropTypes.string,
    /**
     * Pattern to use for validation or keyboard layouts in mobile
     * Allows the caller to define a specific pattern.
     * Should not be used, use instead: pattern
     * */
    customPattern: PropTypes.string,
    dataTestid: PropTypes.string,
    disabled: PropTypes.bool,
    /** If disabled is true, define here the look of the disabled field */
    disabledLayout: PropTypes.oneOf(["default", "opacity"]),
    feedback: PropTypes.shape({
      name: PropTypes.string,
      hasError: PropTypes.bool,
      isValid: PropTypes.bool,
      feedbackMessageId: PropTypes.string,
      errorAriaDescribedBy: PropTypes.string,
    }),
    feedbackInfoMessage: PropTypes.shape({
      message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      isShowInfoMessage: PropTypes.bool,
    }),
    feedbackLinkClick: PropTypes.func,
    feedbackLinkSettings: PropTypes.object,
    feedbackMessageIdPrefix: PropTypes.string,
    feedbackTrackingKeyPath: PropTypes.string,
    forceFeedbackMessage: PropTypes.bool,
    /**
     * Allows to force show the success state. If set to false, the success state will
     * be shown depending only on showSuccessStateOnBlurOnly
     * */
    forceShowSuccessState: PropTypes.bool,
    interpolations: PropTypes.object,
    /** Internationalization library */
    intl: IntlPropType.isRequired,
    /** Content of the input label. If visuallyHiddenLabel is true this becomes the content of aria-label. */
    label: PropTypes.string,
    /** If set to true the label will be displayed as placeholder and moves to top on focus */
    labelAsPlaceholder: PropTypes.bool,
    linkFeedbackMessageId: PropTypes.string,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onBlurValueFormatter: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyUp: PropTypes.func,
    pattern: PropTypes.oneOf(Object.values(PATTERNS)),
    placeholder: PropTypes.string,
    reference: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    /**
     * If you set this to true, it will look up for a country ID and render the flag inside the input. *
     * */
    renderFlags: PropTypes.bool,
    shouldShowAsteriskForRequiredData: PropTypes.bool,
    /**
     * If you set this to true, the valid state will be shown only when you blur the input and not as you type. *
     * Use when you are not validating the component onChange and only onBlue
     * */
    showSuccessStateOnBlurOnly: PropTypes.bool,
    type: PropTypes.oneOf(Object.values(TYPES)).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** The label will be visually hidden. Also the label becomes content of aria-label. */
    visuallyHiddenLabel: PropTypes.bool,
  };

  static defaultProps = {
    acceptDecimals: false,
    ariaDescribedBy: undefined,
    ariaLabel: undefined,
    autoComplete: "off",
    bottomSpacing: null,
    className: undefined,
    countryId: undefined,
    customPattern: undefined,
    dataTestid: undefined,
    disabled: false,
    disabledLayout: "default",
    feedback: {
      feedbackMessageId: undefined,
      hasError: false,
      isValid: false,
      name: undefined,
    },
    feedbackInfoMessage: { message: undefined, isShowInfoMessage: false },
    feedbackLinkSettings: { value: undefined, title: undefined },
    feedbackMessageIdPrefix: undefined,
    feedbackTrackingKeyPath: undefined,
    forceShowSuccessState: false,
    interpolations: {},
    label: undefined,
    labelAsPlaceholder: false,
    linkFeedbackMessageId: undefined,
    onBlur: Function.prototype,
    onBlurValueFormatter: (event) => event.target.value,
    onChange: Function.prototype,
    onFocus: Function.prototype,
    onKeyUp: null,
    pattern: undefined,
    placeholder: undefined,
    reference: React.createRef(),
    renderFlags: false,
    shouldShowAsteriskForRequiredData: false,
    showSuccessStateOnBlurOnly: true,
    value: null,
    visuallyHiddenLabel: false,
    forceFeedbackMessage: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      showSuccessState: props.feedback.isValid,
    };
  }

  getErrorMessageElement = () => {
    const { reference } = this.props;
    return reference.current && reference.current.parentNode
      ? reference.current.parentNode.querySelector("span")
      : undefined;
  };

  onFocus = (event) => {
    const { showSuccessStateOnBlurOnly, onFocus } = this.props;

    if (!showSuccessStateOnBlurOnly) {
      this.setState({
        showSuccessState: true,
      });
    }

    onFocus(event);
  };

  onBlur = (event) => {
    const { onBlur, showSuccessStateOnBlurOnly } = this.props;
    const elem = event.target;

    // Space as first character is not allowed and will be removed if accidentally entered
    elem.value = elem.value.trim();

    onBlur(event);

    if (showSuccessStateOnBlurOnly) {
      this.setState({
        showSuccessState: true,
      });
    }
  };

  onChange = (event) => {
    const { onChange } = this.props;

    onChange(event);
  };

  shouldIShowErrorMessage = () => {
    const { feedback } = this.props;

    return feedback.hasError;
  };

  getValidationAriaDescribedBy = (renderSuccess) => {
    const { name, feedback, feedbackInfoMessage } = this.props;

    if (renderSuccess) {
      return `success_${name}`;
    }

    if (feedbackInfoMessage.isShowInfoMessage) {
      return `infoMessage_${name}`;
    }

    if (feedback.hasError && feedback.errorAriaDescribedBy) {
      return feedback.errorAriaDescribedBy;
    }

    if (feedback.hasError) {
      return `${name}_validationMessage`;
    }

    return undefined;
  };

  getAriaDescribedBy = (renderSuccess) => {
    const { ariaDescribedBy } = this.props;

    // Get only unique values
    return (
      Array.from([
        ...new Set([
          this.getValidationAriaDescribedBy(renderSuccess),
          ...(ariaDescribedBy || "").split(" "),
        ]),
      ])
        .filter(Boolean)
        .join(" ") || undefined
    );
  };

  createLabel = (isRequiredParam) => {
    const { label, shouldShowAsteriskForRequiredData } = this.props;
    return shouldShowAsteriskForRequiredData && isRequiredParam
      ? `${label} <span aria-label=" "> *</span>`
      : label;
  };

  render() {
    const {
      forceFeedbackMessage,
      ariaDescribedBy,
      ariaLabel,
      autoComplete,
      bottomSpacing,
      className,
      copyNamespace,
      countryId,
      dataTestid,
      feedback,
      feedbackInfoMessage,
      feedbackLinkClick,
      feedbackLinkSettings,
      feedbackMessageIdPrefix,
      feedbackTrackingKeyPath,
      forceShowSuccessState,
      interpolations,
      intl,
      label,
      labelAsPlaceholder,
      linkFeedbackMessageId,
      name,
      onBlurValueFormatter,
      reference,
      renderFlags,
      shouldShowAsteriskForRequiredData,
      showSuccessStateOnBlurOnly,
      value,
      visuallyHiddenLabel,
      ...extraPropsForInput
    } = this.props;

    const { showSuccessState } = this.state;

    const renderSuccess =
      forceShowSuccessState && value !== ""
        ? true
        : showSuccessState && feedback.isValid && value !== "";

    const ariaLabelVisible = visuallyHiddenLabel ? label : "";
    const ariaLabelContent = ariaLabel !== "" ? ariaLabel : ariaLabelVisible;
    const ariaLabelOptional = {
      ...(ariaLabelContent && { "aria-label": ariaLabelContent }),
    };

    const showErrorState = feedback.hasError ? feedback.hasError : false;

    // The class name will allow us to adjust vertical or horizontal spacing of this component within a form
    return (
      <InputContainer
        hasFlags={renderFlags}
        feedbackLinkClick={feedbackLinkClick}
        countryId={countryId}
      >
        {renderSuccess && !extraPropsForInput.disabled && !showErrorState && (
          <Fragment>
            <Text className="visually-hidden" id={`success_${name}`}>
              {intl.formatMessage(
                messages[
                  `${copyNamespace}_screenReaderLabel_successDescribedBy`
                ]
              )}
            </Text>
          </Fragment>
        )}
        {renderFlags && (
          <FlagContainer>
            <Flag country={countryId} />
          </FlagContainer>
        )}
        <Input
          {...extraPropsForInput}
          {...ariaLabelOptional}
          className={className}
          ariaDescribedBy={this.getAriaDescribedBy(renderSuccess)}
          autoComplete={autoComplete}
          blurValueFormatter={onBlurValueFormatter}
          dataTestid={dataTestid}
          hasError={showErrorState}
          id={name}
          name={name}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onFocus={this.onFocus}
          reference={reference}
          value={value}
          label={this.createLabel(extraPropsForInput.required)}
          copyNamespace={copyNamespace}
          feedbackMessageIdPrefix={feedbackMessageIdPrefix}
          feedbackMessageId={feedback.feedbackMessageId}
          interpolations={interpolations}
          isSuccess={
            renderSuccess && !extraPropsForInput.disabled && !showErrorState
          }
          feedbackInfoMessage={feedbackInfoMessage}
          forceFeedbackMessage={forceFeedbackMessage}
        />
      </InputContainer>
    );
  }
}

export const ForwardedFormField = injectIntl(
  React.forwardRef((props, ref) => <FormField {...props} forwardedRef={ref} />)
);

export default injectIntl(FormField);
