// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * BookingNotAllowed
 *
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Text } from "@dhl-official/react-ui-library";
import { injectIntl } from "react-intl";
import http from "one-time-shipment/src/lib/http";
import {
  Icon,
  Headline,
  ContentModal,
  Link,
} from "root/libs/ui-components/src";
import { colors, breakpoints } from "root/libs/ui-styleguide/src";
import phoneIcon from "icons/cx/phone.svg";
import styled from "styled-components";

const Content = styled.div`
  width: 100%;
  height: 100%;
`;

const CopyContainer = styled.div`
  text-align: center;
  margin: 50px 0 50px 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: 40px 0 50px 0;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin: 20px 0 30px 0;
  }
`;

const CopyText = styled(Text)`
  margin-bottom: ${(props) => (props.doubleBottomSpacing ? "20px" : 0)};
  text-align: center;
`;

const IconContainer = styled.span`
  position: relative;
  top: -1px;
  ${(props) => (props.insertBefore ? "right: 10px" : "left: 10px")};
`;

const SecondaryButtonContainer = styled.div`
  margin-top: 25px;
`;

const COPY_ID_PREFIX = "BookingNotAllowed";
const BookingNotAllowed = (props) => {
  const { intl, onClose, trackEvent, currentOfferData } = props;

  useEffect(() => {
    trackEvent({ bookingNotAllowedModalTriggered: true });
    const { pathToPurchaseLink } = currentOfferData;
    if (pathToPurchaseLink) {
      http.logPowerBIEvent(pathToPurchaseLink);
    }
  }, []);

  const secondaryPhoneNumberExists = !!intl.messages[
    `${COPY_ID_PREFIX}.secondaryPhoneNumberFormatted`
  ];

  return (
    <ContentModal
      {...props}
      closeButtonAriaLabel={intl.formatMessage({ id: "SS.ContentModal.close" })}
      contentAriaLabel={intl.formatMessage({
        id: `${COPY_ID_PREFIX}.headline`,
      })}
      onClose={() => onClose({})}
      smallCenteredModal
    >
      <Content>
        <CopyContainer>
          <Headline priority={3} weight="200">
            {intl.formatMessage({ id: `${COPY_ID_PREFIX}.headline` })}
          </Headline>
          <CopyText isParagraph doubleBottomSpacing>
            {intl.formatMessage({ id: `${COPY_ID_PREFIX}.copy` })}
          </CopyText>
          <CopyText isParagraph>
            <Link
              href={`tel:${intl.formatMessage({
                id: `${COPY_ID_PREFIX}.phoneNumberFormatted`,
              })}`}
              linkType="cta primary"
            >
              <IconContainer insertBefore>
                <Icon
                  fillColor={colors.white}
                  icon={phoneIcon}
                  width={{ mobile: "12px", desktop: "14px" }}
                  height={{ mobile: "12px", desktop: "14px" }}
                  useAs="decorative"
                />
              </IconContainer>
              {intl.formatMessage({
                id: `${COPY_ID_PREFIX}.phoneNumberFormatted`,
              })}
            </Link>
          </CopyText>
          {secondaryPhoneNumberExists && (
            <SecondaryButtonContainer>
              <Link
                href={`tel:${intl.formatMessage({
                  id: `${COPY_ID_PREFIX}.secondaryPhoneNumberLink`,
                })}`}
                linkType="cta secondary"
              >
                <IconContainer insertBefore>
                  <Icon
                    fillColor={colors.redScarlet}
                    icon={phoneIcon}
                    useAs="decorative"
                    width={{ mobile: "12px", desktop: "14px" }}
                    height={{ mobile: "12px", desktop: "14px" }}
                  />
                </IconContainer>
                {intl.formatMessage({
                  id: `${COPY_ID_PREFIX}.secondaryPhoneNumberFormatted`,
                })}
              </Link>
            </SecondaryButtonContainer>
          )}
        </CopyContainer>
      </Content>
    </ContentModal>
  );
};

BookingNotAllowed.propTypes = {
  intl: PropTypes.any.isRequired,
  onClose: PropTypes.func,
  trackEvent: PropTypes.func.isRequired,
  currentOfferData: PropTypes.object.isRequired,
};

BookingNotAllowed.defaultProps = {
  onClose: () => {},
};

export default injectIntl(BookingNotAllowed);
