// 9fbef606107a605d69c0edbcd8029e5d

export default {
  CANARY_HEADER: "X-Canary",
  MICRO_FRONTEND_ID_HEADER: "X-Source",
  PATHNAME_HEADER: "X-Pathname",
  SAVE_SHIPMENT_LOCAL_STORAGE: "gaq-shipment-info",
  CORRELATION_TRACE_ID: "X-B3-TraceId",
  CORRELATION_SPAN_ID: "X-B3-SpanId",
  TRACEPARENT: "traceparent",
  DHL_COOKIE_CONSENT_COOKIE: "OptanonConsent",
  TARGET_SERVICE_CARD_KEY: "TARGET_SERVICE_CARD",
  TARGET_SERVICE_CARD_CID: "2Ly9tbBg",
  TARGET_SERVICE_CARD_COOKIE: {
    name: "gaq-quote-counter",
    maxValue: 4,
    age: 30,
  },
  DATA_CONTAINER_ELEMENT: "dhl-cj-personalization-container",
  PARAM_KEYS: ["canaryVersion", "experience", "abTesting", "microFrontendId"],
  DATA_ATTRS: {
    canaryVersion: "gaqCanaryVersion",
    experience: "gaqExperience",
    abTesting: "gaqAbtesting",
    microFrontendId: "microFrontendId",
  },
};
