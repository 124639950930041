// 9fbef606107a605d69c0edbcd8029e5d

import React, { memo, forwardRef } from "react";
import PropTypes from "prop-types";
import { keyCodes } from "root/libs/constants/src";
import { StyledLink } from "./styled";

const LINK_TYPES = {
  CTA_PRIMARY: "cta primary",
  CTA_SECONTADY: "cta secondary",
  CTA_SUCCESS: "cta success",
  INLINE_BLOCK: "inline-black",
  INLINE: "inline",
  PRIMARY: "primary",
};

const Link = forwardRef((props, ref) => {
  const {
    ariaLabel,
    ariaLabelledBy,
    ariaDescribedBy,
    children,
    className,
    dataTestid,
    dataTracking,
    disableMinWidth,
    href,
    id,
    linkType,
    onClick,
    rel,
    role,
    target,
  } = props;

  const onKeyDown = (event) => {
    const { keyCode } = event;

    if (keyCode === keyCodes.RETURN || keyCode === keyCodes.SPACE) {
      event.preventDefault();
      event.target.click();
    }
  };

  return (
    <StyledLink
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
      className={["link", linkType, className].filter(Boolean).join(" ")}
      data-testid={dataTestid}
      data-tracking={dataTracking}
      disableMinWidth={disableMinWidth}
      href={href}
      id={id}
      onClick={onClick}
      onKeyDown={onKeyDown}
      ref={ref}
      rel={rel || (target === "_blank" ? "noopener noreferrer" : undefined)}
      role={role}
      target={target}
    >
      {children}
    </StyledLink>
  );
});

Link.displayName = "Link";

Link.propTypes = {
  /** If set will add aria-label attribute to the anchor which will read by screen readers when element is focussed */
  ariaLabel: PropTypes.string,
  ariaLabelledBy: PropTypes.string,
  children: PropTypes.any.isRequired,
  /** You can send your required class names. * Also you can use one for the linkTypes * to combine results */
  className: PropTypes.string,
  dataTestid: PropTypes.string,
  dataTracking: PropTypes.string,
  disableMinWidth: PropTypes.bool,
  href: PropTypes.string.isRequired,
  id: PropTypes.string,
  linkType: PropTypes.oneOf(Object.values(LINK_TYPES)),
  /** onClick handler for the Link element. We attach this event also to onKeyPress. */
  onClick: PropTypes.func,
  rel: PropTypes.string,
  role: PropTypes.string,
  target: PropTypes.string,
};

Link.defaultProps = {
  ariaLabel: undefined,
  ariaLabelledBy: undefined,
  className: undefined,
  dataTestid: undefined,
  dataTracking: undefined,
  disableMinWidth: false,
  id: undefined,
  linkType: undefined,
  onClick: Function.prototype,
  rel: undefined,
  role: undefined,
  target: undefined,
};

export default memo(Link);
