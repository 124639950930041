// 9fbef606107a605d69c0edbcd8029e5d

export const CHECK_LOCATION_VALIDATION =
  "app/TradeLane/CHECK_LOCATION_VALIDATION";
export const FLIP_ORIGIN_DESTINATION = "app/TradeLane/ORIGIN_DESTINATION_FLIP";
export const GET_SHIPMENT_LANE_OPTIONS =
  "app/TradeLane/GET_SHIPMENT_LANE_OPTIONS";
export const GET_SHIPMENT_LANE_OPTIONS_REQUEST_FAILED =
  "app/TradeLane/GET_SHIPMENT_LANE_OPTIONS_REQUEST_FAILED";
export const GET_SHIPMENT_LANE_OPTIONS_REQUEST_SUCCEEDED =
  "app/TradeLane/GET_SHIPMENT_LANE_OPTIONS_REQUEST_SUCCEEDED";
export const PREFILL_INITIAL_SET = "app/TradeLane/INITIAL_SET_PREFILL";
export const PREFILL_TRADE_LANE_FROM_URL =
  "app/TradeLane/PREFILL_TRADE_LANE_FROM_URL";
export const QUERY_CITY = "app/TradeLane/QUERY_CITY";
export const QUERY_CITY_FAILURE = "app/TradeLane/QUERY_CITY_FAILURE";
export const QUERY_CITY_SUCCESS = "app/TradeLane/QUERY_CITY_SUCCESS";
export const QUERY_COUNTRY = "app/TradeLane/QUERY_COUNTRY";
export const QUERY_POSTAL_CODE = "app/TradeLane/QUERY_POSTAL_CODE";
export const QUERY_POSTAL_CODE_FAILURE =
  "app/TradeLane/QUERY_POSTAL_CODE_FAILURE";
export const QUERY_POSTAL_CODE_SUCCESS =
  "app/TradeLane/QUERY_POSTAL_CODE_SUCCESS";
export const RESET_DOCUMENT = "app/TradeLane/RESET_DOCUMENT";
export const RESET_DESTINATION_FIELDS =
  "app/TradeLane/RESET_DESTINATION_FIELDS";
export const RESET_INVALID_FIELDS = "app/TradeLane/RESET_INVALID_FIELDS";
export const RESET_SAVED_QUOTE = "app/TradeLane/RESET_SAVED_QUOTE";
export const SELECT_CITY = "app/TradeLane/SELECT_CITY";
export const SELECT_COUNTRY = "app/TradeLane/SELECT_COUNTRY";
export const SELECT_POSTAL_CODE = "app/TradeLane/SELECT_POSTAL_CODE";
export const SET_INITIAL_COUNTRY = "app/TradeLane/SET_INITIAL_COUNTRY";
export const SET_DOCUMENTS_VALUE = "app/TradeLane/SET_DOCUMENTS_VALUE";
export const SET_DOMESTIC_SHIPPING_AVAILABILITY =
  "app/TradeLane/SET_DOMESTIC_SHIPPING_AVAILABILITY";
export const UPDATE_CITY = "app/TradeLane/UPDATE_CITY";
export const UPDATE_DESTINATION_ADDRESS_TYPE =
  "app/TradeLane/UPDATE_DESTINATION_ADDRESS_TYPE";
export const UPDATE_DOCUMENT = "app/TradeLane/UPDATE_DOCUMENT";
export const UPDATE_POSTAL_CODE = "app/TradeLane/UPDATE_POSTAL_CODE";
export const VALIDATE_CITY = "app/TradeLane/VALIDATE_CITY";
export const VALIDATE_CITY_FAILURE = "app/TradeLane/VALIDATE_CITY_FAILURE";
export const VALIDATE_CITY_SUCCESS = "app/TradeLane/VALIDATE_CITY_SUCCESS";
export const VALIDATE_COUNTRY_FIELD = "app/TradeLane/VALIDATE_COUNTRY_FIELD";
export const VALIDATE_FORM_FIELD = "app/TradeLane/VALIDATE_FORM_FIELD";
export const VALIDATE_LOCATION = "app/TradeLane/VALIDATE_LOCATION";
export const VALIDATE_LOCATION_FAILURE =
  "app/TradeLane/VALIDATE_LOCATION_FAILURE";
export const VALIDATE_LOCATION_SUCCESS =
  "app/TradeLane/VALIDATE_LOCATION_SUCCESS";
export const VALIDATE_POSTAL_CODE = "app/TradeLane/VALIDATE_POSTAL_CODE";
export const VALIDATE_POSTAL_CODE_FAILURE =
  "app/TradeLane/VALIDATE_POSTAL_CODE_FAILURE";
export const VALIDATE_POSTAL_CODE_SUCCESS =
  "app/TradeLane/VALIDATE_POSTAL_CODE_SUCCESS";
export const QUERY_POSTAL_CODE_PLACEHOLDER =
  "app/TradeLane/QUERY_POSTAL_CODE_PLACEHOLDER";
export const QUERY_POSTAL_CODE_PLACEHOLDER_SUCCEEDED =
  "app/TradeLane/QUERY_POSTAL_CODE_PLACEHOLDER_SUCCEEDED";
export const SET_DEFAULT_DESTINATION = "app/TradeLane/SET_DEFAULT_DESTINATION";
export const TOGGLE_DOMESTIC_SHIPMENT_EXCEPTION =
  "app/TradeLane/TOGGLE_DOMESTIC_SHIPMENT_EXCEPTION";

export const LOAD_POSTAL_LOCATION_COUNTRIES =
  "app/TradeLane/LOAD_POSTAL_LOCATION_COUNTRIES";
export const LOAD_POSTAL_LOCATION_COUNTRIES_FAILURE =
  "app/TradeLane/LOAD_POSTAL_LOCATION_COUNTRIES_FAILURE";
export const LOAD_POSTAL_LOCATION_COUNTRIES_SUCCESS =
  "app/TradeLane/LOAD_POSTAL_LOCATION_COUNTRIES_SUCCESS";
export const SET_POSTAL_LOCATION_COUNTRIES_FROM_STORAGE =
  "app/TradeLane/SET_POSTAL_LOCATION_COUNTRIES_FROM_STORAGE";
export const SET_COUNTRIES_WITHOUT_POSTAL_CODE =
  "app/TradeLane/SET_COUNTRIES_WITHOUT_POSTAL_CODE";
