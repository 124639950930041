// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for OfferCardWrapper
 *
 */

import styled from "styled-components";
import { breakpoints } from "root/libs/ui-styleguide/src";

const gutter = "2rem";

const getTemplateAreas = (expandedCardIndex) => {
  switch (expandedCardIndex) {
    case 0:
      return `"card-0 card-0 card-1"
              "card-0 card-0 card-2"`;
    case 1:
      return `"card-0 card-1 card-1"
              "card-2 card-1 card-1"`;
    case 2:
      return `"card-0 card-2 card-2"
              "card-1 card-2 card-2"`;
    default:
      return `"card-0 card-1 card-2"
              "card-0 card-1 card-2"`;
  }
};

export const StyledFlexWrapper = styled.div`
  display: grid;
  transition: 200ms;
  grid-gap: ${gutter};

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: ${(props) =>
      props.isExpanded ? "repeat(2, minmax(0, 1fr))" : "auto"};
    grid-gap: ${(props) => (props.isExpanded === false ? 0 : gutter)};
    grid-template-areas: ${(props) =>
      getTemplateAreas(props.expandedCardIndex)};
    margin-top: ${gutter};
  }
`;
