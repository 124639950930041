// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Label
 *
 */

import React from "react";
import PropTypes from "prop-types";

import { StyledLabel } from "./styled";

export default class Label extends React.PureComponent {
  static propTypes = {
    children: PropTypes.any,
    target: PropTypes.string.isRequired,
    isBold: PropTypes.bool,
    extraStyles: PropTypes.array,
    hasError: PropTypes.bool,
    reference: PropTypes.object,
    cssFontSize: PropTypes.oneOf(["label", "copy"]),
  };

  static defaultProps = {
    children: "",
    cssFontSize: "label",
    isBold: false,
    hasError: false,
    extraStyles: [],
    reference: {
      current: null,
    },
  };

  render() {
    const {
      children,
      target,
      isBold,
      extraStyles,
      hasError,
      reference,
      cssFontSize,
      ...extraProps
    } = this.props;

    return (
      // eslint-disable-next-line jsx-a11y/label-has-for
      <StyledLabel
        cssFontSize={cssFontSize}
        hasError={hasError}
        htmlFor={target}
        isBold={isBold}
        extraStyles={extraStyles}
        ref={reference}
        {...extraProps}
      >
        {children}
      </StyledLabel>
    );
  }
}
