// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * OfferCardWrapper
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { IntlPropType } from "root/libs/core-libs/src";
import { CountrySettings } from "root/libs/ui-containers/src";
import WCCOfferCard from "one-time-shipment/src/components/Offers/WCCOfferCard";
import { StyledFlexWrapper } from "./styled";

const { withCountry } = CountrySettings;

const COPY_ID_PREFIX = "WCCOfferCardWrapper";

export default class WCCOfferCardWrapper extends React.PureComponent {
  static propTypes = {
    /** Internationalization library */
    intl: IntlPropType.isRequired,
    offer: PropTypes.object,
    isBusiness: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.WCCOfferCardWithCountry = withCountry(WCCOfferCard);
    this.state = {
      expandedCardIndex: null,
    };
  }

  getCopy = (id) => {
    const { intl } = this.props;
    return intl.formatMessage({ id });
  };

  handleExpandCard = (index, parentRef) => {
    let newIndex = index;
    const { expandedCardIndex } = this.state;
    if (index === expandedCardIndex) {
      newIndex = null;
    }
    this.setState({
      expandedCardIndex: newIndex,
    });

    setTimeout(() => {
      if (parentRef && parentRef.current && window.scrollY) {
        const headerOffset = 65;
        const elementPosition = parentRef.current.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.scrollY - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }, 0);
  };

  render() {
    const { offer, intl, isBusiness } = this.props;
    const { expandedCardIndex } = this.state;

    const { metadata } = offer;
    const { availablePurchaseMethods } = metadata;
    const indexLength = availablePurchaseMethods.length;

    return (
      <StyledFlexWrapper
        className="grid-maxWidthInner no-padding"
        isExpanded={expandedCardIndex !== null}
        data-testid="wcc-offer-card-wrapper"
        expandedCardIndex={expandedCardIndex}
      >
        {availablePurchaseMethods.map((method, index) => (
          <this.WCCOfferCardWithCountry
            key={method}
            indexLength={indexLength}
            isFirst={index === 0}
            expandedCardIndex={expandedCardIndex}
            isExpanded={index === expandedCardIndex}
            isLast={index === availablePurchaseMethods.length - 1}
            isHighlighted={method === metadata?.highlightedPurchaseMethod}
            purchaseMethod={method}
            offer={offer}
            intl={intl}
            isBusiness={isBusiness}
            offerIndex={index}
            iNeedTheseProps={[
              "nativeLanguageCode",
              "is12HourTimeFormat",
              "nativeDateTimeFormat",
            ]}
            handleExpandCard={this.handleExpandCard}
          />
        ))}
        <span
          id="wcc-offer-card-wrapper-more-information"
          className="visually-hidden"
        >
          {this.getCopy(`${COPY_ID_PREFIX}.screenReaderLabel_moreInformation`)}
        </span>
      </StyledFlexWrapper>
    );
  }
}
