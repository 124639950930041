// 9fbef606107a605d69c0edbcd8029e5d

import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { SS } from "root/libs/constants/src";
import { localization } from "root/libs/core-libs/src";
import {
  Button,
  Text,
  TEXT_SIZES,
  TEXT_WEIGHTS,
} from "@dhl-official/react-ui-library";
import LanguageSelector from "../LanguageSelector";
import Textarea from "../../Forms/Textarea";
import {
  StyledSettingsComponent,
  SettingsContainerWrapper,
  SettingsCol,
  StyledText,
} from "./styled";

const Settings = ({
  environment,
  initialPayload,
  locales,
  onNewEvent,
  onUpdateLocale,
  selectedLocale,
  version,
  availableAbTestingOptions,
  availableCanaryVersionsOptions,
}) => {
  const [eventPayload, setEventPayload] = useState(initialPayload);

  useEffect(() => {
    document.querySelector("html").setAttribute("lang", selectedLocale);
  }, [selectedLocale]);

  return (
    <StyledSettingsComponent>
      <Text weight={TEXT_WEIGHTS[800]} isParagraph>
        {version && `Version: ${version}`}
        <br />
        Environment:
        {` ${environment}`}
      </Text>

      <StyledText size={TEXT_SIZES.SMALL}>
        {" "}
        This section is used for development purposes only. Hide with:&nbsp;
        <code>
          localStorage.setItem(&#34;environmentSettingsEnabled&#34;, false);
        </code>
      </StyledText>

      <SettingsContainerWrapper>
        <SettingsCol>
          <LanguageSelector
            selectedLocale={selectedLocale}
            locales={locales}
            onChange={({ target }) => {
              const locale = target.value;
              const country = localization.getCountry(locale);
              const language = localization.getLanguage(locale);

              onUpdateLocale({
                country,
                language,
                locale,
              });
            }}
          />
          <br />
          <Text weight={TEXT_WEIGHTS[800]} isParagraph>
            Available A/B Testing variants:
          </Text>
          <code>{availableAbTestingOptions}</code>
          <br />
          <br />
          <Text weight={TEXT_WEIGHTS[800]} isParagraph>
            Available Canary Versions:
          </Text>
          <code>{availableCanaryVersionsOptions}</code>
        </SettingsCol>

        <SettingsCol>
          <fieldset>
            <legend>Update to trigger personalization events:</legend>
            <Textarea
              copyNamespace={SS}
              height="150px"
              label="Personalization Object"
              name="commentsSettings"
              onChange={({ target }) => setEventPayload(target.value)}
              value={eventPayload}
            />

            <br />

            <Button
              name="button"
              onClick={() => {
                onNewEvent(JSON.parse(eventPayload));
              }}
              type="submit"
            >
              Trigger Personalization event
            </Button>
          </fieldset>
        </SettingsCol>
      </SettingsContainerWrapper>
    </StyledSettingsComponent>
  );
};

Settings.propTypes = {
  environment: PropTypes.string.isRequired,
  initialPayload: PropTypes.string.isRequired,
  locales: PropTypes.any.isRequired,
  onNewEvent: PropTypes.func.isRequired,
  onUpdateLocale: PropTypes.func.isRequired,
  selectedLocale: PropTypes.string.isRequired,
  version: PropTypes.string,
  availableAbTestingOptions: PropTypes.string.isRequired,
  availableCanaryVersionsOptions: PropTypes.string.isRequired,
};

Settings.defaultProps = {
  version: "",
};

export default memo(Settings);
