// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * DomesticShipmentNotAllowed
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { Text, Button, BUTTON_VARIANTS } from "@dhl-official/react-ui-library";
import { injectIntl } from "react-intl";
import {
  Icon,
  Headline,
  ContentModal,
  Link,
} from "root/libs/ui-components/src";
import { SEGMENT_TYPES } from "one-time-shipment/src/containers/SpotShipmentPage/constants";
import { colors, breakpoints } from "root/libs/ui-styleguide/src";
import fastDeliveryIcon from "iconsTwoColors/cx/fast-delivery.svg";
import styled from "styled-components";

const Content = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledIcon = styled(Icon)`
  margin: 0 auto;
  margin-bottom: 20px;
`;

const LinkCTA = styled(Link)`
  margin: 0 auto 20px auto;
  max-width: 180px;
`;

const CopyContainer = styled.div`
  text-align: center;
  margin: 50px 0 50px 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: 40px 0 50px 0;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin: 20px 0 30px 0;
  }
`;

const CopyText = styled(Text)`
  margin-bottom: 20px;
  text-align: center;
`;

const COPY_ID_PREFIX = "DomesticShipmentNotAllowed";

const DomesticShipmentNotAllowed = (props) => {
  const { intl, onClose, isDomesticShippingAvailable } = props;
  const ctaExists = !!intl.messages[`${COPY_ID_PREFIX}.ctaText`];

  function getSegmentName(isDomesticShippingAvailableParam) {
    let segmentName;

    if (isDomesticShippingAvailableParam.private) {
      segmentName = {
        id: SEGMENT_TYPES.private,
      };
    } else if (isDomesticShippingAvailableParam.business) {
      segmentName = { id: SEGMENT_TYPES.business };
    } else {
      segmentName = undefined;
    }
    return segmentName;
  }

  return (
    <ContentModal
      {...props}
      closeButtonAriaLabel={intl.formatMessage({ id: "SS.ContentModal.close" })}
      contentAriaLabel={intl.formatMessage({
        id: `${COPY_ID_PREFIX}.headline`,
      })}
      onClose={onClose}
      smallCenteredModal
      variant="bottom"
    >
      <Content>
        <CopyContainer>
          <StyledIcon
            fillColor={colors.redScarlet}
            icon={fastDeliveryIcon}
            useAs="decorative"
            width={{ mobile: "108px", desktop: "108px" }}
            height={{ mobile: "64px", desktop: "64px" }}
            extraStyles={[{ margin: "0 auto" }]}
          />
          <Headline priority={2} headlineDisplayType="h" weight="700">
            {intl.formatMessage({
              id: `${COPY_ID_PREFIX}.headline`,
            })}
          </Headline>
          <CopyText doubleBottomSpacing isParagraph>
            {intl.formatMessage({
              id: `${COPY_ID_PREFIX}.description`,
            })}
          </CopyText>
          <LinkCTA
            href={intl.formatMessage({
              id: `${COPY_ID_PREFIX}.linkUrl`,
            })}
            dataTestid="create-shipment-profile-cta"
            linkType="cta primary"
          >
            {intl.formatMessage({
              id: `${COPY_ID_PREFIX}.linkText`,
            })}
          </LinkCTA>
          {ctaExists && (
            <Button
              name="ship-domestic-cta"
              dataTestid="ship-domestic-cta"
              variant={BUTTON_VARIANTS.TEXT}
              type="button"
              onClick={() =>
                onClose({
                  segment: getSegmentName(isDomesticShippingAvailable),
                })
              }
            >
              {intl.formatMessage({ id: `${COPY_ID_PREFIX}.ctaText` })}
            </Button>
          )}
        </CopyContainer>
      </Content>
    </ContentModal>
  );
};

DomesticShipmentNotAllowed.propTypes = {
  intl: PropTypes.any.isRequired,
  onClose: PropTypes.func,
  isDomesticShippingAvailable: PropTypes.shape({
    private: PropTypes.bool,
    business: PropTypes.bool,
  }),
};

DomesticShipmentNotAllowed.defaultProps = {
  onClose: () => {},
};

export default injectIntl(DomesticShipmentNotAllowed);
