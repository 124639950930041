// 9fbef606107a605d69c0edbcd8029e5d

/**
 * The function transforms values from " " (empty space) to "" in an object.
 *
 * Example:
 * Given input `{ name: "Matheus", lastName: "Lustosa", age: " " }`
 * Would output `{ name: "Matheus", lastName: "Lustosa", age: "" }`
 *
 * Why bother?
 * This function exists to _fix_ an issue on AEM where it cannot store empty or null keys.
 * So we defined the whitespace as a placeholder that will get replaced by an empty string
 * when we fetch the translations.
 */
export default (translation) =>
  Object.entries(translation).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value === " " ? "" : value,
    }),
    {}
  );
