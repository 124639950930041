// 9fbef606107a605d69c0edbcd8029e5d

import { css } from "styled-components";
import { breakpoints, grid } from "./variables";
import { theme } from "./themes/getTheme";
import cssLogisticsResets from "./css.logisticsResets";
import sanitize from "./css.sanitize";

const { fonts, colors } = theme;

/* stylelint-disable no-descending-specificity */

// Note: All our global styles are under: .dhl-cj-main
// we dont put them under the body to prevent CSS clashes with dhl.com CSS
const body = css`
  ${sanitize}

  /* Adding the DUIL v2 design tokens for the WCC cards */
  :root {
    --dui-color-black-500: #000000ff;
    --dui-color-white-500: #ffffffff;
    --dui-color-green-500: #007c39ff;
    --dui-color-cool-green-800: #a3d0b8ff;
    --dui-color-gray-300: #ccccccff;
    --gaq-color-cool-blue-800: #B2DEF3ff;
    --gaq-shadow-multi-bu: var(--dui-color-gray-300);
    --dui-size-space-12x: 24px;
  }

  /*
   Why do we have the difference on how the body and html height are defined?
   This is a good explanation: https://www.kirupa.com/html5/make_body_take_up_full_browser_height.html
   TL;DR: We want to ensure that the height of the body and our app container (dhl-cj-main) expand as big as its content.
   We also want to allow the browser to calculate the diffence between the clientHeight and the scrollHeight, so that
   JS scrolls work.
  */
  html {
    height: 100%;
  }

  body,
  .dhl-cj-main {
    min-height: 100%;
  }

  html,
  body,
  .dhl-cj-main {
    width: 100%;
  }

  .dhl-cj-main {
    /* We need to define ltr here so the plugin will replace to rtl */
    direction: ltr;
    /* We want the background color to apply only to our container and not to the whole dhl */
    background-color: ${colors.globalHighlightBackgroundColor};
    color: ${colors.fontColor};
    display: block;
    font-family: var(--dui-font-family);
    font-size: ${fonts.mobile.regular.size};
    line-height: ${fonts.mobile.regular.lineHeight};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: auto;

    @media screen and (min-width: ${breakpoints.tablet}) {
      font-size: ${fonts.tablet.regular.size};
      line-height: ${fonts.tablet.regular.lineHeight};
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      font-size: ${fonts.desktop.regular.size};
      line-height: ${fonts.desktop.regular.lineHeight};
    }

    @media print {
      background-color: ${colors.backgroundColorInverted};
      display: block;
    }

    ${cssLogisticsResets}

    p,
    span,
    button,
    input,
    select,
    textarea {
      font-family: var(--dui-font-family);
    }

    input[type="checkbox"] + div {
      background-color: ${colors.backgroundColorInverted};
    }

    input,
    textarea,
    select {
      font-size: ${fonts.mobile.noZoomCopy.size};
      line-height: ${fonts.desktop.button.lineHeight};
    }

    input,
    textarea,
    select {
      @media screen and (min-width: ${breakpoints.tablet}) {
        font-size: ${fonts.tablet.button.size};
      }

      @media screen and (min-width: ${breakpoints.desktop}) {
        font-size: ${fonts.desktop.button.size};
      }
    }

    select::-ms-expand {
      display: none;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .L1,
    .L2,
    .L3,
    .L4,
    .L5,
    .L6 {
      font-family: var(--dui-font-family);
      color: ${colors.headerColor};
    }

    h1,
    .L1 {
      font-size: ${fonts.mobile.h1.size};
      line-height: ${fonts.mobile.h1.lineHeight};

      @media screen and (min-width: ${breakpoints.tablet}) {
        font-size: ${fonts.tablet.h1.size};
        line-height: ${fonts.tablet.h1.lineHeight};
      }

      @media screen and (min-width: ${breakpoints.desktop}) {
        font-size: ${fonts.desktop.h1.size};
        line-height: ${fonts.desktop.h1.lineHeight};
      }
    }

    h2,
    .L2 {
      font-size: ${fonts.mobile.h2.size};
      line-height: ${fonts.mobile.h2.lineHeight};

      @media screen and (min-width: ${breakpoints.tablet}) {
        font-size: ${fonts.tablet.h2.size};
        line-height: ${fonts.tablet.h2.lineHeight};
      }

      @media screen and (min-width: ${breakpoints.desktop}) {
        font-size: ${fonts.desktop.h2.size};
        line-height: ${fonts.desktop.h2.lineHeight};
      }
    }

    h3,
    .L3 {
      font-size: ${fonts.mobile.h3.size};
      line-height: ${fonts.mobile.h3.lineHeight};

      @media screen and (min-width: ${breakpoints.tablet}) {
        font-size: ${fonts.tablet.h3.size};
        line-height: ${fonts.tablet.h3.lineHeight};
      }

      @media screen and (min-width: ${breakpoints.desktop}) {
        font-size: ${fonts.desktop.h3.size};
        line-height: ${fonts.desktop.h3.lineHeight};
      }
    }

    h4,
    .L4 {
      font-size: ${fonts.mobile.h4.size};
      line-height: ${fonts.mobile.h4.lineHeight};

      @media screen and (min-width: ${breakpoints.tablet}) {
        font-size: ${fonts.tablet.h4.size};
        line-height: ${fonts.tablet.h4.lineHeight};
      }

      @media screen and (min-width: ${breakpoints.desktop}) {
        font-size: ${fonts.desktop.h4.size};
        line-height: ${fonts.desktop.h4.lineHeight};
      }
    }

    h5,
    .L5 {
      font-size: ${fonts.mobile.h5.size};
      line-height: ${fonts.mobile.h5.lineHeight};

      @media screen and (min-width: ${breakpoints.tablet}) {
        font-size: ${fonts.tablet.h5.size};
        line-height: ${fonts.tablet.h5.lineHeight};
      }

      @media screen and (min-width: ${breakpoints.desktop}) {
        font-size: ${fonts.desktop.h5.size};
        line-height: ${fonts.desktop.h5.lineHeight};
      }
    }

    h6,
    .L1 {
      font-size: ${fonts.mobile.h6.size};
      line-height: ${fonts.mobile.h6.lineHeight};

      @media screen and (min-width: ${breakpoints.tablet}) {
        font-size: ${fonts.tablet.h6.size};
        line-height: ${fonts.tablet.h6.lineHeight};
      }

      @media screen and (min-width: ${breakpoints.desktop}) {
        font-size: ${fonts.desktop.h6.size};
        line-height: ${fonts.desktop.h6.lineHeight};
      }
    }

    ul,
    ol {
      list-style-position: inherit;
    }
  }

  @media print {
    .c-media-tiles,
    .c-voc-footer--sitemap,
    .c-go-to-top-button {
      display: none;
    }
  }

  svg {
    /* RTL aligment Fix */
    /*! @noflip */
    direction: ltr;
  }

  #__SVG_SPRITE_NODE__ {
    display: none;
  }

  .freezeScroll {
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  .visually-hidden {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
  }

  .grid-fluid,
  .grid-maxWidth,
  .grid-maxWidthInner {
    margin-right: auto;
    margin-left: auto;

    &:not(.no-padding) {
      padding-left: ${grid.container.paddings.s};
      padding-right: ${grid.container.paddings.s};
    }

    @media screen and (min-width: ${breakpoints.tablet}) {
      &:not(.no-padding) {
        padding-left: unset;
        padding-right: unset;
      }
    }
  }

  .grid-fluid:not(.no-padding) {
    @media screen and (min-width: ${breakpoints.desktop}) {
      padding-left: ${grid.container.paddings.l};
      padding-right: ${grid.container.paddings.l};
    }
  }

  .grid-maxWidth {
    max-width: ${grid.container.maxWidth};
  }

  // Changes in the grid for Project Tara
  .no-margin {
    @media screen and (min-width: ${breakpoints.desktop}) {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  .grid-maxWidthInner {
    max-width: ${grid.container.maxWidthInnerContainer}px;

    &:not(.no-padding) {
      margin-left: ${grid.container.paddings.s};
      margin-right: ${grid.container.paddings.s};
      padding-left: 0;
      padding-right: 0;
      width: auto;
    }

    @media screen and (min-width: ${breakpoints.tablet}) {
      &:not(.no-padding) {
        margin-left: ${grid.container.paddings.m};
        margin-right: ${grid.container.paddings.m};
      }
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      &:not(.no-padding) {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .mx-wrapper {
    margin-left: ${grid.container.paddings.s};
    margin-right: ${grid.container.paddings.s};

    @media screen and (min-width: ${breakpoints.tablet}) {
      margin-left: ${grid.container.paddings.m};
      margin-right: ${grid.container.paddings.m};
    }
  }
`;

export default body;
