// 9fbef606107a605d69c0edbcd8029e5d

const getFlagForCountry = (country) => {
  switch (country) {
    case "AD":
      return {
        "--x": "-86px",
        "--y": "-12px",
      };

    case "AE":
      return {
        "--x": "-926px",
        "--y": "-432px",
      };

    case "AF":
      return {
        "--x": "-2px",
        "--y": "-96px",
      };

    case "AG":
      return {
        "--x": "-86px",
        "--y": "-96px",
      };

    case "AI":
      return {
        "--x": "-170px",
        "--y": "-12px",
      };

    case "AL":
      return {
        "--x": "-170px",
        "--y": "-96px",
      };

    case "AM":
      return {
        "--x": "-2px",
        "--y": "-180px",
      };

    case "AN":
      return {
        "--x": "-86px",
        "--y": "-180px",
      };

    case "AO":
      return {
        "--x": "-170px",
        "--y": "-180px",
      };

    case "AQ":
      return {
        "--x": "-254px",
        "--y": "-12px",
      };

    case "AR":
      return {
        "--x": "-254px",
        "--y": "-96px",
      };

    case "AS":
      return {
        "--x": "-254px",
        "--y": "-180px",
      };

    case "AT":
      return {
        "--x": "-2px",
        "--y": "-264px",
      };

    case "AU":
      return {
        "--x": "-86px",
        "--y": "-264px",
      };

    case "AW":
      return {
        "--x": "-170px",
        "--y": "-264px",
      };

    case "AZ":
      return {
        "--x": "-338px",
        "--y": "-12px",
      };

    case "BA":
      return {
        "--x": "-338px",
        "--y": "-96px",
      };

    case "BB":
      return {
        "--x": "-338px",
        "--y": "-180px",
      };

    case "BD":
      return {
        "--x": "-338px",
        "--y": "-264px",
      };

    case "BE":
      return {
        "--x": "-2px",
        "--y": "-348px",
      };

    case "BF":
      return {
        "--x": "-86px",
        "--y": "-348px",
      };

    case "BG":
      return {
        "--x": "-170px",
        "--y": "-348px",
      };

    case "BH":
      return {
        "--x": "-254px",
        "--y": "-348px",
      };

    case "BI":
      return {
        "--x": "-338px",
        "--y": "-348px",
      };

    case "BJ":
      return {
        "--x": "-422px",
        "--y": "-12px",
      };

    case "BL":
      return {
        "--x": "-422px",
        "--y": "-96px",
      };

    case "BM":
      return {
        "--x": "-422px",
        "--y": "-180px",
      };

    case "BN":
      return {
        "--x": "-422px",
        "--y": "-264px",
      };

    case "BO":
      return {
        "--x": "-422px",
        "--y": "-348px",
      };

    case "BR":
      return {
        "--x": "-2px",
        "--y": "-432px",
      };

    case "BS":
      return {
        "--x": "-86px",
        "--y": "-432px",
      };

    case "BT":
      return {
        "--x": "-170px",
        "--y": "-432px",
      };

    case "BW":
      return {
        "--x": "-254px",
        "--y": "-432px",
      };

    case "BY":
      return {
        "--x": "-338px",
        "--y": "-432px",
      };

    case "BZ":
      return {
        "--x": "-422px",
        "--y": "-432px",
      };

    case "CA":
      return {
        "--x": "-506px",
        "--y": "-12px",
      };

    case "CC":
      return {
        "--x": "-506px",
        "--y": "-96px",
      };

    case "CD":
      return {
        "--x": "-506px",
        "--y": "-180px",
      };

    case "CF":
      return {
        "--x": "-506px",
        "--y": "-264px",
      };

    case "CG":
      return {
        "--x": "-506px",
        "--y": "-348px",
      };

    case "CH":
      return {
        "--x": "-506px",
        "--y": "-432px",
      };

    case "CI":
      return {
        "--x": "-2px",
        "--y": "-516px",
      };

    case "CK":
      return {
        "--x": "-86px",
        "--y": "-516px",
      };

    case "CL":
      return {
        "--x": "-170px",
        "--y": "-516px",
      };

    case "CM":
      return {
        "--x": "-254px",
        "--y": "-516px",
      };

    case "CN":
      return {
        "--x": "-338px",
        "--y": "-516px",
      };

    case "CO":
      return {
        "--x": "-422px",
        "--y": "-516px",
      };

    case "CR":
      return {
        "--x": "-506px",
        "--y": "-516px",
      };

    case "CU":
      return {
        "--x": "-590px",
        "--y": "-12px",
      };

    case "CV":
      return {
        "--x": "-590px",
        "--y": "-96px",
      };

    case "CW":
      return {
        "--x": "-590px",
        "--y": "-180px",
      };

    case "CX":
      return {
        "--x": "-590px",
        "--y": "-264px",
      };

    case "CY":
      return {
        "--x": "-590px",
        "--y": "-348px",
      };

    case "CZ":
      return {
        "--x": "-590px",
        "--y": "-432px",
      };

    case "DE":
      return {
        "--x": "-590px",
        "--y": "-516px",
      };

    case "DJ":
      return {
        "--x": "-2px",
        "--y": "-600px",
      };

    case "DK":
      return {
        "--x": "-86px",
        "--y": "-600px",
      };

    case "DM":
      return {
        "--x": "-170px",
        "--y": "-600px",
      };

    case "DO":
      return {
        "--x": "-254px",
        "--y": "-600px",
      };

    case "DZ":
      return {
        "--x": "-338px",
        "--y": "-600px",
      };

    case "EC":
      return {
        "--x": "-422px",
        "--y": "-600px",
      };

    case "EE":
      return {
        "--x": "-506px",
        "--y": "-600px",
      };

    case "EG":
      return {
        "--x": "-590px",
        "--y": "-600px",
      };

    case "EH":
      return {
        "--x": "-674px",
        "--y": "-12px",
      };

    case "ER":
      return {
        "--x": "-674px",
        "--y": "-96px",
      };

    case "ES":
      return {
        "--x": "-674px",
        "--y": "-180px",
      };

    case "ET":
      return {
        "--x": "-674px",
        "--y": "-264px",
      };

    case "EU":
      return {
        "--x": "-674px",
        "--y": "-348px",
      };

    case "FI":
      return {
        "--x": "-674px",
        "--y": "-432px",
      };

    case "FJ":
      return {
        "--x": "-674px",
        "--y": "-516px",
      };

    case "FK":
      return {
        "--x": "-674px",
        "--y": "-600px",
      };

    case "FM":
      return {
        "--x": "-2px",
        "--y": "-684px",
      };

    case "FO":
      return {
        "--x": "-86px",
        "--y": "-684px",
      };

    case "FR":
    case "PM":
      return {
        "--x": "-170px",
        "--y": "-684px",
      };

    case "GA":
      return {
        "--x": "-254px",
        "--y": "-684px",
      };

    case "GB":
      return {
        "--x": "-338px",
        "--y": "-684px",
      };

    case "GD":
      return {
        "--x": "-422px",
        "--y": "-684px",
      };

    case "GE":
      return {
        "--x": "-506px",
        "--y": "-684px",
      };

    case "GF":
      return {
        "--x": "-590px",
        "--y": "-684px",
      };

    case "GG":
      return {
        "--x": "-674px",
        "--y": "-684px",
      };

    case "GH":
      return {
        "--x": "-758px",
        "--y": "-12px",
      };

    case "GI":
      return {
        "--x": "-758px",
        "--y": "-96px",
      };

    case "GL":
      return {
        "--x": "-758px",
        "--y": "-180px",
      };

    case "GM":
      return {
        "--x": "-758px",
        "--y": "-264px",
      };

    case "GN":
      return {
        "--x": "-758px",
        "--y": "-348px",
      };

    case "GP":
      return {
        "--x": "-758px",
        "--y": "-432px",
      };

    case "GQ":
      return {
        "--x": "-758px",
        "--y": "-516px",
      };

    case "GR":
      return {
        "--x": "-758px",
        "--y": "-600px",
      };

    case "GS":
      return {
        "--x": "-758px",
        "--y": "-684px",
      };

    case "GT":
      return {
        "--x": "-2px",
        "--y": "-768px",
      };

    case "GU":
      return {
        "--x": "-86px",
        "--y": "-768px",
      };

    case "GW":
      return {
        "--x": "-170px",
        "--y": "-768px",
      };

    case "GY":
      return {
        "--x": "-254px",
        "--y": "-768px",
      };

    case "HK":
      return {
        "--x": "-338px",
        "--y": "-768px",
      };

    case "HN":
      return {
        "--x": "-422px",
        "--y": "-768px",
      };

    case "HR":
      return {
        "--x": "-506px",
        "--y": "-768px",
      };

    case "HT":
      return {
        "--x": "-590px",
        "--y": "-768px",
      };

    case "HU":
      return {
        "--x": "-674px",
        "--y": "-768px",
      };

    case "IC":
      return {
        "--x": "-758px",
        "--y": "-768px",
      };

    case "ID":
      return {
        "--x": "-842px",
        "--y": "-12px",
      };

    case "IE":
      return {
        "--x": "-842px",
        "--y": "-96px",
      };

    case "IL":
      return {
        "--x": "-842px",
        "--y": "-180px",
      };

    case "IM":
      return {
        "--x": "-842px",
        "--y": "-264px",
      };

    case "IN":
      return {
        "--x": "-842px",
        "--y": "-348px",
      };

    case "IQ":
      return {
        "--x": "-842px",
        "--y": "-432px",
      };

    case "IR":
      return {
        "--x": "-842px",
        "--y": "-516px",
      };

    case "IS":
      return {
        "--x": "-842px",
        "--y": "-600px",
      };

    case "IT":
      return {
        "--x": "-842px",
        "--y": "-684px",
      };

    case "JE":
      return {
        "--x": "-842px",
        "--y": "-768px",
      };

    case "JM":
      return {
        "--x": "-2px",
        "--y": "-852px",
      };

    case "JO":
      return {
        "--x": "-86px",
        "--y": "-852px",
      };

    case "JP":
      return {
        "--x": "-170px",
        "--y": "-852px",
      };

    case "KE":
      return {
        "--x": "-254px",
        "--y": "-852px",
      };

    case "KG":
      return {
        "--x": "-338px",
        "--y": "-852px",
      };

    case "KH":
      return {
        "--x": "-422px",
        "--y": "-852px",
      };

    case "KI":
      return {
        "--x": "-506px",
        "--y": "-852px",
      };

    case "KM":
      return {
        "--x": "-590px",
        "--y": "-852px",
      };

    case "KN":
      return {
        "--x": "-674px",
        "--y": "-852px",
      };

    case "KP":
      return {
        "--x": "-758px",
        "--y": "-852px",
      };

    case "KR":
      return {
        "--x": "-842px",
        "--y": "-852px",
      };

    case "KV":
      return {
        "--x": "-926px",
        "--y": "-12px",
      };

    case "KW":
      return {
        "--x": "-926px",
        "--y": "-96px",
      };

    case "KY":
      return {
        "--x": "-926px",
        "--y": "-180px",
      };

    case "KZ":
      return {
        "--x": "-926px",
        "--y": "-264px",
      };

    case "LA":
      return {
        "--x": "-926px",
        "--y": "-348px",
      };

    case "LB":
      return {
        "--x": "-2px",
        "--y": "-12px",
      };

    case "LC":
      return {
        "--x": "-926px",
        "--y": "-516px",
      };

    case "LI":
      return {
        "--x": "-926px",
        "--y": "-600px",
      };

    case "LK":
      return {
        "--x": "-926px",
        "--y": "-684px",
      };

    case "LR":
      return {
        "--x": "-926px",
        "--y": "-768px",
      };

    case "LS":
      return {
        "--x": "-926px",
        "--y": "-852px",
      };

    case "LT":
      return {
        "--x": "-2px",
        "--y": "-936px",
      };

    case "LU":
      return {
        "--x": "-86px",
        "--y": "-936px",
      };

    case "LV":
      return {
        "--x": "-170px",
        "--y": "-936px",
      };

    case "LY":
      return {
        "--x": "-254px",
        "--y": "-936px",
      };

    case "MA":
      return {
        "--x": "-338px",
        "--y": "-936px",
      };

    case "MC":
      return {
        "--x": "-422px",
        "--y": "-936px",
      };

    case "MD":
      return {
        "--x": "-506px",
        "--y": "-936px",
      };

    case "ME":
      return {
        "--x": "-590px",
        "--y": "-936px",
      };

    case "MF":
      return {
        "--x": "-674px",
        "--y": "-936px",
      };

    case "MG":
      return {
        "--x": "-758px",
        "--y": "-936px",
      };

    case "MH":
      return {
        "--x": "-842px",
        "--y": "-936px",
      };

    case "MK":
      return {
        "--x": "-926px",
        "--y": "-936px",
      };

    case "ML":
      return {
        "--x": "-1010px",
        "--y": "-12px",
      };

    case "MM":
      return {
        "--x": "-1010px",
        "--y": "-96px",
      };

    case "MN":
      return {
        "--x": "-1010px",
        "--y": "-180px",
      };

    case "MO":
      return {
        "--x": "-1010px",
        "--y": "-264px",
      };

    case "MP":
      return {
        "--x": "-1010px",
        "--y": "-348px",
      };

    case "MQ":
      return {
        "--x": "-1010px",
        "--y": "-432px",
      };

    case "MR":
      return {
        "--x": "-1010px",
        "--y": "-516px",
      };

    case "MS":
      return {
        "--x": "-1010px",
        "--y": "-600px",
      };

    case "MT":
      return {
        "--x": "-1010px",
        "--y": "-684px",
      };

    case "MU":
      return {
        "--x": "-1010px",
        "--y": "-768px",
      };

    case "MV":
      return {
        "--x": "-1010px",
        "--y": "-852px",
      };

    case "MW":
      return {
        "--x": "-1010px",
        "--y": "-936px",
      };

    case "MX":
      return {
        "--x": "-2px",
        "--y": "-1020px",
      };

    case "MY":
      return {
        "--x": "-86px",
        "--y": "-1020px",
      };

    case "MZ":
      return {
        "--x": "-170px",
        "--y": "-1020px",
      };

    case "NA":
      return {
        "--x": "-254px",
        "--y": "-1020px",
      };

    case "NC":
      return {
        "--x": "-338px",
        "--y": "-1020px",
      };

    case "NE":
      return {
        "--x": "-422px",
        "--y": "-1020px",
      };

    case "NF":
      return {
        "--x": "-506px",
        "--y": "-1020px",
      };

    case "NG":
      return {
        "--x": "-590px",
        "--y": "-1020px",
      };

    case "NI":
      return {
        "--x": "-674px",
        "--y": "-1020px",
      };

    case "NL":
      return {
        "--x": "-758px",
        "--y": "-1020px",
      };

    case "BV":
    case "SJ":
    case "NO":
      return {
        "--x": "-842px",
        "--y": "-1020px",
      };

    case "NP":
      return {
        "--x": "-926px",
        "--y": "-1012px",
        border: 0,
        height: "calc(54px * var(--s))",
      };

    case "NR":
      return {
        "--x": "-1010px",
        "--y": "-1020px",
      };

    case "NU":
      return {
        "--x": "-1094px",
        "--y": "-12px",
      };

    case "NZ":
      return {
        "--x": "-1094px",
        "--y": "-96px",
      };

    case "OM":
      return {
        "--x": "-1094px",
        "--y": "-180px",
      };

    case "PA":
      return {
        "--x": "-1094px",
        "--y": "-264px",
      };

    case "PE":
      return {
        "--x": "-1094px",
        "--y": "-348px",
      };

    case "PF":
      return {
        "--x": "-1094px",
        "--y": "-432px",
      };

    case "PG":
      return {
        "--x": "-1094px",
        "--y": "-516px",
      };

    case "PH":
      return {
        "--x": "-1094px",
        "--y": "-600px",
      };

    case "PK":
      return {
        "--x": "-1094px",
        "--y": "-684px",
      };

    case "PL":
      return {
        "--x": "-1094px",
        "--y": "-768px",
      };

    case "PN":
      return {
        "--x": "-1094px",
        "--y": "-852px",
      };

    case "PR":
      return {
        "--x": "-1094px",
        "--y": "-936px",
      };

    case "PS":
      return {
        "--x": "-1094px",
        "--y": "-1020px",
      };

    case "PT":
      return {
        "--x": "-2px",
        "--y": "-1104px",
      };

    case "PW":
      return {
        "--x": "-86px",
        "--y": "-1104px",
      };

    case "PY":
      return {
        "--x": "-170px",
        "--y": "-1104px",
      };

    case "QA":
      return {
        "--x": "-254px",
        "--y": "-1104px",
      };

    case "RE":
      return {
        "--x": "-338px",
        "--y": "-1104px",
      };

    case "RO":
      return {
        "--x": "-422px",
        "--y": "-1104px",
      };

    case "RS":
      return {
        "--x": "-506px",
        "--y": "-1104px",
      };

    case "RU":
      return {
        "--x": "-590px",
        "--y": "-1104px",
      };

    case "RW":
      return {
        "--x": "-674px",
        "--y": "-1104px",
      };

    case "SA":
      return {
        "--x": "-758px",
        "--y": "-1104px",
      };

    case "SB":
      return {
        "--x": "-842px",
        "--y": "-1104px",
      };

    case "SC":
      return {
        "--x": "-926px",
        "--y": "-1104px",
      };

    case "SD":
      return {
        "--x": "-1010px",
        "--y": "-1104px",
      };

    case "SE":
      return {
        "--x": "-1094px",
        "--y": "-1104px",
      };

    case "SG":
      return {
        "--x": "-1178px",
        "--y": "-12px",
      };

    case "SH":
      return {
        "--x": "-1178px",
        "--y": "-96px",
      };

    case "SI":
      return {
        "--x": "-1178px",
        "--y": "-180px",
      };

    case "SK":
      return {
        "--x": "-1178px",
        "--y": "-264px",
      };

    case "SL":
      return {
        "--x": "-1178px",
        "--y": "-348px",
      };

    case "SM":
      return {
        "--x": "-1178px",
        "--y": "-432px",
      };

    case "SN":
      return {
        "--x": "-1178px",
        "--y": "-516px",
      };

    case "SO":
      return {
        "--x": "-1178px",
        "--y": "-600px",
      };

    case "SR":
      return {
        "--x": "-1178px",
        "--y": "-684px",
      };

    case "SS":
      return {
        "--x": "-1178px",
        "--y": "-768px",
      };

    case "ST":
      return {
        "--x": "-1178px",
        "--y": "-852px",
      };

    case "SV":
      return {
        "--x": "-1178px",
        "--y": "-936px",
      };

    case "SY":
      return {
        "--x": "-1178px",
        "--y": "-1020px",
      };

    case "SZ":
      return {
        "--x": "-1178px",
        "--y": "-1104px",
      };

    case "TC":
      return {
        "--x": "-2px",
        "--y": "-1188px",
      };

    case "TD":
      return {
        "--x": "-86px",
        "--y": "-1188px",
      };

    case "TF":
      return {
        "--x": "-170px",
        "--y": "-1188px",
      };

    case "TG":
      return {
        "--x": "-254px",
        "--y": "-1188px",
      };

    case "TH":
      return {
        "--x": "-338px",
        "--y": "-1188px",
      };

    case "TJ":
      return {
        "--x": "-422px",
        "--y": "-1188px",
      };

    case "TK":
      return {
        "--x": "-506px",
        "--y": "-1188px",
      };

    case "TL":
      return {
        "--x": "-590px",
        "--y": "-1188px",
      };

    case "TM":
      return {
        "--x": "-674px",
        "--y": "-1188px",
      };

    case "TN":
      return {
        "--x": "-758px",
        "--y": "-1188px",
      };

    case "TO":
      return {
        "--x": "-842px",
        "--y": "-1188px",
      };

    case "TR":
      return {
        "--x": "-926px",
        "--y": "-1188px",
      };

    case "TT":
      return {
        "--x": "-1010px",
        "--y": "-1188px",
      };

    case "TV":
      return {
        "--x": "-1094px",
        "--y": "-1188px",
      };

    case "TW":
      return {
        "--x": "-1178px",
        "--y": "-1188px",
      };

    case "TZ":
      return {
        "--x": "-1262px",
        "--y": "-12px",
      };

    case "UA":
      return {
        "--x": "-1262px",
        "--y": "-96px",
      };

    case "UG":
      return {
        "--x": "-1262px",
        "--y": "-180px",
      };

    case "US":
      return {
        "--x": "-1262px",
        "--y": "-264px",
      };

    case "UY":
      return {
        "--x": "-1262px",
        "--y": "-348px",
      };

    case "UZ":
      return {
        "--x": "-1262px",
        "--y": "-432px",
      };

    case "VA":
      return {
        "--x": "-1262px",
        "--y": "-516px",
      };

    case "VC":
      return {
        "--x": "-1262px",
        "--y": "-600px",
      };

    case "VE":
      return {
        "--x": "-1262px",
        "--y": "-684px",
      };

    case "VG":
      return {
        "--x": "-1262px",
        "--y": "-768px",
      };

    case "VI":
      return {
        "--x": "-1262px",
        "--y": "-852px",
      };

    case "VN":
      return {
        "--x": "-1262px",
        "--y": "-936px",
      };

    case "VU":
      return {
        "--x": "-1262px",
        "--y": "-1020px",
      };

    case "WF":
      return {
        "--x": "-1262px",
        "--y": "-1104px",
      };

    case "WS":
      return {
        "--x": "-1262px",
        "--y": "-1188px",
      };

    case "XB":
      return {
        "--x": "-2px",
        "--y": "-1272px",
      };

    case "XC":
      return {
        "--x": "-86px",
        "--y": "-1272px",
      };

    case "XE":
      return {
        "--x": "-170px",
        "--y": "-1272px",
      };

    case "XM":
      return {
        "--x": "-254px",
        "--y": "-1272px",
      };

    case "XN":
      return {
        "--x": "-338px",
        "--y": "-1272px",
      };

    case "XS":
      return {
        "--x": "-1010px",
        "--y": "-1272px",
      };

    case "XY":
      return {
        "--x": "-422px",
        "--y": "-1272px",
      };

    case "YE":
      return {
        "--x": "-506px",
        "--y": "-1272px",
      };

    case "YT":
      return {
        "--x": "-590px",
        "--y": "-1272px",
      };

    case "ZA":
      return {
        "--x": "-674px",
        "--y": "-1272px",
      };

    case "ZM":
      return {
        "--x": "-758px",
        "--y": "-1272px",
      };

    case "ZW":
      return {
        "--x": "-842px",
        "--y": "-1272px",
      };

    default:
      return {
        "--x": "-924px",
        "--y": "-1272px",
        backgroundColor: "#d1d6da",
      };
  }
};

export default getFlagForCountry;
