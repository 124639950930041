// 9fbef606107a605d69c0edbcd8029e5d

export default function localeUses24HourTime(langCode) {
  return (
    new Intl.DateTimeFormat(langCode, {
      hour: "numeric",
    })
      .formatToParts(new Date(2022, 0, 1, 13))
      .find((part) => part.type === "hour").value.length === 2
  );
}
