// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * OfferCard
 *
 */

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  keyCodes,
  P2PLinkTypes,
  UTF_EVENT_CONTENT_NAMES,
  UTF_EVENT_TYPES,
  UTF_EVENT_INTERACTIONS,
  UTF_EVENT_CONTENT_POSITION,
  UTF_EVENT_CONVERSION_NAMES,
  UTF_EVENT_CONVERSION_TYPES,
  UTF_EVENT_CONVERSION_DETAIL,
} from "root/libs/constants/src";
import { SEGMENT_TYPES } from "one-time-shipment/src/containers/SpotShipmentPage/constants";
import { TYPES_OF_OFFERS } from "one-time-shipment/src/containers/GetQuote/constants";
import {
  IntlPropType,
  trackContentInteraction,
  getUtfAddOnString,
} from "root/libs/core-libs/src";
import { CountrySettings, CanaryVersions } from "root/libs/ui-containers/src";
import { BUTTON_VARIANTS } from "@dhl-official/react-ui-library";
import QuoteValidity from "one-time-shipment/src/components/Offers/QuoteValidity";
import { selectTrackingKey } from "one-time-shipment/src/containers/Tracking/selectors";
import OfferDeliveryInfo from "one-time-shipment/src/components/Offers/OfferDeliveryInfo";
import AdditionalInformation from "one-time-shipment/src/components/Offers/AdditionalInformation";
import OfferPriceAndPaymentInfo from "one-time-shipment/src/components/Offers/OfferPriceAndPaymentInfo";
import OfferAddOns from "one-time-shipment/src/components/Offers/OfferAddOns";
import OfferProperties from "one-time-shipment/src/components/Offers/OfferProperties";
import {
  OfferCardContent,
  OfferCardWrapper,
  DeliveryInfoContent,
  PaymentInfoContent,
  OfferCardDetailsContent,
  StyledAccordion,
  IconChevronUp,
  IconChevronDown,
  OfferCardPriceDiscount,
} from "./styled";

const { withCountry } = CountrySettings;

const {
  constants: { TYPES_OF_CANARY_VERSIONS },
} = CanaryVersions;

const COPY_ID_PREFIX = "OfferCardHorizontal";

export default class OfferCard extends React.PureComponent {
  static metadataPropTypes = {
    id: PropTypes.string,
    estimatedDeliveryTimeDisplayType: PropTypes.oneOf([
      "EXACT_TIME",
      "END_OF_DAY",
      "LATEST_END_OF_DAY",
    ]),
    estimatedDeliveryTime: PropTypes.instanceOf(Date),
  };

  static pricePropTypes = {
    price: PropTypes.shape({
      /** A number that contains the price */
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      /** A number that contains the price for sorting */
      determinedAmount: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      /** A valid currency code */
      currencyCode: PropTypes.string,
      /** If the price contains VAT. */
      tax: PropTypes.oneOf(["WITH", "WITHOUT", "OPTIONAL"]),
    }),
    priceWithoutDiscount: PropTypes.shape({
      /** A number that contains the price */
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      /** A number that contains the price for sorting */
      determinedAmount: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      /** A valid currency code */
      currencyCode: PropTypes.string,
      /** If the price contains VAT. */
      tax: PropTypes.oneOf(["WITH", "WITHOUT", "OPTIONAL"]),
    }),
  };

  static offersPropTypes = {
    /** business unit of the offer */
    businessUnit: PropTypes.string,
    /** id of the offer */
    key: PropTypes.string,
    id: PropTypes.string,
    /** An array that contains the accepted payment methods */
    acceptedPaymentMethods: PropTypes.arrayOf(PropTypes.string),
    estimatedDeliveryTime: PropTypes.instanceOf(Date),
    /** Estimated delivery information if no concrete time could be identified */
    estimatedDeliveryTransit: PropTypes.shape({
      minDays: PropTypes.number,
      maxDays: PropTypes.number.isRequired,
    }),
    cutOffDateTime: PropTypes.instanceOf(Date),
    /** Add on of the offer */
    addOns: PropTypes.array,
    /** Properties of the offer. From here we get the promoted line */
    properties: PropTypes.array,
    /** Additional information of the offer */
    additionalInformation: PropTypes.array,
    /** Link for Book now button */
    pathToPurchaseLink: PropTypes.string,
    /** Link type for book now button */
    pathToPurchaseLinkType: PropTypes.oneOf(Object.values(P2PLinkTypes)),
    /** Information if the delivery time is estimated or committed */
    deliveryType: PropTypes.oneOf(["COMMITTED", "ESTIMATED"]),
    /** If a special offer should be displayed. */
    specialOffers: PropTypes.string,
    /** Price of the offer */
    ...this.pricePropTypes,
    /** Closest Mapped Location, applies to DGF only  */
    locationMappingInformation: PropTypes.shape({
      /** Origin location */
      originLocation: PropTypes.shape({
        /** Origin country code */
        countryCode: PropTypes.string,
        /** Origin location (mapped or not) */
        location: PropTypes.string,
        /** If the location has changed from what the user typed */
        changed: PropTypes.bool,
      }),
      /** Destination location */
      destinationLocation: PropTypes.shape({
        /** Destination country code */
        countryCode: PropTypes.string,
        /** Destination location (mapped or not) */
        location: PropTypes.string,
        /** If the location has changed from what the user typed */
        changed: PropTypes.bool,
      }),
    }),
    information: PropTypes.array,
  };

  static propTypes = {
    className: PropTypes.string,
    reference: PropTypes.object,
    /** function to add values to the digitalLayer for tracking purposes.
     * Receives an object, the key is the property that will receive the data in the digitalLayer
     * and the value is the data that it needs */
    trackEvent: PropTypes.func,
    /** Call back for for when a card is clicked */
    onCardClick: PropTypes.func,
    /** Internationalization library */
    intl: IntlPropType.isRequired,
    /** An object that contains all the information required of an offer */
    offer: PropTypes.shape({
      /** Defines if the Offer Card is of type AGGREGATED or SIMPLE */
      type: PropTypes.oneOf([
        TYPES_OF_OFFERS.AGGREGATED,
        TYPES_OF_OFFERS.SIMPLE,
      ]),
      metadata: PropTypes.shape({
        availableDeliveryTimes: PropTypes.arrayOf(
          PropTypes.shape({
            ...this.metadataPropTypes,
          })
        ),
        fastestOffer: PropTypes.shape({
          ...this.metadataPropTypes,
        }),
        cheapestOffer: PropTypes.shape({
          id: PropTypes.string,
          /** Price of the offer option */
          ...this.pricePropTypes,
        }),
      }),
      offerOptions: PropTypes.arrayOf(
        PropTypes.shape({
          ...this.offersPropTypes,
        })
      ),
      ...this.offersPropTypes,
    }).isRequired,
    /** Function to hand over for controlling the display of the Mobile Express PTP modal */
    toggleModalExpress: PropTypes.func,
    /** Function to hand over for controlling the display of the Booking not allowed PTP modal */
    toggleBookingNotAllowedModal: PropTypes.func,
    /** Function to hand over for controlling the display of the Freight P2P modal */
    toggleFreightP2PModal: PropTypes.func,
    /** Will set to true, the property will show the business shipment features */
    isBusiness: PropTypes.bool.isRequired,
    index: PropTypes.number,
    indexLength: PropTypes.number,
    showCurrencyIsoCodeForPrices: PropTypes.bool,
    canaryEnabled: PropTypes.bool,
    canaryVersion: PropTypes.string,
    abTestRunning: PropTypes.bool,
    abTestVersion: PropTypes.string,
  };

  static defaultProps = {
    className: "",
    onCardClick: () => {},
    trackEvent: () => {},
    reference: { current: null },
    toggleModalExpress: () => {},
    toggleBookingNotAllowedModal: () => {},
    toggleFreightP2PModal: () => {},
    index: null,
    indexLength: null,
    showCurrencyIsoCodeForPrices: false,
  };

  constructor(props) {
    super(props);
    this.OfferDeliveryInfoWithCountry = withCountry(OfferDeliveryInfo);
    this.OfferPriceAndPaymentInfoWithCountry = withCountry(
      OfferPriceAndPaymentInfo
    );
    this.OfferProperties = withCountry(OfferProperties);
    this.OfferAddOnsWithCountry = withCountry(OfferAddOns);
    this.trackingKeySelector = selectTrackingKey;
    this.idExploredOfferTrackingKeyPath = this.trackingKeySelector(
      "spotShipment.interactions.offer.idExploredOffer"
    );
    /* eslint-disable react/prop-types */
    const initialSelectedOfferOptionId =
      props?.offer?.metadata?.availableDeliveryTimes[0]?.id || null;
    this.state = {
      // Accordion Open - SIMPLE offers, need to refactor for WCC
      isAccordionOpen: false,
      // Selected OfferOption - AGGREGATED offers
      selectedOfferOptionId: initialSelectedOfferOptionId,
    };
  }

  onCardClick = () => {
    const { offer, onCardClick } = this.props;

    onCardClick({ ...offer });
  };

  onKeyPress = (event) => {
    const { keyCode } = event;
    if (keyCode === keyCodes.RETURN || keyCode === keyCodes.SPACE) {
      event.preventDefault();
      this.onCardClick();
    }
  };

  handleDeliveryTimeChange = (offerId) => {
    this.setState({ selectedOfferOptionId: offerId });
  };

  createAriaDescribedByIdCollection = () => {
    const { offer } = this.props;
    const { offerOptions = [] } = offer;
    const { selectedOfferOptionId } = this.state;

    const selectedOffer = offerOptions.find(
      (offerOption) => offerOption.id === selectedOfferOptionId
    );
    const displayOffer =
      offer.type === TYPES_OF_OFFERS.AGGREGATED ? selectedOffer : offer;

    return `${displayOffer.key}-firstline ${displayOffer.key}-headline ${displayOffer.key}-price ${displayOffer.key}-businessVat ${displayOffer.key}-payment ${displayOffer.key}-starBehindPrice ${displayOffer.key}-promoted-mobile ${displayOffer.key}-promoted-desktop ${displayOffer.key}-moreInfo`;
  };

  onClickOffersDetailsAccordion = () => {
    const { trackEvent, isBusiness, offer } = this.props;
    const { offerOptions = [] } = offer;
    const { selectedOfferOptionId, isAccordionOpen } = this.state;

    const selectedOffer = offerOptions.find(
      (offerOption) => offerOption.id === selectedOfferOptionId
    );
    const displayOffer =
      offer.type === TYPES_OF_OFFERS.AGGREGATED ? selectedOffer : offer;

    const key = displayOffer?.key;

    trackEvent({ idExploredOffer: key });

    if (!isAccordionOpen) {
      trackContentInteraction({
        name: UTF_EVENT_CONTENT_NAMES.EXPAND_CARD,
        type: UTF_EVENT_TYPES.BUTTON,
        interaction: UTF_EVENT_INTERACTIONS.CLICK,
        position: UTF_EVENT_CONTENT_POSITION.PRODUCT_CARD,
        context: isBusiness ? SEGMENT_TYPES.business : SEGMENT_TYPES.private,
        attributes: { key },
      });
    }

    this.setState({ isAccordionOpen: !isAccordionOpen });
  };

  showAdditionalInformation = () => {
    const { offer } = this.props;
    const { offerOptions = [] } = offer;
    const { selectedOfferOptionId } = this.state;

    const selectedOffer = offerOptions.find(
      (offerOption) => offerOption.id === selectedOfferOptionId
    );
    const displayOffer =
      offer.type === TYPES_OF_OFFERS.AGGREGATED ? selectedOffer : offer;

    return (
      Array.isArray(displayOffer.additionalInformation) &&
      displayOffer.additionalInformation.length > 0
    );
  };

  showECSInfoMessage = () => {
    const { offer } = this.props;
    const { offerOptions = [] } = offer;
    const { selectedOfferOptionId } = this.state;

    const selectedOffer = offerOptions.find(
      (offerOption) => offerOption.id === selectedOfferOptionId
    );
    const displayOffer =
      offer.type === TYPES_OF_OFFERS.AGGREGATED ? selectedOffer : offer;

    const { information } = displayOffer;

    return (
      information &&
      information.find((element) => element.category === "INFO") &&
      information.find(
        (element) => element.header !== "PALLET_REQUIRED_INFORMATION"
      )
    );
  };

  calculateDiscountPercentage = (price, priceWithoutDiscount) => {
    if (!priceWithoutDiscount || !priceWithoutDiscount.amount) {
      return null; // Indicate that there is no discount available
    }

    if (!price || !price.amount) {
      return null; // Handle cases where the price is invalid
    }

    const discountAmount = priceWithoutDiscount.amount - price.amount;
    if (discountAmount <= 0) {
      return 0; // No discount if the discount amount is zero or negative
    }

    const discountPercentage =
      (discountAmount / priceWithoutDiscount.amount) * 100;
    return discountPercentage;
  };

  formatDiscountPercentage = (discountPercentage) => {
    if (discountPercentage === null || discountPercentage === undefined) {
      return ""; // Handle cases where no discount percentage is available
    }

    let numberValue;

    if (typeof discountPercentage === "number") {
      numberValue = discountPercentage;
    } else if (typeof discountPercentage === "string") {
      numberValue = parseFloat(discountPercentage);
      if (Number.isNaN(numberValue)) {
        return "";
      }
    } else {
      return "";
    }

    const roundedValue = Math.floor(numberValue);
    return `${roundedValue}%`;
  };

  render() {
    const {
      className,
      intl,
      offer,
      reference,
      trackEvent,
      toggleModalExpress,
      toggleBookingNotAllowedModal,
      toggleFreightP2PModal,
      isBusiness,
      index,
      indexLength,
      showCurrencyIsoCodeForPrices,
      canaryVersion,
      canaryEnabled,
      abTestRunning,
      abTestVersion,
    } = this.props;

    const { offerOptions = [], metadata: offerMetadata = {} } = offer;

    const { selectedOfferOptionId } = this.state;

    const selectedOffer = offerOptions.find(
      (offerOption) => offerOption.id === selectedOfferOptionId
    );

    /* eslint-disable react/prop-types */
    const accordionId =
      offer.type === TYPES_OF_OFFERS.AGGREGATED
        ? `offerCard-${selectedOffer.key}`
        : `offerCard-${offer.key}`;

    const additionalInfoECSMessage = this.showECSInfoMessage();
    const displayOffer =
      offer.type === TYPES_OF_OFFERS.AGGREGATED ? selectedOffer : offer;
    const shouldRenderAccordion =
      (Array.isArray(displayOffer.additionalInformation) &&
        displayOffer.additionalInformation.length > 0) ||
      (Array.isArray(displayOffer.properties) &&
        displayOffer.properties.length > 0) ||
      (Array.isArray(displayOffer.addOns) && displayOffer.addOns.length > 0) ||
      !!additionalInfoECSMessage;
    const shouldRenderQuoteValidity = !!displayOffer.price.amount;
    const ariaDescribedByIdCollection = this.createAriaDescribedByIdCollection();
    const offerIndex = index + 1;
    let finalAdditionalInfoList =
      Array.isArray(displayOffer.additionalInformation) &&
      displayOffer.additionalInformation.length > 0
        ? displayOffer.additionalInformation
        : [];

    if (additionalInfoECSMessage) {
      finalAdditionalInfoList = [
        ...finalAdditionalInfoList,
        ...[{ key: additionalInfoECSMessage.message }],
      ];
    }

    const shippingModesString = getUtfAddOnString(displayOffer.addOns);

    const wccScreenReaderLabel =
      offer.type === TYPES_OF_OFFERS.AGGREGATED
        ? ` ${intl.formatMessage({
            id: `${COPY_ID_PREFIX}.aggregatedOfferInfo`,
          })}`
        : "";

    const discountPercentage = this.calculateDiscountPercentage(
      displayOffer?.price,
      displayOffer?.priceWithoutDiscount
    );
    const priceDiscount = this.formatDiscountPercentage(discountPercentage);

    const customClassName = `${className} ${
      canaryEnabled && canaryVersion === TYPES_OF_CANARY_VERSIONS.strikethrough
        ? "has-discount"
        : ""
    }`;

    return (
      <OfferCardWrapper className={customClassName}>
        {shouldRenderAccordion ? (
          <Fragment>
            {canaryEnabled && (
              <CanaryVersions.Component
                version={canaryVersion}
                componentToRender={() =>
                  priceDiscount && (
                    <OfferCardPriceDiscount>
                      {intl.formatMessage(
                        { id: `${COPY_ID_PREFIX}.discount` },
                        {
                          priceDiscount,
                        }
                      )}
                    </OfferCardPriceDiscount>
                  )
                }
                versionsToRender={TYPES_OF_CANARY_VERSIONS.strikethrough}
                fallback={() => {}}
              />
            )}
            <OfferCardContent
              data-testid="h-offer-card"
              data-testvalue={displayOffer.key}
              aria-describedby={ariaDescribedByIdCollection}
              aria-label={`${intl.formatMessage(
                { id: `${COPY_ID_PREFIX}.screenReaderLabel_offersAmount` },
                {
                  currentOfferIndex: offerIndex,
                  totalOffersAmount: indexLength,
                }
              )}${wccScreenReaderLabel}`}
              tabIndex={0}
            >
              <DeliveryInfoContent>
                <this.OfferDeliveryInfoWithCountry
                  abTestRunning={abTestRunning}
                  abTestVersion={abTestVersion}
                  canaryEnabled={canaryEnabled}
                  canaryVersion={canaryVersion}
                  intl={intl}
                  offer={displayOffer}
                  offerType={offer.type}
                  offerIndex={offerIndex}
                  offerMetadata={offerMetadata}
                  selectedOfferOptionId={selectedOfferOptionId}
                  onDeliveryTimeChange={this.handleDeliveryTimeChange}
                  iNeedTheseProps={[
                    "nativeLanguageCode",
                    "is12HourTimeFormat",
                    "nativeDateTimeFormat",
                  ]}
                />
              </DeliveryInfoContent>
              <PaymentInfoContent>
                <this.OfferPriceAndPaymentInfoWithCountry
                  intl={intl}
                  offer={displayOffer}
                  trackEvent={trackEvent}
                  toggleModalExpress={toggleModalExpress}
                  toggleBookingNotAllowedModal={toggleBookingNotAllowedModal}
                  toggleFreightP2PModal={toggleFreightP2PModal}
                  isBusiness={isBusiness}
                  showCurrencyIsoCodeForPrices={showCurrencyIsoCodeForPrices}
                  iNeedTheseProps={[
                    "nativeLanguageCode",
                    "formatCurrencyWithNativeLocale",
                    "isExpressAllowedOnMobile",
                  ]}
                  utfDataObject={{
                    conversion: {
                      name: UTF_EVENT_CONVERSION_NAMES.BOOKING_INTENT,
                      type: UTF_EVENT_CONVERSION_TYPES.HANDOVER,
                      detail: UTF_EVENT_CONVERSION_DETAIL.LEGACY,
                    },
                    products: [
                      {
                        id: displayOffer.id,
                        key: displayOffer.key,
                        businessUnit: displayOffer.businessUnit,
                        price: displayOffer.price.amount,
                        currency: displayOffer.price.currencyCode,
                        shippingModes: shippingModesString,
                      },
                    ],
                    content: {
                      name: UTF_EVENT_CONTENT_NAMES.CONTINUE_TO_BOOKING,
                      type: UTF_EVENT_TYPES.BUTTON,
                      interaction: UTF_EVENT_INTERACTIONS.CLICK,
                      position: UTF_EVENT_CONTENT_POSITION.PRODUCT_CARD,
                      context: isBusiness
                        ? SEGMENT_TYPES.business
                        : SEGMENT_TYPES.private,
                    },
                  }}
                  canaryEnabled={canaryEnabled}
                  canaryVersion={canaryVersion}
                />
              </PaymentInfoContent>
            </OfferCardContent>
            <span
              id={`${displayOffer.key}-moreInfo`}
              className="visually-hidden"
            >
              {intl.formatMessage({
                id: `${COPY_ID_PREFIX}.screenReaderLabel_moreInformation`,
              })}
            </span>
            <StyledAccordion
              useAsCollapsible
              showExpandCollapseButton
              expandCollapseButtonVariation="bubbleWithText"
              expandCollapseButtonTestId={`expand-collapse-${displayOffer.key}`}
              expandButtonLabel={intl.formatMessage({
                id: `${COPY_ID_PREFIX}.exploreOffer`,
              })}
              collapseButtonLabel={intl.formatMessage({
                id: `${COPY_ID_PREFIX}.closeOffer`,
              })}
              expandButtonAriaLabel={intl.formatMessage({
                id: `${COPY_ID_PREFIX}.screenReaderLabel_ariaExploreOffer`,
              })}
              collapseButtonAriaLabel={intl.formatMessage({
                id: `${COPY_ID_PREFIX}.screenReaderLabel_ariaCloseOffer`,
              })}
              buttonVariant={BUTTON_VARIANTS.TEXT}
              expandButtonAriaControls={`${accordionId}-content`}
              expandButtonDataTracking={this.idExploredOfferTrackingKeyPath}
              onTitleClick={this.onClickOffersDetailsAccordion}
              collapseIcon={<IconChevronUp />}
              expandIcon={<IconChevronDown />}
            >
              <StyledAccordion.Title
                id={`${accordionId}-title`}
                ariaControls={`${accordionId}-content`}
                onKeyDown={this.onKeyPress}
                ref={reference}
                dataTracking={this.idExploredOfferTrackingKeyPath}
                trackingKeySelector={this.trackingKeySelector}
              />
              <StyledAccordion.Content
                id={`${accordionId}-content`}
                labeledBy={`${accordionId}-title`}
                className="offerCollapsibleContent"
              >
                <OfferCardDetailsContent
                  shouldRenderQuoteValidity={shouldRenderQuoteValidity}
                >
                  <div>
                    <this.OfferProperties
                      properties={displayOffer.properties}
                      offerId={displayOffer.key}
                      estimatedDeliveryTime={displayOffer.estimatedDeliveryTime}
                      intl={intl}
                      iNeedTheseProps={["is12HourTimeFormat"]}
                    />
                    {(this.showAdditionalInformation() ||
                      additionalInfoECSMessage) && (
                      <AdditionalInformation
                        intl={intl}
                        additionalInformationList={finalAdditionalInfoList}
                      />
                    )}
                  </div>
                  <this.OfferAddOnsWithCountry
                    trackEvent={trackEvent}
                    addOns={displayOffer.addOns}
                    offerKey={displayOffer.key}
                    intl={intl}
                    showCurrencyIsoCodeForPrices={showCurrencyIsoCodeForPrices}
                    iNeedTheseProps={[
                      "nativeLanguageCode",
                      "formatCurrencyWithNativeLocale",
                    ]}
                  />
                </OfferCardDetailsContent>
                {shouldRenderQuoteValidity && (
                  <QuoteValidity
                    canaryEnabled={canaryEnabled}
                    canaryVersion={canaryVersion}
                    businessUnit={displayOffer.businessUnit}
                  />
                )}
              </StyledAccordion.Content>
            </StyledAccordion>
          </Fragment>
        ) : (
          <OfferCardContent
            data-tracking={this.idExploredOfferTrackingKeyPath}
            tabIndex="0"
            data-testid="h-offer-card"
            data-testvalue={displayOffer.key}
            aria-describedby={ariaDescribedByIdCollection}
            aria-label={intl.formatMessage(
              { id: `${COPY_ID_PREFIX}.screenReaderLabel_offersAmount` },
              {
                currentOfferIndex: index + 1,
                totalOffersAmount: indexLength,
              }
            )}
          >
            <DeliveryInfoContent>
              <this.OfferDeliveryInfoWithCountry
                canaryEnabled={canaryEnabled}
                canaryVersion={canaryVersion}
                intl={intl}
                offer={displayOffer}
                offerType={offer.type}
                offerIndex={offerIndex}
                offerMetadata={offerMetadata}
                selectedOfferOptionId={selectedOfferOptionId}
                onDeliveryTimeChange={this.handleDeliveryTimeChange}
                iNeedTheseProps={[
                  "nativeLanguageCode",
                  "is12HourTimeFormat",
                  "nativeDateTimeFormat",
                ]}
              />
            </DeliveryInfoContent>
            <PaymentInfoContent>
              <this.OfferPriceAndPaymentInfoWithCountry
                intl={intl}
                offer={displayOffer}
                trackEvent={trackEvent}
                toggleModalExpress={toggleModalExpress}
                toggleBookingNotAllowedModal={toggleBookingNotAllowedModal}
                toggleFreightP2PModal={toggleFreightP2PModal}
                isBusiness={isBusiness}
                canaryEnabled={canaryEnabled}
                canaryVersion={canaryVersion}
                iNeedTheseProps={[
                  "nativeLanguageCode",
                  "formatCurrencyWithNativeLocale",
                  "isExpressAllowedOnMobile",
                ]}
              />
            </PaymentInfoContent>
          </OfferCardContent>
        )}
      </OfferCardWrapper>
    );
  }
}
