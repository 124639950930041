// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for ModalFreightP2P
 *
 */

import styled from "styled-components";
import { Text } from "@dhl-official/react-ui-library";
import { FormField } from "root/libs/ui-components/src";
import { breakpoints } from "root/libs/ui-styleguide/src";
import { key } from "styled-theme";

const formFieldBottomSpacing = "10px";

export const OfferSummary = styled.section`
  border-top: 1px solid ${key("colors.globalBorderColor")};
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 10px 0 10px;
  box-sizing: border-box;

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    padding: 30px 0 20px;
  }
`;
export const PriceArea = styled.div`
  display: flex;
  margin-top: 5px;
  margin-bottom: 30px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: 0;
    padding-right: 10px;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    width: 18%;
  }

  .price {
    font-size: ${key("fonts.mobile.h2.size")};
    line-height: ${key("fonts.mobile.h2.lineHeight")};
    font-weight: 900;

    @media screen and (min-width: ${breakpoints.tablet}) {
      font-size: ${key("fonts.tablet.h5.size")};
      line-height: ${key("fonts.tablet.h5.lineHeight")};
    }
  }

  dd,
  dl {
    margin: 0;
  }

  dt {
    display: inline-block;
    padding-right: 10px;

    @media screen and (min-width: ${breakpoints.tablet}) {
      font-size: ${key("fonts.tablet.label.size")};
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      display: block;
    }
  }

  dd {
    display: inline-block;
  }
`;
export const QuotationOfferArea = styled.div`
  display: flex;
  margin-bottom: 10px;

  span {
    margin-right: 16px;

    svg {
      position: absolute;
      align-self: center;
    }

    @media screen and (min-width: ${breakpoints.tablet}) {
      margin-right: 10px;
    }
  }

  .quotationId {
    font-size: ${key("fonts.mobile.h4.size")};
    line-height: ${key("fonts.mobile.h4.lineHeight")};
    font-weight: 900;

    @media screen and (min-width: ${breakpoints.tablet}) {
      font-size: ${key("fonts.tablet.h5.size")};
      line-height: ${key("fonts.tablet.h5.lineHeight")};
    }
  }

  dt::after {
    content: ": ";
  }

  dl,
  dd {
    margin: 0;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: calc(100% / 1.5);
    padding-right: 20px;
    align-self: flex-start;
  }

  &.offerSummary {
    flex-direction: column;

    @media screen and (min-width: ${breakpoints.tablet}) {
      order: 3;
      padding-right: 0;
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      width: 50%;
    }

    h5 {
      margin-bottom: 4px;
    }

    dt,
    dd {
      display: inline;
      font-size: ${key("fonts.mobile.label.size")};
      line-height: ${key("fonts.mobile.label.lineHeight")};
    }

    dt {
      padding-right: 4px;
    }
  }
`;
export const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 620px;
  margin: 0 auto;
`;
export const StyledFieldset = styled.fieldset`
  width: 100%;
  box-sizing: border-box;

  .textNote {
    text-align: center;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: 0;
  }
`;
export const Wrapper = styled.div`
  width: 100%;

  & > p {
    padding: 0 20px;
    padding-bottom: 10px;
  }

  .button-wrapper {
    padding: 0 10px;
    margin: 20px auto;
  }
`;
export const FieldsetLegend = styled.legend`
  width: 100%;
  max-width: 460px;
  margin: 0 auto;
  margin-bottom: 30px;

  h4 {
    margin-bottom: 0;
  }
`;

export const FormFieldWrapper = styled(FormField)`
  margin-bottom: ${formFieldBottomSpacing};
`;

export const StyledContainer = styled.div`
  display: block;
  width: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: 50%;
    padding-left: 13px;
    padding-right: 0;
    box-sizing: border-box;

    &:first-of-type {
      padding-right: 13px;
      padding-left: 0;
    }
  }
`;
export const TermsAndConditionsText = styled(Text)`
  text-align: center;
  max-width: 620px;
  margin: 0 auto;
`;
