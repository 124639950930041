// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * ModalExpress
 *
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Text, TEXT_SIZES } from "@dhl-official/react-ui-library";
import { injectIntl } from "react-intl";
import { trackConversionInteraction } from "root/libs/core-libs/src";
import styled from "styled-components";
import { Icon, Link, ContentModal } from "root/libs/ui-components/src";
import { colors } from "root/libs/ui-styleguide/src";
import phoneIcon from "icons/cx/phone.svg";

const Content = styled.div`
  width: 100%;
  align-items: center;
  height: 100%;
`;

const CopyContainer = styled.div`
  text-align: center;
  margin: 20px auto 0;
  max-width: 300px;
`;

const ButtonContainer = styled.div`
  margin-top: 35px;
`;

const SecondaryButtonContainer = styled.div`
  margin-top: 25px;
`;

const IconContainer = styled.span`
  position: relative;
  top: -1px;
  ${(props) => (props.insertBefore ? "right: 10px" : "left: 10px")};
`;

const CopyText = styled(Text)`
  margin-bottom: ${(props) => (props.doubleBottomSpacing ? "20px" : "10px")};
  text-align: center;
`;

const COPY_ID_PREFIX = "ModalExpress";

const ModalExpress = (props) => {
  const { intl, showModalExpressPhoneCta, onClose, utfDataObject } = props;

  useEffect(() => {
    trackConversionInteraction(utfDataObject);
  }, []);

  const buttonCallNowExists = !!intl.messages[
    `${COPY_ID_PREFIX}.buttonCallNow`
  ];
  const secondaryPhoneNumberExists = !!intl.messages[
    `${COPY_ID_PREFIX}.secondaryPhoneNumberFormatted`
  ];

  return (
    <ContentModal
      {...props}
      smallCenteredModal
      shouldShowCloseButton
      closeButtonAriaLabel={intl.formatMessage({ id: "SS.ContentModal.close" })}
      onClose={onClose}
      contentAriaLabel={`${intl.formatMessage({
        id: `${COPY_ID_PREFIX}.headline`,
      })}`}
    >
      <Content>
        <CopyContainer>
          <CopyText isParagraph size={TEXT_SIZES.LARGE} weight={400}>
            {intl.formatMessage({ id: `${COPY_ID_PREFIX}.headline` })}
          </CopyText>
          <CopyText isParagraph size={TEXT_SIZES.SMALLPLUS} doubleBottomSpacing>
            {intl.formatMessage(
              { id: `${COPY_ID_PREFIX}.copy` },
              {
                copyOptionalInlineLink: (
                  <Link
                    href={intl.formatMessage({
                      id: `${COPY_ID_PREFIX}.copyOptionalLink`,
                    })}
                    linkType="inline"
                  >
                    {intl.formatMessage({
                      id: `${COPY_ID_PREFIX}.copyOptionalLinkText`,
                    })}
                  </Link>
                ),
              }
            )}
          </CopyText>
          {showModalExpressPhoneCta && (
            <React.Fragment>
              {!secondaryPhoneNumberExists && (
                <CopyText
                  isParagraph
                  size={TEXT_SIZES.SMALLPLUS}
                  doubleBottomSpacing
                >
                  {intl.formatMessage({
                    id: `${COPY_ID_PREFIX}.phoneNumberFormatted`,
                  })}
                </CopyText>
              )}
              {buttonCallNowExists && (
                <ButtonContainer>
                  <Link
                    href={`tel:${intl.formatMessage({
                      id: `${COPY_ID_PREFIX}.phoneNumberLink`,
                    })}`}
                    linkType="cta primary"
                    className="automation-expressModal-callNow-button"
                  >
                    {!secondaryPhoneNumberExists &&
                      intl.formatMessage({
                        id: `${COPY_ID_PREFIX}.buttonCallNow`,
                      })}
                    <IconContainer insertBefore={secondaryPhoneNumberExists}>
                      <Icon
                        fillColor={colors.white}
                        icon={phoneIcon}
                        useAs="decorative"
                        width={{ mobile: "12px", desktop: "14px" }}
                        height={{ mobile: "12px", desktop: "14px" }}
                      />
                    </IconContainer>
                    {secondaryPhoneNumberExists &&
                      intl.formatMessage({
                        id: `${COPY_ID_PREFIX}.phoneNumberFormatted`,
                      })}
                  </Link>
                </ButtonContainer>
              )}
              {secondaryPhoneNumberExists && (
                <SecondaryButtonContainer>
                  <Link
                    href={`tel:${intl.formatMessage({
                      id: `${COPY_ID_PREFIX}.secondaryPhoneNumberLink`,
                    })}`}
                    linkType="cta secondary"
                    className="automation-expressModal-callNow-button"
                  >
                    <IconContainer insertBefore>
                      <Icon
                        fillColor={colors.redScarlet}
                        icon={phoneIcon}
                        useAs="decorative"
                        width={{ mobile: "12px", desktop: "14px" }}
                        height={{ mobile: "12px", desktop: "14px" }}
                      />
                    </IconContainer>
                    {intl.formatMessage({
                      id: `${COPY_ID_PREFIX}.secondaryPhoneNumberFormatted`,
                    })}
                  </Link>
                </SecondaryButtonContainer>
              )}
            </React.Fragment>
          )}
        </CopyContainer>
      </Content>
    </ContentModal>
  );
};

ModalExpress.propTypes = {
  intl: PropTypes.any.isRequired,
  onClose: PropTypes.func,
  showModalExpressPhoneCta: PropTypes.bool,
  utfDataObject: PropTypes.object.isRequired,
};

ModalExpress.defaultProps = {
  onClose: () => {},
  showModalExpressPhoneCta: true,
};

export default injectIntl(ModalExpress);
