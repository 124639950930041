// 9fbef606107a605d69c0edbcd8029e5d

import * as actions from "./actions";
import * as selectors from "./selectors";
import reducer from "./reducer";
import sagas from "./sagas";

export default {
  actions,
  createSagas: sagas,
  reducer,
  selectors,
};
