// 9fbef606107a605d69c0edbcd8029e5d

import { THEME } from "@dhl-official/react-ui-library";

export default {
  fontFamily: THEME.getFontFamily(),
  desktop: {
    h1: {
      size: "39px",
      lineHeight: "43px",
    },
    h2: {
      size: "31px",
      lineHeight: "35px",
    },
    h3: {
      size: "27px",
      lineHeight: "31px",
    },
    h4: {
      size: "20px",
      lineHeight: "24px",
    },
    h5: {
      size: "16px",
      lineHeight: "22px",
    },
    h6: {
      size: "14px",
      lineHeight: "18px",
    },
    button: {
      size: "14px",
      lineHeight: "normal",
    },
    regular: {
      size: "14px",
      lineHeight: "18px",
    },
    copy: {
      size: "14px",
      lineHeight: "18px",
    },
    label: {
      size: "12px",
      lineHeight: "16px",
    },
    caption: {
      size: "12px",
      lineHeight: "16px",
    },
    tabs: {
      size: "16px",
      lineHeight: "26px",
    },
    tabsVariant: {
      size: "20px",
      lineHeight: "24px",
    },
  },
  tablet: {
    h1: {
      size: "36px",
      lineHeight: "40px",
    },
    h2: {
      size: "28px",
      lineHeight: "33px",
    },
    h3: {
      size: "24px",
      lineHeight: "29px",
    },
    h4: {
      size: "18px",
      lineHeight: "24px",
    },
    h5: {
      size: "16px",
      lineHeight: "20px",
    },
    h6: {
      size: "14px",
      lineHeight: "18px",
    },
    button: {
      size: "14px",
      lineHeight: "25px",
    },
    regular: {
      size: "14px",
      lineHeight: "18px",
    },
    copy: {
      size: "14px",
      lineHeight: "18px",
    },
    label: {
      size: "12px",
      lineHeight: "16px",
    },
    caption: {
      size: "12px",
      lineHeight: "16px",
    },
    tabs: {
      size: "16px",
      lineHeight: "26px",
    },
    tabsVariant: {
      size: "20px",
      lineHeight: "24px",
    },
  },
  mobile: {
    h1: {
      size: "33px",
      lineHeight: "36px",
    },
    h2: {
      size: "25px",
      lineHeight: "29px",
    },
    h3: {
      size: "21px",
      lineHeight: "25px",
    },
    h4: {
      size: "20px",
      lineHeight: "24px",
    },
    h5: {
      size: "16px",
      lineHeight: "20px",
    },
    h6: {
      size: "14px",
      lineHeight: "18px",
    },
    button: {
      size: "14px",
      lineHeight: "18px",
    },
    regular: {
      size: "14px",
      lineHeight: "18px",
    },
    noZoomCopy: {
      size: "16px",
    },
    copy: {
      size: "12px",
      lineHeight: "16px",
    },
    label: {
      size: "12px",
      lineHeight: "16px",
    },
    caption: {
      size: "12px",
      lineHeight: "16px",
    },
    tabs: {
      size: "14px",
      lineHeight: "18px",
    },
    tabsVariant: {
      size: "16px",
      lineHeight: "20px",
    },
  },
};
