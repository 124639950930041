// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for Textarea
 *
 */

import styled, { css } from "styled-components";
import { key } from "styled-theme";
import {
  breakpoints,
  sizes,
  colors,
  focusedState,
} from "root/libs/ui-styleguide/src";

export const StyledTextareaContainer = styled.div`
  ${(props) =>
    props.marginBottom ? `margin-bottom: ${props.marginBottom};` : ""};
`;

export const TextareaWrapper = styled.div`
  ${focusedState.focusWithinAllBorders};
  overflow: hidden;
  position: relative;
  display: block;
  border: 1px solid ${(props) => key(props.borderColor)};
  border-radius: 4px;
  padding: 1px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : "initial")};
  }

  &:focus-within {
    border-width: 2px;
    padding: 0;

    label {
      font-weight: 800;
    }
  }

  @supports (display: grid) {
    grid-column: 1 / span 2;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    @supports (display: grid) {
      grid-column: unset;
    }

    textarea {
      padding-bottom: 7px;
    }
  }
`;

export const StyledTextarea = styled.textarea`
  padding: 0 ${sizes.inputSidePaddings} ${sizes.inputSidePaddings}
    ${sizes.inputSidePaddings};
  min-height: ${sizes.inputControlHeight};
  height: ${(props) => props.height};
  width: ${(props) => (props.preventTextAndIconOverlap ? "90%" : "100%")};
  box-sizing: border-box;
  outline: 1px solid transparent;
  font-size: ${key("fonts.desktop.copy.size")};
  line-height: ${key("fonts.desktop.copy.lineHeight")};
  background: transparent;
  appearance: none;
  border: none;
  display: block;
  resize: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    font-size: ${key("fonts.desktop.copy.size")};
    line-height: ${key("fonts.desktop.copy.lineHeight")};
  }

  &:disabled {
    color: ${colors.greyishBrownEmperor};
    background: ${colors.whiteTwoAlto};
    border: 0;
  }
`;

export const StyledLabel = css`
  display: block;
  padding: ${sizes.inputSidePaddings} ${sizes.inputSidePaddings} 4px
    ${sizes.inputSidePaddings};
  color: ${(props) =>
    props.hasError ? key("colors.errorColor") : key("colors.fontColor")};
  line-height: 1;
`;

export const StyledSuccessIcon = css`
  position: absolute;
  right: 20px;
  bottom: 10px;
`;
