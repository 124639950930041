// 9fbef606107a605d69c0edbcd8029e5d

import dates from "../date";

/* eslint-disable no-console */

function logMetadata(metadata) {
  if (metadata) {
    console.group("GAQ-API information");
    const { processingEnd, processingStart, buildProperties } = metadata;

    if (processingEnd && processingStart) {
      const processingTime =
        new Date(processingEnd) - new Date(processingStart);
      console.log("Request time in ms:", dates.toMiliseconds(processingTime));
    }

    if (buildProperties && buildProperties.length > 0) {
      buildProperties.forEach((buildProperty) => {
        console.log(buildProperty.key, buildProperty.value);
      });
    }

    console.groupEnd();
  }
}

function logBackends(backends = []) {
  if (backends && backends.length > 0) {
    console.group("GAQ-API Backends status");
    backends.forEach((backend) => {
      console.log(`${backend.backend}:`, backend.status);
    });
    console.groupEnd();
  }
}

function warn(error) {
  if (error && process.env.IS_LOCALHOST) {
    console.warn(error);
  }
}

/* eslint-enable no-console */

export default {
  logMetadata,
  logBackends,
  warn,
};
