// 9fbef606107a605d69c0edbcd8029e5d

import { memo } from "react";
import { connect } from "react-redux";
import { Tracking } from "root/libs/ui-containers/src";
import Modal from "./index";

const {
  actions: { trackEvent },
} = Tracking;

const mapStateToProps = (state) => ({
  currentOfferData: state.offersLane.bookingNotAllowedModal,
});

const mapDispatchToProps = { trackEvent };

export default connect(mapStateToProps, mapDispatchToProps)(memo(Modal));
