// 9fbef606107a605d69c0edbcd8029e5d

/**
 * This object contains general CSS grid breakpoints and widths
 * @type {Object}
 */

/*
 * Reason these media queries are in rems because when you test the application with 200% zoom level it breaks the Text.
 * Rem based number increase based on viewport settings like media queries and works accordingly.
 * Defects: DPCJG-5456
 */
export default {
  desktop: "64rem", // 1024px
  tablet: "48rem", // 768px
  phablet: "29.875rem", // 478px, Not defined in PL
};
