// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * https://www.w3.org/TR/wai-aria-practices/#tabpanel
 * https://www.w3.org/TR/wai-aria-practices/examples/tabs/tabs-1/tabs.html
 *
 */

import React from "react";
import PropTypes from "prop-types";
import TabTitle from "../TabTitle";
import TabPanel from "../TabPanel";
import { TabTitleList, TabTitleFullBleed } from "./styled";

class Tabs extends React.PureComponent {
  static propTypes = {
    /** Here the sub components (TabTitle and TabPanel will go) */
    children: PropTypes.any,
    /** Defines which one of the accordions is open.
     * The Title and Content of an accordion are the same. They start in 0
     * Default: 0 (first shown) */
    activeIndex: PropTypes.number,
    onTitleClick: PropTypes.func,
    activeIndexResetId: PropTypes.string,
    /** If set the tabs bar gets a grey background and its max-width is 1000px in desktop view */
    isMaxWidthInner: PropTypes.bool,
    /** Class that allows us to exten this and add our own styles in a caller component */
    className: PropTypes.string,
  };

  static defaultProps = {
    children: "",
    className: "",
    activeIndexResetId: "",
    activeIndex: 0,
    isMaxWidthInner: false,
    onTitleClick: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: props.activeIndex,
      activeIndexResetId: props.activeIndexResetId, //eslint-disable-line
      // The line above is disabled becase we use this technique to update the ActiveIndex state
      // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#alternative-1-reset-uncontrolled-component-with-an-id-prop
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.activeIndexResetId !== state.activeIndexResetId) {
      return {
        activeIndex: props.activeIndex,
        activeIndexResetId: props.activeIndexResetId,
      };
    }

    return null;
  }

  onTitleClick = (event, titleProps) => {
    const { index } = titleProps;

    const { onTitleClick } = this.props;

    const newActiveIndex = index;

    this.setState({ activeIndex: newActiveIndex });
    onTitleClick(titleProps);
  };

  /**
   * Given the child type tag, will iterate over the children and will
   * return in an array the list of children of the given type.
   *
   * ChildTypeTag can be: TabPanel or TabTile
   */
  getChildrenByTagType = (ChildTypeTag) => {
    const childrenOfTag = [];
    const { children } = this.props;

    children.map((child) => {
      /* istanbul ignore else */
      if (child.type === ChildTypeTag) {
        childrenOfTag.push(child);
      }
      return childrenOfTag;
    });
    return childrenOfTag;
  };

  showNextTab = (titleProps) => {
    const { index } = titleProps;
    let newActiveIndex = index + 1;
    const { children } = this.props;

    // Divide by 2 as we have for each children TabTitle and TabPanel elements
    if (newActiveIndex >= children.length / 2) {
      newActiveIndex = 0;
    }

    this.setState({ activeIndex: newActiveIndex });
  };

  showPrevTab = (titleProps) => {
    const { index } = titleProps;
    let newActiveIndex = index - 1;
    const { children } = this.props;

    if (newActiveIndex < 0) {
      // Divide by 2 as we have for each children
      // TabTitle and TabPanel elements
      newActiveIndex = children.length / 2 - 1;
    }

    this.setState({ activeIndex: newActiveIndex });
  };

  connectChildrenWithToggle = (ChildTypeTag, extraPropsForChild) => {
    const { isMaxWidthInner } = this.props;
    const children = [];
    const childrenTags = this.getChildrenByTagType(ChildTypeTag);
    const numberOfTabs = childrenTags.length;
    childrenTags.map((child, index) => {
      const { props, type } = child;
      let newProps;

      /* istanbul ignore else */
      if (type === ChildTypeTag) {
        const isSelected = this.isIndexSelected(index);
        newProps = {
          isSelected,
          index,
          isMaxWidthInner,
          numberOfTabs,
          key: `child-${index}`,
          ...extraPropsForChild,
        };
        // We create a new react element, and extend it with the new properties
        // that they need, in order to be connected to the expand/collpse logic
        children.push(React.createElement(type, { ...props, ...newProps }));
      }

      return children;
    });
    return children;
  };

  isIndexSelected = (index) => {
    const { activeIndex } = this.state;

    return index === activeIndex;
  };

  render() {
    const { isMaxWidthInner, className } = this.props;

    const cssClasses = `${className} ${
      isMaxWidthInner ? "grid-maxWidthInner" : ""
    }`;
    return (
      <React.Fragment>
        <TabTitleFullBleed className={cssClasses}>
          <TabTitleList role="tablist">
            {this.connectChildrenWithToggle(TabTitle, {
              onClick: this.onTitleClick,
              showNextTab: this.showNextTab,
              showPrevTab: this.showPrevTab,
            })}
          </TabTitleList>
        </TabTitleFullBleed>
        {this.connectChildrenWithToggle(TabPanel)}
      </React.Fragment>
    );
  }
}

Tabs.Panel = TabPanel;
Tabs.Title = TabTitle;

export default Tabs;
