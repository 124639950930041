// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * WCCOfferCardInformation
 *
 */

import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {
  IntlPropType,
  trackContentInteraction,
} from "root/libs/core-libs/src";
import {
  UTF_EVENT_CONTENT_NAMES,
  UTF_EVENT_TYPES,
  UTF_EVENT_INTERACTIONS,
  UTF_EVENT_CONTENT_POSITION,
} from "root/libs/constants/src";
import { SEGMENT_TYPES } from "one-time-shipment/src/containers/SpotShipmentPage/constants";
import { Text, TEXT_SIZES, Link } from "@dhl-official/react-ui-library";
import { Icon } from "root/libs/ui-components/src";
import wccCall from "icons/cx/wcc-call-multi-bu.svg";
import wccClick from "icons/cx/wcc-click-multi-bu.svg";
import wccWalk from "icons/cx/wcc-walk-multi-bu.svg";
import wccWalkSingle from "icons/cx/location.svg";

const walkColor = "var(--dui-color-cool-green-800)";
const onlineColor = "var(--gaq-color-cool-blue-800)";

const OfferInformationWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
`;

const StyledIconWrapper = styled.div`
  ${(props) => {
    let backgroundColor;
    let additionalStyles;

    if (!props.multiBu) {
      backgroundColor = "transparent";
      additionalStyles = `
        display: inline-block;
        color: #D40511;
      `;
    } else {
      backgroundColor =
        props.purchaseMethod === "WALK" ? walkColor : onlineColor;
      additionalStyles = `
        width: 32px;
        height: 32px;
        border-radius: 50%;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      `;
    }

    return `
      background-color: ${backgroundColor};
      ${additionalStyles}
    `;
  }}
`;

const StyledText = styled(Text)`
  min-height: 32px;
  min-height: 11%;
  padding-left: 10px;
  align-self: center;
`;

const StyledLink = styled(Link)`
  min-height: 32px;
  min-height: 11%;
  align-self: center;
  padding-left: ${(props) => (props.multiBu ? "10px" : "5px")};
  display: ${(props) => (props.multiBu ? "block" : "inline-block")};
`;

export default class WCCOfferCardInformation extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    offerIndex: PropTypes.string,
    businessUnit: PropTypes.string,
    /** An object that contains all the information required of an offer */
    purchaseMethod: PropTypes.string,
    /** Internationalization library */
    intl: IntlPropType.isRequired,
    locatorLink: PropTypes.string,
    isBusiness: PropTypes.bool.isRequired,
    copyPrefix: PropTypes.string.isRequired,
    multiBu: PropTypes.bool,
    offer: PropTypes.object.isRequired,
  };

  static defaultProps = {
    id: "",
    purchaseMethod: "CLICK",
    multiBu: true,
  };

  handleUtfClick = () => {
    const { isBusiness, businessUnit } = this.props;
    trackContentInteraction({
        name: `${UTF_EVENT_CONTENT_NAMES.FIND_DROPOFF_POINT} - ${businessUnit}`,
        type: UTF_EVENT_TYPES.LINK,
        interaction: UTF_EVENT_INTERACTIONS.CLICK,
        position: UTF_EVENT_CONTENT_POSITION.PRODUCT_CARD,
        context: isBusiness ? SEGMENT_TYPES.business : SEGMENT_TYPES.private,
      });
  };

  getIcon = () => {
    const { purchaseMethod, multiBu } = this.props;

    if (!multiBu) {
      return wccWalkSingle;
    }

    const icons = {
      WALK: wccWalk,
      CLICK: wccClick,
      CALL: wccCall,
    };

    return icons[purchaseMethod] || wccWalk;
  };

  isMappedLocation = () => {
    const {offer} = this.props;
    return !!(
      offer.locationMappingInformation &&
      (offer.locationMappingInformation.originLocation.changed ||
        offer.locationMappingInformation.destinationLocation.changed)
    );
  }

  getMappedLocation = () => {
    const {offer, intl} = this.props;
    let key;
    let strObj;

    if (
      offer.locationMappingInformation.originLocation.changed &&
      offer.locationMappingInformation.destinationLocation.changed
    ) {
      key = "closestAlternativeOriginAndDestination";
      strObj = {
        originLocation:
          offer.locationMappingInformation.originLocation.location,
        destinationLocation:
          offer.locationMappingInformation.destinationLocation.location,
      };
    } else if (
      offer.locationMappingInformation.originLocation.changed &&
      !offer.locationMappingInformation.destinationLocation.changed
    ) {
      key = "closestAlternativeOrigin";
      strObj = {
        location: offer.locationMappingInformation.originLocation.location,
      };
    } else if (
      !offer.locationMappingInformation.originLocation.changed &&
      offer.locationMappingInformation.destinationLocation.changed
    ) {
      key = "closestAlternativeDestination";
      strObj = {
        location: offer.locationMappingInformation.destinationLocation.location,
      };
    }
    strObj = {
      ...strObj,
      b: (chunks) => <strong>{chunks}</strong>,
    };
    return intl.formatMessage({ id: `OfferCardHorizontal.${key}` }, { ...strObj });
  }

  getCopyText = () => {
    const { purchaseMethod, intl, businessUnit, copyPrefix } = this.props;
    switch (purchaseMethod) {
      case "WALK":
        return intl.formatMessage({
          id: `${copyPrefix}.WALK_Information`,
        });
      case "CLICK":
        if(this.isMappedLocation()) {
          return this.getMappedLocation();
        }
        return intl.formatMessage({
          id: `${copyPrefix}.CLICK_${businessUnit}_Information`,
        });
      case "CALL":
        return intl.formatMessage({
          id: `${copyPrefix}.CALL_${businessUnit}_Information`,
        });
      default:
        return intl.formatMessage({ id: `${copyPrefix}.WALK_Information` });
    }
  };

  render() {
    const {
      purchaseMethod,
      intl,
      id,
      offerIndex,
      locatorLink,
      copyPrefix,
      multiBu,
    } = this.props;
    const isWalk = purchaseMethod === "WALK";
    const fallbackLink = intl.formatMessage({
      id: `${copyPrefix}.WALK_Link`,
    });
    const walkLink = locatorLink || fallbackLink;
    return (
      <OfferInformationWrapper id={`${id}`}>
        <StyledIconWrapper
          purchaseMethod={purchaseMethod}
          id={`${id}-icon`}
          dataTestid={`wcc-offer-information-icon-${offerIndex}`}
        >
          <Icon
            label="placeholder"
            icon={this.getIcon()}
            size={multiBu ? "large" : "small"}
          />
        </StyledIconWrapper>
        {isWalk && (
          <StyledLink
            id={`${id}-content`}
            dataTestid={`wcc-offer-information-content-${offerIndex}`}
            size={TEXT_SIZES.TINY}
            onClick={this.handleUtfClick}
            href={walkLink}
            target="_blank"
          >
            {this.getCopyText()}
          </StyledLink>
        )}
        {!isWalk && (
          <StyledText
            id={`${id}-content`}
            dataTestid={`wcc-offer-information-content-${offerIndex}`}
            size={TEXT_SIZES.TINY}
          >
            {this.getCopyText()}
          </StyledText>
        )}
      </OfferInformationWrapper>
    );
  }
}
