// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * ModalFreightP2P
 *
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { TEXT_SIZES } from "@dhl-official/react-ui-library";
import { SEGMENT_TYPES } from "one-time-shipment/src/containers/SpotShipmentPage/constants";
import { injectIntl } from "react-intl";
import {
  validations,
  IntlPropType,
  GetCountryCode,
  trackContentInteraction,
} from "root/libs/core-libs/src";
import {
  TextAndLink,
  Headline,
  Icon,
  Textarea,
  ContentModal,
  Form,
  ErrorBoundary,
  countryAutocompleteFieldMessages,
} from "root/libs/ui-components/src";
import FreightIcon from "iconsTwoColors/cx/freight_icon.svg";
import {
  SS,
  showCurrencyAsCode,
  UTF_EVENT_CONTENT_NAMES,
  UTF_EVENT_TYPES,
  UTF_EVENT_INTERACTIONS,
  UTF_EVENT_CONTENT_POSITION,
} from "root/libs/constants/src";
import {
  OfferSummary,
  FormWrapper,
  StyledFieldset,
  FieldsetLegend,
  StyledContainer,
  QuotationOfferArea,
  PriceArea,
  Wrapper,
  TermsAndConditionsText,
  FormFieldWrapper,
} from "./styled";

const COPY_ID_PREFIX = "ModalFreightP2P";

const ModalFreightP2P = (props) => {
  const {
    children,
    intl,
    trackingKeyPaths,
    formFields: {
      companyName,
      firstName,
      lastName,
      email,
      phone,
      additionalInfo,
    },
    offerData: {
      quotationId,
      amount,
      pickUpDate,
      deliveryOption,
      deliveryDate,
      currencyCode,
      deliveryType,
      tax,
      destinationCountry,
      originCountry,
    },
    validity,
    isWaitingForResponse,
    isOpen,
    isFormValid,
    validateFreightP2PModalFormFields,
    firstFieldRef,
    getFreightP2PModalRequestSent,
    showCurrencyIsoCodeForPrices,
    formatCurrencyWithNativeLocale,
    trackEvent,
    nativeLanguageCode,
    isBusiness,
    ...modalProps
  } = props;

  const selectedSegment = isBusiness
    ? SEGMENT_TYPES.business
    : SEGMENT_TYPES.private;

  const offerStaticMessages = {
    taxType: {
      WITHOUT: intl.formatMessage({
        id: `${COPY_ID_PREFIX}.contentPriceText`,
      }),
      WITH: intl.formatMessage({
        id: `${COPY_ID_PREFIX}.contentPriceInclTaxText`,
      }),
      OPTIONAL: intl.formatMessage({
        id: `${COPY_ID_PREFIX}.contentPriceOptionalText`,
      }),
    },
    deliveryType:
      deliveryType === "COMMITTED"
        ? intl.formatMessage({
            id: `${COPY_ID_PREFIX}.contentCommittedDelivery`,
          })
        : intl.formatMessage({
            id: `${COPY_ID_PREFIX}.contentEstimatedDelivery`,
          }),
  };

  useEffect(() => {
    // Setting focus on first item in order to mitigate accessibility norms.
    setTimeout(() => {
      firstFieldRef.current.focus();
    }, 10);
    trackContentInteraction({
      name: UTF_EVENT_CONTENT_NAMES.FREIGHT_P2P_MODAL,
      type: UTF_EVENT_TYPES.MODAL,
      interaction: UTF_EVENT_INTERACTIONS.LOAD,
      position: UTF_EVENT_CONTENT_POSITION.PRODUCT_CARD,
      context: selectedSegment,
      attributes: { selectedFreightOfferId: quotationId },
    });
  }, []);

  const onChange = (event) => {
    const { name, value } = event.target;
    const { onFreightP2PModalInformationChange } = props;

    const inputValue = {
      [name]: value,
    };

    onFreightP2PModalInformationChange({
      event,
      inputValue,
    });
  };

  /**
   * Validate the DOM element and tell us if the element is valid.
   */
  const validateElement = (input) => {
    const { target } = input;
    const validationProperties = validations.extractValidationProperties(
      target
    );
    validationProperties.isBusiness = isBusiness;
    validationProperties.utfPosition =
      UTF_EVENT_CONTENT_POSITION.FREIGHT_P2P_MODAL;
    validateFreightP2PModalFormFields(validationProperties);
    const validityElement = validity[target.name];
    const isInvalid = validityElement && validityElement.hasError;
    const isValid = validityElement && validityElement.isValid;

    return isValid || !isInvalid;
  };

  const countryCode = GetCountryCode(intl.locale);
  const priceFormatLocale = formatCurrencyWithNativeLocale
    ? `${nativeLanguageCode}-${countryCode}`
    : intl.locale;
  const isCurrencyCodeAvailable = currencyCode !== "";

  return (
    <React.Fragment>
      {isOpen && (
        <ContentModal
          isOpen
          isScrollable
          variation="fullScreen"
          aria={{
            describedby: "p2pModalDescription",
          }}
          contentAriaLabel={intl.formatMessage({
            id: `${COPY_ID_PREFIX}.screenReaderLabel_ariaHeading`,
          })}
          closeButtonAriaLabel={intl.formatMessage({
            id: "SS.ContentModal.close",
          })}
          {...modalProps}
        >
          <Wrapper>
            <span
              aria-hidden="false"
              id="p2pModalDescription"
              className="visually-hidden"
            >
              {intl.formatMessage({
                id: `${COPY_ID_PREFIX}.screenReaderLabel_ariaDescription`,
              })}
            </span>
            <ErrorBoundary>
              <Form
                onSubmit={() =>
                  getFreightP2PModalRequestSent({
                    selectedSegment,
                    amount,
                    currencyCode,
                  })
                }
                buttonLabel={intl.formatMessage({
                  id: `${COPY_ID_PREFIX}.submitBtn`,
                })}
                buttonAriaDescribedBy="offer-summary-description offer-summary-info offer-summary-price"
                isFormValid={isFormValid}
                validateDomElement={validateFreightP2PModalFormFields}
                copyNamespace={SS}
                autoScrollToFirstValidationError
                isButtonDisabled={isWaitingForResponse}
                trackEvent={trackEvent}
                trackEventKey="freightP2PModalSubmit"
                buttonDataTrackingKey={trackingKeyPaths.submitButton}
                buttonTestId="freight-P2P-modal-submit-button"
              >
                <StyledFieldset>
                  <FieldsetLegend>
                    <Headline
                      center
                      id="p2pModalHeading"
                      priority={4}
                      weight="300"
                      aria-label={intl.formatMessage({
                        id: `${COPY_ID_PREFIX}.screenReaderLabel_ariaHeading`,
                      })}
                    >
                      {intl.formatMessage({
                        id: `${COPY_ID_PREFIX}.headline`,
                      })}
                    </Headline>
                  </FieldsetLegend>
                  <FormWrapper>
                    <StyledContainer>
                      <FormFieldWrapper
                        type="text"
                        ariaLabel={intl.formatMessage({
                          id: `${COPY_ID_PREFIX}.formFieldLabelCompanyName`,
                        })}
                        value={companyName}
                        label={`${intl.formatMessage({
                          id: `${COPY_ID_PREFIX}.formFieldLabelCompanyName`,
                        })}`}
                        name="companyName"
                        reference={firstFieldRef}
                        required
                        onChange={onChange}
                        onBlur={validateElement}
                        feedback={validity.companyName}
                        interpolations={{
                          fieldName: intl
                            .formatMessage({
                              id: `${COPY_ID_PREFIX}.formFieldLabelCompanyName`,
                            })
                            .toLowerCase(),
                        }}
                        copyNamespace={SS}
                        feedbackMessageIdPrefix=""
                      />
                      <FormFieldWrapper
                        type="text"
                        ariaLabel={intl.formatMessage({
                          id: `${COPY_ID_PREFIX}.formFieldLabelFirstName`,
                        })}
                        value={firstName}
                        label={`${intl.formatMessage({
                          id: `${COPY_ID_PREFIX}.formFieldLabelFirstName`,
                        })}`}
                        name="firstName"
                        required
                        onChange={onChange}
                        onBlur={validateElement}
                        feedback={validity.firstName}
                        interpolations={{
                          fieldName: intl
                            .formatMessage({
                              id: `${COPY_ID_PREFIX}.formFieldLabelFirstName`,
                            })
                            .toLowerCase(),
                        }}
                        copyNamespace={SS}
                        feedbackMessageIdPrefix=""
                      />
                      <FormFieldWrapper
                        type="text"
                        ariaLabel={intl.formatMessage({
                          id: `${COPY_ID_PREFIX}.formFieldLabelLastName`,
                        })}
                        value={lastName}
                        label={`${intl.formatMessage({
                          id: `${COPY_ID_PREFIX}.formFieldLabelLastName`,
                        })}`}
                        name="lastName"
                        required
                        onChange={onChange}
                        onBlur={validateElement}
                        feedback={validity.lastName}
                        interpolations={{
                          fieldName: intl
                            .formatMessage({
                              id: `${COPY_ID_PREFIX}.formFieldLabelLastName`,
                            })
                            .toLowerCase(),
                        }}
                        copyNamespace={SS}
                        feedbackMessageIdPrefix=""
                      />
                      <FormFieldWrapper
                        type="email"
                        ariaLabel={intl.formatMessage({
                          id: `${COPY_ID_PREFIX}.formFieldLabelEmail`,
                        })}
                        value={email}
                        label={`${intl.formatMessage({
                          id: `${COPY_ID_PREFIX}.formFieldLabelEmail`,
                        })}`}
                        name="email"
                        required
                        onChange={onChange}
                        onBlur={validateElement}
                        feedback={validity.email}
                        feedbackMessageIdPrefix="email"
                        interpolations={{
                          fieldName: intl
                            .formatMessage({
                              id: `${COPY_ID_PREFIX}.formFieldLabelEmail`,
                            })
                            .toLowerCase(),
                        }}
                        pattern="email"
                        copyNamespace={SS}
                      />
                    </StyledContainer>
                    <StyledContainer>
                      <FormFieldWrapper
                        type="tel"
                        ariaLabel={intl.formatMessage({
                          id: `${COPY_ID_PREFIX}.formFieldLabelPhone`,
                        })}
                        value={phone}
                        label={`${intl.formatMessage({
                          id: `${COPY_ID_PREFIX}.formFieldLabelPhone`,
                        })}`}
                        name="phone"
                        required
                        onChange={onChange}
                        onBlur={validateElement}
                        feedback={validity.phone}
                        interpolations={{
                          fieldName: intl
                            .formatMessage({
                              id: `${COPY_ID_PREFIX}.formFieldLabelPhone`,
                            })
                            .toLowerCase(),
                        }}
                        copyNamespace={SS}
                        feedbackMessageIdPrefix=""
                      />
                      <Textarea
                        value={additionalInfo}
                        ariaLabel={intl.formatMessage({
                          id: `${COPY_ID_PREFIX}.formFieldLabelAdditionalInfo`,
                        })}
                        label={intl.formatMessage({
                          id: `${COPY_ID_PREFIX}.formFieldLabelAdditionalInfo`,
                        })}
                        name="additionalInfo"
                        height="158px"
                        onChange={onChange}
                        onBlur={validateElement}
                        feedback={validity.additionalInfo}
                        interpolations={{
                          fieldName: intl
                            .formatMessage({
                              id: `${COPY_ID_PREFIX}.formFieldLabelAdditionalInfo`,
                            })
                            .toLowerCase(),
                        }}
                        copyNamespace={SS}
                      />
                    </StyledContainer>
                    <TextAndLink
                      center
                      className="textNote"
                      id="privacyNote"
                      copyString={intl.formatMessage({
                        id: `${COPY_ID_PREFIX}.contentPrivacyNoticeText`,
                      })}
                      linkString={intl.formatMessage({
                        id: `${COPY_ID_PREFIX}.contentPrivacyNoticeLinkText`,
                      })}
                      linkUrl={intl.formatMessage({
                        id: `${COPY_ID_PREFIX}.contentPrivacyNoticeLink`,
                      })}
                      linkAriaLabel={`${intl.formatMessage({
                        id: `${COPY_ID_PREFIX}.contentPrivacyNoticeText`,
                      })} ${intl.formatMessage({
                        id: `${COPY_ID_PREFIX}.contentPrivacyNoticeLinkText`,
                      })}`}
                      linkTarget="_blank"
                      textRole="note"
                    />
                  </FormWrapper>
                </StyledFieldset>
              </Form>
            </ErrorBoundary>
            <OfferSummary>
              <div id="offer-summary-description" className="visually-hidden">
                {intl.formatMessage({
                  id: `${COPY_ID_PREFIX}.screenReaderLabel_summaryDescription`,
                })}
              </div>
              <QuotationOfferArea>
                <Icon
                  label="icon"
                  icon={FreightIcon}
                  width={{
                    mobile: "50px",
                    desktop: "70px",
                  }}
                  height={{
                    mobile: "50px",
                    desktop: "70px",
                  }}
                />
                <dl>
                  <dt>
                    {intl.formatMessage({
                      id: `${COPY_ID_PREFIX}.contentQuotationID`,
                    })}
                  </dt>
                  <dd className="quotationId">{quotationId}</dd>
                </dl>
              </QuotationOfferArea>
              <QuotationOfferArea className="offerSummary">
                <Headline priority={5} weight="700">
                  {intl.formatMessage({
                    id: `${COPY_ID_PREFIX}.contentOfferSummary`,
                  })}
                </Headline>
                <dl id="offer-summary-info">
                  <div>
                    <dt>
                      {intl.formatMessage({
                        id: `${COPY_ID_PREFIX}.contentPickupOn`,
                      })}
                    </dt>
                    <dd>
                      {intl.formatDate(pickUpDate)}
                      {intl.formatMessage({
                        id: `${COPY_ID_PREFIX}.contentPickUpFrom`,
                      })}
                      {intl.formatMessage(
                        countryAutocompleteFieldMessages[originCountry]
                      )}
                      <span className="visually-hidden">:</span>
                    </dd>
                  </div>
                  <div>
                    <dt>
                      {intl.formatMessage({
                        id: `${COPY_ID_PREFIX}.contentDeliveryOption`,
                      })}
                    </dt>
                    <dd>{deliveryOption}</dd>
                    <span className="visually-hidden">:</span>
                  </div>
                  <div>
                    <dt>{offerStaticMessages.deliveryType}</dt>
                    <dd>
                      {`${intl.formatDate(deliveryDate)}
                        ,
                        ${intl.formatMessage(
                          countryAutocompleteFieldMessages[destinationCountry]
                        )}`}
                      <span className="visually-hidden">:</span>
                    </dd>
                  </div>
                </dl>
              </QuotationOfferArea>
              <PriceArea>
                <dl id="offer-summary-price">
                  <dt>{offerStaticMessages.taxType[tax]}</dt>
                  <dd className="price">
                    {showCurrencyIsoCodeForPrices &&
                      isCurrencyCodeAvailable &&
                      `${currencyCode} ${amount}`}
                    {!showCurrencyIsoCodeForPrices &&
                      isCurrencyCodeAvailable &&
                      new Intl.NumberFormat(priceFormatLocale, {
                        style: "currency",
                        currency: currencyCode,
                        currencyDisplay: showCurrencyAsCode.includes(
                          currencyCode
                        )
                          ? "code"
                          : "symbol",
                      }).format(amount)}
                    {currencyCode === "" &&
                      intl.formatNumber(amount, {
                        minimumFractionDigits: 2,
                      })}
                  </dd>
                </dl>
              </PriceArea>
            </OfferSummary>
            <TermsAndConditionsText size={TEXT_SIZES.TINY} isParagraph>
              {intl.formatMessage({
                id: `${COPY_ID_PREFIX}.contentTermsText`,
              })}
            </TermsAndConditionsText>
          </Wrapper>
        </ContentModal>
      )}
    </React.Fragment>
  );
};

ModalFreightP2P.propTypes = {
  children: PropTypes.any,
  /** Internationalization library */
  intl: IntlPropType.isRequired,
  /** Function to handle changes of input fields */
  onFreightP2PModalInformationChange: PropTypes.func,
  /** The validity object containing information about validity of the respective input fields */
  validity: PropTypes.object.isRequired,
  /** Object holding the input field data */
  formFields: PropTypes.object.isRequired,
  /** Function for validation of the form fields */
  validateFreightP2PModalFormFields: PropTypes.func,
  /** Bool to check status of overlay open/closed */
  isOpen: PropTypes.bool.isRequired,
  /** Boolean indicating complete form validity status from Redux */
  isFormValid: PropTypes.bool.isRequired,
  /** Called during onSubmit handler to dispatch http request to the BE service */
  getFreightP2PModalRequestSent: PropTypes.func.isRequired,
  isWaitingForResponse: PropTypes.bool.isRequired,
  offerData: PropTypes.object.isRequired,
  firstFieldRef: PropTypes.object,
  showCurrencyIsoCodeForPrices: PropTypes.bool,
  trackEvent: PropTypes.func.isRequired,
  trackingKeyPaths: PropTypes.object.isRequired,
  nativeLanguageCode: PropTypes.string,
  formatCurrencyWithNativeLocale: PropTypes.bool,
  isBusiness: PropTypes.bool,
};

ModalFreightP2P.defaultProps = {
  children: "",
  onFreightP2PModalInformationChange: () => {},
  validateFreightP2PModalFormFields: () => {},
  firstFieldRef: React.createRef(),
  showCurrencyIsoCodeForPrices: false,
  nativeLanguageCode: "",
  formatCurrencyWithNativeLocale: false,
};

export default injectIntl(ModalFreightP2P);
