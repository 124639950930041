// 9fbef606107a605d69c0edbcd8029e5d

/*
 *
 * AbTestExperiment actions
 *
 */

import { createAction } from "redux-actions";
import { ENABLE_AB_TESTING, CHANGE_AB_VERSION } from "./constants";

export const enableAbTesting = createAction(ENABLE_AB_TESTING);
export const changeABVersion = createAction(CHANGE_AB_VERSION);
