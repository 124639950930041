// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for TeaserBusinessAssistant
 *
 */

import styled from "styled-components";
import { Text, Headline } from "@dhl-official/react-ui-library";
import { breakpoints } from "root/libs/ui-styleguide/src";

export const CardWrapper = styled.div`
  padding: 40px;
  text-align: center;

  svg {
    width: 100px;
    margin-bottom: 20px;

    @media screen and (min-width: ${breakpoints.tablet}) {
      width: 180px;
      margin: 0 24px 0 0;
    }
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: 70px 80px 90px;
    display: flex;
    align-items: flex-start;
    text-align: left;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding: 80px 120px 100px;
  }
`;

export const ContentWrapper = styled.div`
  display: block;
`;

export const StyledHeadline = styled(Headline)`
  margin-bottom: 14px;
`;

export const StyledText = styled(Text)`
  display: block;
  margin-bottom: 14px;
`;
