// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for ErrorPage
 *
 */

import styled from "styled-components";
import { breakpoints } from "root/libs/ui-styleguide/src";
import { Text } from "@dhl-official/react-ui-library";
import Headline from "../../Texts/Headline";

export const PageWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 265px;
  margin: 0 auto;
  padding-top: 100px;
  padding-bottom: 100px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-top: 160px;
    padding-bottom: 160px;
    align-items: center;
    flex-direction: row;
    width: auto;
  }
`;

export const IllustrationWrapper = styled.div`
  display: block;

  svg {
    width: 150px;
    height: 164px;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    svg {
      width: 325px;
      height: 355px;
    }
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    svg {
      width: 410px;
      height: 447px;
    }
  }
`;

export const ContentWrapper = styled.div`
  display: block;
  width: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-left: 75px;
    max-width: 325px;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-left: 125px;
    max-width: 410px;
  }
`;

export const StyledHeadline = styled(Headline)`
  padding-top: 23px;
  margin-bottom: 0;

  &.customFontSize {
    font-size: 31px;
    line-height: normal;
  }
`;

export const StyledSubline = styled(Text)`
  margin-top: 0;
  margin-bottom: 18px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: 12px;
  }

  &.customFontSize {
    font-size: 21px;
    line-height: normal;

    @media screen and (min-width: ${breakpoints.tablet}) {
      font-size: 27px;
      line-height: normal;
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      font-size: 31px;
      line-height: normal;
    }
  }
`;

export const StyledText = styled(Text)`
  margin-top: 0;
  margin-bottom: 0;

  &.customFontSize {
    font-size: 16px;
    line-height: normal;
  }
`;

export const LinkWrapper = styled.div`
  margin-top: 41px;
  display: inline-block;
`;
