// 9fbef606107a605d69c0edbcd8029e5d

import React, { memo } from "react";
import PropTypes from "prop-types";
import { DropdownField } from "@dhl-official/react-ui-library";
import { LanguageSelectorWrapper } from "./styled";

const LABELS = {
  "en-AU": "Australia - EN",
  "en-US": "United States - EN",
  "en-IT": "Italy - EN",
  "it-IT": "Italy - IT",
  "en-IN": "India - EN",
  "en-MX": "Mexico - EN",
  "es-MX": "Mexico - ES",
  "en-GB": "Great Britain - EN",
};

const LanguageSelector = ({ onChange, selectedLocale, locales }) => (
  <LanguageSelectorWrapper className="LanguageSelectorWrapper">
    <DropdownField
      id="localeSelector"
      label="Locale Selector"
      onChange={onChange}
      name="localeSelector"
      value={selectedLocale}
    >
      {locales.map((o) => (
        <option key={o} value={o}>
          {LABELS[o]}
        </option>
      ))}
    </DropdownField>
  </LanguageSelectorWrapper>
);

LanguageSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  locales: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedLocale: PropTypes.string.isRequired,
};

export default memo(LanguageSelector);
