// 9fbef606107a605d69c0edbcd8029e5d

import { handleActions } from "redux-actions";
import { RemoteData } from "root/libs/core-libs/src";
import * as actions from "./actions";

export const initialState = {
  country: undefined,
  experience: null,
  remote: {},
};

const setCountrySettingsFromStorage = (
  state,
  { payload: { country, settings } }
) => ({
  ...state,
  country,
  remote: {
    ...state.remote,
    [country]: RemoteData.success(settings),
  },
});

const loadCountrySettings = (state, { payload }) => {
  const { country, experience } = payload;
  const remoteCountrySettings = state.remote[country];

  if (remoteCountrySettings && RemoteData.isSuccess(remoteCountrySettings)) {
    return {
      ...state,
      country,
      experience,
    };
  }

  return {
    ...state,
    country,
    remote: {
      ...state.remote,
      [country]: RemoteData.loading(),
    },
  };
};

const loadCountrySettingsSuccess = (state, { payload }) => ({
  ...state,
  country: payload.country,
  experience: payload.experience,
  remote: {
    ...state.remote,
    [payload.country]: RemoteData.success(payload.settings),
  },
});

const loadCountrySettingsFailure = (
  state,
  { payload: { country, experience, message } }
) => {
  const remoteCountrySettings = state.remote[country];

  // Do we already have a success state (via loading the settings from localstorage)?
  // Then take just that, use the localstorage country settings and ignore the failed request
  if (remoteCountrySettings && RemoteData.isSuccess(remoteCountrySettings)) {
    return {
      ...state,
      country,
    };
  }

  return {
    ...state,
    country,
    experience,
    remote: {
      ...state.remote,
      [country]: RemoteData.error(message),
    },
  };
};

export default handleActions(
  {
    [actions.loadCountrySettings]: loadCountrySettings,
    [actions.loadCountrySettingsFailure]: loadCountrySettingsFailure,
    [actions.loadCountrySettingsSuccess]: loadCountrySettingsSuccess,
    [actions.setCountrySettingsFromStorage]: setCountrySettingsFromStorage,
  },
  initialState
);
