// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * ModalShipmentInformationDetected
 *
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Text, Button, BUTTON_VARIANTS } from "@dhl-official/react-ui-library";
import { injectIntl } from "react-intl";
import styled from "styled-components";
import { breakpoints } from "root/libs/ui-styleguide/src";
import { trackContentInteraction } from "root/libs/core-libs/src";
import { SEGMENT_TYPES } from "one-time-shipment/src/containers/SpotShipmentPage/constants";
import {
  UTF_EVENT_CONTENT_NAMES,
  UTF_EVENT_TYPES,
  UTF_EVENT_INTERACTIONS,
  UTF_EVENT_CONTENT_POSITION,
} from "root/libs/constants/src";
import { ContentModal, Headline } from "root/libs/ui-components/src";

const CopyText = styled(Text)`
  margin-bottom: 20px;
  margin-top: 10px;
`;

const CTAWrapper = styled.div`
  button {
    width: 100%;

    &:first-child {
      margin-bottom: 10px;
    }
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    button {
      width: initial;

      &:first-child {
        margin-right: 20px;
      }
    }
  }
`;

const Wrapper = styled.div``;

const COPY_ID_PREFIX = "ModalShipmentInformationDetected";

const ModalShipmentInformationDetected = (props) => {
  const { intl, trackEvent, onRestoreHandler, onClose, isBusiness } = props;

  const handleUtfClick = (attr) => {
    trackContentInteraction({
      name: UTF_EVENT_CONTENT_NAMES.RESTORE_SHIPMENT,
      type: UTF_EVENT_TYPES.MODAL,
      interaction: UTF_EVENT_INTERACTIONS.CLICK,
      position: UTF_EVENT_CONTENT_POSITION.PRODUCT_LANE,
      context: isBusiness ? SEGMENT_TYPES.business : SEGMENT_TYPES.private,
      attributes: attr,
    });
  };

  useEffect(() => {
    trackEvent({ shipmentInformationDetectedModalTriggered: true });
    trackContentInteraction({
      name: UTF_EVENT_CONTENT_NAMES.RESTORE_SHIPMENT,
      type: UTF_EVENT_TYPES.MODAL,
      interaction: UTF_EVENT_INTERACTIONS.LOAD,
      position: UTF_EVENT_CONTENT_POSITION.PRODUCT_LANE,
      context: isBusiness ? SEGMENT_TYPES.business : SEGMENT_TYPES.private,
    });
  }, []);

  const onAcceptedClick = () => {
    trackEvent({ clickedOnRestoreShipmentButton: true });
    handleUtfClick({ restore_shipment: "yes" });
    onRestoreHandler();
  };

  const onCanceledClick = () => {
    trackEvent({ clickedOnNewShipmentButton: true });
    handleUtfClick({ restore_shipment: "no" });
    onClose();
  };

  const getCopy = (id, values, prefix = COPY_ID_PREFIX) => {
    return intl.formatMessage({ id: `${prefix}.${id}` }, values);
  };

  return (
    <ContentModal
      {...props}
      smallCenteredModal
      shouldShowCloseButton={false}
      closeButtonAriaLabel={intl.formatMessage({ id: "SS.ContentModal.close" })}
      contentAriaLabel={getCopy("description")}
    >
      <Wrapper data-testid="shipment-info-detected-modal">
        <Headline priority={2}>{getCopy("headline")}</Headline>
        <CopyText isParagraph>{getCopy("description")}</CopyText>
        <CTAWrapper>
          <Button
            type="button"
            onClick={onAcceptedClick}
            dataTestid="shipment-info-detected-modal-restore-btn"
          >
            {getCopy("primaryButton")}
          </Button>
          <Button
            type="button"
            variant={BUTTON_VARIANTS.SECONDARY}
            onClick={onCanceledClick}
            dataTestid="shipment-info-detected-modal-new-btn"
          >
            {getCopy("secondaryButton")}
          </Button>
        </CTAWrapper>
      </Wrapper>
    </ContentModal>
  );
};

ModalShipmentInformationDetected.propTypes = {
  intl: PropTypes.any.isRequired,
  trackEvent: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  isBusiness: PropTypes.bool,
  onRestoreHandler: PropTypes.func,
};

ModalShipmentInformationDetected.defaultProps = {
  isOpen: false,
};

export default injectIntl(ModalShipmentInformationDetected);
