// 9fbef606107a605d69c0edbcd8029e5d

import { performanceTrackingContract } from "root/libs/core-libs/src";

export default {
  digitalLayer: {
    gaq: {
      shippingType: "",
      spotShipment: {
        initialSet: {
          originZip: "",
          originCity: "",
          originCountry: "",
          destinationZip: "",
          destinationCity: "",
          destinationCountry: "",
          senderType: "",
          receiverType: "",
          // Possible values: BUSINESS | RESIDENTIAL
          receiverAddressType: "",
          settingsDangerousGoods: false,
          items: [
            {
              weight: "",
              height: "",
              length: "",
              width: "",
              quantity: 1,
              presetSize: "",
              settingsNonStackable: false,
            },
          ],
        },
        // Possible values: business | private
        selectedSegment: "",
        options: [
          {
            key: "",
            type: "",
            value: "",
          },
        ],
        offers: [
          {
            key: "",
            price: {
              amount: 0,
              currencyCode: "",
            },
            acceptedPaymentMethods: [],
            properties: [
              {
                key: "",
                promoted: true,
              },
            ],
            pathToPurchaseLink: "",
          },
        ],
        backends: [
          {
            backend: "",
            status: "",
            properties: {
              "adapter.version": "",
            },
          },
        ],
        metadata: {
          earliestShippingDate: "",
          processingStart: "",
          processingEnd: "",
          requestId: "",
          buildProperties: [
            {
              key: "",
              value: "",
            },
          ],
        },
        // @UPDATE-CONTRACT 2020-02
        freightP2PModal: {
          // BOOL
          freightP2PModalSubmitSuccess: "",
        },
        interactions: {
          // @UPDATE-CONTRACT 2021-05
          selfSegmentSelection: "",
          offer: {
            idExploredOffer: "",
            idExploredAddOn: "",
            bookNowOfferClicked: "",
          },
          // timestamp.
          regularShipmentCardCTA: "",
          // ENUM['IMPORT', 'EXPORT']
          clickedOnImportExportSwitch: "",
          clickedOnOverWeightLink: false,
          clickedOnDocumentsCheckboxIcon: false,
          clickedOnDangerousGoodsCheckboxIcon: false,
          clickedOnNonStackableCheckboxIcon: false,
          clickedOnSizesTabsMobile: false,
          // DEPRECATED - RELEASE VERSION 17.3.0
          clickedOnShippingOptionsDesktop: false,
          // DEPRECATED - RELEASE VERSION 17.3.0
          clickedOnShippingOptionsMobile: false,
          clickedOnAddShipmentLaneItem: false,
          clickedOnRemoveShipmentLaneItem: false,
          // VALIDATION_ERRORS_SHOWN | timestamp
          continueToShipmentClicked: "",
          offersTriggers: {
            // VALIDATION_ERRORS_SHOWN | timestamp
            getOffersClicked: "",
            // BOOL
            documentsCheckboxChecked: false,
            // string: date example: '2018-11-03'
            shipmentDateChanged: "",
            // string: example: priceLowToHigh / priceHighToLow
            sortingOrderChanged: "",
            // BOOL
            downloadQuotesClicked: false,
            // BOOL
            shareQuotesClicked: false,
            // BOOL
            shareQuotesFallbackClicked: false,
          },
          wrongCountry: false,
          regularShipmentTeaserDisplayed: false,
          regularShipmentTeaserButtonClicked: false,
          regularShipmentTeaserClosed: false,
          // @UPDATE-CONTRACT 2020-02
          clickedOnFreightP2PModalCloseButton: false,
          // TIMESTAMP
          freightP2PModalSubmit: "",
          freightP2PModalQuotationId: "",
          // @UPDATE-CONTRACT 2020-06
          bookingNotAllowedModalTriggered: false,
          // @UPDATE-CONTACT 2021-03
          unitSelectorWarningModalTriggered: false,
          clickedOnProceedUnitSelectionChange: false,
          clickedOnCancelUnitSelectionChange: false,
          // @UPDATE-CONTRACT 2021-04
          saveInformationModalTriggered: false,
          clickedOnSaveInformationCancel: false,
          clickedOnSaveInformationAccepted: false,
          shipmentInformationDetectedModalTriggered: false,
          clickedOnRestoreShipmentButton: false,
          clickedOnNewShipmentButton: false,
          // @UPDATE-CONTRACT 2021-06
          clickedOnFrequentShipmentLink: false,
          // @UPDATE-CONTRACT 2021-11
          shipmentLaneRendered: false,
          quoteLaneRendered: false,
        },
        performance: {
          quoteStarted: "",
          quoteEnded: "",
        },
      },
      performance: performanceTrackingContract.v2,
      system: {
        errorPageShown: false,
      },
    },
  },
};
