// 9fbef606107a605d69c0edbcd8029e5d

import React from "react";
import PropTypes from "prop-types";
import { IntlPropType } from "root/libs/core-libs/src";
import { css } from "styled-components";
import { Icon } from "root/libs/ui-components/src";

import payByCash from "iconsTwoColors/cx/pay-by-cash.svg";
import payByIdeal from "iconsTwoColors/cx/pay-by-ideal.svg";
import payByInvoice from "iconsTwoColors/cx/pay-by-invoice.svg";
import payByDiscover from "iconsTwoColors/cx/pay-by-discover.svg";
import payByMastercard from "iconsTwoColors/cx/pay-by-mastercard.svg";
import payByPaypal from "iconsTwoColors/cx/pay-by-paypal.svg";
import payByVisa from "iconsTwoColors/cx/pay-by-visa.svg";
import payByAmex from "iconsTwoColors/cx/pay-by-amex.svg";
import payByDiners from "iconsTwoColors/cx/pay-by-diners.svg";
import payByJCB from "iconsTwoColors/cx/pay-by-jcb.svg";
import payByKlarnaSofort from "iconsTwoColors/cx/pay-by-klarna-sofort.svg";
import payByMaestro from "iconsTwoColors/cx/pay-by-maestro.svg";
import payByUnionPay from "iconsTwoColors/cx/pay-by-union-pay.svg";
import payByVisaElectron from "iconsTwoColors/cx/pay-by-visa-electron.svg";
import payByGiroPay from "iconsTwoColors/cx/pay-by-giropay.svg";
import payBySepa from "iconsTwoColors/cx/pay-by-sepa.svg";
import payByCOD from "iconsTwoColors/cx/pay-by-cod.svg";
import payByEprzelewy from "iconsTwoColors/cx/pay-by-eprzelewy.svg";
import payByMada from "iconsTwoColors/cx/pay-by-mada.svg";
import payByNordea from "iconsTwoColors/cx/pay-by-nordea.svg";

const paymentMethodsLogos = {
  CASH: payByCash,
  IDEAL: payByIdeal,
  MASTERCARD: payByMastercard,
  PAYPAL: payByPaypal,
  VISA: payByVisa,
  AMEX: payByAmex,
  DISCOVER: payByDiscover,
  INVOICE: payByInvoice,
  DINERS: payByDiners,
  JCB: payByJCB,
  MAESTRO: payByMaestro,
  KLARNA_SOFORT: payByKlarnaSofort,
  UNION_PAY: payByUnionPay,
  VISA_ELECTRON: payByVisaElectron,
  GIROPAY: payByGiroPay,
  SEPA: payBySepa,
  COD: payByCOD,
  EP: payByEprzelewy,
  MAD: payByMada,
  NO: payByNordea,
};

const InvoiceIconExtraStyles = css`
  display: inline-block;
  margin-right: 4px;
`;

function getCopy(intl, id, values) {
  return intl.formatMessage({ id }, values);
}

export function getPaymentLabel(intl, copyPrefix, paymentMethod) {
  const copyKey = `${copyPrefix}.${paymentMethod.toLowerCase()}`;
  // eslint-disable-next-line no-extra-boolean-cast
  return !!intl.messages[copyKey] ? getCopy(intl, copyKey) : paymentMethod;
}

export default function PaymentLogo({ intl, paymentMethod, copyPrefix }) {
  let iconHeight = "18px";
  let iconWidth = "22px";

  switch (paymentMethod) {
    case "VISA":
      iconHeight = "31px";
      break;

    case "NO":
      iconWidth = "50px";
      iconHeight = "31px";
      break;

    case "MASTERCARD":
    case "COD":
      iconHeight = "28px";
      break;

    case "PAYPAL":
      iconHeight = "16px";
      iconWidth = "14px";
      break;

    case "KLARNA_SOFORT":
    case "JCB":
      iconWidth = "31px";
      break;

    case "EP":
      iconHeight = "22px";
      break;

    case "GIROPAY":
      iconWidth = "32px";
      break;

    case "MAD":
    case "SEPA":
      iconWidth = "38px";
      iconHeight = "14px";
      break;

    default:
      break;
  }

  return (
    <Icon
      icon={paymentMethodsLogos[paymentMethod]}
      label={getPaymentLabel(intl, copyPrefix, paymentMethod)}
      width={{
        mobile:
          paymentMethod === "VISA" || paymentMethod === "DISCOVER"
            ? "32px"
            : iconWidth,
        desktop:
          paymentMethod === "VISA" || paymentMethod === "DISCOVER"
            ? "32px"
            : iconWidth,
      }}
      height={{
        mobile: iconHeight,
        desktop: iconHeight,
      }}
      key={paymentMethod}
      useAs="decorative"
      extraStyles={
        paymentMethod === "INVOICE" ? InvoiceIconExtraStyles : undefined
      }
    />
  );
}

PaymentLogo.propTypes = {
  intl: IntlPropType.isRequired,
  paymentMethod: PropTypes.string.isRequired,
  copyPrefix: PropTypes.string.isRequired,
};
