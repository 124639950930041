// 9fbef606107a605d69c0edbcd8029e5d

import { createSelector } from "reselect";
import has from "lodash/has";

const getDigitalLayerGaq = (trackingContract) => () =>
  !!window && !!window.digitalLayer
    ? window.digitalLayer.gaq
    : trackingContract.digitalLayer.gaq;

const getTrackingKey = (trackingKey) => trackingKey;

const selectTrackingData = (state) => state.tracking.trackingData;

const makeSelectTrackingKey = (trackingContract) =>
  createSelector(
    [getDigitalLayerGaq(trackingContract), getTrackingKey],
    (digitalLayerGaq, trackingKey) => {
      if (!trackingKey) {
        const message = `[Tracking Selector]: I was expecting a tracking key that is a path to a property in the digitalLayer,
      but you gave me: ${trackingKey}`;
        throw message;
      }
      const mightExist = trackingKey.indexOf("digitalLayer.gaq.") !== -1;
      const trackingKeyNormalized = mightExist
        ? trackingKey.replace("digitalLayer.gaq.", "")
        : trackingKey;

      if (!has(digitalLayerGaq, trackingKeyNormalized)) {
        const message = `[Containers/Tracking/Selectors]: The key ${trackingKey} does not exist in window.digitalLayer.
                        See what exists/Add new key in: app/containers/Tracking/tracking-contract.js.`;
        throw message;
      }

      return `digitalLayer.gaq.${trackingKeyNormalized}`;
    }
  );

export { selectTrackingData, makeSelectTrackingKey };
