// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for OfferDeliveryInfo
 *
 */

import styled from "styled-components";
import { breakpoints } from "root/libs/ui-styleguide/src";
import { Text } from "@dhl-official/react-ui-library";

export const StyledAdditionalInfo = styled(Text)`
  display: inline;
`;

export const InformationTextWrapper = styled.div`
  margin-top: 10px;

  > .icon {
    display: inline;
    margin-right: 5px;
  }
`;

export const StyledMappedLocation = styled(Text)`
  display: inline;
  line-height: 20px;
`;

export const ContentWrapper = styled.div`
  order: 1;
  width: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    order: 2;
    padding-right: 30px;
  }
`;

export const DeliveryInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > .icon {
    order: 2;
    height: 100%;
    width: 100%;
    text-align: right;
    max-width: 72px;
    align-self: flex-end;
    margin-bottom: auto;
  }

  &.wcc-aggregated-card  > .icon {
    order: 1;
    position: absolute;
    right: 1.5rem;
    top: .625rem;

    @media screen and (min-width: ${breakpoints.tablet}) {
      position: unset;
      top: unset;
      right: unset;
    }
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    align-items: center;

    > .icon {
      margin-right: 30px;
      margin-bottom: 0;
      order: 1;
      text-align: left;
      height: auto;
      width: auto;
      max-width: unset;
      align-self: unset;
    }
  }

  &.wcc-aggregated-card ${ContentWrapper} {
    margin-top: .4rem;
  }
`;

export const BusinessUnitName = styled.div`
  font-weight: 200;
  font-size: 12px;
  color: #333; // TODO: Replace with design token
  margin-top: 7px; // TODO: Replace with design token
`;
