// 9fbef606107a605d69c0edbcd8029e5d

import { takeLatest, all, fork } from "redux-saga/effects";
import mergeWith from "lodash/mergeWith";
import merge from "lodash/merge";
import { PUSH_TO_DIGITAL_LAYER, INITIALIZE_DIGITAL_LAYER } from "./constants";

function getDigitalLayer(trackingContract) {
  return window.digitalLayer
    ? window.digitalLayer
    : trackingContract.digitalLayer;
}

function customizer(objValue, srcValue, key) {
  let returnValue;

  /**
   * The value of these keys will be wiped everytime and only contain the new state,
   * not a merge of old and new state like for the other keys.
   */
  const alwaysUpdateTheseKeys = [
    "inputsError",
    "validationStates",
    "offers",
    "validity",
  ];

  if (alwaysUpdateTheseKeys.includes(key)) {
    returnValue = srcValue;
  }

  return returnValue;
}

function createIfNotExist() {
  if (!window.digitalLayer) {
    window.digitalLayer = { gaq: {} };
  }
}

export function push(trackingContract, data) {
  const { digitalLayer } = data.payload;
  const existingDigitalLayer = getDigitalLayer(trackingContract);
  createIfNotExist();

  if (digitalLayer && Object.keys(digitalLayer).length > 0) {
    mergeWith(
      (window.digitalLayer.gaq = existingDigitalLayer.gaq),
      digitalLayer.gaq,
      customizer
    );
  }
}

export function init(trackingContract) {
  createIfNotExist();
  window.digitalLayer = merge(
    window.digitalLayer,
    trackingContract.digitalLayer
  );
}

export function* pushToDigitalLayer(trackingContract) {
  yield takeLatest(PUSH_TO_DIGITAL_LAYER, push.bind(null, trackingContract));
}

export function* initializeDigitalLayer(trackingContract) {
  yield takeLatest(INITIALIZE_DIGITAL_LAYER, init.bind(null, trackingContract));
}

function* sagas(trackingContract) {
  yield all([
    fork(pushToDigitalLayer.bind(null, trackingContract)),
    fork(initializeDigitalLayer.bind(null, trackingContract)),
  ]);
}

export default function createSagas(trackingContract) {
  return sagas.bind(null, trackingContract);
}
