// 9fbef606107a605d69c0edbcd8029e5d

export default {
  tradeLane: {
    showOriginBillingLink: true,
    warRestrictedZones: ["BY", "RU"],
    partialWarRestrictedZones: ["UA"],
  },
  shipmentLane: {
    business: {
      settingsDangerousGoods: true,
      quantitySelector: true,
      addItem: true,
      maxNumberOfShipmentItems: 5,
    },
    private: {
      settingsDangerousGoods: false,
      quantitySelector: false,
      addItem: false,
      maxNumberOfShipmentItems: 5,
    },
  },
  offersLane: {
    business: {
      enableTeaserOverlayFeature: false,
    },
    private: {
      enableTeaserOverlayFeature: false,
    },
  },
  shipmentDayRange: 6, // In days
};
