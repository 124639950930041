// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * OfferDeliveryInfo
 *
 */
import React from "react";
import PropTypes from "prop-types";
import goGreenIcon from "iconsTwoColors/cx/DPDHL_Group_GoGreen.svg";
import { Icon } from "root/libs/ui-components/src";
import { TEXT_SIZES } from "@dhl-official/react-ui-library";
import { injectIntl } from "react-intl";
import {
  IntlPropType,
  getDeliveryDate,
  getTimeOfDay,
  getDeliveryTime,
} from "root/libs/core-libs/src";
import { offerFeatures } from "root/libs/constants/src";
import {
  DeliveryTimeText,
  DeliveryDateHeadline,
  DateTimeWrapper,
  EstimatedDeliveryText,
  OfferFeaturesWrapper,
} from "./styled";

const COPY_ID_PREFIX = "CardAndSummary";

const EstimatedDeliveryInfo = ({
  intl,
  estimatedDeliveryTime,
  estimatedDeliveryTimeDisplayType,
  is12HourTimeFormat,
  deliveryType,
  offerKey,
  features,
  nativeDateTimeFormat,
  nativeLanguageCode,
}) => {
  const getCopy = (id, values, prefix = COPY_ID_PREFIX) => {
    return intl.formatMessage({ id: `${prefix}.${id}` }, values);
  };

  const getOfferTimes = () => {
    const deliveryDate = getDeliveryDate(
      intl,
      estimatedDeliveryTime,
      nativeDateTimeFormat,
      nativeLanguageCode
    );

    let deliveryTime = "";

    if (estimatedDeliveryTime) {
      const timeOfDay = getTimeOfDay(
        intl,
        estimatedDeliveryTime,
        estimatedDeliveryTimeDisplayType,
        is12HourTimeFormat
      );
      deliveryTime = getDeliveryTime(
        intl,
        timeOfDay,
        estimatedDeliveryTimeDisplayType
      );
    }

    return {
      deliveryDate,
      deliveryTime,
    };
  };

  const { deliveryDate, deliveryTime } = getOfferTimes();

  return (
    <React.Fragment>
      <EstimatedDeliveryText
        size={TEXT_SIZES.TINY}
        id={`${offerKey}-firstline`}
        isParagraph
      >
        {getCopy(
          deliveryType === "ESTIMATED"
            ? "estimatedDelivery"
            : "committedDelivery"
        )}
      </EstimatedDeliveryText>

      <DateTimeWrapper
        data-testid="offer-date"
        data-testvalue={new Date(estimatedDeliveryTime).toJSON()}
        id={`${offerKey}-headline`}
      >
        {!!deliveryDate && (
          <DeliveryDateHeadline
            weight="800"
            inlined
            priority={4}
            className="horizontal-card-headline"
          >
            {deliveryDate}
          </DeliveryDateHeadline>
        )}
        {!!deliveryTime && (
          <DeliveryTimeText weight={200} size={TEXT_SIZES.LARGE}>
            {deliveryTime}
          </DeliveryTimeText>
        )}
        {!!(
          features?.climateNeutralShippingMode === offerFeatures.GO_GREEN
        ) && (
          <OfferFeaturesWrapper>
            <Icon
              icon={goGreenIcon}
              data-testid="icon-gogreen"
              width={{
                mobile: "70px",
                desktop: "70px",
              }}
              height={{
                mobile: "28px",
                desktop: "28px",
              }}
              useAs="decorative"
            />
          </OfferFeaturesWrapper>
        )}
      </DateTimeWrapper>
    </React.Fragment>
  );
};

EstimatedDeliveryInfo.propTypes = {
  /** Internationalization library */
  intl: IntlPropType.isRequired,
  offerKey: PropTypes.string.isRequired,
  estimatedDeliveryTime: PropTypes.instanceOf(Date),
  estimatedDeliveryTimeDisplayType: PropTypes.string,
  deliveryType: PropTypes.oneOf(["COMMITTED", "ESTIMATED"]),
  is12HourTimeFormat: PropTypes.bool.isRequired,
  features: PropTypes.object,
  nativeDateTimeFormat: PropTypes.string,
  nativeLanguageCode: PropTypes.string,
};

EstimatedDeliveryInfo.defaultProps = {
  estimatedDeliveryTime: null,
  estimatedDeliveryTimeDisplayType: null,
  deliveryType: "ESTIMATED",
  features: {},
};

export default injectIntl(EstimatedDeliveryInfo);
