// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * WeightSelector
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { valueFormatters, IntlPropType } from "root/libs/core-libs/src";
import { FormField } from "root/libs/ui-components/src";
import { selectTrackingKey } from "one-time-shipment/src/containers/Tracking/selectors";
import { SS } from "root/libs/constants/src";
import {
  WeightContent,
  WeightInputWrapper,
  StyledHeadline,
  StyledFeedbackMessageButton,
} from "./styled";

export default class WeightSelector extends React.PureComponent {
  static propTypes = {
    /** Translations library  */
    intl: IntlPropType.isRequired,
    className: PropTypes.string,
    weightName: PropTypes.string.isRequired,
    weightLabel: PropTypes.string.isRequired,
    // weightUnit: PropTypes.string.isRequired,
    weightValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /** This function needs to receive the event target. This should be a valid DOM input so we can use CheckValidity in the reducer */
    onWeightChange: PropTypes.func.isRequired,
    onWeightfeedbackLinkClick: PropTypes.func,
    weightValidationRules: PropTypes.shape({
      private: PropTypes.object,
      business: PropTypes.object,
    }).isRequired,
    onBlur: PropTypes.func,
    /** Units to be used in the component. */
    unitSystem: PropTypes.object.isRequired,
    selectedUnitSystem: PropTypes.string.isRequired,
    feedback: PropTypes.shape({
      name: PropTypes.string,
      hasError: PropTypes.bool,
      isValid: PropTypes.bool,
      feedbackMessageId: PropTypes.string,
    }),
    trackEvent: PropTypes.func,
    isBusiness: PropTypes.bool,
    itemIndex: PropTypes.number,
    addItemDescriptionId: PropTypes.string,
    reference: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  };

  static defaultProps = {
    className: "",
    weightValue: 0,
    feedback: {
      name: "",
      hasError: false,
      isValid: false,
      feedbackMessageId: "",
    },
    trackEvent: () => {},
    onWeightfeedbackLinkClick: () => {},
    onBlur: () => {},
    isBusiness: false,
    itemIndex: 0,
    addItemDescriptionId: "",
    reference: React.createRef(),
  };

  constructor(props) {
    super(props);
    this.trackingKeySelector = selectTrackingKey;
    this.trackingKeyPaths = {
      weightName: "",
      overweightLink: this.trackingKeySelector(
        "spotShipment.interactions.clickedOnOverWeightLink"
      ),
    };
  }

  componentDidMount() {
    const { itemIndex } = this.props;

    setTimeout(() => {
      this.trackingKeyPaths.weightName = this.trackingKeySelector(
        `spotShipment.initialSet.items[${itemIndex}].weight`
      );
    }, 0);
  }

  onBlur = (event) => {
    const { onBlur, selectedUnitSystem } = this.props;

    const input = event.target;
    onBlur(input, selectedUnitSystem);
  };

  onWeightChange = (event) => {
    const { onWeightChange, selectedUnitSystem } = this.props;

    const input = event.target;
    onWeightChange(input, selectedUnitSystem);
  };

  onWeightfeedbackLinkClick = (e) => {
    const { trackEvent, onWeightfeedbackLinkClick } = this.props;

    trackEvent({ clickedOnOverWeightLink: true });
    onWeightfeedbackLinkClick(e);
  };

  onWeightBlurValueFormatter = (event) => {
    const { value } = event.target;
    const { unitSystem, selectedUnitSystem } = this.props;

    let formattedValue = value;
    formattedValue = formattedValue.trim();
    formattedValue = valueFormatters.floatingPointWithoutZeroValueFormatter(
      formattedValue
    );
    formattedValue = valueFormatters.stripUnitNameValueFormatter({
      value: formattedValue,
      unit: unitSystem[selectedUnitSystem].weight,
    });
    formattedValue = valueFormatters.roundToTwoDecimalsValueFormatter(
      formattedValue
    );

    return Number.isNaN(formattedValue) ? value : formattedValue;
  };

  getFeedbackLink = (isFeedbackLink) => {
    const {
      intl,
      weightValidationRules,
      selectedUnitSystem,
      unitSystem,
    } = this.props;

    const { business } = weightValidationRules;

    if (!isFeedbackLink) {
      return null;
    }

    return intl.formatMessage(
      {
        id: `SS.validations.weightrangeOverflowLink`,
      },
      {
        br: "\n",
        maxValue: `${business[selectedUnitSystem].maxValue}${unitSystem[selectedUnitSystem].weight}`,
      }
    );
  };

  render() {
    const {
      className,
      weightName,
      feedback,
      weightLabel,
      unitSystem,
      selectedUnitSystem,
      weightValidationRules,
      weightValue,
      isBusiness,
      addItemDescriptionId,
      reference,
      itemIndex,
    } = this.props;

    const validationRules = isBusiness
      ? weightValidationRules.business
      : weightValidationRules.private;

    const useFeedbackLink =
      !!validationRules.links &&
      validationRules.links.length > 0 &&
      feedback.feedbackMessageId === "rangeOverflow";

    const showFeedbackLink = this.getFeedbackLink(useFeedbackLink);
    const feedbackLinkId = `weight-${itemIndex}_feedback_link`;

    return (
      <WeightContent className={className}>
        <StyledHeadline
          priority={5}
          weight="700"
          dataTestid={`item-${weightName}`}
        >
          {weightLabel}
        </StyledHeadline>
        <WeightInputWrapper>
          <FormField
            feedback={feedback}
            interpolations={{
              minValue: `${validationRules[selectedUnitSystem].minValue} ${unitSystem[selectedUnitSystem].weight}`,
              maxValue: `${validationRules[selectedUnitSystem].maxValue} ${unitSystem[selectedUnitSystem].weight}`,
              dimensionName: validationRules[selectedUnitSystem].maxValue,
              fieldName: "weight",
            }}
            customPattern={validationRules.pattern}
            feedbackMessageIdPrefix="weight"
            type="numeric"
            name={weightName}
            data-tracking={this.trackingKeyPaths.weightName}
            onChange={this.onWeightChange}
            onBlur={this.onBlur}
            value={weightValue}
            acceptDecimals
            label={`${weightLabel} (${unitSystem[selectedUnitSystem].weight})`}
            required={validationRules.required}
            maxValue={validationRules[selectedUnitSystem].maxValue}
            minValue={validationRules[selectedUnitSystem].minValue}
            feedbackTrackingKeyPath={this.trackingKeyPaths.overweightLink}
            onBlurValueFormatter={this.onWeightBlurValueFormatter}
            copyNamespace={SS}
            ariaDescribedBy={`${addItemDescriptionId} ${feedbackLinkId}`}
            reference={reference}
          />
          {showFeedbackLink && (
            <StyledFeedbackMessageButton
              id={feedbackLinkId}
              dataTestid={feedbackLinkId}
              size="small"
              type="button"
              variant="text"
              onClick={this.onWeightfeedbackLinkClick}
              isBlock
            >
              {showFeedbackLink}
            </StyledFeedbackMessageButton>
          )}
        </WeightInputWrapper>
      </WeightContent>
    );
  }
}
