// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * ShipmentLaneComponent
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { IntlPropType } from "root/libs/core-libs/src";
import { Icon, Headline, FormField } from "root/libs/ui-components/src";

import { SEGMENT_TYPES } from "one-time-shipment/src/containers/SpotShipmentPage/constants";
import QuantitySelector from "one-time-shipment/src/components/Forms/QuantitySelector";
import { SS } from "root/libs/constants/src";
import {
  CustomDimensionsWrapper,
  WidthHeightLengthControls,
  IconWrapper,
  StyledLegend,
  StyledPresetSizeSelector,
  StyledXText,
  StyledQuantityColumn,
} from "./styled";

const COPY_ID_PREFIX_DIM_SELECT = "DimensionSelector";

class DimensionSelectorLarge extends React.PureComponent {
  static propTypes = {
    /** Translations library  */
    intl: IntlPropType.isRequired,
    /** This function needs to receive the event target. This should be a valid DOM input so we can use CheckValidity in the reducer */
    onDimensionChange: PropTypes.func.isRequired,
    onDimensionBlur: PropTypes.func.isRequired,
    onPresetSelected: PropTypes.func.isRequired,

    heightLabel: PropTypes.string.isRequired,
    widthLabel: PropTypes.string.isRequired,
    lengthLabel: PropTypes.string.isRequired,
    /** Will be used as headline and legend of the fieldset */
    headline: PropTypes.string,
    /** These are intructions to provide context to the users, so they know the presets exist for them. */
    customSizesFieldsetDescribedByCopy: PropTypes.string,
    heightValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    widthValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    lengthValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    heightValidationRules: PropTypes.object,
    widthValidationRules: PropTypes.object,
    lengthValidationRules: PropTypes.object,
    quantityValidationRules: PropTypes.object,
    heightError: PropTypes.shape({
      name: PropTypes.string,
      hasError: PropTypes.bool,
      isValid: PropTypes.bool,
      feedbackMessageId: PropTypes.string,
    }),
    widthError: PropTypes.shape({
      name: PropTypes.string,
      hasError: PropTypes.bool,
      isValid: PropTypes.bool,
      feedbackMessageId: PropTypes.string,
    }),
    lengthError: PropTypes.shape({
      name: PropTypes.string,
      hasError: PropTypes.bool,
      isValid: PropTypes.bool,
      feedbackMessageId: PropTypes.string,
    }),
    quantityError: PropTypes.shape({
      name: PropTypes.string,
      hasError: PropTypes.bool,
      isValid: PropTypes.bool,
      feedbackMessageId: PropTypes.string,
    }),
    /** Will be truty when the user clicks on a preset size. */
    forceShowSuccessState: PropTypes.shape({
      height: PropTypes.bool,
      length: PropTypes.bool,
      width: PropTypes.bool,
    }),
    presetSize: PropTypes.string,
    unitSystem: PropTypes.object.isRequired,
    selectedUnitSystem: PropTypes.string.isRequired,
    iconToRender: PropTypes.object.isRequired,
    dimensionKeyPaths: PropTypes.object,
    /** If set to true, AdvancedSettings will be shown. */
    isBusiness: PropTypes.bool,
    trackEvent: PropTypes.func,
    presetSizes: PropTypes.shape({
      weightBreakThreshold: PropTypes.object,
      private: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
          value: PropTypes.string,
          label: PropTypes.string,
          icon: PropTypes.string,
          values: PropTypes.shape({
            length: PropTypes.string,
            width: PropTypes.string,
            height: PropTypes.string,
            presetSize: PropTypes.string,
          }),
        })
      ),
      business: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
          value: PropTypes.string,
          label: PropTypes.string,
          icon: PropTypes.string,
          values: PropTypes.shape({
            length: PropTypes.string,
            width: PropTypes.string,
            height: PropTypes.string,
            presetSize: PropTypes.string,
          }),
        })
      ),
    }).isRequired,
    onDimensionBlurValueFormatter: PropTypes.func,
    weight: PropTypes.string,
    isWeightValid: PropTypes.bool,
    quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onQuantityChange: PropTypes.func,
    onQuantityBlur: PropTypes.func,
    onCheckboxChange: PropTypes.func,
    settingsNonStackable: PropTypes.bool,
    heightFieldRef: PropTypes.object,
    heightFeedbackInfoMessage: PropTypes.object,
    /* Defines the roundedUpInfoMessage that is only shown when the heightFeedbackInfoMessage is not present  */
    roundedUpInfoMessage: PropTypes.object,
    toggleInfoMessage: PropTypes.func.isRequired,
    isQuantitySelectorEnabled: PropTypes.bool.isRequired,
    itemIndex: PropTypes.number,
    addItemDescriptionId: PropTypes.string,
    /* Defines the displayed list of presets */
    segmentForPresets: PropTypes.oneOf(Object.values(SEGMENT_TYPES)).isRequired,
    /* Boolean to disable advanced settings checkboxes when min weight is not met */
    isCheckboxDisabledBecauseOfWeight: PropTypes.bool,
  };

  static defaultProps = {
    heightValue: 0,
    headline: "",
    customSizesFieldsetDescribedByCopy: "",
    dimensionKeyPaths: {},
    widthValue: 0,
    lengthValue: 0,
    heightValidationRules: {},
    widthValidationRules: {},
    lengthValidationRules: {},
    quantityValidationRules: {},
    heightError: {
      name: "",
      hasError: false,
      isValid: false,
      feedbackMessageId: "",
    },
    widthError: {
      name: "",
      hasError: false,
      isValid: false,
      feedbackMessageId: "",
    },
    lengthError: {
      name: "",
      hasError: false,
      isValid: false,
      feedbackMessageId: "",
    },
    quantityError: {
      name: "",
      hasError: false,
      isValid: false,
      feedbackMessageId: "",
    },
    forceShowSuccessState: {
      height: false,
      length: false,
      width: false,
    },
    presetSize: "",
    isBusiness: false,
    trackEvent: () => {},
    onDimensionBlurValueFormatter: (event) => event.target.value,
    weight: "",
    isWeightValid: false,
    quantity: 1,
    onQuantityChange: () => {},
    onQuantityBlur: () => {},
    onCheckboxChange: () => {},
    settingsNonStackable: false,
    heightFieldRef: React.createRef(),
    heightFeedbackInfoMessage: {
      message: "",
      isShowInfoMessage: false,
    },
    roundedUpInfoMessage: {
      message: "",
      isShowInfoMessage: {},
    },
    itemIndex: 0,
    addItemDescriptionId: "",
    isCheckboxDisabledBecauseOfWeight: false,
  };

  onDimensionBlur = (event) => {
    const { toggleInfoMessage, onDimensionBlur } = this.props;

    toggleInfoMessage(event);
    onDimensionBlur(event);
  };

  render() {
    const {
      heightLabel,
      widthLabel,
      lengthLabel,
      heightValidationRules,
      widthValidationRules,
      lengthValidationRules,
      quantityValidationRules,
      heightError,
      widthError,
      lengthError,
      quantityError,
      presetSize,
      unitSystem,
      selectedUnitSystem,
      intl,
      iconToRender,
      heightValue,
      widthValue,
      lengthValue,
      onPresetSelected,
      onDimensionChange,
      onDimensionBlur,
      dimensionKeyPaths,
      trackEvent,
      headline,
      forceShowSuccessState,
      isBusiness,
      presetSizes,
      onDimensionBlurValueFormatter,
      weight,
      isWeightValid,
      quantity,
      onQuantityChange,
      onQuantityBlur,
      onCheckboxChange,
      settingsNonStackable,
      heightFieldRef,
      heightFeedbackInfoMessage,
      roundedUpInfoMessage,
      toggleInfoMessage,
      customSizesFieldsetDescribedByCopy,
      isQuantitySelectorEnabled,
      itemIndex,
      addItemDescriptionId,
      segmentForPresets,
      isCheckboxDisabledBecauseOfWeight,
    } = this.props;

    const getCopy = (key) =>
      intl.formatMessage({ id: `${COPY_ID_PREFIX_DIM_SELECT}.${key}` });

    const roundedUpLength =
      roundedUpInfoMessage.isShowInfoMessage[`length-${itemIndex}`];
    const roundedUpWidth =
      roundedUpInfoMessage.isShowInfoMessage[`width-${itemIndex}`];
    const roundedUpHeight =
      roundedUpInfoMessage.isShowInfoMessage[`height-${itemIndex}`];

    return (
      <React.Fragment>
        <IconWrapper
          className={isQuantitySelectorEnabled ? "has-quantity" : ""}
        >
          <Icon
            data-testid={iconToRender.id}
            icon={iconToRender}
            width={{
              mobile: "82px",
              desktop: "144px",
            }}
            height={{
              mobile: "81px",
              desktop: "100px",
            }}
            useAs="decorative"
            isRelativeSvgSize
          />
        </IconWrapper>

        <CustomDimensionsWrapper
          isBusiness={isBusiness}
          data-testid="custom-sizes-content"
        >
          <fieldset aria-describedby="customSizesFieldsetDescribedByCopy">
            <StyledLegend>
              <Headline
                dataTestid={`item-dimension-${itemIndex}`}
                priority={5}
                weight="700"
                noMargin
              >
                {headline}
              </Headline>
            </StyledLegend>
            <p
              id="customSizesFieldsetDescribedByCopy"
              className="visually-hidden"
            >
              {customSizesFieldsetDescribedByCopy}
            </p>
            <WidthHeightLengthControls>
              <FormField
                name={`length-${itemIndex}`}
                type="numeric"
                label={`${lengthLabel} (${unitSystem[selectedUnitSystem].length})`}
                data-tracking={dimensionKeyPaths.length}
                value={lengthValue}
                onChange={onDimensionChange}
                onBlur={onDimensionBlur}
                feedback={lengthError}
                interpolations={{
                  minValue: `${lengthValidationRules[selectedUnitSystem].minValue} ${unitSystem[selectedUnitSystem].length}`,
                  maxValue: `${lengthValidationRules[selectedUnitSystem].maxValue} ${unitSystem[selectedUnitSystem].length}`,
                  dimensionName:
                    lengthValidationRules[selectedUnitSystem].maxValue,
                  fieldName: getCopy("lengthLabelErrorMessage"),
                }}
                required={lengthValidationRules.required}
                maxValue={lengthValidationRules[selectedUnitSystem].maxValue}
                minValue={lengthValidationRules[selectedUnitSystem].minValue}
                feedbackMessageIdPrefix="integers"
                feedbackInfoMessage={{
                  message: roundedUpInfoMessage.message,
                  isShowInfoMessage: roundedUpLength,
                }}
                pattern="numberTwoDecimals"
                forceShowSuccessState={forceShowSuccessState.length}
                onBlurValueFormatter={onDimensionBlurValueFormatter}
                copyNamespace={SS}
                ariaDescribedBy={addItemDescriptionId}
                className={`${roundedUpLength ? "validation-info" : ""}`}
                forceFeedbackMessage={roundedUpLength}
              />
              <StyledXText ariaHidden weight={800} isParagraph>
                X
              </StyledXText>
              <FormField
                name={`width-${itemIndex}`}
                type="numeric"
                label={`${widthLabel} (${unitSystem[selectedUnitSystem].length})`}
                data-tracking={dimensionKeyPaths.width}
                value={widthValue}
                onChange={onDimensionChange}
                onBlur={onDimensionBlur}
                feedback={widthError}
                interpolations={{
                  minValue: `${widthValidationRules[selectedUnitSystem].minValue} ${unitSystem[selectedUnitSystem].length}`,
                  maxValue: `${widthValidationRules[selectedUnitSystem].maxValue} ${unitSystem[selectedUnitSystem].length}`,
                  dimensionName:
                    widthValidationRules[selectedUnitSystem].maxValue,
                  fieldName: getCopy("widthLabelErrorMessage"),
                }}
                required={widthValidationRules.required}
                minValue={widthValidationRules[selectedUnitSystem].minValue}
                maxValue={widthValidationRules[selectedUnitSystem].maxValue}
                feedbackMessageIdPrefix="integers"
                feedbackInfoMessage={{
                  message: roundedUpInfoMessage.message,
                  isShowInfoMessage: roundedUpWidth,
                }}
                pattern="numberTwoDecimals"
                forceShowSuccessState={forceShowSuccessState.width}
                onBlurValueFormatter={onDimensionBlurValueFormatter}
                copyNamespace={SS}
                ariaDescribedBy={addItemDescriptionId}
                className={`${roundedUpWidth ? "validation-info" : ""}`}
                forceFeedbackMessage={roundedUpWidth}
              />
              <StyledXText ariaHidden weight={800}>
                X
              </StyledXText>
              <FormField
                name={`height-${itemIndex}`}
                type="numeric"
                label={`${heightLabel} (${unitSystem[selectedUnitSystem].length})`}
                data-tracking={dimensionKeyPaths.height}
                value={heightValue}
                onFocus={toggleInfoMessage}
                onChange={onDimensionChange}
                onBlur={this.onDimensionBlur}
                feedback={heightError}
                interpolations={{
                  minValue: `${heightValidationRules[selectedUnitSystem].minValue} ${unitSystem[selectedUnitSystem].length}`,
                  maxValue: `${heightValidationRules[selectedUnitSystem].maxValue} ${unitSystem[selectedUnitSystem].length}`,
                  dimensionName:
                    heightValidationRules[selectedUnitSystem].maxValue,
                  fieldName: getCopy("heightLabelErrorMessage"),
                }}
                required={heightValidationRules.required}
                maxValue={heightValidationRules[selectedUnitSystem].maxValue}
                minValue={heightValidationRules[selectedUnitSystem].minValue}
                pattern="numberTwoDecimals"
                feedbackMessageIdPrefix="integers"
                feedbackInfoMessage={
                  roundedUpHeight
                    ? {
                        message: roundedUpInfoMessage.message,
                        isShowInfoMessage: roundedUpHeight,
                      }
                    : {
                        message: heightFeedbackInfoMessage.message,
                        isShowInfoMessage:
                          heightFeedbackInfoMessage.isShowInfoMessage,
                      }
                }
                reference={heightFieldRef}
                forceShowSuccessState={forceShowSuccessState.height}
                onBlurValueFormatter={onDimensionBlurValueFormatter}
                copyNamespace={SS}
                ariaDescribedBy={addItemDescriptionId}
                className={`${roundedUpHeight ? "validation-info" : ""}`}
                forceFeedbackMessage={roundedUpHeight}
              />
            </WidthHeightLengthControls>
          </fieldset>
        </CustomDimensionsWrapper>
        {isQuantitySelectorEnabled && (
          <StyledQuantityColumn>
            <QuantitySelector
              quantityName={`quantity-${itemIndex}`}
              quantityLabel={
                isBusiness
                  ? getCopy("quantityLabel")
                  : getCopy("quantityLabelPrivate")
              }
              onQuantityChange={onQuantityChange}
              shouldReplaceEmptyWithMinValue
              quantityValue={quantity}
              unitSystem={unitSystem}
              selectedUnitSystem={selectedUnitSystem}
              key="quantitySelector"
              feedback={quantityError}
              onBlur={onQuantityBlur}
              quantityValidationRules={{
                ...quantityValidationRules[selectedUnitSystem],
                ...{ required: quantityValidationRules.required },
              }}
              indicatorLabel={intl.formatMessage({
                id: "ShipmentLaneComponent.totalWeightLabel",
              })}
              weightValue={weight}
              isWeightValid={isWeightValid}
              itemIndex={itemIndex}
              ariaDescribedBy={addItemDescriptionId}
            />
          </StyledQuantityColumn>
        )}

        <StyledPresetSizeSelector
          gridAlignment={{
            row: 2,
            column: "1 / span 3",
          }}
          intl={intl}
          onPresetSelected={onPresetSelected}
          presetSize={presetSize}
          unit={unitSystem[selectedUnitSystem].length}
          isMobile={false}
          trackEvent={trackEvent}
          isBusiness={isBusiness}
          presetSizes={presetSizes}
          selectedUnitSystem={selectedUnitSystem}
          onCheckboxChange={onCheckboxChange}
          settingsNonStackable={settingsNonStackable}
          itemIndex={itemIndex}
          advancedSettingsAriaDescribedBy={addItemDescriptionId}
          segmentForPresets={segmentForPresets}
          isCheckboxDisabledBecauseOfWeight={isCheckboxDisabledBecauseOfWeight}
        />
      </React.Fragment>
    );
  }
}

export default injectIntl(DimensionSelectorLarge);
