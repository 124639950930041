// 9fbef606107a605d69c0edbcd8029e5d

import { combineReducers } from "redux";
import {
  AbTesting,
  CountrySettings,
  Localization,
  Tracking,
  RtlSupport,
  CanaryVersions,
  Utf,
  MicroFrontend,
} from "root/libs/ui-containers/src";
import getQuoteReducer from "../containers/GetQuote/reducer";
import shipmentLaneReducer from "../containers/ShipmentLane/reducer";
import offersLaneReducer from "../containers/OffersLane/reducer";
import tradeLaneReducer from "../containers/TradeLane/reducer";
import personalization from "../containers/Personalization/reducer";
import spotShipmentPage from "../containers/SpotShipmentPage/reducer";

const reducers = combineReducers({
  abTestExperiment: AbTesting.reducer,
  rtlSupport: RtlSupport.reducer,
  canaryVersion: CanaryVersions.reducer,
  countrySettings: CountrySettings.reducer,
  getQuote: getQuoteReducer,
  localization: Localization.reducer,
  offersLane: offersLaneReducer,
  personalization,
  shipmentLane: shipmentLaneReducer,
  spotShipmentPage,
  tracking: Tracking.reducer,
  tradeLane: tradeLaneReducer,
  utf: Utf.reducer,
  microFrontend: MicroFrontend.reducer,
});

export default reducers;
