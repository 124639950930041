// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * VectorBackground
 *
 */

import React from "react";

export default function VectorBackground() {
  // return (
  //   <svg
  //     className="vector-background"
  //     viewBox="0 0 412 105"
  //     version="1.1"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
  //       <path
  //         d="M0,0 L412,0 L412,94.2112389 C394.423151,92.2501191 369.479911,91.719753 337.170281,92.6201404 C284.116459,94.0986159 279.898058,96.4543637 242.134738,100.012518 C213.160993,102.742497 155.864609,106.850467 126.339004,104.078326 C111.829973,102.716081 92.1040009,100.686678 73.285181,97.055567 C53.2142168,93.1828536 20.9908907,86.9629997 0,81.9 L0,0 Z"
  //         id="Rectangle"
  //         fill="#FFCC00"
  //       />
  //     </g>
  //   </svg>
  // );

  return (
    <svg
      className="vector-background"
      viewBox="0 0 239 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M0,0 L239,0 L239,8.24599409 C228.803721,7.20006354 214.33422,6.91720159 195.591498,7.39740823 C164.81513,8.1859285 162.368048,9.44232729 140.461656,11.3400096 C123.654071,12.7959982 90.4166058,14.9869158 73.288888,13.5084403 C64.8722417,12.7819101 53.4292627,11.6995615 42.51252,9.76296904 C30.8694122,7.6975219 12.1767545,4.38026651 0,1.68 L0,0 Z"
          id="Rectangle-2"
          fill="#FFCC00"
        />
      </g>
    </svg>
  );
}
