// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * AdvancedSettings
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { IntlPropType } from "root/libs/core-libs/src";
import {
  CheckboxField,
  CHECKBOXFIELD_SIZES,
  TEXT_SIZES,
} from "@dhl-official/react-ui-library";
import { IconButtonTooltip } from "root/libs/ui-components/src";
import { selectTrackingKey } from "one-time-shipment/src/containers/Tracking/selectors";
import {
  AdvancedSettingsWrapper,
  AdvancedSettingsCheckboxWrapper,
  StyledHeadline,
  CheckboxContainer,
  ToolTipText,
} from "./styled";

const COPY_ID_PREFIX = "AdvancedSettings";

class AdvancedSettings extends React.PureComponent {
  static propTypes = {
    /** Internationalization library */
    intl: IntlPropType.isRequired,
    /** If set to true Non Stackable Checkbox is shown. Default is true */
    showNonStackableCheckbox: PropTypes.bool,
    settingsNonStackable: PropTypes.bool,
    onCheckboxChange: PropTypes.func,
    trackEvent: PropTypes.func,
    itemIndex: PropTypes.number,
    ariaDescribedBy: PropTypes.string,
    /* Boolean to disable advanced settings checkboxes when min weight is not met */
    isCheckboxDisabledBecauseOfWeight: PropTypes.bool,
  };

  static defaultProps = {
    showNonStackableCheckbox: true,
    settingsNonStackable: false,
    onCheckboxChange: () => {},
    trackEvent: () => {},
    itemIndex: 0,
    ariaDescribedBy: "",
    isCheckboxDisabledBecauseOfWeight: false,
  };

  constructor(props) {
    super(props);
    this.advancedSettingsCheckboxNonStackable = "";
    this.trackingKeySelector = selectTrackingKey;
    this.nonStackableCheckboxIconTrackingKeyPath = this.trackingKeySelector(
      "spotShipment.interactions.clickedOnNonStackableCheckboxIcon"
    );
    this.nonStackableCheckboxIconTrackEventKey =
      "clickedOnNonStackableCheckboxIcon";
  }

  componentDidMount() {
    const { itemIndex } = this.props;

    setTimeout(() => {
      this.advancedSettingsCheckboxNonStackable = this.trackingKeySelector(
        `spotShipment.initialSet.items[${itemIndex}].settingsNonStackable`
      );
    }, 0);
  }

  componentDidUpdate(prevProps) {
    const {
      isCheckboxDisabledBecauseOfWeight,
      onCheckboxChange,
      itemIndex,
    } = this.props;

    /*
      Adding his condition to un-tick the checkboxes when they are coming in disabled
    */
    if (
      prevProps.isCheckboxDisabledBecauseOfWeight !==
        isCheckboxDisabledBecauseOfWeight &&
      isCheckboxDisabledBecauseOfWeight === true
    ) {
      const settingsNonStackableUnchecked = {
        name: `settingsNonStackable-${itemIndex}`,
        value: false,
      };
      onCheckboxChange(settingsNonStackableUnchecked);
    }
  }

  onCheckboxChange = (event) => {
    const { onCheckboxChange } = this.props;
    const { name, checked } = event.target;

    onCheckboxChange({ name, value: checked });
  };

  getCopy = (id) => {
    const { intl } = this.props;
    return intl.formatMessage({ id });
  };

  render() {
    const {
      showNonStackableCheckbox,
      settingsNonStackable,
      trackEvent,
      ariaDescribedBy,
      itemIndex,
      isCheckboxDisabledBecauseOfWeight,
    } = this.props;

    const messages = {
      headline: this.getCopy(`${COPY_ID_PREFIX}.headline`),
      tooltipHeadline: this.getCopy(`${COPY_ID_PREFIX}.tooltipHeadline`),
      labelNonStackable: this.getCopy(`${COPY_ID_PREFIX}.labelNonStackable`),
      tooltipNonStackable: this.getCopy(
        `${COPY_ID_PREFIX}.tooltipNonStackable`
      ),
    };

    return (
      <AdvancedSettingsWrapper>
        <legend className="visually-hidden">{messages.headline}</legend>
        <StyledHeadline priority={5} weight="700">
          {messages.headline}
        </StyledHeadline>
        <AdvancedSettingsCheckboxWrapper>
          {showNonStackableCheckbox && (
            <CheckboxContainer>
              <CheckboxField
                dataTestid={`advanced-non-stackable-${itemIndex}`}
                value={`advanced-non-stackable-${itemIndex}`}
                name={`settingsNonStackable-${itemIndex}`}
                size={CHECKBOXFIELD_SIZES.SMALL}
                isChecked={settingsNonStackable}
                onChange={this.onCheckboxChange}
                trackEvent={trackEvent}
                dataTracking={this.advancedSettingsCheckboxNonStackable}
                disabled={isCheckboxDisabledBecauseOfWeight}
              >
                <span data-testid={`advanced-non-stackable-label-${itemIndex}`}>
                  {messages.labelNonStackable}
                </span>
              </CheckboxField>
              <IconButtonTooltip
                id="tooltip-non-stackable"
                dataTracking={this.nonStackableCheckboxIconTrackingKeyPath}
                ariaLabel={messages.labelNonStackable}
                iconTrackEventKey={this.nonStackableCheckboxIconTrackEventKey}
                name={`icon-advanced-non-stackable-${itemIndex}`}
                iconType="help"
                trackEvent={trackEvent}
                tooltipContent={
                  <React.Fragment>
                    <ToolTipText
                      size={TEXT_SIZES.TINY}
                      weight={700}
                      isParagraph
                    >
                      {messages.tooltipHeadline}
                    </ToolTipText>
                    <ToolTipText size={TEXT_SIZES.TINY}>
                      {messages.tooltipNonStackable}
                    </ToolTipText>
                  </React.Fragment>
                }
                ariaDescribedBy={ariaDescribedBy}
              />
            </CheckboxContainer>
          )}
        </AdvancedSettingsCheckboxWrapper>
      </AdvancedSettingsWrapper>
    );
  }
}

export default injectIntl(AdvancedSettings);
