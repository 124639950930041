// 9fbef606107a605d69c0edbcd8029e5d

import React, { memo } from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { connect } from "react-redux";
import { CountrySettings, Localization } from "root/libs/ui-containers/src";
import { RemoteData } from "root/libs/core-libs/src";
import { compose } from "redux";

export const SKELETON_TEST_ID = "skeleton";

const {
  selectors: { selectMessages },
} = Localization;

const {
  selectors: { selectCountrySettings },
} = CountrySettings;

const SkeletonOr = ({ isLoading, children, ...props }) =>
  isLoading ? (
    <span data-testid={SKELETON_TEST_ID}>
      <Skeleton {...props} />
    </span>
  ) : (
    children
  );

SkeletonOr.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading:
    RemoteData.isLoading(selectMessages(state)) &&
    RemoteData.isLoading(selectCountrySettings(state)),
});

export default compose(memo, connect(mapStateToProps))(SkeletonOr);
