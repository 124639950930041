// 9fbef606107a605d69c0edbcd8029e5d

/**
*
* AutocompleteField
  UX logic
  List autocomplete with manual selection means that:
  1. When the listbox popup is displayed, it contains suggested values that
  complete or logically correspond to the characters typed in the textbox.
  In this implementation, the values in the listbox have names that start with
  the characters typed in the textbox.
  2. Users may set the value of the combobox by choosing a value from the list
  of suggested values.
  3. If the user does not choose a value from the listbox before moving focus
  outside the combobox, the value that the user typed, if any, becomes the value of the combobox.

  Validations Logic:
  - Show success state directly when the user selects a valid item
  - Show error state only onBlur
*
*/

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import memoize from "lodash/memoize";
import { injectIntl } from "react-intl";
import { IntlPropType, normalizeValueForSearch } from "root/libs/core-libs/src";
import { dhlVarColors } from "root/libs/ui-styleguide/src";
import { keyCodes } from "root/libs/constants/src";
import {
  TEXT_SIZES,
  Text,
  THEME,
  TEXT_WEIGHTS,
} from "@dhl-official/react-ui-library";
// Note: Use the line below instead if you want to test work of the react-autocomplete-2 lib
// in our code, before publishing a release.
// For this to work you also need to uncomment a line of the resolve config in:
// internals/webpack/webpack.base.babel.js
import ReactAutocomplete from "@learnwithgurpreet/react-autocomplete";
import { ForwardedFormField } from "../FormField";
import Flag from "../../Flag";
import messages from "./messages";

const StyledText = styled(Text)`
  margin-right: ${THEME.getUnit("small")};
  margin-left: ${THEME.getUnit("small")};
  font-weight: ${TEXT_WEIGHTS[200]};
  font-size: ${THEME.getFontSize(TEXT_SIZES.SMALLPLUS)};
`;

const StyledMenuWrapper = styled.div`
  background: ${dhlVarColors.backgroundColorInverted};
  border-radius: 0 0 4px 4px;
  border-width: 1px 2px 2px;
  border-style: solid;
  border-color: ${dhlVarColors.shadowDarkHover} ${dhlVarColors.focusBorderColor}
    ${dhlVarColors.focusBorderColor};
  margin-top: -4px;
  box-shadow: 0;
  font-size: 100%;
  left: 0;
  max-height: 40vh;
  overflow: auto;
  padding: 0;
  position: absolute;
  top: 58px;
  width: 100%;
  z-index: 5;

  @media screen and (max-width: ${THEME.getViewport("medium")}) {
    max-height: 160px;
  }
`;

class AutocompleteField extends React.PureComponent {
  static propTypes = {
    ariaDescribedBy: PropTypes.string,
    /** HTML attribute to toggle on or off the browser autocomplete */
    autoComplete: PropTypes.string,
    /** The items to display in the dropdown menu */
    autocompleteItems: PropTypes.array.isRequired,
    blacklistedValues: PropTypes.array,
    warRestrictedValues: PropTypes.array,
    className: PropTypes.string,
    /** Additional key to look up and display specific message variations */
    copyNamespace: PropTypes.string.isRequired,
    /** Country ID to render correct flag */
    countryId: PropTypes.string,
    customPattern: PropTypes.string,
    dataTestid: PropTypes.string,
    feedback: PropTypes.object,
    feedbackMessageIdPrefix: PropTypes.string,
    forceShowSuccessState: PropTypes.bool,
    intl: IntlPropType.isRequired,
    /**
     * Invoked when attempting to select an item.
     * The return value is used to determine whether the item *
     * should be selectable or not. By default all items are selectable.
     * */
    isItemSelectable: PropTypes.func,
    label: PropTypes.string,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    openOnFocus: PropTypes.bool,
    /** When set to true, it will look up for a country ID and render the flag inside the Dropdown and Input. */
    renderFlags: PropTypes.bool,
    required: PropTypes.bool,
    /** When set to true, once the item is selected, it will return an object with shape: * { label: item.label, value: item.id } */
    returnSelectedObject: PropTypes.bool,
    /** Invoked when the user selects an item from the dropdown menu. */
    selectItem: PropTypes.func,
    /** Whether or not to automatically select the highlighted item when the <input> loses focus. */
    selectOnBlur: PropTypes.bool,
    shouldItemRender: PropTypes.func,
    /** For single autocompletes like the CountryAutocomplete we want to show sucess directly. * For auotocomplete like zip and city, we want to show it onBlur only, so that we wait for the BE validation */
    showSuccessStateOnBlurOnly: PropTypes.bool,
    value: PropTypes.string,
    valueForDataValidation: PropTypes.string,
    whitelistedValues: PropTypes.array,
    splitLabel: PropTypes.bool,
    role: PropTypes.string,
    ariaExpanded: PropTypes.bool,
    ariaControls: PropTypes.string,
  };

  static defaultProps = {
    ariaDescribedBy: "",
    /** Default value "off" is ignored by the majority of browser nowadays */
    autoComplete: "defaultreactautocompletefield",
    blacklistedValues: [],
    warRestrictedValues: [],
    className: "autocomplete",
    countryId: "",
    customPattern: "",
    dataTestid: undefined,
    feedback: { hasError: false, isValid: false, errorMessageId: "" },
    feedbackMessageIdPrefix: "",
    forceShowSuccessState: false,
    isItemSelectable: undefined,
    label: "",
    name: "",
    onBlur: Function.prototype,
    onChange: Function.prototype,
    onFocus: Function.prototype,
    openOnFocus: false,
    renderFlags: false,
    required: false,
    returnSelectedObject: false,
    selectItem: Function.prototype,
    selectOnBlur: false,
    shouldItemRender: undefined,
    showSuccessStateOnBlurOnly: true,
    value: "",
    valueForDataValidation: undefined,
    whitelistedValues: [],
    splitLabel: false,
    role: "",
    ariaExpanded: false,
    ariaControls: "",
  };

  getFeedbackStatus = memoize((feedback) => ({
    isPristine: !feedback.isValid && !feedback.hasError,
    isValid: !!feedback.isValid && !feedback.hasError,
  }));

  constructor(props) {
    super(props);

    this.autocompleteReference = React.createRef();

    const isPristine = !props.feedback.isValid && !props.feedback.hasError;
    const hasError = !props.feedback.isValid && !!props.feedback.hasError;

    this.state = {
      showFeedback: isPristine || hasError,
      anItemWasSelected: false,
    };
  }

  /**
   * Arguments: value: String, item: Any
   * Invoked when the user selects an item from the dropdown menu.
   * todo - test returnSelectedObject
   * */
  onSelect = (value, item) => {
    const { selectItem, returnSelectedObject, feedback } = this.props;

    const selectedValue = returnSelectedObject
      ? { label: item.label, value: item.id }
      : value;

    selectItem(selectedValue);

    const AutocompleteObject = this.autocompleteReference.current;
    const input =
      !!AutocompleteObject && AutocompleteObject.references.input.current;

    const { isValid, hasError } = feedback;

    this.setState({ showFeedback: !!isValid && !hasError });
    this.setState({ anItemWasSelected: true });
    // OnSelect FF triggers a focusOut. We want the focus to stay where it belongs
    input.focus();
  };

  onChange = (event) => {
    const { onChange } = this.props;

    this.setState({ showFeedback: false });
    this.setState({ anItemWasSelected: false });

    onChange(event);
  };

  onBlur = (event) => {
    const { onBlur } = this.props;

    this.setState({ showFeedback: true });
    onBlur(event);
  };

  onFocus = (event) => {
    const { onFocus, value } = this.props;

    this.setState({ showFeedback: value === "" });
    this.setState({ anItemWasSelected: value === "" });

    onFocus(event);
  };

  onMenuVisibilityChange = (isOpen) => {
    this.setState({ showFeedback: !isOpen });
  };

  onKeyPress = (event) => {
    const { onChange } = this.props;
    const { keyCode } = event;

    const { name } = this.props;

    if (keyCode === keyCodes.ESC) {
      const newEvent = {
        ...event,
        target: { ...event.target, value: "", name },
      };
      onChange(newEvent);
    }
  };

  /** Gets the text of the rendered item. Used to filter later the autocomplete */
  getItemLabel = (item) => item.label;

  /**
   * After the user enters a value in the input we compare that value with the list
   * and filter the results, this way only relevant results are always visible
   */
  shouldItemRender = (item, value) =>
    item.label &&
    normalizeValueForSearch(item.label).indexOf(
      normalizeValueForSearch(value)
    ) > -1;

  renderFormField = ({ ref, ...props }) => {
    const {
      autoComplete,
      forceShowSuccessState,
      ariaDescribedBy,
      intl,
      copyNamespace,
      feedback,
      showSuccessStateOnBlurOnly,
    } = this.props;

    const { showFeedback, anItemWasSelected } = this.state;

    const { isValid } = this.getFeedbackStatus(feedback);

    const showFeedbackOnInteraction = !!anItemWasSelected && !!isValid;
    const makeYourMindThen = showFeedbackOnInteraction || showFeedback;

    return (
      <React.Fragment>
        <span id={`${props.name}-instructions`} className="visually-hidden">
          {intl.formatMessage(
            messages[`${copyNamespace}_screenReaderLabel_usageInstructions`]
          )}
        </span>
        <ForwardedFormField
          showSuccessStateOnBlurOnly={showSuccessStateOnBlurOnly}
          forceShowSuccessState={forceShowSuccessState}
          reference={ref}
          autoComplete={autoComplete}
          {...props}
          ariaDescribedBy={`${
            props.feedback && props.feedback.errorAriaDescribedBy
              ? props.feedback.errorAriaDescribedBy
              : ""
          } ${ariaDescribedBy} ${props.name}-instructions`}
          feedback={makeYourMindThen ? props.feedback : {}}
        />
      </React.Fragment>
    );
  };

  splitLabelFn = (label) => {
    const { splitLabel } = this.props;

    if (splitLabel) {
      const splittedValue = label.split(" / ");
      if (splittedValue.length === 2) {
        return (
          <React.Fragment>
            <StyledText>{splittedValue[0]}</StyledText>/
            <StyledText lang="en">{splittedValue[1]}</StyledText>
          </React.Fragment>
        );
      }
    }
    return <StyledText>{label}</StyledText>;
  };

  renderItem = (item, isHighlighted) => {
    const { renderFlags, autocompleteItems } = this.props;

    const withFlagStyles = {
      display: "flex",
      height: "46px",
      lineHeight: "46px",
      alignItems: "center",
      paddingLeft: "1.4rem",
    };

    const itemStyles = {
      backgroundColor: isHighlighted
        ? `${dhlVarColors.shadowLightDefault}`
        : "#f9f9f9",
      color: item.disabled ? "#afafaf" : `${dhlVarColors.headerColor}`,
      fontWeight: 200,
      padding: "10px",
      ...(renderFlags && withFlagStyles),
    };

    const label = this.splitLabelFn(item.displayLabel || item.label);

    const itemIndex = autocompleteItems.findIndex((obj) => obj.id === item.id);

    return (
      <div
        aria-selected={isHighlighted}
        aria-label={`${item.label} (${itemIndex + 1} of ${
          autocompleteItems.length
        })`}
        key={item.id}
        role="option"
        style={itemStyles}
        id={item.id}
        tabIndex="-1"
      >
        {renderFlags && <Flag country={item.id} />}
        {label}
      </div>
    );
  };

  renderMenuWrapper = (children, suggestionsMenuId) => {
    return (
      <StyledMenuWrapper id={suggestionsMenuId} role="listbox">
        {children}
      </StyledMenuWrapper>
    );
  };

  render() {
    const {
      autocompleteItems,
      blacklistedValues,
      warRestrictedValues,
      className,
      copyNamespace,
      dataTestid,
      intl,
      isItemSelectable,
      onBlur,
      onChange,
      openOnFocus,
      selectOnBlur,
      shouldItemRender,
      value,
      valueForDataValidation,
      whitelistedValues,
      role,
      ariaExpanded,
      ...inputProps
    } = this.props;

    const suggestionsMenuId = `${inputProps.name}-suggestions`;

    return (
      <ReactAutocomplete
        autoHighlight={false}
        getItemValue={this.getItemLabel}
        inputProps={{
          ...inputProps,
          "data-blacklisted-values": blacklistedValues,
          "data-value-for-validation": valueForDataValidation,
          "data-whitelisted-values": whitelistedValues,
          "data-war-restricted-values": warRestrictedValues,
          dataTestid,
          copyNamespace,
          onBlur: this.onBlur,
          onFocus: this.onFocus,
          suggestionsMenuId,
        }}
        isItemSelectable={(item) =>
          isItemSelectable ? isItemSelectable(item) : !item.disabled
        }
        items={autocompleteItems}
        numberOfResultsAvailableCopy={intl.formatMessage(
          messages[`${copyNamespace}_screenReaderLabel_numberOfResults`]
        )}
        onChange={this.onChange}
        onKeyDown={this.onKeyPress}
        onMenuVisibilityChange={this.onMenuVisibilityChange}
        onSelect={this.onSelect}
        openOnFocus={openOnFocus}
        ref={this.autocompleteReference}
        renderInput={this.renderFormField}
        renderItem={this.renderItem}
        renderMenu={(children, suggestionsMenuId) =>
          this.renderMenuWrapper(children, suggestionsMenuId)
        }
        selectOnBlur={selectOnBlur}
        shouldItemRender={shouldItemRender || this.shouldItemRender}
        suggestionsMenuId={suggestionsMenuId}
        value={value}
        wrapperProps={{ onKeyDown: this.onKeyPress, className }}
        role="combobox"
        ariaExpanded={{ onKeyDown: true }}
        ariaControls={suggestionsMenuId}
        ariaDescribedby={suggestionsMenuId}
        ariaAutocomplete="list"
      />
    );
  }
}

export default injectIntl(AutocompleteField);
