// 9fbef606107a605d69c0edbcd8029e5d

import qs from "qs";
import { COMMON } from "root/libs/constants/src";

// Later, performance and analytics consent types can also be added here.
const CONSENT_TYPES = {
  FUNCTIONAL: "C0003:1",
};

const getCookie = (cname) => {
  if (document.cookie) {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");

    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  }
  return undefined;
};

const checkDHLCookieConsent = (type) => {
  const { DHL_COOKIE_CONSENT_COOKIE } = COMMON;
  const dhlCookieConsent = getCookie(DHL_COOKIE_CONSENT_COOKIE);

  if (dhlCookieConsent) {
    const parsed = qs.parse(dhlCookieConsent);
    return parsed.groups.split(",").includes(type);
  }

  return false;
};

const setCookie = (cname, cvalue, exdays, type = CONSENT_TYPES.FUNCTIONAL) => {
  if (document.cookie && checkDHLCookieConsent(type)) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cname}=${cvalue};${expires};path=/`;
  }
};

export default {
  setCookie,
  getCookie,
  CONSENT_TYPES,
};
