// 9fbef606107a605d69c0edbcd8029e5d

import { css } from "styled-components";

// From: https://github.com/csstools/sanitize.css/blob/master/sanitize.css

export default css`
  [type="checkbox"],
  [type="radio"] {
    padding: 0;
  }

  ::before,
  ::after {
    background-repeat: no-repeat;
    box-sizing: border-box;
  }

  a,
  area,
  button,
  input,
  label,
  select,
  summary,
  textarea,
  [tabindex] {
    touch-action: manipulation;
  }

  [aria-controls] {
    cursor: pointer;
  }

  [aria-disabled="true"],
  [disabled] {
    cursor: not-allowed;
  }

  [aria-hidden="false"][hidden]:not(:focus) {
    clip: rect(0, 0, 0, 0);
    display: inherit;
    position: absolute;
  }
`;
