// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for ExpandCollapseButton
 *
 */

import styled, { css } from "styled-components";
import { key } from "styled-theme";
import { breakpoints } from "root/libs/ui-styleguide/src";
import { Text } from "@dhl-official/react-ui-library";

const bubbleStyles = css`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  box-shadow: 0 3px 8px 0 ${key("colors.shadowLightDefault")};
  transition: ease-in-out 200ms box-shadow;
`;

const bubbleWithTextStyles = css`
  width: fit-content;
  padding: 5px 20px;
  border-radius: 4px;

  &:focus,
  &:active {
    padding: 3px 18px;
    border: 2px solid ${key("colors.fontColor")};
  }
`;

export const ExpandCollapseButtonBubble = styled.button`
  ${(props) =>
    props.variation === "bubbleWithText" ? bubbleWithTextStyles : bubbleStyles}
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  background-color: ${key("colors.backgroundColorInverted")};

  &:hover,
  &:focus,
  &:active {
    .text {
      color: ${key("colors.primaryHover")};
    }

    .icon svg {
      transition: fill 0.2s ease-out;
      fill: ${key("colors.primaryHover")};
    }
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${(props) =>
      props.overflowBottomMarginDesktop ? "margin-bottom: -33px;" : ""}
  }

  button.icon {
    border-radius: 50%;

    &:hover,
    &:focus,
    &:active {
      border: 0;
    }
  }
`;

export const StyledText = styled(Text)`
  transition: color 0.2s ease-out;
  display: inline-block;
  color: ${key("colors.primary")};
  padding-right: 15px;
  padding-top: 4px;
`;
