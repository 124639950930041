// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for Tabs
 *
 */

import styled from "styled-components";

export const TabTitleList = styled.ul`
  list-style: none;
  display: flex;
  padding: 0;
  margin-bottom: 0;
`;

export const TabTitleFullBleed = styled.div`
  width: 100%;
  padding: 20px 0 0;
`;
