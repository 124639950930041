// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Radio
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { keyCodes } from "root/libs/constants/src";
import checkIcon from "icons/cx/check.svg";
import {
  StyledRadioWrapper,
  StyledLabel,
  StyledInput,
  StyledIconWrapper,
  StyledIcon,
} from "./styled";

export default class Radio extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    /** The value of the input */
    value: PropTypes.string.isRequired,
    /** The name of the radio button. If you have a group of radios,
     * all of them have the same name and a different value */
    name: PropTypes.string.isRequired,
    /** Label text */
    label: PropTypes.string.isRequired,
    /** If this is set will be used as aria-label,
     * otherwise no aria label will be rendered.
     * It will be used if visuallyHiddenLabel is true */
    ariaLabel: PropTypes.string,
    /** True if the label will not be visually there.
     * This is used for cases such as PreseSizeCardGroup */
    visuallyHiddenLabel: PropTypes.bool,
    /** Child element that will be displayed as part of label */
    children: PropTypes.any,
    /** Boolean value that is setting whether is the checkbox checked or not */
    isChecked: PropTypes.bool.isRequired,
    isLabelBold: PropTypes.bool,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyUp: PropTypes.func,
    onKeyDown: PropTypes.func,
    reference: PropTypes.object,
    dataTrackingKeyPath: PropTypes.string,
    /** If set the z-index of the radio container will be the received value. Otherwise wont be set. */
    cssZIndex: PropTypes.number,
    appearance: PropTypes.oneOf(["radio", "card"]),
  };

  static defaultProps = {
    children: "",
    ariaLabel: "",
    cssZIndex: 0,
    isLabelBold: false,
    visuallyHiddenLabel: false,
    dataTrackingKeyPath: "",
    onChange: () => { },
    onFocus: () => { },
    onKeyUp: () => { },
    onKeyDown: () => { },
    reference: {
      current: null,
    },
    appearance: "radio",
  };

  onKeyDown = (event) => {
    const { onKeyDown, onChange, name, isChecked } = this.props;

    if (event.keyCode === keyCodes.RETURN) {
      onChange({ target: { name, checked: !isChecked } });
    }

    onKeyDown(event);
  };

  render() {
    const {
      id,
      value,
      name,
      children,
      onChange,
      isChecked,
      onFocus,
      reference,
      onKeyUp,
      dataTrackingKeyPath,
      appearance,
      label,
      ariaLabel,
      isLabelBold,
      visuallyHiddenLabel,
      cssZIndex,
    } = this.props;

    const labelProps = {};

    if (ariaLabel !== "" || visuallyHiddenLabel) {
      labelProps["aria-label"] = ariaLabel;
    }

    return (
      <StyledRadioWrapper cssZIndex={cssZIndex}>
        <StyledInput
          type="radio"
          name={name}
          id={id}
          value={value}
          checked={isChecked}
          onChange={onChange}
          data-tracking={dataTrackingKeyPath}
        />
        <StyledLabel
          onKeyDown={this.onKeyDown}
          onKeyUp={onKeyUp}
          onFocus={onFocus}
          target={id}
          tabIndex="0"
          reference={reference}
          appearance={appearance}
          isBold={isLabelBold}
          cssFontSize="copy"
          {...labelProps}
        >
          {!visuallyHiddenLabel && label}
        </StyledLabel>
        {appearance === "card" && (
          <StyledIconWrapper className="toggleIcon">
            <StyledIcon
              icon={checkIcon}
              useAs="decorative"
              fillColor="white"
              width={{ desktop: "12px", mobile: "12px" }}
              height={{ desktop: "12px", mobile: "12px" }}
            />
          </StyledIconWrapper>
        )}
        {children}
      </StyledRadioWrapper>
    );
  }
}
