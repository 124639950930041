// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for SegmentControl
 *
 */

import styled, { css } from "styled-components";
import { key } from "styled-theme";
import { breakpoints, colors } from "root/libs/ui-styleguide/src";

export const SegmentControlWrapper = styled.ul`
  display: flex;
  overflow: hidden;
  position: relative;
  list-style-type: none;
  box-shadow: -5px 5px 8px -5px ${key("colors.shadowLightDefault")};
  margin-bottom: 0;
`;

const SegmentControlItemControl = css`
  cursor: pointer;
  border-top: 1px solid ${key("colors.shadowDarkHover")};
  transition: border-top 100ms ease-in-out;
  box-shadow: 2px 0 8px -3px ${key("colors.shadowLightDefault")};
`;

export const SegmentedListWrapper = styled.li`
  list-style: none;
  flex: 1 0 ${(props) => 100 / props.numberOfTabs}%;
  position: relative;
  ${SegmentControlItemControl}

  @media screen and (min-width: ${breakpoints.tablet}) {
    min-width: 205px;
    flex: inherit;
  }

  &:last-child {
    border-left: 0;
  }

  @media print {
    display: none;
  }
`;

export const SegmentedControlButton = styled.a`
  &[aria-selected="true"] {
    color: ${key("colors.primary")};
    border-top-color: ${key("colors.primary")};
    background-color: ${key("colors.backgroundColorInverted")};
  }


  text-decoration: none;
  display: block;
  width: 100%;
  text-align: center;
  outline: none;
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: ${key("colors.fontColor")};
  cursor: pointer;
  z-index: 5;

  border-top: 5px solid transparent;
  transition: border-top 100ms ease-in-out;
  font-weight: 600;
  padding: 12px 6px;
  font-size: ${key("fonts.mobile.tabs.size")};
  line-height: ${key("fonts.mobile.tabs.lineHeight")};
  background-color: ${colors.shadowHalfOpacity};

  .labelText {
    padding: 4px 3px 1px;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    font-size: ${key("fonts.desktop.tabs.size")};
    line-height: ${key("fonts.desktop.tabs.lineHeight")};
    padding: 14px 20px;
    text-align: center;
    &:focus > .labelText {
      background-color: ${key("colors.primary")};
      color: ${key("colors.fontColorInverded")};
    }
  }
`;
