// 9fbef606107a605d69c0edbcd8029e5d

import { handleActions } from "redux-actions";
import * as actions from "./actions";
import { SEGMENT_TYPES } from "./constants";

export const initialState = {
  selectedSegment: SEGMENT_TYPES.private,
};

const changeSegment = (_, { payload: { segment } }) => ({
  selectedSegment: segment.id,
});

export default handleActions(
  {
    [actions.changeSegment]: changeSegment,
    [actions.prefillSegment]: changeSegment,
  },
  initialState
);
