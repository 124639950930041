// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * EmptyOffers
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import scrollToComponent from "react-scroll-to-component";
import {
  TEXT_SIZES,
  IconCancelCircle,
  Link,
} from "@dhl-official/react-ui-library";
import { getPageUrl, IntlPropType } from "root/libs/core-libs/src";

import {
  NoOffersWrapper,
  StyledIconWrapper,
  StyledContentWrapper,
  StyledHeadline,
  StyledText,
  StyledLinkWrapper,
} from "./styled";

const COPY_ID_PREFIX = "EmptyOffers";

const EmptyOffers = ({
  haveTheBackendsErrors,
  intl,
  copyNamespace,
  backToShipmentLaneReference,
  trackEvent,
  dataTracking,
}) => {
  const messageType = haveTheBackendsErrors ? "apiError" : "noOffers";

  function onButtonClick(e) {
    if (backToShipmentLaneReference.current !== null) {
      e.preventDefault();

      scrollToComponent(backToShipmentLaneReference.current, {
        offset: -100,
        align: "top",
      });

      const formInput = backToShipmentLaneReference.current.props.formReference.current.querySelector(
        "input"
      );

      if (formInput) {
        formInput.focus();
      }
    }
  }

  return (
    <NoOffersWrapper aria-live="polite" data-testid="no-offers-section">
      <StyledIconWrapper>
        <IconCancelCircle />
      </StyledIconWrapper>
      <StyledContentWrapper>
        <StyledHeadline tag="h2">
          {intl.formatMessage({
            id: `${copyNamespace}.${COPY_ID_PREFIX}.${messageType}Headline`,
          })}
        </StyledHeadline>
        <StyledText size={TEXT_SIZES.LARGE} weight={200} isParagraph>
          {intl.formatMessage({
            id: `${copyNamespace}.${COPY_ID_PREFIX}.${messageType}Text`,
          })}
        </StyledText>
        {!haveTheBackendsErrors && (
          <StyledLinkWrapper>
            <Link
              href={getPageUrl.url}
              withButtonStyle={{ variant: "secondary" }}
              dataTestid="noOfferNewQuoteLink"
              onClick={onButtonClick}
            >
              {intl.formatMessage({
                id: `${copyNamespace}.${COPY_ID_PREFIX}.${messageType}Link`,
              })}
            </Link>
            <Link
              href={intl.formatMessage({
                id: `${copyNamespace}.${COPY_ID_PREFIX}.noOffersBusinessLinkUrl`,
              })}
              withButtonStyle={{ variant: "primary" }}
              dataTestid="noOfferBusinessLink"
              dataTracking={dataTracking}
              onClick={() =>
                trackEvent({ clickedOnFrequentShipmentLink: true })
              }
            >
              {intl.formatMessage({
                id: `${copyNamespace}.${COPY_ID_PREFIX}.${messageType}BusinessLinkText`,
              })}
            </Link>
          </StyledLinkWrapper>
        )}
      </StyledContentWrapper>
    </NoOffersWrapper>
  );
};

EmptyOffers.propTypes = {
  intl: IntlPropType.isRequired,
  haveTheBackendsErrors: PropTypes.bool,
  /** Additional key to look up and display specific message variations */
  copyNamespace: PropTypes.string.isRequired,
  backToShipmentLaneReference: PropTypes.object,
  dataTracking: PropTypes.string.isRequired,
  trackEvent: PropTypes.func,
};

EmptyOffers.defaultProps = {
  haveTheBackendsErrors: false,
  backToShipmentLaneReference: React.createRef(),
  trackEvent: () => {},
};

export default injectIntl(EmptyOffers);
