// 9fbef606107a605d69c0edbcd8029e5d

module.exports = {
  apiBaseUrl: process.env.API_BASE_URL,
  gaqTranslationsBaseUrl: process.env.GAQ_TRANSLATIONS_BASE_URL,
  postalLocationBaseUrl: process.env.POSTAL_LOCATION_BASE_URL,
  quoteBaseUrl: process.env.GAQ_QUOTE_BASE_URL || process.env.API_BASE_URL,
  countrySettingsEndpoint: process.env.END_POINT_COUNTRY_CONFIG,
  freightP2PModalEndpoint: process.env.END_POINT_FREIGHT_P2P,
  locationAutocompleteEndpoint: process.env.END_POINT_LOCATION_AUTO_COMPLETE,
  postalCodePlaceholderEndpoint: process.env.END_POINT_POSTAL_CODE_PLACEHOLDER,
  quoteEndpoint: process.env.END_POINT_QUOTE,
};
