// 9fbef606107a605d69c0edbcd8029e5d

/*
 *
 * CanaryVersions actions
 *
 */

import { createAction } from "redux-actions";
import { ENABLE_CANARY_VERSION } from "./constants";

export const enableCanaryVersion = createAction(ENABLE_CANARY_VERSION);
