// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Textarea
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { IntlPropType } from "root/libs/core-libs/src";
import { Text } from "@dhl-official/react-ui-library";
import { key } from "styled-theme";
import checkIcon from "icons/cx/check.svg";
import Label from "../../Texts/Label";
import Icon from "../../Icons/Icon";
import FeedbackMessage from "../../Texts/FeedbackMessage";
import messages from "./messages";
import {
  StyledTextareaContainer,
  TextareaWrapper,
  StyledTextarea,
  StyledLabel,
  StyledSuccessIcon,
} from "./styled";

class Textarea extends React.Component {
  static propTypes = {
    /** Internationalization library */
    intl: IntlPropType.isRequired,
    /** Additional key to look up and display specific message variations */
    copyNamespace: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    /* Used to link the label and the Textarea. If not defined name will be used. */
    id: PropTypes.string,
    label: PropTypes.string,
    height: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    /** The label is allowed to be hidden only when the select can
     * have its own natural height. So when: matchFormFieldHeight = false.
     */
    visuallyHiddenLabel: PropTypes.bool,
    required: PropTypes.bool,
    dataTestid: PropTypes.string,
    maxWidth: PropTypes.string,
    feedback: PropTypes.shape({
      name: PropTypes.string,
      hasError: PropTypes.bool,
      isValid: PropTypes.bool,
      feedbackMessageId: PropTypes.string,
    }),
    interpolations: PropTypes.object,
    feedbackMessageIdPrefix: PropTypes.string,
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    bottomSpacing: PropTypes.string,
    reference: PropTypes.object,
  };

  static defaultProps = {
    dataTestid: undefined,
    label: "",
    id: "",
    onChange: () => {},
    onFocus: () => {},
    onKeyDown: () => {},
    maxWidth: "",
    required: false,
    disabled: false,
    visuallyHiddenLabel: false,
    interpolations: {},
    value: "",
    height: "102px", // Height of two standard input fields
    feedback: {
      name: "",
      hasError: false,
      isValid: false,
      feedbackMessageId: "",
    },
    feedbackMessageIdPrefix: "",
    bottomSpacing: null,
    reference: null,
  };

  onChange = (event) => {
    const { onChange } = this.props;
    onChange(event);
  };

  render() {
    const {
      name,
      id,
      label,
      disabled,
      visuallyHiddenLabel,
      maxWidth,
      dataTestid,
      height,
      feedback,
      interpolations,
      feedbackMessageIdPrefix,
      required,
      value,
      onFocus,
      bottomSpacing,
      onKeyDown,
      onChange,
      reference,
      intl,
      copyNamespace,
      ...extraProps
    } = this.props;

    const hasError = feedback.hasError ? feedback.hasError : false;
    const isValid = feedback.isValid ? feedback.isValid : false;
    const hasLabel = !visuallyHiddenLabel;

    const renderSuccess = value !== "" && isValid;

    let borderColor = "colors.globalBorderColor";
    if (!hasError && renderSuccess) {
      borderColor = "colors.successColor";
    } else if (hasError) {
      borderColor = "colors.errorColor";
    }

    const ariaAttributesTextarea = {};
    if (!hasLabel) {
      ariaAttributesTextarea["aria-label"] = label;
    }
    if (!hasError && renderSuccess) {
      ariaAttributesTextarea["aria-describedby"] = `success_${id || name}`;
    } else if (hasError) {
      ariaAttributesTextarea["aria-describedby"] = `errorMessage_${id || name}`;
      ariaAttributesTextarea["aria-invalid"] = true;
    }

    return (
      <StyledTextareaContainer
        marginBottom={bottomSpacing}
        gridArea={extraProps.cssGridArea}
      >
        <TextareaWrapper
          maxWidth={maxWidth}
          className="textarea"
          borderColor={borderColor}
        >
          {hasLabel && (
            <Label hasError={hasError} target={name} extraStyles={StyledLabel}>
              {label}
            </Label>
          )}
          {renderSuccess && (
            <React.Fragment>
              <Icon
                icon={checkIcon}
                size="small"
                useAs="decorative"
                extraStyles={StyledSuccessIcon}
                fillColor={key("colors.successColor")}
              />
              <Text className="visually-hidden" id={`success_${name}`}>
                {intl.formatMessage(
                  messages[
                    `${copyNamespace}_screenReaderLabel_successDescribedBy`
                  ]
                )}
              </Text>
            </React.Fragment>
          )}
          <StyledTextarea
            name={name}
            id={id || name}
            height={height}
            data-testid={dataTestid}
            onFocus={onFocus}
            onChange={this.onChange}
            hasError={hasError}
            required={required}
            value={value}
            disabled={disabled}
            className={hasError ? "invalid" : ""}
            onKeyDown={onKeyDown}
            ref={reference}
            preventTextAndIconOverlap={isValid}
            {...ariaAttributesTextarea}
            {...extraProps}
          />
        </TextareaWrapper>
        {!!hasError && (
          <FeedbackMessage
            id={id || name}
            feedback={feedback}
            interpolations={interpolations}
            feedbackMessageIdPrefix={feedbackMessageIdPrefix}
            copyNamespace={copyNamespace}
          />
        )}
      </StyledTextareaContainer>
    );
  }
}

export default injectIntl(Textarea);
