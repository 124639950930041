// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for BypassBlock
 *
 */

import styled from "styled-components";
import {
  focusedState,
  focusedFormControlStyle,
} from "root/libs/ui-styleguide/src";
import { key } from "styled-theme";
import { IconButton } from "root/libs/ui-components/src";

export const BypassBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const IconWrapper = styled(IconButton)`
  border: 1px solid ${key("colors.globalBorderColor")};
  width: ${(props) => (props.showCopyInside === true ? "auto" : "46px")};
  height: ${(props) => (props.showCopyInside === true ? "38px" : "46px")};
  padding-left: ${(props) => (props.showCopyInside === true ? "11px" : "1px")};
  padding-right: ${(props) => (props.showCopyInside === true ? "11px" : "1px")};
  ${focusedState.focus}

  svg {
    margin-right: ${(props) => (props.showCopyInside === true ? "10px" : 0)};
  }

  &:focus {
    text-decoration: underline;
    font-weight: 800;
  }

  &:hover {
    border: ${focusedFormControlStyle};
    padding-left: ${(props) => (props.showCopyInside === true ? "10px" : "0")};
    padding-right: ${(props) => (props.showCopyInside === true ? "10px" : "0")};
  }
`;
