// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * OfferAddOns
 *
 */

import React from "react";
import { IntlPropType, GetCountryCode } from "root/libs/core-libs/src";
import PropTypes from "prop-types";
import { selectTrackingKey } from "one-time-shipment/src/containers/Tracking/selectors";
import { showCurrencyAsCode } from "root/libs/constants/src";
import {
  AddOnsWrapper,
  AddOnsContent,
  OfferAddOnsContentExtraStyles,
  StyledHeadline,
  StyledAccordion,
} from "./styled";

const COPY_ID_PREFIX = "OfferAddOns";
const ADD_ON_MSG_COPY_ID_PREFIX = "AddOnsMessages";

export default class OfferAddOns extends React.PureComponent {
  static propTypes = {
    /** These are the addOns that can be added to an offer */
    addOns: PropTypes.array.isRequired,
    /** will combined with the add on KEY to create a unique ID for the accordion */
    offerKey: PropTypes.string,
    /** Translations library  */
    intl: IntlPropType.isRequired,
    trackEvent: PropTypes.func.isRequired,
    accordionFocusedIndex: PropTypes.number,
    showCurrencyIsoCodeForPrices: PropTypes.bool,
    nativeLanguageCode: PropTypes.string,
    formatCurrencyWithNativeLocale: PropTypes.bool,
    customHeadline: PropTypes.string,
    variationType: PropTypes.string,
  };

  static defaultProps = {
    offerKey: "",
    accordionFocusedIndex: -1,
    showCurrencyIsoCodeForPrices: false,
    nativeLanguageCode: "",
    formatCurrencyWithNativeLocale: false,
  };

  constructor(props) {
    super(props);
    this.trackingKeySelector = selectTrackingKey;
    this.addOnExploredKeyPath = this.trackingKeySelector(
      "spotShipment.interactions.offer.idExploredAddOn"
    );
  }

  getNameString = (addOn) => {
    let nameSufix = "";
    const {
      intl,
      showCurrencyIsoCodeForPrices,
      nativeLanguageCode,
      formatCurrencyWithNativeLocale,
    } = this.props;

    const countryCode = GetCountryCode(intl.locale);
    const priceFormatLocale = formatCurrencyWithNativeLocale
      ? `${nativeLanguageCode}-${countryCode}`
      : intl.locale;

    if (addOn.price) {
      const price =
        showCurrencyIsoCodeForPrices || addOn.price.currencyCode === ""
          ? `${addOn.price.currencyCode} ${addOn.price.amount}`
          : new Intl.NumberFormat(priceFormatLocale, {
              style: "currency",
              currency: addOn.price.currencyCode,
              currencyDisplay: showCurrencyAsCode.includes(
                addOn.price.currencyCode
              )
                ? "code"
                : "symbol",
            }).format(addOn.price.amount);
      nameSufix = `[+ ${price}]`;
    }

    return nameSufix;
  };

  getAddOnTranslation(addOn) {
    const { intl } = this.props;
    const name = intl.formatMessage({
      id: `${ADD_ON_MSG_COPY_ID_PREFIX}.${addOn.key}_name`,
    });
    const description = intl.formatMessage(
      {
        id: `${ADD_ON_MSG_COPY_ID_PREFIX}.${addOn.key}_description`,
      },
      {
        br: <br key="key" />,
        b: (chunks) => <strong>{chunks}</strong>,
        p: (chunks) => <p>{chunks}</p>,
      }
    );

    return {
      name,
      description,
    };
  }

  trackEvent = (addOnProps) => {
    const { trackEvent } = this.props;

    trackEvent({ idExploredAddOn: addOnProps.id });
  };

  render() {
    const {
      addOns,
      intl,
      accordionFocusedIndex,
      offerKey,
      customHeadline,
      variationType,
    } = this.props;

    const hasAddOns = !!addOns.length;

    let headlineString;

    if (customHeadline) {
      headlineString = intl.formatMessage({ id: customHeadline });
    } else if (hasAddOns) {
      headlineString = intl.formatMessage({ id: `${COPY_ID_PREFIX}.headline` });
    } else {
      headlineString = intl.formatMessage({ id: `${COPY_ID_PREFIX}.noAddOns` });
    }

    return !hasAddOns ? null : (
      <AddOnsWrapper variationType={variationType}>
        <AddOnsContent variationType={variationType}>
          <StyledHeadline priority={5} weight="700">
            {headlineString}
          </StyledHeadline>
          <StyledAccordion
            onTitleClick={this.trackEvent}
            focusedIndex={accordionFocusedIndex}
            variationType={variationType}
          >
            {addOns.map((addOn, index) => (
              <div key={addOn.key}>
                <StyledAccordion.Title
                  id={`${addOn.key}-${offerKey}`}
                  ariaControls={`${addOn.key}-${offerKey}-content`}
                  ariaLevel={6}
                  headlineFontWeight="400"
                  headlineString={`${
                    this.getAddOnTranslation(addOn).name
                  } ${this.getNameString(addOn)}`}
                  childIndex={index}
                  dataTracking={this.addOnExploredKeyPath}
                  trackingKeySelector={this.trackingKeySelector}
                />
                <StyledAccordion.Content
                  id={`${addOn.key}-${offerKey}-content`}
                  labeledBy={`${addOn.key}-${offerKey}`}
                  extraStyles={OfferAddOnsContentExtraStyles}
                >
                  <div tabIndex={0}>
                    {this.getAddOnTranslation(addOn).description}
                  </div>
                </StyledAccordion.Content>
              </div>
            ))}
          </StyledAccordion>
        </AddOnsContent>
      </AddOnsWrapper>
    );
  }
}
