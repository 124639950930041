// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * OfferSort
 *
 */

import React from "react";
import { IntlPropType } from "root/libs/core-libs/src";
import PropTypes from "prop-types";
import { DropdownField } from "@dhl-official/react-ui-library";
import styled, { css } from "styled-components";
import { offerSortingOptions } from "root/libs/constants/src";
import { biDimemsionalCell } from "root/libs/ui-components/src";
import { breakpoints } from "root/libs/ui-styleguide/src";

export const edgeBrowserSortingWrapper = css`
  /* Only for EDGE */
  /* stylelint-disable selector-type-no-unknown */
  _:-ms-lang(x),
  _:-webkit-full-screen,
  & {
    width: 40%;
  }
  /* stylelint-enable selector-type-no-unknown */
`;
export const SortingWrapper = styled.div`
  ${biDimemsionalCell}
  ${edgeBrowserSortingWrapper}

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    grid-column-end: span 4;
    grid-column-start: 5;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: block;
    flex-wrap: inherit;
    justify-content: inherit;
    align-items: inherit;
    grid-column: 5 / span 3;
  }

  @media print {
    display: none;
  }
`;
export const SortingSelectWrapper = styled.div`
  width: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: auto;
    min-width: 200px;
    margin-bottom: 20px;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-bottom: 25px;
  }
`;

const COPY_ID_PREFIX = "OffersLaneComponent";

const OfferSort = ({
  intl,
  onSortOffersChange,
  trackingKeyPaths,
  sortOffersBy,
  segment,
}) => {
  const getCopy = (id, values) => {
    return intl.formatMessage(
      {
        id: `${COPY_ID_PREFIX}.${id}`,
      },
      values
    );
  };

  const sortOptions = offerSortingOptions;

  return (
    <SortingWrapper
      gridColumn="5 / span 3"
      spanTablet="5"
      columnStartTablet="6"
    >
      <SortingSelectWrapper>
        <DropdownField
          id="sortBy"
          label={getCopy("sortBy")}
          onChange={(event) => onSortOffersChange(segment, event)}
          name="sortBy"
          value={sortOffersBy}
          dataTracking={trackingKeyPaths}
          isBlock
        >
          {sortOptions.map((o) => (
            <option key={o} value={o}>
              {getCopy(o)}
            </option>
          ))}
        </DropdownField>
      </SortingSelectWrapper>
    </SortingWrapper>
  );
};

OfferSort.propTypes = {
  /** Translations library */
  intl: IntlPropType.isRequired,
  sortOffersBy: PropTypes.string,
  onSortOffersChange: PropTypes.func,
  trackingKeyPaths: PropTypes.string,
  segment: PropTypes.string,
};

export default OfferSort;
