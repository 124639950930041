// 9fbef606107a605d69c0edbcd8029e5d

import { connect } from "react-redux";
import { selectTrackingKey } from "one-time-shipment/src/containers/Tracking/selectors";
import { sortOffers } from "one-time-shipment/src/containers/GetQuote/actions";
import { Tracking, CanaryVersions } from "root/libs/ui-containers/src";
import { trackContentInteraction } from "root/libs/core-libs/src";
import {
  UTF_EVENT_CONTENT_NAMES,
  UTF_EVENT_TYPES,
  UTF_EVENT_INTERACTIONS,
  UTF_EVENT_CONTENT_POSITION,
} from "root/libs/constants/src";
import OfferSort from "./index";

const {
  actions: { trackEvent },
} = Tracking;

const {
  selectors: { selectCanaryVersionEnabled, selectCanaryVersion },
} = CanaryVersions;

const TRACKING_KEY =
  "spotShipment.interactions.offersTriggers.sortingOrderChanged";

const DATA_LAYER_KEY = "sortingOrderChanged";

const mapStateToProps = (state) => ({
  trackingKeyPaths: selectTrackingKey(TRACKING_KEY),
  canaryEnabled: selectCanaryVersionEnabled(state),
  canaryVersion: selectCanaryVersion(state),
});

const mapDispatchToProps = (dispatch) => ({
  onSortOffersChange: (segment, elem) => {
    const { value } = elem.target;
    dispatch(sortOffers({ sortOffersBy: value }));
    dispatch(trackEvent({ [DATA_LAYER_KEY]: value }));

    trackContentInteraction({
      name: UTF_EVENT_CONTENT_NAMES.OFFER_SORT,
      type: UTF_EVENT_TYPES.DROPDOWN,
      interaction: UTF_EVENT_INTERACTIONS.CHANGE,
      position: UTF_EVENT_CONTENT_POSITION.PRODUCT_LANE,
      context: segment,
      attributes: {
        order: value,
      },
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OfferSort);
