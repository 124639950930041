// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for EstimatedDeliveryInfo
 *
 */

import styled from "styled-components";
import { key } from "styled-theme";
import { Text } from "@dhl-official/react-ui-library";
import { TYPES_OF_OFFERS } from "one-time-shipment/src/containers/GetQuote/constants";
import { breakpoints } from "root/libs/ui-styleguide/src";

export const DeliveryTimeText = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${key("colors.fontColor")} !important;

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-top: 0;
    padding-left: ${({ variation }) =>
      variation === TYPES_OF_OFFERS.WCC ? "" : "7px"};
    margin-left: ${({ variation }) =>
      variation === TYPES_OF_OFFERS.WCC ? "" : "7px"};
    border-left: 2px solid ${key("colors.lightBorderColor")};
  }
`;

export const DeliveryDateHeadline = styled(Text)`
  font-size: 2rem;
  line-height: 2.4rem;
  margin: 0;
  font-weight: 800;
  white-space: normal;
  color: ${key("colors.fontColor")} !important;

  @media screen and (min-width: ${breakpoints.tablet}) {
    max-width: 33.5rem;
    white-space: nowrap;
  }
`;

export const DateTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > p {
    white-space: nowrap;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    line-height: 24px;
    display: flex;
    margin-top: 5px;
    margin-bottom: 8px;
    justify-content: flex-start;
    align-items: ${({ variation }) =>
      variation === TYPES_OF_OFFERS.WCC ? "start" : "center"};
    flex-direction: ${({ variation }) =>
      variation === TYPES_OF_OFFERS.WCC ? "column" : "row"};
    flex-wrap: nowrap;
  }

  &.wrap-mobile {
    margin: 0;

    ${DeliveryTimeText} {
      @media screen and (min-width: ${breakpoints.desktop}) {
        border-left: 1px solid ${key("colors.fontColor")};
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.6rem;
      }
    }

    ${DeliveryDateHeadline} {
      @media screen and (min-width: ${breakpoints.desktop}) {
        font-weight: 200;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.6rem;
      }
    }
  }
`;
