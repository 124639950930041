// 9fbef606107a605d69c0edbcd8029e5d

/* eslint-disable no-param-reassign */

import { handleActions } from "redux-actions";
import { produce, current } from "immer";
import always from "lodash/fp/always";
import isNil from "lodash/isNil";
import {
  countryCodes,
  getCountriesWithoutZipCode,
  UTF_EVENT_CONTENT_POSITION,
} from "root/libs/constants/src";
import {
  validations,
  RemoteData,
  FormField,
  AutoComplete,
  normalizeValueForSearch,
} from "root/libs/core-libs/src";
import { countryAutocompleteFieldMessages } from "root/libs/ui-components/src";
import { TYPES_OF_ADDRESSES } from "one-time-shipment/src/containers/GetQuote/constants";
import escapeRegExp from "lodash/escapeRegExp";
import * as actions from "./actions";

export const INITIAL_STATE = {
  postalLocationCountries: RemoteData.notAsked(),
  wasDataLoadedfromUrl: false,
  countriesWithoutPostalCode: [],
  destination: {
    fields: {
      city: FormField.pristine(
        AutoComplete.unselected("", RemoteData.notAsked())
      ),
      country: FormField.pristine(AutoComplete.unselected("", [])),
      postalCode: undefined,
    },
    isValidCity: RemoteData.notAsked(),
    isValidPostalCode: RemoteData.notAsked(),
  },
  initialDocumentsOption: {
    private: false,
    business: false,
  },
  documentsOption: {
    private: false,
    business: false,
  },
  isDomesticShippingAvailable: {
    private: true,
    business: false,
  },
  fields: {
    destinationAddressType: FormField.valid(TYPES_OF_ADDRESSES.BUSINESS),
  },
  isLocationApiAvailable: true,
  isOriginDestinationFlipped: false,
  origin: {
    fields: {
      city: FormField.pristine(
        AutoComplete.unselected("", RemoteData.notAsked())
      ),
      country: FormField.pristine(AutoComplete.selected("US")),
      postalCode: undefined,
    },
    isValidCity: RemoteData.notAsked(),
    isValidPostalCode: RemoteData.notAsked(),
  },
  domesticShipmentException: false,
};

const setCountriesWithoutPostalCode = (state, { payload }) => ({
  ...state,
  countriesWithoutPostalCode: getCountriesWithoutZipCode(
    RemoteData.success(payload)
  ),
});

const setPostalLocationCountriesFromStorage = (state, { payload }) => ({
  ...state,
  postalLocationCountries: RemoteData.success(payload),
});

const loadPostalLocationCountries = (state, { payload }) => {
  const remotePostalLocationCountries = state.postalLocationCountries;

  if (
    remotePostalLocationCountries &&
    RemoteData.isSuccess(remotePostalLocationCountries)
  ) {
    return {
      ...state,
    };
  }

  return {
    ...state,
    postalLocationCountries: RemoteData.loading(payload),
  };
};

const loadPostalLocationCountriesSuccess = (state, { payload }) => ({
  ...state,
  postalLocationCountries: RemoteData.success(payload),
});

const loadPostalLocationCountriesFailure = (
  state,
  { payload: { message } }
) => {
  const remotePostalLocationCountries = state.postalLocationCountries;

  // Do we already have a success state (via loading the postal location countries from localstorage)?
  // Then take just that, use the localstorage postal location countries and ignore the failed request
  if (
    remotePostalLocationCountries &&
    RemoteData.isSuccess(remotePostalLocationCountries)
  ) {
    return {
      ...state,
    };
  }

  return {
    ...state,
    postalLocationCountries: RemoteData.error(message),
  };
};

const updateDestinationAddressType = (
  draft,
  { payload: destinationAddressType }
) => {
  draft.fields.destinationAddressType = FormField.valid(destinationAddressType);
};

const validateLocation = (draft, { payload: { target, value } }) => {
  draft[target].isValidCity = value;
  draft[target].isValidPostalCode = value;
};

const validateCity = (draft, { payload: { target, value } }) => {
  draft[target].isValidCity = value;
};

const validatePostalCode = (draft, { payload: { target, value } }) => {
  draft[target].isValidPostalCode = value;
};

const validateLocationFailure = (
  draft,
  { payload: { target, error, correlationId } }
) => {
  if (
    RemoteData.getCorrelationId(draft[target].isValidCity) === correlationId &&
    RemoteData.getCorrelationId(draft[target].isValidPostalCode) ===
      correlationId
  ) {
    draft.isLocationApiAvailable = false;
    draft[target].isValidCity = RemoteData.error(error, correlationId);
    draft[target].isValidPostalCode = RemoteData.error(error, correlationId);
  }
};

const validateLocationSuccess = (
  draft,
  { payload: { target, isValid, correlationId } }
) => {
  if (
    RemoteData.getCorrelationId(draft[target].isValidCity) === correlationId &&
    RemoteData.getCorrelationId(draft[target].isValidPostalCode) ===
      correlationId
  ) {
    draft.isLocationApiAvailable = true;
    draft[target].isValidCity = RemoteData.success(isValid, correlationId);
    draft[target].isValidPostalCode = RemoteData.success(
      isValid,
      correlationId
    );
  }
};

const validateCityFailure = (
  draft,
  { payload: { target, error, correlationId } }
) => {
  if (
    RemoteData.getCorrelationId(draft[target].isValidCity) === correlationId
  ) {
    draft.isLocationApiAvailable = false;
    draft[target].isValidCity = RemoteData.error(error, correlationId);
  }
};

const hasPostalCode = (state, target) =>
  Boolean(state[target].fields.postalCode);

const validateCitySuccess = (
  draft,
  { payload: { target, isValid, correlationId, isBusiness } }
) => {
  if (
    RemoteData.getCorrelationId(draft[target].isValidCity) === correlationId
  ) {
    draft.isLocationApiAvailable = true;
    draft[target].isValidCity = RemoteData.success(isValid, correlationId);

    if (isBusiness && !hasPostalCode(draft, target)) {
      draft[target].fields.city = FormField.chain(
        isValid
          ? FormField.valid
          : FormField.invalid({
              hasError: true,
              isValid,
              errorAriaDescribedBy: `errorMessage_location-${target}`,
            }),
        draft[target].fields.city
      );
    }
  }
};

const validatePostalCodeFailure = (
  draft,
  { payload: { target, error, correlationId } }
) => {
  if (
    RemoteData.getCorrelationId(draft[target].isValidPostalCode) ===
    correlationId
  ) {
    draft.isLocationApiAvailable = false;
    draft[target].isValidPostalCode = RemoteData.error(error, correlationId);
  }
};

const validatePostalCodeSuccess = (
  draft,
  { payload: { target, isValid, correlationId, isBusiness } }
) => {
  if (
    RemoteData.getCorrelationId(draft[target].isValidPostalCode) ===
    correlationId
  ) {
    draft.isLocationApiAvailable = true;
    draft[target].isValidPostalCode = RemoteData.success(
      isValid,
      correlationId
    );

    if (isBusiness) {
      draft[target].fields.postalCode = FormField.chain(
        isValid
          ? FormField.valid
          : FormField.invalid({
              hasError: true,
              isValid,
              errorAriaDescribedBy: `errorMessage_location-${target}`,
            }),
        draft[target].fields.postalCode
      );
    }
  }
};

const prepareFieldWithValidation = (formField, target) =>
  RemoteData.case({
    success: (isValid) =>
      FormField.chain(
        isValid
          ? FormField.valid
          : FormField.invalid({
              hasError: true,
              isValid,
              errorAriaDescribedBy: `errorMessage_location-${target}`,
            }),
        formField
      ),
    _: always(formField),
  });

const validateFormField = (
  draft,
  { payload: { element, target, isBusiness } }
) => {
  const {
    hasError,
    isValid,
    feedbackMessageId,
    name,
  } = validations.validateElement({
    ...element,
    isBusiness,
    utfPosition: UTF_EVENT_CONTENT_POSITION.TRADE_LANE,
    utfName: `${target}_${element.name}`,
  });

  const elementName = name || element.name;

  const feedback = {
    hasError,
    isValid,
    feedbackMessageId,
  };

  if (!elementName) {
    return;
  }

  /**
   * If there's no `target`. We assume the field is neither an `origin` nor `destination`
   * form field and is, therefore, in the main `fields` property from the reducer.
   */
  if (!target) {
    draft.fields[elementName] = FormField.chain(
      hasError ? FormField.invalid(feedback) : FormField.valid,
      draft.fields[elementName]
    );

    return;
  }

  /**
   * Updates the `origin` or `destination` field with the `static` validation.
   * The static validation is basically performed by the `validateElement` function
   * above and it checks the DOM node for attributes such as: `required`, `pattern`
   * and asserts if the `value` is valid.
   */
  draft[target].fields[elementName] = FormField.chain(
    hasError ? FormField.invalid(feedback) : FormField.valid,
    draft[target].fields[elementName]
  );

  /**
   * 🤷‍♀️ However, we have a special validation case for the business lane.
   */
  if (isBusiness && !hasError) {
    const getFieldWithValiation = prepareFieldWithValidation(
      draft[target].fields[elementName],
      target
    );

    /**
     * The `postalCode` field should not only perform the `static` validation, but
     * should also check another variable to see if the `postalCode` is
     * valid in our API.
     *
     * This is information is available in the `isValidPostalCode` property.
     */
    if (elementName.includes("postalCode")) {
      draft[target].fields.postalCode = getFieldWithValiation(
        draft[target].isValidPostalCode
      );
      return;
    }

    /**
     * The `city` field should not only perform the `static` validation, but
     * should also check another variable to see if the `city` is
     * valid in our API. We only need to perform this validation if there is
     * no `postalCode` in the reducer. Meaning that the selected country
     * doesn't required a `postalCode`, so we need to check the `city` as the
     * main validation point.
     *
     * This is information is available  in the `isValidCity` property.
     */
    if (elementName.includes("city") && !hasPostalCode(draft, target)) {
      draft[target].fields.city = getFieldWithValiation(
        draft[target].isValidCity
      );
    }
  }
};

const validateCountryField = (draft, { payload }) => {
  const {
    target,
    blacklistedDestinationCountries,
    warRestrictedZones,
    isDomesticShippingAvailable,
    marketCountry,
  } = payload;

  FormField.case(
    {
      // 1. In here we check if the country's form field didn't have any validity state yet (unchecked) (...)
      unchecked: AutoComplete.case({
        unselected: (value, options) => {
          // (...) 2. And we check the `auto-complete` options (suggested countries) that are in the field. (...)
          const selectedCountryCode = options.find(
            ({ label }) =>
              normalizeValueForSearch(label) === normalizeValueForSearch(value)
          );
          // (...) 3. If we find that the field has a typed value that is a valid suggested country we: (...)
          if (selectedCountryCode) {
            // (...) 4. Set it to valid and select the country.
            draft[target].fields.country = FormField.valid(
              AutoComplete.selected(selectedCountryCode.id, options)
            );
          }
          if (
            selectedCountryCode &&
            blacklistedDestinationCountries.includes(selectedCountryCode.id)
          ) {
            draft[target].fields.country = FormField.invalid(
              {},
              AutoComplete.selected(selectedCountryCode.id, options)
            );
          }
        },
      }),
      /* This logic is simply making valid country field selections back to invalid */
      valid: AutoComplete.case({
        selected: (value) => {
          if (warRestrictedZones.includes(value)) {
            draft[target].fields.country = FormField.invalid(
              {},
              AutoComplete.selected(value, {})
            );
          }
          if (
            !isDomesticShippingAvailable &&
            target === "destination" &&
            value === marketCountry
          ) {
            draft.domesticShipmentException = true;
          }
        },
      }),
    },
    draft[target].fields.country
  );

  // 1. If the previous validation didn't set the country to valid. (...)
  if (!FormField.isValid(draft[target].fields.country)) {
    // (...) 2. We basically run the default form validation.
    validateFormField(draft, { payload });
  }
};

const updateDocument = (draft, { payload }) => {
  const { value, segment } = payload;

  draft.documentsOption[segment] = value;
};

const resetDocumentOptionsValue = (draft) => {
  draft.documentsOption = draft.initialDocumentsOption;
};

const flipOriginDestination = (draft, { payload }) => {
  draft.isOriginDestinationFlipped = payload;
};

const countryHasPostalCode = (state, country) => {
  const countryList = state.countriesWithoutPostalCode;
  return !countryList.includes(country);
};

const queryLocalCountryAutoCompleteValue = (draft, { payload }) => {
  const {
    blackListedCountries,
    includeCountryCodes,
    intl,
    target,
    value,
  } = payload;

  const normalizedInput = normalizeValueForSearch(value);

  const options = countryCodes.reduce((acc, code) => {
    const label = intl.formatMessage(countryAutocompleteFieldMessages[code]);

    let labelToQuery;
    let isDisabled = false;
    let formattedLabel = label;

    if (blackListedCountries.includes(code)) {
      isDisabled = true;
      formattedLabel = `${label} ${intl.formatMessage(
        countryAutocompleteFieldMessages.shippingUnavailable
      )}`;
    }

    const countryNameRegex = new RegExp(
      `${escapeRegExp(`${normalizedInput}`)}.*`,
      "i"
    );

    if (intl.messages[`CountryAutocompleteField.${code}.synonyms`]) {
      const synonyms =
        intl.messages[`CountryAutocompleteField.${code}.synonyms`];
      labelToQuery = includeCountryCodes
        ? `${label} ${synonyms} ${code}`
        : `${label} ${synonyms}`;
    } else {
      labelToQuery = includeCountryCodes ? `${label} ${code}` : label;
    }

    if (normalizeValueForSearch(labelToQuery).match(countryNameRegex)) {
      return [
        ...acc,
        {
          disabled: isDisabled,
          id: code,
          label: formattedLabel,
        },
      ];
    }

    return acc;
  }, []);

  const selectedCountryCode = options.find(
    ({ label }) =>
      normalizeValueForSearch(label) === normalizedInput.toLowerCase()
  );

  draft[target].fields.city = FormField.pristine(
    AutoComplete.unselected("", RemoteData.notAsked())
  );

  draft[target].fields.postalCode =
    selectedCountryCode && countryHasPostalCode(draft, selectedCountryCode.id)
      ? FormField.pristine(AutoComplete.unselected("", RemoteData.notAsked()))
      : undefined;

  draft[target].fields.country =
    selectedCountryCode && !selectedCountryCode.disabled && options.length === 1
      ? FormField.valid(AutoComplete.selected(selectedCountryCode.id, options))
      : FormField.unchecked(AutoComplete.unselected(value, options));
};

const selectCountry = (draft, { payload }) => {
  const { target, value } = payload;

  draft[target].fields.country = FormField.valid(AutoComplete.selected(value));

  draft[target].fields.city = FormField.pristine(
    AutoComplete.unselected("", RemoteData.notAsked())
  );

  draft[target].fields.postalCode = countryHasPostalCode(draft, value)
    ? FormField.pristine(AutoComplete.unselected("", RemoteData.notAsked()))
    : undefined;
};

const setInitialCountry = (draft, { payload: country }) => {
  const currentPostalCode = draft.origin.fields.postalCode;

  draft.origin.fields.country = FormField.valid(AutoComplete.selected(country));
  draft.origin.fields.postalCode = countryHasPostalCode(draft, country)
    ? currentPostalCode ||
      FormField.pristine(AutoComplete.unselected("", RemoteData.notAsked()))
    : undefined;
};

const prefillInitialSet = (draft, { payload }) => {
  draft.wasDataLoadedfromUrl = true;
  draft.destination = payload.destination;

  // Check if destination country has postal code
  if (
    !countryHasPostalCode(draft, payload.destination.fields.country.value.value)
  ) {
    draft.destination.fields.postalCode = undefined;
  }

  draft.documentsOption = payload.documentsOption;
  draft.initialDocumentsOption = payload.documentsOption;
  draft.fields = payload.fields;
  draft.isOriginDestinationFlipped = payload.isOriginDestinationFlipped;
  draft.origin = payload.origin;
  draft.isDomesticShippingAvailable = payload.isDomesticShippingAvailable;

  // Check if origin country has postal code
  if (!countryHasPostalCode(draft, payload.origin.fields.country.value.value)) {
    draft.origin.fields.postalCode = undefined;
  }
};

const queryAutoCompleteValue = (field) => (draft, { payload }) => {
  const { value, target, correlationId } = payload;

  draft[target].fields[field] = FormField.map(
    always(AutoComplete.unselected(value, RemoteData.loading(correlationId))),
    draft[target].fields[field]
  );
};

const updateAutoCompleteValue = (field) => (
  draft,
  { payload: { value, target } }
) => {
  draft[target].fields[field] = FormField.unchecked(
    AutoComplete.unselected(value, RemoteData.notAsked())
  );
};

const getCurrentAutoCompleteCorrelationId = (state, target, field) => {
  const currentFormField = state[target].fields[field];
  const currentAutoComplete = FormField.getValue(currentFormField);

  return AutoComplete.case(
    {
      unselected: (_, options) => RemoteData.getCorrelationId(options),
      _: always(undefined),
    },
    currentAutoComplete
  );
};

const queryAutoCompleteValueSuccess = (field) => (draft, { payload }) => {
  const { data, value, target, correlationId } = payload;
  const currentCorrelationId = getCurrentAutoCompleteCorrelationId(
    draft,
    target,
    field
  );

  if (currentCorrelationId === correlationId) {
    draft.isLocationApiAvailable = true;
    draft[target].fields[field] = FormField.map(
      always(
        AutoComplete.unselected(value, RemoteData.success(data, correlationId))
      ),
      draft[target].fields[field]
    );
  }
};

const queryAutoCompleteValueFailure = (field) => (draft, { payload }) => {
  const { error, value, target, correlationId } = payload;
  const currentCorrelationId = getCurrentAutoCompleteCorrelationId(
    draft,
    target,
    field
  );

  if (currentCorrelationId === correlationId) {
    draft.isLocationApiAvailable = false;
    draft[target].fields[field] = FormField.unchecked(
      AutoComplete.unselected(value, RemoteData.error(error))
    );
  }
};

const selectAutoCompleteValue = (field) => (draft, { payload }) => {
  const { value, target } = payload;

  draft[target].fields[field] = FormField.valid(
    AutoComplete.selected(value, RemoteData.notAsked())
  );
};

const uncheckInvalidFields = (fields) =>
  Object.entries(fields).reduce((acc, [key, value]) => {
    const isInvalidField = value && FormField.isInvalid(value);

    return {
      ...acc,
      [key]: isInvalidField
        ? FormField.chain(FormField.unchecked, value)
        : value,
    };
  }, {});

const resetInvalidFields = (draft) => {
  draft.origin.fields = uncheckInvalidFields(draft.origin.fields);
  draft.destination.fields = uncheckInvalidFields(draft.destination.fields);
};

const resetSavedQuote = (draft) => {
  draft.wasDataLoadedfromUrl = false;
};

const resetDestinationFields = (draft) => {
  const origin = current(draft.origin.fields.country.value);
  const destination = current(draft.destination.fields.country.value);
  if (origin.value === destination.value) {
    draft.destination = INITIAL_STATE.destination;
  }
};

// If `documentsOption` has initial value as true
// but user changed to false and shared quote,
// then we check if data was loaded from URL / hash
// if not, then we set according to config,
// otherwise default to saved value in the Trade Lane
const setDocumentsValue = (draft, { payload: value }) => {
  if (!isNil(value) && !draft?.wasDataLoadedfromUrl) {
    draft.documentsOption = value;
    draft.initialDocumentsOption = value;
  }
};

const setDomesticShippingAvailability = (draft, { payload: value }) => {
  draft.isDomesticShippingAvailable = value;
};

const queryPostalCodePlaceholderSucceeded = (draft, { payload }) => {
  const { data, target } = payload;
  if (hasPostalCode(draft, target)) {
    const countryCode = draft[target].fields.country.value?.value;
    const placeholder = data.find((c) => c.countryCode === countryCode)
      ?.recommendedPostcodeFormat;
    draft[target].fields.postalCode.placeholder = placeholder;
  }
};

const toggleDomesticShipmentException = (draft) => {
  draft.domesticShipmentException = !draft.domesticShipmentException;
  resetDestinationFields(draft);
};

export default handleActions(
  {
    [actions.loadPostalLocationCountries]: loadPostalLocationCountries,
    [actions.loadPostalLocationCountriesFailure]: loadPostalLocationCountriesFailure,
    [actions.loadPostalLocationCountriesSuccess]: loadPostalLocationCountriesSuccess,
    [actions.setPostalLocationCountriesFromStorage]: setPostalLocationCountriesFromStorage,
    [actions.flipOriginDestination]: produce(flipOriginDestination),
    [actions.prefillInitialSet]: produce(prefillInitialSet),
    [actions.prefillTradeLaneFromUrl]: produce(prefillInitialSet),
    [actions.queryCity]: produce(queryAutoCompleteValue("city")),
    [actions.queryCityFailure]: produce(queryAutoCompleteValueFailure("city")),
    [actions.queryCitySuccess]: produce(queryAutoCompleteValueSuccess("city")),
    [actions.queryCountry]: produce(queryLocalCountryAutoCompleteValue),
    [actions.queryPostalCode]: produce(queryAutoCompleteValue("postalCode")),
    [actions.queryPostalCodeFailure]: produce(
      queryAutoCompleteValueFailure("postalCode")
    ),
    [actions.queryPostalCodeSuccess]: produce(
      queryAutoCompleteValueSuccess("postalCode")
    ),
    [actions.resetInvalidFields]: produce(resetInvalidFields),
    [actions.resetDestinationFields]: produce(resetDestinationFields),
    [actions.resetDocumentOptionsValue]: produce(resetDocumentOptionsValue),
    [actions.resetSavedQuote]: produce(resetSavedQuote),
    [actions.selectCity]: produce(selectAutoCompleteValue("city")),
    [actions.selectCountry]: produce(selectCountry),
    [actions.selectPostalCode]: produce(selectAutoCompleteValue("postalCode")),
    [actions.setDocumentsValue]: produce(setDocumentsValue),
    [actions.setDomesticShippingAvailability]: produce(
      setDomesticShippingAvailability
    ),
    [actions.setInitialCountry]: produce(setInitialCountry),
    [actions.updateCity]: produce(updateAutoCompleteValue("city")),
    [actions.updateDestinationAddressType]: produce(
      updateDestinationAddressType
    ),
    [actions.updateDocument]: produce(updateDocument),
    [actions.updatePostalCode]: produce(updateAutoCompleteValue("postalCode")),
    [actions.validateCity]: produce(validateCity),
    [actions.validateCityFailure]: produce(validateCityFailure),
    [actions.validateCitySuccess]: produce(validateCitySuccess),
    [actions.validateCountryField]: produce(validateCountryField),
    [actions.validateFormField]: produce(validateFormField),
    [actions.validateLocation]: produce(validateLocation),
    [actions.validateLocationFailure]: produce(validateLocationFailure),
    [actions.validateLocationSuccess]: produce(validateLocationSuccess),
    [actions.validatePostalCode]: produce(validatePostalCode),
    [actions.validatePostalCodeFailure]: produce(validatePostalCodeFailure),
    [actions.validatePostalCodeSuccess]: produce(validatePostalCodeSuccess),
    [actions.queryPostalCodePlaceholderSucceeded]: produce(
      queryPostalCodePlaceholderSucceeded
    ),
    [actions.toggleDomesticShipmentException]: produce(
      toggleDomesticShipmentException
    ),
    [actions.setCountriesWithoutPostalCode]: produce(
      setCountriesWithoutPostalCode
    ),
  },
  INITIAL_STATE
);
