// 9fbef606107a605d69c0edbcd8029e5d

import styled from "styled-components";
import { breakpoints, grid } from "root/libs/ui-styleguide/src";

export const ScrollFlexWrapper = styled.div`
  display: flex;
  padding-top: 10px;
  padding-bottom: 0;
  padding-left: 3px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-color: transparent transparent;
    -webkit-overflow-scrolling: touch;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-bottom: 10px;
  }

  ::-webkit-scrollbar {
    background: transparent;
    display: none;
  }
`;

export const PresetSizeCardGroupWrapper = styled.div`
  flex: 0 0 auto;
  padding: 4px ${grid.columns.width.s} 17px ${grid.columns.width.s};
  flex-wrap: wrap;
  width: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-left: 0;
    flex-wrap: inherit;
    width: inherit;
    padding: 4px 0;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-right: 0;
    flex: unset;
    padding: 0;
  }
`;

export const GradientWrapper = styled.div`
  position: relative;
  width: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    &::after {
      content: "";
      position: absolute;
      height: 100%;
      top: 0;
      right: 0;
      pointer-events: none;
      z-index: 1;
      width: 80px;
      background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1) 60%,
        rgba(255, 255, 255, 1)
      );
    }
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    &::after {
      display: none;
    }
  }

  @media print {
    display: none;
  }
`;
