// 9fbef606107a605d69c0edbcd8029e5d

import styled from "styled-components";
import { breakpoints, grid } from "root/libs/ui-styleguide/src";
import { Headline } from "root/libs/ui-components/src";

export const QuantityContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 20px 0 0;
  margin-left: ${grid.container.paddings.s};
  margin-right: ${grid.container.paddings.s};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-bottom: 0;
    padding-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  @supports (display: grid) {
    grid-row: weightRow;
    grid-column: 1 / span 12;

    @media screen and (min-width: ${breakpoints.tablet}) {
      grid-row: dimensionsRow;
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      grid-area: quantityCol;
    }
  }

  @media print {
    width: 30%;
  }
`;

export const StyledHeadline = styled(Headline)`
  margin-bottom: 12px;
`;

export const QuantityInputWrapper = styled.div`
  max-width: 100%;
  margin-bottom: ${(props) => (props.hasError ? 0 : "10px")};

  @media screen and (min-width: ${breakpoints.tablet}) {
    max-width: 120px;
  }
`;
