// 9fbef606107a605d69c0edbcd8029e5d

const getCurrentTimestamp = () => new Date().getTime();

// was getNow
function getNowIsoString() {
  return new Date().toISOString();
}

/**
 * This function makes use of the web Navigation Timing API
 * https://developer.mozilla.org/en-US/docs/Web/API/Navigation_timing_API
 */

function toMiliseconds(timestamp) {
  return new Date(timestamp).getMilliseconds();
}

export default {
  getCurrentTimestamp,
  toMiliseconds,
  getNowIsoString,
};
