// 9fbef606107a605d69c0edbcd8029e5d

import { fork, takeLatest, all, select, put } from "redux-saga/effects";
import memoize from "lodash/memoize";
import { v4 as uuidv4 } from "uuid";
import {
  resetInvalidFields as resetInvalidTradeLaneFields,
  resetDestinationFields,
  flipOriginDestination,
} from "one-time-shipment/src/containers/TradeLane/actions";
import {
  prefillInitialSet as prefillShipmentLaneInitialSet,
  updateValidity,
  hideShipmentLane,
} from "one-time-shipment/src/containers/ShipmentLane/actions";
import { hideOffersLane } from "one-time-shipment/src/containers/OffersLane/actions";
import {
  makeBusinessShipmentLaneInitialSetSelection,
  makeValiditySelection,
} from "one-time-shipment/src/containers/ShipmentLane/selectors";
import { CountrySettings } from "root/libs/ui-containers/src";
import { SEGMENT_TYPES, CHANGE_SEGMENT } from "./constants";

const {
  selectors: { selectIsDomesticShippingAvailable },
} = CountrySettings;

const resetValidityForItems = memoize((items) =>
  items.map(() => ({
    id: uuidv4(),
    weight: {},
    height: {},
    length: {},
    width: {},
    quantity: {},
  }))
);

const resetDimensionsForAllItems = memoize((items) =>
  items.map((item) => ({
    ...item,
    presetSize: "",
    weight: "",
    width: "",
    height: "",
    length: "",
    quantity: 1,
    settingsNonStackable: false,
  }))
);

const resetPresetSizeSelectionForAllItems = memoize((items) =>
  items.map((item) => ({
    ...item,
    presetSize: "",
  }))
);

export function* resetOldSegmentData({ payload: { segment } }) {
  const { items } = yield select(makeBusinessShipmentLaneInitialSetSelection());
  const validity = yield select(makeValiditySelection());
  const isDomesticShippingAvailable = yield select(
    selectIsDomesticShippingAvailable
  );

  /*
    As the valid values in business funnel may not be valid in private
    we reset the dimensions in all items when moving from business to private.
  */
  const itemsToPrefill =
    segment.id === SEGMENT_TYPES.private
      ? resetDimensionsForAllItems(items)
      : resetPresetSizeSelectionForAllItems(items);

  yield put(hideOffersLane());
  yield put(hideShipmentLane());
  yield put(resetInvalidTradeLaneFields());

  if (segment.id === SEGMENT_TYPES.private) {
    // Reset trade lane when switching from Business to Private segment
    yield put(flipOriginDestination(false));
  }

  // Adding an optional chaining for this selector because if this configuration
  // is null in the OTS config, it will break the app when the user switches from
  // private to business
  if (
    isDomesticShippingAvailable &&
    Object.prototype.hasOwnProperty.call(
      isDomesticShippingAvailable,
      segment.id
    ) &&
    !isDomesticShippingAvailable[segment.id]
  ) {
    yield put(resetDestinationFields());
  }

  yield put(prefillShipmentLaneInitialSet({ items: itemsToPrefill }));

  if (segment.id === SEGMENT_TYPES.private) {
    /*
      Business funnel have different validation rules than private,
      so we reset validation state when switching the segments.
    */
    yield put(
      updateValidity({
        validity: {
          items: resetValidityForItems(validity.items),
        },
      })
    );
  }
}

export function* watchChangeSegment() {
  yield takeLatest(CHANGE_SEGMENT, resetOldSegmentData);
}

export default function* sagas() {
  yield all([fork(watchChangeSegment)]);
}
