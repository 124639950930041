// 9fbef606107a605d69c0edbcd8029e5d

import React from "react";
import { injectIntl } from "react-intl";
import { Text, TEXT_SIZES } from "@dhl-official/react-ui-library";
import { selectTrackingKey } from "one-time-shipment/src/containers/Tracking/selectors";
import AddressTypeCheckbox from "one-time-shipment/src/components/Forms/AddressTypeCheckbox";
import { SEGMENT_TYPES } from "one-time-shipment/src/containers/SpotShipmentPage/constants";
import TeaserBusinessAssistant from "one-time-shipment/src/components/Teaser/TeaserBusinessAssistant";
import {
  validations,
  getPerformanceNow,
  dates,
  IntlPropType,
  AutoComplete,
  FormField,
  RemoteDataTypes,
  trackEngagementInteraction,
  RemoteData,
} from "root/libs/core-libs/src";
import {
  SS,
  countryCodes,
  UTF_EVENT_PAGE_NAMES,
  UTF_EVENT_CONTENT_NAMES,
  UTF_EVENT_INTERACTIONS,
} from "root/libs/constants/src";
import {
  ErrorBoundary,
  FeedbackMessage,
  Form,
  countryAutocompleteFieldMessages,
} from "root/libs/ui-components/src";
import LocationSelector from "one-time-shipment/src/components/Location/LocationSelector";
import DomesticShipmentNotAllowed from "one-time-shipment/src/components/Modals/DomesticShipmentNotAllowed";
import PropTypes from "prop-types";
import SkeletonOr from "one-time-shipment/src/containers/SkeletonOr";
import { CountrySettings } from "root/libs/ui-containers/src";
import { TYPES_OF_ADDRESSES } from "one-time-shipment/src/containers/GetQuote/constants";
import validationRules from "./validation-rules";
import {
  FeedbackMessageContainer,
  OriginContainer,
  DestinationContainer,
  DirectionParagraph,
  DirectionHeadlineWrapper,
  TradeLaneWrapper,
  TradeLaneFormContentWrapper,
  TradeLaneImportExportToggleWrapper,
  ToggleTextAndIcon,
  ToggleField,
} from "./styled";

const { withCountry } = CountrySettings;

const AddressTypeCheckboxWithCountry = withCountry(AddressTypeCheckbox);
const LocationSelectorWithCountry = withCountry(LocationSelector);

const DELAY = 1600;

const TARGETS = {
  ORIGIN: "origin",
  DESTINATION: "destination",
};

const FIELDS = {
  ORIGIN_COUNTRY: "originCountry",
  ORIGIN_CITY: "originCity",
  ORIGIN_POSTAL_CODE: "originZip",
  DESTINATION_COUNTRY: "destinationCountry",
  DESTINATION_CITY: "destinationCity",
  DESTINATION_POSTAL_CODE: "destinationZip",
  DESTINATION_ADDRESS_TYPE: "receiverAddressType",
};

const FIELD_TYPES = {
  CITY: "city",
  COUNTRY: "country",
  POSTAL_CODE: "postalCode",
  DESTINATION_ADDRESS: "destinationAddressType",
};

const getElementMetadata = (name) => {
  switch (name) {
    case FIELDS.ORIGIN_COUNTRY:
      return [TARGETS.ORIGIN, FIELD_TYPES.COUNTRY];

    case FIELDS.ORIGIN_CITY:
      return [TARGETS.ORIGIN, FIELD_TYPES.CITY];

    case FIELDS.ORIGIN_POSTAL_CODE:
      return [TARGETS.ORIGIN, FIELD_TYPES.POSTAL_CODE];

    case FIELDS.DESTINATION_COUNTRY:
      return [TARGETS.DESTINATION, FIELD_TYPES.COUNTRY];

    case FIELDS.DESTINATION_CITY:
      return [TARGETS.DESTINATION, FIELD_TYPES.CITY];

    case FIELDS.DESTINATION_POSTAL_CODE:
      return [TARGETS.DESTINATION, FIELD_TYPES.POSTAL_CODE];

    case FIELDS.DESTINATION_ADDRESS_TYPE:
      return [undefined, FIELD_TYPES.DESTINATION_ADDRESS];

    default:
      return [undefined, name];
  }
};

const getImportExportLabel = (
  intl,
  originCountry,
  destinationCountry,
  isOriginDestinationFlipped,
  localMessageObj
) => {
  const getIntlMessage = (country, message) => {
    return intl.formatMessage(message, {
      country: intl.formatMessage(countryAutocompleteFieldMessages[country]),
    });
  };

  if (isOriginDestinationFlipped) {
    return AutoComplete.case(
      {
        selected: (country) =>
          getIntlMessage(country, localMessageObj.flipButtonExportAriaLabel),
        unselected: () => "",
      },
      destinationCountry
    );
  }

  return AutoComplete.case(
    {
      selected: (country) =>
        getIntlMessage(country, localMessageObj.flipButtonImportAriaLabel),
      unselected: () => "",
    },
    originCountry
  );
};

const COPY_ID_PREFIX = "tradeLaneComponent";

class TradeLaneComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.delay = null;
    this.isTradeLaneAlreadyTracked = false;
    this.trackingKeySelector = selectTrackingKey;
    this.importExportSwitchTrackingKeyPath = this.trackingKeySelector(
      "spotShipment.interactions.clickedOnImportExportSwitch"
    );
  }

  componentDidMount() {
    const { trackEvent, selectedSegment, pageName } = this.props;
    const { TRADE_LANE } = UTF_EVENT_PAGE_NAMES;

    this.delay = setTimeout(() => {
      if (!this.isTradeLaneAlreadyTracked) {
        trackEngagementInteraction({
          funnel: {
            name: pageName,
            type: UTF_EVENT_CONTENT_NAMES.CUSTOMER_JOURNEY,
            step: TRADE_LANE,
            progress: UTF_EVENT_INTERACTIONS.LOAD,
          },
          content: {
            context: selectedSegment,
          },
        });
        this.isTradeLaneAlreadyTracked = true;
      }
    }, DELAY);

    trackEvent({
      uiMetrics: {
        firstMeaningfulPaint: getPerformanceNow(),
      },
      quoteStarted: dates.getNowIsoString(),
    });
  }

  componentDidUpdate = (prevProps) => {
    const { selectedSegment, pageName } = this.props;
    const { TRADE_LANE } = UTF_EVENT_PAGE_NAMES;

    // When we load an experience, we're doing a second request to the OTS Config
    // Therefore we need to "cancel" the trackEngagementInteraction from the componentDidMount()
    // The boolean isTradeLaneAlreadyTracked will prevent it to be called more than twice
    if (
      selectedSegment !== prevProps.selectedSegment &&
      !this.isTradeLaneAlreadyTracked
    ) {
      clearTimeout(this.delay);
      trackEngagementInteraction({
        funnel: {
          name: pageName,
          type: UTF_EVENT_CONTENT_NAMES.CUSTOMER_JOURNEY,
          step: TRADE_LANE,
          progress: UTF_EVENT_INTERACTIONS.LOAD,
        },
        content: {
          context: selectedSegment,
        },
      });
      this.isTradeLaneAlreadyTracked = true;
    }
  };

  onBlur = (event) => {
    const {
      validateLocation,
      isOriginCityValid,
      isOriginPostalCodeValid,
      isDestinationCityValid,
      isDestinationPostalCodeValid,
    } = this.props;
    const { target } = event;

    // Validate the current target element
    this.validateElement(target);

    // Helper function to validate location based on field validity
    const validateIfNeeded = (isValid, targetType) => {
      if (!RemoteData.withDefault(false, isValid)) {
        validateLocation(targetType);
      }
    };

    const ORIGIN_FIELDS = {
      [FIELDS.ORIGIN_COUNTRY]: isOriginCityValid,
      [FIELDS.ORIGIN_CITY]: isOriginCityValid,
      [FIELDS.ORIGIN_POSTAL_CODE]: isOriginPostalCodeValid,
    };

    const DESTINATION_FIELDS = {
      [FIELDS.DESTINATION_COUNTRY]: isDestinationCityValid,
      [FIELDS.DESTINATION_CITY]: isDestinationCityValid,
      [FIELDS.DESTINATION_POSTAL_CODE]: isDestinationPostalCodeValid,
    };

    // Validate origin fields if target is an origin field
    if (target.name in ORIGIN_FIELDS) {
      validateIfNeeded(ORIGIN_FIELDS[target.name], TARGETS.ORIGIN);
    }

    // Validate destination fields if target is a destination field
    else if (target.name in DESTINATION_FIELDS) {
      validateIfNeeded(DESTINATION_FIELDS[target.name], TARGETS.DESTINATION);
    }
  };

  queryAutocomplete = (event) => {
    const {
      intl,
      queryCountry,
      updateCity,
      updatePostalCode,
      blacklistedDestinationCountries,
      countryAutocompleteIncludeCountryCodes,
      isDomesticShippingAvailable,
      selectedSegment,
      hideShipmentLane,
    } = this.props;

    const { value, name } = event.target;

    const handleCountryQuery = (target) => {
      // In this case, it makes sense to keep old key name domesticShippingNotAvailable
      queryCountry({
        target,
        value,
        intl,
        blackListedCountries: blacklistedDestinationCountries,
        includeCountryCodes: countryAutocompleteIncludeCountryCodes,
        domesticShippingNotAvailable: !isDomesticShippingAvailable[
          selectedSegment
        ],
      });
    };

    const handleCityUpdate = (target) => {
      updateCity({ target, value });
    };

    const handlePostalCodeUpdate = (target) => {
      updatePostalCode({ target, value });
    };

    switch (name) {
      case FIELDS.ORIGIN_COUNTRY:
        handleCountryQuery(TARGETS.ORIGIN);
        break;

      case FIELDS.ORIGIN_CITY:
        handleCityUpdate(TARGETS.ORIGIN);
        break;

      case FIELDS.ORIGIN_POSTAL_CODE:
        handlePostalCodeUpdate(TARGETS.ORIGIN);
        break;

      case FIELDS.DESTINATION_COUNTRY:
        handleCountryQuery(TARGETS.DESTINATION);
        hideShipmentLane(); // DPCJG-7300 - Hide segments after destination change
        break;

      case FIELDS.DESTINATION_CITY:
        handleCityUpdate(TARGETS.DESTINATION);
        break;

      case FIELDS.DESTINATION_POSTAL_CODE:
        handlePostalCodeUpdate(TARGETS.DESTINATION);
        break;

      default:
        break;
    }
  };

  onAutocompleteSelect = (item) => {
    const { selectCity, selectCountry, selectPostalCode } = this.props;

    const { value, name } = item;

    switch (name) {
      case FIELDS.ORIGIN_COUNTRY: {
        selectCountry({ target: TARGETS.ORIGIN, value });
        break;
      }

      case FIELDS.ORIGIN_CITY: {
        selectCity({ target: TARGETS.ORIGIN, value });
        break;
      }

      case FIELDS.ORIGIN_POSTAL_CODE: {
        selectPostalCode({ target: TARGETS.ORIGIN, value });
        break;
      }

      case FIELDS.DESTINATION_COUNTRY: {
        selectCountry({ target: TARGETS.DESTINATION, value });
        break;
      }

      case FIELDS.DESTINATION_CITY: {
        selectCity({ target: TARGETS.DESTINATION, value });
        break;
      }

      case FIELDS.DESTINATION_POSTAL_CODE: {
        selectPostalCode({ target: TARGETS.DESTINATION, value });
        break;
      }

      default:
        break;
    }
  };

  validateFormField = (element) => {
    const {
      country,
      validateFormField,
      validateCountryField,
      isBusiness,
      blacklistedDestinationCountries,
      warRestrictedZones,
      isDomesticShippingAvailable,
      selectedSegment,
    } = this.props;
    const [target, name] = getElementMetadata(element.name);
    const elementWithName = {
      ...element,
      name,
    };
    const payload = {
      element: elementWithName,
      isBusiness,
      target,
      blacklistedDestinationCountries,
      warRestrictedZones,
      isDomesticShippingAvailable: isDomesticShippingAvailable[selectedSegment],
      marketCountry: country,
    };

    switch (name) {
      case FIELD_TYPES.COUNTRY: {
        validateCountryField(payload);
        break;
      }

      default: {
        validateFormField(payload);
        break;
      }
    }
  };

  validateElement = (input) => {
    this.validateFormField(validations.extractValidationProperties(input));
  };

  updateDestinationAddressType = (event) => {
    const { checked } = event.target;
    const { updateDestinationAddressType } = this.props;

    updateDestinationAddressType(
      checked ? TYPES_OF_ADDRESSES.RESIDENTIAL : TYPES_OF_ADDRESSES.BUSINESS
    );
  };

  flipOriginAndDestination = (event) => {
    const {
      flipOriginDestination,
      isOriginDestinationFlipped,
      trackEvent,
    } = this.props;

    event.preventDefault();

    // Mind we are checking the old value before the state update here, hence the flipped result values
    const currentShipmentDirection = isOriginDestinationFlipped
      ? "EXPORT"
      : "IMPORT";
    trackEvent({ clickedOnImportExportSwitch: currentShipmentDirection });

    flipOriginDestination(!isOriginDestinationFlipped);
  };

  toggleOriginAndDestination = () => {
    const {
      flipOriginDestination,
      isOriginDestinationFlipped,
      trackEvent,
    } = this.props;

    // Mind we are checking the old value before the state update here, hence the flipped result values
    const currentShipmentDirection = isOriginDestinationFlipped
      ? "EXPORT"
      : "IMPORT";
    trackEvent({ clickedOnImportExportSwitch: currentShipmentDirection });

    flipOriginDestination(!isOriginDestinationFlipped);
  };

  getCopy = (id) => {
    const { intl } = this.props;
    return intl.formatMessage({ id });
  };

  wrongCountryClickHandler = () => {
    const { trackEvent } = this.props;
    trackEvent({ wrongCountry: true });
  };

  render() {
    const {
      abTestRunning,
      backToTradeLaneReference,
      blacklistedDestinationCountries,
      warRestrictedZones,
      buttonDataTrackingKeyPath,
      destinationAddressType,
      destinationFields,
      displayCountrySuggestionOnFocus,
      intl,
      isBusiness,
      isDestinationCityValid,
      isDestinationPostalCodeValid,
      isFormValid,
      isLocationApiAvailable,
      isOriginCityValid,
      isOriginDestinationFlipped,
      isOriginPostalCodeValid,
      originFields,
      enableImportExportFeature,
      showShipmentLane,
      showTradeLaneBusinessShipmentAssistant,
      trackEvent,
      validateLocation,
      wrongCountryLinkPath,
      showOriginBillingLink,
      isDomesticShipmentException,
      toggleDomesticShipmentException,
      isDomesticShippingAvailable,
      countriesWithoutPostalCode,
      selectedSegment,
    } = this.props;

    const messages = {
      originHeadline: this.getCopy(`${COPY_ID_PREFIX}.originHeadline`),
      destinationHeadline: this.getCopy(
        `${COPY_ID_PREFIX}.destinationHeadline`
      ),
      countryLabel: this.getCopy(`${COPY_ID_PREFIX}.countryLabel`),
      countryEmptyOptionLabel: this.getCopy(
        `${COPY_ID_PREFIX}.countryEmptyOptionLabel`
      ),
      regionLabel: `${this.getCopy(`${COPY_ID_PREFIX}.regionLabel`)} ${
        !isBusiness ? this.getCopy(`${COPY_ID_PREFIX}.regionLabelExtend`) : ""
      }`,
      zipLabel: `${this.getCopy(`${COPY_ID_PREFIX}.zipLabel`)} ${
        !isBusiness ? this.getCopy(`${COPY_ID_PREFIX}.zipLabelExtend`) : ""
      }`,
      zipLabelErrorMessage: this.getCopy(
        `${COPY_ID_PREFIX}.zipLabelErrorMessage`
      ),
      regionLabelErrorMessage: this.getCopy(
        `${COPY_ID_PREFIX}.regionLabelErrorMessage`
      ),
      showShipment: this.getCopy(`${COPY_ID_PREFIX}.showShipment`),
      withVatNumber: this.getCopy(`${COPY_ID_PREFIX}.withVatNumber`),
      businessRecipient: this.getCopy(`${COPY_ID_PREFIX}.businessRecipient`),
      tooltipLabel: this.getCopy(`${COPY_ID_PREFIX}.tooltipLabel`),
      tooltipImportToggleLabel: this.getCopy(
        `${COPY_ID_PREFIX}.tooltipImportToggleLabel`
      ),
      tooltipImportToggleHeading: this.getCopy(
        `${COPY_ID_PREFIX}.${
          isBusiness
            ? "tooltipImportToggleHeadingBusiness"
            : "tooltipImportToggleHeadingPrivate"
        }`
      ),
      tooltipIconAriaLabel: this.getCopy(
        `${COPY_ID_PREFIX}.tooltipIconAriaLabel`
      ),
      tooltipContent: this.getCopy(`${COPY_ID_PREFIX}.tooltipContent`),
      tooltipHeading: this.getCopy(`${COPY_ID_PREFIX}.tooltipHeading`),
    };

    const waitingForZipValidation = () => {
      return (
        isOriginCityValid?.type === RemoteDataTypes.LOADING ||
        isOriginPostalCodeValid?.type === RemoteDataTypes.LOADING ||
        isDestinationCityValid?.type === RemoteDataTypes.LOADING ||
        isDestinationPostalCodeValid?.type === RemoteDataTypes.LOADING
      );
    };

    const importExportLabels = {
      flipButtonImportAriaLabel: {
        id: `${COPY_ID_PREFIX}.flipButtonImportAriaLabel`,
      },
      flipButtonExportAriaLabel: {
        id: `${COPY_ID_PREFIX}.flipButtonExportAriaLabel`,
      },
    };

    const originCountry = FormField.getValue(originFields.country);
    const destinationCountry = FormField.getValue(destinationFields.country);

    const destinationZipValidation = validationRules.destinationZip.getDefaultRule(
      countriesWithoutPostalCode,
      isBusiness,
      AutoComplete.getValue(destinationCountry)
    );
    const destinationCityValidation = validationRules.destinationCity.getDefaultRule(
      isBusiness,
      typeof destinationFields.postalCode !== "undefined"
    );

    const originZipValidation = validationRules.originZip.getDefaultRule(
      countriesWithoutPostalCode,
      isBusiness,
      AutoComplete.getValue(originCountry)
    );
    const originCityValidation = validationRules.originCity.getDefaultRule(
      isBusiness,
      typeof originFields.postalCode !== "undefined"
    );

    const importExportLabel = getImportExportLabel(
      intl,
      originCountry,
      destinationCountry,
      isOriginDestinationFlipped,
      importExportLabels
    );
    const enableImportExport = isBusiness
      ? enableImportExportFeature.business
      : enableImportExportFeature.private;

    return (
      <div className="mx-wrapper">
        <TradeLaneWrapper
          className="grid-maxWidthInner no-padding"
          ref={backToTradeLaneReference}
          id="tradelane-panel"
        >
          {isDomesticShipmentException && (
            <DomesticShipmentNotAllowed
              intl={intl}
              isOpen={isDomesticShipmentException}
              onClose={toggleDomesticShipmentException}
              isDomesticShippingAvailable={isDomesticShippingAvailable}
            />
          )}
          {isBusiness && showTradeLaneBusinessShipmentAssistant ? (
            <TeaserBusinessAssistant intl={intl} />
          ) : (
            <ErrorBoundary>
              <Form
                buttonDataTrackingKey={buttonDataTrackingKeyPath}
                buttonLabel={messages.showShipment}
                buttonTestId="describe-shipment-button"
                className="grid-maxWidth"
                copyNamespace={SS}
                isButtonDisabled={
                  (isBusiness && !isLocationApiAvailable) ||
                  waitingForZipValidation()
                }
                isFormValid={isFormValid}
                onSubmit={() => showShipmentLane(selectedSegment)}
                trackEvent={trackEvent}
                trackEventKey="continueToShipmentClicked"
                validateDomElement={this.validateFormField}
              >
                {enableImportExport && (
                  <TradeLaneImportExportToggleWrapper data-testid="tradelane-importexport-toggle-tooltip">
                    <SkeletonOr width={200}>
                      <ToggleTextAndIcon
                        ariaLabel={messages.tooltipImportToggleLabel}
                        alignRight
                        iconLabel={messages.tooltipIconAriaLabel}
                        iconName="info"
                        tooltipHeading={messages.tooltipImportToggleHeading}
                        label={
                          <ToggleField
                            ariaLabel={importExportLabel}
                            dataTestid="import-export-button"
                            name="flipButton"
                            isChecked={isOriginDestinationFlipped}
                            onChange={this.toggleOriginAndDestination}
                            dataTracking={
                              this.importExportSwitchTrackingKeyPath
                            }
                            value="flipButton"
                          >
                            <span data-testid="import-export-toggle-label">
                              {messages.tooltipImportToggleLabel}
                            </span>
                          </ToggleField>
                        }
                        tooltipContent={
                          <Text size={TEXT_SIZES.TINY}>
                            {intl.formatMessage(
                              {
                                id: `${COPY_ID_PREFIX}.${
                                  isBusiness
                                    ? "tooltipImportToggleContentBusiness"
                                    : "tooltipImportToggleContentPrivate"
                                }`,
                              },
                              {
                                br: <br />,
                              }
                            )}
                          </Text>
                        }
                        tooltipID="tradeLaneImportToggleTooltip"
                      />
                    </SkeletonOr>
                  </TradeLaneImportExportToggleWrapper>
                )}

                <TradeLaneFormContentWrapper isBusiness={isBusiness}>
                  <OriginContainer>
                    <legend className="visually-hidden">
                      {messages.originHeadline}
                    </legend>
                    <DirectionHeadlineWrapper>
                      <DirectionParagraph
                        size={TEXT_SIZES.REGULAR}
                        weight={700}
                        isParagraph
                      >
                        <SkeletonOr width={50}>
                          {messages.originHeadline}
                        </SkeletonOr>
                      </DirectionParagraph>
                    </DirectionHeadlineWrapper>

                    <LocationSelectorWithCountry
                      toggleOriginAndDestination={
                        this.toggleOriginAndDestination
                      }
                      wrongCountryLinkPath={wrongCountryLinkPath}
                      trackEvent={trackEvent}
                      showChangeCountryBadge={
                        !isOriginDestinationFlipped && showOriginBillingLink
                      }
                      abTestRunning={abTestRunning}
                      autocompleteSectionName={
                        isOriginDestinationFlipped
                          ? `section-${TARGETS.DESTINATION}`
                          : `section-${TARGETS.ORIGIN}`
                      }
                      cityField={originFields.city}
                      countryDataTestid="origin-country"
                      countryEmptyOptionLabel={messages.countryEmptyOptionLabel}
                      countryField={originFields.country}
                      countryLabel={messages.countryLabel}
                      countryName={
                        isOriginDestinationFlipped
                          ? FIELDS.DESTINATION_COUNTRY
                          : FIELDS.ORIGIN_COUNTRY
                      }
                      displayCountrySuggestionOnFocus={
                        displayCountrySuggestionOnFocus
                      }
                      feedbackDataTestid="origin"
                      getKeyInDigitalLayer={this.trackingKeySelector}
                      isBusiness={isBusiness}
                      isCityValid={isOriginCityValid}
                      isCountryDisabled={!isOriginDestinationFlipped}
                      isCountryRequired={
                        validationRules.originCountry.defaultRule.required
                      }
                      isPostalCodeRequired={originZipValidation.required}
                      isPostalCodeValid={isOriginPostalCodeValid}
                      isRegionRequired={originCityValidation.required}
                      onAutocompleteSelect={this.onAutocompleteSelect}
                      onChange={this.queryAutocomplete}
                      onBlur={this.onBlur}
                      postalCodeField={originFields.postalCode}
                      postalCodePattern={originZipValidation.pattern}
                      regionDataTestid="origin-city"
                      regionLabel={messages.regionLabel}
                      regionLabelErrorMessage={messages.regionLabelErrorMessage}
                      regionName={
                        isOriginDestinationFlipped
                          ? FIELDS.DESTINATION_CITY
                          : FIELDS.ORIGIN_CITY
                      }
                      validateLocation={validateLocation}
                      whitelistedCountries={countryCodes}
                      warRestrictedZones={warRestrictedZones}
                      zipDataTestid="origin-postal-code"
                      zipLabel={messages.zipLabel}
                      zipLabelErrorMessage={messages.zipLabelErrorMessage}
                      zipName={
                        isOriginDestinationFlipped
                          ? FIELDS.DESTINATION_POSTAL_CODE
                          : FIELDS.ORIGIN_POSTAL_CODE
                      }
                      isOriginDestinationFlipped={isOriginDestinationFlipped}
                      iNeedTheseProps={["swapCityPostalCode"]}
                    />
                  </OriginContainer>

                  <DestinationContainer>
                    <legend className="visually-hidden">
                      {messages.destinationHeadline}
                    </legend>
                    <DirectionHeadlineWrapper>
                      <DirectionParagraph
                        size={TEXT_SIZES.REGULAR}
                        weight={700}
                        isParagraph
                      >
                        <SkeletonOr width={50}>
                          {messages.destinationHeadline}
                        </SkeletonOr>
                      </DirectionParagraph>
                    </DirectionHeadlineWrapper>

                    <LocationSelectorWithCountry
                      toggleOriginAndDestination={
                        this.toggleOriginAndDestination
                      }
                      wrongCountryLinkPath={wrongCountryLinkPath}
                      trackEvent={trackEvent}
                      showChangeCountryBadge={
                        isOriginDestinationFlipped && showOriginBillingLink
                      }
                      abTestRunning={abTestRunning}
                      autocompleteSectionName={
                        isOriginDestinationFlipped
                          ? `section-${TARGETS.ORIGIN}`
                          : `section-${TARGETS.DESTINATION}`
                      }
                      cityField={destinationFields.city}
                      countryDataTestid="destination-country"
                      countryEmptyOptionLabel={messages.countryEmptyOptionLabel}
                      countryField={destinationFields.country}
                      countryLabel={messages.countryLabel}
                      countryName={
                        isOriginDestinationFlipped
                          ? FIELDS.ORIGIN_COUNTRY
                          : FIELDS.DESTINATION_COUNTRY
                      }
                      countryPattern={
                        validationRules.destinationCountry.defaultRule.pattern
                      }
                      displayCountrySuggestionOnFocus={
                        displayCountrySuggestionOnFocus
                      }
                      feedbackDataTestid="destination"
                      getKeyInDigitalLayer={this.trackingKeySelector}
                      blacklistedDestinationCountries={
                        blacklistedDestinationCountries
                      }
                      warRestrictedZones={warRestrictedZones}
                      isBusiness={isBusiness}
                      isCityValid={isDestinationCityValid}
                      isCountryDisabled={isOriginDestinationFlipped}
                      isCountryRequired={
                        validationRules.destinationCountry.defaultRule.required
                      }
                      isPostalCodeRequired={destinationZipValidation.required}
                      isPostalCodeValid={isDestinationPostalCodeValid}
                      isRegionRequired={destinationCityValidation.required}
                      onAutocompleteSelect={this.onAutocompleteSelect}
                      onChange={this.queryAutocomplete}
                      onFocus={this.queryAutocomplete}
                      onBlur={this.onBlur}
                      postalCodeField={destinationFields.postalCode}
                      postalCodePattern={destinationZipValidation.pattern}
                      regionDataTestid="destination-city"
                      regionLabel={messages.regionLabel}
                      regionLabelErrorMessage={messages.regionLabelErrorMessage}
                      regionName={
                        isOriginDestinationFlipped
                          ? FIELDS.ORIGIN_CITY
                          : FIELDS.DESTINATION_CITY
                      }
                      validateLocation={validateLocation}
                      whitelistedCountries={countryCodes}
                      zipDataTestid="destination-postal-code"
                      zipLabel={messages.zipLabel}
                      zipLabelErrorMessage={messages.zipLabelErrorMessage}
                      zipName={
                        isOriginDestinationFlipped
                          ? FIELDS.ORIGIN_POSTAL_CODE
                          : FIELDS.DESTINATION_POSTAL_CODE
                      }
                      isOriginDestinationFlipped={isOriginDestinationFlipped}
                      iNeedTheseProps={["swapCityPostalCode"]}
                    />

                    {AutoComplete.isSelected(
                      FormField.getValue(destinationFields.country)
                    ) && (
                      <AddressTypeCheckboxWithCountry
                        getKeyInDigitalLayer={this.trackingKeySelector}
                        iNeedTheseProps={["hasResidencialAddress"]}
                        intl={intl}
                        onChange={this.updateDestinationAddressType}
                        value={destinationAddressType}
                      />
                    )}
                  </DestinationContainer>
                </TradeLaneFormContentWrapper>

                {isBusiness && !isLocationApiAvailable && (
                  <FeedbackMessageContainer>
                    <FeedbackMessage
                      id="autoCompleteAPIUnavailable"
                      copyNamespace={SS}
                      feedback={{
                        hasError: true,
                        feedbackMessageId: "autoCompleteAPIUnavailable",
                        name: "autoCompleteAPIUnavailable",
                      }}
                      role="status"
                      variation="error"
                    />
                  </FeedbackMessageContainer>
                )}
              </Form>
            </ErrorBoundary>
          )}
        </TradeLaneWrapper>
      </div>
    );
  }
}

TradeLaneComponent.propTypes = {
  abTestRunning: PropTypes.bool,
  backToTradeLaneReference: PropTypes.object,
  blacklistedDestinationCountries: PropTypes.array,
  warRestrictedZones: PropTypes.array,
  buttonDataTrackingKeyPath: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  countryAutocompleteIncludeCountryCodes: PropTypes.bool,
  destinationAddressType: PropTypes.oneOf(Object.values(TYPES_OF_ADDRESSES))
    .isRequired,
  destinationFields: PropTypes.object.isRequired,
  displayCountrySuggestionOnFocus: PropTypes.bool,
  isDomesticShippingAvailable: PropTypes.shape({
    private: PropTypes.bool,
    business: PropTypes.bool,
  }),
  enableImportExportFeature: PropTypes.shape({
    private: PropTypes.bool,
    business: PropTypes.bool,
  }),
  flipOriginDestination: PropTypes.func,
  intl: IntlPropType.isRequired,
  isBusiness: PropTypes.bool,
  isDestinationCityValid: PropTypes.object.isRequired,
  isDestinationPostalCodeValid: PropTypes.object.isRequired,
  isFormValid: PropTypes.bool.isRequired,
  isLocationApiAvailable: PropTypes.bool.isRequired,
  isOriginCityValid: PropTypes.object.isRequired,
  isOriginDestinationFlipped: PropTypes.bool,
  isOriginPostalCodeValid: PropTypes.object.isRequired,
  originFields: PropTypes.object.isRequired,
  queryCountry: PropTypes.func.isRequired,
  selectCity: PropTypes.func.isRequired,
  selectCountry: PropTypes.func.isRequired,
  selectPostalCode: PropTypes.func.isRequired,
  showOriginBillingLink: PropTypes.bool,
  showShipmentLane: PropTypes.func.isRequired,
  hideShipmentLane: PropTypes.func.isRequired,
  showTradeLaneBusinessShipmentAssistant: PropTypes.bool,
  trackEvent: PropTypes.func,
  updateCity: PropTypes.func.isRequired,
  updateDestinationAddressType: PropTypes.func.isRequired,
  updatePostalCode: PropTypes.func.isRequired,
  validateCountryField: PropTypes.func.isRequired,
  validateFormField: PropTypes.func.isRequired,
  validateLocation: PropTypes.func.isRequired,
  wrongCountryLinkPath: PropTypes.string.isRequired,
  selectedSegment: PropTypes.oneOf(Object.values(SEGMENT_TYPES)),
  isDomesticShipmentException: PropTypes.bool.isRequired,
  toggleDomesticShipmentException: PropTypes.func.isRequired,
  countriesWithoutPostalCode: PropTypes.array,
  pageName: PropTypes.string.isRequired,
};

TradeLaneComponent.defaultProps = {
  abTestRunning: true,
  backToTradeLaneReference: React.createRef(),
  countriesWithoutPostalCode: [],
  blacklistedDestinationCountries: [],
  warRestrictedZones: [],
  countryAutocompleteIncludeCountryCodes: false,
  displayCountrySuggestionOnFocus: false,
  isDomesticShippingAvailable: { private: true, business: true },
  enableImportExportFeature: { private: true, business: true },
  flipOriginDestination: Function.prototype,
  isBusiness: false,
  isOriginDestinationFlipped: false,
  showOriginBillingLink: false,
  trackEvent: Function.prototype,
  showTradeLaneBusinessShipmentAssistant: false,
};

export default injectIntl(TradeLaneComponent);
