// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * WCCOfferCardHeader
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { IntlPropType } from "root/libs/core-libs/src";
import { key } from "styled-theme";
import {
  Text,
  TEXT_SIZES,
  POPOVER_PLACEMENTS,
} from "@dhl-official/react-ui-library";
import { Headline, Icon } from "root/libs/ui-components/src";
import ResponsiveRender from "one-time-shipment/src/components/Layout/ResponsiveRender";
import { offerPurchaseMethods } from "root/libs/constants/src";
import wccWalkIcon from "icons/cx/wcc-walk.svg";
import wccClickIcon from "icons/cx/wcc-click.svg";
import wccCallIcon from "icons/cx/wcc-call.svg";

import {
  StyledOfferCardHeader,
  StyledHeadingWrapper,
  SubHeadline,
  StyledIconButtonTooltip,
  StyledHeadline,
  StyledIconWrapper,
} from "./styled";

const WCCIcons = {
  WALK: wccWalkIcon,
  CLICK: wccClickIcon,
  CALL: wccCallIcon,
};

const COPY_ID_PREFIX = "WCCOfferCardHeader";

export default function WCCOfferCardHeader({
  intl,
  isFirst,
  isLast,
  purchaseMethod,
  isHighlighted,
  expandedCardIndex,
  offerIndex,
}) {
  function getCopy(id, values = {}) {
    return intl.formatMessage({ id }, values);
  }

  const renderTooltip = (className, arrowDirection, showCloseButton) => (
    <StyledIconButtonTooltip
      className={className}
      isFirst={isFirst}
      expandedCardIndex={expandedCardIndex}
      id={`wcc-offer-card-tooltip-${offerIndex}`}
      dataTracking="data-tracking"
      ariaLabel={getCopy(
        `${COPY_ID_PREFIX}.${purchaseMethod}_tooltipAriaHeading`
      )}
      arrowDirection={arrowDirection}
      name={`wcc-offer-card-tooltip-${offerIndex}`}
      iconType="info"
      showCloseButton={showCloseButton}
      tooltipContent={
        <Text size={TEXT_SIZES.TINY}>
          {getCopy(`${COPY_ID_PREFIX}.${purchaseMethod}_tooltipContent`, {
            br: <br />,
          })}
        </Text>
      }
    />
  );

  return (
    <StyledOfferCardHeader
      isFirst={isFirst}
      isLast={isLast}
      isHighlighted={isHighlighted}
      expandedCardIndex={expandedCardIndex}
    >
      <StyledIconWrapper>
        <Icon
          useAs="decorative"
          icon={WCCIcons[purchaseMethod]}
          fillColor={key("colors.primary")}
          size="xxlarge"
          data-testid={`wcc-offer-card-icon-${offerIndex}`}
        />
      </StyledIconWrapper>
      <StyledHeadingWrapper>
        <ResponsiveRender
          render={(isMobile) =>
            isMobile ? (
              <Headline
                priority={2}
                headlineDisplayType="h"
                weight="700"
                data-testid={`wcc-offer-card-headline-${offerIndex}`}
                id={`wcc-offer-card-headline-${offerIndex}`}
                noMargin
              >
                {getCopy(
                  `${COPY_ID_PREFIX}.${purchaseMethod}_headline_mobile`,
                  {
                    br: <br />,
                  }
                )}
              </Headline>
            ) : (
              <StyledHeadline
                priority={2}
                headlineDisplayType="h"
                weight="700"
                data-testid={`wcc-offer-card-headline-${offerIndex}`}
                id={`wcc-offer-card-headline-${offerIndex}`}
                noMargin
              >
                {getCopy(
                  `${COPY_ID_PREFIX}.${purchaseMethod}_headline_desktop`
                )}
              </StyledHeadline>
            )
          }
        />
        <SubHeadline
          size={TEXT_SIZES.SMALL}
          dataTestid={`wcc-offer-card-sub-headline-${offerIndex}`}
          id={`wcc-offer-card-sub-headline-${offerIndex}`}
          isParagraph
        >
          {getCopy(`${COPY_ID_PREFIX}.${purchaseMethod}_subheadline`)}
        </SubHeadline>
      </StyledHeadingWrapper>
      <ResponsiveRender
        render={(isMobile) =>
          isMobile
            ? renderTooltip(
                "wcc-offer-card-tooltip-sm",
                POPOVER_PLACEMENTS.BOTTOM,
                true
              )
            : renderTooltip(
                "wcc-offer-card-tooltip-md",
                POPOVER_PLACEMENTS.AUTO,
                false
              )
        }
      />
    </StyledOfferCardHeader>
  );
}

WCCOfferCardHeader.propTypes = {
  /** Internationalization library */
  intl: IntlPropType.isRequired,
  /** Flag to indicate if the card is the first in the list */
  isFirst: PropTypes.bool,
  /** Flag to indicate if the card is the last in the list */
  isLast: PropTypes.bool,
  /** The purchase method */
  purchaseMethod: PropTypes.oneOf(Object.values(offerPurchaseMethods))
    .isRequired,
  /** Flag to indicate if the card is highlighted */
  isHighlighted: PropTypes.bool,
  /** The index of the expanded card */
  expandedCardIndex: PropTypes.number,
  /** The index of the offer */
  offerIndex: PropTypes.number,
};
