// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * DeliveryTime
 *
 */
import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import {
  IntlPropType,
  getDeliveryDate,
  getTimeOfDay,
  getDeliveryTime,
} from "root/libs/core-libs/src";
import {
  DeliveryTimeText,
  DeliveryDateHeadline,
  DateTimeWrapper,
} from "./styled";

const DeliveryTime = ({
  intl,
  estimatedDeliveryTime,
  estimatedDeliveryTimeDisplayType,
  is12HourTimeFormat,
  nativeDateTimeFormat,
  nativeLanguageCode,
  wrapOnMobile,
  variation,
}) => {
  const getOfferTimes = () => {
    const deliveryDate = getDeliveryDate(
      intl,
      estimatedDeliveryTime,
      nativeDateTimeFormat,
      nativeLanguageCode
    );
    let deliveryTime = "";

    if (estimatedDeliveryTime) {
      const timeOfDay = getTimeOfDay(
        intl,
        estimatedDeliveryTime,
        estimatedDeliveryTimeDisplayType,
        is12HourTimeFormat
      );
      deliveryTime = getDeliveryTime(
        intl,
        timeOfDay,
        estimatedDeliveryTimeDisplayType
      );
    }

    return {
      deliveryDate,
      deliveryTime,
    };
  };

  const { deliveryDate, deliveryTime } = getOfferTimes();

  return (
    <DateTimeWrapper
      className={wrapOnMobile ? "wrap-mobile" : ""}
      variation={variation}
    >
      <DeliveryDateHeadline variation={variation}>
        {deliveryDate}
      </DeliveryDateHeadline>
      <DeliveryTimeText variation={variation}>{deliveryTime}</DeliveryTimeText>
    </DateTimeWrapper>
  );
};

DeliveryTime.propTypes = {
  /** Internationalization library */
  intl: IntlPropType.isRequired,
  estimatedDeliveryTime: PropTypes.instanceOf(Date),
  estimatedDeliveryTimeDisplayType: PropTypes.string,
  is12HourTimeFormat: PropTypes.bool.isRequired,
  nativeDateTimeFormat: PropTypes.string,
  nativeLanguageCode: PropTypes.string,
  wrapOnMobile: PropTypes.bool,
  variation: PropTypes.string,
};

DeliveryTime.defaultProps = {
  estimatedDeliveryTime: null,
  estimatedDeliveryTimeDisplayType: null,
};

export default injectIntl(DeliveryTime);
