import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { RadioField, RADIOFIELD_SIZES } from "@dhl-official/react-ui-library";
import { breakpoints, grid } from "root/libs/ui-styleguide/src";

import ModalUnitSwitchInfo from "one-time-shipment/src/components/Modals/ModalUnitSwitchInfo";

const GridSection = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(2, 90px);
  justify-content: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    max-width: 700px;
    justify-content: left;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    max-width: ${grid.container.maxWidth};
  }
`;

const UnitSelector = ({
  unitSystem,
  selectedUnitSystem,
  onUnitSelectorChange,
  trackEvent,
}) => {
  const [modalSwitch, setModalSwitch] = useState(false);
  const [selectedUnitSystemState, setSelectedUnitSystemState] = useState(
    selectedUnitSystem
  );

  const radioGroup = Object.keys(unitSystem);

  const onChangeMock = (e) => {
    setSelectedUnitSystemState(e.target.value);
    setModalSwitch(!modalSwitch);
  };

  const onClose = (isConfirmed) => {
    if (isConfirmed === true) {
      onUnitSelectorChange(selectedUnitSystemState);
    }
    setModalSwitch(false);
  };

  return (
    <div className="grid-maxWidthInner no-padding" data-testid="unit-selector">
      <GridSection className="grid-maxWidth">
        {radioGroup.map((unit) => {
          return (
            <RadioField
              key={unit}
              size={RADIOFIELD_SIZES.SMALL}
              name="unitRadioGroup"
              isChecked={unit === selectedUnitSystem}
              onChange={onChangeMock}
              value={unit}
            >
              {`${unitSystem[unit].weight}/${unitSystem[unit].length}`}
            </RadioField>
          );
        })}
      </GridSection>
      {modalSwitch && (
        <ModalUnitSwitchInfo
          isOpen={modalSwitch}
          unitSystem={unitSystem}
          newUnitValue={selectedUnitSystemState}
          onClose={onClose}
          trackEvent={trackEvent}
        />
      )}
    </div>
  );
};

UnitSelector.propTypes = {
  unitSystem: PropTypes.object.isRequired,
  selectedUnitSystem: PropTypes.string.isRequired,
  onUnitSelectorChange: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
};

export default UnitSelector;
