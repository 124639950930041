// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * BillingCountryCard
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { IntlPropType, trackContentInteraction } from "root/libs/core-libs/src";
import { SEGMENT_TYPES } from "one-time-shipment/src/containers/SpotShipmentPage/constants";
import {
  UTF_EVENT_CONTENT_NAMES,
  UTF_EVENT_TYPES,
  UTF_EVENT_INTERACTIONS,
  UTF_EVENT_CONTENT_POSITION,
} from "root/libs/constants/src";
import { CountrySettings } from "root/libs/ui-containers/src";
import ModalChangeBillingCountry from "one-time-shipment/src/components/Modals/ModalChangeBillingCountry";
import {
  DisabledCountryCard,
  BillingCountryNote,
  CountryLabel,
  CountryFlag,
  CountryWrapper,
  ChangeCountryButton,
} from "./styled";

const { withCountry } = CountrySettings;

export default class BillingCountryCard extends React.PureComponent {
  static propTypes = {
    abTestRunning: PropTypes.bool.isRequired,
    dataTestid: PropTypes.string,
    countryId: PropTypes.string,
    dataTracking: PropTypes.string,
    trackEvent: PropTypes.func,
    intl: IntlPropType.isRequired,
    toggleOriginAndDestination: PropTypes.func.isRequired,
    isBusiness: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    dataTracking: "",
    dataTestid: "",
    countryId: "",
    trackEvent: () => {},
  };

  constructor(props) {
    super(props);
    this.modalChangeBillingCountryWithCountry = withCountry(
      ModalChangeBillingCountry
    );
    this.state = { openChangeBillingCountryModal: false };
  }

  triggerClickTrackingEvent = () => {
    const { isBusiness } = this.props;

    trackContentInteraction({
      name: UTF_EVENT_CONTENT_NAMES.CHANGE_BILLING_COUNTRY,
      type: UTF_EVENT_TYPES.BUTTON,
      interaction: UTF_EVENT_INTERACTIONS.CLICK,
      position: UTF_EVENT_CONTENT_POSITION.TRADE_LANE,
      context: isBusiness ? SEGMENT_TYPES.business : SEGMENT_TYPES.private,
    });
  };

  onClickHandler = () => {
    const { trackEvent } = this.props;

    trackEvent({ wrongCountry: true });
    this.triggerClickTrackingEvent();
    this.setState({ openChangeBillingCountryModal: true });
  };

  render() {
    const {
      abTestRunning,
      dataTestid,
      countryId,
      dataTracking,
      intl,
      toggleOriginAndDestination,
      isBusiness,
    } = this.props;

    const { openChangeBillingCountryModal } = this.state;

    const getCopy = (id) => intl.formatMessage({ id });

    const messages = {
      billingCountry: getCopy("ChangeCountryTextAndLink.billingCountry"),
      countryLabel: getCopy(`CountryAutocompleteField.${countryId}`) || "",
      wrongCountryCopyOrigin: getCopy(
        "ChangeCountryTextAndLink.wrongCountryCopyOrigin"
      ),
      wrongCountryLinkUrl: getCopy(
        "ChangeCountryTextAndLink.wrongCountryLinkUrl"
      ),
      wrongCountryLinkText: getCopy(
        "ChangeCountryTextAndLink.wrongCountryLinkText"
      ),
    };

    return (
      <DisabledCountryCard dataTestid={dataTestid}>
        <CountryWrapper>
          <CountryFlag country={countryId} />
          <CountryLabel
            ariaLabelledby="billing-country"
            tabIndex="0"
            dataTestid={dataTestid}
            dataCountryId={countryId}
          >
            {messages.countryLabel}
          </CountryLabel>
          <ChangeCountryButton
            name="button"
            size="regular"
            variant="text"
            type="button"
            dataTestid={`${dataTestid}-change-location-cta`}
            onClick={this.onClickHandler}
            dataTracking={dataTracking}
            id={`${dataTestid}-cta`}
            ariaLabel={`${messages.wrongCountryLinkText}`}
          >
            {messages.wrongCountryLinkText}
          </ChangeCountryButton>
        </CountryWrapper>
        <BillingCountryNote id="billing-country">
          <span className="visually-hidden">{messages.countryLabel} - </span>
          {messages.billingCountry}
        </BillingCountryNote>
        <this.modalChangeBillingCountryWithCountry
          abTestRunning={abTestRunning}
          intl={intl}
          isOpen={openChangeBillingCountryModal}
          onClose={() =>
            this.setState({ openChangeBillingCountryModal: false })
          }
          isBusiness={isBusiness}
          iNeedTheseProps={["changeCountryURI", "changeCountryURIBusiness"]}
          toggleOriginAndDestination={toggleOriginAndDestination}
        />
      </DisabledCountryCard>
    );
  }
}
