// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * IconButton
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { key } from "styled-theme";
import { keyCodes } from "root/libs/constants/src";
import { sizes } from "root/libs/ui-styleguide/src";
import { StyledIconButton } from "./styled";

export default class IconButton extends React.PureComponent {
  static propTypes = {
    /**
     * onClick handler for the icon element. Note:
     * We need to attach also onKeyDown events. This way we make
     * elements accessible for keyboards.
     */
    onClick: PropTypes.func,
    /** Blur function to use for example to close a tooltip if it's opened */
    onBlur: PropTypes.func,
    /** It will be used to tell screen readers what the icon button does */
    ariaLabel: PropTypes.string.isRequired,
    /** It will be used to tell screen readers what is the icon about */
    ariaDescribedBy: PropTypes.string,
    ariaExpanded: PropTypes.bool,
    role: PropTypes.string,
    /** Name to identify the button */
    name: PropTypes.string.isRequired,
    /** Allows the component styles to be edited from a caller compoent */
    className: PropTypes.string,
    /** Fills the background of the button.
     * If you select custom as a value, the button will not have:
     * background, border and focus styles pre-defined
     */
    buttonStyle: PropTypes.oneOf([
      "tertiary",
      "primary",
      "secondary",
      "transparent",
      "custom",
    ]),
    /** Select one of the pre-define icon sizes */
    iconSize: PropTypes.oneOf([
      "",
      "small",
      "intermediate",
      "medium",
      "large",
      "mlarge",
      "xlarge",
      "xxlarge",
      "inbetweenLarge",
      "xxxlarge",
    ]),
    /**
     * Use to sepcify a custom size for the icon
     * Receives a string with number and unit
     */
    iconWidth: PropTypes.shape({
      mobile: PropTypes.string,
      desktop: PropTypes.string,
    }),
    /**
     * Use to sepcify a custom size for the icon
     * Receives a string with number and unit
     */
    iconHeight: PropTypes.shape({
      mobile: PropTypes.string,
      desktop: PropTypes.string,
    }),
    variation: PropTypes.oneOf(["standard", "circle"]),
    /**
     * Specify a custom size for the button.
     * If not defined will default to the default button sizes form @dhl-cj/ui-styleguide
     * width: 210px, height: 45px
     */
    buttonSize: PropTypes.shape({
      width: PropTypes.shape({
        mobile: PropTypes.string,
        desktop: PropTypes.string,
      }),
      height: PropTypes.shape({
        mobile: PropTypes.string,
        desktop: PropTypes.string,
      }),
    }),
    /** Object generated by the svg-sprite-loader
     * importing an icon to be used */
    icon: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string,
        viewBox: PropTypes.string,
        url: PropTypes.string,
      }),
    ]).isRequired,
    /**
     * Optional prop to hold the tracking path.
     * It will get used as data-attribute on
     * the rendered interactive element
     */
    dataTracking: PropTypes.string,
    /** Send any children you might need in case you are extending this component */
    children: PropTypes.node,
  };

  static defaultProps = {
    onClick: () => { },
    onBlur: () => { },
    iconSize: "small",
    className: "",
    buttonStyle: "transparent",
    variation: "standard",
    iconWidth: {
      mobile: "",
      desktop: "",
    },
    iconHeight: {
      mobile: "",
      desktop: "",
    },
    dataTracking: null,
    buttonSize: {
      width: {
        mobile: sizes.buttonMinWidth,
        desktop: sizes.buttonMinWidth,
      },
      height: {
        mobile: sizes.buttonHeightInPx,
        desktop: sizes.buttonHeightInPx,
      },
    },
    children: null,
    ariaDescribedBy: "",
    ariaExpanded: null,
    role: "",
  };

  onKeyDown = (event) => {
    const { keyCode } = event;
    const { onClick } = this.props;

    if (keyCode === keyCodes.SPACE || keyCode === keyCodes.RETURN) {
      onClick(event);
      event.stopPropagation();
    }
  };

  render() {
    const {
      icon,
      buttonStyle,
      dataTracking,
      className,
      ariaLabel,
      ariaDescribedBy,
      ariaExpanded,
      role,
      variation,
      iconSize,
      iconWidth,
      iconHeight,
      buttonSize,
      name,
      children,
      onClick,
      onBlur,
      ...propsOfExtendedComponent
    } = this.props;

    let iconFillColor = key("colors.fontColor");

    if (buttonStyle === "primary") {
      iconFillColor = key("colors.fontColorInverded");
    }

    return (
      <StyledIconButton
        buttonName={name}
        variation={variation}
        buttonStyle={buttonStyle}
        fillColor={iconFillColor}
        icon={icon}
        className={className}
        onClick={onClick}
        isClickable
        onKeyDown={this.onKeyDown}
        size={iconSize}
        width={iconWidth}
        height={iconHeight}
        buttonWidth={buttonSize.width}
        buttonHeight={buttonSize.height}
        label={ariaLabel}
        ariaDescribedBy={ariaDescribedBy}
        ariaExpanded={ariaExpanded}
        role={role}
        useAs="standalone"
        tabIndex="0"
        dataTracking={dataTracking}
        onBlur={onBlur}
        {...propsOfExtendedComponent}
      >
        {!!children && children}
      </StyledIconButton>
    );
  }
}
