// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * SpotShipmentPage
 *
 */

import React from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import {
  Tracking,
  AbTesting,
  RtlSupport,
  CanaryVersions,
  CountrySettings,
} from "root/libs/ui-containers/src";
import SpotShipment from "one-time-shipment/src/pages/SpotShipment";
import { COMMON } from "root/libs/constants/src";
import { changeSegment, prefillSegment } from "./actions";
import { resetItem, prefillShipmentLaneFromUrl } from "../ShipmentLane/actions";
import {
  prefillTradeLaneFromUrl,
  resetDocumentOptionsValue,
} from "../TradeLane/actions";
import {
  localShipmentDetected,
  getOffersRequestSent,
} from "../GetQuote/actions";
import { decodeTradeLaneToState } from "../TradeLane/selectors";
import { showOffersLane } from "../OffersLane/actions";

const {
  actions: { trackEvent },
} = Tracking;

const {
  selectors: { selectAbTestRunning },
} = AbTesting;

const {
  selectors: { selectCanaryVersionEnabled, selectCanaryVersion },
} = CanaryVersions;
const {
  selectors: { selectRtlSupportEnabled },
} = RtlSupport;

const { withCountry } = CountrySettings;

const SpotShipmentComponent = withCountry(SpotShipment);

function SpotShipmentPage(props) {
  return (
    <SpotShipmentComponent
      iNeedTheseProps={["hideSegments", "defaultSelectedSegment", "segments"]}
      {...props}
    />
  );
}

const mapStateToProps = (state) => ({
  language: state.localization.language,
  country: state.localization.country,
  storeInitialSetAction: state.personalization.storeInitialSetAction,
  wannaRunClientStorageAction:
    state.personalization.wannaRunClientStorageAction,
  analyticsUserId: state.personalization.analyticsUserId,
  isByPassBlockVisible:
    state.offersLane.isOffersLaneVisible ||
    !isEmpty(state.getQuote.networkError),
  selectedSegment: state.spotShipmentPage.selectedSegment,
  showShipmentDetectedModal: state.getQuote.localShipmentDetected,
  abTestRunning: selectAbTestRunning(state),
  rtlSupportEnabled: selectRtlSupportEnabled(state),
  canaryEnabled: selectCanaryVersionEnabled(state),
  canaryVersion: selectCanaryVersion(state),
});

const mapDispatchToProps = (dispatch) => ({
  onSegmentChange: (segment) => {
    dispatch(changeSegment({ segment }));
    dispatch(resetItem({ segment }));
    dispatch(resetDocumentOptionsValue());
  },
  trackEvent: (trackingData) => dispatch(trackEvent(trackingData)),
  closeShipmentDetectedModal: ({ localStorageSavedShipment, country }) => {
    dispatch(localShipmentDetected({ value: false }));
    if (localStorageSavedShipment) {
      const decodedBase64 = atob(localStorageSavedShipment);
      const decodedUri = decodeURIComponent(decodedBase64);
      const shipmentData = JSON.parse(decodedUri);
      const { shipmentLane, tradeLane } = shipmentData;

      dispatch(
        prefillSegment({
          segment: { id: shipmentData.spotShipmentPage.selectedSegment },
        })
      );
      dispatch(prefillTradeLaneFromUrl(decodeTradeLaneToState(tradeLane)));
      dispatch(prefillShipmentLaneFromUrl({ shipmentLane }));
      dispatch(showOffersLane());
      dispatch(getOffersRequestSent());
    }
    window.localStorage.removeItem(
      `${COMMON.SAVE_SHIPMENT_LOCAL_STORAGE}:${country}`
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SpotShipmentPage);
