// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * OfferPromotedLine
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { TEXT_SIZES } from "@dhl-official/react-ui-library";
import format from "date-fns/format";
import { IntlPropType, GetCountryCode } from "root/libs/core-libs/src";
import { dayPeriodOverwrites } from "root/libs/constants/src";
import { Link } from "root/libs/ui-components/src";
import { PromotedText, PromotedTextWrapper } from "./styled";

const COPY_ID_PREFIX = "PropertiesMessages";

const OfferPromotedLine = ({
  intl,
  promotedProperty,
  showInViewport,
  cutOffDateTime,
  pickUpDate,
  offerKey,
  nativeLanguageCode,
  is12HourTimeFormat,
  nativeDateTimeFormat,
  offerIndex,
  purchaseMethod,
}) => {
  const getFormattedDateTime = (cutOffDateTimeParam, pickUpDateParam) => {
    /**
     * To get stable date formatting we need the native language and the
     * country code combined in a locale even when the current displayed
     * language is another one. Only the language code or country code is
     * not enough. For some countries the language and country code differs (e.g. "AT")
     * and some countries with the same native language code has different
     * date formats e.g. US (MM/DD/YYYY), AU (YYYY/MM/DD) and GB (DD/MM/YYYY)
     * If the date is in the wrong format, or displaying different values on different browsers,
     * the country configuration must be updated with the required `nativeDateTimeFormat` locale.
     *
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
     */

    const countryCode = GetCountryCode(intl.locale);
    const dateFormatLocale = nativeDateTimeFormat
      ? nativeDateTimeFormat
      : `${nativeLanguageCode}-${countryCode}`;
    const instanceOfDate = new Intl.DateTimeFormat(dateFormatLocale);
    let cutOffTime;

    // IF countryCode belongs to list of wrong native dayPeriod strings
    // we have to force the pattern using date-fns to get correct cut-off time
    // date-fns doesn't create a new instance of time from undefined
    // so we must provide a new Date() as default
    if (countryCode in dayPeriodOverwrites) {
      cutOffTime = format(
        cutOffDateTimeParam,
        dayPeriodOverwrites[countryCode]
      );
    } else {
      cutOffTime = intl.formatTime(cutOffDateTimeParam, {
        hour12: is12HourTimeFormat,
      });
    }

    return {
      cutOffTime,
      cutOffDate: cutOffDateTimeParam
        ? instanceOfDate.format(cutOffDateTimeParam)
        : "",
      pickUpDate: pickUpDateParam ? instanceOfDate.format(pickUpDateParam) : "",
    };
  };

  const nullChecker = (id) => {
    return !!intl.messages[`${COPY_ID_PREFIX}.${id}`];
  };

  const getCopy = (id, values, prefix = COPY_ID_PREFIX) => {
    const addOn =
      purchaseMethod !== null && purchaseMethod === "WALK"
        ? `_${purchaseMethod}`
        : "";
    return intl.formatMessage({ id: `${prefix}.${id}${addOn}` }, values);
  };

  const formattedDateAndTime = getFormattedDateTime(cutOffDateTime, pickUpDate);
  const propertyMessageId = `${promotedProperty.key}_promotedLine`;
  const isPropertyMessageIdAvailable = nullChecker(propertyMessageId);
  const containsLink =
    nullChecker(`${promotedProperty.key}_promotedLinkText`) &&
    nullChecker(`${promotedProperty.key}_promotedLinkUrl`);

  return (
    <PromotedTextWrapper showInViewport={showInViewport}>
      <PromotedText
        dataTestid={`${offerKey}-promoted${
          showInViewport ? `-${showInViewport}` : ""
        }${offerIndex !== undefined ? `-${offerIndex}` : ""}`}
        size={TEXT_SIZES.TINY}
        className="Card__promotedAddOn"
        id={`${offerKey}-promoted${showInViewport ? `-${showInViewport}` : ""}${
          offerIndex !== undefined ? `-${offerIndex}` : ""
        }`}
        isParagraph
      >
        {!!formattedDateAndTime &&
          isPropertyMessageIdAvailable &&
          getCopy(propertyMessageId, formattedDateAndTime)}
        {!formattedDateAndTime && getCopy("DROP_OFF_ECS_INT_promotedLine")}
        {containsLink && (
          <React.Fragment>
            {" "}
            <Link
              href={getCopy(`${promotedProperty.key}_promotedLinkUrl`)}
              linkType="inline-black"
              target="_blank"
            >
              {getCopy(`${promotedProperty.key}_promotedLinkText`)}
            </Link>
          </React.Fragment>
        )}
        {((!!formattedDateAndTime && isPropertyMessageIdAvailable) ||
          !formattedDateAndTime ||
          containsLink) && <span className="visually-hidden">:</span>}
      </PromotedText>
    </PromotedTextWrapper>
  );
};

OfferPromotedLine.propTypes = {
  /** Internationalization library */
  intl: IntlPropType.isRequired,
  offerKey: PropTypes.string.isRequired,
  promotedProperty: PropTypes.object.isRequired,
  cutOffDateTime: PropTypes.instanceOf(Date),
  pickUpDate: PropTypes.instanceOf(Date),
  showInViewport: PropTypes.oneOf(["mobile", "desktop", ""]),
  is12HourTimeFormat: PropTypes.bool.isRequired,
  nativeLanguageCode: PropTypes.string.isRequired,
  nativeDateTimeFormat: PropTypes.string,
  offerIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  purchaseMethod: PropTypes.string,
};

OfferPromotedLine.defaultProps = {
  cutOffDateTime: new Date(),
  nativeDateTimeFormat: "",
  pickUpDate: undefined,
  showInViewport: "",
  purchaseMethod: null,
};

export default injectIntl(OfferPromotedLine);
