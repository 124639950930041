// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for ModalError
 *
 */

import styled from "styled-components";
import { breakpoints } from "root/libs/ui-styleguide/src";
import { Text } from "@dhl-official/react-ui-library";
import { key } from "styled-theme";

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ContentFreightP2PModal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: left;
  margin: 50px 0 50px 0;

  h2 {
    margin-bottom: 0;
  }

  div {
    margin-bottom: 20px;
  }

  .subheading {
    font-size: ${key("fonts.mobile.h2.size")};
    line-height: ${key("fonts.mobile.h2.lineHeight")};
    font-weight: 300;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: 40px 0 50px 0;

    & {
      flex-direction: row;
      text-align: left;
    }

    div {
      margin-bottom: 0;

      &:first-child {
        padding-right: 20px;
      }
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      margin: 20px 0 30px 0;
    }
  }
`;

export const CopyContainer = styled.div`
  text-align: center;
  margin: 20px auto 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    p {
      margin-top: 0;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const LinkWrapper = styled.span`
  display: block;
`;

export const OrWraper = styled.div`
  margin: 10px 0 5px;
`;

export const TextInfo = styled(Text)`
  margin-bottom: ${(props) => (props.doubleBottomSpacing ? "20px" : 0)};
`;
