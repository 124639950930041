// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Icon
 *
 *  Usage:
 *  1. Import this component and the icon that you want to use. ie:
 *    ```
 *     import { Icon } from '@dhl-cj/ui-components';
 *     import fooIcon from 'icons/fooIcon.svg';
 *    ```
 *
 *  2. Call the icon tag, and send the imported icon as attr. ie:
 *    ```
 *     <Icon label="some accessible label" icon={fooIcon}></Icon>
 *    ```
 *
 *  3. Carry on configuring accordingly.
 */

import React from "react";
import PropTypes from "prop-types";
import { colors } from "root/libs/ui-styleguide/src";
import { sizes, IconButton, IconSpan } from "./styled";

export default class Icon extends React.PureComponent {
  static propTypes = {
    /** String or a react element to apply as the SVG title element */
    label: PropTypes.node,
    /** Allows the component styles to be edited from a caller compoent */
    className: PropTypes.string,
    /**
     * Standalone: icon needs to convey meaning all by itself
     * Decorative: icon is just visual sugar - the words around it convey the meaning.
     * Default: decorative
     * decorative will add aria-hidden=true.
     * standalone: will add aria-label and the title tag to the icon.
     * more: https://css-tricks.com/can-make-icon-system-accessible/
     */
    useAs: PropTypes.oneOf(["standalone", "decorative"]),
    /** String with an alternative fill color for the icon -
     * Accepts a function when you get the
     * color from `import { key } from 'styled-theme';` */
    fillColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    /** String with an alternative for a hover fill color for the icon */
    fillHoverColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    /* Extra styles to position the icon */
    extraStyles: PropTypes.array,
    /** onClick handler for the icon element. Note:
    We need to attach also onKeyDown events. This way we make
    elements accessible for keyboards. */
    onClick: PropTypes.func,
    onKeyDown: PropTypes.func,
    /** Tells the icon if its a clickable element */
    isClickable: PropTypes.bool,
    /** Tells the size of the SVG should be relative */
    isRelativeSvgSize: PropTypes.bool,
    /** The background color of the button.
     * Use any of the colors defined in: '/@dhl-cj/ui-styleguide/themes/dhl/vars.colors.js' */
    buttonBackgroundColor: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
    ]),
    /** Control the size of the icon.
    It will be mapped to px, which will be used in
    the svg properties and the CSS.
    Default: regular
    Possible options: small, medium, large, xlarge */
    size: PropTypes.oneOf([
      "",
      "small",
      "intermediate",
      "medium",
      "large",
      "mlarge",
      "xlarge",
      "xxlarge",
      "inbetweenLarge",
      "xxxlarge",
    ]),
    /** Use the width and height instead of the size property when you need to set a custom size.
     * If you send an object it expects an array of objects where you define the sizes for mobile
     * and desktop. Receives a string with number and unit
     */
    width: PropTypes.shape({
      mobile: PropTypes.string,
      desktop: PropTypes.string,
    }),
    /** Use the width and height instead of the size property when you need to set a custom size.
     * If you send an object it expects an array of objects where you define the sizes for mobile
     * and desktop. Receives a string with number and unit
     */
    height: PropTypes.shape({
      mobile: PropTypes.string,
      desktop: PropTypes.string,
    }),
    /** Object generated by the svg-sprite-loader importing an icon to be used */
    icon: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string,
        viewBox: PropTypes.string,
        url: PropTypes.string,
      }),
    ]).isRequired,
    tabIndex: PropTypes.string,
    /** Optional prop to hold the tracking path. It will get used as data-attribute on the rendered interactive element */
    dataTracking: PropTypes.string,
    buttonName: PropTypes.string,
    /** Send any children you might need in case you are extending this component */
    children: PropTypes.any,
    ariaDescribedBy: PropTypes.string,
    ariaExpanded: PropTypes.bool,
    role: PropTypes.string,
    dataTestid: PropTypes.string,
  };

  static defaultProps = {
    onClick: () => {},
    buttonName: "",
    className: "",
    onKeyDown: () => {},
    isClickable: false,
    fillColor: colors.black,
    fillHoverColor: colors.silverDark,
    extraStyles: [],
    isRelativeSvgSize: false,
    buttonBackgroundColor: "transparent",
    width: {
      mobile: "",
      desktop: "",
    },
    height: {
      mobile: "",
      desktop: "",
    },
    size: "",
    label: "",
    useAs: "decorative",
    tabIndex: "-1",
    dataTracking: null,
    children: null,
    ariaDescribedBy: "",
    ariaExpanded: null,
    role: "",
  };

  getSize = (props) => sizes[props.size];

  render() {
    const {
      size,
      icon,
      label,
      fillColor,
      isClickable,
      fillHoverColor,
      extraStyles,
      isRelativeSvgSize,
      buttonBackgroundColor,
      width,
      height,
      useAs,
      tabIndex,
      onKeyDown,
      dataTracking,
      className,
      buttonName,
      children,
      onClick,
      ariaDescribedBy,
      ariaExpanded,
      role,
      dataTestid,
      ...propsOfExtendedComponent
    } = this.props;

    const iconId = `#${icon.id}`;
    let iconWidth = width.desktop;
    let iconHeight = height.desktop;

    if (size !== "") {
      iconWidth = this.getSize(this.props);
      iconHeight = this.getSize(this.props);
    }

    let ariaPropsTag = {};
    let ariaPropsSvg = {};
    let IconTag;

    let buttonProps = {};

    if (isClickable) {
      IconTag = IconButton;
      buttonProps = {
        name: buttonName,
        "aria-label": label,
      };
    } else {
      IconTag = IconSpan;
    }

    if (useAs === "decorative") {
      ariaPropsSvg = {
        "aria-hidden": true,
        role: "presentation",
      };
    } else if (useAs === "standalone") {
      if (!isClickable) {
        ariaPropsTag = {
          title: label,
        };
        ariaPropsSvg = {
          role: "img",
          "aria-label": label,
        };
      } else {
        ariaPropsSvg = {
          "aria-hidden": true,
        };
      }
    }

    const extraProps = {};

    if (role) {
      extraProps.role = role;
    }

    if (dataTestid) {
      extraProps["data-testid"] = dataTestid;
    }

    const ariaDescribedByOptional = {
      ...(ariaDescribedBy && { "aria-describedby": ariaDescribedBy }),
    };

    const ariaExpandedOptional = {
      ...(ariaExpanded !== "" && { "aria-expanded": ariaExpanded }),
    };

    return (
      <IconTag
        className={`icon ${className}`}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        isClickable={isClickable}
        size={size}
        iconWidth={width}
        iconHeight={height}
        fillColor={fillColor}
        fillHoverColor={fillHoverColor}
        isRelativeSvgSize={isRelativeSvgSize}
        buttonBackgroundColor={buttonBackgroundColor}
        extraStyles={extraStyles}
        tabIndex={tabIndex}
        data-tracking={dataTracking}
        {...extraProps}
        {...buttonProps}
        {...ariaPropsTag}
        {...ariaDescribedByOptional}
        {...ariaExpandedOptional}
        {...propsOfExtendedComponent}
      >
        <svg
          {...ariaPropsSvg}
          width={iconWidth}
          height={iconHeight}
          viewBox={icon.viewBox}
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          {!!(useAs === "standalone") && <title>{label}</title>}
          <use xlinkHref={iconId} />
        </svg>
        {!!children && children}
      </IconTag>
    );
  }
}
