// 9fbef606107a605d69c0edbcd8029e5d

import memoize from "lodash/memoize";
import escapeRegExp from "lodash/escapeRegExp";

// If users inputs .87, add automatically 0 to make it 0.87
export const floatingPointWithoutZeroValueFormatter = memoize((value) =>
  value.replace(/^,\d*|^\.\d*/g, (x) => `0${x.replace(",", ".")}`)
);

// Removes the unit name from the value
export const stripUnitNameValueFormatter = memoize(({ value, unit }) => {
  let regex = new RegExp(
    `${escapeRegExp(unit)}s?$|\\s${escapeRegExp(unit)}s?$`,
    "gi"
  );
  if (unit === "lbs") {
    regex = new RegExp(
      `${escapeRegExp(unit)}$|\\s${escapeRegExp(unit)}$`,
      "gi"
    );
  }

  return value.replace(regex, "");
});

// If value has more than 2 decimal places, round upwards to 2 decimals
export const roundToTwoDecimalsValueFormatter = memoize((value) => {
  const formattedValue = value.replace(",", ".");
  const stringBased = formattedValue.split(".");

  /* This code is being used to resolve the problem which we have in number.
  Number system has limit, it accepts up to 15 digit number in JavaScript and beyond that it just changed it to 1***
  Example: 9999999999999999 => 10000000000000000
  In our case users were entering value shown above and that value was being converted to 10000000000000000
  which was wrong from user's point of view.
  Solution: Here in case, if user is entering number with more than 15 digits,
  we will convert it to string and send it back (without making any number conversion) */
  if (
    stringBased.length > 2 ||
    stringBased[0].length > 15 ||
    (typeof stringBased[1] !== "undefined" && stringBased[1].length <= 2)
  ) {
    return formattedValue;
  }

  if (formattedValue) {
    return Math.ceil(formattedValue * 100) / 100;
  }

  return formattedValue;
});

// If value has more than 2 decimal places, round upwards to next integer
export const roundToIntegerValueFormatter = memoize((value) => {
  const formattedValue = value.toString().replace(",", ".");
  const stringBased = formattedValue.split(".");
  if (
    stringBased.length > 2 ||
    stringBased[0].length > 15 ||
    (typeof stringBased[1] !== "undefined" && stringBased[1].length <= 2)
    ) {
      return Math.ceil(formattedValue);
  }
  
  if (formattedValue) {
    return formattedValue;
  }

  return formattedValue;
});

export default {
  floatingPointWithoutZeroValueFormatter,
  stripUnitNameValueFormatter,
  roundToTwoDecimalsValueFormatter,
  roundToIntegerValueFormatter
};
