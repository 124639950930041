// 9fbef606107a605d69c0edbcd8029e5d

import logging from "../devTools/logging";

const save = (storage, key, data) => {
  try {
    if (typeof data === "string") storage.setItem(key, data);
    else storage.setItem(key, JSON.stringify(data));
  } catch (ex) {
    logging.warn(ex);
  }
};

const load = (storage, key) => {
  try {
    return storage.getItem(key);
  } catch (ex) {
    logging.warn(ex);
    return undefined;
  }
};

export default {
  save,
  load,
};
