// 9fbef606107a605d69c0edbcd8029e5d

/*
 *
 * ShipmentLane constants
 *
 */

export const SHOW_SHIPMENT_LANE = "app/ShipmentLane/SHOW_SHIPMENT_LANE";
export const HIDE_SHIPMENT_LANE = "app/ShipmentLane/HIDE_SHIPMENT_LANE";
export const VALIDATE_FORM_FIELDS = "app/ShipmentLane/VALIDATE_FORM_FIELDS";
export const CLEAR_DIMENSION_FIELDS_VALIDATION_ERRORS =
  "app/ShipmentLane/CLEAR_DIMENSION_FIELDS_VALIDATION_ERRORS";
export const RESET_SCROLLING = "app/ShipmentLane/RESET_SCROLLING";
export const RESET_ITEM = "app/ShipmentLane/RESET_ITEM";
export const UPDATE_ITEM = "app/ShipmentLane/UPDATE_ITEM";
export const PREFILL_INITIAL_SET = "app/ShipmentLane/INITIAL_SET_PREFILL";
export const PREFILL_SHIPMENT_LANE_FROM_URL =
  "app/ShipmentLane/PREFILL_SHIPMENT_LANE_FROM_URL";
export const UPDATE_VALIDITY = "app/ShipmentLane/UPDATE_VALIDITY";
export const ON_CHECKBOX_CHANGE = "app/ShipmentLane/ON_CHECKBOX_CHANGE";
export const ADD_SHIPMENT_LINE_ITEM = "app/ShipmentLane/ADD_SHIPMENT_LINE_ITEM";
export const REMOVE_SHIPMENT_LINE_ITEM =
  "app/ShipmentLane/REMOVE_SHIPMENT_LINE_ITEM";
export const COPY_SELECTED_UNIT_SYSTEM_FROM_COUNTRY_SETTINGS =
  "app/ShipmentLane/COPY_SELECTED_UNIT_SYSTEM_FROM_COUNTRY_SETTINGS";
export const SET_UNIT_SYSTEM = "app/ShipmentLane/SET_UNIT_SYSTEM";

export const PRESET_SIZES_XS = {
  length: "32",
  width: "24",
  height: "1",
  presetSize: "xs",
};

export const PRESET_SIZES_S = {
  length: "23",
  width: "14",
  height: "4",
  presetSize: "s",
};

export const PRESET_SIZES_M = {
  length: "35",
  width: "20",
  height: "15",
  presetSize: "m",
};

export const PRESET_SIZES_L = {
  length: "75",
  width: "35",
  height: "35",
  presetSize: "l",
};
