// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * AbTestExperiment
 * To learn how this works and how to setup: docs/Analytics/AB-testing.md
 */

import { connect } from "react-redux";
import AbTest from "./AbTest";

const mapStateToProps = (state) => ({
  showThisVersion: state.abTestExperiment.showThisVersion,
});

export default connect(mapStateToProps)(AbTest);
