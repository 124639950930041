// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * ModalSaveInformationSuccess
 *
 */

import React from "react";
import PropTypes from "prop-types";
import successIcon from "icons/cx/success.svg";
import { Text } from "@dhl-official/react-ui-library";
import { brandPalette } from "root/libs/ui-styleguide/src";
import { injectIntl } from "react-intl";
import styled from "styled-components";

import { ContentModal, Headline, Icon } from "root/libs/ui-components/src";

const CopyText = styled(Text)`
  margin-bottom: 20px;
  margin-top: 10px;
`;

const Wrapper = styled.div`
  display: flex;

  > div:first-child {
    margin-right: 20px;
    align-self: center;
  }

  p {
    margin-bottom: 0;
  }
`;

const COPY_ID_PREFIX = "ModalSaveInformationSuccess";

const ModalSaveInformationSuccess = (props) => {
  const { intl } = props;

  const getCopy = (id, values, prefix = COPY_ID_PREFIX) => {
    return intl.formatMessage({ id: `${prefix}.${id}` }, values);
  };

  return (
    <ContentModal
      {...props}
      smallCenteredModal
      closeButtonAriaLabel={intl.formatMessage({ id: "SS.ContentModal.close" })}
      contentAriaLabel={getCopy("description")}
    >
      <Wrapper data-testid="save-information-success-modal">
        <div>
          <Icon
            icon={successIcon}
            useAs="decorative"
            fillColor={brandPalette.successColor}
            width={{
              mobile: "52px",
              desktop: "72px",
            }}
          />
        </div>
        <div>
          <Headline priority={2}>{getCopy("headline")}</Headline>
          <CopyText isParagraph>{getCopy("description")}</CopyText>
        </div>
      </Wrapper>
    </ContentModal>
  );
};

ModalSaveInformationSuccess.propTypes = {
  intl: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ModalSaveInformationSuccess.defaultProps = {
  isOpen: false,
};

export default injectIntl(ModalSaveInformationSuccess);
