// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * QuoteValidity
 *
 */

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import { IntlPropType } from "root/libs/core-libs/src";
import { businessUnits } from "root/libs/constants/src";
import { Text, TEXT_SIZES } from "@dhl-official/react-ui-library";
import { breakpoints } from "root/libs/ui-styleguide/src";
import { key } from "styled-theme";

const StyledText = styled(Text)`
  padding-left: 19px;
  margin: ${(props) => (props.noMargin ? "0" : "10px 10px 0")};
  position: relative;
  color: ${key("colors.successColor")};
  width: ${(props) =>
    props.variationType === "WCCOfferCard" ? "100%" : "auto"};

  &::after {
    position: absolute;
    left: 0;
    top: 3px;
    content: "";
    display: block;
    background: ${key("colors.successColor")};
    width: 9px;
    height: 9px;
    border-radius: 50%;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-left: ${(props) => (props.noMargin ? "0" : "20px")};
    margin-right: ${(props) => (props.noMargin ? "0" : "20px")};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-left: ${(props) => (props.noMargin ? "0" : "30px")};
    margin-right: ${(props) => (props.noMargin ? "0" : "30px")};
  }
`;

const COPY_ID_PREFIX = "QuoteValidity";

const QuoteValidity = ({ intl, businessUnit, noMargin, variationType }) => {
  let textLabel;

  switch (businessUnit) {
    case businessUnits.DGF:
      textLabel = `${COPY_ID_PREFIX}.DGF_Message`;
      break;
    case businessUnits.FREIGHT:
      textLabel = `${COPY_ID_PREFIX}.FRE_Message`;
      break;
    case businessUnits.ECS:
      textLabel = `${COPY_ID_PREFIX}.ECS_Message`;
      break;
    default:
      textLabel = `${COPY_ID_PREFIX}.EXP_Message`;
  }

  return (
    <StyledText
      size={TEXT_SIZES.TINY}
      noMargin={noMargin}
      variationType={variationType}
    >
      {intl.formatMessage({ id: textLabel })}
    </StyledText>
  );
};

QuoteValidity.propTypes = {
  /** Internationalization library */
  intl: IntlPropType.isRequired,
  businessUnit: PropTypes.oneOf(Object.values(businessUnits)),
  noMargin: PropTypes.bool,
  variationType: PropTypes.string,
};

QuoteValidity.defaultProps = {
  businessUnit: undefined,
};

export default injectIntl(QuoteValidity);
