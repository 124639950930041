// 9fbef606107a605d69c0edbcd8029e5d

/*
 *
 * CanaryVersions reducer
 *
 */

import { handleActions } from "redux-actions";
import {
  setMicroFrontendId as setMicroFrontendIdAction,
} from "./actions";

const initialState = {
  id: "",
};

const setMicroFrontendId = (
  state,
  { payload: id = "" }
) => ({
  id,
});

export default handleActions(
  {
    [setMicroFrontendIdAction]: setMicroFrontendId,
  },
  initialState
);
// To bind this to Redux, import it in the reducers.js
