// 9fbef606107a605d69c0edbcd8029e5d

// See: https://github.com/formatjs/react-intl/issues/1456 for more reasons why we use this
import PropTypes from "prop-types";

export const IntlPropType = PropTypes.shape({
  formatMessage: PropTypes.func.isRequired,
});

export default IntlPropType;
