// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * CanaryVersions
 */

import React from "react";
import PropTypes from "prop-types";

export default class Canary extends React.PureComponent {
  static propTypes = {
    versionsToRender: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
    componentToRender: PropTypes.func.isRequired,
    fallback: PropTypes.func.isRequired,
    version: PropTypes.string.isRequired,
  };

  render() {
    const {
      versionsToRender,
      componentToRender,
      fallback,
      version,
    } = this.props;

    return (
      <React.Fragment>
        {version === versionsToRender ||
        (Array.isArray(versionsToRender) && versionsToRender.includes(version))
          ? componentToRender()
          : fallback()}
      </React.Fragment>
    );
  }
}
