// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * DimensionSelectorSmall
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { IntlPropType } from "root/libs/core-libs/src";
import { Icon, FormField } from "root/libs/ui-components/src";
import { SEGMENT_TYPES } from "one-time-shipment/src/containers/SpotShipmentPage/constants";
import PresetSizeSelector from "one-time-shipment/src/components/Forms/PresetSizeSelector";
import AdvancedSettings from "one-time-shipment/src/components/Forms/AdvancedSettings";
import { SS } from "root/libs/constants/src";
import {
  DimensionSelectorSmallWrapper,
  StyledHeadline,
  CustomDimensionsWrapper,
  WidthHeightLengthControls,
  IconHeadlineWrapper,
  StyledQuantitySelectorWrapper,
  StyledQuantitySelector,
} from "./styled";

const COPY_ID_PREFIX_DIM_SELECT = "DimensionSelector";

class DimensionSelectorSmall extends React.PureComponent {
  static propTypes = {
    /** Translations library  */
    intl: IntlPropType.isRequired,
    headline: PropTypes.string,
    /** This function needs to receive the event target. This should be a valid DOM input so we can use CheckValidity in the reducer */
    onDimensionChange: PropTypes.func.isRequired,
    onPresetSelected: PropTypes.func.isRequired,

    heightLabel: PropTypes.string.isRequired,
    widthLabel: PropTypes.string.isRequired,
    lengthLabel: PropTypes.string.isRequired,

    heightValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    widthValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    lengthValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    heightValidationRules: PropTypes.object,
    widthValidationRules: PropTypes.object,
    lengthValidationRules: PropTypes.object,
    quantityValidationRules: PropTypes.object,
    heightError: PropTypes.shape({
      name: PropTypes.string,
      hasError: PropTypes.bool,
      isValid: PropTypes.bool,
      feedbackMessageId: PropTypes.string,
    }),
    widthError: PropTypes.shape({
      name: PropTypes.string,
      hasError: PropTypes.bool,
      isValid: PropTypes.bool,
      feedbackMessageId: PropTypes.string,
    }),
    lengthError: PropTypes.shape({
      name: PropTypes.string,
      hasError: PropTypes.bool,
      isValid: PropTypes.bool,
      feedbackMessageId: PropTypes.string,
    }),
    quantityError: PropTypes.shape({
      name: PropTypes.string,
      hasError: PropTypes.bool,
      isValid: PropTypes.bool,
      feedbackMessageId: PropTypes.string,
    }),
    /** Will be truthy when the user clicks on a preset size. */
    forceShowSuccessState: PropTypes.shape({
      height: PropTypes.bool,
      length: PropTypes.bool,
      width: PropTypes.bool,
    }),
    presetSize: PropTypes.string,
    /** If set to true, AdvancedSettings will be shown. */
    isBusiness: PropTypes.bool,
    /** Units to be used in the component. */
    unitSystem: PropTypes.object.isRequired,
    selectedUnitSystem: PropTypes.string.isRequired,
    iconToRender: PropTypes.object.isRequired,
    dimensionKeyPaths: PropTypes.object,
    trackEvent: PropTypes.func,
    presetSizes: PropTypes.shape({
      weightBreakThreshold: PropTypes.object,
      private: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
          value: PropTypes.string,
          label: PropTypes.string,
          icon: PropTypes.string,
          values: PropTypes.shape({
            length: PropTypes.string,
            width: PropTypes.string,
            height: PropTypes.string,
            presetSize: PropTypes.string,
          }),
        })
      ),
      business: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
          value: PropTypes.string,
          label: PropTypes.string,
          icon: PropTypes.string,
          values: PropTypes.shape({
            length: PropTypes.string,
            width: PropTypes.string,
            height: PropTypes.string,
            presetSize: PropTypes.string,
          }),
        })
      ),
    }).isRequired,
    onDimensionBlur: PropTypes.func,
    onDimensionBlurValueFormatter: PropTypes.func,
    weight: PropTypes.string,
    isWeightValid: PropTypes.bool,
    quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onQuantityChange: PropTypes.func,
    onQuantityBlur: PropTypes.func,
    onCheckboxChange: PropTypes.func,
    settingsNonStackable: PropTypes.bool,
    heightFieldRef: PropTypes.object,
    customTabRef: PropTypes.object,
    heightFeedbackInfoMessage: PropTypes.object,
    /* Defines the roundedUpInfoMessage that is only shown when the heightFeedbackInfoMessage is not present  */
    roundedUpInfoMessage: PropTypes.object,
    toggleInfoMessage: PropTypes.func.isRequired,
    isQuantitySelectorEnabled: PropTypes.bool.isRequired,
    itemIndex: PropTypes.number,
    addItemDescriptionId: PropTypes.string,
    /* Defines the displayed list of presets */
    segmentForPresets: PropTypes.oneOf(Object.values(SEGMENT_TYPES)).isRequired,
    /* Boolean to disable advanced settings checkboxes when min weight is not met */
    isCheckboxDisabledBecauseOfWeight: PropTypes.bool,
  };

  static defaultProps = {
    headline: "",
    heightValue: 0,
    widthValue: 0,
    lengthValue: 0,
    heightValidationRules: {},
    widthValidationRules: {},
    lengthValidationRules: {},
    quantityValidationRules: {},
    heightError: {
      name: "",
      hasError: false,
      isValid: false,
      feedbackMessageId: "",
    },
    widthError: {
      name: "",
      hasError: false,
      isValid: false,
      feedbackMessageId: "",
    },
    lengthError: {
      name: "",
      hasError: false,
      isValid: false,
      feedbackMessageId: "",
    },
    quantityError: {
      name: "",
      hasError: false,
      isValid: false,
      feedbackMessageId: "",
    },
    forceShowSuccessState: {
      height: false,
      length: false,
      width: false,
    },
    presetSize: "",
    isBusiness: false,
    dimensionKeyPaths: {},
    trackEvent: () => {},
    onDimensionBlur: () => {},
    onDimensionBlurValueFormatter: (event) => event.target.value,
    weight: "",
    isWeightValid: false,
    quantity: 1,
    onQuantityChange: () => {},
    onQuantityBlur: () => {},
    onCheckboxChange: () => {},
    settingsNonStackable: false,
    heightFieldRef: React.createRef(),
    customTabRef: React.createRef(),
    heightFeedbackInfoMessage: {
      message: "",
      isShowInfoMessage: false,
    },
    roundedUpInfoMessage: {
      message: "",
      isShowInfoMessage: {},
    },
    itemIndex: 0,
    addItemDescriptionId: "",
    isCheckboxDisabledBecauseOfWeight: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      showPresets: true,
      triggerShowCustomTab: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { heightError, widthError, lengthError } = this.props;

    if (
      (prevProps.heightError.hasError !== heightError.hasError &&
        heightError.hasError) ||
      (prevProps.widthError.hasError !== widthError.hasError &&
        widthError.hasError) ||
      (prevProps.lengthError.hasError !== lengthError.hasError &&
        lengthError.hasError)
    ) {
      this.changeToCustomTabOnError();
    }
  }

  changeToCustomTabOnError = () => {
    this.setState({ triggerShowCustomTab: true });
  };

  onMobileAccordionSelect = (tabProps) => {
    const { index } = tabProps;

    this.setState({ showPresets: index === 1 });
  };

  onDimensionBlur = (event) => {
    const { toggleInfoMessage, onDimensionBlur } = this.props;

    toggleInfoMessage(event);
    onDimensionBlur(event);
  };

  render() {
    const {
      heightLabel,
      widthLabel,
      lengthLabel,
      heightValidationRules,
      widthValidationRules,
      lengthValidationRules,
      heightError,
      widthError,
      lengthError,
      quantityError,
      presetSize,
      unitSystem,
      selectedUnitSystem,
      intl,
      iconToRender,
      heightValue,
      widthValue,
      lengthValue,
      headline,
      onPresetSelected,
      onDimensionChange,
      dimensionKeyPaths,
      trackEvent,
      forceShowSuccessState,
      isBusiness,
      presetSizes,
      onDimensionBlur,
      onDimensionBlurValueFormatter,
      weight,
      isWeightValid,
      quantity,
      onQuantityChange,
      onQuantityBlur,
      quantityValidationRules,
      onCheckboxChange,
      settingsNonStackable,
      heightFieldRef,
      customTabRef,
      toggleInfoMessage,
      heightFeedbackInfoMessage,
      roundedUpInfoMessage,
      isQuantitySelectorEnabled,
      itemIndex,
      addItemDescriptionId,
      segmentForPresets,
      isCheckboxDisabledBecauseOfWeight,
    } = this.props;

    const { showPresets, triggerShowCustomTab } = this.state;
    const getCopy = (key) =>
      intl.formatMessage({ id: `${COPY_ID_PREFIX_DIM_SELECT}.${key}` });

    const roundedUpLength =
      roundedUpInfoMessage.isShowInfoMessage[`length-${itemIndex}`];
    const roundedUpWidth =
      roundedUpInfoMessage.isShowInfoMessage[`width-${itemIndex}`];
    const roundedUpHeight =
      roundedUpInfoMessage.isShowInfoMessage[`height-${itemIndex}`];

    return (
      <DimensionSelectorSmallWrapper>
        <IconHeadlineWrapper>
          <StyledHeadline
            dataTestid={`item-dimension-${itemIndex}`}
            priority={4}
            weight="700"
          >
            {headline}
          </StyledHeadline>
          <Icon
            data-testid={iconToRender.id}
            icon={iconToRender}
            useAs="decorative"
            width={{
              mobile: "113px",
              desktop: "100%",
            }}
            height={{
              mobile: "82px",
              desktop: "100%",
            }}
          />
        </IconHeadlineWrapper>
        <PresetSizeSelector
          intl={intl}
          onPresetSelected={onPresetSelected}
          presetSize={presetSize}
          unit={unitSystem[selectedUnitSystem].length}
          isMobile
          trackEvent={trackEvent}
          isBusiness={isBusiness}
          presetSizes={presetSizes}
          selectedUnitSystem={selectedUnitSystem}
          onMobileAccordionSelect={this.onMobileAccordionSelect}
          onCheckboxChange={onCheckboxChange}
          settingsNonStackable={settingsNonStackable}
          triggerShowCustomTab={triggerShowCustomTab}
          customTabRef={customTabRef}
          itemIndex={itemIndex}
          segmentForPresets={segmentForPresets}
          isCheckboxDisabledBecauseOfWeight={isCheckboxDisabledBecauseOfWeight}
        />

        <CustomDimensionsWrapper
          className={showPresets ? "hideDimensionWrapper" : null}
          data-testid="custom-sizes-content"
        >
          <legend className="visually-hidden">
            {intl.formatMessage({
              id: "PresetSizeSelector.customSizes",
            })}
          </legend>
          <WidthHeightLengthControls>
            <FormField
              name={`length-${itemIndex}`}
              type="numeric"
              label={`${lengthLabel} (${unitSystem[selectedUnitSystem].length})`}
              data-tracking={dimensionKeyPaths.length}
              value={lengthValue}
              onChange={onDimensionChange}
              onBlur={onDimensionBlur}
              feedback={lengthError}
              interpolations={{
                minValue: `${lengthValidationRules[selectedUnitSystem].minValue} ${unitSystem[selectedUnitSystem].length}`,
                maxValue: `${lengthValidationRules[selectedUnitSystem].maxValue} ${unitSystem[selectedUnitSystem].length}`,
                dimensionName:
                  lengthValidationRules[selectedUnitSystem].maxValue,
                fieldName: getCopy("lengthLabelErrorMessage"),
              }}
              required={lengthValidationRules.required}
              maxValue={lengthValidationRules[selectedUnitSystem].maxValue}
              minValue={lengthValidationRules[selectedUnitSystem].minValue}
              feedbackMessageIdPrefix="integers"
              feedbackInfoMessage={{
                message: roundedUpInfoMessage.message,
                isShowInfoMessage: roundedUpLength,
              }}
              pattern="numberTwoDecimals"
              forceShowSuccessState={forceShowSuccessState.length}
              onBlurValueFormatter={onDimensionBlurValueFormatter}
              copyNamespace={SS}
              ariaDescribedBy={addItemDescriptionId}
              className={`${roundedUpLength ? "validation-info" : ""}`}
              forceFeedbackMessage={roundedUpLength}
            />
            <FormField
              name={`width-${itemIndex}`}
              type="numeric"
              label={`${widthLabel} (${unitSystem[selectedUnitSystem].length})`}
              data-tracking={dimensionKeyPaths.width}
              value={widthValue}
              onChange={onDimensionChange}
              onBlur={onDimensionBlur}
              feedback={widthError}
              feedbackInfoMessage={{
                message: roundedUpInfoMessage.message,
                isShowInfoMessage: roundedUpWidth,
              }}
              interpolations={{
                minValue: `${widthValidationRules[selectedUnitSystem].minValue} ${unitSystem[selectedUnitSystem].length}`,
                maxValue: `${widthValidationRules[selectedUnitSystem].maxValue} ${unitSystem[selectedUnitSystem].length}`,
                dimensionName:
                  widthValidationRules[selectedUnitSystem].maxValue,
                fieldName: getCopy("widthLabelErrorMessage"),
              }}
              required={widthValidationRules.required}
              minValue={widthValidationRules[selectedUnitSystem].minValue}
              maxValue={widthValidationRules[selectedUnitSystem].maxValue}
              feedbackMessageIdPrefix="integers"
              pattern="numberTwoDecimals"
              forceShowSuccessState={forceShowSuccessState.width}
              onBlurValueFormatter={onDimensionBlurValueFormatter}
              copyNamespace={SS}
              ariaDescribedBy={addItemDescriptionId}
              className={`${roundedUpWidth ? "validation-info" : ""}`}
              forceFeedbackMessage={roundedUpWidth}
            />
            <FormField
              name={`height-${itemIndex}`}
              type="numeric"
              label={`${heightLabel} (${unitSystem[selectedUnitSystem].length})`}
              data-tracking={dimensionKeyPaths.height}
              value={heightValue}
              onFocus={toggleInfoMessage}
              onChange={onDimensionChange}
              onBlur={this.onDimensionBlur}
              feedback={heightError}
              interpolations={{
                minValue: `${heightValidationRules[selectedUnitSystem].minValue} ${unitSystem[selectedUnitSystem].length}`,
                maxValue: `${heightValidationRules[selectedUnitSystem].maxValue} ${unitSystem[selectedUnitSystem].length}`,
                dimensionName:
                  heightValidationRules[selectedUnitSystem].maxValue,
                fieldName: getCopy("heightLabelErrorMessage"),
              }}
              required={heightValidationRules.required}
              maxValue={heightValidationRules[selectedUnitSystem].maxValue}
              minValue={heightValidationRules[selectedUnitSystem].minValue}
              pattern="numberTwoDecimals"
              feedbackMessageIdPrefix="integers"
              forceShowSuccessState={forceShowSuccessState.height}
              onBlurValueFormatter={onDimensionBlurValueFormatter}
              reference={heightFieldRef}
              copyNamespace={SS}
              feedbackInfoMessage={
                roundedUpHeight
                  ? {
                      message: roundedUpInfoMessage.message,
                      isShowInfoMessage: roundedUpHeight,
                    }
                  : {
                      message: heightFeedbackInfoMessage.message,
                      isShowInfoMessage:
                        heightFeedbackInfoMessage.isShowInfoMessage,
                    }
              }
              ariaDescribedBy={addItemDescriptionId}
              className={`${roundedUpHeight ? "validation-info" : ""}`}
              forceFeedbackMessage={roundedUpHeight}
            />
          </WidthHeightLengthControls>
        </CustomDimensionsWrapper>

        {isQuantitySelectorEnabled && (
          <React.Fragment>
            <StyledQuantitySelectorWrapper>
              <StyledQuantitySelector
                quantityName={`quantity-${itemIndex}`}
                quantityLabel={
                  isBusiness
                    ? getCopy("quantityLabel")
                    : getCopy("quantityLabelPrivate")
                }
                onQuantityChange={onQuantityChange}
                shouldReplaceEmptyWithMinValue
                quantityValue={quantity}
                unitSystem={unitSystem}
                selectedUnitSystem={selectedUnitSystem}
                quantityValidationRules={{
                  ...quantityValidationRules[selectedUnitSystem],
                  ...{ required: quantityValidationRules.required },
                }}
                feedback={quantityError}
                onQuantityBlur={onQuantityBlur}
                indicatorLabel={intl.formatMessage({
                  id: "ShipmentLaneComponent.totalWeightLabel",
                })}
                weightValue={weight}
                isWeightValid={isWeightValid}
                itemIndex={itemIndex}
                ariaDescribedBy={addItemDescriptionId}
              />
            </StyledQuantitySelectorWrapper>
            <AdvancedSettings
              onCheckboxChange={onCheckboxChange}
              settingsNonStackable={settingsNonStackable}
              trackEvent={trackEvent}
              itemIndex={itemIndex}
              ariaDescribedBy={addItemDescriptionId}
              isCheckboxDisabledBecauseOfWeight={
                isCheckboxDisabledBecauseOfWeight
              }
            />
          </React.Fragment>
        )}
      </DimensionSelectorSmallWrapper>
    );
  }
}

export default injectIntl(DimensionSelectorSmall);
