// 9fbef606107a605d69c0edbcd8029e5d

import { connect } from "react-redux";
import { CountrySettings, Localization } from "root/libs/ui-containers/src";
import { Settings } from "root/libs/ui-components/src";

const {
  actions: { loadCountrySettings },
} = CountrySettings;

const {
  actions: { setupLocaleData },
  selectors: { makeSelectLocale },
} = Localization;

const mapStateToProps = (state) => ({
  selectedLocale: makeSelectLocale()(state),
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateLocale: (payload) => {
    dispatch(setupLocaleData(payload));
    dispatch(loadCountrySettings(payload.country));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
