// 9fbef606107a605d69c0edbcd8029e5d

import { connect } from "react-redux";
import {
  selectTradeLaneInitialSet,
  selectTradeLaneValidity,
} from "one-time-shipment/src/containers/TradeLane/selectors";
import { Tracking } from "root/libs/ui-containers/src";
import TrackingComponent from "./Component";

const {
  actions: { pushToDigitalLayer },
  selectors: { selectTrackingData },
} = Tracking;

const mapStateToProps = (state) => ({
  trackingData: selectTrackingData(state),
  tradeLaneData: {
    initialSet: selectTradeLaneInitialSet(state),
    validity: selectTradeLaneValidity(state),
  },
  shipmentLaneData: {
    initialSet: state.shipmentLane.initialSet,
    validity: state.shipmentLane.validity,
  },
  freightP2PModalData: {
    freightP2PModalSubmitSuccess: state.offersLane.freightP2PModalSubmitSuccess,
  },
  options: state.getQuote.options,
  country: state.localization.country,
  offers: state.getQuote.offers ? state.getQuote.offers : [],
  backends: state.getQuote.offers ? state.getQuote.backends : [],
  metadata: state.getQuote.offers ? state.getQuote.metadata : [],
  spotShipmentPage: state.spotShipmentPage,
});

const mapDispatchToProps = {
  pushToDigitalLayer,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackingComponent);
