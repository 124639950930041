// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for EmptyOffers
 *
 */

import styled, { css } from "styled-components";
import { breakpoints, colors } from "root/libs/ui-styleguide/src";
import { Text, Card, Headline } from "@dhl-official/react-ui-library";

export const NoOffersWrapper = styled(Card)`
  margin-left: 20px;
  margin-right: 20px;
  padding: 30px 80px;
  text-align: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: 30px 180px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1000px;
  }
`;

export const StyledIconWrapper = styled.div`
  margin: 0 auto;
  display: inline-block;

  svg {
    color: ${colors.redScarlet};
    width: 52px;
    height: 52px;
  }
`;

export const StyledContentWrapper = styled.div`
  display: block;
  width: 100%;
`;

export const StyledHeadline = styled(Headline)`
  margin-bottom: 14px;
`;

export const StyledText = styled(Text)`
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.2em;
`;

export const StyledLinkWrapper = styled.div`
  margin-top: 40px;
  justify-content: center;

  a {
    display: block;
    margin-bottom: 24px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: 30px;
    display: flex;
    gap: 24px;

    a {
      margin-bottom: 0;
      width: 100%;
    }
  }
`;

export const ErrorIconExtraStyles = css`
  justify-self: center;
  margin-bottom: 30px;
`;
