// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * AdditionalInformation
 *
 */

import React from "react";
import { IntlPropType } from "root/libs/core-libs/src";
import { Text, TEXT_SIZES } from "@dhl-official/react-ui-library";
import PropTypes from "prop-types";
import {
  AdditionalInformationWrapper,
  AdditionalInformationHeadline,
  AdditionalInformationList,
} from "./styled";

const COPY_ID_PREFIX = "CardAndSummary";

const AdditionalInformation = ({ intl, additionalInformationList }) => {
  const getCopy = (id, values, prefix = COPY_ID_PREFIX) => {
    return intl.formatMessage({ id: `${prefix}.${id}` }, values);
  };

  const headlineString = getCopy("additionalInformation");

  return (
    additionalInformationList &&
    additionalInformationList.length !== 0 && (
      <AdditionalInformationWrapper>
        <AdditionalInformationHeadline priority={5} weight="700">
          {headlineString}
        </AdditionalInformationHeadline>
        <AdditionalInformationList>
          {additionalInformationList.map((item) => (
            <li key={item.key}>
              <Text size={TEXT_SIZES.TINY} isParagraph>
                {getCopy(
                  `${item.key}_description`,
                  undefined,
                  "AdditionalInformationMessages"
                )}
              </Text>
            </li>
          ))}
        </AdditionalInformationList>
      </AdditionalInformationWrapper>
    )
  );
};

AdditionalInformation.propTypes = {
  /** These are the items of additional information that are added to an offer */
  additionalInformationList: PropTypes.array.isRequired,
  /** Translations library  */
  intl: IntlPropType.isRequired,
};

export default AdditionalInformation;
