// 9fbef606107a605d69c0edbcd8029e5d

export const UTF_EVENT_INTERACTIONS = {
  CLICK: "Click",
  CHANGE: "Change",
  LOAD: "Load",
  VALIDATION: "Validation",
  ERROR: "Error",
};

export const UTF_EVENT_PAGE_NAMES = {
  ERROR_PAGE: "Error Page",
  ERROR_MODAL: "Error Modal",
  TRADE_LANE: "Trade Lane",
  SHIPMENT_LANE: "Shipment Lane",
  PRODUCT_LANE: "Product Lane",
  PRODUCT_CARD: "Product Card",
};

export const UTF_EVENT_TYPES = {
  BUTTON: "Button",
  LINK: "Link",
  DROPDOWN: "Dropdown",
  MODAL: "Modal",
  INPUT_ERROR: "Input Error",
};

export const UTF_EVENT_CONVERSION_NAMES = {
  BOOKING_INTENT: "Booking Intent",
  CONTINUE_TO_FS: "Continue to FS",
};

export const UTF_EVENT_CONVERSION_TYPES = {
  HANDOVER: "Handover",
}

export const UTF_EVENT_CONVERSION_DETAIL = {
  TARGETED_SERVICE_CARD: "TARGETED_SERVICE_CARD",
  EXPRESS_CTA: "EXPRESS_CALL_CTA",
  LEGACY: "LEGACY",
  WALK: "WALK",
  CLICK: "CLICK",
  CALL: "CALL",
}

export const UTF_EVENT_CONTENT_NAMES = {
  CUSTOMER_JOURNEY: "Customer Journey",
  CHANGE_BILLING_COUNTRY: "Change Billing Country",
  FORM_SUBMIT: "Form Submit",
  CHANGE_SEGMENT: "Change Segment",
  ADD_ITEM: "Add Item",
  OFFER_SORT: "Offer Sort",
  SAVE_SHIPMENT: "Save Shipment Modal",
  RESTORE_SHIPMENT: "Restore Shipment Modal",
  EXPAND_CARD: "Expand Card",
  BACK_TO: "Back To",
  PRINT_QUOTES: "Print Quotes",
  COPY_LINK: "Copy Link",
  EMAIL_QUOTES: "Email Quotes",
  CONTINUE_TO_BOOKING: "Continue to Booking",
  FORM_INPUT: "Form Input",
  MOBILE_BOOKING_MODAL: "Mobile Booking Modal",
  FREIGHT_P2P_MODAL: "Freight P2P Modal",
  FS_GET_IN_CONTACT: "FS Get In Contact",
  FS_GET_IN_CONTACT_CONTROL: "FS Get In Contact - Control Card",
  FS_GET_IN_CONTACT_VARIANT: "FS Get In Contact - Variant Card",
  FIND_DROPOFF_POINT: "Find the closest drop-off point",
};

export const UTF_EVENT_CONTENT_POSITION = {
  TRADE_LANE: "Trade Lane",
  CHANGE_BILLING_COUNTRY_MODAL: "Change Billing Country Modal",
  SHIPMENT_LANE: "Shipment Lane",
  PRODUCT_LANE: "Product Lane",
  PRODUCT_CARD: "Product Card",
  GLOBAL: "Global",
  FREIGHT_P2P_MODAL: "Freight P2P Modal",
};

export const UTF_PRODUCT_CARD_VARIATIONS = {
  LEGACY: "legacy",
  SINGLE_BU: "singleBU",
  MULTI_BU: "multiBU"
}