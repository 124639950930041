// 9fbef606107a605d69c0edbcd8029e5d

import { GetCountryCode } from "root/libs/core-libs/src";
import { countriesWithWrongMonthName } from "root/libs/constants/src";

export default function getDeliveryDate(
  intl,
  estimatedDeliveryTime,
  nativeDateTimeFormat,
  nativeLanguageCode
) {
  const countryCode = GetCountryCode(intl.locale);
  const options = { weekday: "short", month: "long", day: "numeric" };
  let deliveryDate;
  let instanceOfDate;
  if (!estimatedDeliveryTime) {
    deliveryDate = intl.formatMessage({
      id: "CardAndSummary.noDeliveryInfoHeadline",
    });
  } else if (countriesWithWrongMonthName.includes(countryCode)) {
    /** This condition is added to show date in the correct browser language.
    Some non-native English speaking countries displays months with wrong names
    when used `en-XX` is the locale on certain browsers (Safari iOS).
    `en-US` used here for displaying the day after the month, GB does the opposite.
    If we introduce more Arabic speaking countries we'll have to add them to this.
    */
    instanceOfDate = new Intl.DateTimeFormat("en-US", options);
    deliveryDate = instanceOfDate.format(estimatedDeliveryTime);
  } else if (nativeDateTimeFormat && !nativeLanguageCode) {
    instanceOfDate = new Intl.DateTimeFormat(nativeDateTimeFormat, options);
    deliveryDate = instanceOfDate.format(estimatedDeliveryTime);
  } else {
    deliveryDate = intl.formatDate(estimatedDeliveryTime, options);
  }
  return deliveryDate;
}
