// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for OfferProperties
 *
 */

import styled from "styled-components";
import { dhlVarColors, breakpoints, grid } from "root/libs/ui-styleguide/src";
import { Headline } from "root/libs/ui-components/src";
import { Text } from "@dhl-official/react-ui-library";

export const PropertiesWrapper = styled.div`
  margin-bottom: ${(props) =>
    props.variationType === "WCCMultiBUOfferCard" ? "10px;" : "30px;"};
  display: flex;

  @media print {
    margin-top: 0;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-right: ${(props) =>
      props.variationType === "WCCOfferCard" ? grid.gutter.s : false};
    width: ${(props) =>
      props.variationType === "WCCOfferCard" ? "50%" : false};
    padding: ${(props) =>
      props.variationType === "WCCMultiBUOfferCard" ? "0 20px 0;" : "0;"};
  }
`;

export const PropertiesContent = styled.div`
  ul {
    margin-bottom: 0;
    margin-top: 14px;

    li::before {
      width: 20px;
    }

    li::after {
      margin-left: 0px;
      background-color: ${(props) =>
        props.variationType === "WCCMultiBUOfferCard"
          ? `${dhlVarColors.primary}`
          : null};
    }
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-left: 0;
    margin-right: ${grid.gutter.s};
  }

  @media print {
    & ul li {
      margin-bottom: 0rem;
    }
  }
`;

export const PropertiesHeadline = styled(Headline)`
  margin-bottom: 5px;
`;

export const PropertiesText = styled(Text)`
  margin: 0;
  line-height: 1.5em;
`;
