// 9fbef606107a605d69c0edbcd8029e5d

/*
 *
 * RtlSupport reducer
 *
 */

import { handleActions } from "redux-actions";
import { enableRtlSupport as enableRtlSupportAction } from "./actions";

const initialState = {
  enabled: false,
};

const enableRtlSupport = (state, { payload: enabled = true }) => ({
  ...state,
  enabled,
});

export default handleActions(
  {
    [enableRtlSupportAction]: enableRtlSupport,
  },
  initialState
);
