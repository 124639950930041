// 9fbef606107a605d69c0edbcd8029e5d

import { takeLatest, all, fork, select, put, call } from "redux-saga/effects";
import isEmpty from "lodash/isEmpty";
import isString from "lodash/isString";
import { logging } from "root/libs/core-libs/src";
import * as tradeLaneSelectors from "one-time-shipment/src/containers/TradeLane/selectors";
import { RUN_CLIENT_STORAGE_ACTION } from "./constants";
import { clearClientStorageActions } from "./actions";
import { makeStoreInitialSetSelector } from "./selectors";
import { makeShipmentLaneInitialSetSelection } from "../ShipmentLane/selectors";
import { prefillInitialSet as prefillTradeLane } from "../TradeLane/actions";
import {
  prefillInitialSet as prefillShipmentLane,
  showShipmentLane,
} from "../ShipmentLane/actions";
import { selectSelectedSegment } from "../SpotShipmentPage/selectors";
import { prefillSegment } from "../SpotShipmentPage/actions";

export function setLocalStorage(analyticsUserId, dataToStore) {
  localStorage.setItem(analyticsUserId, JSON.stringify(dataToStore));
}

export function removeLocalStorage(analyticsUserId) {
  localStorage.removeItem(analyticsUserId);
}

export function* handleClientStorage() {
  try {
    const {
      action,
      analyticsUserId,
      wannaRunClientStorageAction,
    } = yield select(makeStoreInitialSetSelector());

    const storedItem = localStorage.getItem(analyticsUserId);
    const storedItemExist = !!storedItem && isString(storedItem);

    let dataToStore = {};

    if (action === "set") {
      const selectedSegment = yield select(selectSelectedSegment);
      const shipmentLaneInitialSet = yield select(
        makeShipmentLaneInitialSetSelection(selectedSegment)()
      );

      dataToStore = {
        tradeLane: {
          documentsOptionValue: yield select(
            tradeLaneSelectors.selectDocumentsOptionValue
          ),
          initialSet: yield select(
            tradeLaneSelectors.selectTradeLaneInitialSet
          ),
          originDestinationFlipped: yield select(
            tradeLaneSelectors.selectIsOriginDestinationFlipped
          ),
        },
        shipmentLane: shipmentLaneInitialSet,
        selectedSegment,
      };

      if (!storedItem && wannaRunClientStorageAction && !isEmpty(dataToStore)) {
        yield call(setLocalStorage, analyticsUserId, dataToStore);
      }
    }

    let retrievedData;

    if (storedItemExist && action === "get" && wannaRunClientStorageAction) {
      retrievedData = JSON.parse(storedItem);

      yield put(
        prefillSegment({ segment: { id: retrievedData.selectedSegment } })
      );
      yield put(
        prefillTradeLane(
          tradeLaneSelectors.decodeTradeLaneToState(retrievedData.tradeLane)
        )
      );
      yield put(prefillShipmentLane(retrievedData.shipmentLane));
      yield put(showShipmentLane(retrievedData.selectedSegment));
    }

    if (storedItemExist && action === "remove" && wannaRunClientStorageAction) {
      yield call(removeLocalStorage, analyticsUserId);
    }

    yield put(clearClientStorageActions());
  } catch (error) {
    yield call(logging.warn, error);
    yield put(clearClientStorageActions());
  }

  return true;
}

export function* takeRunClientStorage() {
  yield takeLatest(RUN_CLIENT_STORAGE_ACTION, handleClientStorage);
}

export default function* sagas() {
  yield all([fork(takeRunClientStorage)]);
}
