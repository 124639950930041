// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for VatInfoMessage
 *
 */

import styled from "styled-components";
import { key } from "styled-theme";
import { Link } from "@dhl-official/react-ui-library";

export const StyledMessage = styled.span`
  color: ${key("colors.primary")};
  background-color: ${key("colors.backgroundColorInverted")};
  border: 1px solid ${key("colors.primary")};
  padding: 4px 10px;
  font-size: 10px;
  line-height: 1;
`;

export const StyledMessageWCC = styled(Link)`
  font-size: ${key("fonts.mobile.h5.size")};
  line-height: ${key("fonts.mobile.h5.lineHeight")};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  };
`;
