// 9fbef606107a605d69c0edbcd8029e5d

/*
 *
 * RtlSupport actions
 *
 */

import { createAction } from "redux-actions";
import { ENABLE_RTL_SUPPORT } from "./constants";

export const enableRtlSupport = createAction(ENABLE_RTL_SUPPORT);
