// 9fbef606107a605d69c0edbcd8029e5d

/**
 * Renders a headline with given priority
 */

import React from "react";
import PropTypes from "prop-types";

import { headlineStyles } from "./styled";

export default class Headline extends React.PureComponent {
  static propTypes = {
    dataTestid: PropTypes.string,
    /** String that allows us to add a css defined class */
    className: PropTypes.string,
    /** A number between 1 and 5.
     * It's going to be use to decide which level of headline to use.
     * (1-6) */
    priority: PropTypes.number.isRequired,
    /**
     * Selecting: h will generate a <h> tag. Will be of the level you defined in priority.
     *  Selecting: span will generate a span with the looks of the h level of the priority you selected.
     *  Selecting: p will generate a p with the looks of the h level of the priority you selected.
     */
    headlineDisplayType: PropTypes.oneOf(["h", "span", "p"]),
    /** Label to render */
    children: PropTypes.any.isRequired,
    /** If true, headline text will be centered */
    center: PropTypes.bool,
    /** If true, headline text will be centered only in desktop */
    centerInDesktop: PropTypes.bool,
    /** If true, headline text will be centered only in mobile */
    centerInMobile: PropTypes.bool,
    color: PropTypes.oneOf(["red", "default"]),
    weight: PropTypes.oneOf(["", "200", "300", "400", "700", "800"]),
    noMargin: PropTypes.bool,
    /** If set will set the headline to inline-block */
    inlined: PropTypes.bool,
  };

  static defaultProps = {
    dataTestid: undefined,
    className: "",
    center: false,
    centerInMobile: false,
    centerInDesktop: false,
    color: "default",
    weight: "800",
    noMargin: false,
    inlined: false,
    headlineDisplayType: "p",
  };

  render() {
    const {
      dataTestid,
      priority,
      className,
      children,
      center,
      centerInDesktop,
      centerInMobile,
      color,
      weight,
      noMargin,
      inlined,
      headlineDisplayType,
      ...extraProps
    } = this.props;

    const isHTag = headlineDisplayType === "h";
    // create a 'custom' tag to dynamically set the tagname based on the priority parameter
    const HeadlineTag = isHTag
      ? `${headlineDisplayType}${priority}`
      : headlineDisplayType;
    const StyledHeadline = headlineStyles.withComponent(HeadlineTag);
    const levelClass = !isHTag ? `L${priority}` : "";

    return (
      <StyledHeadline
        data-testid={dataTestid}
        className={`${className} ${levelClass}`}
        weight={weight}
        center={center}
        fontColor={color}
        noMargin={noMargin}
        centerInDesktop={centerInDesktop}
        centerInMobile={centerInMobile}
        inlined={inlined}
        {...extraProps}
      >
        {children}
      </StyledHeadline>
    );
  }
}
