// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * ShipmentLaneComponent
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { IntlPropType, valueFormatters } from "root/libs/core-libs/src";

import { selectTrackingKey } from "one-time-shipment/src/containers/Tracking/selectors";
import { SEGMENT_TYPES } from "one-time-shipment/src/containers/SpotShipmentPage/constants";
import PresetDefaultIconPrivate from "iconsTwoColors/cx/DHL_Preset-Default.svg";
import PresetDefaultIconBusiness from "iconsTwoColors/cx/DHL_Preset-Default-Business.svg";
import ResponsiveRender from "one-time-shipment/src/components/Layout/ResponsiveRender";
import DimensionSelectorSmall from "one-time-shipment/src/components/ShipmentItem/DimensionSelectorSmall";
import DimensionSelectorLarge from "one-time-shipment/src/components/ShipmentItem/DimensionSelectorLarge";

const COPY_ID_PREFIX = "DimensionSelector";

class DimensionSelector extends React.PureComponent {
  static propTypes = {
    /** Translations library  */
    intl: IntlPropType.isRequired,
    /** This function needs to receive the event target. This should be a valid DOM input so we can use CheckValidity in the reducer */
    onDimensionChange: PropTypes.func.isRequired,
    onDimensionBlur: PropTypes.func.isRequired,
    onPresetSelected: PropTypes.func.isRequired,

    heightLabel: PropTypes.string.isRequired,
    widthLabel: PropTypes.string.isRequired,
    lengthLabel: PropTypes.string.isRequired,

    heightValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    widthValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    lengthValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    heightValidationRules: PropTypes.object,
    widthValidationRules: PropTypes.object,
    lengthValidationRules: PropTypes.object,
    quantityValidationRules: PropTypes.object,
    heightError: PropTypes.shape({
      name: PropTypes.string,
      hasError: PropTypes.bool,
      isValid: PropTypes.bool,
      feedbackMessageId: PropTypes.string,
    }),
    widthError: PropTypes.shape({
      name: PropTypes.string,
      hasError: PropTypes.bool,
      isValid: PropTypes.bool,
      feedbackMessageId: PropTypes.string,
    }),
    lengthError: PropTypes.shape({
      name: PropTypes.string,
      hasError: PropTypes.bool,
      isValid: PropTypes.bool,
      feedbackMessageId: PropTypes.string,
    }),
    quantityError: PropTypes.shape({
      name: PropTypes.string,
      hasError: PropTypes.bool,
      isValid: PropTypes.bool,
      feedbackMessageId: PropTypes.string,
    }),
    presetSize: PropTypes.string,
    /** Units to be used in the component. */
    unitSystem: PropTypes.object.isRequired,
    selectedUnitSystem: PropTypes.string.isRequired,
    trackEvent: PropTypes.func,
    /** If set to true, AdvancedSettings will be shown. */
    isBusiness: PropTypes.bool,
    weight: PropTypes.string,
    weightError: PropTypes.shape({
      isValid: PropTypes.bool,
    }),
    quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onQuantityChange: PropTypes.func,
    onQuantityBlur: PropTypes.func,
    onCheckboxChange: PropTypes.func,
    settingsNonStackable: PropTypes.bool,
    spotShipmentPresetSizes: PropTypes.shape({
      weightBreakThreshold: PropTypes.object,
      private: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
          value: PropTypes.string,
          label: PropTypes.string,
          icon: PropTypes.string,
          values: PropTypes.shape({
            length: PropTypes.string,
            width: PropTypes.string,
            height: PropTypes.string,
            presetSize: PropTypes.string,
          }),
        })
      ),
      business: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
          value: PropTypes.string,
          label: PropTypes.string,
          icon: PropTypes.string,
          values: PropTypes.shape({
            length: PropTypes.string,
            width: PropTypes.string,
            height: PropTypes.string,
            presetSize: PropTypes.string,
          }),
        })
      ),
    }).isRequired,
    isQuantitySelectorEnabled: PropTypes.bool.isRequired,
    itemIndex: PropTypes.number,
    addItemDescriptionId: PropTypes.string,
    /* Defines the displayed list of presets */
    segmentForPresets: PropTypes.oneOf(Object.values(SEGMENT_TYPES)).isRequired,
    /* Boolean to disable advanced settings checkboxes when min weight is not met */
    isCheckboxDisabledBecauseOfWeight: PropTypes.bool,
  };

  static defaultProps = {
    heightValue: 0,
    widthValue: 0,
    lengthValue: 0,
    heightValidationRules: {},
    widthValidationRules: {},
    lengthValidationRules: {},
    quantityValidationRules: {},
    heightError: {
      name: "",
      hasError: false,
      isValid: false,
      feedbackMessageId: "",
    },
    widthError: {
      name: "",
      hasError: false,
      isValid: false,
      feedbackMessageId: "",
    },
    lengthError: {
      name: "",
      hasError: false,
      isValid: false,
      feedbackMessageId: "",
    },
    quantityError: {
      name: "",
      hasError: false,
      isValid: false,
      feedbackMessageId: "",
    },
    presetSize: "",
    trackEvent: () => {},
    isBusiness: false,
    weight: "",
    weightError: {
      hasError: false,
    },
    quantity: 1,
    onQuantityChange: () => {},
    onQuantityBlur: () => {},
    onCheckboxChange: () => {},
    settingsNonStackable: false,
    itemIndex: 0,
    addItemDescriptionId: "",
    isCheckboxDisabledBecauseOfWeight: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isShowInfoMessage: false,
      boxIcon: props.isBusiness
        ? PresetDefaultIconBusiness
        : PresetDefaultIconPrivate,
      // Set to true on presetChange, and to false on change.
      forceShowSuccessState: {
        width: false,
        height: false,
        length: false,
      },
      // Since we can have multiple items with these three fields,
      // Only the fields that needed to be rounded up are stored here
      isShowRoundedUpMessage: {},
    };
    this.dimensionKeyPaths = {
      length: "",
      width: "",
      height: "",
    };
    this.heightFieldRef = React.createRef();
    this.customTabRef = React.createRef();
  }

  componentDidMount() {
    const { itemIndex } = this.props;
    this.trackingKeySelector = selectTrackingKey;

    setTimeout(() => {
      this.dimensionKeyPaths = {
        length: this.trackingKeySelector(
          `spotShipment.initialSet.items[${itemIndex}].length`
        ),
        width: this.trackingKeySelector(
          `spotShipment.initialSet.items[${itemIndex}].width`
        ),
        height: this.trackingKeySelector(
          `spotShipment.initialSet.items[${itemIndex}].height`
        ),
      };
    }, 0);
  }

  setHeightFocus = (preset) => {
    if (preset.height === "") {
      // Check if the custom tab is present and has to be clicked first
      if (this.customTabRef.current) {
        this.customTabRef.current.click();
      }
      // Timeout because focus doesn't work right after the tab gets changed
      setTimeout(() => {
        this.heightFieldRef.current.focus();
      });
    }
  };

  onDimensionChange = (event) => {
    const { onDimensionChange, selectedUnitSystem } = this.props;

    const { forceShowSuccessState } = this.state;

    this.setState({
      forceShowSuccessState: {
        ...forceShowSuccessState,
        [event.target.name]: false,
      },
    });

    onDimensionChange(event.target, selectedUnitSystem);
  };

  onDimensionBlur = (event) => {
    const { onDimensionBlur, selectedUnitSystem } = this.props;

    const { forceShowSuccessState } = this.state;

    this.setState({
      forceShowSuccessState: {
        ...forceShowSuccessState,
        [event.target.name]: false,
      },
    });

    onDimensionBlur(event.target, selectedUnitSystem);
  };

  onPresetSelected = (presetValues) => {
    const { onPresetSelected, selectedUnitSystem } = this.props;

    const { forceShowSuccessState } = this.state;

    this.setState({
      forceShowSuccessState: {
        ...forceShowSuccessState,
        height: true,
        length: true,
        width: true,
      },
    });

    onPresetSelected(presetValues, selectedUnitSystem);
    this.setBoxIcon(presetValues.presetSize);
    this.setHeightFocus(presetValues);
  };

  // Ignoring because we mock the svg imports in Jest.
  // As of now we dont have a way to know if the icon loaded changed in a test
  /* istanbul ignore next */
  setBoxIcon = (presetSize) => {
    const { spotShipmentPresetSizes, segmentForPresets } = this.props;

    const icon =
      segmentForPresets === SEGMENT_TYPES.business
        ? PresetDefaultIconBusiness
        : PresetDefaultIconPrivate;

    const currentPreset = spotShipmentPresetSizes[segmentForPresets].find(
      (preset) => preset.value === presetSize
    );

    this.setState({
      boxIcon: currentPreset?.svg || icon,
    });
  };

  /** Rounds up numbers to the next integer when necessary
   * Since the user can add more than one item, the three field names are dynamic
   * We have to store the rounded up field names somewhere and flag {true|false}
   * to show the feedback message that the number has been formatted.
   */
  onDimensionBlurValueFormatter = (event) => {
    const { value, name } = event.target;
    const { unitSystem, selectedUnitSystem } = this.props;
    const { isShowRoundedUpMessage } = this.state;
    let isRoundedUp = false;
    let formattedValue = value;

    formattedValue = formattedValue.trim();
    formattedValue = valueFormatters.floatingPointWithoutZeroValueFormatter(
      formattedValue
    );
    formattedValue = valueFormatters.stripUnitNameValueFormatter({
      value: formattedValue,
      unit: unitSystem[selectedUnitSystem].length,
    });
    formattedValue = valueFormatters.roundToIntegerValueFormatter(
      formattedValue
    );

    if (formattedValue !== value) {
      isRoundedUp = true;
    }
    this.setState({
      isShowRoundedUpMessage: {
        ...isShowRoundedUpMessage,
        [name]: isRoundedUp,
      },
    });

    return Number.isNaN(formattedValue) ? value : formattedValue;
  };

  toggleInfoMessage = (event) => {
    const { presetSize } = this.props;
    const { target } = event;

    if (target.value === "" && presetSize) {
      this.setState((prevState) => ({
        isShowInfoMessage: !prevState.isShowInfoMessage,
      }));
    } else {
      this.setState({
        isShowInfoMessage: false,
      });
    }
  };

  render() {
    const {
      heightLabel,
      widthLabel,
      lengthLabel,
      heightValidationRules,
      widthValidationRules,
      lengthValidationRules,
      quantityValidationRules,
      heightError,
      widthError,
      lengthError,
      quantityError,
      presetSize,
      unitSystem,
      intl,
      trackEvent,
      isBusiness,
      weight,
      weightError,
      quantity,
      onQuantityChange,
      onQuantityBlur,
      onCheckboxChange,
      settingsNonStackable,
      heightValue,
      widthValue,
      lengthValue,
      spotShipmentPresetSizes,
      isQuantitySelectorEnabled,
      itemIndex,
      addItemDescriptionId,
      segmentForPresets,
      isCheckboxDisabledBecauseOfWeight,
      selectedUnitSystem,
    } = this.props;

    const {
      boxIcon,
      forceShowSuccessState,
      isShowInfoMessage,
      isShowRoundedUpMessage,
    } = this.state;

    const validationRules = isBusiness
      ? {
          quantityValidationRules: quantityValidationRules.business,
          lengthValidationRules: lengthValidationRules.business,
          widthValidationRules: widthValidationRules.business,
          heightValidationRules: heightValidationRules.business,
        }
      : {
          quantityValidationRules: quantityValidationRules.private,
          lengthValidationRules: lengthValidationRules.private,
          widthValidationRules: widthValidationRules.private,
          heightValidationRules: heightValidationRules.private,
        };

    const heightFeedbackInfoMessage = {
      message: intl.formatMessage(
        { id: `${COPY_ID_PREFIX}.heightValueMissingInfoMessage` },
        {
          fieldName: intl.formatMessage({
            id: `${COPY_ID_PREFIX}.heightLabelErrorMessage`,
          }),
        }
      ),
      isShowInfoMessage,
    };

    /** The requirement is to have a message with a line break.
     * DUIL currently supports only strings in the feedback message for form validation.
     * We can change our propTypes to string or array, but this still throws propType errors
     * Until then, we have to use intl and replace {br} with a text line break.
     * On mobile, the CSS is set to `white-space: pre-line`, which work as as expected.
     * On tablet and desktop this is not necessary as the space breaks the text in two periods.
     */
    const roundedUpInfoMessage = {
      message: intl.formatMessage(
        { id: "SS.validations.decimalsRoundedUp" },
        {
          br: "\n",
        }
      ),
      isShowInfoMessage: isShowRoundedUpMessage,
    };

    return (
      <ResponsiveRender
        render={(isMobile) =>
          isMobile ? (
            <DimensionSelectorSmall
              headline={
                isBusiness
                  ? intl.formatMessage({ id: `${COPY_ID_PREFIX}.headline` })
                  : intl.formatMessage({
                      id: `${COPY_ID_PREFIX}.headlinePrivate`,
                    })
              }
              customSizesFieldsetDescribedByCopy={intl.formatMessage({
                id: `${COPY_ID_PREFIX}.screenReaderLabel_customSizesDescribedBy`,
              })}
              onDimensionChange={this.onDimensionChange}
              onDimensionBlur={this.onDimensionBlur}
              onPresetSelected={this.onPresetSelected}
              heightLabel={heightLabel}
              widthLabel={widthLabel}
              lengthLabel={lengthLabel}
              heightError={heightError}
              widthError={widthError}
              lengthError={lengthError}
              toggleInfoMessage={this.toggleInfoMessage}
              heightFeedbackInfoMessage={heightFeedbackInfoMessage}
              roundedUpInfoMessage={roundedUpInfoMessage}
              quantityError={quantityError}
              heightValue={heightValue}
              widthValue={widthValue}
              lengthValue={lengthValue}
              intl={intl}
              presetSize={presetSize}
              unitSystem={unitSystem}
              selectedUnitSystem={selectedUnitSystem}
              heightValidationRules={validationRules.heightValidationRules}
              widthValidationRules={validationRules.widthValidationRules}
              lengthValidationRules={validationRules.lengthValidationRules}
              iconToRender={boxIcon}
              dimensionKeyPaths={this.dimensionKeyPaths}
              trackEvent={trackEvent}
              forceShowSuccessState={forceShowSuccessState}
              isBusiness={isBusiness}
              presetSizes={spotShipmentPresetSizes}
              onDimensionBlurValueFormatter={this.onDimensionBlurValueFormatter}
              weight={weight}
              isWeightValid={weightError.isValid}
              quantity={quantity}
              onQuantityChange={onQuantityChange}
              quantityValidationRules={validationRules.quantityValidationRules}
              onQuantityBlur={onQuantityBlur}
              onCheckboxChange={(input) =>
                onCheckboxChange(input, selectedUnitSystem)
              }
              settingsNonStackable={settingsNonStackable}
              heightFieldRef={this.heightFieldRef}
              customTabRef={this.customTabRef}
              isQuantitySelectorEnabled={isQuantitySelectorEnabled}
              itemIndex={itemIndex}
              addItemDescriptionId={addItemDescriptionId}
              segmentForPresets={segmentForPresets}
              isCheckboxDisabledBecauseOfWeight={
                isCheckboxDisabledBecauseOfWeight
              }
            />
          ) : (
            <DimensionSelectorLarge
              headline={
                isBusiness
                  ? intl.formatMessage({ id: `${COPY_ID_PREFIX}.headline` })
                  : intl.formatMessage({
                      id: `${COPY_ID_PREFIX}.headlinePrivate`,
                    })
              }
              customSizesFieldsetDescribedByCopy={intl.formatMessage({
                id: `${COPY_ID_PREFIX}.screenReaderLabel_customSizesDescribedBy`,
              })}
              onDimensionChange={this.onDimensionChange}
              onDimensionBlur={this.onDimensionBlur}
              onPresetSelected={this.onPresetSelected}
              heightLabel={heightLabel}
              widthLabel={widthLabel}
              lengthLabel={lengthLabel}
              heightError={heightError}
              toggleInfoMessage={this.toggleInfoMessage}
              heightFeedbackInfoMessage={heightFeedbackInfoMessage}
              roundedUpInfoMessage={roundedUpInfoMessage}
              widthError={widthError}
              lengthError={lengthError}
              quantityError={quantityError}
              heightValue={heightValue}
              widthValue={widthValue}
              lengthValue={lengthValue}
              intl={intl}
              presetSize={presetSize}
              unitSystem={unitSystem}
              selectedUnitSystem={selectedUnitSystem}
              heightValidationRules={validationRules.heightValidationRules}
              widthValidationRules={validationRules.widthValidationRules}
              lengthValidationRules={validationRules.lengthValidationRules}
              iconToRender={boxIcon}
              dimensionKeyPaths={this.dimensionKeyPaths}
              trackEvent={trackEvent}
              forceShowSuccessState={forceShowSuccessState}
              isBusiness={isBusiness}
              presetSizes={spotShipmentPresetSizes}
              onDimensionBlurValueFormatter={this.onDimensionBlurValueFormatter}
              weight={weight}
              isWeightValid={weightError.isValid}
              quantity={quantity}
              onQuantityChange={onQuantityChange}
              quantityValidationRules={validationRules.quantityValidationRules}
              onQuantityBlur={onQuantityBlur}
              onCheckboxChange={(input) =>
                onCheckboxChange(input, selectedUnitSystem)
              }
              settingsNonStackable={settingsNonStackable}
              heightFieldRef={this.heightFieldRef}
              customTabRef={this.customTabRef}
              isQuantitySelectorEnabled={isQuantitySelectorEnabled}
              itemIndex={itemIndex}
              addItemDescriptionId={addItemDescriptionId}
              segmentForPresets={segmentForPresets}
              isCheckboxDisabledBecauseOfWeight={
                isCheckboxDisabledBecauseOfWeight
              }
            />
          )
        }
      />
    );
  }
}

export default injectIntl(DimensionSelector);
