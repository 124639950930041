// 9fbef606107a605d69c0edbcd8029e5d

import { css } from "styled-components";

export default css`
  *,
  a:active,
  a:hover {
    outline: 1px solid transparent;
  }

  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }
`;
