// 9fbef606107a605d69c0edbcd8029e5d

const createShareCode = (shareDataFromProps) => {
  const stringifiedData = JSON.stringify(shareDataFromProps);
  const encodedData = encodeURIComponent(stringifiedData);
  const base64EncodedData = btoa(encodedData);
  return base64EncodedData;
};

const createShareUrl = (encodedShareCode) => {
  let currentUrl = "";
  currentUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  return `${currentUrl}?share=${encodedShareCode}`;
};

export { createShareCode, createShareUrl };
