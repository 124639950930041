// 9fbef606107a605d69c0edbcd8029e5d

import { createSelector } from "reselect";
import { SEGMENT_TYPES } from "one-time-shipment/src/containers/SpotShipmentPage/constants";

const shipmentLaneSelector = (state) => state.shipmentLane;

const filterItemsForPrivateShipment = (items) =>
  items.map((item) => ({
    weight: item.weight,
    height: item.height,
    length: item.length,
    width: item.width,
    quantity: item.quantity,
    presetSize: item.presetSize,
    unitSystem: item.unitSystem,
  }));

export const makeBusinessShipmentLaneInitialSetSelection = () =>
  createSelector(
    shipmentLaneSelector,
    (shipmentLane) => shipmentLane.initialSet
  );

export const makePrivateShipmentLaneInitialSetSelection = () =>
  createSelector(shipmentLaneSelector, (shipmentLane) => ({
    items: filterItemsForPrivateShipment(shipmentLane.initialSet.items),
  }));

const makeShipmentLaneInitialSetSelection = (selectedSegment) =>
  selectedSegment === SEGMENT_TYPES.business
    ? makeBusinessShipmentLaneInitialSetSelection
    : makePrivateShipmentLaneInitialSetSelection;

export const makeValiditySelection = () =>
  createSelector(shipmentLaneSelector, (shipmentLane) => shipmentLane.validity);

export const makeSelectedUnitSystemSelection = () =>
  createSelector(
    shipmentLaneSelector,
    (shipmentLane) => shipmentLane.selectedUnitSystem
  );

const selectCalculateTotalShipmentWeight = (state) =>
  state.shipmentLane.initialSet.items
    .map((item) => item.weight * item.quantity)
    .reduce((a, b) => a + b, 0);

export {
  makeShipmentLaneInitialSetSelection,
  selectCalculateTotalShipmentWeight,
};
