// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * BypassBlock
 *
 */

import React from "react";
import scrollToComponent from "react-scroll-to-component";
import PropTypes from "prop-types";
import { trackContentInteraction } from "root/libs/core-libs/src";
import arrowUp from "icons/navigation/arrow-up.svg";
import { IconWrapper, BypassBlockWrapper } from "./styled";

const BypassBlock = ({
  innerRef,
  tabIndex,
  copyString,
  showCopyInside,
  dataTestid,
  reference,
  utfDataObject,
  name,
}) => {
  const onClick = () => {
    scrollToComponent(reference.current, {
      offset: -100,
      align: "top",
    });
    trackContentInteraction(utfDataObject);
  };

  return (
    <BypassBlockWrapper className="bypassBlock automation-bypassBlock">
      <IconWrapper
        ref={innerRef}
        ariaLabel={copyString}
        onClick={onClick}
        data-testid={dataTestid}
        tabIndex={tabIndex}
        role="button"
        icon={arrowUp}
        name={name}
        buttonStyle="custom"
        showCopyInside={showCopyInside}
      >
        {copyString}
      </IconWrapper>
    </BypassBlockWrapper>
  );
};

BypassBlock.propTypes = {
  /** React ref that will get focus when you click on BypassBlock */
  reference: PropTypes.object,
  /** React ref that will get passed to the BypassBlock so you can perform some DOM actions on it */
  innerRef: PropTypes.object,
  tabIndex: PropTypes.string,
  dataTestid: PropTypes.string,
  showCopyInside: PropTypes.bool,
  copyString: PropTypes.string.isRequired,
  name: PropTypes.string,
  utfDataObject: PropTypes.object,
};

BypassBlock.defaultProps = {
  reference: null,
  innerRef: null,
  dataTestid: undefined,
  tabIndex: "0",
  showCopyInside: true,
  name: "bypass-btn",
  utfDataObject: {},
};

export default BypassBlock;
