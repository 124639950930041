// 9fbef606107a605d69c0edbcd8029e5d

export const CHANGE_LANGUAGE = "shared/Localization/CHANGE_LANGUAGE";
export const LOAD_COPY_TEXT = "shared/Localization/LOAD_COPY_TEXT";
export const LOAD_COPY_TEXT_FAILURE =
  "shared/Localization/LOAD_COPY_TEXT_FAILURE";
export const LOAD_COPY_TEXT_SUCCESS =
  "shared/Localization/LOAD_COPY_TEXT_SUCCESS";
export const SET_COPY_TEXT_FROM_STORAGE =
  "shared/Localization/SET_COPY_TEXT_FROM_STORAGE";
export const SET_COUNTRY = "shared/Localization/SET_COUNTRY";
export const SETUP_LOCALE_DATA = "shared/Localization/SETUP_LOCALE_DATA";
export const SETUP_LOCALE_DATA_FAILURE =
  "shared/Localization/SETUP_LOCALE_DATA_FAILURE";
export const SETUP_LOCALE_DATA_SUCCESS =
  "shared/Localization/SETUP_LOCALE_DATA_SUCCESS";
