// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * LinkAndIcon
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { key } from "styled-theme";
import forwardIcon from "icons/navigation/linkarrow.svg";
import arrowDown from "icons/navigation/arrow-down.svg";
import Icon from "../../Icons/Icon";
import { StyledLink, StyledText } from "./styled";

export default class LinkAndIcon extends React.PureComponent {
  static propTypes = {
    /** Test ID */
    dataTestid: PropTypes.string,
    /** Label of the link */
    label: PropTypes.string.isRequired,
    /** Link to the section */
    href: PropTypes.string.isRequired,
    /* Extra styles to position the badge */
    extraStyles: PropTypes.array,
    /* Link text alignment. Can be: 'flex-end', 'flex-start' or 'center'. Default: 'flex-start' */
    linkTextAlignment: PropTypes.oneOf(["flex-end", "flex-start", "center"]),
    /** Set the size of the icon. Can be: small or medium. Default: small */
    iconSize: PropTypes.oneOf(["small", "medium"]),
    /** You can render a close or a forward icon. Default: forward */
    iconName: PropTypes.oneOf(["forward", "arrowDown"]),
    /** String with an alternative fill color for the icon -
     * Accepts a function when you get the
     * color from `import { key } from 'styled-theme';` */
    fillColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    /* Accessibility */
    ariaLabel: PropTypes.string,
    /* tabindex */
    tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    linkTextAlignment: "flex-start",
    iconName: "forward",
    extraStyles: [],
    iconSize: "small",
    fillColor: key("colors.primary"),
  };

  render() {
    const {
      label,
      href,
      extraStyles,
      linkTextAlignment,
      iconSize,
      iconName,
      fillColor,
      ariaLabel,
      tabIndex,
      dataTestid,
    } = this.props;

    const iconToRender = iconName === "forward" ? forwardIcon : arrowDown;
    return (
      <StyledLink
        dataTestid={dataTestid}
        tabIndex={tabIndex}
        href={href}
        linkTextAlignment={linkTextAlignment}
        extraStyles={extraStyles}
        ariaLabel={ariaLabel}
      >
        <StyledText linkTextAlignment={linkTextAlignment}>{label}</StyledText>
        <Icon
          useAs="decorative"
          size={iconSize}
          icon={iconToRender}
          fillColor={fillColor}
        />
      </StyledLink>
    );
  }
}
