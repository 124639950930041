// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * DropdownField
 *
 */

import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import { usePopper } from "react-popper";
import { breakpoints } from "root/libs/ui-styleguide/src";
import { useKeysWithMenu } from "./hooks/useKeysWithMenu";
import useOutsideClick from "./hooks/useOutsideClick";
import {
  ContentContainer,
  StyledPlaceholder,
  StyledLabel,
  LabelPlaceholderContainer,
  Options,
  Container,
  HiddenInput,
  ArrowContainer,
  ArrowIcon,
  OptionListItem,
  TextSrOnly,
} from "./styled";

const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
  window.HTMLInputElement.prototype,
  "value"
).set;

const COMPUTED_DROPDOWN_HEIGHT = "51px";
const COMPUTED_DROPDOWN_HEIGHT_MOBILE = "63px";

// eslint-disable-next-line react/prop-types
const Arrow = ({ open }) => (
  <ArrowContainer>
    <ArrowIcon open={open} />
  </ArrowContainer>
);

export const Option = styled(OptionListItem).attrs({
  role: "option",
})`
  color: #000;
  cursor: pointer;
  font-size: calc(1.6 * 1rem);
  padding: 0.8rem calc(1.6 * 1rem);

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding: calc(1.6 * 0.875rem) calc(1.6 * 1rem);
  }

  &[aria-disabled="true"] {
    cursor: default;
  }
`;

export const OptionsContainer = styled.div`
  background: #fff;
  border: calc(1.6 * 0.125rem) solid
    rgba(0, 0, 0, ${({ disabled }) => (disabled ? 0.4 : 1)});
  border-radius: 4px;
  border-start-end-radius: 0;
  border-start-start-radius: 0;
  border-top: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
  z-index: 3;

  &[data-popper-placement="top"] {
    border: calc(1.6 * 0.125rem) solid
      rgba(0, 0, 0, ${({ disabled }) => (disabled ? 0.4 : 1)});
    border-bottom: none;
    border-end-end-radius: 0;
    border-end-start-radius: 0;
    border-radius: 4px;

    transform: translateY(
      calc(-${COMPUTED_DROPDOWN_HEIGHT_MOBILE} + 2px)
    ) !important;

    @media screen and (min-width: ${breakpoints.desktop}) {
      transform: translateY(
        calc(-${COMPUTED_DROPDOWN_HEIGHT} + 2px)
      ) !important;
    }

    & + ${ContentContainer} {
      &[data-open="true"] {
        border: calc(1.6 * 0.125rem) solid #000;
        padding: 0 calc(calc(1.6 * 1rem) - 1px);
        border-radius: 4px;
        border-start-end-radius: 0;
        border-start-start-radius: 0;
        border-top-color: transparent;
      }
    }
  }

  &[data-popper-placement="bottom"] {
    transform: translateY(
      calc(${COMPUTED_DROPDOWN_HEIGHT_MOBILE} - 1px)
    ) !important;
    @media screen and (min-width: ${breakpoints.desktop}) {
      transform: translateY(calc(${COMPUTED_DROPDOWN_HEIGHT} - 1px)) !important;
    }
  }
`;

const DropdownField = ({
  children,
  className,
  optionsContainerClassName,
  dataTestid,
  dataTracking,
  disabled,
  id,
  label,
  ariaDescribedBy,
  ariaLabel,
  ariaDescribe,
  name,
  value: propValue,
  isBlock,
  onChange,
  onClick,
  onOptionClick,
  onOpen,
  onClose,
  placeholder,
}) => {
  const value = propValue;
  const containerRef = useRef();
  const hiddenInputRef = useRef();
  const [open, setOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement);
  const ariaDescribeId = `${id}-instructions`;
  const combinedAriaDescribedBy = ariaDescribe
    ? ariaDescribeId
    : ariaDescribedBy;

  // When user clicks outside the multiselect
  useOutsideClick(containerRef, () => setOpen(false));

  /* eslint-disable no-unused-expressions */
  useEffect(() => {
    if (!open) {
      referenceElement?.blur();
    }
    referenceElement?.focus();
  }, [open]);

  const triggerOnChange = (newValue) => {
    nativeInputValueSetter.call(hiddenInputRef.current, newValue);
    hiddenInputRef.current.dispatchEvent(
      new Event("change", { bubbles: true })
    );
  };

  const handleValueChange = (eventValue) => {
    triggerOnChange(eventValue);
  };

  const handleOnContainerClick = (close) => {
    if (disabled && !open) return;

    const isOpen = close ? false : !open;
    setOpen(isOpen);

    if (isOpen) {
      onOpen();
    } else {
      onClose();
    }
  };

  const handleItemClick = ({ props: { value: clickedValue } }) => (e) => {
    e?.preventDefault();
    e?.stopPropagation();

    onOptionClick?.(clickedValue);
    handleValueChange(clickedValue);
    handleOnContainerClick(true);
  };

  const {
    activeIndex,
    setActiveIndex,
    handleKeyDown: menuKeyEventHandler,
    setMenuRef,
  } = useKeysWithMenu(
    (selectedValue) => {
      handleItemClick({
        props: {
          value: selectedValue,
        },
      })();
    },
    () => handleOnContainerClick(true),
    "active",
    false,
    open
  );

  const handleKeyDown = (e) => {
    if (!open) handleOnContainerClick();

    menuKeyEventHandler(e);
  };

  const selectedOptions = [];
  const optionIds = [];
  const items = React.Children.toArray(children)
    .filter((c) => React.isValidElement(c))
    .flat()
    .map((child, index) => {
      const {
        props: { value: childValue, children: itemNode, ...restElemProps },
      } = child;
      const item = {
        label: itemNode,
        value: childValue,
      };
      const selected = value === childValue;
      if (selected) {
        selectedOptions.push(item);
      }

      const optionId = `${name}-option-${index}`;
      optionIds.push(optionId);

      const props = {
        "aria-posinset": index + 1,
        "aria-selected": selected,
        "aria-disabled": disabled,
        id: optionId,
        onClick: disabled ? null : handleItemClick(child),
        onFocus: disabled ? null : () => setActiveIndex(index),
        onMouseOver: disabled ? null : () => setActiveIndex(index),
        value: undefined,
        "data-value": childValue,
      };
      if (child.type === "option") {
        return React.createElement(
          Option,
          {
            ...restElemProps,
            ...props,
          },
          itemNode
        );
      }
      return React.cloneElement(child, props);
    })
    .filter((o) => !!o)
    .map((c, i) =>
      React.cloneElement(c, {
        // eslint-disable-next-line
        key: i,
        "aria-setsize": optionIds.length,
      })
    );

  const optionsId = `${name}-options`;
  const labelId = `${name}-label`;
  return (
    <Fragment>
      <Container isBlock={isBlock} ref={containerRef} data-disabled={disabled}>
        {open && (
          <OptionsContainer
            className={optionsContainerClassName}
            disabled={disabled}
            style={styles.popper}
            dataTestid={`${dataTestid}-menu-container`}
            ref={setPopperElement}
            {...attributes.popper} //eslint-disable-line
          >
            <Options id={optionsId} ref={setMenuRef}>
              {items}
            </Options>
          </OptionsContainer>
        )}
        <ContentContainer
          aria-describedby={combinedAriaDescribedBy}
          aria-label={ariaLabel || label}
          aria-haspopup="listbox"
          aria-expanded={open}
          aria-owns={optionsId}
          aria-activedescendant={
            open && activeIndex > -1 ? optionIds[activeIndex] : undefined
          }
          role="button"
          tabIndex="0"
          id={`${id}-select`}
          className={className}
          ref={setReferenceElement}
          data-open={open}
          data-disabled={disabled}
          onClick={(e) => {
            handleOnContainerClick();
            onClick(e);
          }}
          onKeyDown={handleKeyDown}
        >
          <TextSrOnly id={ariaDescribeId}>{ariaDescribe}</TextSrOnly>
          <HiddenInput
            ref={hiddenInputRef}
            id={`${id}-hidden-input`}
            name={name}
            defaultValue={value}
            dataTestid={`${dataTestid}-hidden-input`}
            data-tracking={dataTracking}
            onChange={onChange}
          />

          <LabelPlaceholderContainer aria-hidden="true">
            <StyledLabel id={labelId} htmlFor={`${id}-hidden-input`}>
              {label}
            </StyledLabel>
            <StyledPlaceholder itemSelected={value && selectedOptions.length}>
              {placeholder ||
                (value && selectedOptions.length && selectedOptions[0].label)}
            </StyledPlaceholder>
          </LabelPlaceholderContainer>
          <Arrow open={open} />
        </ContentContainer>
      </Container>
    </Fragment>
  );
};

export { StyledPlaceholder, StyledLabel, ContentContainer };

DropdownField.propTypes = {
  /** An **optional** prop defining the list of reference ids (separated by space), recommended when you want to show some errormessage on your field */
  ariaDescribedBy: PropTypes.string,
  /** An **optional** Aria describe prop read by screen readers, used for accessibility to pass instructions to user */
  ariaDescribe: PropTypes.string,
  /** An **optional** Aria label prop read by screen readers, used for accessibility by the search variant */
  ariaLabel: PropTypes.string,
  /** An **optional** test-id prop used to target the Dropdown component for testing */
  dataTestid: PropTypes.string,
  /** An **optional** prop holding the tracking value attached to the Dropdown as a data-tracking attribute. */
  dataTracking: PropTypes.string,
  /** An **optional** prop that sets whether the Dropdown is in a disabled state */
  disabled: PropTypes.bool,
  /** A **required** id prop for the Dropdown component  */
  id: PropTypes.string.isRequired,
  /** An **optional** name prop for the Dropdown component */
  name: PropTypes.string,
  /** An **optional** classname prop for the Dropdown component */
  className: PropTypes.string,
  /** An **optional** classname prop for the Dropdown Options Container */
  optionsContainerClassName: PropTypes.string,
  /** An **optional** label prop for the Dropdown component */
  label: PropTypes.string,
  /** A **required**  prop holding children of type `<Option>` */
  children: PropTypes.node.isRequired,
  /** An **optional** prop holding the array of selected values in case of multiselect and string value in case of single select */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  /** An **optional** prop flag to control if the Dropdown has a 100% width or set width */
  isBlock: PropTypes.bool,
  /** An **optional** onChange callback handler */
  onChange: PropTypes.func,
  /** An **optional** onClick callback handler */
  onClick: PropTypes.func,
  /** An **optional** onClick callback for an option, value of the clicked item is passed as callback parameter */
  onOptionClick: PropTypes.func,
  /** An **optional** onOpen callback handler */
  onOpen: PropTypes.func,
  /** An **optional** onClose callback handler */
  onClose: PropTypes.func,
  /** An **optional** prop holding children that will render on `<StyledPlaceholder>` */
  placeholder: PropTypes.node,
};

DropdownField.defaultProps = {
  isBlock: false,
  value: "",
  onChange: Function.prototype,
  onClick: Function.prototype,
  onOptionClick: Function.prototype,
  onOpen: Function.prototype,
  onClose: Function.prototype,
};

export default injectIntl(DropdownField);
