// 9fbef606107a605d69c0edbcd8029e5d

export default {
  /*
    A required string that will be used by the store data personalization,
    to do all the operations with the users's initial set data.
    This function gets the visitor ID.
    _satellite.getVisitorId().getMarketingCloudVisitorID()
  */
  analyticsUserId: "12345",
  /* Defines the A/B testing version to show */
  abTesting: "control",
  /* Campaign funnel information */
  funnel: "",
  /* Toggles the regular shipment teaser */
  isRegularUser: false,
  /* Possible values: set, get, remove or empty */
  storeInitialSetAction: "",
  /* Defines the Canary version to show and to set the request headers */
  canaryVersion: "",
  /* Custom Country Config object with different experience */
  experience: "",
};
