// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * SegmentControl
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import scrollToComponent from "react-scroll-to-component";
import {
  trackContentInteraction,
} from "root/libs/core-libs/src";
import {
  UTF_EVENT_CONTENT_NAMES,
  UTF_EVENT_TYPES,
  UTF_EVENT_INTERACTIONS,
  UTF_EVENT_CONTENT_POSITION,
} from "root/libs/constants/src";
import SkeletonOr from "one-time-shipment/src/containers/SkeletonOr";
import {
  SegmentControlWrapper,
  SegmentedListWrapper,
  SegmentedControlButton,
} from "./styled";


function scrollToSegment(segmentReference) {
  scrollToComponent(segmentReference.current, {
    offset: -100,
    align: "top",
  });
}

function handleUTF(newSegmentSelection, selectedSegment) {
  const { id } = newSegmentSelection;
  if (id !== selectedSegment) {
    // only when new segment is selected
    trackContentInteraction({
      name: UTF_EVENT_CONTENT_NAMES.CHANGE_SEGMENT,
      type: UTF_EVENT_TYPES.BUTTON,
      interaction: UTF_EVENT_INTERACTIONS.CLICK,
      position: UTF_EVENT_CONTENT_POSITION.TRADE_LANE,
      context: id,
    });
  }
}

const SegmentControl = ({
  segments,
  className,
  onClickHandler,
  selectedSegment,
  segmentReference,
  dataTrackingKeyPath,
}) => {
  return (
    <SegmentControlWrapper className={className} role="tablist">
      {segments.map((segment, index) => [
        <SegmentedListWrapper
          key={`${segment.id}list`}
          numberOfTabs={segments.length}
          aria-controls="tradelane-panel"
        >
          <SegmentedControlButton
            key={`${segment.id}link`}
            id={segment.id}
            role="tab"
            aria-selected={selectedSegment === segment.id}
            data-testid={`spot-shipment-${segment.id}-tab`}
            ref={index === 0 ? segmentReference : null}
            data-tracking={dataTrackingKeyPath}
            aria-labelledby={segment.id}
            href={`#${segment.id}-tab`}
            onClick={(event) => {
              event.preventDefault();
              handleUTF(segment, selectedSegment);
              onClickHandler(segment);
              scrollToSegment(segmentReference);
            }}
          >
            <SkeletonOr width={150}>
              <span className="labelText">{segment.headlineString}</span>
            </SkeletonOr>
          </SegmentedControlButton>
        </SegmentedListWrapper>,
      ])}
    </SegmentControlWrapper>
  );
};

function propsAreEqual(prevSegment, nextSegment) {
  return prevSegment.selectedSegment === nextSegment.selectedSegment;
}

SegmentControl.prototype.propTypes = {
  className: PropTypes.string,
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      headlineString: PropTypes.string,
    })
  ).isRequired,
  onClickHandler: PropTypes.func,
  selectedSegment: PropTypes.string.isRequired,
  segmentReference: PropTypes.object,
  /** String indicating the path to track in the tracking contract */
  dataTrackingKeyPath: PropTypes.string,
};

SegmentControl.prototype.defaultProps = {
  className: "",
  segmentReference: React.createRef(),
  onClickHandler: () => {},
  dataTrackingKeyPath: "",
};

export default SegmentControl;
