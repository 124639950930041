// 9fbef606107a605d69c0edbcd8029e5d

import { createSelector } from "reselect";
import every from "lodash/every";

const makeBackendsErrorSelection = (backends) =>
  createSelector(() => every(backends, ["status", "ERROR"]));

export { makeBackendsErrorSelection };

export default {
  makeBackendsErrorSelection,
};
