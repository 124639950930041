// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Form
 *
 */

import React from "react";
import { FormattedMessage } from "react-intl";
import scrollToComponent from "react-scroll-to-component";
import PropTypes from "prop-types";
import forEach from "lodash/forEach";
import remove from "lodash/remove";
import filter from "lodash/filter";
import { keyCodes } from "root/libs/constants/src";
import { validations } from "root/libs/core-libs/src";
import { Button, Text, TEXT_SIZES } from "@dhl-official/react-ui-library";
import messages from "./messages";
import { StyledButtonWrapper, StyledButtonHintText } from "./styled";

export default class Form extends React.PureComponent {
  static propTypes = {
    children: PropTypes.any.isRequired,
    onSubmit: PropTypes.func.isRequired,
    /** Use when you need your own Submit button
     * to trigger form.submit from your componet.
     * In there you would simply add to the onClick of your button
     * form.submit(); */
    formReference: PropTypes.object,
    validateDomElement: PropTypes.func.isRequired,
    className: PropTypes.string,
    isButtonDisabled: PropTypes.bool,
    buttonLabel: PropTypes.string.isRequired,
    buttonTestId: PropTypes.string,
    buttonAriaDescribedBy: PropTypes.string,
    showButtonHint: PropTypes.bool,
    buttonHintLabel: PropTypes.string,
    autoScrollToFirstValidationError: PropTypes.bool,
    isFormValid: PropTypes.oneOf([true, false, null]),
    /** object key path to an interactive element to the digitalLayer */
    buttonDataTrackingKey: PropTypes.string,
    /** Action to be dispached to update the tracking key of the button (if present) in the digitalLayer */
    trackEvent: PropTypes.func,
    /** The trackEventKey (one string without dots) that will be updated in the digitalLayer
     * by the trackEvent acti on.
     * The submit value will be
     * - in case of a successful submit: the current timestamp
     * - in case of a validation error: VALIDATION_ERRORS_SHOWN
     */
    trackEventKey: PropTypes.string,
    /** If true you are adding your own submit button in your own component */
    useMyOwnSubmitButton: PropTypes.bool,
    buttonAlignment: PropTypes.shape({
      horizontal: PropTypes.oneOf(["center", "left", "right"]),
      addGridContainer: PropTypes.bool,
    }),
    /** Additional key to look up and display specific message variations */
    copyNamespace: PropTypes.string.isRequired,
    dataTestid: PropTypes.string,
    wasFormSubmittedOnce: PropTypes.bool,
  };

  static defaultProps = {
    buttonTestId: undefined,
    className: "",
    showButtonHint: false,
    formReference: React.createRef(),
    autoScrollToFirstValidationError: false,
    buttonHintLabel: "",
    buttonAriaDescribedBy: "",
    isFormValid: null,
    isButtonDisabled: false,
    buttonDataTrackingKey: "",
    trackEvent: () => {},
    trackEventKey: "",
    buttonAlignment: {
      horizontal: "center",
      addGridContainer: false,
    },
    useMyOwnSubmitButton: false,
    dataTestid: undefined,
    wasFormSubmittedOnce: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      amountErrorFields: "0",
      wasFormSubmittedOnce: props.wasFormSubmittedOnce, // eslint-disable-line react/destructuring-assignment
    };
  }

  onSubmit = (event) => {
    event.preventDefault();
    const { form } = event.currentTarget;
    const {
      onSubmit,
      trackEvent,
      trackEventKey,
      useMyOwnSubmitButton,
    } = this.props;

    let formFields = form;
    let submitDelay = 10;
    if (useMyOwnSubmitButton && !formFields) {
      formFields = {
        elements: event.currentTarget,
      };
    }

    // If a Input field is focussed and the form is sent via
    // Enter key, the input is blured before to trigger validation
    if (document.activeElement.tagName === "INPUT") {
      submitDelay = 400;
      document.activeElement.blur();
    }

    this.setState({
      wasFormSubmittedOnce: true,
    });

    this.validateAll(formFields);

    setTimeout(() => {
      const { autoScrollToFirstValidationError } = this.props;
      if (this.isFormValid(formFields)) {
        onSubmit();
        trackEvent({ [trackEventKey]: Date.now() });
      } else {
        trackEvent({ [trackEventKey]: "VALIDATION_ERRORS_SHOWN" });
        const firstFieldWithError = this.getFirstNodeWithError(formFields);

        if (autoScrollToFirstValidationError) {
          this.scrollToFirstNode(firstFieldWithError);
        }
        // Focus first field with error
        if (firstFieldWithError) {
          firstFieldWithError.focus();
        }
      }
    }, submitDelay);
  };

  isFormValid = (form) => {
    const { isFormValid } = this.props;

    return isFormValid !== null ? isFormValid : form.checkValidity();
  };

  isGroupCheckbox = (elem) =>
    elem.type === "checkbox" && elem.required === true;

  getFirstNodeWithError = (form) => {
    const allElements = form.elements;
    const fieldsWithError = filter(
      allElements,
      (elem) =>
        elem.tagName !== "BUTTON" &&
        typeof elem.getAttribute === "function" &&
        elem.getAttribute("aria-invalid") === "true"
    );

    this.setState({ amountErrorFields: fieldsWithError.length });
    return fieldsWithError[0];
  };

  scrollToFirstNode = (firstFieldWithError) => {
    scrollToComponent(firstFieldWithError, {
      offset: -180,
      align: "top",
    });
  };

  submitFormOnReturn = () => {
    const { formReference } = this.props;

    const currentForm = formReference.current;
    const that = this;

    if (
      currentForm !== null &&
      currentForm.querySelectorAll("button").length === 0
    ) {
      currentForm.onkeydown = (e) => {
        const focusedElement = document.activeElement;
        if (
          e.keyCode === keyCodes.RETURN &&
          focusedElement.tagName === "INPUT"
        ) {
          // Exception for auto complete field. When the suggestion list is open
          // and one entry is focused, the form will not be submitted.
          if (
            focusedElement.getAttribute("aria-activedescendant") !== null &&
            focusedElement.getAttribute("aria-activedescendant").length > 0
          ) {
            return;
          }
          that.onSubmit(e);
        }
      };
    }
  };

  validateGroupOfRequiredCheckboxes = (allElements) => {
    const { validateDomElement } = this.props;

    const requiredCheckboxes = filter(allElements, this.isGroupCheckbox);
    const checkboxesToValidate = [];

    forEach(requiredCheckboxes, (element) => {
      const validationArgs = validations.extractValidationProperties(element);
      checkboxesToValidate.push(validationArgs);
    });

    if (checkboxesToValidate.length > 0) {
      validateDomElement(checkboxesToValidate);
    }
  };

  validateAll = (form) => {
    const allElements = form.elements;
    const elements = filter(allElements, (elem) => elem.tagName !== "BUTTON");

    const { validateDomElement } = this.props;

    remove(elements, this.isGroupCheckbox);
    this.validateGroupOfRequiredCheckboxes(allElements);

    forEach(elements, (element) => {
      const validationArgs = validations.extractValidationProperties(element);
      validateDomElement(validationArgs);
    });
  };

  render() {
    const {
      children,
      className,
      isButtonDisabled,
      buttonLabel,
      buttonTestId,
      buttonHintLabel,
      buttonAriaDescribedBy,
      showButtonHint,
      buttonDataTrackingKey,
      buttonAlignment,
      useMyOwnSubmitButton,
      formReference,
      isFormValid,
      copyNamespace,
      dataTestid,
    } = this.props;

    const { wasFormSubmittedOnce, amountErrorFields } = this.state;

    const buttonClasses = ["button-wrapper"];

    if (buttonAlignment.addGridContainer) {
      buttonClasses.push("grid-maxWidth");
    }

    let formExtraProps = {};
    if (useMyOwnSubmitButton) {
      formExtraProps = {
        onSubmit: this.onSubmit,
      };

      this.submitFormOnReturn();
    }

    return (
      <form
        ref={formReference}
        noValidate
        className={className}
        {...formExtraProps}
        data-testid={dataTestid}
      >
        {children}

        {!useMyOwnSubmitButton && (
          <StyledButtonWrapper
            className={buttonClasses.splice(" ")}
            buttonAlignment={buttonAlignment.horizontal}
          >
            {showButtonHint && (
              <StyledButtonHintText
                center
                doubleBottomSpacing
                maxWidth="300px"
                size={TEXT_SIZES.SMALL}
              >
                {buttonHintLabel}
              </StyledButtonHintText>
            )}
            <Button
              type="submit"
              value="button"
              onClick={this.onSubmit}
              isDisabled={isButtonDisabled}
              dataTestid={buttonTestId}
              data-tracking={buttonDataTrackingKey}
              aria-describedby={buttonAriaDescribedBy}
            >
              {buttonLabel}
            </Button>
          </StyledButtonWrapper>
        )}
        {!isFormValid && wasFormSubmittedOnce && (
          <Text ariaLive="assertive" role="status" className="visually-hidden">
            <FormattedMessage
              id={
                messages[`${copyNamespace}_screenReaderLabel_formErrorMessage`]
                  .id
              }
              values={{ numberOfErrors: amountErrorFields }}
            />
          </Text>
        )}
      </form>
    );
  }
}
