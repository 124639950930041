// 9fbef606107a605d69c0edbcd8029e5d

import { createAction } from "redux-actions";
import {
  LOAD_COUNTRY_SETTINGS_FAILURE,
  LOAD_COUNTRY_SETTINGS_SUCCESS,
  LOAD_COUNTRY_SETTINGS,
  SET_COUNTRY_SETTINGS_FROM_STORAGE,
} from "./constants";

export const loadCountrySettings = createAction(LOAD_COUNTRY_SETTINGS);
export const loadCountrySettingsFailure = createAction(
  LOAD_COUNTRY_SETTINGS_FAILURE
);
export const loadCountrySettingsSuccess = createAction(
  LOAD_COUNTRY_SETTINGS_SUCCESS
);
export const setCountrySettingsFromStorage = createAction(
  SET_COUNTRY_SETTINGS_FROM_STORAGE
);
