// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for OfferAddOns
 *
 */

import styled, { css } from "styled-components";
import { Headline } from "root/libs/ui-components/src";
import { dhlVarColors, breakpoints, grid } from "root/libs/ui-styleguide/src";
import { Accordion } from "@dhl-official/react-ui-library";

const lightBorder = `1px solid ${dhlVarColors.lightBorderColor};`;

export const AddOnsWrapper = styled.div`
  border-top: ${(props) =>
    props.variationType === "WCCMultiBUOfferCard" ? lightBorder : null};
  margin-bottom: 30px;

  padding-top: ${(props) =>
    props.variationType === "WCCMultiBUOfferCard" ? "15px 0 0 0;" : null};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: 10px;
    width: ${(props) =>
      props.variationType === "WCCOfferCard" ? "50%" : false};
    padding: ${(props) =>
      props.variationType === "WCCMultiBUOfferCard"
        ? "15px 10px 0 10px;"
        : null};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-bottom: 30px;
  }

  @media print {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const StyledHeadline = styled(Headline)`
  margin-bottom: 20px;
`;

export const StyledAccordion = styled(Accordion)`
  padding: 0;
`;

export const AddOnsContent = styled.div`
  .accordion {
    margin-bottom: 0;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-right: 0;
    margin-left: ${(props) =>
      props.variationType === "WCCOfferCard" ? false : grid.gutter.s};
  }
`;

export const OfferAddOnsContentExtraStyles = css`
  white-space: pre-line;
`;
