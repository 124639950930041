// 9fbef606107a605d69c0edbcd8029e5d

/*
 *
 * getOffers reducer
 *
 */

import { handleActions } from "redux-actions";
import { validations, trackContentInteraction } from "root/libs/core-libs/src";
import {
  UTF_EVENT_CONTENT_NAMES,
  UTF_EVENT_TYPES,
  UTF_EVENT_INTERACTIONS,
  UTF_EVENT_CONTENT_POSITION,
} from "root/libs/constants/src";
import {
  showOffersLane as showOffersLaneAction,
  hideOffersLane as hideOffersLaneAction,
  toggleModalError as toggleModalErrorAction,
  toggleModalExpress as toggleModalExpressAction,
  toggleBookingNotAllowedModal as toggleBookingNotAllowedModalAction,
  resetFreightP2PModalState as resetFreightP2PModalStateAction,
  toggleFreightP2PModal as toggleFreightP2PModalAction,
  validateFreightP2PModalFormFields as validateFreightP2PModalFormFieldsAction,
  onFreightP2PModalInformationChange as onFreightP2PModalInformationChangeAction,
  getFreightP2PModalRequestSent as getFreightP2PModalRequestSentAction,
  getFreightP2PModalRequestCompleted as getFreightP2PModalRequestCompletedAction,
  toggleModalSuccess as toggleModalSuccessAction,
  selectedFreightOfferId as selectedFreightOfferIdAction,
} from "./actions";

const initialState = {
  isOffersLaneVisible: false,
  idSelectedAddOn: "",
  isModalErrorVisible: false,
  isModalExpressVisible: false,
  isModalBookingNotAllowedVisible: false,
  isFreightP2PModalOpen: false,
  isWaitingForFreightModalResponse: false,
  freightModalSubmitSuccess: "",
  isModalSuccessVisible: false,
  selectedFreightOfferId: "",
  bookingNotAllowedModal: {},
  freightP2PModal: {
    companyName: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    additionalInfo: "",
  },
  validity: {
    companyName: {},
    firstName: {},
    lastName: {},
    email: {},
    phone: {},
    additionalInfo: {},
  },
};

const showOffersLane = (state) => ({
  ...state,
  isOffersLaneVisible: true,
});

const hideOffersLane = (state) => ({
  ...state,
  isOffersLaneVisible: false,
});

const toggleModalError = (state) => ({
  ...state,
  isModalErrorVisible: !state.isModalErrorVisible,
});

const toggleModalExpress = (state) => ({
  ...state,
  isModalExpressVisible: !state.isModalExpressVisible,
});

const toggleBookingNotAllowedModal = (state, { payload: data }) => ({
  ...state,
  isModalBookingNotAllowedVisible: !state.isModalBookingNotAllowedVisible,
  bookingNotAllowedModal: data,
});

const toggleFreightP2PModal = (state) => ({
  ...state,
  isFreightP2PModalOpen: !state.isFreightP2PModalOpen,
});

const selectedFreightOfferId = (state, { payload: data }) => ({
  ...state,
  selectedFreightOfferId: data,
});

const onFreightP2PModalInformationChange = (state, { payload: data }) => ({
  ...state,
  freightP2PModal: {
    ...state.freightP2PModal,
    ...data,
  },
});

const validateFreightP2PModalFormFields = (state, { payload: element }) => {
  const elementValidityState = validations.validateElement(element);
  let elementName = elementValidityState.name;

  if (elementName === undefined) {
    elementName = element.name;
  }

  let elementValidity = {};

  if (elementName) {
    elementValidity = {
      [elementName]: {
        hasError: elementValidityState.hasError,
        feedbackMessageId: elementValidityState.feedbackMessageId,
        isValid: elementValidityState.isValid,
        value: element.value,
      },
    };
  }

  return {
    ...state,
    freightP2PModal: {
      ...state.freightP2PModal,
    },
    validity: {
      ...state.validity,
      ...elementValidity,
    },
  };
};

const resetFreightP2PModalState = (state) => ({
  ...state,
  freightP2PModal: {
    ...initialState.freightP2PModal,
  },
  validity: {
    ...initialState.validity,
  },
});

const getFreightP2PModalRequestSent = (state, { payload }) => {
  // eslint-disable-next-line no-shadow
  const { selectedFreightOfferId } = state;

  const { selectedSegment, amount, currencyCode = "" } = payload;

  const attributes = {
    selectedFreightOfferId,
    amount,
    currencyCode,
  };

  trackContentInteraction({
    name: UTF_EVENT_CONTENT_NAMES.FORM_SUBMIT,
    type: UTF_EVENT_TYPES.BUTTON,
    interaction: UTF_EVENT_INTERACTIONS.CLICK,
    position: UTF_EVENT_CONTENT_POSITION.FREIGHT_P2P_MODAL,
    context: selectedSegment,
    attributes,
  });
  return {
    ...state,
    isWaitingForFreightModalResponse: true,
  };
};

const getFreightP2PModalRequestCompleted = (
  state,
  { payload: submitResult }
) => ({
  ...state,
  freightP2PModalSubmitSuccess: submitResult,
  isWaitingForFreightModalResponse: false,
});

const toggleModalSuccess = (state) => ({
  ...state,
  isModalSuccessVisible: !state.isModalSuccessVisible,
});

export default handleActions(
  {
    [showOffersLaneAction]: showOffersLane,
    [hideOffersLaneAction]: hideOffersLane,
    [toggleModalErrorAction]: toggleModalError,
    [toggleModalExpressAction]: toggleModalExpress,
    [toggleBookingNotAllowedModalAction]: toggleBookingNotAllowedModal,
    [resetFreightP2PModalStateAction]: resetFreightP2PModalState,
    [validateFreightP2PModalFormFieldsAction]: validateFreightP2PModalFormFields,
    [toggleFreightP2PModalAction]: toggleFreightP2PModal,
    [selectedFreightOfferIdAction]: selectedFreightOfferId,
    [onFreightP2PModalInformationChangeAction]: onFreightP2PModalInformationChange,
    [getFreightP2PModalRequestSentAction]: getFreightP2PModalRequestSent,
    [getFreightP2PModalRequestCompletedAction]: getFreightP2PModalRequestCompleted,
    [toggleModalSuccessAction]: toggleModalSuccess,
  },
  initialState
);
