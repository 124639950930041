// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * QuantityField
 *
 */

import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { keyCodes } from "root/libs/constants/src";
import { injectIntl } from "react-intl";
import { IntlPropType } from "root/libs/core-libs/src";
import { FeedbackMessage, Input } from "root/libs/ui-components/src";
import {
  StyledQuantityField,
  StyledButtonWrapper,
  StyledWrapper,
  StyledMinusButton,
  StyledPlusButton,
} from "./styled";

const COPY_ID_PREFIX = "QuantityField";

const QuantityField = ({
  className,
  name,
  feedback,
  interpolations,
  value,
  feedbackMessageIdPrefix,
  feedbackTrackingKeyPath,
  trackingKeyPaths,
  ariaLabel,
  ariaDescribedBy,
  intl,
  required,
  copyNamespace,
  maxValue,
  minValue,
  onBlur,
  onChange,
  totalSumIndicatorLabel,
  totalSumIndicatorValue,
  totalSumIndicatorUnitSystem,
  onMinusButtonClick,
  onPlusButtonClick,
}) => {
  const referenceObj = useRef(null);
  const [announceCurrentQuantity, setAnnounceCurrentQuantity] = useState({
    announceCurrentQuantity: false,
  });

  const onButtonClick = (event, reference, button) => {
    if (button === "minus") {
      onMinusButtonClick(event, reference);
    } else if (button === "plus") {
      onPlusButtonClick(event, reference);
    }

    // Add the current quantity message for screen readers only
    // when the buttons are used or value changed via keyboard.
    setAnnounceCurrentQuantity({ announceCurrentQuantity: true });
  };

  const onKeyDown = (event, reference) => {
    switch (event.keyCode) {
      case keyCodes.UP: // arrow up
      case keyCodes.PLUS: // plus key
        // Called when arrow up or plus is pressed
        event.preventDefault();
        onButtonClick(event, reference, "plus");
        break;
      case keyCodes.DOWN: // arrow down
      case keyCodes.MINUS: // minus key
        // Called when arrow down or minus is pressed
        event.preventDefault();
        onButtonClick(event, reference, "minus");
        break;
      default:
        break;
    }
  };

  const showErrorState = feedback.hasError ? feedback.hasError : false;
  const descriptionId = `${name}_quantity-description`;
  const borderColor = showErrorState
    ? "colors.errorColor"
    : "colors.inputFieldBorderColor";

  // The class name will allow us to adjust vertical
  // or horizontal spacing of this component within a form
  return (
    <StyledQuantityField className={className}>
      {announceCurrentQuantity && !feedback.hasError && (
        <span
          className="visually-hidden"
          aria-atomic="true"
          aria-live="assertive"
        >
          {intl.formatMessage({
            id: `${COPY_ID_PREFIX}.screenReaderLabel_announceQuantity`,
          })}{" "}
          {value}
          {value > 1
            ? `: ${totalSumIndicatorLabel} ${totalSumIndicatorValue} ${totalSumIndicatorUnitSystem}`
            : ""}
        </span>
      )}
      <span className="visually-hidden" id={descriptionId}>
        {intl.formatMessage({
          id: `${COPY_ID_PREFIX}.screenReaderLabel_describedBy`,
        })}
      </span>
      <StyledButtonWrapper>
        <StyledMinusButton
          data-testid="quantity-minus-button"
          onClick={(event) => onButtonClick(event, referenceObj, "minus")}
          aria-label={intl.formatMessage({
            id: `${COPY_ID_PREFIX}.screenReaderLabel_message_removeItem`,
          })}
          aria-describedby={name}
          type="button"
        >
          -
        </StyledMinusButton>
        <StyledWrapper hasError={showErrorState} borderColor={borderColor}>
          <Input
            type="text"
            inputMode="numeric"
            data-tracking={trackingKeyPaths}
            data-is-number="true"
            pattern="numberNoDecimals"
            data-min-value={minValue}
            data-max-value={maxValue}
            id={name}
            autoComplete="off"
            name={name}
            value={value.toString()}
            hasError={showErrorState}
            className={showErrorState ? "invalid" : ""}
            reference={referenceObj}
            onBlur={onBlur}
            onChange={onChange}
            onKeyDown={(event) => onKeyDown(event, referenceObj)}
            aria-describedby={`errorMessage_${name} ${descriptionId} ${ariaDescribedBy}`}
            required={required}
            aria-label={ariaLabel}
          />
        </StyledWrapper>
        <StyledPlusButton
          data-testid="quantity-plus-button"
          onClick={(event) => onButtonClick(event, referenceObj, "plus")}
          aria-label={intl.formatMessage({
            id: `${COPY_ID_PREFIX}.screenReaderLabel_message_addItem`,
          })}
          aria-describedby={name}
          type="button"
        >
          +
        </StyledPlusButton>
      </StyledButtonWrapper>
      {feedback.hasError && (
        <FeedbackMessage
          id={name}
          feedback={feedback}
          interpolations={interpolations}
          feedbackMessageIdPrefix={feedbackMessageIdPrefix}
          feedbackTrackingKeyPath={feedbackTrackingKeyPath}
          copyNamespace={copyNamespace}
        />
      )}
    </StyledQuantityField>
  );
};

QuantityField.propTypes = {
  /** Internationalization library */
  intl: IntlPropType.isRequired,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  feedback: PropTypes.shape({
    name: PropTypes.string,
    hasError: PropTypes.bool,
    isValid: PropTypes.bool,
    feedbackMessageId: PropTypes.string,
  }),
  interpolations: PropTypes.object,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  feedbackMessageIdPrefix: PropTypes.string,
  feedbackTrackingKeyPath: PropTypes.string,
  onMinusButtonClick: PropTypes.func,
  onPlusButtonClick: PropTypes.func,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  /** If ariaLabel is provided this will be set instead of label */
  ariaLabel: PropTypes.string,
  copyNamespace: PropTypes.string.isRequired,
  trackingKeyPaths: PropTypes.string,
  totalSumIndicatorLabel: PropTypes.string,
  totalSumIndicatorValue: PropTypes.number,
  totalSumIndicatorUnitSystem: PropTypes.string,
  ariaDescribedBy: PropTypes.string,
};

QuantityField.defaultProps = {
  className: "",
  value: null,
  feedback: {
    name: "",
    hasError: false,
    isValid: false,
    feedbackMessageId: "",
  },
  interpolations: {},
  onBlur: () => {},
  onChange: () => {},
  feedbackMessageIdPrefix: "",
  feedbackTrackingKeyPath: "",
  onMinusButtonClick: () => {},
  onPlusButtonClick: () => {},
  ariaLabel: "",
  ariaDescribedBy: "",
  required: false,
  trackingKeyPaths: "",
  minValue: null,
  maxValue: null,
  totalSumIndicatorLabel: "",
  totalSumIndicatorValue: 0,
  totalSumIndicatorUnitSystem: "",
};

/* eslint-enable react/no-multi-comp */
export default injectIntl(QuantityField);
