// 9fbef606107a605d69c0edbcd8029e5d

export default {
  inputControlHeight: "auto",
  buttonHeightInPx: "45px",
  buttonMinWidth: "210px",
  inputVerticalPaddingsBig: "9px",
  inputVerticalDouble: "24px",
  formFieldHeight: "76px",
  inputSidePaddings: "10px",
  maxWidthLimited: "80%",
  checkboxRadioLabelLeftDistance: 10,
};
