// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * TeaserBusinessAssistant
 *
 */

import React from "react";
import { IntlPropType } from "root/libs/core-libs/src";
import { Link, IlluFreightShipping } from "@dhl-official/react-ui-library";
import {
  CardWrapper,
  StyledHeadline,
  StyledText,
  ContentWrapper,
} from "./styled";

const COPY_ID_PREFIX = "TeaserBusinessAssistant";

export default function TeaserBusinessAssistant({ intl }) {
  return (
    <CardWrapper>
      <IlluFreightShipping />
      <ContentWrapper>
        <StyledHeadline priority={4}>
          {intl.formatMessage({
            id: `${COPY_ID_PREFIX}.headlineText`,
          })}
        </StyledHeadline>
        <StyledText>
          {intl.formatMessage({
            id: `${COPY_ID_PREFIX}.copyText`,
          })}
        </StyledText>
        <Link
          href={intl.formatMessage({
            id: `${COPY_ID_PREFIX}.buttonUrl`,
          })}
          withButtonStyle={{ variant: "primary" }}
          dataTestid="teaserBusinessAssistantButton"
        >
          {intl.formatMessage({
            id: `${COPY_ID_PREFIX}.buttonText`,
          })}
        </Link>
      </ContentWrapper>
    </CardWrapper>
  );
}

TeaserBusinessAssistant.propTypes = {
  /** Internationalization library */
  intl: IntlPropType.isRequired,
};
