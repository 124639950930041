// 9fbef606107a605d69c0edbcd8029e5d

import { createAction } from "redux-actions";
import {
  CLEAR_CLIENT_STORAGE_ACTIONS,
  DELETE_USER_INITIAL_SET,
  RESTORE_USER_INITIAL_SET,
  RUN_CLIENT_STORAGE_ACTION,
  SET_PERSONALIZATION_TOGGLES,
  STORE_USER_INITIAL_SET,
  TOGGLE_REGULAR_SHIPMENT_TEASER,
} from "./constants";

export const clearClientStorageActions = createAction(
  CLEAR_CLIENT_STORAGE_ACTIONS
);
export const deleteInitialSet = createAction(DELETE_USER_INITIAL_SET);
export const restoreInitialSet = createAction(RESTORE_USER_INITIAL_SET);
export const runClientStorageAction = createAction(RUN_CLIENT_STORAGE_ACTION);
export const setPersonalizationToggles = createAction(
  SET_PERSONALIZATION_TOGGLES
);
export const storeInitialSet = createAction(STORE_USER_INITIAL_SET);
export const toggleRegularShipmentTeaser = createAction(
  TOGGLE_REGULAR_SHIPMENT_TEASER
);
