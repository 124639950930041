// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * ShipmentLaneComponent
 *
 */

import React from "react";
import PropTypes from "prop-types";
import scrollToComponent from "react-scroll-to-component";
import {
  cookieBridge,
  validations,
  dates,
  removeIndexSuffix,
  IntlPropType,
  trackEngagementInteraction,
  trackContentInteraction,
} from "root/libs/core-libs/src";
import {
  CheckboxField,
  CHECKBOXFIELD_SIZES,
  TEXT_SIZES,
  Button,
} from "@dhl-official/react-ui-library";
import isNil from "lodash/isNil";
import { SEGMENT_TYPES } from "one-time-shipment/src/containers/SpotShipmentPage/constants";
import { ErrorBoundary, IconButtonTooltip } from "root/libs/ui-components/src";
// Loadable loads the components async
import DimensionSelector from "one-time-shipment/src/components/ShipmentItem/DimensionSelector";
import { colors } from "root/libs/ui-styleguide/src";
import plusIcon from "icons/cx/plus.svg";
import closeIcon from "icons/cx/close.svg";
import { injectIntl } from "react-intl";
import { AbTesting, CountrySettings } from "root/libs/ui-containers/src";
import { selectTrackingKey } from "one-time-shipment/src/containers/Tracking/selectors";
import {
  SS,
  COMMON,
  UTF_EVENT_PAGE_NAMES,
  UTF_EVENT_CONTENT_NAMES,
  UTF_EVENT_TYPES,
  UTF_EVENT_INTERACTIONS,
  UTF_EVENT_CONTENT_POSITION,
} from "root/libs/constants/src";

import UnitSelectorContainer from "../UnitSelector/container";
import {
  ShipmentLaneFormWrapper,
  StyledHeadline,
  ShipmentItemGridContainer,
  DimensionsContent,
  SubmitButtonCell,
  SettingsCheckboxWrapper,
  StyledCheckboxInfo,
  StyledWeightSelector,
  AddItemButtonCell,
  StyledIconButton,
  StyledItemIndexNumber,
  StyledCloseIcon,
  StyledHiddenCloseIconContainer,
  StyledHiddenCloseIcon,
  StyledTotalShipmentWeight,
  ToolTipText,
} from "./styled";

const { withCountry } = CountrySettings;

const COPY_ID_PREFIX = "ShipmentLaneComponent";
class ShipmentLaneComponent extends React.PureComponent {
  static propTypes = {
    abTestRunning: PropTypes.bool,
    /** Translations library  */
    intl: IntlPropType.isRequired,
    waitingForResponse: PropTypes.bool,
    items: PropTypes.array.isRequired,
    getOffers: PropTypes.func.isRequired,
    updateItem: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    clearDimensionFieldsValidationErrors: PropTypes.func.isRequired,
    /** Function to be called to check if the entered value is valid. Receives a valid DOM input element */
    validateFormFields: PropTypes.func.isRequired,
    isShipmentLaneVisibile: PropTypes.bool.isRequired,
    trackEvent: PropTypes.func,
    shouldScroll: PropTypes.bool,
    resetScrolling: PropTypes.func,
    validity: PropTypes.object.isRequired,
    isFormValid: PropTypes.bool.isRequired,
    offersLaneReference: PropTypes.object,
    prefillItemsValues: PropTypes.array,
    /** If set to true, AdvancedSettings and business presets will be shown */
    isBusiness: PropTypes.bool,
    documentsOptionValue: PropTypes.object,
    updateDocument: PropTypes.func.isRequired,
    settingsDangerousGoods: PropTypes.bool,
    backToShipmentLaneReference: PropTypes.object,
    backToTradeLaneReference: PropTypes.object,
    segmentReference: PropTypes.object,
    onCheckboxChange: PropTypes.func,
    settingsNonStackable: PropTypes.bool,
    configs: PropTypes.shape({
      isDangerousGoodsEnabled: PropTypes.bool,
      isQuantitySelectorEnabled: PropTypes.bool,
      isAddItemEnabled: PropTypes.bool,
      maxNumberOfShipmentItems: PropTypes.number,
    }).isRequired,
    addShipmentLaneItem: PropTypes.func,
    spotShipmentPresetSizes: PropTypes.shape({
      weightBreakThreshold: PropTypes.object,
      private: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
          value: PropTypes.string,
          label: PropTypes.string,
          icon: PropTypes.string,
          values: PropTypes.shape({
            length: PropTypes.string,
            width: PropTypes.string,
            height: PropTypes.string,
            presetSize: PropTypes.string,
          }),
        })
      ),
      business: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
          value: PropTypes.string,
          label: PropTypes.string,
          icon: PropTypes.string,
          values: PropTypes.shape({
            length: PropTypes.string,
            width: PropTypes.string,
            height: PropTypes.string,
            presetSize: PropTypes.string,
          }),
        })
      ),
    }).isRequired,
    removeShipmentLaneItem: PropTypes.func.isRequired,
    /* Define the preset collection that is shown in Business funnel when weight is still empty */
    defaultPresetSegmentForBusinessFunnel: PropTypes.string,
    /* Contains the total weight of all items of the shipment */
    totalShipmentWeight: PropTypes.number.isRequired,
    unitSystem: PropTypes.object.isRequired,
    selectedUnitSystem: PropTypes.string.isRequired,
    isUnitSwitchEnabled: PropTypes.bool,
    /* Holds the threshold weight value to compare the current total
     * weight against, that will enable several checkboxes in the Shipment Lane
     */
    shipmentLaneCheckboxStatusWeightThreshold: PropTypes.object.isRequired,
    pageName: PropTypes.string.isRequired,
    experience: PropTypes.string,
  };

  static defaultProps = {
    waitingForResponse: false,
    trackEvent: () => {},
    shouldScroll: false,
    resetScrolling: () => {},
    offersLaneReference: {},
    prefillItemsValues: [],
    isBusiness: false,
    settingsDangerousGoods: false,
    backToShipmentLaneReference: React.createRef(),
    backToTradeLaneReference: React.createRef(),
    segmentReference: React.createRef(),
    onCheckboxChange: () => {},
    settingsNonStackable: false,
    addShipmentLaneItem: () => {},
    defaultPresetSegmentForBusinessFunnel: "business",
    isUnitSwitchEnabled: false,
    abTestRunning: false,
    experience: "",
  };

  constructor(props) {
    super(props);
    this.formReference = React.createRef();
    this.headlineReference = React.createRef();
    this.shipmentLaneItemReferences = [React.createRef()];
    this.shipmentLaneFirstInputReferences = [React.createRef()];
    this.dimensionSelectorWithCountry = withCountry(DimensionSelector);
    this.weightSelectorWithCountry = withCountry(StyledWeightSelector);

    this.experience = props.experience;

    const {
      constants: { TYPES_OF_ABTESTVERSIONS },
    } = AbTesting;

    this.AbTestingTypes = TYPES_OF_ABTESTVERSIONS;

    const trackingKeySelector = selectTrackingKey;

    this.getOffersTrackingKeyPath = trackingKeySelector(
      "spotShipment.interactions.offersTriggers.getOffersClicked"
    );
    this.documentsCheckboxTrackingKeyPath = trackingKeySelector(
      "spotShipment.interactions.offersTriggers.documentsCheckboxChecked"
    );
    this.documentsCheckboxIconTrackingKeyPath = trackingKeySelector(
      "spotShipment.interactions.clickedOnDocumentsCheckboxIcon"
    );
    this.dangerousGoodsCheckboxTrackingKeyPath = trackingKeySelector(
      "spotShipment.initialSet.settingsDangerousGoods"
    );
    this.dangerousGoodsCheckboxIconTrackingKeyPath = trackingKeySelector(
      "spotShipment.interactions.clickedOnDangerousGoodsCheckboxIcon"
    );
    this.documentsCheckboxIconTrackEventKey = "clickedOnDocumentsCheckboxIcon";
    this.dangerousGoodsCheckboxIconTrackEventKey =
      "clickedOnDangerousGoodsCheckboxIcon";

    this.addShipmentLaneItemClickedTrackingKeyPath = trackingKeySelector(
      "spotShipment.interactions.clickedOnAddShipmentLaneItem"
    );
    this.removeShipmentLaneItemClickedTrackingKeyPath = trackingKeySelector(
      "spotShipment.interactions.clickedOnRemoveShipmentLaneItem"
    );
    this.addShipmentLaneItemClickedTrackEventKey =
      "clickedOnAddShipmentLaneItem";
    this.removeShipmentLaneItemClickedTrackEventKey =
      "clickedOnRemoveShipmentLaneItem";

    this.state = {
      isItemRemoved: false,
      documentsCheckboxDisabled: false,
      dangerousGoodsCheckboxDisabled: false,
    };
  }

  componentDidMount = () => {
    document.addEventListener("animationend", this.handleAnimationEnd);
  };

  componentDidUpdate = (prevProps) => {
    const {
      shouldScroll,
      resetScrolling,
      isShipmentLaneVisibile,
      trackEvent,
      isBusiness,
      pageName,
    } = this.props;

    const { SHIPMENT_LANE } = UTF_EVENT_PAGE_NAMES;

    if (
      isShipmentLaneVisibile !== prevProps.isShipmentLaneVisibile &&
      isShipmentLaneVisibile
    ) {
      trackEvent({ shipmentLaneRendered: true });

      trackEngagementInteraction({
        funnel: {
          name: pageName,
          type: UTF_EVENT_CONTENT_NAMES.CUSTOMER_JOURNEY,
          step: SHIPMENT_LANE,
          progress: UTF_EVENT_INTERACTIONS.LOAD,
        },
        content: {
          context: isBusiness ? SEGMENT_TYPES.business : SEGMENT_TYPES.private,
        }
      });
    }

    if (shouldScroll) {
      setTimeout(() => {
        scrollToComponent(this.headlineReference.current, {
          offset: -100,
          align: "top",
        });
        resetScrolling();
      }, 0);
    }
  };

  componentWillUnmount = () => {
    document.removeEventListener("animationend", this.handleAnimationEnd);
  };

  handleAnimationEnd = (event) => {
    const { removeShipmentLaneItem } = this.props;
    const { animationName, target } = event;
    if (animationName === "remove-item-hide") {
      const itemIndex = parseInt(target.id, 10);
      removeShipmentLaneItem(itemIndex);
      this.setState({ isItemRemoved: true });
    }
  };

  isDimensionField = (fieldName) =>
    ["length", "width", "height"].includes(fieldName);

  setTargetServiceCardCookie = () => {
    const { TARGET_SERVICE_CARD_COOKIE } = COMMON;
    const currentCookieValue =
      cookieBridge.getCookie(TARGET_SERVICE_CARD_COOKIE.name) || 0;

    if (currentCookieValue < TARGET_SERVICE_CARD_COOKIE.maxValue) {
      cookieBridge.setCookie(
        TARGET_SERVICE_CARD_COOKIE.name,
        +currentCookieValue + 1,
        TARGET_SERVICE_CARD_COOKIE.age
      );
    }
  };

  prepareUtfFormSubmitData = () => {
    const {
      items,
      settingsDangerousGoods,
      documentsOptionValue,
      isBusiness,
    } = this.props;
    const segment = isBusiness ? SEGMENT_TYPES.business : SEGMENT_TYPES.private;

    const output = {};

    items.map((d, i) =>
      Object.assign(output, {
        [`item${
          i + 1
        }`]: `weight:${d.weight}|length:${d.length}|width:${d.width}|height:${d.height}|isDocuments:${documentsOptionValue[segment]}|nonStackable:${d.settingsNonStackable}|dangerousGoods:${settingsDangerousGoods}|quantity:${d.quantity}`,
      })
    );

    return output;
  };

  onSubmit = () => {
    const {
      getOffers,
      onSubmit,
      trackEvent,
      offersLaneReference,
      waitingForResponse,
      isBusiness,
    } = this.props;

    if (!waitingForResponse) {
      getOffers();
      onSubmit();
      this.setTargetServiceCardCookie();
      trackEvent({ quoteEnded: dates.getNowIsoString() });
      const attributes = this.prepareUtfFormSubmitData();

      trackContentInteraction({
        name: UTF_EVENT_CONTENT_NAMES.FORM_SUBMIT,
        type: UTF_EVENT_TYPES.BUTTON,
        interaction: UTF_EVENT_INTERACTIONS.CLICK,
        position: UTF_EVENT_CONTENT_POSITION.SHIPMENT_LANE,
        context: isBusiness ? SEGMENT_TYPES.business : SEGMENT_TYPES.private,
        attributes,
      });

      scrollToComponent(offersLaneReference.current, {
        offset: -90,
        align: "top",
      });
    }
  };

  onWeightChange = (input, itemIndex, unitSystem) => {
    const {
      updateItem,
      isBusiness,
      shipmentLaneCheckboxStatusWeightThreshold,
      selectedUnitSystem,
      onCheckboxChange,
    } = this.props;

    const { name, value } = input;

    const newItemValues = {
      [name]: value,
      unitSystem,
    };

    updateItem({ itemIndex, newItemValues });
    if (
      isBusiness &&
      value < shipmentLaneCheckboxStatusWeightThreshold[selectedUnitSystem]
    ) {
      onCheckboxChange("settingsDangerousGoods", false);
      this.setState({ documentsCheckboxDisabled: false });
    }
  };

  disableOtherCheckbox = (name, status) => {
    // Make other checkbox disabled and unchecked
    if (name === "documents") {
      this.setState({ dangerousGoodsCheckboxDisabled: status });
    } else {
      this.setState({ documentsCheckboxDisabled: status });
    }
  };

  validateFormFields = (input) => {
    const { validateFormFields, isBusiness } = this.props;

    let validationProperties = input;
    // Before this function is executed, the same in the form is executed.
    // In there, the validationAttributes are added to the input
    // here we check that we dont extract them again
    if (!input.validationAttributes) {
      validationProperties = validations.extractValidationProperties(input);
    }

    // Derive item index from input name
    validationProperties.itemIndex = (input.name || "").split("-")[1] || 0;

    validationProperties.isBusiness = isBusiness;

    validateFormFields(validationProperties);
  };

  getSegmentForPresets = (itemIndex = 0) => {
    const {
      spotShipmentPresetSizes,
      isBusiness,
      items: {
        [itemIndex]: { weight },
      },
      defaultPresetSegmentForBusinessFunnel,
      selectedUnitSystem,
    } = this.props;

    if (isBusiness) {
      if (spotShipmentPresetSizes.weightBreakThreshold[selectedUnitSystem]) {
        if (weight !== "") {
          if (
            Number(weight.replace(",", ".")) <
            spotShipmentPresetSizes.weightBreakThreshold[selectedUnitSystem]
          ) {
            return SEGMENT_TYPES.private;
          }
          return SEGMENT_TYPES.business;
        }
        return defaultPresetSegmentForBusinessFunnel;
      }
      return SEGMENT_TYPES.business;
    }
    return SEGMENT_TYPES.private;
  };

  shouldPresetGetDeselected = (input) => {
    const { name, itemIndex, presetSize } = input;

    const { spotShipmentPresetSizes, selectedUnitSystem } = this.props;

    /** Don't deselect if changed dimension is height and preset does not contain height definition */
    const segmentForPresets = this.getSegmentForPresets(itemIndex);
    const currentPreset = spotShipmentPresetSizes[segmentForPresets].find(
      (preset) =>
        preset.presetSize === presetSize || preset.value === presetSize
    );
    if (
      name === "height" &&
      !!currentPreset &&
      (!("height" in currentPreset[selectedUnitSystem]) ||
        currentPreset[selectedUnitSystem].height === "")
    ) {
      return false;
    }

    return true;
  };

  updateItem = (input, itemIndex, unitSystem) => {
    const { updateItem, items } = this.props;

    const { name, value } = input;

    const newItemValues = {
      [name]: value,
      unitSystem,
    };

    const generalizedName = removeIndexSuffix(name);

    if (
      this.isDimensionField(generalizedName) &&
      !!items[itemIndex].presetSize &&
      value !== items[itemIndex][generalizedName]
    ) {
      const valuesForPresetCheck = {
        name: generalizedName,
        itemIndex,
        presetSize: items[itemIndex].presetSize,
      };
      if (this.shouldPresetGetDeselected(valuesForPresetCheck)) {
        newItemValues.presetSize = "";
      }
    }

    updateItem({ itemIndex, newItemValues });
  };

  weightErrorClicked = (event) => {
    event.preventDefault();
    const { segmentReference, backToTradeLaneReference } = this.props;

    const segmentReferenceDOMObject = segmentReference.current;
    scrollToComponent(backToTradeLaneReference.current, {
      offset: -120,
      align: "top",
    });
    segmentReferenceDOMObject.focus();
  };

  updateAndValidateItem = (input, itemIndex, unitSystem) => {
    this.validateFormFields(input, itemIndex);
    this.updateItem(input, itemIndex, unitSystem);
  };

  updatePresetSize = (itemSelected, itemIndex, unitSystem) => {
    const { clearDimensionFieldsValidationErrors, updateItem } = this.props;

    const newItemValues = {
      ...itemSelected,
      unitSystem,
    };

    updateItem({ itemIndex, newItemValues });
    clearDimensionFieldsValidationErrors(itemIndex);
  };

  onDocumentsChange = (event) => {
    const { updateDocument, trackEvent, isBusiness } = this.props;

    const value = event.target.checked;
    const segment = isBusiness ? SEGMENT_TYPES.business : SEGMENT_TYPES.private;

    trackEvent({ documentsCheckboxChecked: value });
    updateDocument({ value, segment });
    this.disableOtherCheckbox("documents", value);
  };

  onCheckboxChange = (event) => {
    const { onCheckboxChange } = this.props;

    const { name, checked } = event.target;

    onCheckboxChange(name, checked);
    this.disableOtherCheckbox(name, checked);
  };

  onQuantityChange = (itemIndex, unitSystem, input) => {
    const { updateItem } = this.props;
    const { value } = input;

    const newItemValues = { quantity: value };

    updateItem({ itemIndex, newItemValues, unitSystem });
    this.validateFormFields(input, itemIndex);
  };

  addShipmentLaneItem = (event) => {
    const { addShipmentLaneItem, trackEvent, items, isBusiness } = this.props;

    event.preventDefault();

    trackEvent({ [this.addShipmentLaneItemClickedTrackEventKey]: true });

    trackContentInteraction({
      name: UTF_EVENT_CONTENT_NAMES.ADD_ITEM,
      type: UTF_EVENT_TYPES.BUTTON,
      interaction: UTF_EVENT_INTERACTIONS.CLICK,
      position: UTF_EVENT_CONTENT_POSITION.SHIPMENT_LANE,
      context: isBusiness ? SEGMENT_TYPES.business : SEGMENT_TYPES.private,
    });

    const refToNextItem = this.shipmentLaneItemReferences[items.length];
    const refToFirstInput = this.shipmentLaneFirstInputReferences[items.length];

    addShipmentLaneItem();

    const timeOut = window.setTimeout(() => {
      if (refToNextItem.current) {
        scrollToComponent(refToNextItem.current, {
          offset: -100,
          align: "top",
        });
        if (refToFirstInput.current) {
          refToFirstInput.current.focus();
        }
      }
      window.clearTimeout(timeOut);
    }, 500);
  };

  removeShipmentLaneItemLocal = (itemClassName) => {
    const { trackEvent } = this.props;
    const deletedItem = document.getElementsByClassName(itemClassName)[0];
    deletedItem.classList.add("deleted-item");

    trackEvent({ [this.removeShipmentLaneItemClickedTrackEventKey]: true });
  };

  getCopy = (id, values, prefix = COPY_ID_PREFIX) => {
    const { intl } = this.props;
    if (
      this.experience &&
      this.experience !== "" &&
      !!intl.messages[`${prefix}.${id}_${this.experience}`]
    ) {
      return intl.formatMessage(
        { id: `${prefix}.${id}_${this.experience}` },
        values
      );
    }
    return intl.formatMessage({ id: `${prefix}.${id}` }, values);
  };

  render() {
    const {
      abTestRunning,
      intl,
      items,
      isShipmentLaneVisibile,
      trackEvent,
      validity,
      isFormValid,
      prefillItemsValues,
      isBusiness,
      documentsOptionValue,
      backToShipmentLaneReference,
      settingsDangerousGoods,
      configs,
      totalShipmentWeight,
      unitSystem,
      shipmentLaneCheckboxStatusWeightThreshold,
      selectedUnitSystem,
      isUnitSwitchEnabled,
    } = this.props;

    const {
      isItemRemoved,
      dangerousGoodsCheckboxDisabled,
      documentsCheckboxDisabled,
    } = this.state;

    const isCheckboxDisabledBecauseOfWeight =
      isBusiness &&
      totalShipmentWeight <
        shipmentLaneCheckboxStatusWeightThreshold[selectedUnitSystem];

    const nextItemIndex = items.length;
    this.shipmentLaneItemReferences[nextItemIndex] = React.createRef();
    this.shipmentLaneFirstInputReferences[nextItemIndex] = React.createRef();

    const documentsTooltipContent = (
      <React.Fragment>
        <ToolTipText size={TEXT_SIZES.TINY} weight={700} isParagraph>
          {this.getCopy("settingsTooltipHeader")}
        </ToolTipText>
        <ToolTipText size={TEXT_SIZES.TINY}>
          {this.getCopy("documentsTooltipText")}
        </ToolTipText>
      </React.Fragment>
    );

    const dangerousGoodsTooltipContent = (
      <React.Fragment>
        <ToolTipText size={TEXT_SIZES.TINY} weight={700} isParagraph>
          {this.getCopy("settingsTooltipHeader")}
        </ToolTipText>
        <ToolTipText size={TEXT_SIZES.TINY}>
          {this.getCopy("dangerousGoodsTooltipText")}
        </ToolTipText>
      </React.Fragment>
    );

    const renderHeadline = (type, headline, isWrapped = false) => (
      <AbTesting.Component
        type={type}
        componentToRender={() =>
          isWrapped ? (
            <div className="grid-maxWidthInner no-padding">
              <StyledHeadline
                weight="800"
                left
                priority={2}
                className="no-margin"
                headlineDisplayType="h"
                ref={this.headlineReference}
              >
                {this.getCopy(headline)}
              </StyledHeadline>
            </div>
          ) : (
            <StyledHeadline
              weight="800"
              center
              priority={2}
              headlineDisplayType="h"
              ref={this.headlineReference}
            >
              {this.getCopy(headline)}
            </StyledHeadline>
          )
        }
      />
    );

    let documentCheckboxState = false;

    if (!isNil(documentsOptionValue)) {
      documentCheckboxState = isBusiness
        ? documentsOptionValue[SEGMENT_TYPES.business]
        : documentsOptionValue[SEGMENT_TYPES.private];
    }

    return (
      <ErrorBoundary>
        {!!isShipmentLaneVisibile && (
          <ShipmentLaneFormWrapper
            copyNamespace={SS}
            className="grid-fluid no-padding"
            formReference={this.formReference}
            validateDomElement={(input) => this.validateFormFields(input)}
            onSubmit={this.onSubmit}
            buttonLabel={this.getCopy("submit")}
            buttonDataTrackingKey={this.getOffersTrackingKeyPath}
            trackEvent={trackEvent}
            trackEventKey="getOffersClicked"
            isFormValid={isFormValid}
            useMyOwnSubmitButton
            autoScrollToFirstValidationError
            ref={backToShipmentLaneReference}
            dataTestid="shipment-lane-form"
          >
            {abTestRunning ? (
              <React.Fragment>
                {renderHeadline(this.AbTestingTypes.control, "headline")}
                {renderHeadline(this.AbTestingTypes.tara, "headlineTara", true)}
              </React.Fragment>
            ) : (
              <StyledHeadline
                weight="800"
                center
                priority={2}
                headlineDisplayType="h"
                ref={this.headlineReference}
              >
                {this.getCopy("headline")}
              </StyledHeadline>
            )}
            <span className="visually-hidden" aria-live="assertive">
              {this.getCopy("screenReaderLabel_messageOnAppear")}
            </span>
            {isItemRemoved && (
              <span className="visually-hidden" aria-live="assertive">
                {this.getCopy("screenReaderLabel_messageOnRemoveItem", {
                  totalItems: validity.items.length,
                })}
              </span>
            )}
            {isUnitSwitchEnabled && (
              <UnitSelectorContainer
                unitSystem={unitSystem}
                selectedUnitSystem={selectedUnitSystem}
                trackEvent={trackEvent}
              />
            )}
            <div className="mx-wrapper">
              <ShipmentItemGridContainer
                className="no-padding"
                dimension="biDimensional"
                addMaxWidth
              >
                {validity.items.map((currentItemObject, itemIndex) => {
                  const { weight, height, length, width, presetSize } = items[
                    itemIndex
                  ];

                  let weightValue = weight;
                  let heightValue = height;
                  let lengthValue = length;
                  let widthValue = width;
                  let presetSizeValue = presetSize;

                  const currentItemIndex = itemIndex + 1;
                  const itemDescriptionId = `add-item-description-${currentItemIndex}`;
                  const totalItemsAmount = validity.items.length;
                  const segmentForPresets = this.getSegmentForPresets(
                    itemIndex
                  );

                  if (prefillItemsValues[itemIndex]) {
                    const prefilledItem = prefillItemsValues[itemIndex];
                    weightValue = prefilledItem.weight;
                    heightValue = prefilledItem.height;
                    lengthValue = prefilledItem.length;
                    widthValue = prefilledItem.width;
                    presetSizeValue = presetSize;
                  }
                  return (
                    <DimensionsContent
                      id={itemIndex}
                      key={currentItemObject.id}
                      dimension="biDimensional"
                      spanMobile="12"
                      columnStartMobile="1"
                      spanTablet="12"
                      columnStartTablet="1"
                      spanDesktop="10"
                      columnStart="2"
                      isBusiness={isBusiness}
                      dataTestid={`dimensions-content-card-${currentItemIndex}`}
                      isItemIndexVisible={configs.isAddItemEnabled}
                      ref={this.shipmentLaneItemReferences[itemIndex]}
                      className={`item-index-${currentItemIndex} ${
                        currentItemIndex > 1 && "added-item"
                      }`}
                    >
                      {configs.isAddItemEnabled && (
                        <React.Fragment>
                          <StyledItemIndexNumber
                            size={TEXT_SIZES.LARGE}
                            weight={800}
                            isParagraph
                          >
                            {`#${currentItemIndex}`}
                          </StyledItemIndexNumber>
                          <span
                            className="visually-hidden"
                            id={itemDescriptionId}
                          >
                            {this.getCopy(
                              "screenReaderLabel_shipmentItemNumber",
                              {
                                totalItems: totalItemsAmount,
                                itemNumber: currentItemIndex,
                              }
                            )}
                          </span>
                        </React.Fragment>
                      )}
                      {currentItemIndex > 1 && (
                        <span className="visually-hidden" aria-live="assertive">
                          {this.getCopy(
                            "screenReaderLabel_shipmentItemNumber",
                            {
                              totalItems: totalItemsAmount,
                              itemNumber: currentItemIndex,
                            }
                          )}
                        </span>
                      )}
                      {itemIndex > 0 && (
                        <StyledCloseIcon
                          icon={closeIcon}
                          isClickable
                          onClick={() =>
                            this.removeShipmentLaneItemLocal(
                              `item-index-${currentItemIndex}`
                            )
                          }
                          size="small"
                          fillHoverColor={colors.redCherryHover}
                          fillColor={colors.redScarlet}
                          label={this.getCopy("removeItemCta")}
                          useAs="standalone"
                          data-testid={`remove-shipment-item-button-${itemIndex}`}
                          tabIndex="0"
                          dataTracking={
                            this.removeShipmentLaneItemClickedTrackingKeyPath
                          }
                          itemAdded={totalItemsAmount > 1}
                        />
                      )}
                      <this.weightSelectorWithCountry
                        intl={intl}
                        weightLabel={
                          isBusiness
                            ? this.getCopy("weightHeadline")
                            : this.getCopy("weightHeadlinePrivate")
                        }
                        weightName={`weight-${itemIndex}`}
                        weightValue={weightValue}
                        onWeightChange={(item, currentUnitSystem) =>
                          this.onWeightChange(
                            item,
                            itemIndex,
                            currentUnitSystem
                          )
                        }
                        onBlur={(item, currentUnitSystem) =>
                          this.updateAndValidateItem(
                            item,
                            itemIndex,
                            currentUnitSystem
                          )
                        }
                        feedback={currentItemObject.weight}
                        trackEvent={trackEvent}
                        unitSystem={unitSystem}
                        selectedUnitSystem={selectedUnitSystem}
                        onWeightfeedbackLinkClick={this.weightErrorClicked}
                        iNeedTheseProps={["weightValidationRules"]}
                        isBusiness={isBusiness}
                        itemIndex={itemIndex}
                        addItemDescriptionId={
                          configs.isAddItemEnabled && totalItemsAmount > 1
                            ? itemDescriptionId
                            : ""
                        }
                        reference={
                          this.shipmentLaneFirstInputReferences[itemIndex]
                        }
                      />

                      <this.dimensionSelectorWithCountry
                        onDimensionBlur={(item, currentUnitSystem) =>
                          this.updateAndValidateItem(
                            item,
                            itemIndex,
                            currentUnitSystem
                          )
                        }
                        onDimensionChange={(item, currentUnitSystem) =>
                          this.updateAndValidateItem(
                            item,
                            itemIndex,
                            currentUnitSystem
                          )
                        }
                        onPresetSelected={(item, currentUnitSystem) =>
                          this.updatePresetSize(
                            item,
                            itemIndex,
                            currentUnitSystem
                          )
                        }
                        heightLabel={this.getCopy("height")}
                        widthLabel={this.getCopy("width")}
                        lengthLabel={this.getCopy("length")}
                        heightError={currentItemObject.height}
                        widthError={currentItemObject.width}
                        lengthError={currentItemObject.length}
                        quantityError={currentItemObject.quantity}
                        heightValue={heightValue}
                        widthValue={widthValue}
                        lengthValue={lengthValue}
                        intl={intl}
                        presetSize={presetSizeValue}
                        trackEvent={trackEvent}
                        isBusiness={isBusiness}
                        unitSystem={unitSystem}
                        selectedUnitSystem={selectedUnitSystem}
                        iNeedTheseProps={[
                          "spotShipmentPresetSizes",
                          "quantityValidationRules",
                          "lengthValidationRules",
                          "heightValidationRules",
                          "widthValidationRules",
                        ]}
                        weight={items[itemIndex].weight}
                        weightError={currentItemObject.weight}
                        quantity={
                          items[itemIndex].quantity === ""
                            ? null
                            : items[itemIndex].quantity
                        }
                        onQuantityChange={(currentUnitSystem, input) =>
                          this.onQuantityChange(
                            itemIndex,
                            currentUnitSystem,
                            input
                          )
                        }
                        onQuantityBlur={(input) =>
                          this.validateFormFields(input)
                        }
                        onCheckboxChange={(item, currentUnitSystem) =>
                          this.updateItem(item, itemIndex, currentUnitSystem)
                        }
                        settingsNonStackable={
                          items[itemIndex].settingsNonStackable
                        }
                        isQuantitySelectorEnabled={
                          configs.isQuantitySelectorEnabled
                        }
                        itemIndex={itemIndex}
                        addItemDescriptionId={
                          configs.isAddItemEnabled && totalItemsAmount > 1
                            ? itemDescriptionId
                            : ""
                        }
                        segmentForPresets={segmentForPresets}
                        isCheckboxDisabledBecauseOfWeight={
                          isCheckboxDisabledBecauseOfWeight
                        }
                      />
                      {itemIndex > 0 && (
                        <StyledHiddenCloseIconContainer>
                          <StyledHiddenCloseIcon
                            icon={closeIcon}
                            isClickable
                            onClick={() =>
                              this.removeShipmentLaneItemLocal(
                                `item-index-${currentItemIndex}`
                              )
                            }
                            size="small"
                            fillHoverColor={colors.redCherryHover}
                            fillColor={colors.redScarlet}
                            label={this.getCopy("removeItem", {
                              totalItems: totalItemsAmount,
                              itemNumber: currentItemIndex,
                            })}
                            useAs="standalone"
                            data-testid="remove-shipment-item-button"
                            tabIndex="0"
                            dataTracking={
                              this.removeShipmentLaneItemClickedTrackingKeyPath
                            }
                            itemAdded={totalItemsAmount > 1}
                          />
                        </StyledHiddenCloseIconContainer>
                      )}
                    </DimensionsContent>
                  );
                })}
                <AddItemButtonCell
                  spanMobile="12"
                  columnStartMobile="1"
                  gridColumn="9 / span 2"
                >
                  {configs.isAddItemEnabled && (
                    <StyledIconButton
                      ariaLabel={this.getCopy(
                        "screenReaderLabel_addItemDescription"
                      )}
                      buttonStyle="tertiary"
                      icon={plusIcon}
                      type="button"
                      value="addItem"
                      name="addItem"
                      data-testid="add-shipment-item-button"
                      disabled={
                        validity.items.length >=
                        configs.maxNumberOfShipmentItems
                      }
                      onClick={this.addShipmentLaneItem}
                      dataTracking={
                        this.addShipmentLaneItemClickedTrackingKeyPath
                      }
                    >
                      {this.getCopy("addItem")}
                    </StyledIconButton>
                  )}
                  <StyledTotalShipmentWeight
                    size={TEXT_SIZES.LARGE}
                    weight={700}
                    addItemButtonVisible={configs.isAddItemEnabled}
                    dataTestid="total-shipment-weight"
                    isParagraph
                  >
                    {this.getCopy("totalShipmentWeightLabel")}{" "}
                    {Number.isNaN(totalShipmentWeight)
                      ? "-"
                      : `${totalShipmentWeight} ${unitSystem[selectedUnitSystem].weight}`}
                  </StyledTotalShipmentWeight>
                </AddItemButtonCell>
                <SettingsCheckboxWrapper gridColumn="7 / span 5">
                  {configs.isDangerousGoodsEnabled && (
                    <StyledCheckboxInfo>
                      <CheckboxField
                        dataTestid="dangerous-goods-checkbox"
                        name="settingsDangerousGoods"
                        size={CHECKBOXFIELD_SIZES.SMALL}
                        value="settingsDangerousGoods"
                        isChecked={settingsDangerousGoods}
                        onChange={this.onCheckboxChange}
                        trackEvent={trackEvent}
                        dataTracking={
                          this.dangerousGoodsCheckboxTrackingKeyPath
                        }
                        disabled={
                          isCheckboxDisabledBecauseOfWeight ||
                          dangerousGoodsCheckboxDisabled
                        }
                      >
                        <span data-testid="dangerous-goods-label">
                          {this.getCopy("dangerousGoodsLabel")}
                        </span>
                      </CheckboxField>
                      <IconButtonTooltip
                        id="tooltip-dangerous-goods"
                        dataTracking={
                          this.dangerousGoodsCheckboxIconTrackingKeyPath
                        }
                        ariaLabel={this.getCopy("dangerousGoodsLabel")}
                        iconTrackEventKey={
                          this.dangerousGoodsCheckboxIconTrackEventKey
                        }
                        name="icon-settingsDangerousGoods"
                        iconType="help"
                        trackEvent={trackEvent}
                        tooltipContent={dangerousGoodsTooltipContent}
                      />
                    </StyledCheckboxInfo>
                  )}
                  <StyledCheckboxInfo>
                    <CheckboxField
                      dataTestid="documents"
                      name="documents"
                      size={CHECKBOXFIELD_SIZES.SMALL}
                      value="documents"
                      isChecked={documentCheckboxState}
                      onChange={this.onDocumentsChange}
                      trackEvent={trackEvent}
                      dataTracking={this.documentsCheckboxTrackingKeyPath}
                      disabled={documentsCheckboxDisabled}
                    >
                      <span data-testid="documents-label">
                        {this.getCopy("documents")}
                      </span>
                    </CheckboxField>
                    <IconButtonTooltip
                      id="tooltip-documents"
                      dataTracking={this.documentsCheckboxIconTrackingKeyPath}
                      ariaLabel={this.getCopy("documentsIconLabel")}
                      iconTrackEventKey={
                        this.documentsCheckboxIconTrackEventKey
                      }
                      name="icon-documents"
                      iconType="help"
                      trackEvent={trackEvent}
                      tooltipContent={documentsTooltipContent}
                    />
                  </StyledCheckboxInfo>
                </SettingsCheckboxWrapper>
                <SubmitButtonCell
                  spanMobile="12"
                  columnStartMobile="1"
                  gridColumn="9 / span 2"
                >
                  <Button
                    type="submit"
                    value="button"
                    name="submit"
                    dataTestid="get-a-quote-button"
                    data-tracking={this.getOffersTrackingKeyPath}
                  >
                    {this.getCopy("submit")}
                  </Button>
                </SubmitButtonCell>
              </ShipmentItemGridContainer>
            </div>
          </ShipmentLaneFormWrapper>
        )}
      </ErrorBoundary>
    );
  }
}

export default injectIntl(ShipmentLaneComponent);
