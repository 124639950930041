// 9fbef606107a605d69c0edbcd8029e5d

/*
 * DimensionSelector Messages
 */
import { defineMessages } from "react-intl";

const idPrefix = "CountryAutocompleteField";

export default defineMessages({
  AF: {
    id: `${idPrefix}.AF`,
    defaultMessage: "Afghanistan",
  },
  AL: {
    id: `${idPrefix}.AL`,
    defaultMessage: "Albania",
  },
  DZ: {
    id: `${idPrefix}.DZ`,
    defaultMessage: "Algeria",
  },
  AS: {
    id: `${idPrefix}.AS`,
    defaultMessage: "American Samoa",
  },
  AD: {
    id: `${idPrefix}.AD`,
    defaultMessage: "Andorra",
  },
  AO: {
    id: `${idPrefix}.AO`,
    defaultMessage: "Angola",
  },
  AI: {
    id: `${idPrefix}.AI`,
    defaultMessage: "Anguilla",
  },
  AG: {
    id: `${idPrefix}.AG`,
    defaultMessage: "Antigua and Barbuda",
  },
  AR: {
    id: `${idPrefix}.AR`,
    defaultMessage: "Argentina",
  },
  AM: {
    id: `${idPrefix}.AM`,
    defaultMessage: "Armenia",
  },
  AW: {
    id: `${idPrefix}.AW`,
    defaultMessage: "Aruba",
  },
  AU: {
    id: `${idPrefix}.AU`,
    defaultMessage: "Australia",
  },
  AT: {
    id: `${idPrefix}.AT`,
    defaultMessage: "Austria",
  },
  AZ: {
    id: `${idPrefix}.AZ`,
    defaultMessage: "Azerbaijan",
  },
  BS: {
    id: `${idPrefix}.BS`,
    defaultMessage: "Bahamas",
  },
  BH: {
    id: `${idPrefix}.BH`,
    defaultMessage: "Bahrain",
  },
  BD: {
    id: `${idPrefix}.BD`,
    defaultMessage: "Bangladesh",
  },
  BB: {
    id: `${idPrefix}.BB`,
    defaultMessage: "Barbados",
  },
  BY: {
    id: `${idPrefix}.BY`,
    defaultMessage: "Belarus",
  },
  BE: {
    id: `${idPrefix}.BE`,
    defaultMessage: "Belgium",
  },
  BZ: {
    id: `${idPrefix}.BZ`,
    defaultMessage: "Belize",
  },
  BJ: {
    id: `${idPrefix}.BJ`,
    defaultMessage: "Benin",
  },
  BM: {
    id: `${idPrefix}.BM`,
    defaultMessage: "Bermuda",
  },
  BT: {
    id: `${idPrefix}.BT`,
    defaultMessage: "Bhutan",
  },
  BO: {
    id: `${idPrefix}.BO`,
    defaultMessage: "Bolivia",
  },
  XB: {
    id: `${idPrefix}.XB`,
    defaultMessage: "Bonaire",
  },
  BA: {
    id: `${idPrefix}.BA`,
    defaultMessage: "Bosnia and Herzegovina",
  },
  BW: {
    id: `${idPrefix}.BW`,
    defaultMessage: "Botswana",
  },
  BV: {
    id: `${idPrefix}.BV`,
    defaultMessage: "Bouvet Island",
  },
  BR: {
    id: `${idPrefix}.BR`,
    defaultMessage: "Brazil",
  },
  VG: {
    id: `${idPrefix}.VG`,
    defaultMessage: "British Virgin Islands",
  },
  BN: {
    id: `${idPrefix}.BN`,
    defaultMessage: "Brunei",
  },
  BG: {
    id: `${idPrefix}.BG`,
    defaultMessage: "Bulgaria",
  },
  BF: {
    id: `${idPrefix}.BF`,
    defaultMessage: "Burkina Faso",
  },
  BI: {
    id: `${idPrefix}.BI`,
    defaultMessage: "Burundi",
  },
  KH: {
    id: `${idPrefix}.KH`,
    defaultMessage: "Cambodia",
  },
  CM: {
    id: `${idPrefix}.CM`,
    defaultMessage: "Cameroon",
  },
  CA: {
    id: `${idPrefix}.CA`,
    defaultMessage: "Canada",
  },
  IC: {
    id: `${idPrefix}.IC`,
    defaultMessage: "Canary Islands",
  },
  CV: {
    id: `${idPrefix}.CV`,
    defaultMessage: "Cape Verde",
  },
  KY: {
    id: `${idPrefix}.KY`,
    defaultMessage: "Cayman Islands",
  },
  CF: {
    id: `${idPrefix}.CF`,
    defaultMessage: "Central African Republic",
  },
  TD: {
    id: `${idPrefix}.TD`,
    defaultMessage: "Chad",
  },
  CL: {
    id: `${idPrefix}.CL`,
    defaultMessage: "Chile",
  },
  CN: {
    id: `${idPrefix}.CN`,
    defaultMessage: "China",
  },
  CX: {
    id: `${idPrefix}.CX`,
    defaultMessage: "Christmas Island",
  },
  CC: {
    id: `${idPrefix}.CC`,
    defaultMessage: "Cocos Islands",
  },
  CO: {
    id: `${idPrefix}.CO`,
    defaultMessage: "Colombia",
  },
  KM: {
    id: `${idPrefix}.KM`,
    defaultMessage: "Comoros",
  },
  CK: {
    id: `${idPrefix}.CK`,
    defaultMessage: "Cook Islands",
  },
  CR: {
    id: `${idPrefix}.CR`,
    defaultMessage: "Costa Rica",
  },
  HR: {
    id: `${idPrefix}.HR`,
    defaultMessage: "Croatia",
  },
  CU: {
    id: `${idPrefix}.CU`,
    defaultMessage: "Cuba",
  },
  XC: {
    id: `${idPrefix}.XC`,
    defaultMessage: "Curacao",
  },
  CY: {
    id: `${idPrefix}.CY`,
    defaultMessage: "Cyprus",
  },
  CZ: {
    id: `${idPrefix}.CZ`,
    defaultMessage: "Czech Republic",
  },
  CI: {
    id: `${idPrefix}.CI`,
    defaultMessage: "Côte d’Ivoire",
  },
  CD: {
    id: `${idPrefix}.CD`,
    defaultMessage: "Democratic Republic of the Congo",
  },
  DK: {
    id: `${idPrefix}.DK`,
    defaultMessage: "Denmark",
  },
  DJ: {
    id: `${idPrefix}.DJ`,
    defaultMessage: "Djibouti",
  },
  DM: {
    id: `${idPrefix}.DM`,
    defaultMessage: "Dominica",
  },
  DO: {
    id: `${idPrefix}.DO`,
    defaultMessage: "Dominican Republic",
  },
  EC: {
    id: `${idPrefix}.EC`,
    defaultMessage: "Ecuador",
  },
  EG: {
    id: `${idPrefix}.EG`,
    defaultMessage: "Egypt",
  },
  SV: {
    id: `${idPrefix}.SV`,
    defaultMessage: "El Salvador",
  },
  GQ: {
    id: `${idPrefix}.GQ`,
    defaultMessage: "Equatorial Guinea",
  },
  ER: {
    id: `${idPrefix}.ER`,
    defaultMessage: "Eritrea",
  },
  EE: {
    id: `${idPrefix}.EE`,
    defaultMessage: "Estonia",
  },
  ET: {
    id: `${idPrefix}.ET`,
    defaultMessage: "Ethiopia",
  },
  FK: {
    id: `${idPrefix}.FK`,
    defaultMessage: "Falkland Islands",
  },
  FO: {
    id: `${idPrefix}.FO`,
    defaultMessage: "Faroe Islands",
  },
  FJ: {
    id: `${idPrefix}.FJ`,
    defaultMessage: "Fiji",
  },
  FI: {
    id: `${idPrefix}.FI`,
    defaultMessage: "Finland",
    synonyms: `${idPrefix}.FI.synonyms`,
  },
  FR: {
    id: `${idPrefix}.FR`,
    defaultMessage: "France",
  },
  GF: {
    id: `${idPrefix}.GF`,
    defaultMessage: "French Guiana",
  },
  GA: {
    id: `${idPrefix}.GA`,
    defaultMessage: "Gabon",
  },
  GM: {
    id: `${idPrefix}.GM`,
    defaultMessage: "Gambia",
  },
  GE: {
    id: `${idPrefix}.GE`,
    defaultMessage: "Georgia",
  },
  DE: {
    id: `${idPrefix}.DE`,
    defaultMessage: "Germany",
  },
  GH: {
    id: `${idPrefix}.GH`,
    defaultMessage: "Ghana",
  },
  GI: {
    id: `${idPrefix}.GI`,
    defaultMessage: "Gibraltar",
  },
  GR: {
    id: `${idPrefix}.GR`,
    defaultMessage: "Greece",
  },
  GL: {
    id: `${idPrefix}.GL`,
    defaultMessage: "Greenland",
  },
  GD: {
    id: `${idPrefix}.GD`,
    defaultMessage: "Grenada",
  },
  GP: {
    id: `${idPrefix}.GP`,
    defaultMessage: "Guadeloupe",
  },
  GU: {
    id: `${idPrefix}.GU`,
    defaultMessage: "Guam",
  },
  GT: {
    id: `${idPrefix}.GT`,
    defaultMessage: "Guatemala",
  },
  GG: {
    id: `${idPrefix}.GG`,
    defaultMessage: "Guernsey",
  },
  GN: {
    id: `${idPrefix}.GN`,
    defaultMessage: "Guinea",
  },
  GW: {
    id: `${idPrefix}.GW`,
    defaultMessage: "Guinea-Bissau",
  },
  GY: {
    id: `${idPrefix}.GY`,
    defaultMessage: "Guyana",
  },
  HT: {
    id: `${idPrefix}.HT`,
    defaultMessage: "Haiti",
  },
  HN: {
    id: `${idPrefix}.HN`,
    defaultMessage: "Honduras",
  },
  HK: {
    id: `${idPrefix}.HK`,
    defaultMessage: "Hong Kong",
  },
  HU: {
    id: `${idPrefix}.HU`,
    defaultMessage: "Hungary",
  },
  IS: {
    id: `${idPrefix}.IS`,
    defaultMessage: "Iceland",
  },
  IN: {
    id: `${idPrefix}.IN`,
    defaultMessage: "India",
  },
  ID: {
    id: `${idPrefix}.ID`,
    defaultMessage: "Indonesia",
  },
  IR: {
    id: `${idPrefix}.IR`,
    defaultMessage: "Iran",
  },
  IQ: {
    id: `${idPrefix}.IQ`,
    defaultMessage: "Iraq",
  },
  IE: {
    id: `${idPrefix}.IE`,
    defaultMessage: "Ireland",
  },
  IM: {
    id: `${idPrefix}.IM`,
    defaultMessage: "Isle of Man",
  },
  IL: {
    id: `${idPrefix}.IL`,
    defaultMessage: "Israel",
  },
  IT: {
    id: `${idPrefix}.IT`,
    defaultMessage: "Italy",
  },
  JM: {
    id: `${idPrefix}.JM`,
    defaultMessage: "Jamaica",
  },
  JP: {
    id: `${idPrefix}.JP`,
    defaultMessage: "Japan",
  },
  JE: {
    id: `${idPrefix}.JE`,
    defaultMessage: "Jersey",
  },
  JO: {
    id: `${idPrefix}.JO`,
    defaultMessage: "Jordan",
  },
  KZ: {
    id: `${idPrefix}.KZ`,
    defaultMessage: "Kazakhstan",
  },
  KE: {
    id: `${idPrefix}.KE`,
    defaultMessage: "Kenya",
  },
  KI: {
    id: `${idPrefix}.KI`,
    defaultMessage: "Kiribati",
  },
  KV: {
    id: `${idPrefix}.KV`,
    defaultMessage: "Kosovo",
  },
  KW: {
    id: `${idPrefix}.KW`,
    defaultMessage: "Kuwait",
  },
  KG: {
    id: `${idPrefix}.KG`,
    defaultMessage: "Kyrgyzstan",
  },
  LA: {
    id: `${idPrefix}.LA`,
    defaultMessage: "Laos",
  },
  LV: {
    id: `${idPrefix}.LV`,
    defaultMessage: "Latvia",
  },
  LB: {
    id: `${idPrefix}.LB`,
    defaultMessage: "Lebanon",
  },
  LS: {
    id: `${idPrefix}.LS`,
    defaultMessage: "Lesotho",
  },
  LR: {
    id: `${idPrefix}.LR`,
    defaultMessage: "Liberia",
  },
  LY: {
    id: `${idPrefix}.LY`,
    defaultMessage: "Libya",
  },
  LI: {
    id: `${idPrefix}.LI`,
    defaultMessage: "Liechtenstein",
  },
  LT: {
    id: `${idPrefix}.LT`,
    defaultMessage: "Lithuania",
  },
  LU: {
    id: `${idPrefix}.LU`,
    defaultMessage: "Luxembourg",
  },
  MO: {
    id: `${idPrefix}.MO`,
    defaultMessage: "Macao",
  },
  MK: {
    id: `${idPrefix}.MK`,
    defaultMessage: "Macedonia",
  },
  MG: {
    id: `${idPrefix}.MG`,
    defaultMessage: "Madagascar",
  },
  MW: {
    id: `${idPrefix}.MW`,
    defaultMessage: "Malawi",
  },
  MY: {
    id: `${idPrefix}.MY`,
    defaultMessage: "Malaysia",
  },
  MV: {
    id: `${idPrefix}.MV`,
    defaultMessage: "Maldives",
  },
  ML: {
    id: `${idPrefix}.ML`,
    defaultMessage: "Mali",
  },
  MT: {
    id: `${idPrefix}.MT`,
    defaultMessage: "Malta",
  },
  MH: {
    id: `${idPrefix}.MH`,
    defaultMessage: "Marshall Islands",
  },
  MQ: {
    id: `${idPrefix}.MQ`,
    defaultMessage: "Martinique",
  },
  MR: {
    id: `${idPrefix}.MR`,
    defaultMessage: "Mauritania",
  },
  MU: {
    id: `${idPrefix}.MU`,
    defaultMessage: "Mauritius",
  },
  YT: {
    id: `${idPrefix}.YT`,
    defaultMessage: "Mayotte",
  },
  MX: {
    id: `${idPrefix}.MX`,
    defaultMessage: "Mexico",
  },
  FM: {
    id: `${idPrefix}.FM`,
    defaultMessage: "Micronesia",
  },
  MD: {
    id: `${idPrefix}.MD`,
    defaultMessage: "Moldova",
  },
  MC: {
    id: `${idPrefix}.MC`,
    defaultMessage: "Monaco",
  },
  MN: {
    id: `${idPrefix}.MN`,
    defaultMessage: "Mongolia",
  },
  ME: {
    id: `${idPrefix}.ME`,
    defaultMessage: "Montenegro",
  },
  MS: {
    id: `${idPrefix}.MS`,
    defaultMessage: "Montserrat",
  },
  MA: {
    id: `${idPrefix}.MA`,
    defaultMessage: "Morocco",
  },
  MZ: {
    id: `${idPrefix}.MZ`,
    defaultMessage: "Mozambique",
  },
  MM: {
    id: `${idPrefix}.MM`,
    defaultMessage: "Myanmar",
  },
  NA: {
    id: `${idPrefix}.NA`,
    defaultMessage: "Namibia",
  },
  NR: {
    id: `${idPrefix}.NR`,
    defaultMessage: "Nauru",
  },
  NP: {
    id: `${idPrefix}.NP`,
    defaultMessage: "Nepal",
  },
  NL: {
    id: `${idPrefix}.NL`,
    defaultMessage: "Netherlands",
  },
  XN: {
    id: `${idPrefix}.XN`,
    defaultMessage: "Nevis",
  },
  NC: {
    id: `${idPrefix}.NC`,
    defaultMessage: "New Caledonia",
  },
  NZ: {
    id: `${idPrefix}.NZ`,
    defaultMessage: "New Zealand",
  },
  NI: {
    id: `${idPrefix}.NI`,
    defaultMessage: "Nicaragua",
  },
  NE: {
    id: `${idPrefix}.NE`,
    defaultMessage: "Niger",
  },
  NG: {
    id: `${idPrefix}.NG`,
    defaultMessage: "Nigeria",
  },
  NU: {
    id: `${idPrefix}.NU`,
    defaultMessage: "Niue",
  },
  NF: {
    id: `${idPrefix}.NF`,
    defaultMessage: "Norfolk Island",
  },
  KP: {
    id: `${idPrefix}.KP`,
    defaultMessage: "North Korea",
  },
  MP: {
    id: `${idPrefix}.MP`,
    defaultMessage: "Northern Mariana Islands",
  },
  NO: {
    id: `${idPrefix}.NO`,
    defaultMessage: "Norway",
  },
  OM: {
    id: `${idPrefix}.OM`,
    defaultMessage: "Oman",
  },
  PK: {
    id: `${idPrefix}.PK`,
    defaultMessage: "Pakistan",
  },
  PW: {
    id: `${idPrefix}.PW`,
    defaultMessage: "Palau",
  },
  PA: {
    id: `${idPrefix}.PA`,
    defaultMessage: "Panama",
  },
  PG: {
    id: `${idPrefix}.PG`,
    defaultMessage: "Papua New Guinea",
  },
  PY: {
    id: `${idPrefix}.PY`,
    defaultMessage: "Paraguay",
  },
  PE: {
    id: `${idPrefix}.PE`,
    defaultMessage: "Peru",
  },
  PH: {
    id: `${idPrefix}.PH`,
    defaultMessage: "Philippines",
  },
  PN: {
    id: `${idPrefix}.PN`,
    defaultMessage: "Pitcairn",
  },
  PL: {
    id: `${idPrefix}.PL`,
    defaultMessage: "Poland",
  },
  PT: {
    id: `${idPrefix}.PT`,
    defaultMessage: "Portugal",
  },
  PR: {
    id: `${idPrefix}.PR`,
    defaultMessage: "Puerto Rico",
  },
  QA: {
    id: `${idPrefix}.QA`,
    defaultMessage: "Qatar",
  },
  XS: {
    id: `${idPrefix}.XS`,
    defaultMessage: "Republic of Somaliland",
  },
  CG: {
    id: `${idPrefix}.CG`,
    defaultMessage: "Republic of the Congo",
  },
  RE: {
    id: `${idPrefix}.RE`,
    defaultMessage: "Reunion",
  },
  RO: {
    id: `${idPrefix}.RO`,
    defaultMessage: "Romania",
  },
  RU: {
    id: `${idPrefix}.RU`,
    defaultMessage: "Russia",
  },
  RW: {
    id: `${idPrefix}.RW`,
    defaultMessage: "Rwanda",
  },
  XE: {
    id: `${idPrefix}.XE`,
    defaultMessage: "Saint Eustatius",
  },
  SH: {
    id: `${idPrefix}.SH`,
    defaultMessage: "Saint Helena",
  },
  KN: {
    id: `${idPrefix}.KN`,
    defaultMessage: "Saint Kitts",
  },
  LC: {
    id: `${idPrefix}.LC`,
    defaultMessage: "Saint Lucia",
  },
  XM: {
    id: `${idPrefix}.XM`,
    defaultMessage: "Saint Martin",
  },
  PM: {
    id: `${idPrefix}.PM`,
    defaultMessage: "Saint Pierre and Miquelon",
  },
  VC: {
    id: `${idPrefix}.VC`,
    defaultMessage: "Saint Vincent and the Grenadines",
  },
  WS: {
    id: `${idPrefix}.WS`,
    defaultMessage: "Samoa",
  },
  SM: {
    id: `${idPrefix}.SM`,
    defaultMessage: "San Marino",
  },
  ST: {
    id: `${idPrefix}.ST`,
    defaultMessage: "Sao Tome and Principe",
  },
  SA: {
    id: `${idPrefix}.SA`,
    defaultMessage: "Saudi Arabia",
  },
  SN: {
    id: `${idPrefix}.SN`,
    defaultMessage: "Senegal",
  },
  RS: {
    id: `${idPrefix}.RS`,
    defaultMessage: "Serbia",
  },
  SC: {
    id: `${idPrefix}.SC`,
    defaultMessage: "Seychelles",
  },
  SL: {
    id: `${idPrefix}.SL`,
    defaultMessage: "Sierra Leone",
  },
  SG: {
    id: `${idPrefix}.SG`,
    defaultMessage: "Singapore",
  },
  SK: {
    id: `${idPrefix}.SK`,
    defaultMessage: "Slovakia",
  },
  SI: {
    id: `${idPrefix}.SI`,
    defaultMessage: "Slovenia",
  },
  SB: {
    id: `${idPrefix}.SB`,
    defaultMessage: "Solomon Islands",
  },
  SO: {
    id: `${idPrefix}.SO`,
    defaultMessage: "Somalia",
  },
  ZA: {
    id: `${idPrefix}.ZA`,
    defaultMessage: "South Africa",
  },
  KR: {
    id: `${idPrefix}.KR`,
    defaultMessage: "South Korea",
  },
  SS: {
    id: `${idPrefix}.SS`,
    defaultMessage: "South Sudan",
  },
  ES: {
    id: `${idPrefix}.ES`,
    defaultMessage: "Spain",
  },
  LK: {
    id: `${idPrefix}.LK`,
    defaultMessage: "Sri Lanka",
  },
  XY: {
    id: `${idPrefix}.XY`,
    defaultMessage: "St. Barthelemy",
  },
  SD: {
    id: `${idPrefix}.SD`,
    defaultMessage: "Sudan",
  },
  SR: {
    id: `${idPrefix}.SR`,
    defaultMessage: "Suriname",
  },
  SJ: {
    id: `${idPrefix}.SJ`,
    defaultMessage: "Svalbard and Jan Mayen",
  },
  SZ: {
    id: `${idPrefix}.SZ`,
    defaultMessage: "Swaziland",
  },
  SE: {
    id: `${idPrefix}.SE`,
    defaultMessage: "Sweden",
  },
  CH: {
    id: `${idPrefix}.CH`,
    defaultMessage: "Switzerland",
  },
  SY: {
    id: `${idPrefix}.SY`,
    defaultMessage: "Syria",
  },
  PF: {
    id: `${idPrefix}.PF`,
    defaultMessage: "Tahiti",
  },
  TW: {
    id: `${idPrefix}.TW`,
    defaultMessage: "Taiwan",
  },
  TJ: {
    id: `${idPrefix}.TJ`,
    defaultMessage: "Tajikistan",
  },
  TZ: {
    id: `${idPrefix}.TZ`,
    defaultMessage: "Tanzania",
  },
  TH: {
    id: `${idPrefix}.TH`,
    defaultMessage: "Thailand",
  },
  TL: {
    id: `${idPrefix}.TL`,
    defaultMessage: "Timor Leste",
  },
  TG: {
    id: `${idPrefix}.TG`,
    defaultMessage: "Togo",
  },
  TK: {
    id: `${idPrefix}.TK`,
    defaultMessage: "Tokelau",
  },
  TO: {
    id: `${idPrefix}.TO`,
    defaultMessage: "Tonga",
  },
  TT: {
    id: `${idPrefix}.TT`,
    defaultMessage: "Trinidad and Tobago",
  },
  TN: {
    id: `${idPrefix}.TN`,
    defaultMessage: "Tunisia",
  },
  TR: {
    id: `${idPrefix}.TR`,
    defaultMessage: "Turkey",
  },
  TM: {
    id: `${idPrefix}.TM`,
    defaultMessage: "Turkmenistan",
  },
  TC: {
    id: `${idPrefix}.TC`,
    defaultMessage: "Turks and Caicos Islands",
  },
  TV: {
    id: `${idPrefix}.TV`,
    defaultMessage: "Tuvalu",
  },
  VI: {
    id: `${idPrefix}.VI`,
    defaultMessage: "U.S. Virgin Islands",
  },
  UG: {
    id: `${idPrefix}.UG`,
    defaultMessage: "Uganda",
  },
  UA: {
    id: `${idPrefix}.UA`,
    defaultMessage: "Ukraine",
  },
  AE: {
    id: `${idPrefix}.AE`,
    defaultMessage: "United Arab Emirates",
  },
  GB: {
    id: `${idPrefix}.GB`,
    defaultMessage: "United Kingdom",
    synonyms: `${idPrefix}.GB.synonyms`,
  },
  US: {
    id: `${idPrefix}.US`,
    defaultMessage: "United States of America",
    synonyms: `${idPrefix}.US.synonyms`,
  },
  shippingUnavailable: {
    id: `${idPrefix}.shippingUnavailable`,
    defaultMessage: "(Shipment service unavailable)",
  },
  UY: {
    id: `${idPrefix}.UY`,
    defaultMessage: "Uruguay",
  },
  UZ: {
    id: `${idPrefix}.UZ`,
    defaultMessage: "Uzbekistan",
  },
  VU: {
    id: `${idPrefix}.VU`,
    defaultMessage: "Vanuatu",
  },
  VA: {
    id: `${idPrefix}.VA`,
    defaultMessage: "Vatican",
  },
  VE: {
    id: `${idPrefix}.VE`,
    defaultMessage: "Venezuela",
  },
  VN: {
    id: `${idPrefix}.VN`,
    defaultMessage: "Vietnam",
  },
  WF: {
    id: `${idPrefix}.WF`,
    defaultMessage: "Wallis and Futuna",
  },
  YE: {
    id: `${idPrefix}.YE`,
    defaultMessage: "Yemen",
  },
  ZM: {
    id: `${idPrefix}.ZM`,
    defaultMessage: "Zambia",
  },
  ZW: {
    id: `${idPrefix}.ZW`,
    defaultMessage: "Zimbabwe",
  },
});
