// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for WCCOfferCardHeader
 *
 */

import styled from "styled-components";
import { key } from "styled-theme";
import { breakpoints, colors, dhlVarColors } from "root/libs/ui-styleguide/src";
import { IconButtonTooltip, Headline } from "root/libs/ui-components/src";
import { Text } from "@dhl-official/react-ui-library";

export const StyledOfferCardHeader = styled.div`
  position: relative;
  align-items: flex-start;
  padding: 20px 20px ${(props) => (!props.isHighlighted ? "20px" : "10px")} 20px;
  margin-bottom: 20px;
  display: flex;
  background-color: ${(props) =>
    props.isHighlighted ? dhlVarColors.secondary : ""};
  box-shadow: ${(props) =>
    !props.isHighlighted
      ? `0 3px 8px 0 ${dhlVarColors.shadowLightDefault}`
      : ""};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-left: ${(props) =>
      props.isLast && props.expandedCardIndex === null ? "40px" : false};
  }
`;

export const StyledHeadline = styled(Headline)`
  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-bottom: 8px;
    font-size: ${key("fonts.desktop.h4.size")} !important;
    line-height: ${key("fonts.desktop.h4.lineHeight")} !important;
  }
`;

export const StyledIconWrapper = styled.div`
  border-radius: 50%;
  padding: 0px;
  background-color: ${colors.white};
  border: 1px solid ${dhlVarColors.fontColor};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledHeadingWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  z-index: 0;
`;

export const SubHeadline = styled(Text)`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: block;
  }
`;

export const StyledIconButtonTooltip = styled(IconButtonTooltip)`
  position: absolute;
  right: 0;
  top: 0;

  /* 344px = Samsung Galazy Fold */
  &.wcc-offer-card-tooltip-sm {
    .tooltip-container {
      max-width: 344px !important; 
      & > div[data-testid] {
        width: 314px !important;
      }
    }
  }

  &.wcc-offer-card-tooltip-md {
    .tooltip-container {
      max-width: 360px !important;
      & > div[data-testid] {
        width: 330px !important;
      }
    }
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    right: ${(props) =>
      props.isFirst && props.expandedCardIndex === null ? "20px" : false};
  }
`;
