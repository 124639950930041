// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for AdvancedSettings
 *
 */

import styled from "styled-components";
import { Headline } from "root/libs/ui-components/src";
import { Text } from "@dhl-official/react-ui-library";
import { breakpoints } from "root/libs/ui-styleguide/src";
import { key } from "styled-theme";

export const AdvancedSettingsWrapper = styled.fieldset`
  .dhl-cj-main & {
    display: block;
    margin: 0 14px 10px;
    padding-top: 18px;
    border-top: 1px solid ${key("colors.globalBorderColor")};
    width: 100%;

    @media screen and (min-width: ${breakpoints.tablet}) {
      margin: 20px 0 0;
      border-top: none;
      padding-top: 0;
    }
  }

  @media print {
    border-top: 0 !important;
    padding-top: 0px;
  }
`;

export const StyledHeadline = styled(Headline)`
  margin-bottom: 0;
`;

export const AdvancedSettingsCheckboxWrapper = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .checkbox {
      margin-right: 30px;
      padding: 9px 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media print {
    display: flex;
    flex-direction: row;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 9px 20px 9px 0;
  align-items: center;

  .iconWrapper {
    margin-top: -9px;
  }

  @media print {
    padding: 0px;
  }

  label > input {
    /*! @noflip */
    left: -9999px;
    /*! @noflip */
    right: unset;
    position: absolute;
  }

`;

export const ToolTipText = styled(Text)`
  margin-bottom: ${(props) => (props.isParagraph ? "10px" : 0)};
`;
