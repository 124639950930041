// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * OfferPaymentIcons
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { IntlPropType } from "root/libs/core-libs/src";
import styled from "styled-components";
import isEmpty from "lodash/isEmpty";
import { Text, TEXT_SIZES } from "@dhl-official/react-ui-library";
import { TYPES_OF_OFFERS } from "one-time-shipment/src/containers/GetQuote/constants";
import PaymentLogo, { getPaymentLabel } from "../PaymentLogo/index";

const COPY_ID_PREFIX = "OfferPriceAndPaymentInfo";

export const PaymentMethods = styled.div`
  justify-content: flex-start;
  align-items: baseline;
  display: flex;
  justify-content: ${(props) =>
    props.iconVariation === TYPES_OF_OFFERS.WCC && !props.isBusiness
      ? "center"
      : "flex-start"};
`;

export const PaymentMethodWrapper = styled.div`
  padding-right: 6px;

  .icon {
    max-width: 85%;
  }
`;

export const StyledInvoiceLabel = styled(Text)`
  display: inline-block;
`;

export default function OfferPaymentIcons({
  intl,
  offer,
  iconVariation,
  dataTestid,
}) {
  const { acceptedPaymentMethods } = offer;

  function getCopy(id) {
    return intl.formatMessage({ id: `${COPY_ID_PREFIX}.${id}` });
  }

  return (
    !isEmpty(acceptedPaymentMethods) && (
      <React.Fragment>
        <PaymentMethods iconVariation={iconVariation} data-testid={dataTestid}>
          {offer.acceptedPaymentMethods.map((acceptedPaymentMethod) => {
            return (
              <PaymentMethodWrapper key={acceptedPaymentMethod}>
                <PaymentLogo
                  intl={intl}
                  copyPrefix={COPY_ID_PREFIX}
                  paymentMethod={acceptedPaymentMethod}
                />
                {acceptedPaymentMethod === "INVOICE" && (
                  <StyledInvoiceLabel size={TEXT_SIZES.TINY} ariaHidden>
                    {getCopy("invoice")}
                  </StyledInvoiceLabel>
                )}
              </PaymentMethodWrapper>
            );
          })}
        </PaymentMethods>
        <span id={`${offer.key}-payment`} className="visually-hidden">
          {getCopy("screenReaderLabel_paymentOptions")}
          {offer.acceptedPaymentMethods.map(
            (acceptedPaymentMethod, index, arr) => {
              return `${getPaymentLabel(
                intl,
                COPY_ID_PREFIX,
                acceptedPaymentMethod
              )} ${index + 1 === arr.length ? "" : ", "}`;
            }
          )}
          :
        </span>
      </React.Fragment>
    )
  );
}

OfferPaymentIcons.propTypes = {
  intl: IntlPropType.isRequired,
  offer: PropTypes.object.isRequired,
  iconVariation: PropTypes.string,
  dataTestid: PropTypes.string,
  isBusiness: PropTypes.bool,
};
