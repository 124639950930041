// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * TargetedServiceCard
 *
 */

import React from "react";
import PropTypes from "prop-types";
import {
  IntlPropType,
  trackConversionInteraction,
} from "root/libs/core-libs/src";
import { CountrySettings } from "root/libs/ui-containers/src";
import { BUTTON_VARIANTS, TEXT_SIZES } from "@dhl-official/react-ui-library";
import { Icon, Link } from "root/libs/ui-components/src";

import { selectTrackingKey } from "one-time-shipment/src/containers/Tracking/selectors";
import OfferPriceAndPaymentInfo from "one-time-shipment/src/components/Offers/OfferPriceAndPaymentInfo";
import OfferAddOns from "one-time-shipment/src/components/Offers/OfferAddOns";
import OfferProperties from "one-time-shipment/src/components/Offers/OfferProperties";
import infoIcon from "iconsTwoColors/cx/info_filled.svg";
import FrequentShipmentIcon from "iconsTwoColors/cx/DHL_Frequent_Shipment.svg";
import {
  TargetedServiceCardContent,
  TargetedServiceCardWrapper,
  IntroContent,
  CreateAccountWrapper,
  CreateAccountContent,
  OfferCardDetailsContent,
  StyledAccordion,
  IconChevronUp,
  IconChevronDown,
  IntroInfoWrapper,
  IntroContentWrapper,
  RegularShipperText,
  CreateAccountHeadline,
  PromotedTextWrapper,
  PromotedText,
  CardButtonWrapper,
  RecommendedSaveQuote,
} from "./styled";

const { withCountry } = CountrySettings;

const COPY_ID_PREFIX = "TargetedServiceCard";

export default class TargetedServiceCard extends React.PureComponent {

  static propTypes = {
    intl: IntlPropType.isRequired,
    contentKey: PropTypes.string,
    cid: PropTypes.string,
    utfDataObject: PropTypes.object,
    className: PropTypes.string,
    reference: PropTypes.object,
    trackEvent: PropTypes.func,
    index: PropTypes.number,
    indexLength: PropTypes.number,
  }

  constructor(props) {
    super(props);
    this.OfferPriceAndPaymentInfoWithCountry = withCountry(
      OfferPriceAndPaymentInfo
    );
    this.OfferProperties = withCountry(OfferProperties);
    this.OfferAddOnsWithCountry = withCountry(OfferAddOns);
    this.trackingKeySelector = selectTrackingKey;
    this.idExploredOfferTrakingKeyPath = this.trackingKeySelector(
      "spotShipment.interactions.offer.idExploredOffer"
    );
  }

  trackUtfEvent = (utfDataObject) => {
    trackConversionInteraction(utfDataObject);
  };

  getCopy = (id) => {
    const { intl, contentKey } = this.props;
    return intl.formatMessage({ id: `${COPY_ID_PREFIX}.${contentKey}_${id}` });
  };

  render() {
    const {
      className,
      intl,
      reference,
      trackEvent,
      index,
      indexLength,
      contentKey,
      utfDataObject,
      cid,
    } = this.props;

    const accordionId = `offerCard-fs-product`;

    const cardProperties = [
      {
        key: "SHIPMENT_DESCRIPTION",
        promoted: false,
      },
      {
        key: "PARTNERSHIP_BENEFITS",
        promoted: false,
      },
    ];

    const cardAddOns = [
      {
        key: "PREFERENTIAL_RATES",
      },
      {
        key: "OUTSTANDING_SUPPORT",
      },
      {
        key: "WORLDCLASS_SERVICES",
      },
    ];

    const ariaDescribedByIdCollection = `${contentKey}-firstline ${contentKey}-headline ${contentKey}-promoted ${contentKey}-moreInfo`;
    const offerIndex = index + 1;

    const messages = {
      firstline: this.getCopy('firstline'),
      headline: this.getCopy('headline'),
      promoted: this.getCopy('promoted'),
      linkText: this.getCopy('linkText'),
      linkUrl: this.getCopy('linkUrl'),
      saveQuote: this.getCopy('saveQuote'),
    };

    const buttonLink = `${messages.linkUrl}?cid=${cid}`;

    return (
      <TargetedServiceCardWrapper className={className}>
        <TargetedServiceCardContent
          data-testid="targeted-service-card"
          data-testvalue={contentKey}
          aria-describedby={ariaDescribedByIdCollection}
          aria-label={intl.formatMessage(
            { id: `OfferCardHorizontal.screenReaderLabel_offersAmount` },
            {
              currentOfferIndex: offerIndex,
              totalOffersAmount: indexLength,
            }
          )}
          tabIndex={0}
        >
          <IntroContent>
            <IntroInfoWrapper>
              <Icon
                icon={FrequentShipmentIcon}
                width={{
                  mobile: "77px",
                  desktop: "94px",
                }}
                height={{
                  mobile: "48px",
                  desktop: "60px",
                }}
                useAs="decorative"
              />
              <IntroContentWrapper>
                <RegularShipperText
                  dataTestid="targeted-service-card-firstline"
                  size={TEXT_SIZES.TINY}
                  id={`${contentKey}-firstline`}
                  isParagraph
                >
                  {messages.firstline}
                </RegularShipperText>
                <CreateAccountWrapper id={`${contentKey}-headline`}>
                  <CreateAccountHeadline
                    dataTestid="targeted-service-card-headline"
                    weight={800}
                    inlined
                    priority={4}
                    className="horizontal-card-headline"
                  >
                    {messages.headline}
                  </CreateAccountHeadline>
                </CreateAccountWrapper>
                <PromotedTextWrapper>
                  <Icon useAs="decorative" size="small" icon={infoIcon} />
                  <PromotedText
                    dataTestid="targeted-service-card-promoted"
                    size={TEXT_SIZES.TINY}
                    id={`${contentKey}-promoted`}
                    isParagraph
                  >
                    {messages.promoted}
                  </PromotedText>
                </PromotedTextWrapper>
              </IntroContentWrapper>
            </IntroInfoWrapper>
          </IntroContent>
          <CreateAccountContent>
            <CardButtonWrapper>
              <Link
                href={buttonLink}
                dataTestid="targeted-service-card-cta"
                linkType="cta primary"
                target="_blank"
                disableMinWidth
                onClick={() => this.trackUtfEvent(utfDataObject)}
              >
                {messages.linkText}
              </Link>
              <RecommendedSaveQuote
                size={TEXT_SIZES.TINY}
                dataTestid="targeted-service-card-saveQuote"
                id={`${contentKey}-saveQuote`}
                isParagraph
              >
                {messages.saveQuote}
              </RecommendedSaveQuote>
            </CardButtonWrapper>
          </CreateAccountContent>
        </TargetedServiceCardContent>
        <span id={`${contentKey}-moreInfo`} className="visually-hidden">
          {intl.formatMessage({
            id: `OfferCardHorizontal.screenReaderLabel_moreInformation`,
          })}
        </span>
        <StyledAccordion
          useAsCollapsible
          showExpandCollapseButton
          expandCollapseButtonVariation="bubbleWithText"
          expandCollapseButtonTestId={`expand-collapse-${contentKey}`}
          expandButtonLabel={intl.formatMessage({
            id: `OfferCardHorizontal.exploreOffer`,
          })}
          collapseButtonLabel={intl.formatMessage({
            id: `OfferCardHorizontal.closeOffer`,
          })}
          expandButtonAriaLabel={intl.formatMessage({
            id: `OfferCardHorizontal.screenReaderLabel_ariaExploreOffer`,
          })}
          collapseButtonAriaLabel={intl.formatMessage({
            id: `OfferCardHorizontal.screenReaderLabel_ariaCloseOffer`,
          })}
          buttonVariant={BUTTON_VARIANTS.TEXT}
          expandButtonAriaControls={`${accordionId}-content`}
          expandButtonDataTracking={this.idExploredOfferTrakingKeyPath}
          collapseIcon={<IconChevronUp />}
          expandIcon={<IconChevronDown />}
        >
          <StyledAccordion.Title
            id={`${accordionId}-title`}
            ariaControls={`${accordionId}-content`}
            ref={reference}
            dataTracking={this.idExploredOfferTrakingKeyPath}
            trackingKeySelector={this.trackingKeySelector}
          />
          <StyledAccordion.Content
            id={`${accordionId}-content`}
            dataTestid="targeted-service-card-accordion-content"
            labeledBy={`${accordionId}-title`}
            className="offerCollapsibleContent"
          >
            <OfferCardDetailsContent>
              <div>
                <this.OfferProperties
                  customHeadline={`${COPY_ID_PREFIX}.PROPERTIES_headline`}
                  properties={cardProperties}
                  offerId={contentKey}
                  intl={intl}
                  iNeedTheseProps={["is12HourTimeFormat"]}
                />
              </div>
              <this.OfferAddOnsWithCountry
                trackEvent={trackEvent}
                addOns={cardAddOns}
                offerKey={contentKey}
                intl={intl}
                showCurrencyIsoCodeForPrices={false}
                iNeedTheseProps={[
                  "nativeLanguageCode",
                  "formatCurrencyWithNativeLocale",
                ]}
                customHeadline={`${COPY_ID_PREFIX}.ADDONS_headline`}
              />
            </OfferCardDetailsContent>
          </StyledAccordion.Content>
        </StyledAccordion>
      </TargetedServiceCardWrapper>
    );
  }
}
