// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for OfferCard
 *
 */

import styled from "styled-components";
import { breakpoints, colors, dhlVarColors } from "root/libs/ui-styleguide/src";
import { Text } from "@dhl-official/react-ui-library";
import {
  IconButton,
  IconButtonTooltip,
  Link,
} from "root/libs/ui-components/src";

export const StyledOfferCard = styled.div`
  position: relative;
  background-color: ${colors.white};
  box-shadow: 0 3px 8px 0 ${dhlVarColors.shadowLightDefault};
  border-radius: 4px;
  padding-bottom: 70px;

  &:hover {
    box-shadow: 0 3px 8px 0 ${dhlVarColors.shadowDarkFocus};
  }

  &:focus,
  &:focus-within {
    box-shadow: 0 2px 7px 1px ${dhlVarColors.shadowLightHover};
    outline: 2px solid transparent;
  }

  &.highlighted {
    border: 2px solid ${dhlVarColors.secondary};
    order: -1;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-area: ${(props) => `card-${props.offerIndex}`};

    &.highlighted {
      z-index: 1;
      scale: ${(props) => (props.expandedCardIndex === null ? `1.1` : false)};
      overflow: ${(props) => (props.iAmExpanded ? "hidden" : false)};
      order: revert;
    }
  }
`;

export const StyledContainerGridArea = styled.div`
  position: relative;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: ${(props) => (props.iAmExpanded ? "grid" : false)};
    grid-template-columns: ${(props) =>
      props.iAmExpanded ? "repeat(2, minmax(0, 1fr))" : false};
  }

  & > .vector-background {
    position: absolute;
    top: -22px;

    @media screen and (min-width: ${breakpoints.tablet}) {
      top: ${(props) => (props.iAmExpanded ? "-38px" : false)};
      left: ${(props) => (props.iAmExpanded ? "-70px" : false)};
      z-index: -1;
    }
  }
`;

export const StyledEstimatedDeliveryText = styled(Text)`
  margin-top: 20px;
  margin-right: ${(props) =>
    props.isFirst && props.expandedCardIndex === null ? "40px" : "20px"};
  margin-left: ${(props) =>
    props.isLast && props.expandedCardIndex === null ? "40px" : "20px"};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${(props) =>
      props.iAmExpanded && !props.isHighlighted ? 0 : false};
    grid-column: ${(props) => (props.iAmExpanded ? "span 2" : false)};
  }
`;

export const StyledEstimatedDeliveryInfo = styled(Text)`
  margin-top: 20px;
  margin-right: ${(props) =>
    props.isFirst && props.expandedCardIndex === null ? "40px" : "20px"};
  margin-left: ${(props) =>
    props.isLast && props.expandedCardIndex === null ? "40px" : "20px"};

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-column: ${(props) => (props.iAmExpanded ? "span 2" : false)};
    order: ${(props) => (props.iAmExpanded ? 3 : false)};
  }
`;

export const StyledTextAndTooltip = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  position: relative;
`;

export const StyledIconButtonTooltip = styled(IconButtonTooltip)`
  position: absolute;
  right: 0;
  top: 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    right: ${(props) =>
      props.isFirst && props.expandedCardIndex === null ? "20px" : false};
  }
`;

export const StyledPriceContainer = styled.div`
  display: flex;
  position: relative;
  padding-top: 20px;
  padding-bottom: 40px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-right: ${(props) =>
      props.isFirst && props.expandedCardIndex === null ? `20px` : ""};
    margin-left: ${(props) =>
      props.isLast && props.expandedCardIndex === null ? `20px` : ""};
    order: ${(props) => (props.iAmExpanded ? 2 : false)};
    justify-content: ${(props) => (props.iAmExpanded ? "center" : false)};
    padding: ${(props) => (props.iAmExpanded ? 0 : false)};
  }
`;

export const StyledContainer = styled.div`
  padding: 10px 20px 20px 20px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-right: ${(props) =>
      props.isFirst && props.expandedCardIndex === null ? `20px` : ``};
    margin-left: ${(props) =>
      props.isLast && props.expandedCardIndex === null ? `20px` : ``};
    order: ${(props) => (props.iAmExpanded ? 1 : false)};
  }
`;

export const StyledCTAWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 10px 20px 20px 20px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-right: ${(props) =>
      props.isFirst && props.expandedCardIndex === null ? `40px` : ``};
    padding-left: ${(props) =>
      props.isLast && props.expandedCardIndex === null ? `40px` : ``};
    order: ${(props) => (props.iAmExpanded ? 3 : false)};
    grid-column: ${(props) => (props.iAmExpanded ? "span 2" : false)};
  }
`;

export const StyledLine = styled.hr`
  border: 0;
  height: 1px;
  background: ${(props) =>
    props.isHighlighted
      ? dhlVarColors.secondary
      : dhlVarColors.shadowLightDefault};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-right: ${(props) =>
      props.isFirst && props.expandedCardIndex === null ? `40px` : `20px`};
    margin-left: ${(props) =>
      props.isLast && props.expandedCardIndex === null ? `40px` : `20px`};
    order: ${(props) => (props.iAmExpanded ? 3 : false)};
    grid-column: ${(props) => (props.iAmExpanded ? "span 2" : false)};
  }
`;

export const StyledExpandCollapseOffer = styled(IconButton)`
  border: none;
  position: absolute;
  right: 20px;
  bottom: 20px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    right: ${(props) =>
      props.isFirst && props.expandedCardIndex === null ? "40px" : false};
  }

  &:focus,
  &:active {
    border: none;
    box-shadow: 0 0 0 2px white, 0 0 0 4px #d40511;
  }
`;

export const StyledAdditionalOfferDetails = styled.div`
  padding: 2rem;
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    flex-wrap: wrap;
  }
`;
