// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * TabTitle
 * Subcomponent of Tabs.
 * Subcomponent: In this context, sub-components are defined as
 * components which have their own definition declared within
 * another parent component, and can only be used in the
 * context of that parent component.
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { keyCodes } from "root/libs/constants/src";
import { TabListItem, TabLinkStyled } from "./styled";

export default class TabTitle extends React.PureComponent {
  static propTypes = {
    /** Id of the accordion. It will be cross-used in the AccordionContent
     * to identify which is the label of that content. */
    id: PropTypes.string.isRequired,
    /** Points to the ID of the content which the accordion title controls */
    ariaControls: PropTypes.string.isRequired,
    /** String to render inside the headline */
    headlineString: PropTypes.string,
    /** The font weight of the headline in nummeric strings */
    headlineFontWeight: PropTypes.string,
    /** The text alignment of the headline */
    headlineAlignment: PropTypes.oneOf(["left", "center"]),
    /**
     * Event listener to call on click
     *
     * @param {SyntheticEvent} event - React's original SyntheticEvent.
     * @param {object} data - All props.
     */
    onClick: PropTypes.func,
    /**
     * Event listener to call on right arrow keydown
     */
    showNextTab: PropTypes.func,
    /**
     * Event listener to call left arrow keydown
     */
    showPrevTab: PropTypes.func,
    /** Will tell the component, wheter it should show its content or not. */
    isSelected: PropTypes.bool,
    /** Property for passing extra css styles in order to style accordion title as needed inside of parent components */
    extraStyles: PropTypes.array,
    /** Property that match the index of the accordions in case there are multiple accordions. It can be used for example for styling depending on the order of the accordion in the list. */
    childIndex: PropTypes.number,
    reference: PropTypes.object,
    /** object key path to an interactive element to the digitalLayer */
    dataTracking: PropTypes.string,
    /** Will ensure that all the TabTitle occupy the same visual space. (Same width) */
    numberOfTabs: PropTypes.number,
    /** id for automation */
    dataTestid: PropTypes.string,
    /* Selector from the tracking container to get the tracking key */
    trackingKeySelector: PropTypes.func,
  };

  static defaultProps = {
    headlineString: "",
    onClick: () => { },
    numberOfTabs: 2,
    showNextTab: () => { },
    showPrevTab: () => { },
    isSelected: false,
    extraStyles: [],
    childIndex: 0,
    reference: null,
    dataTracking: "",
    headlineFontWeight: "700",
    headlineAlignment: "left",
    dataTestid: undefined,
    trackingKeySelector: () => { },
  };

  constructor(props) {
    super(props);
    if (props.dataTracking) {
      this.titleDataTracking = props.trackingKeySelector(props.dataTracking);
    }
  }

  componentDidUpdate(prevProps) {
    const { isSelected, reference } = this.props;

    if (prevProps.reference !== reference) {
      const hasReference = reference && reference.current;
      if (isSelected && hasReference) {
        reference.current.focus();
      }
    }
  }

  // We need to call preventDefault, so the default click of the
  // button is not called. If this happen the accordion will not expand
  onClick = (event) => {
    event.preventDefault();
    const { onClick } = this.props;
    onClick(event, this.props);
  };

  onKeyPress = (event) => {
    const { keyCode } = event;
    const { showNextTab, showPrevTab } = this.props;

    if (keyCode === keyCodes.RIGHT) {
      showNextTab(this.props);
    } else if (keyCode === keyCodes.LEFT) {
      showPrevTab(this.props);
    }
  };

  getTabIndex = () => {
    const { isSelected } = this.props;

    return isSelected ? "0" : "-1";
  };

  getClassName = () => {
    const { isSelected } = this.props;

    return isSelected ? "is-selected" : "";
  };

  render() {
    const {
      id,
      ariaControls,
      headlineString,
      isSelected,
      extraStyles,
      childIndex,
      reference,
      headlineFontWeight,
      headlineAlignment,
      numberOfTabs,
      dataTestid,
    } = this.props;

    const extraProps = {};
    if (this.titleDataTracking) {
      extraProps["data-tracking"] = this.titleDataTracking;
    }

    return (
      <TabListItem
        role="presentation"
        className={this.getClassName()}
        numberOfTabs={numberOfTabs}
      >
        <TabLinkStyled
          id={id}
          href={id}
          role="tab"
          aria-controls={ariaControls}
          aria-selected={isSelected}
          onClick={this.onClick}
          extraStyles={extraStyles}
          childIndex={childIndex}
          tabIndex={this.getTabIndex()}
          className={this.getClassName()}
          onKeyDown={this.onKeyPress}
          ref={reference}
          {...extraProps}
          headlineFontWeight={headlineFontWeight}
          headlineAlignment={headlineAlignment}
          data-testid={dataTestid}
        >
          <span className="tabLinkText">{headlineString}</span>
        </TabLinkStyled>
      </TabListItem>
    );
  }
}
