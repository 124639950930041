// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for OfferCard
 *
 */

import styled from "styled-components";
import { Accordion, IconDoubleChevron } from "@dhl-official/react-ui-library";
import { breakpoints, colors, dhlVarColors } from "root/libs/ui-styleguide/src";

export const OfferCardWrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: ${colors.white};
  box-shadow: 0 3px 8px 0 ${dhlVarColors.shadowLightDefault};
  transition: ease-in-out 200ms box-shadow;
  border-radius: 4px;

  &.has-discount {
    padding-top: 20px;

    @media screen and (min-width: ${breakpoints.tablet}) {
      padding-top: 0;
  }
  }

  > .accordion {
    width: 100%;
    margin: 0 0 30px 0;
    padding: 0;
  }

  .offerCollapsibleContent {
    margin-bottom: 0;
    padding: 0;

    &.expanded {
      padding: 2.1rem 2.1rem 30px 2.1rem;
    }
  }

  &:hover {
    box-shadow: 0 3px 8px 0 ${dhlVarColors.shadowDarkFocus};

    .accordion {
      & > div:not(.accordionPanel) {
        button {
          box-shadow: 0 8px 5px -5px ${dhlVarColors.shadowDarkFocus};
        }
      }
    }
  }

  &:focus,
  &:focus-within {
    box-shadow: 0 2px 7px 1px ${dhlVarColors.shadowLightHover};
    outline: 2px solid transparent;

    .accordion {
      & > div:not(.accordionPanel) {
        button {
          box-shadow: 0 8px 5px -5px ${dhlVarColors.shadowLightHover};
        }
      }
    }
  }

  @media print {
    padding: 20px;
    page-break-after: always;
    page-break-inside: avoid;
    margin-top: 30px;

    & .accordion > dd {
      height: auto;
    }
  }
`;

const CardContentPaddingMobile = "20px 10px 30px";
const CardContentPaddingTablet = "30px 20px";
const CardContentPaddingDesktop = "25px 30px";

export const OfferCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 190px;
  padding: ${CardContentPaddingMobile};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${CardContentPaddingTablet};
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding: ${CardContentPaddingDesktop};
  }
`;

export const DeliveryInfoContent = styled.div`
  margin-bottom: 30px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-basis: 48rem;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-bottom: 0;
    flex-basis: 58rem;
  }

  @media all and (-ms-high-contrast: none) {
    width: 66%;
  }
`;

export const PaymentInfoContent = styled.div`
  position: relative;
  display: block;

  @media screen and (min-width: ${breakpoints.tablet}) {
    min-width: 280px;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    width: 280px;
  }
`;

const lightBorder = `1px solid ${dhlVarColors.lightBorderColor};`;

export const StyledAccordion = styled(Accordion)`
  position: relative;
  padding: 0;

  .accordion {
    width: 100%;

    & > div > button:focus {
      outline: 1px dotted ${colors.silverDark};
    }

    & > div:not(.accordionPanel) {
      button {
        position: absolute;
        bottom: -20px;
        background: #fff;
        box-shadow: rgb(229 229 229) 0 9px 6px -5px;
        transition: ease-in-out 200ms box-shadow;
      }
    }
  }
`;

export const IconChevronUp = styled(IconDoubleChevron)`
  transform: rotate(180deg);
`;

export const IconChevronDown = styled(IconDoubleChevron)`
  transform: rotate(0deg);
`;

export const OfferCardDetailsContent = styled.div`
  padding-top: 30px;
  padding-bottom: 0;
  margin-left: 10px;
  margin-right: 10px;
  border-top: ${lightBorder};
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-top: 35px;
    padding-bottom: ${(props) =>
      props.shouldRenderQuoteValidity ? "0" : "40px"};
    margin-left: 20px;
    margin-right: 20px;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;

    > div {
      width: 50%;

      &:first-child {
        margin-right: 0;
      }
    }
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-top: 20px;
    margin-left: 30px;
    margin-right: 30px;

    > div:first-child {
      margin-right: 127px;
    }
  }
`;

export const OfferCardPriceDiscount = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 110px;
  height: 19px;
  padding-left: 11px;
  background: #d4070f; // TODO: Replace with design token
  color: #fff; // TODO: Replace with design token
  font-size: 12px;
  font-style: italic;
  clip-path: polygon(0 0, 100% 0%, 89.25% 100%, 0% 100%);

  @media screen and (min-width: ${breakpoints.tablet}) {
    font-size: 14px;
    width: 115px;
    height: 24px;
    line-height: 24px;
  }
`