// 9fbef606107a605d69c0edbcd8029e5d

import styled, { css } from "styled-components";
import { key } from "styled-theme";
import { Text } from "@dhl-official/react-ui-library";
import { breakpoints, grid } from "root/libs/ui-styleguide/src";
import WeightSelector from "one-time-shipment/src/components/ShipmentItem/WeightSelector";
import {
  Headline,
  Icon,
  IconButton,
  Form,
  GridContainer,
  biDimemsionalCell,
} from "root/libs/ui-components/src";

import bgImageL from "./images/shipment-lane-bg-L.webp";
import bgImageS from "./images/shipment-lane-bg-S.webp";

const ieCheckboxButtonPadding = "60%";
const dimensionsContentPaddingLeftRightTablet = "38px";
const dimensionsContentPaddingLeftRightDesktop = "25px";
const dimensionsContentGridGap = "20px";
const dimensionsContentMarginTopTotalShipmentWeight = "20px";

export const ShipmentLaneFormWrapper = styled(Form)`
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: ${key("colors.secondary")};
  background-image: url(${bgImageS}),
    linear-gradient(135deg, ${key("colors.secondary")} 9%, #fff1b4 100%);
  background-size: cover;
  position: relative;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-top: 45px;
    padding-bottom: 50px;
    background-image: url(${bgImageL}),
      linear-gradient(135deg, ${key("colors.secondary")} 9%, #fff1b4 100%);
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-top: 60px;
    padding-bottom: 20px;
  }

  .button-wrapper {
    margin-left: ${grid.container.paddings.s};
    margin-right: ${grid.container.paddings.s};

    @media screen and (min-width: ${breakpoints.tablet}) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  @media print {
    padding: 0px;

    & > h2 {
      margin: 0px;
    }
  }
`;

export const StyledHeadline = styled(Headline)`
  margin-left: ${grid.container.paddings.s};
  margin-right: ${grid.container.paddings.s};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: 0 0 30px 0;
  }
`;

export const ieShipmentItemGridContainer = css`
  /* Only for IE11 */
  @media all and (-ms-high-contrast: none) {
    max-width: ${grid.container.maxWidthInnerContainer}px;
  }

  /* Only for EDGE */
  /* stylelint-disable selector-type-no-unknown */
  _:-ms-lang(x),
  _:-webkit-full-screen,
  & {
    max-width: ${grid.container.maxWidthInnerContainer}px;
  }
  /* stylelint-enable selector-type-no-unknown */
`;

export const ShipmentItemGridContainer = styled(GridContainer)`
  margin-top: 20px;

  @supports (display: grid) {
    grid-row-gap: 0;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-template-columns: repeat(12, 1fr);
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    grid-template-columns: repeat(12, 73px);
  }

  ${ieShipmentItemGridContainer}
`;

export const ieSubmitButtonCell = css`
  /* Only for IE11 */
  @media all and (-ms-high-contrast: none) {
    padding-left: ${ieCheckboxButtonPadding};
  }

  /* Only for EDGE */
  /* stylelint-disable selector-type-no-unknown */
  _:-ms-lang(x),
  _:-webkit-full-screen,
  & {
    padding-left: ${ieCheckboxButtonPadding};

    @supports (display: grid) {
      padding-left: 0;
    }
  }
  /* stylelint-enable selector-type-no-unknown */
`;

export const SubmitButtonCell = styled.div`
  ${biDimemsionalCell}
  margin-top: ${dimensionsContentGridGap};

  @media screen and (min-width: ${breakpoints.tablet}) {
    align-self: center;

    @supports (display: grid) {
      grid-column: 7 / span 6;
    }

    button {
      width: 100%;
    }
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-left: 40px;

    @supports (display: grid) {
      grid-column: 8 / span 4;
    }
  }

  @media print {
    display: none;
  }

  ${ieSubmitButtonCell}
`;

export const ieAddItemButtonCell = css`
  /* Only for IE11 */
  @media all and (-ms-high-contrast: none) {
    padding-right: 50%;
    height: 0;
    margin-top: 30px;
    margin-bottom: -30px;
  }

  /* Only for EDGE */
  /* stylelint-disable selector-type-no-unknown */
  _:-ms-lang(x),
  _:-webkit-full-screen,
  & {
    padding-right: 50%;
    height: 0;
    margin-top: 30px;
    margin-bottom: -30px;

    @supports (display: grid) {
      padding-left: 0;
      margin-top: 0;
      margin-bottom: 0;
      padding-right: 0;
      height: auto;
      width: 100%;

      @media screen and (min-width: ${breakpoints.tablet}) {
        width: 500px;
      }
    }
  }
  /* stylelint-enable selector-type-no-unknown */
`;

export const AddItemButtonCell = styled.div`
  ${biDimemsionalCell}

  @media screen and (min-width: ${breakpoints.tablet}) {
    align-self: start;

    @supports (display: grid) {
      grid-column: 1;
      width: fit-content;
      grid-row-end: span 3;
    }
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    @supports (display: grid) {
      grid-column: 2;
    }
  }

  @media print {
    & > button {
      display: none;
    }
  }

  ${ieAddItemButtonCell}
`;

export const ieStyledIconButton = css`
  /* Only for IE11 */
  @media all and (-ms-high-contrast: none) {
    width: auto;
  }

  /* Only for EDGE */
  /* stylelint-disable selector-type-no-unknown */
  _:-ms-lang(x),
  _:-webkit-full-screen,
  & {
    width: 100%;

    @media screen and (min-width: ${breakpoints.tablet}) {
      width: auto;
    }
  }
  /* stylelint-enable selector-type-no-unknown */
`;

export const StyledIconButton = styled(IconButton)`
  line-height: 1em;
  width: 100%;
  margin-top: 10px;

  svg {
    margin-right: 10px;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: 20px;
    padding-left: 52px;
    padding-right: 20px;
    width: max-content;

    &:active,
    &:focus {
      padding-left: 51px;
      padding-right: 19px;
    }

    svg {
      margin-right: 0;
      position: absolute;
      left: 25px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-top: 0;
  }

  ${ieStyledIconButton}
`;

export const DimensionsContent = styled(GridContainer)`
  position: relative;
  transition: padding-top 0.3s ease-out;
  ${(props) =>
    props.isItemIndexVisible ? "padding-top: 29px;" : "padding-top: 5px;"}
  padding-bottom: 5px;
  background-color: ${key("colors.backgroundColorInverted")};
  border-radius: 4px;
  ${biDimemsionalCell}
  margin-bottom: ${dimensionsContentGridGap};

  &.added-item {
    overflow: hidden;
    opacity: 0;
    animation: new-item 0.4s ease-in-out forwards;
    margin-top: 30px;
  }

  &.deleted-item {
    overflow: hidden;
    height: auto;
    animation:
      remove-item-hide 0.3s ease-in-out forwards,
      remove-item-shrink 0.3s 0.3s ease-in-out forwards;
  }

  @keyframes new-item {
    0% {
      opacity: 0;
      transform: translateY(-110%);
    }

    20% {
      opacity: 0.7;
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes remove-item-hide {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes remove-item-shrink {
    0% {
      height: auto;
      padding-top: 29px;
      padding-bottom: 5px;
      margin-top: 30px;
      margin-bottom: ${dimensionsContentGridGap};
    }

    100% {
      height: 0;
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: 42px ${dimensionsContentPaddingLeftRightTablet} 20px;
    ${(props) =>
      props.isItemIndexVisible ? "padding-top: 56px;" : "padding-top: 42px;"}
    align-items: flex-start;
  }

  @supports (display: grid) {
    grid-row-gap: 0;
    grid-template-rows: [weightRow] 1fr [dimensionsRow] auto; /* stylelint-disable-line value-keyword-case */

    @media screen and (min-width: ${breakpoints.tablet}) {
      grid-column-gap: 25px;
      grid-template-areas: ". weightCol weightCol weightCol weightCol . . . iconCol iconCol iconCol .";
      grid-template-rows: [weightRow] auto [dimensionsRow] auto [presetsRow] auto; /* stylelint-disable-line value-keyword-case */
      grid-template-columns: repeat(1, 1fr);
      padding-left: 15px;
      padding-right: 15px;
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      grid-template-rows: [dimensionsRow] 1fr [presetsRow] auto; /* stylelint-disable-line value-keyword-case */
      grid-template-areas: ${(props) =>
        props.isBusiness
          ? '". weightCol weightCol iconCol iconCol dimensionsCol dimensionsCol dimensionsCol dimensionsCol dimensionsCol quantityCol quantityCol"'
          : '". weightCol weightCol iconCol iconCol dimensionsCol dimensionsCol dimensionsCol dimensionsCol dimensionsCol dimensionsCol ."'};
      ${(props) =>
        props.isBusiness
          ? "grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 8fr 1fr;"
          : ""}
    }
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-left: ${dimensionsContentPaddingLeftRightDesktop};
    padding-right: ${dimensionsContentPaddingLeftRightDesktop};
  }

  @media print {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledWeightSelector = styled(WeightSelector)`
  @media screen and (min-width: ${breakpoints.desktop}) {
    ${(props) => (props.isBusiness ? "grid-column-start: 1;" : "")}
  }
`;

export const ieSettingsCheckboxWrapper = css`
  /* Only for IE11 */
  @media all and (-ms-high-contrast: none) {
    padding-left: ${ieCheckboxButtonPadding};
    margin: 20px 0;
  }

  /* Only for EDGE */
  /* stylelint-disable selector-type-no-unknown */
  _:-ms-lang(x),
  _:-webkit-full-screen,
  & {
    padding-left: ${ieCheckboxButtonPadding};
    margin: 20px 0;

    @supports (display: grid) {
      padding-left: 0;
    }
  }
  /* stylelint-enable selector-type-no-unknown */
`;

export const SettingsCheckboxWrapper = styled.div`
  margin: 0;
  ${biDimemsionalCell}

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: 10px 0 -12px;

    @supports (display: grid) {
      grid-column: 7 / span 6;
    }
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin: -10px 0 -5px;
    padding-left: 40px;

    @supports (display: grid) {
      grid-column: 8 / span 4;
    }
  }

  @media print {
    display: flex;
    flex-direction: row;
  }

  ${ieSettingsCheckboxWrapper}
`;

export const StyledCheckboxInfo = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  margin: 0;
  justify-content: space-between;
  padding: 10px 0 0 0;
  position: relative;
  width: 100%;

  .iconWrapper {
    position: absolute;
    right: 0;
    top: 0;
  }

  label {
    height: auto;
    min-height: 20px;
  }

  input {
    /*! @noflip */
    left: -9999px;
    /*! @noflip */
    right: unset;
    position: absolute;
  }
`;

export const StyledItemIndexNumber = styled(Text)`
  position: absolute;
  top: 4px;
  left: 12px;
  margin-top: 10px;
  margin-bottom: 10px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    top: 9px;
    left: 10px;
    left: ${dimensionsContentPaddingLeftRightTablet};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    left: ${dimensionsContentPaddingLeftRightDesktop};
  }
`;

export const StyledCloseIcon = styled(Icon)`
  transition: opacity 0.3s ease-out;
  ${(props) => (props.itemAdded ? "opacity: 1;" : "opacity: 0;")}
  position: absolute;
  top: 5px;
  right: 5px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    top: 10px;
    right: 10px;
  }
`;

export const StyledHiddenCloseIconContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;
  width: 50px;
  height: 50px;
`;

export const StyledHiddenCloseIcon = styled(Icon)`
  opacity: 0;
  position: absolute;
  bottom: -60px;
  right: 5px;

  &:focus {
    opacity: 1;
    bottom: 5px;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    right: 10px;

    &:focus {
      bottom: 10px;
    }
  }
`;

export const StyledTotalShipmentWeight = styled(Text)`
  margin-top: ${(props) =>
    props.addItemButtonVisible
      ? dimensionsContentMarginTopTotalShipmentWeight
      : "10px"};
  margin-bottom: 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${dimensionsContentMarginTopTotalShipmentWeight};
    margin-bottom: 10px;
    white-space: nowrap;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-top: ${(props) =>
      props.addItemButtonVisible
        ? dimensionsContentMarginTopTotalShipmentWeight
        : 0};
  }
`;
export const ToolTipText = styled(Text)`
  margin-bottom: ${(props) => (props.isParagraph ? "10px" : 0)};
`;
