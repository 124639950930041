import { connect } from "react-redux";
import { hideOffersLane } from "one-time-shipment/src/containers/OffersLane/actions";
import {
  resetItem,
  setUnitSystem,
} from "one-time-shipment/src/containers/ShipmentLane/actions";
import UnitSelector from "./index";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onUnitSelectorChange: (value) => {
    dispatch(resetItem({}));
    dispatch(setUnitSystem(value));
    dispatch(hideOffersLane());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UnitSelector);
