// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * WCCMultiBUOfferCardHeader
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { IntlPropType } from "root/libs/core-libs/src";
import { offerPurchaseMethods } from "root/libs/constants/src";
import ResponsiveRender from "one-time-shipment/src/components/Layout/ResponsiveRender";
import wccWalkIcon from "icons/cx/wcc-walk.svg";
import wccClickIcon from "icons/cx/wcc-click.svg";
import wccCallIcon from "icons/cx/wcc-call.svg";

import {
  StyledOfferCardHeader,
  StyledHeadingWrapper,
  StyledHeadline,
  StyledIconWrapper,
  ArrowIcon,
} from "./styled";

const WCCIcons = {
  WALK: wccWalkIcon,
  CLICK: wccClickIcon,
  CALL: wccCallIcon,
};

const COPY_ID_PREFIX = "WCCMultiBUOfferCardHeader";

export default function WCCMultiBUOfferCardHeader({
  intl,
  purchaseMethod,
  offerIndex,
  onClick,
  isHeaderOpen,
}) {
  function getCopy(id, values = {}) {
    return intl.formatMessage({ id }, values);
  }

  return (
    <StyledOfferCardHeader
      tabIndex={0}
      onClick={onClick}
      purchaseMethod={purchaseMethod}
    >
      <StyledHeadingWrapper>
        <ResponsiveRender
          render={(isMobile) =>
            isMobile ? (
              <StyledHeadline
                priority={2}
                headlineDisplayType="h"
                weight="700"
                data-testid={`wcc-offer-card-headline-${offerIndex}`}
                id={`wcc-offer-card-headline-${offerIndex}`}
                noMargin
              >
                {getCopy(
                  `${COPY_ID_PREFIX}.${purchaseMethod}_headline_mobile`,
                  {
                    br: <br />,
                  }
                )}
              </StyledHeadline>
            ) : (
              <StyledHeadline
                priority={2}
                headlineDisplayType="h"
                weight="700"
                data-testid={`wcc-offer-card-headline-${offerIndex}`}
                id={`wcc-offer-card-headline-${offerIndex}`}
                noMargin
              >
                {getCopy(
                  `${COPY_ID_PREFIX}.${purchaseMethod}_headline_desktop`
                )}
              </StyledHeadline>
            )
          }
        />
        <StyledIconWrapper>
          <ArrowIcon isHeaderOpen={isHeaderOpen} />
        </StyledIconWrapper>
      </StyledHeadingWrapper>
    </StyledOfferCardHeader>
  );
}

WCCMultiBUOfferCardHeader.propTypes = {
  /** Internationalization library */
  intl: IntlPropType.isRequired,
  /** The purchase method */
  purchaseMethod: PropTypes.oneOf(Object.values(offerPurchaseMethods)),
  /** The index of the offer */
  offerIndex: PropTypes.number,
  isHeaderOpen: PropTypes.bool,
  onClick: PropTypes.func,
};

WCCMultiBUOfferCardHeader.defaultProps = {
  purchaseMethod: offerPurchaseMethods.CLICK,
};
