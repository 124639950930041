// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * WCCOfferAdditionalTimings
 *
 */

import React from "react";
import PropTypes from "prop-types";
import sortBy from "lodash/sortBy";
import { IntlPropType, getTimeOfDay } from "root/libs/core-libs/src";
import { Text, TEXT_SIZES } from "@dhl-official/react-ui-library";

const COPY_ID_PREFIX = "WCCOfferAdditionalTimings";

export default function WCCOfferAdditionalTimings({
  intl,
  offerIndex,
  offerId,
  metadataByPurchaseMethod,
  useFallback,
  isMultiBU,
}) {
  function getRestOfTheAvailableDeliveryTimes() {
    const { availableDeliveryTimes } = metadataByPurchaseMethod;
    const offersExcludingSelected = availableDeliveryTimes.filter(
      (obj) => obj.id !== offerId
    );
    return sortBy(offersExcludingSelected, "estimatedDeliveryTime");
  }

  // If we need to add the 4 time options, this is the place to do it:
  function createStringMessage() {
    if (useFallback) {
      return intl.formatMessage({ id: `${COPY_ID_PREFIX}.fallback` });
    }
    const remainingOffers = getRestOfTheAvailableDeliveryTimes();
    return intl.formatMessage(
      { id: `${COPY_ID_PREFIX}.text` },
      {
        slot1:
          remainingOffers[0]?.estimatedDeliveryTime &&
          getTimeOfDay(
            intl,
            remainingOffers[0]?.estimatedDeliveryTime,
            "",
            true
          ),
        slot2:
          remainingOffers[1]?.estimatedDeliveryTime &&
          getTimeOfDay(
            intl,
            remainingOffers[1]?.estimatedDeliveryTime,
            "",
            true
          ),
      }
    );
  }

  return isMultiBU ? (
    <Text
      dataTestid={`wcc-offer-card-additional-timings-${offerIndex}`}
      size={TEXT_SIZES.TINY}
      isParagraph
    >
      {createStringMessage()}
    </Text>
  ) : (
    <Text
      dataTestid={`wcc-offer-card-additional-timings-${offerIndex}`}
      weight="200"
      isParagraph
    >
      {createStringMessage()}
    </Text>
  );
}

WCCOfferAdditionalTimings.propTypes = {
  /** Internationalization library */
  intl: IntlPropType.isRequired,
  /** The index of the offer */
  offerIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  offerId: PropTypes.string,
  metadataByPurchaseMethod: PropTypes.object,
  useFallback: PropTypes.bool,
  isMultiBU: PropTypes.bool,
};
