// 9fbef606107a605d69c0edbcd8029e5d

// Confused? Read the docs: docs/general/enable-environment-settings.md
const hostName = () => window.location.hostname;
const resourcesLink = () => window.resourceBaseUrl || false;

const isStaging = () => hostName().indexOf("staging") !== -1;
const isEmbedded = () =>
  typeof document.body.dataset.gaqStandalone === "undefined";

const isEmbeddedProd = () =>
  (isEmbedded() &&
    resourcesLink() &&
    resourcesLink().indexOf("cj-gaq.") !== -1) ||
  false;

export default {
  enableEnvSettings: () => {
    let environmentSettingsEnabled = false;

    // For Safari security error prevention. More: http://codingrepo.com/javascript/2018/11/15/safari-securityerror-dom-exception-18-thrown-by-localstorage-or-cookies-are-blocked/
    if (navigator.cookieEnabled && window.localStorage) {
      environmentSettingsEnabled =
        localStorage.getItem("environmentSettingsEnabled") === "true" &&
        !isEmbedded();
    }

    return environmentSettingsEnabled && !isEmbeddedProd();
  },
  isStaging,
  isEmbedded,
  isEmbeddedProd,
};
