// 9fbef606107a605d69c0edbcd8029e5d

export const keyCodes = {
  RETURN: 13,
  SPACE: 32,
  ESC: 27,
  TAB: 9,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  PLUS: 107,
  MINUS: 109,
};
