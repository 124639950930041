// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for TargetedServiceCard
 *
 */

import styled from "styled-components";
import { key } from "styled-theme";
import {
  Accordion,
  IconDoubleChevron,
  Text,
} from "@dhl-official/react-ui-library";
import { breakpoints, colors, dhlVarColors } from "root/libs/ui-styleguide/src";

const DHL_BUSINESS_YELLOW = "#FBBC05";

export const TargetedServiceCardWrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: ${colors.white};
  box-shadow: 0 3px 8px 0 ${dhlVarColors.shadowLightDefault};
  transition: ease-in-out 200ms box-shadow;
  border-radius: 4px;
  border: 2px solid ${DHL_BUSINESS_YELLOW};

  &:before {
    border-bottom: 4.3rem solid transparent;
    border-right: 4.3rem solid ${DHL_BUSINESS_YELLOW};
    border-top: 0 solid transparent;
    content: "";
    height: 0;
    position: absolute;
    right: 0;
    width: 0;

    @media screen and (min-width: ${breakpoints.desktop}) {
      border-bottom: 7.6rem solid transparent;
      border-right: 7.6rem solid ${DHL_BUSINESS_YELLOW};
    }
  }

  > .accordion {
    width: 100%;
    margin: 0 0 30px 0;
    padding: 0;
  }

  .offerCollapsibleContent {
    margin-bottom: 0;
    padding: 0;

    &.expanded {
      padding: 2.1rem 2.1rem 30px 2.1rem;
    }
  }

  &:hover {
    box-shadow: 0 3px 8px 0 ${dhlVarColors.shadowDarkFocus};

    .accordion {
      & > div:not(.accordionPanel) {
        button {
          box-shadow: 0 8px 5px -5px ${dhlVarColors.shadowDarkFocus};
        }
      }
    }
  }

  &:focus,
  &:focus-within {
    box-shadow: 0 2px 7px 1px ${dhlVarColors.shadowLightHover};
    outline: 2px solid transparent;

    .accordion {
      & > div:not(.accordionPanel) {
        button {
          box-shadow: 0 8px 5px -5px ${dhlVarColors.shadowLightHover};
        }
      }
    }
  }

  @media print {
    padding: 20px;
    page-break-after: always;
    page-break-inside: avoid;
    margin-top: 30px;

    & .accordion > dd {
      height: auto;
    }
  }
`;

const CardContentPaddingMobile = "20px 10px 30px";
const CardContentPaddingTablet = "30px 20px";
const CardContentPaddingDesktop = "25px 30px";

export const TargetedServiceCardContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 190px;
  padding: ${CardContentPaddingMobile};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${CardContentPaddingTablet};
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding: ${CardContentPaddingDesktop};
  }
`;

export const IntroContent = styled.div`
  margin-bottom: 30px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: 0;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    width: 66%;
  }
`;

export const CreateAccountContent = styled.div`
  display: block;

  @media screen and (min-width: ${breakpoints.tablet}) {
    min-width: 280px;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    width: 280px;
  }
`;

const lightBorder = `1px solid ${dhlVarColors.lightBorderColor};`;

export const StyledAccordion = styled(Accordion)`
  position: relative;
  padding: 0;

  .accordion {
    width: 100%;

    & > div > button:focus {
      outline: 1px dotted ${colors.silverDark};
    }

    & > div:not(.accordionPanel) {
      button {
        position: absolute;
        bottom: -20px;
        background: #fff;
        box-shadow: rgb(229 229 229) 0 9px 6px -5px;
        transition: ease-in-out 200ms box-shadow;
      }
    }
  }
`;

export const IconChevronUp = styled(IconDoubleChevron)`
  transform: rotate(180deg);
`;

export const IconChevronDown = styled(IconDoubleChevron)`
  transform: rotate(0deg);
`;

export const OfferCardDetailsContent = styled.div`
  padding-top: 30px;
  padding-bottom: 0;
  margin-left: 10px;
  margin-right: 10px;
  border-top: ${lightBorder};
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-top: 35px;
    padding-bottom: 0;
    margin-left: 20px;
    margin-right: 20px;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;

    > div {
      width: 50%;

      &:first-child {
        margin-right: 0;
      }
    }
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-top: 20px;
    margin-left: 30px;
    margin-right: 30px;

    > div:first-child {
      margin-right: 127px;
    }
  }
`;
export const ToolTipText = styled(Text)`
  margin-bottom: ${(props) => (props.isParagraph ? "10px" : 0)};
`;

export const IntroContentWrapper = styled.div`
  order: 1;
  width: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    order: 2;
    padding-right: 30px;
  }
`;

export const IntroInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > .icon {
    position: absolute;
    top: 2.5rem;
    right: 1.6rem;
    order: 2;
    height: 100%;
    width: 100%;
    text-align: right;
    max-width: 72px;
    align-self: flex-end;
    margin-bottom: auto;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    align-items: center;

    > .icon {
      position: unset;
      top: unset;
      right: unset;
      margin-right: 30px;
      margin-bottom: 0;
      order: 1;
      text-align: left;
      height: auto;
      width: auto;
      max-width: unset;
      align-self: unset;
    }
  }
`;

export const RegularShipperText = styled(Text)`
  color: ${key("colors.headerColor")};
  margin: 0;
  margin-bottom: 2px;
`;

export const RecommendedSaveQuote = styled(Text)`
  text-align: left;
  margin-top: 1rem;

  @media screen and (min-width: ${breakpoints.desktop}) {
    text-align: right;
    width: 22rem;
  }
`;

export const CreateAccountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 15px;

  @media screen and (min-width: ${breakpoints.desktop}) {
    line-height: 24px;
    flex-direction: row;
    display: inline-flex;
    margin-bottom: 8px;
  }
`;

export const CreateAccountHeadline = styled(Text)`
  font-size: 2rem;
  line-height: 2.4rem;
  margin: 0;
  font-weight: 800;
  white-space: normal;
  color: ${key("colors.fontColor")} !important;
  padding-right: 9.3rem;

  @media screen and (min-width: ${breakpoints.tablet}) {
    max-width: 33.5rem;
    padding-right: 0;
  }
`;

export const PromotedTextWrapper = styled.div`
  display: flex;
  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-right: 50px;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-right: 0;
    align-items: inherit;

    .icon {
      margin-top: 5px;
    }
  }
`;

export const PromotedText = styled(Text)`
  min-height: 32px;
  min-height: 11%;
  padding-left: 1rem;
  @media screen and (min-width: ${breakpoints.desktop}) {
    width: 31.5rem;
  }
`;

export const CardButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 5px;

  a {
    width: 100% !important;
  }

  @media print {
    display: none;
  }
`;
