// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * PresetSizeSelector Component
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { IntlPropType } from "root/libs/core-libs/src";
import { Tabs } from "root/libs/ui-components/src";
import { SEGMENT_TYPES } from "one-time-shipment/src/containers/SpotShipmentPage/constants";
import PresetSizeCardGroup from "one-time-shipment/src/components/Forms/PresetSizeCardGroup";
import AdvancedSettings from "one-time-shipment/src/components/Forms/AdvancedSettings";
import { selectTrackingKey } from "one-time-shipment/src/containers/Tracking/selectors";
import {
  PresetMobileWrapper,
  PresetDesktopWrapper,
  StyledPresetsHeadline,
  StyledFieldset,
} from "./styled";

const copyIdPrefix = "PresetSizeSelector";

class PresetSizeSelector extends React.PureComponent {
  constructor(props) {
    super(props);
    this.trackingKeySelector = selectTrackingKey;
    this.sizesTabsMobileTrackingKeyPath = this.trackingKeySelector(
      "spotShipment.interactions.clickedOnSizesTabsMobile"
    );
  }

  componentDidUpdate(prevProps) {
    const { triggerShowCustomTab } = this.props;

    if (prevProps.triggerShowCustomTab !== triggerShowCustomTab) {
      this.showCustomTab();
    }
  }

  showCustomTab = () => {
    const { customTabRef } = this.props;
    customTabRef.current.click();
  };

  onClickSizesTabsMobile = (titleProps) => {
    const { trackEvent, onMobileAccordionSelect } = this.props;

    trackEvent({ clickedOnSizesTabsMobile: true });
    onMobileAccordionSelect(titleProps);
  };

  render() {
    const {
      intl,
      presetSize: selectedPresetSize,
      onPresetSelected,
      unit,
      isMobile,
      className,
      isBusiness,
      presetSizes,
      onCheckboxChange,
      settingsNonStackable,
      customTabRef,
      trackEvent,
      itemIndex,
      advancedSettingsAriaDescribedBy,
      segmentForPresets,
      isCheckboxDisabledBecauseOfWeight,
      selectedUnitSystem,
    } = this.props;

    const presetSizesWithLabels = presetSizes[segmentForPresets].map(
      (preset) => ({
        ...preset,
        label: intl.formatMessage({
          id: `${copyIdPrefix}.${preset.label}`,
        }),
      })
    );

    return (
      <StyledFieldset
        className={className}
        aria-describedby="presetSizes-instructions"
      >
        <legend className="visually-hidden">{`${intl.formatMessage({
          id: `${copyIdPrefix}.presetSizes`,
        })}`}</legend>
        <span
          id="presetSizes-instructions"
          className="visually-hidden"
        >{`${intl.formatMessage({
          id: `${copyIdPrefix}.title`,
        })}, ${intl.formatMessage({
          id: `${copyIdPrefix}.screenReaderLabel_describedByPresetsInstructions`,
        })}`}</span>
        {isMobile && (
          <PresetMobileWrapper>
            <Tabs onTitleClick={this.onClickSizesTabsMobile} activeIndex={1}>
              <Tabs.Title
                id="customSizes"
                ariaControls="customSizes-Content"
                headlineAlignment="center"
                headlineString={intl.formatMessage({
                  id: `${copyIdPrefix}.customSizes`,
                })}
                isSelected
                reference={customTabRef}
                dataTracking={this.sizesTabsMobileTrackingKeyPath}
                dataTestid={`custom-sizes-title-${itemIndex}`}
              />
              <Tabs.Title
                id="defaultSizes"
                ariaControls="defaultSizes-content"
                headlineAlignment="center"
                headlineString={intl.formatMessage({
                  id: `${copyIdPrefix}.presetSizes`,
                })}
                dataTracking={this.sizesTabsMobileTrackingKeyPath}
                dataTestid={`default-sizes-title-${itemIndex}`}
              />
              <Tabs.Panel
                id="customSizes-Content"
                labeledBy="customSizes"
                isSelected
              />
              <Tabs.Panel
                id="defaultSizes-content"
                labeledBy="defaultSizes"
                dataTestid={`default-sizes-content-${itemIndex}`}
              >
                <PresetSizeCardGroup
                  onPresetSelected={onPresetSelected}
                  presetSizes={presetSizesWithLabels}
                  selectedPresetSize={selectedPresetSize}
                  unit={unit}
                  itemIndex={itemIndex}
                  selectedUnitSystem={selectedUnitSystem}
                />
              </Tabs.Panel>
            </Tabs>
          </PresetMobileWrapper>
        )}

        {!isMobile && (
          <PresetDesktopWrapper>
            <StyledPresetsHeadline priority={5} weight="700" aria-hidden="true">
              {intl.formatMessage({
                id: `${copyIdPrefix}.title`,
              })}
            </StyledPresetsHeadline>
            <PresetSizeCardGroup
              onPresetSelected={onPresetSelected}
              presetSizes={presetSizesWithLabels}
              selectedPresetSize={selectedPresetSize}
              unit={unit}
              itemIndex={itemIndex}
              selectedUnitSystem={selectedUnitSystem}
            />
            {isBusiness && (
              <AdvancedSettings
                onCheckboxChange={onCheckboxChange}
                settingsNonStackable={settingsNonStackable}
                trackEvent={trackEvent}
                itemIndex={itemIndex}
                ariaDescribedBy={advancedSettingsAriaDescribedBy}
                isCheckboxDisabledBecauseOfWeight={
                  isCheckboxDisabledBecauseOfWeight
                }
              />
            )}
          </PresetDesktopWrapper>
        )}
      </StyledFieldset>
    );
  }
}

PresetSizeSelector.propTypes = {
  /** Translations library  */
  intl: IntlPropType.isRequired,
  onPresetSelected: PropTypes.func.isRequired,
  presetSize: PropTypes.string,
  unit: PropTypes.string.isRequired,
  /** A wrapper component uses ResponsiveRender. T
   * his sends a boolean to this component to tell it which of the presets should render */
  isMobile: PropTypes.bool.isRequired,
  trackEvent: PropTypes.func,
  /** Class attribute to allow the component styles to be extended by another component */
  className: PropTypes.string,
  /** If set to true, AdvancedSettings will be shown. */
  isBusiness: PropTypes.bool,
  presetSizes: PropTypes.shape({
    weightBreakThreshold: PropTypes.object,
    private: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        label: PropTypes.string,
        icon: PropTypes.string,
        values: PropTypes.shape({
          length: PropTypes.string,
          width: PropTypes.string,
          height: PropTypes.string,
          presetSize: PropTypes.string,
        }),
      })
    ),
    business: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
        label: PropTypes.string,
        icon: PropTypes.string,
        values: PropTypes.shape({
          length: PropTypes.string,
          width: PropTypes.string,
          height: PropTypes.string,
          presetSize: PropTypes.string,
        }),
      })
    ),
  }).isRequired,
  selectedUnitSystem: PropTypes.string.isRequired,
  onMobileAccordionSelect: PropTypes.func,
  onCheckboxChange: PropTypes.func,
  settingsNonStackable: PropTypes.bool,
  triggerShowCustomTab: PropTypes.bool,
  customTabRef: PropTypes.object,
  itemIndex: PropTypes.number,
  advancedSettingsAriaDescribedBy: PropTypes.string,
  /* Defines the displayed list of presets */
  segmentForPresets: PropTypes.oneOf(Object.values(SEGMENT_TYPES)),
  /* Boolean to disable advanced settings checkboxes when min weight is not met */
  isCheckboxDisabledBecauseOfWeight: PropTypes.bool,
};

PresetSizeSelector.defaultProps = {
  presetSize: "",
  className: "",
  isBusiness: false,
  trackEvent: () => { },
  onMobileAccordionSelect: () => { },
  onCheckboxChange: () => { },
  settingsNonStackable: false,
  triggerShowCustomTab: false,
  customTabRef: React.createRef(),
  itemIndex: 0,
  advancedSettingsAriaDescribedBy: "",
  segmentForPresets: SEGMENT_TYPES.private,
  isCheckboxDisabledBecauseOfWeight: false,
};

export default injectIntl(PresetSizeSelector);
