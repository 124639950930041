// 9fbef606107a605d69c0edbcd8029e5d

import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import {
  IntlPropType,
  AutoComplete,
  FormField,
  RemoteData,
} from "root/libs/core-libs/src";
import identity from "lodash/fp/identity";
import compose from "lodash/fp/compose";
import always from "lodash/fp/always";
import {
  FormField as FormFieldComponent,
  AutocompleteField,
  countryAutocompleteFieldMessages,
} from "root/libs/ui-components/src";
import globalConfiguration from "one-time-shipment/src/config/globalConfiguration";
import { AbTesting } from "root/libs/ui-containers/src";
import SkeletonOr from "one-time-shipment/src/containers/SkeletonOr";
import { SS } from "root/libs/constants/src";
import { Text, TEXT_SIZES, TEXT_WEIGHTS } from "@dhl-official/react-ui-library";
import BillingCountryCard from "../BillingCountryCard";
import {
  LocationSelectorWrapper,
  OthersWrapper,
  CountryAutocompleteWrapper,
  StyledFeedbackMessage,
  StyledCrimeaMessage,
} from "./styled";

const SKELETON_HEIGHT = 56;

const FIELDS = {
  COUNTRY: "Country",
  REGION: "Region",
  POSTAL_CODE: "Postal Code",
};

const {
  constants: { TYPES_OF_ABTESTVERSIONS },
} = AbTesting;

const COPY_ID_PREFIX_DIM_SELECT = "LocationSelector";

const getRemoteAutoCompleteOptions = (autoComplete) =>
  AutoComplete.case(
    {
      unselected: (_, options) => RemoteData.withDefault([], options),
      _: () => [],
    },
    autoComplete
  );

const getLocalAutoCompleteOptions = (autoComplete) =>
  AutoComplete.case(
    {
      unselected: (_, options) => options,
      _: () => [],
    },
    autoComplete
  );

const getValidationFeedback = (formField) =>
  FormField.case(
    {
      invalid: (_, feedback) => feedback,
      _: () => ({}),
    },
    formField
  );

const getCountryValue = (intl, countryField) =>
  AutoComplete.case(
    {
      unselected: identity,
      selected: (value) =>
        intl.formatMessage(countryAutocompleteFieldMessages[value]),
    },
    FormField.getValue(countryField)
  );

const getAutoCompleteValue = compose(AutoComplete.getValue, FormField.getValue);

// LocationSelector Component
const LocationSelector = ({
  abTestRunning,
  getKeyInDigitalLayer,
  countryName,
  regionName,
  zipName,
  postalCodeField,
  onAutocompleteSelect,
  countryField,
  countryPattern,
  isCountryRequired,
  isPostalCodeRequired,
  isRegionRequired,
  postalCodePattern,
  regionPattern,
  autocompleteSectionName,
  isPostalCodeValid,
  isOriginDestinationFlipped,
  onBlur,
  onChange,
  zipLabel,
  zipLabelErrorMessage,
  zipDataTestid,
  regionDataTestid,
  regionLabel,
  cityField,
  isBusiness,
  regionLabelErrorMessage,
  isCountryDisabled,
  intl,
  isCityValid,
  blacklistedDestinationCountries,
  warRestrictedZones,
  countryDataTestid,
  countryLabel,
  displayCountrySuggestionOnFocus,
  onFocus,
  whitelistedCountries,
  wrongCountryLinkPath,
  trackEvent,
  countryEmptyOptionLabel,
  toggleOriginAndDestination,
  swapCityPostalCode,
}) => {
  /**
   * Because of reasosns we need to display a warning message when
   * Russia is the destination country and a postal code from the
   * Crimea area is entered. This function is loading the postal
   * code list and checks if the entered postal code is part of it.
   *
   * More information: See JIRA Story DPCJG-491
   *
   * */

  const [shouldRenderCrimeaMessage, setShouldRenderCrimeaMessage] = useState(
    false
  );
  const [shouldRenderCityMessage, setShouldRenderCityMessage] = useState(false);
  const [shouldRenderUkraineMessage, setShouldRenderUkraineMessage] = useState(
    false
  );

  useEffect(() => {
    const { tradeLane } = globalConfiguration;
    const countryCode = getAutoCompleteValue(countryField);
    const isDestination =
      (isOriginDestinationFlipped && isCountryDisabled) ||
      (!isOriginDestinationFlipped && !isCountryDisabled);

    if (countryCode === "RU" && isDestination) {
      import("./crimeaZipCityList").then((module) => {
        const postalCodeFieldValue = getAutoCompleteValue(postalCodeField);
        const cityFieldValue = getAutoCompleteValue(cityField)
          .toLowerCase()
          .trim();

        setShouldRenderCrimeaMessage(
          module.zip.includes(postalCodeFieldValue) ||
            module.city.includes(cityFieldValue)
        );
      });
    } else {
      setShouldRenderCrimeaMessage(false);
    }
    if (tradeLane.partialWarRestrictedZones.includes(countryCode)) {
      setShouldRenderUkraineMessage(true);
    } else {
      setShouldRenderUkraineMessage(false);
    }
  }, [
    countryField,
    cityField,
    postalCodeField,
    isOriginDestinationFlipped,
    isCountryDisabled,
  ]);

  /**
   * Show hint message to fill out city field when its empty.
   * Shows when blurs city field or click button for next step.
   *
   * More information: DPCJG-938
   */
  useEffect(() => {
    if (isBusiness) {
      const cityFieldValue = getAutoCompleteValue(cityField)
        .toLowerCase()
        .trim();

      if (
        cityFieldValue === "" &&
        cityField.type === "Valid" &&
        typeof postalCodeField !== "undefined"
      ) {
        setShouldRenderCityMessage(true);
      } else if (
        cityFieldValue !== "" &&
        cityField.type === "Valid" &&
        typeof postalCodeField !== "undefined"
      ) {
        setShouldRenderCityMessage(false);
      }
    }
  }, [cityField]);

  const keyTrackingPaths = (key) => {
    switch (key) {
      case "country":
        return getKeyInDigitalLayer(`spotShipment.initialSet.${countryName}`);
      case "region":
        return getKeyInDigitalLayer(`spotShipment.initialSet.${regionName}`);
      case "zip":
        return getKeyInDigitalLayer(`spotShipment.initialSet.${zipName}`);

      default:
        return undefined;
    }
  };

  const splitZipCity = (autocompleteValue) => {
    let zipCityArray = [autocompleteValue];

    if (postalCodeField && autocompleteValue.indexOf(",") !== -1) {
      const values = autocompleteValue ? autocompleteValue.split(",") : [];

      if (values.length === 2) {
        const [cityValue, postalCodeValue] = values;
        zipCityArray = [cityValue, postalCodeValue];
      }

      if (values.length === 3) {
        zipCityArray = [`${values[0]},${values[1]}`, values[2]];
      }
    }

    return zipCityArray;
  };

  const onAutocompleteSelectLocal = (autocompleteValue) => {
    const [cityValue, postalCodeValue] = splitZipCity(autocompleteValue);

    if (postalCodeField) {
      const postalCode =
        postalCodeValue !== undefined ? postalCodeValue.trim() : "";

      onAutocompleteSelect({
        name: zipName,
        value: postalCode,
      });
    }

    onAutocompleteSelect({
      name: regionName,
      value: cityValue,
    });
  };

  const onCountryAutocompleteSelect = (selectedItem) => {
    onAutocompleteSelect({
      name: countryName,
      value: selectedItem.value,
    });
  };

  const getValidationRules = (fieldToGet) => {
    switch (fieldToGet) {
      case FIELDS.POSTAL_CODE:
        return {
          customPattern: postalCodePattern,
          hasZipCode: true,
          required: isPostalCodeRequired,
        };

      case FIELDS.REGION:
        return {
          customPattern: regionPattern,
          hasZipCode: true,
          required: isRegionRequired,
        };

      case FIELDS.COUNTRY:
      default:
        return {
          customPattern: countryPattern,
          hasZipCode: true,
          required: isCountryRequired,
        };
    }
  };

  const renderPostalCodeAutoComplete = (postalCodeFieldParam) => {
    const zipRules = getValidationRules(FIELDS.POSTAL_CODE);

    return (
      <SkeletonOr height={SKELETON_HEIGHT}>
        <AutocompleteField
          autoComplete="off"
          autocompleteItems={getRemoteAutoCompleteOptions(
            FormField.getValue(postalCodeFieldParam)
          )}
          copyNamespace={SS}
          data-tracking={keyTrackingPaths("zip")}
          dataTestid={zipDataTestid}
          feedback={getValidationFeedback(postalCodeFieldParam)}
          feedbackMessageIdPrefix="zip"
          forceShowSuccessState={
            FormField.isValid(postalCodeFieldParam) &&
            RemoteData.withDefault(false, isPostalCodeValid)
          }
          interpolations={{
            fieldName: zipLabelErrorMessage,
          }}
          label={zipLabel}
          name={zipName}
          onBlur={onBlur}
          onChange={onChange}
          selectItem={onAutocompleteSelectLocal}
          shouldItemRender={always(true)}
          type="text"
          value={getAutoCompleteValue(postalCodeFieldParam)}
          placeholder={
            postalCodeField &&
            postalCodeField.placeholder &&
            intl.formatMessage(
              { id: "tradeLaneComponent.zipPlaceholder" },
              { placeholder: postalCodeField.placeholder }
            )
          }
          {...zipRules}
        />
      </SkeletonOr>
    );
  };

  const renderAbTestingComponent = (type, cityZipcodeAutomationSelector) => (
    <AbTesting.Component
      type={type}
      componentToRender={() => (
        <OthersWrapper id={cityZipcodeAutomationSelector} hasZipField>
          <SkeletonOr height={SKELETON_HEIGHT}>
            <FormFieldComponent
              copyNamespace={SS}
              data-tracking={keyTrackingPaths("country")}
              disabled
              disabledLayout="opacity"
              forceShowSuccessState={false}
              label={regionLabel}
              name={regionName}
              dataTestid={regionDataTestid}
              type="text"
              value=""
              className="autocomplete-mocks"
            />
          </SkeletonOr>
          <SkeletonOr height={SKELETON_HEIGHT}>
            <FormFieldComponent
              copyNamespace={SS}
              data-tracking={keyTrackingPaths("zip")}
              disabled
              disabledLayout="opacity"
              forceShowSuccessState={false}
              label={zipLabel}
              name={zipName}
              dataTestid={zipDataTestid}
              type="text"
              value=""
              className="autocomplete-mocks"
              placeholder={
                postalCodeField &&
                postalCodeField.placeholder &&
                intl.formatMessage(
                  { id: "tradeLaneComponent.zipPlaceholder" },
                  { placeholder: postalCodeField.placeholder }
                )
              }
            />
          </SkeletonOr>
        </OthersWrapper>
      )}
    />
  );

  const renderDisabledAutoCompletes = (cityZipcodeAutomationSelector) => {
    return (
      <Fragment>
        {abTestRunning ? (
          <Fragment>
            {renderAbTestingComponent(
              TYPES_OF_ABTESTVERSIONS.control,
              cityZipcodeAutomationSelector
            )}
            {renderAbTestingComponent(
              TYPES_OF_ABTESTVERSIONS.tara,
              cityZipcodeAutomationSelector
            )}
          </Fragment>
        ) : (
          <OthersWrapper id={cityZipcodeAutomationSelector} hasZipField>
            <SkeletonOr height={SKELETON_HEIGHT}>
              <FormFieldComponent
                copyNamespace={SS}
                data-tracking={keyTrackingPaths("country")}
                disabled
                disabledLayout="opacity"
                forceShowSuccessState={false}
                label={regionLabel}
                name={regionName}
                dataTestid={regionDataTestid}
                type="text"
                value=""
                className="autocomplete-mocks"
              />
            </SkeletonOr>
            <SkeletonOr height={SKELETON_HEIGHT}>
              <FormFieldComponent
                copyNamespace={SS}
                data-tracking={keyTrackingPaths("zip")}
                disabled
                disabledLayout="opacity"
                forceShowSuccessState={false}
                label={zipLabel}
                name={zipName}
                dataTestid={zipDataTestid}
                type="text"
                value=""
                className="autocomplete-mocks"
                placeholder={
                  postalCodeField &&
                  postalCodeField.placeholder &&
                  intl.formatMessage(
                    { id: "tradeLaneComponent.zipPlaceholder" },
                    { placeholder: postalCodeField.placeholder }
                  )
                }
              />
            </SkeletonOr>
          </OthersWrapper>
        )}
      </Fragment>
    );
  };

  const renderFeedbackIfAvailable = (
    isLocationValid,
    errorMessageID,
    isCityLocationValid,
    hasCityValidationSucceeded
  ) => {
    const fieldLabel = postalCodeField
      ? zipLabelErrorMessage
      : regionLabelErrorMessage;
    const feedbackName = (postalCodeField ? zipName : regionName).toLowerCase();

    const getCopy = (key) =>
      intl.formatMessage({ id: `${COPY_ID_PREFIX_DIM_SELECT}.${key}` });

    const feedback = RemoteData.case(
      {
        success: (isValid) => {
          let locationValidationFeedback;

          if (isValid) {
            if (
              postalCodeField &&
              hasCityValidationSucceeded &&
              cityField.value.value !== "" &&
              !isCityLocationValid
            ) {
              locationValidationFeedback = {
                messageId: "businessCityInvalid",
                variation: "warning",
                interpolation: { fieldLabel },
              };
            }
          } else {
            locationValidationFeedback = {
              messageId: isBusiness
                ? "businessLocationInvalid"
                : "locationInvalid",
              variation: isBusiness ? "error" : "warning",
              interpolation: { fieldLabel },
            };
          }

          return locationValidationFeedback;
        },
      },
      isLocationValid
    );

    return (
      <React.Fragment>
        {feedback && (
          <SkeletonOr>
            <StyledFeedbackMessage
              copyNamespace={SS}
              dataTestid={zipDataTestid}
              feedback={{
                feedbackMessageId: feedback.messageId,
                hasError: true,
                name: feedbackName,
              }}
              id={errorMessageID}
              interpolations={feedback.interpolation}
              isBusiness={isBusiness}
              variation={feedback.variation}
              role="alert"
            />
          </SkeletonOr>
        )}

        {shouldRenderCityMessage && !feedback && (
          <SkeletonOr>
            <StyledFeedbackMessage
              copyNamespace={SS}
              dataTestid="fff"
              feedback={{
                feedbackMessageId: "businessCityEmpty",
                hasError: true,
                name: "name",
              }}
              id="id"
              isBusiness={isBusiness}
              variation="hint"
            />
          </SkeletonOr>
        )}

        {shouldRenderCrimeaMessage && (
          <SkeletonOr>
            <StyledCrimeaMessage
              size={TEXT_SIZES.TINY}
              dataTestid="warning-crimea-message"
              isParagraph
            >
              <Text weight={TEXT_WEIGHTS[700]} size={TEXT_SIZES.TINY}>
                {getCopy("crimeaWarningHeadline")}
              </Text>
              {getCopy("crimeaWarningText")}
            </StyledCrimeaMessage>
          </SkeletonOr>
        )}
      </React.Fragment>
    );
  };

  const countryFeedback = getValidationFeedback(countryField);
  const countryValue = getCountryValue(intl, countryField);
  const countryCode = getAutoCompleteValue(countryField);
  const cityValue = getAutoCompleteValue(cityField);

  const regionRules = getValidationRules(FIELDS.REGION);
  const countryRules = getValidationRules(FIELDS.COUNTRY);
  const errorMessageID = `location-${autocompleteSectionName}`;

  const isCityLocationValid =
    FormField.isValid(cityField) && RemoteData.withDefault(false, isCityValid);
  const hasCityValidationSucceeded = RemoteData.case(
    {
      success: () => true,
      _: () => false,
    },
    isCityValid
  );
  const cityZipcodeAutomationSelector =
    regionDataTestid === "destination-city"
      ? "city-zipcode-destination"
      : "city-zipcode-origin";

  // If there is no `postalCode` we force its location to valid.
  const isPostalCodeLocationValid = postalCodeField
    ? FormField.isValid(postalCodeField) &&
      RemoteData.withDefault(false, isPostalCodeValid)
    : true;

  const errorValidationValue = countryValue ? "invalid-code" : "";

  const ImportExportAutocompleteWrapper = () => (
    <CountryAutocompleteWrapper>
      <SkeletonOr height={SKELETON_HEIGHT}>
        <BillingCountryCard
          countryId={
            AutoComplete.isSelected(FormField.getValue(countryField))
              ? countryCode
              : ""
          }
          intl={intl}
          dataTracking={wrongCountryLinkPath}
          toggleOriginAndDestination={toggleOriginAndDestination}
          trackEvent={trackEvent}
          abTestRunning={abTestRunning}
          dataTestid={countryDataTestid}
          isBusiness={isBusiness}
        />
      </SkeletonOr>
    </CountryAutocompleteWrapper>
  );

  const cityInputField = () => (
    <SkeletonOr height={SKELETON_HEIGHT}>
      <AutocompleteField
        autoComplete="off"
        autocompleteItems={getRemoteAutoCompleteOptions(
          FormField.getValue(cityField)
        )}
        copyNamespace={SS}
        data-tracking={keyTrackingPaths("region")}
        dataTestid={regionDataTestid}
        feedback={getValidationFeedback(cityField)}
        forceShowSuccessState={isCityLocationValid && isPostalCodeLocationValid}
        interpolations={{
          fieldName: regionLabelErrorMessage,
        }}
        label={regionLabel}
        name={regionName}
        onBlur={onBlur}
        onChange={onChange}
        selectItem={onAutocompleteSelectLocal}
        type="text"
        value={cityValue}
        shouldItemRender={always(true)}
        {...regionRules}
      />
    </SkeletonOr>
  );

  return (
    <LocationSelectorWrapper>
      {/* eslint-disable-next-line no-nested-ternary */}
      {isCountryDisabled ? (
        <ImportExportAutocompleteWrapper />
      ) : (
        <CountryAutocompleteWrapper>
          <SkeletonOr height={SKELETON_HEIGHT}>
            <AutocompleteField
              feedbackInfoMessage={{
                isShowInfoMessage: shouldRenderUkraineMessage,
                message: (
                  <Text size={TEXT_SIZES.SMALL}>
                    {intl.formatMessage(
                      {
                        id: "SS.validations.country_UA_restrictionMessage",
                      },
                      {
                        br: <br />,
                      }
                    )}
                  </Text>
                ),
              }}
              forceFeedbackMessage={shouldRenderUkraineMessage}
              splitLabel
              ariaDescribedBy={
                !countryFeedback.hasError && countryValue
                  ? `warningMessage_${errorMessageID}`
                  : ""
              }
              autoComplete={`${autocompleteSectionName} country-name`}
              autocompleteItems={getLocalAutoCompleteOptions(
                FormField.getValue(countryField)
              )}
              blacklistedValues={blacklistedDestinationCountries}
              warRestrictedValues={warRestrictedZones}
              copyNamespace={SS}
              countryId={
                AutoComplete.isSelected(FormField.getValue(countryField))
                  ? countryCode
                  : ""
              }
              data-tracking={keyTrackingPaths("country")}
              dataTestid={countryDataTestid}
              disabled={isCountryDisabled}
              openOnFocus={displayCountrySuggestionOnFocus}
              feedback={countryFeedback}
              feedbackMessageIdPrefix="country"
              forceShowSuccessState={FormField.isValid(countryField)}
              interpolations={{
                fieldName: countryLabel.toLowerCase(),
              }}
              label={countryLabel}
              renderFlags
              name={countryName}
              onBlur={onBlur}
              onChange={onChange}
              onFocus={displayCountrySuggestionOnFocus ? onFocus : undefined}
              returnSelectedObject
              selectItem={onCountryAutocompleteSelect}
              shouldItemRender={always(true)}
              type="text"
              value={countryValue}
              valueForDataValidation={
                AutoComplete.isSelected(FormField.getValue(countryField))
                  ? countryCode
                  : errorValidationValue
              }
              whitelistedValues={whitelistedCountries}
              placeholder={countryEmptyOptionLabel}
              {...countryRules}
            />
          </SkeletonOr>
        </CountryAutocompleteWrapper>
      )}
      {FormField.isValid(countryField) &&
        (!swapCityPostalCode ? (
          <OthersWrapper
            id={cityZipcodeAutomationSelector}
            hasZipField={Boolean(postalCodeField)}
          >
            {cityInputField()}

            {postalCodeField &&
              renderPostalCodeAutoComplete(postalCodeField, errorMessageID)}

            {renderFeedbackIfAvailable(
              postalCodeField ? isPostalCodeValid : isCityValid,
              errorMessageID,
              isCityLocationValid,
              hasCityValidationSucceeded
            )}
          </OthersWrapper>
        ) : (
          <OthersWrapper
            id={cityZipcodeAutomationSelector}
            hasZipField={Boolean(postalCodeField)}
          >
            {postalCodeField &&
              renderPostalCodeAutoComplete(postalCodeField, errorMessageID)}

            {cityInputField()}

            {renderFeedbackIfAvailable(
              postalCodeField ? isPostalCodeValid : isCityValid,
              errorMessageID,
              isCityLocationValid,
              hasCityValidationSucceeded
            )}
          </OthersWrapper>
        ))}

      {!FormField.isValid(countryField) &&
        renderDisabledAutoCompletes(cityZipcodeAutomationSelector)}
    </LocationSelectorWrapper>
  );
};

LocationSelector.propTypes = {
  autocompleteSectionName: PropTypes.string,
  abTestRunning: PropTypes.bool,
  /** Expect an array of country iso codes to be invalid or disabled */
  blacklistedDestinationCountries: PropTypes.array,
  warRestrictedZones: PropTypes.array,
  cityField: PropTypes.object.isRequired,
  countryDataTestid: PropTypes.string,
  countryField: PropTypes.object.isRequired,
  countryLabel: PropTypes.string.isRequired,
  countryName: PropTypes.string.isRequired,
  countryPattern: PropTypes.string,
  displayCountrySuggestionOnFocus: PropTypes.bool,
  feedbackDataTestid: PropTypes.string,
  getKeyInDigitalLayer: PropTypes.func,
  intl: IntlPropType.isRequired,
  isBusiness: PropTypes.bool.isRequired,
  isCityValid: PropTypes.object.isRequired,
  isCountryDisabled: PropTypes.bool,
  isCountryRequired: PropTypes.bool,
  isOriginDestinationFlipped: PropTypes.bool,
  isPostalCodeRequired: PropTypes.bool,
  isPostalCodeValid: PropTypes.object.isRequired,
  isRegionRequired: PropTypes.bool,
  onAutocompleteSelect: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  postalCodeField: PropTypes.object,
  postalCodePattern: PropTypes.string,
  regionDataTestid: PropTypes.string,
  regionLabel: PropTypes.string.isRequired,
  regionLabelErrorMessage: PropTypes.string.isRequired,
  regionName: PropTypes.string.isRequired,
  regionPattern: PropTypes.string,
  whitelistedCountries: PropTypes.array,
  zipDataTestid: PropTypes.string,
  zipLabel: PropTypes.string.isRequired,
  zipLabelErrorMessage: PropTypes.string.isRequired,
  zipName: PropTypes.string.isRequired,
  wrongCountryLinkPath: PropTypes.string,
  trackEvent: PropTypes.func,
  showChangeCountryBadge: PropTypes.bool,
  countryEmptyOptionLabel: PropTypes.string,
  toggleOriginAndDestination: PropTypes.func.isRequired,
  swapCityPostalCode: PropTypes.bool,
};

LocationSelector.defaultProps = {
  swapCityPostalCode: false,
  abTestRunning: false,
  autocompleteSectionName: "origin",
  blacklistedDestinationCountries: [],
  warRestrictedZones: [],
  countryDataTestid: undefined,
  countryPattern: "",
  displayCountrySuggestionOnFocus: false,
  feedbackDataTestid: undefined,
  getKeyInDigitalLayer: Function.prototype,
  isCountryDisabled: false,
  isCountryRequired: true,
  isOriginDestinationFlipped: false,
  isPostalCodeRequired: true,
  isRegionRequired: false,
  onAutocompleteSelect: Function.prototype,
  onBlur: Function.prototype,
  onChange: Function.prototype,
  onFocus: Function.prototype,
  postalCodeField: undefined,
  postalCodePattern: "",
  regionDataTestid: undefined,
  regionPattern: "",
  whitelistedCountries: [],
  zipDataTestid: undefined,
  trackEvent: () => {},
  showChangeCountryBadge: false,
};

export default injectIntl(LocationSelector);
