// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for WCCOfferCardHeader
 *
 */

import styled from "styled-components";
import { breakpoints } from "root/libs/ui-styleguide/src";
import { Headline, Icon } from "root/libs/ui-components/src";
import chevronDown from "icons/navigation/chevron-down.svg";

// Can replace the colors with the variables in the css.body.js file
const walkColor = "var(--dui-color-cool-green-800)";
const onlineColor = "var(--gaq-color-cool-blue-800)";

export const StyledOfferCardHeader = styled.div`
  cursor: pointer;
  position: relative;
  align-items: center;
  padding: 0 11px;
  margin-bottom: var(--dui-size-space-12x);
  display: flex;
  background-color: ${(props) =>
    props.purchaseMethod === "WALK" ? walkColor : onlineColor};
  box-shadow: 0 3px 8px 0 var(--gaq-shadow-multi-bu);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  min-height: 39px;
`;

export const StyledHeadline = styled(Headline)`
  font-size: 18px !important;

  @media screen and (min-width: ${breakpoints.tablet}) {
    line-height: 39px !important;
  }
`;

export const StyledHeadingWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  z-index: 0;
`;
export const StyledIconWrapper = styled.div`
  position: absolute;
  right: 35px;
  top: 9px;
`;

export const ArrowIcon = styled(Icon).attrs({
  icon: chevronDown,
  useAs: "decorative",
  fillColor: "black",
  width: {
    mobile: "20",
    desktop: "20",
  },
  height: {
    mobile: "20",
    desktop: "20",
  },
})`
  height: calc(1.6 * 1.25rem);
  transform: ${(props) => props.isHeaderOpen ? "rotate(0deg)" : "rotate(180deg)"};
  width: calc(1.6 * 1.25rem);
`;