// 9fbef606107a605d69c0edbcd8029e5d

/*
 *
 * GetQuote constants
 *
 */

export const GET_OFFERS_REQUEST_SENT = "app/GetQuote/GET_OFFERS_REQUEST_SENT";
export const GET_OFFERS_REQUEST_SUCCEEDED =
  "app/GetQuote/GET_OFFERS_REQUEST_SUCCEEDED";
export const GET_OFFERS_REQUEST_FAILED =
  "app/GetQuote/GET_OFFERS_REQUEST_FAILED";

export const UPDATE_SHIPPING_DATE = "app/GetQuote/UPDATE_SHIPPING_DATE";

export const TYPES_OF_CUSTOMERS = {
  business: "BUSINESS",
  consumer: "CONSUMER",
};
export const TYPES_OF_ADDRESSES = {
  BUSINESS: "BUSINESS",
  RESIDENTIAL: "RESIDENTIAL",
};

export const TYPES_OF_OFFERS = {
  SIMPLE: "SIMPLE",
  AGGREGATED: "AGGREGATED",
  AGGREGATED_BY_PURCHASE_METHOD: "AGGREGATED_BY_PURCHASE_METHOD",
  WCC: "WCC",
};

export const GO_GREEN_PLUS = "GO_GREEN_PLUS";

export const RESET_QUOTE_RESULTS = "app/GetQuote/RESET_QUOTE_RESULTS";
export const SORT_OFFERS = "app/GetQuote/SORT_OFFERS";
export const DEFAULT_TYPE_OF_CUSTOMER = TYPES_OF_CUSTOMERS.consumer;
export const LOCAL_SHIPMENT_DETECTED = "app/GetQuote/LOCAL_SHIPMENT_DETECTED";
