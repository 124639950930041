// 9fbef606107a605d69c0edbcd8029e5d

import {
  all,
  call,
  takeEvery,
  debounce,
  fork,
  put,
  select,
  takeLatest,
  delay,
} from "redux-saga/effects";
import isNil from "lodash/isNil";
import {
  logging,
  localStorageBridge,
  RemoteData,
  AutoComplete,
  FormField,
  capitalizer,
  normalizeValueForSearch,
} from "root/libs/core-libs/src";
import { countriesWithSpecialCharacterCities } from "root/libs/constants/src";
import http from "one-time-shipment/src/lib/http";
import {
  Localization,
  CountrySettings,
  CanaryVersions,
} from "root/libs/ui-containers/src";
import uniqBy from "lodash/uniqBy";
import compose from "lodash/fp/compose";
import always from "lodash/fp/always";
import { v4 as uuidv4 } from "uuid";
import * as offersLaneActions from "one-time-shipment/src/containers/OffersLane/actions";
import * as spotShipmentSelectors from "one-time-shipment/src/containers/SpotShipmentPage/selectors";
import { changeSegment as changeSegmentAction } from "one-time-shipment/src/containers/SpotShipmentPage/actions";
import * as actions from "./actions";
import * as constants from "./constants";
import * as selectors from "./selectors";

const {
  constants: { SETUP_LOCALE_DATA_SUCCESS },
} = Localization;

const {
  selectors: { selectDefaultDestination },
  constants: { LOAD_COUNTRY_SETTINGS_SUCCESS },
} = CountrySettings;

const {
  selectors: { selectCanaryVersion },
} = CanaryVersions;

// Auto complete debounce time is faster as we want to show suggestions quicker.
const AUTOCOMPLETE_DEBOUNCE_TIME = 200;

const ACTIONS_UPDATING_FORM = [
  constants.FLIP_ORIGIN_DESTINATION,
  constants.QUERY_CITY,
  constants.QUERY_COUNTRY,
  constants.QUERY_POSTAL_CODE,
  constants.SELECT_CITY,
  constants.SELECT_COUNTRY,
  constants.SELECT_POSTAL_CODE,
  constants.UPDATE_DESTINATION_ADDRESS_TYPE,
];

const getAutoCompleteFormFieldValue = compose(
  AutoComplete.getValue,
  FormField.getValue
);

function* getCanaryVersion() {
  const canaryParam = yield select(selectCanaryVersion);

  return canaryParam;
}

export function* getCountryCode(target) {
  const countryFormField = yield select(
    target === "origin"
      ? selectors.selectOriginCountry
      : selectors.selectDestinationCountry
  );

  return getAutoCompleteFormFieldValue(countryFormField);
}

const sanitizeSuggestionsFromAPI = (responseData) =>
  responseData.filter((item) => item.city !== null);

export function* getSuggestionsFromAPI(params) {
  const canary = yield call(getCanaryVersion);
  let response;
  // Must check if the canary version is not null or undefined
  if (!isNil(canary) && canary !== "") {
    response = yield call(http.getPostalLocationQuery, params, canary);
  } else {
    response = yield call(http.getPostalLocationQuery, params);
  }
  const responseData =
    response && response.data
      ? sanitizeSuggestionsFromAPI(response.data.postalLocationList)
      : [];

  const responseDataFrequentPostalLocationList =
    response && response.data
      ? sanitizeSuggestionsFromAPI(response.data.frequentPostalLocationList)
      : [];

  const uniqByPostalLocationList = uniqBy(
    responseData.map((item, index) => ({
      id: index,
      label: [capitalizer(item.city), item.postalCode]
        .filter(Boolean)
        .join(", "),
    })),
    "label"
  );

  const uniqByFrequentPostalLocationList = uniqBy(
    responseDataFrequentPostalLocationList.map((item, index) => ({
      id: index,
      label: [capitalizer(item.city), item.postalCode]
        .filter(Boolean)
        .join(", "),
    })),
    "label"
  );

  return [...uniqByFrequentPostalLocationList, ...uniqByPostalLocationList];
}

export function* getCitySuggestions({ payload }) {
  const { target, value, correlationId } = payload;
  const countryCode = (yield call(getCountryCode, target)).trim();

  const params = {
    city: normalizeValueForSearch(
      value,
      countriesWithSpecialCharacterCities.includes(countryCode) && true
    ),
    countryCode,
  };

  try {
    const data = yield call(getSuggestionsFromAPI, params);

    yield put(
      actions.queryCitySuccess({
        // calling get suggestions from api function
        target,
        value,
        data,
        correlationId,
      })
    );
  } catch (error) {
    yield put(
      actions.queryCityFailure({
        target,
        value,
        error,
        correlationId,
      })
    );
  }
}

export function* getPostalCodeSuggestions({ payload }) {
  const { target, value, correlationId } = payload;
  const currentPostalCode = value.trim(); // trims value users have enetred
  const currentCountryCode = (yield call(getCountryCode, target)).trim();

  const params = {
    postalCode: currentPostalCode,
    countryCode: currentCountryCode,
  };

  try {
    const data = yield call(getSuggestionsFromAPI, params);

    yield put(
      actions.queryPostalCodeSuccess({
        target,
        value,
        data,
        correlationId,
      })
    );
  } catch (error) {
    yield put(
      actions.queryPostalCodeFailure({
        target,
        value,
        error,
        correlationId,
      })
    );
  }
}

export function* validateCity(correlationId, target, params) {
  const segment = yield select(spotShipmentSelectors.selectSelectedSegment);
  const isBusiness = segment === "business";

  try {
    yield put(
      actions.validateCity({ target, value: RemoteData.loading(correlationId) })
    );

    const response = yield call(http.getPostalLocationQuery, params);
    const responseData =
      response && response.data && response.data.postalLocationList
        ? response.data.postalLocationList
        : [];

    const isValid = responseData.some(
      (data) =>
        data.city.toUpperCase() === params.city.toUpperCase() &&
        data.countryCode.toUpperCase() === params.countryCode.toUpperCase()
    );

    yield put(
      actions.validateCitySuccess({
        target,
        isValid,
        correlationId,
        isBusiness,
      })
    );
  } catch (error) {
    yield put(
      actions.validateCityFailure({
        correlationId,
        error,
        isBusiness,
        target,
      })
    );
  }
}

export function* validatePostalCode(correlationId, target, params) {
  const segment = yield select(spotShipmentSelectors.selectSelectedSegment);
  const isBusiness = segment === "business";

  try {
    yield put(
      actions.validatePostalCode({
        target,
        value: RemoteData.loading(correlationId),
      })
    );

    const response = yield call(http.getPostalLocationQuery, params);
    const responseData =
      response && response.data && response.data.postalLocationList
        ? response.data.postalLocationList
        : [];

    const isValid = responseData.some(
      (data) =>
        data.postalCode.toUpperCase() === params.postalCode.toUpperCase() &&
        data.countryCode.toUpperCase() === params.countryCode.toUpperCase()
    );

    yield put(
      actions.validatePostalCodeSuccess({
        target,
        isValid,
        correlationId,
        isBusiness,
      })
    );
  } catch (error) {
    yield put(
      actions.validatePostalCodeFailure({
        correlationId,
        error,
        isBusiness,
        target,
      })
    );
  }
}

export function* checkLocationValidation(
  { payload: target },
  externalCorrelationId
) {
  const correlationId = externalCorrelationId || uuidv4();
  const fields = yield select(
    target === "origin"
      ? selectors.selectOriginFields
      : selectors.selectDestinationFields
  );
  const countryCode = getAutoCompleteFormFieldValue(fields.country).trim();
  const city = getAutoCompleteFormFieldValue(fields.city).trim();
  const hasStaticError = FormField.case({
    invalid: (_, feedback) =>
      typeof feedback.errorAriaDescribedBy === "undefined",
    _: always(false),
  });
  const postalCode =
    fields.postalCode &&
    getAutoCompleteFormFieldValue(fields.postalCode).trim();

  // If the country field is invalid, we clear all validations.
  if (hasStaticError(fields.country) || countryCode === "") {
    yield put(
      actions.validateLocation({ target, value: RemoteData.notAsked() })
    );
    return;
  }

  // The selected country needs a `postalCode`.
  if (postalCode !== undefined) {
    if (
      hasStaticError(fields.city) ||
      city === "" ||
      hasStaticError(fields.postalCode) ||
      postalCode === ""
    ) {
      yield put(actions.validateCity({ target, value: RemoteData.notAsked() }));
    } else {
      yield call(validateCity, correlationId, target, {
        countryCode,
        city,
        postalCode,
      });
    }

    if (hasStaticError(fields.postalCode) || postalCode === "") {
      yield put(
        actions.validatePostalCode({ target, value: RemoteData.notAsked() })
      );
    } else {
      yield call(validatePostalCode, correlationId, target, {
        countryCode,
        postalCode,
      });
    }

    return;
  }

  yield put(
    actions.validatePostalCode({ target, value: RemoteData.notAsked() })
  );

  if (hasStaticError(fields.city) || city === "") {
    yield put(actions.validateCity({ target, value: RemoteData.notAsked() }));
  } else {
    yield call(validateCity, correlationId, target, { countryCode, city });
  }
}

export function* validateLocation(correlationId, target, params) {
  try {
    yield put(
      actions.validateLocation({
        target,
        value: RemoteData.loading(correlationId),
      })
    );

    const response = yield call(http.getPostalLocationQuery, params);
    const responseData =
      response && response.data && response.data.postalLocationList
        ? response.data.postalLocationList
        : [];

    const isValid = responseData.some(
      (data) =>
        data.city.toUpperCase() === params.city.toUpperCase() &&
        data.countryCode.toUpperCase() === params.countryCode.toUpperCase() &&
        (data.postalCode || "").toUpperCase() ===
          params.postalCode.toUpperCase()
    );

    yield put(
      actions.validateLocationSuccess({ target, isValid, correlationId })
    );
  } catch (error) {
    yield put(
      actions.validateLocationFailure({
        correlationId,
        error,
        target,
      })
    );
  }
}

export function* watchCheckLocationValidation() {
  yield takeEvery(constants.CHECK_LOCATION_VALIDATION, checkLocationValidation);
}

export function* watchQueryCity() {
  yield takeLatest(constants.QUERY_CITY, getCitySuggestions);
}

export function* watchQueryPostalCode() {
  yield takeLatest(constants.QUERY_POSTAL_CODE, getPostalCodeSuggestions);
}

export function* hideOffersLane() {
  yield put(offersLaneActions.hideOffersLane());
}

export function* watchFieldUpdates() {
  yield takeLatest(ACTIONS_UPDATING_FORM, hideOffersLane);
}

export function* updateCity({ payload }) {
  const correlationId = payload.correlationId || uuidv4();
  const { value, target } = payload;

  yield put(actions.queryCity({ value, target, correlationId }));
}

export function* updatePostalCode({ payload }) {
  const correlationId = payload.correlationId || uuidv4();
  const { value, target } = payload;

  yield put(actions.queryPostalCode({ value, target, correlationId }));
}

export function* selectLocation({ payload }) {
  yield put(actions.checkLocationValidation(payload.target));
}

export function* setInitialSetCountry({ payload }) {
  const dataLoadedFromUrl = yield select(
    selectors.selectIsTradeLaneDataLoadedFromUrl
  );

  if (!dataLoadedFromUrl) {
    try {
      let postalLocation = RemoteData.notAsked();
      while (
        RemoteData.isNotAsked(postalLocation) ||
        RemoteData.isLoading(postalLocation)
      ) {
        yield delay(100);
        postalLocation = yield select(selectors.selectPostalLocationCountries);
      }
      if (RemoteData.isSuccess(postalLocation)) {
        yield all([
          put(actions.setInitialCountry(payload.country)),
          put(
            actions.queryPostalCodePlaceholder({
              target: "origin",
              element: { value: payload.country },
            })
          ),
        ]);
      }
    } catch (error) {
      logging.warn(error);
    }
  }
}

export function* setDefaultDestinationCountry() {
  const dataLoadedFromUrl = yield select(
    selectors.selectIsTradeLaneDataLoadedFromUrl
  );

  if (!dataLoadedFromUrl) {
    const defaultDestination = yield select(selectDefaultDestination);

    if (defaultDestination) {
      yield put(
        actions.selectCountry({
          target: "destination",
          value: defaultDestination,
        })
      );
    }
  }
}

export function* watchSetupInitialSetCountry() {
  yield takeLatest(SETUP_LOCALE_DATA_SUCCESS, setInitialSetCountry);
}

export function* watchSetupDefaultCountry() {
  yield takeLatest(LOAD_COUNTRY_SETTINGS_SUCCESS, setDefaultDestinationCountry);
}

export function* watchUpdateCity() {
  yield debounce(AUTOCOMPLETE_DEBOUNCE_TIME, constants.UPDATE_CITY, updateCity);
}

export function* watchUpdatePostalCode() {
  yield debounce(
    AUTOCOMPLETE_DEBOUNCE_TIME,
    constants.UPDATE_POSTAL_CODE,
    updatePostalCode
  );
}

export function* getPostalCodePlaceholder({ payload }) {
  const {
    target,
    element: { value },
  } = payload;
  if (value) {
    try {
      let postalLocation = RemoteData.notAsked();
      while (
        RemoteData.isNotAsked(postalLocation) ||
        RemoteData.isLoading(postalLocation)
      ) {
        yield delay(100);
        postalLocation = yield select(selectors.selectPostalLocationCountries);
      }
      if (RemoteData.isSuccess(postalLocation)) {
        yield put(
          actions.queryPostalCodePlaceholderSucceeded({
            data: postalLocation?.data?.countries,
            target,
          })
        );
      } else {
        yield put(
          actions.queryPostalCodePlaceholderSucceeded({
            data: [],
            target,
          })
        );
      }
    } catch (error) {
      yield put(
        actions.queryPostalCodePlaceholderSucceeded({
          data: [],
          target,
        })
      );
    }
  }
}

export function* toggleDomesticShipmentException({ payload }) {
  try {
    const { segment } = payload;
    if (segment) {
      yield put(changeSegmentAction({ segment }));
    }
  } catch (error) {
    /* eslint-disable no-console */

    console.log(error);
  }
}

export function* watchToggleDomesticShipmentException() {
  yield takeLatest(
    constants.TOGGLE_DOMESTIC_SHIPMENT_EXCEPTION,
    toggleDomesticShipmentException
  );
}

export function* watchSelectLocation() {
  yield takeLatest(
    [
      constants.SELECT_CITY,
      constants.SELECT_COUNTRY,
      constants.SELECT_POSTAL_CODE,
    ],
    selectLocation
  );
}

export function* watchQueryPostalCodePlaceholder() {
  yield takeLatest(
    [constants.QUERY_POSTAL_CODE_PLACEHOLDER, constants.VALIDATE_COUNTRY_FIELD],
    getPostalCodePlaceholder
  );
}

export function* loadPostalLocationCountries(options) {
  const { getPostalLocationCountries, cacheNameSpace } = options;

  try {
    const localStorageKey = cacheNameSpace;
    const localPostalLocationCountries = yield call(
      localStorageBridge.load,
      localStorage,
      localStorageKey
    );

    if (localPostalLocationCountries) {
      yield all([
        put(
          actions.setPostalLocationCountriesFromStorage({
            countries: JSON.parse(localPostalLocationCountries),
          })
        ),
        put(
          actions.setCountriesWithoutPostalCode({
            countries: JSON.parse(localPostalLocationCountries),
          })
        ),
      ]);
    }

    const countries = yield call(getPostalLocationCountries);

    yield all([
      put(actions.loadPostalLocationCountriesSuccess({ countries })),
      put(actions.setCountriesWithoutPostalCode({ countries })),
    ]);

    yield call(
      localStorageBridge.save,
      localStorage,
      localStorageKey,
      countries
    );
  } catch (error) {
    yield call(logging.warn, error);
    yield put(
      actions.loadPostalLocationCountriesFailure({ message: error.message })
    );
  }
}

export function* watchLoadPostalLocationCountries(options) {
  yield takeLatest(
    constants.LOAD_POSTAL_LOCATION_COUNTRIES,
    loadPostalLocationCountries.bind(null, options)
  );
}

export function* sagas(options) {
  yield all([
    fork(watchCheckLocationValidation),
    fork(watchFieldUpdates),
    fork(watchQueryCity),
    fork(watchQueryPostalCode),
    fork(watchSelectLocation),
    fork(watchUpdateCity),
    fork(watchUpdatePostalCode),
    fork(watchSetupInitialSetCountry),
    fork(watchQueryPostalCodePlaceholder),
    fork(watchSetupDefaultCountry),
    fork(watchToggleDomesticShipmentException),
    fork(watchLoadPostalLocationCountries.bind(null, options)),
  ]);
}

export default function createSagas(
  getPostalLocationCountries,
  cacheNameSpace
) {
  return sagas.bind(null, { getPostalLocationCountries, cacheNameSpace });
}
