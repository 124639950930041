// 9fbef606107a605d69c0edbcd8029e5d

import { getMemoryInfo } from "./perfHelper";
import { getUiMetrics } from "./uiMetricsHelper";

/**
 * Please refer following links for more understand
 * https://developers.google.com/web/fundamentals/performance/navigation-and-resource-timing
 * https://developer.mozilla.org/en-US/docs/Web/API/Navigation_timing_API
 * https://alligator.io/js/js-performance-api/
 */
export default {
  /**
   * The Navigation Timing API provides data that can be used to measure the performance of a web site.
   * Unlike JavaScript-based libraries that have historically been used to collect similar information,
   * the Navigation Timing API can be much more accurate and reliable.
   */
  navigation(entry) {
    return {
      uiMetrics: getUiMetrics(entry),
      // get memory at this event
      ...getMemoryInfo(),
    };
  },

  /**
   * The PerformancePaintTiming interface of the Paint Timing provides timing information
   * about "paint" (also called "render") operations during web page construction.
   * We can get "first-paint" and "first-contentful-paint" durations
   */
  paint({ name, startTime, duration }) {
    const keys = {
      "first-paint": "firstPaint",
      "first-contentful-paint": "firstContentFullPaint",
    };
    return {
      uiMetrics: {
        [keys[name]]: Math.round(startTime + duration),
      },
    };
  },
  /**
   * Measure load time of resources
   * Possible resources are
   * - img (for media files)
   * - video (its obvious)
   * - link (for css files)
   * - script (for script files)
   * - iframe (its obvious)
   * - xmlhttprequest (for ajax calls)
   * - other (for others)
   */
  resource(entry) {
    const { name, initiatorType, duration } = entry;
    let key = "";
    switch (initiatorType) {
      case "img":
      case "video":
        key = "mediaResource";
        break;
      case "xmlhttprequest":
        key = "apiResource";
        break;
      default:
        key = "otherResource";
    }
    return {
      [key]: { url: name, duration: Math.round(duration) },
      // at every resource request, capture the memory usage as well
      ...getMemoryInfo(),
    };
  },
  longtask({ startTime, duration }) {
    return {
      uiMetrics: {
        longtask: Math.round(startTime + duration),
      },
      // get memory at this event
      ...getMemoryInfo(),
    };
  },
};
