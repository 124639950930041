// 9fbef606107a605d69c0edbcd8029e5d

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { RemoteData } from "root/libs/core-libs/src";
import { selectPostalLocationCountries } from "one-time-shipment/src/containers/TradeLane/selectors";

const Component = ({ children, postalLocationCountries, errorPage }) =>
  RemoteData.case(
    {
      error: () => errorPage,
      _: () => children,
    },
    postalLocationCountries
  );

Component.propTypes = {
  children: PropTypes.element.isRequired,
  postalLocationCountries: PropTypes.object.isRequired,
  errorPage: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => ({
  postalLocationCountries: selectPostalLocationCountries(state),
});

export default connect(mapStateToProps)(Component);
