// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * OfferProperties
 *
 */

import React from "react";
import { IntlPropType } from "root/libs/core-libs/src";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import {
  Link,
  List,
  LIST_VARIANTS,
  TEXT_SIZES,
} from "@dhl-official/react-ui-library";
import {
  PropertiesWrapper,
  PropertiesContent,
  PropertiesHeadline,
  PropertiesText,
} from "./styled";

const COPY_ID_PREFIX = "OfferProperites";
const PROP_MSG_COPY_ID_PREFIX = "PropertiesMessages";
const GO_GREEN_PLUS = "GO_GREEN_PLUS";
const GO_GREEN_PROP = "GO_GREEN_PLUS";

const OfferProperties = ({
  intl,
  estimatedDeliveryTime,
  is12HourTimeFormat,
  properties,
  offerId,
  customHeadline,
  variationType,
}) => {
  const nullChecker = (id) => {
    return !!intl.messages[`${PROP_MSG_COPY_ID_PREFIX}.${id}`];
  };
  const getCopy = (id, values, prefix = PROP_MSG_COPY_ID_PREFIX) => {
    return intl.formatMessage({ id: `${prefix}.${id}` }, values);
  };

  const messages = {
    goGreenPlusLinkLabel: intl.formatMessage({
      id: "OfferCardHorizontal.goGreenPlusLinkLabel",
    }),
    goGreenPlusLinkUrl: intl.formatMessage({
      id: "OfferCardHorizontal.goGreenPlusLinkUrl",
    }),
  };

  const GoGreenLink = (
    <Link href={messages.goGreenPlusLinkUrl} target="_blank">
      {messages.goGreenPlusLinkLabel}
    </Link>
  );

  const getPropertyTranslation = (property) => {
    const propertyMessageId = `${property.key}_description`;
    const isGoGreenPlus =
      offerId === GO_GREEN_PLUS && property.key === GO_GREEN_PROP;
    const isPropertyMessageIdAvailable =
      propertyMessageId && nullChecker(propertyMessageId);

    let description = "";
    let parsedDeliveryTime = "";

    if (
      estimatedDeliveryTime instanceof Date &&
      Number.isFinite(estimatedDeliveryTime.getTime())
    ) {
      parsedDeliveryTime = intl.formatTime(estimatedDeliveryTime, {
        hour12: is12HourTimeFormat,
      });
    }

    if (isPropertyMessageIdAvailable && !isGoGreenPlus) {
      description = getCopy(propertyMessageId, {
        estimatedDeliveryTime: parsedDeliveryTime,
      });
    }

    if (isGoGreenPlus) {
      description = getCopy(propertyMessageId, {
        b: (chunks) => <strong>{chunks}</strong>,
        goGreenPlusLinkLabel: GoGreenLink,
      });
    }

    return {
      description,
    };
  };

  const hasProperties = !!properties?.length;
  const headlineString = customHeadline
    ? intl.formatMessage({
        id: customHeadline,
      })
    : intl.formatMessage({
        id: `${COPY_ID_PREFIX}.headline`,
      });

  return !hasProperties ? null : (
    <PropertiesWrapper variationType={variationType}>
      <PropertiesContent
        data-testid="offer-properties-content"
        tabIndex={0}
        variationType={variationType}
      >
        <PropertiesHeadline priority={5} weight="700">
          {headlineString}
        </PropertiesHeadline>
        {properties.length !== 0 && (
          <List variant={LIST_VARIANTS.BULLET}>
            {properties.map((property) => (
              <List.Item key={property.key}>
                <PropertiesText isParagraph size={TEXT_SIZES.SMALLPLUS}>
                  {getPropertyTranslation(property).description}
                </PropertiesText>
              </List.Item>
            ))}
          </List>
        )}
      </PropertiesContent>
    </PropertiesWrapper>
  );
};

OfferProperties.propTypes = {
  /** Passing the Offer title to the card */
  offerId: PropTypes.string,
  /** These are the properties that are added to an offer */
  properties: PropTypes.array,
  /** If your property description message has {estimatedDeliveryTime}, you need to send the date. Example cases: OFFER_9AM_description, OFFER_1030AM_description */
  estimatedDeliveryTime: PropTypes.instanceOf(Date),
  /** Translations library  */
  intl: IntlPropType.isRequired,
  is12HourTimeFormat: PropTypes.bool.isRequired,
  customHeadline: PropTypes.string,
  variationType: PropTypes.string,
};

OfferProperties.defaultProps = {
  estimatedDeliveryTime: undefined,
};

export default injectIntl(OfferProperties);
