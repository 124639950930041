// 9fbef606107a605d69c0edbcd8029e5d

export const LOAD_COUNTRY_SETTINGS =
  "shared/CountrySettings/LOAD_COUNTRY_SETTINGS";
export const LOAD_COUNTRY_SETTINGS_FAILURE =
  "shared/CountrySettings/LOAD_COUNTRY_SETTINGS_FAILURE";
export const LOAD_COUNTRY_SETTINGS_SUCCESS =
  "shared/CountrySettings/LOAD_COUNTRY_SETTINGS_SUCCESS";
export const SET_COUNTRY_SETTINGS_FROM_STORAGE =
  "shared/CountrySettings/SET_COUNTRY_SETTINGS_FROM_STORAGE";
