// 9fbef606107a605d69c0edbcd8029e5d

import curry from "lodash/curry";

const AUTO_COMPLETE_DATA_TYPE = {
  SELECTED: "Selected",
  UNSELECTED: "Unselected",
};

const NOOP = Function.prototype;

const AutoComplete = {
  unselected: (value = "", options = []) => ({
    value,
    options,
    type: AUTO_COMPLETE_DATA_TYPE.UNSELECTED,
  }),

  selected: (value) => ({
    value,
    type: AUTO_COMPLETE_DATA_TYPE.SELECTED,
  }),

  case: curry((caseObj, autoComplete) => {
    const { unselected, selected, _ = NOOP } = caseObj;

    if (selected && AutoComplete.isSelected(autoComplete)) {
      return selected(autoComplete.value);
    }

    if (unselected && AutoComplete.isUnselected(autoComplete)) {
      return unselected(autoComplete.value, autoComplete.options);
    }

    return _();
  }),

  isUnselected: (autoComplete) =>
    autoComplete.type === AUTO_COMPLETE_DATA_TYPE.UNSELECTED,

  isSelected: (autoComplete) =>
    autoComplete.type === AUTO_COMPLETE_DATA_TYPE.SELECTED,

  getValue: (autoComplete) => autoComplete.value,
};

export default AutoComplete;
