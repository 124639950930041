// 9fbef606107a605d69c0edbcd8029e5d

import styled from "styled-components";
import { breakpoints } from "root/libs/ui-styleguide/src";
import { key } from "styled-theme";

export const headlineStyles = styled.h1`
  color: ${(props) =>
    props.fontColor === "default"
      ? key("colors.headerColor")
      : key("colors.fontColorHeader")};
  ${(props) => (props.center ? "text-align: center" : "")};
  ${(props) => (props.centerInMobile ? "text-align: center" : "")};
  font-weight: ${(props) => props.weight};
  margin-top: 0;
  ${(props) => (props.noMargin ? "margin-bottom: 0;" : "")}
  ${(props) => (props.inlined ? "display: inline-block;" : "")}

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${(props) => (props.centerInDesktop ? "text-align: center;" : "")}
    ${(props) => (props.centerInMobile ? "text-align: initial" : "")};
  }
`;
