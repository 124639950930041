// 9fbef606107a605d69c0edbcd8029e5d

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getOffersRequestSent,
  resetQuoteResults,
} from "one-time-shipment/src/containers/GetQuote/actions";
import {
  updateDocument,
  resetSavedQuote,
} from "one-time-shipment/src/containers/TradeLane/actions";
import {
  showOffersLane,
  hideOffersLane,
} from "one-time-shipment/src/containers/OffersLane/actions";
import { Tracking, CountrySettings, Utf } from "root/libs/ui-containers/src";
import { runClientStorageAction } from "one-time-shipment/src/containers/Personalization/actions";
import * as tradeLaneSelectors from "one-time-shipment/src/containers/TradeLane/selectors";
import { selectCalculateTotalShipmentWeight } from "one-time-shipment/src/containers/ShipmentLane/selectors";
import globalConfiguration from "one-time-shipment/src/config/globalConfiguration";
import { SEGMENT_TYPES } from "one-time-shipment/src/containers/SpotShipmentPage/constants";
import { makeIsFormValidSelector } from "root/libs/core-libs/src";
import ShipmentLaneComponent from "one-time-shipment/src/components/ShipmentItem/ShipmentLaneComponent";
import {
  validateFormFields,
  clearDimensionFieldsValidationErrors,
  resetScrolling,
  updateItem,
  onCheckboxChange,
  addShipmentLaneItem,
  removeShipmentLaneItem,
} from "./actions";

const {
  selectors: { selectPageName },
} = Utf;

const {
  selectors: { selectIsUnitSwitchEnabled },
} = CountrySettings;

const {
  actions: { trackEvent },
} = Tracking;

function ShipmentLane(props) {
  const { isBusiness } = props;

  const selectedSegment = isBusiness
    ? SEGMENT_TYPES.business
    : SEGMENT_TYPES.private;
  const shipmentLaneConfigs = {
    isDangerousGoodsEnabled:
      globalConfiguration.shipmentLane[selectedSegment].settingsDangerousGoods,
    isQuantitySelectorEnabled:
      globalConfiguration.shipmentLane[selectedSegment].quantitySelector,
    isAddItemEnabled: globalConfiguration.shipmentLane[selectedSegment].addItem,
    maxNumberOfShipmentItems:
      globalConfiguration.shipmentLane[selectedSegment]
        .maxNumberOfShipmentItems || 0,
  };
  return <ShipmentLaneComponent configs={shipmentLaneConfigs} {...props} />;
}

ShipmentLane.propTypes = {
  isBusiness: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  waitingForResponse: state.getQuote.waitingForResponse,
  items: state.shipmentLane.initialSet.items,
  isShipmentLaneVisibile: state.shipmentLane.isVisible,
  shouldScroll: state.shipmentLane.scrollToSection,
  validity: state.shipmentLane.validity,
  isFormValid: makeIsFormValidSelector(state, "shipmentLane"),
  documentsOptionValue: tradeLaneSelectors.selectDocumentsOptionValue(state),
  settingsPalletized: state.shipmentLane.initialSet.settingsPalletized,
  settingsNonStackable: state.shipmentLane.initialSet.settingsNonStackable,
  settingsDangerousGoods: state.shipmentLane.initialSet.settingsDangerousGoods,
  totalShipmentWeight: selectCalculateTotalShipmentWeight(state),
  selectedUnitSystem: state.shipmentLane.selectedUnitSystem,
  isUnitSwitchEnabled: selectIsUnitSwitchEnabled(state),
  pageName: selectPageName(state),
  experience: state.personalization.experience,
});

/* istanbul ignore next */
// Note: it is not necessary to test that our mapDispatchToProps is properly
// passing our login function to the connected component, because Redux is
// already responsible for this.
const mapDispatchToProps = (dispatch) => ({
  getOffers: () => {
    dispatch(getOffersRequestSent());
    dispatch(runClientStorageAction(true));
  },
  trackEvent: (trackingData) => dispatch(trackEvent(trackingData)),
  onSubmit: () => {
    dispatch(showOffersLane());
    dispatch(resetSavedQuote());
  },
  validateFormFields: (validationProperties) =>
    dispatch(validateFormFields(validationProperties)),
  updateItem: ({ itemIndex, newItemValues }) => {
    dispatch(updateItem({ itemIndex, newItemValues }));
    dispatch(hideOffersLane());
    dispatch(resetQuoteResults());
  },
  clearDimensionFieldsValidationErrors: (itemIndex) =>
    dispatch(clearDimensionFieldsValidationErrors(itemIndex)),
  resetScrolling: () => dispatch(resetScrolling()),
  updateDocument: (document) => {
    dispatch(updateDocument(document));
    dispatch(hideOffersLane());
  },
  onCheckboxChange: (name, value) => {
    dispatch(onCheckboxChange({ name, value }));
    dispatch(hideOffersLane());
  },
  addShipmentLaneItem: () => {
    dispatch(addShipmentLaneItem());
    dispatch(hideOffersLane());
  },
  removeShipmentLaneItem: (itemIndex) => {
    dispatch(removeShipmentLaneItem(itemIndex));
    dispatch(hideOffersLane());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentLane);
