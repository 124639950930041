// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * SpotShipmentPage
 *
 */

import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { injectIntl } from "react-intl";
import { IntlPropType } from "root/libs/core-libs/src";
import { AbTesting, CountrySettings } from "root/libs/ui-containers/src";
import { selectTrackingKey } from "one-time-shipment/src/containers/Tracking/selectors";
import ShipmentLane from "one-time-shipment/src/containers/ShipmentLane";
import SegmentControl from "one-time-shipment/src/components/Forms/SegmentControl";
import BypassBlock from "one-time-shipment/src/components/Buttons/BypassBlock";
import ModalShipmentInformationDetected from "one-time-shipment/src/components/Modals/ModalShipmentInformationDetected";
import TradeLane from "one-time-shipment/src/containers/TradeLane";
import SkeletonOr from "one-time-shipment/src/containers/SkeletonOr";
import globalConfiguration from "one-time-shipment/src/config/globalConfiguration";
import { SEGMENT_TYPES } from "one-time-shipment/src/containers/SpotShipmentPage/constants";
import {
  COMMON,
  UTF_EVENT_PAGE_NAMES,
  UTF_EVENT_CONTENT_NAMES,
  UTF_EVENT_TYPES,
  UTF_EVENT_INTERACTIONS,
  UTF_EVENT_CONTENT_POSITION,
} from "root/libs/constants/src";
import OffersLane from "one-time-shipment/src/containers/OffersLane";
import { BypassBlockWrapper, StyledHeadline, GlobalPrintStyle } from "./styled";

const { withCountry } = CountrySettings;

const COPY_ID_PREFIX = "SpotShipmentPage";

class SpotShipment extends React.PureComponent {
  static propTypes = {
    intl: IntlPropType.isRequired,
    language: PropTypes.string,
    country: PropTypes.string,
    isByPassBlockVisible: PropTypes.bool,
    onSegmentChange: PropTypes.func,
    selectedSegment: PropTypes.string.isRequired,
    segmentReference: PropTypes.object,
    /** function to add values to the digitalLayer for tracking purposes.
     * Receives an object, the key is the property that will recieve the data in the digitalLayer
     * and the value is the data that it needs */
    trackEvent: PropTypes.func,
    showShipmentDetectedModal: PropTypes.bool,
    closeShipmentDetectedModal: PropTypes.func.isRequired,
    abTestRunning: PropTypes.bool.isRequired,
    canaryEnabled: PropTypes.bool.isRequired,
    canaryVersion: PropTypes.string,
    hideSegments: PropTypes.bool,
    segments: PropTypes.array,
  };

  static defaultProps = {
    language: "en",
    country: "NL",
    isByPassBlockVisible: false,
    onSegmentChange: () => {},
    trackEvent: () => {},
    segmentReference: React.createRef(),
    showShipmentDetectedModal: false,
    hideSegments: false,
    segments: [SEGMENT_TYPES.private, SEGMENT_TYPES.business],
  };

  constructor(props) {
    super(props);

    const { segments } = props;
    this.TradeLaneWithCountry = withCountry(TradeLane);
    this.ShipmentLaneWithCountry = withCountry(ShipmentLane);
    this.OffersLaneWithCountry = withCountry(OffersLane);
    const trackingKeySelector = selectTrackingKey;
    this.selectedSegmentTrackingKeyPath = trackingKeySelector(
      "spotShipment.selectedSegment"
    );

    const {
      constants: { TYPES_OF_ABTESTVERSIONS },
    } = AbTesting;

    this.AbTestingTypes = TYPES_OF_ABTESTVERSIONS;

    this.segments = segments.map((s) => {
      return {
        id: SEGMENT_TYPES[s],
        headlineString: this.getCopy(`${s}Shipment`),
      };
    });

    this.OffersLaneReference = React.createRef();
    this.backToTradeLaneReference = React.createRef();
    this.backToShipmentLaneReference = React.createRef();
  }

  getCopy = (id, values, prefix = COPY_ID_PREFIX) => {
    const { intl } = this.props;
    return intl.formatMessage({ id: `${prefix}.${id}` }, values);
  };

  onModalShipmentInformationDetectedModalClose = () => {
    const { closeShipmentDetectedModal, country } = this.props;
    closeShipmentDetectedModal({ country });
  };

  onRestoreHandlerShipmentDetectedModal = () => {
    // Restore state here
    const { closeShipmentDetectedModal, country } = this.props;
    const localStorageSavedShipment = window.localStorage.getItem(
      `${COMMON.SAVE_SHIPMENT_LOCAL_STORAGE}:${country}`
    );
    closeShipmentDetectedModal({ localStorageSavedShipment, country });
  };

  render() {
    const {
      intl,
      language,
      country,
      isByPassBlockVisible,
      onSegmentChange,
      selectedSegment,
      segmentReference,
      hideSegments,
      trackEvent,
      showShipmentDetectedModal,
      abTestRunning,
      canaryEnabled,
      canaryVersion,
    } = this.props;

    const isBusiness = selectedSegment === SEGMENT_TYPES.business;

    const renderHeadline = (type, headline) => (
      <AbTesting.Component
        type={type}
        componentToRender={() => (
          <div className="mx-wrapper">
            <div className="grid-maxWidthInner no-padding">
              <StyledHeadline
                priority={2}
                headlineDisplayType="h"
                dataTestid="shipping-as-headline"
              >
                <SkeletonOr width={300}>{this.getCopy(headline)}</SkeletonOr>
              </StyledHeadline>
              <SegmentControl
                segments={this.segments}
                onClickHandler={(segment) => onSegmentChange(segment, country)}
                selectedSegment={selectedSegment}
                segmentReference={segmentReference}
                dataTrackingKeyPath={this.selectedSegmentTrackingKeyPath}
              />
            </div>
          </div>
        )}
      />
    );

    const renderTradeLane = (type) => (
      <AbTesting.Component
        type={type}
        componentToRender={() => (
          <this.TradeLaneWithCountry
            abTestingVersion={type}
            isTradeLaneVisible
            selectedSegment={selectedSegment}
            intl={intl}
            language={language}
            backToTradeLaneReference={this.backToTradeLaneReference}
            isBusiness={isBusiness}
            showOriginBillingLink={
              globalConfiguration.tradeLane.showOriginBillingLink
            }
            warRestrictedZones={
              globalConfiguration.tradeLane.warRestrictedZones
            }
            iNeedTheseProps={[
              "blacklistedDestinationCountries",
              "enableImportExportFeature",
              "isDomesticShippingAvailable",
              "showTradeLaneBusinessShipmentAssistant",
            ]}
            countryAutocompleteIncludeCountryCodes
            displayCountrySuggestionOnFocus
          />
        )}
      />
    );

    return (
      <React.Fragment>
        {canaryEnabled && (
          <Helmet>
            <meta name="canaryVersion" content={canaryVersion} />
          </Helmet>
        )}
        {showShipmentDetectedModal && (
          <ModalShipmentInformationDetected
            trackEvent={trackEvent}
            isBusiness={isBusiness}
            intl={intl}
            isOpen={showShipmentDetectedModal}
            onClose={this.onModalShipmentInformationDetectedModalClose}
            onRestoreHandler={this.onRestoreHandlerShipmentDetectedModal}
          />
        )}
        <GlobalPrintStyle />
        {!hideSegments &&
          (abTestRunning ? (
            <React.Fragment>
              {renderHeadline(this.AbTestingTypes.control, "headline")}
              {renderHeadline(this.AbTestingTypes.tara, "headlineTara")}
            </React.Fragment>
          ) : (
            <div className="mx-wrapper">
              <div className="grid-maxWidthInner no-padding">
                <StyledHeadline
                  priority={2}
                  headlineDisplayType="h"
                  dataTestid="shipping-as-headline"
                >
                  <SkeletonOr width={300}>
                    {this.getCopy("headline")}
                  </SkeletonOr>
                </StyledHeadline>
                <SegmentControl
                  segments={this.segments}
                  onClickHandler={(segment) =>
                    onSegmentChange(segment, country)
                  }
                  selectedSegment={selectedSegment}
                  segmentReference={segmentReference}
                  dataTrackingKeyPath={this.selectedSegmentTrackingKeyPath}
                />
              </div>
            </div>
          ))}
        {abTestRunning ? (
          <React.Fragment>
            {renderTradeLane(this.AbTestingTypes.control)}
            {renderTradeLane(this.AbTestingTypes.tara)}
          </React.Fragment>
        ) : (
          <this.TradeLaneWithCountry
            isTradeLaneVisible
            selectedSegment={selectedSegment}
            intl={intl}
            language={language}
            backToTradeLaneReference={this.backToTradeLaneReference}
            isBusiness={isBusiness}
            showOriginBillingLink={
              globalConfiguration.tradeLane.showOriginBillingLink
            }
            warRestrictedZones={
              globalConfiguration.tradeLane.warRestrictedZones
            }
            iNeedTheseProps={[
              "blacklistedDestinationCountries",
              "enableImportExportFeature",
              "isDomesticShippingAvailable",
              "showTradeLaneBusinessShipmentAssistant",
            ]}
            countryAutocompleteIncludeCountryCodes
            displayCountrySuggestionOnFocus
          />
        )}
        <Suspense fallback="">
          <this.ShipmentLaneWithCountry
            abTestRunning={abTestRunning}
            intl={intl}
            offersLaneReference={this.OffersLaneReference}
            backToTradeLaneReference={this.backToTradeLaneReference}
            backToShipmentLaneReference={this.backToShipmentLaneReference}
            segmentReference={segmentReference}
            isBusiness={isBusiness}
            iNeedTheseProps={[
              "spotShipmentPresetSizes",
              "unitSystem",
              "shipmentLaneCheckboxStatusWeightThreshold",
            ]}
          />
        </Suspense>
        <Suspense fallback="">
          <this.OffersLaneWithCountry
            reference={this.OffersLaneReference}
            backToTradeLaneReference={this.backToTradeLaneReference}
            backToShipmentLaneReference={this.backToShipmentLaneReference}
            iNeedTheseProps={[
              "showCurrencyIsoCodeForPrices",
              "aggregatedOfferViewMode",
            ]}
            intl={intl}
            enableTeaserOverlayFeature={
              globalConfiguration.offersLane[selectedSegment]
                .enableTeaserOverlayFeature
            }
            isBusiness={isBusiness}
            language={language}
          />
          {isByPassBlockVisible && (
            <BypassBlockWrapper className="grid-maxWidth">
              <BypassBlock
                dataTestid="back-to-from"
                copyString={this.getCopy("fromTo")}
                reference={this.backToTradeLaneReference}
                showCopyInside
                name="bypass-back-to-from"
                utfDataObject={{
                  name: UTF_EVENT_CONTENT_NAMES.BACK_TO,
                  type: UTF_EVENT_TYPES.BUTTON,
                  interaction: UTF_EVENT_INTERACTIONS.CLICK,
                  position: UTF_EVENT_CONTENT_POSITION.GLOBAL,
                  context: isBusiness
                    ? SEGMENT_TYPES.business
                    : SEGMENT_TYPES.private,
                  attributes: { back_to: UTF_EVENT_PAGE_NAMES.TRADE_LANE },
                }}
              />
              <BypassBlock
                dataTestid="back-to-shipment"
                copyString={this.getCopy("shipmentSpecification")}
                reference={this.backToShipmentLaneReference}
                showCopyInside
                name="bypass-back-to-shipment"
                utfDataObject={{
                  name: UTF_EVENT_CONTENT_NAMES.BACK_TO,
                  type: UTF_EVENT_TYPES.BUTTON,
                  interaction: UTF_EVENT_INTERACTIONS.CLICK,
                  position: UTF_EVENT_CONTENT_POSITION.GLOBAL,
                  context: isBusiness
                    ? SEGMENT_TYPES.business
                    : SEGMENT_TYPES.private,
                  attributes: { back_to: UTF_EVENT_PAGE_NAMES.SHIPMENT_LANE },
                }}
              />
            </BypassBlockWrapper>
          )}
        </Suspense>
      </React.Fragment>
    );
  }
}

export default injectIntl(SpotShipment);
