// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for DimensionSelectorLarge
 *
 *  Big preset Icon in mobile: 82
 *  Big preset icon in desktop: 102
 */

import styled, { css } from "styled-components";
import { breakpoints, colors } from "root/libs/ui-styleguide/src";
import { Text } from "@dhl-official/react-ui-library";
import PresetSizeSelector from "one-time-shipment/src/components/Forms/PresetSizeSelector";

export const ieIconWrapper = css`
  display: inline-block;
  width: 17%;
  padding: 0 15px;
  margin-top: -10px;
  vertical-align: top;

  @supports (display: grid) {
    display: unset;
    padding: 0;
    width: 100%;
    margin-top: 0;
  }
`;

export const IconWrapper = styled.div`
  align-self: center;
  height: 100%;
  ${ieIconWrapper}

  .icon {
    height: auto;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    @supports (display: grid) {
      grid-row: weightRow;
      grid-area: iconCol;
    }
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    &.has-quantity {
      padding-top: 26px;
    }

    @supports (display: grid) {
      grid-row: dimensionsRow;
      grid-area: iconCol;
    }
  }

  @media print {
    display: none;
  }
`;

export const ieCustomDimensionsWrapper = css`
  display: inline-block;
  margin-right: 0;
  vertical-align: top;
  width: 64%;

  @media all and (-ms-high-contrast: none) {
    ${(props) => (props.isBusiness ? "width: 45%" : "width: 64%")};
  }

  /* Only for EDGE */
  /* stylelint-disable selector-type-no-unknown */
  _:-ms-lang(x),
  _:-webkit-full-screen,
  & {
    ${(props) => (props.isBusiness ? "width: 45%" : "width: 64%")};
  }
  /* stylelint-enable selector-type-no-unknown */

  @supports (display: grid) {
    display: unset;
    margin-right: 30px;
    width: 100%;
  }
`;

export const CustomDimensionsWrapper = styled.div`
  ${ieCustomDimensionsWrapper}

  @media screen and (min-width: ${breakpoints.tablet}) {
    @supports (display: grid) {
      grid-row: dimensionsRow;
      grid-column: ${(props) =>
        props.isBusiness ? "2 / span 8" : "2 / span 10"};
    }
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    @supports (display: grid) {
      grid-row: dimensionsRow;
      grid-area: dimensionsCol;
    }
  }

  @media print {
    grid-row: dimensionsRow;
    grid-area: dimensionsCol;
    padding-left: 10px;
  }
`;

export const StyledPresetSizeSelector = styled(PresetSizeSelector)`
  @supports (display: grid) {
    grid-row: presetsRow;
    grid-column: 2 / span 10;

    @media screen and (min-width: ${breakpoints.desktop}) {
      grid-column: ${(props) =>
        props.isBusiness ? "1 / span 12" : "2 / span 10"};
    }
  }

  @media print {
    width: auto;
  }
`;

export const WidthHeightLengthControls = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;

  & > div {
    margin-right: 14px;
    margin-left: 14px;
    margin-bottom: auto;
    width: 100%;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .validation-info div[role="alert"] > div {
    color: ${colors.black};
  }
`;

export const StyledLegend = styled.legend`
  margin-bottom: 12px;
`;

export const StyledXText = styled(Text)`
  align-self: flex-start;
  height: 50px;
  line-height: 50px;
`;

export const ieStyledQuantityColumn = css`
  @media all and (-ms-high-contrast: none) {
    float: right;
    display: inline-block;
  }

  /* Only for EDGE */
  /* stylelint-disable selector-type-no-unknown */
  _:-ms-lang(x),
  _:-webkit-full-screen,
  & {
    float: right;
    display: inline-block;
  }
  /* stylelint-enable selector-type-no-unknown */

  @supports (display: grid) {
    display: unset;
    float: none;
  }
`;

export const StyledQuantityColumn = styled.div`
  grid-area: quantityCol;
  @supports (display: grid) {
    @media screen and (min-width: ${breakpoints.tablet}) {
      grid-row: dimensionsRow;
      grid-column: 10 / span 2;
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      grid-row: unset;
      grid-column: unset;
    }
  }

  ${ieStyledQuantityColumn}
`;

export const StyledQuantitySelectorWrapper = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: block;
    grid-column: 10 / 12;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    grid-column: 11 / 13;
  }

  @media all and (-ms-high-contrast: none) {
    margin-left: 30px;
  }
`;
