// 9fbef606107a605d69c0edbcd8029e5d

import { all, put, call, takeLatest, fork } from "redux-saga/effects";
import { logging, localStorageBridge } from "root/libs/core-libs/src";
import { SETUP_LOCALE_DATA, SETUP_LOCALE_DATA_SUCCESS } from "./constants";
import * as actions from "./actions";

export function* setupLocaleData(action) {
  const { payload } = action;

  try {
    yield put(actions.setupLocaleDataSuccess(payload));
  } catch (error) {
    yield call(logging.warn, error);
    yield put(
      actions.setupLocaleDataFailure({
        locale: payload.locale,
        message: error.message,
      })
    );
  }
}

export function* loadCopyText(options, action) {
  const { payload } = action;
  const { locale } = payload;
  const { getTranslations, cacheNameSpace } = options;

  try {
    yield put(actions.loadCopyText(payload));

    const localStorageKey = `${cacheNameSpace}:${locale}`;
    const localMessages = yield call(
      localStorageBridge.load,
      localStorage,
      localStorageKey
    );

    if (localMessages) {
      yield put(
        actions.setCopyTextFromStorage({
          locale,
          messages: JSON.parse(localMessages),
        })
      );
    }

    const messages = yield call(getTranslations, locale);

    yield put(actions.loadCopyTextSuccess({ ...payload, messages }));
    yield call(
      localStorageBridge.save,
      localStorage,
      localStorageKey,
      messages
    );
  } catch (error) {
    yield call(logging.warn, error);
    yield put(actions.loadCopyTextFailure({ locale, message: error.message }));
  }
}

export function* watchSetupLocaleData() {
  yield takeLatest(SETUP_LOCALE_DATA, setupLocaleData);
}

export function* watchSetupLocaleDataSuccess(options) {
  yield takeLatest(SETUP_LOCALE_DATA_SUCCESS, loadCopyText.bind(null, options));
}

function* sagas(options) {
  yield all([
    fork(watchSetupLocaleData),
    fork(watchSetupLocaleDataSuccess.bind(null, options)),
  ]);
}

export default function createSagas(getTranslations, cacheNameSpace) {
  return sagas.bind(null, { getTranslations, cacheNameSpace });
}
