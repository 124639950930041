// 9fbef606107a605d69c0edbcd8029e5d

import { createSelector } from "reselect";
import { RemoteData } from "root/libs/core-libs/src";
import prop from "lodash/fp/prop";

export const selectCountry = ({ countrySettings }) => countrySettings.country;

export const selectIsUnitSwitchEnabled = ({ countrySettings }) =>
  countrySettings.remote[countrySettings.country].data.enableUnitSwitch;

export const selectCountrySettings = createSelector(
  prop("countrySettings"),
  (countrySettings) =>
    countrySettings.remote[countrySettings.country] || RemoteData.notAsked()
);

export const selectDefaultDestination = ({ countrySettings }) =>
  countrySettings.remote[countrySettings.country].data
    .defaultDestinationCountry;

export const selectIsDomesticShippingAvailable = ({ countrySettings }) =>
  countrySettings.remote[countrySettings.country].data
    .isDomesticShippingAvailable;
