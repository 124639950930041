// 9fbef606107a605d69c0edbcd8029e5d
import { deliveryTimeDisplayTypes } from "one-time-shipment/src/containers/OffersLane/constants";

export default function getDeliveryTime(
  intl,
  timeOfDay,
  estimatedDeliveryTimeDisplayType
) {
  let deliveryTime = "";

  if (
    !(
      typeof estimatedDeliveryTimeDisplayType === "undefined" ||
      estimatedDeliveryTimeDisplayType === null
    )
  ) {
    deliveryTime = `${intl.formatMessage({
      id: "CardAndSummary.by",
    })} ${timeOfDay}`;
  }

  const isExactorEndOfDay =
    estimatedDeliveryTimeDisplayType ===
      deliveryTimeDisplayTypes.latestEndOfDay ||
    estimatedDeliveryTimeDisplayType === deliveryTimeDisplayTypes.exact;

  if (isExactorEndOfDay) {
    deliveryTime = intl.formatMessage(
      { id: "CardAndSummary.latestBy" },
      { timeOfDay }
    );
  }

  return deliveryTime;
}
