// 9fbef606107a605d69c0edbcd8029e5d

import axios from "axios";
import qs from "qs";
import {
  devModeOptions,
  getTranslations,
  getUrl,
  isRemoteApiEnabled,
  urlNormalizer,
  cookieBridge,
  getCorrelationHeaders,
} from "root/libs/core-libs/src";
import { COMMON } from "root/libs/constants/src";

import apisConfig from "../config/apis";

const { hosts } = apisConfig.urls;

const getApiBaseUrl = (adapter) => {
  switch (adapter) {
    case "quote":
      return hosts.quoteBaseUrl;
    default:
      return hosts.mainApiUrl;
  }
};

const setCanaryVersionHeader = (canaryVersion) =>
  canaryVersion && { [COMMON.CANARY_HEADER]: canaryVersion };

const setMicroFrontendIdHeader = (microFrontendId) =>
  microFrontendId && { [COMMON.MICRO_FRONTEND_ID_HEADER]: microFrontendId };

const getPathnameHeader = () => {
  if (window) {
    const { pathname } = window.location;
    return { [COMMON.PATHNAME_HEADER]: pathname };
  }
  return {};
};

const getHeaderForTargetServiceCard = () => {
  const cookieValue = cookieBridge.getCookie(
    COMMON.TARGET_SERVICE_CARD_COOKIE.name
  );
  if (cookieValue) {
    return {
      [COMMON.TARGET_SERVICE_CARD_COOKIE.name]: cookieValue,
    };
  }
  return {};
};

const buildQueryString = (params) =>
  qs
    .stringify(params, {
      allowDots: true,
      addQueryPrefix: true,
      encodeValuesOnly: true,
    })
    .replace(/(\[)/g, "(")
    .replace(/(\])/g, ")");

function get(
  endpoint,
  options = { params: "", adapter: "", canaryVersion: "", microFrontendId: "" }
) {
  const useRemote = isRemoteApiEnabled({ devMode: devModeOptions.API });
  const apiBaseUri = !useRemote ? hosts.root : getApiBaseUrl(options.adapter);
  const finalEndpoint = getUrl({
    endpoint,
    urls: apisConfig.urls,
    apiBaseUri,
  });
  const queryString = buildQueryString(options.params) || "";

  return axios.get(`${finalEndpoint}${queryString}`, {
    headers: {
      ...getCorrelationHeaders(),
      ...getPathnameHeader(),
      ...getHeaderForTargetServiceCard(),
      ...setCanaryVersionHeader(options.canaryVersion),
      ...setMicroFrontendIdHeader(options.microFrontendId),
    },
  });
}

function setupTranslations() {
  const { gaqTranslationsBaseUrl } = apisConfig.urls.hosts;

  return getTranslations(
    (locale) => {
      const [language] = locale.toLowerCase().split("-");
      if (language === "ar") {
        return import(
          "one-time-shipment/mocks/translations/_default_ar.json"
        ).then((module) => module.default);
      }
      return import("one-time-shipment/mocks/translations/_default.json").then(
        (module) => module.default
      );
    },
    (locale) => {
      const [language, country] = locale.toLowerCase().split("-");

      return `${gaqTranslationsBaseUrl}/${country}/${country}-${language}/content-api/one-time-shipment.data.json`;
    }
  );
}

const NAME_MAPPER = {
  nlEnvelope: "DHL_Preset-NL-Envelope",
  nlBooks: "DHL_Preset-NL-Books",
  nlShoebox: "DHL_Preset-NL-Shoebox",
  nlMovingbox: "DHL_Preset-NL-MovingBox",
  usEnvelope: "DHL_Preset-US-DocumentEnvelope",
  usBooks: "DHL_Preset-US-Books",
  usBoardgame: "DHL_Preset-US-BoardGame",
  usMovingbox: "DHL_Preset-US-MovingBox",
  usKitchenappliance: "DHL_Preset-US-KitchenAppliance",
  nlPaletEur1: "DHL_Preset-Pallet-EUR-1-120x80",
  nlPaletEur3: "DHL_Preset-Pallet-EUR-3-120x100",
  nlPaletEur6: "DHL_Preset-Pallet-EUR-6-80x60",
  auStandardPallet: "DHL_Preset-Pallet-AU-Standard-116x116",
  auExportPallet: "DHL_Preset-Pallet-AU-Export-110x110",
  us1Pallet: "DHL_Preset-Pallet-US-1-48x40",
  mxStandardPallet: "DHL_Preset-Pallet-MX-Standard-120x120",
  eur1Pallet: "DHL_Preset-Pallet-EUR-1-120x80",
  eur3Pallet: "DHL_Preset-Pallet-EUR-3-120x100",
  eur6Pallet: "DHL_Preset-Pallet-EUR-6-80x60",
  cp3Pallet: "DHL_Preset-Pallet-CP3-114x114",
};

const fetchSvgForPresets = (presets) =>
  // 1. We make a list of promises that will be called for every icon.
  Promise.all(
    presets.map((item) =>
      // 2. If the icon is not mapped (meaning we don't have an icon for it)
      // we just return an object with `default` set to undefined.
      NAME_MAPPER[item.icon]
        ? import(`iconsTwoColors/cx/${NAME_MAPPER[item.icon]}.svg`)
        : Promise.resolve({ default: undefined })
    )
  ).then((res) =>
    // 3. We then get the response of that call and put the svg inside the `svg` property.
    presets.map((b, index) => ({
      ...b,
      svg: res[index].default,
    }))
  );

const getResponseWithSvgs = (data) => {
  // 1. We declare the promises that will be called to fetch the svgs for business preset sizes.
  const businessPromises = fetchSvgForPresets(
    data.spotShipmentPresetSizes.business
  );

  // 2. We declare the promises that will be called to fetch the svgs for private preset sizes.
  const privatePromises = fetchSvgForPresets(
    data.spotShipmentPresetSizes.private
  );

  // 3. We now call all the promises in parallel and adjust our object with the responses
  // we got from them.
  return Promise.all([businessPromises, privatePromises]).then(
    ([businessWithSvg, priavateWithSvg]) => ({
      ...data,
      spotShipmentPresetSizes: {
        weightBreakThreshold: data.spotShipmentPresetSizes.weightBreakThreshold,
        business: businessWithSvg,
        private: priavateWithSvg,
      },
    })
  );
};

function getCountrySettings(country, experience) {
  const useRemote = isRemoteApiEnabled({ devMode: devModeOptions.SETTINGS });

  const apiBaseUri = !useRemote ? hosts.root : getApiBaseUrl();

  if (useRemote) {
    const url = getUrl({
      endpoint: "countrySettings",
      urls: apisConfig.urls,
      apiBaseUri,
    });

    return axios
      .get(
        // eslint-disable-next-line prefer-template
        urlNormalizer(`${url}/${country}${experience ? "/" + experience : ""}`)
      )
      .then(({ data }) => getResponseWithSvgs(data));
  }

  return import(
    "one-time-shipment/mocks/settings/_default.json"
  ).then((module) => getResponseWithSvgs(module.default));
}

function getPostalLocationCountries(params, canaryVersion) {
  const apiBaseUri = getApiBaseUrl();

  const url = getUrl({
    endpoint: "postalCodePlaceholder",
    urls: apisConfig.urls,
    apiBaseUri,
  });

  return axios
    .get(
      // eslint-disable-next-line prefer-template
      urlNormalizer(url),
      {
        params: {
          ...params,
        },
        headers: {
          ...getCorrelationHeaders(),
          ...setCanaryVersionHeader(canaryVersion),
        },
      }
    )
    .then((response) => response.data);
}

function post({ endpoint, options = { payload: {}, adapter: "" } }) {
  const useRemote = isRemoteApiEnabled({ devMode: devModeOptions.API });
  const apiBaseUri = !useRemote ? hosts.root : getApiBaseUrl(options.adapter);

  const finalEndpoint = getUrl({
    endpoint,
    urls: apisConfig.urls,
    apiBaseUri,
  });
  const headers = {
    "Content-Type": "application/vnd.dhl.logistics.quotation+json;version=1",
    ...getCorrelationHeaders(),
  };

  return axios.post(`${finalEndpoint}`, options.payload, {
    headers,
  });
}

function getPostalLocationQuery(params, canaryVersion, key) {
  const { urls } = apisConfig;

  return axios({
    baseURL: urls.hosts.postalLocationBaseUrl,
    url: key ? urls.endpoints[key] : urls.endpoints.locationAutocomplete,
    params: {
      ...params,
    },
    headers: {
      ...getCorrelationHeaders(),
      ...setCanaryVersionHeader(canaryVersion),
    },
  });
}

function logPowerBIEvent(endpoint) {
  axios.get(endpoint).catch(function catchBlock(error) {
    if (typeof error.response === "undefined") {
      /* eslint-enable no-console */
      console.warn(`A network error occurred.`);
    } else {
      throw new Error(error.message);
    }
  });
}

export default {
  get,
  post,
  getPostalLocationQuery,
  setupTranslations,
  getCountrySettings,
  getPathnameHeader,
  getPostalLocationCountries,
  logPowerBIEvent,
};
