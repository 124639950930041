// 9fbef606107a605d69c0edbcd8029e5d

import { css } from "styled-components";
import deliveryWBd2 from "@dhl-official/react-ui-library/dist/assets/fonts/Delivery_W_Bd.woff2";
import deliveryWCdBlk2 from "@dhl-official/react-ui-library/dist/assets/fonts/Delivery_W_CdBlk.woff2";
import deliveryWCdLt2 from "@dhl-official/react-ui-library/dist/assets/fonts/Delivery_W_CdLt.woff2";
import deliveryWRg2 from "@dhl-official/react-ui-library/dist/assets/fonts/Delivery_W_Rg.woff2";

export const LOCALES_FONT_ASSISTANT = ["he"];
export const LOCALES_FONT_VIETNAMESE = ["vi"];
export const LOCALES_FONT_ARABIC = ["ar"];

export const hasDeliverySetForElement = (element) =>
  window
    .getComputedStyle(element)
    .getPropertyValue("font-family")
    .includes("Delivery");

export const getAssistantFontStyles = () => css`
  --dui-font-family: Assistant, Delivery, Verdana, sans-serif;
`;

export const getArabicFontStyles = () => css`
  --dui-font-family: "Delivery Arabic", Delivery, Verdana, sans-serif;
`;

export const getVietnameseFontStyles = () => css`
  --dui-font-family: "Delivery Vietnamese", Delivery, Verdana, sans-serif;
`;

const getAdditionalFonts = (locale) => {
  const language = locale.split("-")[0]; // Extracting language part from the locale

  if (LOCALES_FONT_ASSISTANT.includes(language)) {
    // Apply Assistant font styles
    return getAssistantFontStyles();
  }

  if (LOCALES_FONT_ARABIC.includes(language)) {
    // Apply Arabic font styles
    return getArabicFontStyles();
  }

  if (LOCALES_FONT_VIETNAMESE.includes(language)) {
    // Apply Vietnamese font styles
    return getVietnameseFontStyles();
  }
};

export default (locale) => css`
  ${!hasDeliverySetForElement(document.body) &&
  css`
    :root {
      --dui-font-family: Delivery, Verdana, sans-serif;
    }
    @font-face {
      font-family: "Delivery";
      font-display: swap;
      src: url(${deliveryWCdLt2}) format("woff2");
      font-weight: 200;
    }

    @font-face {
      font-family: "Delivery";
      font-display: swap;
      src: url(${deliveryWRg2}) format("woff2");
      font-weight: 400;
    }

    @font-face {
      font-family: "Delivery";
      font-display: swap;
      src: url(${deliveryWBd2}) format("woff2");
      font-weight: 700;
    }

    @font-face {
      font-family: "Delivery";
      font-display: swap;
      src: url(${deliveryWCdBlk2}) format("woff2");
      font-weight: 800;
    }
  `}
  :root {
    ${getAdditionalFonts(locale)}
  }
`;
