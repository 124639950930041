// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for FeedbackMessage
 *
 */

import styled, { css } from "styled-components";
import { key } from "styled-theme";
import { sizes } from "root/libs/ui-styleguide/src";
import { Text } from "@dhl-official/react-ui-library";

const variations = {
  error: css`
    color: ${key("colors.errorColor")};
  `,
  warning: css`
    color: ${key("colors.warningColor")};
    background-color: ${key("colors.warningBackgroundColor")};
    padding: 8px 10px 5px;
  `,
  hint: css`
    background-color: ${key("colors.backgroundColorInverted")};
    border: 1px solid ${key("colors.lightBorderColor")};
    padding: 15px 14px;
    color: ${key("colors.fontColor")};
  `,
};

export const StyledText = styled(Text)`
  color: ${key("colors.errorColor")};
  margin-top: ${sizes.inputSidePaddings};
  text-decoration: ${(props) => (props.underline ? "underline" : "none")};
  ${(props) => variations[props.variation]}
  ${(props) => (props.centered ? "text-align: center;" : "")};
`;
