// 9fbef606107a605d69c0edbcd8029e5d

import { createAction } from "redux-actions";
import {
  TRACK_EVENT,
  PUSH_TO_DIGITAL_LAYER,
  INITIALIZE_DIGITAL_LAYER,
} from "./constants";

export const trackEvent = createAction(TRACK_EVENT);
export const pushToDigitalLayer = createAction(PUSH_TO_DIGITAL_LAYER);
export const initializeDigitalLayer = createAction(INITIALIZE_DIGITAL_LAYER);
