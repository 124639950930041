// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for AdditionalInformation
 *
 */

import styled from "styled-components";
import { Text } from "@dhl-official/react-ui-library";
import { Headline, Link } from "root/libs/ui-components/src";
import { breakpoints } from "root/libs/ui-styleguide/src";

export const AdditionalInformationWrapper = styled.div`
  margin-bottom: 40px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: 30px;
  }
`;

export const AdditionalInformationHeadline = styled(Headline)`
  margin-bottom: 12px;
`;

export const AdditionalInformationList = styled.ul`
  list-style-type: none;
`;
