// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for OfferPromotedLine
 *
 */

import styled from "styled-components";
import { Text } from "@dhl-official/react-ui-library";
import { breakpoints } from "root/libs/ui-styleguide/src";

export const PromotedTextWrapper = styled.div`
  display: flex;
  ${(props) => (props.showInViewport === "mobile" ? "display: flex" : "")};
  ${(props) => (props.showInViewport === "desktop" ? "display: none" : "")};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${(props) => (props.showInViewport === "mobile" ? "display: none" : "")};
    ${(props) => (props.showInViewport === "desktop" ? "display: flex" : "")};
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-right: 50px;
    align-items: center;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-right: 0;
    align-items: inherit;
  }
`;

export const PromotedText = styled(Text)`
  min-height: 32px;
  min-height: 11%;
`;
