// 9fbef606107a605d69c0edbcd8029e5d

import styled, { createGlobalStyle } from "styled-components";
import { breakpoints } from "root/libs/ui-styleguide/src";
import { Headline } from "root/libs/ui-components/src";

// NOTE: These global styles will override logistics print styles for heading elements.
export const GlobalPrintStyle = createGlobalStyle`
  @media print{
    .c-nav {
      background: transparent;
    }
    .c-page-headline {
      display: none;
    }
    ::placeholder { /* WebKit browsers */
      color: transparent !important;
    }
  }
`;

export const BypassBlockWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  justify-items: center;
  flex-direction: column;
  height: 100px;
  margin-top: 100px;
  margin-bottom: 50px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: 150px 20px 70px;
    flex-direction: row;
    height: auto;
    justify-content: flex-end;

    .bypassBlock:first-child {
      margin-right: 10px;
    }
  }

  @media print {
    display: none;
  }
`;

export const StyledHeadline = styled(Headline)`
  margin-bottom: 30px;
  padding-top: 40px;

  @media print {
    display: none;
  }
`;

export const StyledHeadlineVariant = styled(StyledHeadline)`
  text-align: center;
`;
