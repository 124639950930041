// 9fbef606107a605d69c0edbcd8029e5d

export const getUtfAddOnString = (array) => {
  return array?.map((obj) => obj.key).join("|");
};

export const getUtfValidityString = (map) => {
  let valid = true;
  const utfString = Object.keys(map)
    .filter((key) => {
      if (map[key]) {
        valid = false;
        return true;
      }
      return false;
    })
    .join("|");
  return { valid, utfString };
};
