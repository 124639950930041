// 9fbef606107a605d69c0edbcd8029e5d

/*
 *
 * CanaryVersions reducer
 *
 */

import { handleActions } from "redux-actions";
import {
  enableCanaryVersion as enableCanaryVersionAction,
} from "./actions";

const initialState = {
  enabled: false,
  version: "",
};

const enableCanaryVersion = (
  state,
  { payload: version = "" }
) => ({
  enabled: true,
  version,
});

export default handleActions(
  {
    [enableCanaryVersionAction]: enableCanaryVersion,
  },
  initialState
);
// To bind this to Redux, import it in the reducers.js
