// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for GlobalAlert
 *
 */

import styled, { css } from "styled-components";

export const ieCSS = css`
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  transform: rotate(0deg);
  stroke-dasharray: 210, 160;
  animation: rotate 2s linear infinite;
  transform-origin: center center;
  ${(props) => `width: ${props.iconSize}; height: ${props.iconSize};`}

  .grey-circle {
    display: none;
  }
`;

export const ieAnimation = css`
  /* Only for IE11 */
  @media all and (-ms-high-contrast: none) {
    ${ieCSS}
  }

  /* Only for EDGE */
  /* stylelint-disable selector-type-no-unknown */
  _:-ms-lang(x),
  _:-webkit-full-screen,
  & {
    ${ieCSS}
  }
  /* stylelint-enable selector-type-no-unknown */
`;

export const StyledContent = styled.div`
  display: ${(props) => (props.isVisible ? "inline-block" : "none")};
  ${ieAnimation}
`;
