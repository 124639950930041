// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * IconButtonTooltip
 *
 */

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import memoize from "lodash/memoize";
import infoIcon from "iconsTwoColors/cx/info.svg";
import helpIcon from "iconsTwoColors/cx/help.svg";
import { Popover, POPOVER_PLACEMENTS } from "@dhl-official/react-ui-library";
import Icon from "../../Icons/Icon";

const StyledPopover = styled(Popover)`
  & > div[role="button"] {
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
  }
`;

const getIconToUse = memoize(({ icon, iconType }) => {
  let iconToUse = infoIcon;
  if (iconType === "help") {
    iconToUse = helpIcon;
  }
  if (icon !== null) {
    iconToUse = icon;
  }

  return iconToUse;
});

const IconButtonTooltip = ({
  id,
  icon,
  dataTracking,
  className,
  ariaLabel,
  tooltipContent,
  arrowDirection,
  iconType,
  ariaDescribedBy,
  trackEvent,
  iconTrackEventKey,
  heading,
  showCloseButton,
}) => {
  function onClick(e) {
    e.preventDefault();

    if (iconTrackEventKey) {
      trackEvent({ [iconTrackEventKey]: true });
    }
  }

  const iconToUse = getIconToUse({ icon, iconType });

  return (
    <StyledPopover
      placement={arrowDirection}
      id={id}
      showCloseButton={showCloseButton}
      popoverTriggerAriaLabel={ariaLabel || "Click here to open popover"}
      className={className}
      heading={heading}
      triggerEl={
        <Icon
          useAs="decorative"
          size="small"
          icon={iconToUse}
          onClick={onClick}
          dataTracking={dataTracking}
          ariaDescribedBy={ariaDescribedBy}
        />
      }
    >
      {tooltipContent}
    </StyledPopover>
  );
};

IconButtonTooltip.propTypes = {
  /** JSX that will be used to be rendered inside the tooltip.
   * If this is set, the icon must me 'standalone' and have a label.
   * You are encouraged to use span as text for the tooltip. This way you have valid HTML markup.
   * Buttons dont allow p as children
   */
  tooltipContent: PropTypes.any.isRequired,
  id: PropTypes.string,
  heading: PropTypes.string,
  /** It will be used to tell screen readers what the icon button does */
  ariaLabel: PropTypes.string.isRequired,
  ariaDescribedBy: PropTypes.string,
  arrowDirection: PropTypes.oneOf(Object.values(POPOVER_PLACEMENTS)),
  /** Info is an i icon, and help is a question mark */
  iconType: PropTypes.oneOf(["info", "help", ""]),
  /** Object generated by the svg-sprite-loader importing an
   * icon to be used.
   * If you dont send this, you will need to decide on the Icon Type: Help or Info */
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
      viewBox: PropTypes.string,
      url: PropTypes.string,
    }),
  ]),
  /** Allows the component styles to be edited from a caller compoent */
  className: PropTypes.string,
  /**
   * Optional prop to hold the tracking path.
   * It will get used as data-attribute on
   * the rendered interactive element
   */
  dataTracking: PropTypes.string,
  /** This prop holds the id to be updated by the trackEvent call of a tooltip icon click */
  iconTrackEventKey: PropTypes.string,
  trackEvent: PropTypes.func,
  showCloseButton: PropTypes.bool,
};

IconButtonTooltip.defaultProps = {
  showCloseButton: false,
  arrowDirection: POPOVER_PLACEMENTS.AUTO,
  iconType: "info",
  icon: null,
  dataTracking: null,
  iconTrackEventKey: null,
  trackEvent: () => {},
  className: "",
  ariaDescribedBy: "",
  id: "tooltip",
  heading: null,
};

export default IconButtonTooltip;
