// 9fbef606107a605d69c0edbcd8029e5d

import { TYPES_OF_OFFERS } from "one-time-shipment/src/containers/GetQuote/constants";

/*  New structure of the Offers Array to align towards MultiBU with all Offers
    we will return a new array looking like this: 
        [method: "WALK", offers: [a, b, c, d]][method: "CLICK", offers: [e, f, g]] [method: "CALL", offers: [h, i]]
    this array will then be consumed inside the WCCOfferCardWrapper where we will connect the header towards the offers inside the associated payment method. 
    */
export const sortMultiBUOffersByPurchaseMethod = (offers) => {
  const returnOffersArrayByMethod = [
    { method: "WALK", offers: [], isHighlighted: false },
    { method: "CLICK", offers: [], isHighlighted: false },
    { method: "CALL", offers: [], isHighlighted: false },
    { method: "TARGET_SERVICE", offers: [], isHighlighted: false }
  ];

  const filteredAggregatedOffers = offers.filter(
    (offer) => offer.type === TYPES_OF_OFFERS.AGGREGATED
  );
  const filteredSimpleOffers = offers.filter(
    (offer) => offer.type === TYPES_OF_OFFERS.SIMPLE
  );

  filteredAggregatedOffers.map((offer) => {
    const { metadata } = offer;
    const { availablePurchaseMethods } = metadata;
    if (metadata?.highlightedPurchaseMethod) {
      const highlightedMethod = metadata.highlightedPurchaseMethod;

      returnOffersArrayByMethod.find(
        (obj) => obj.method === highlightedMethod
      ).isHighlighted = true;
    }
    availablePurchaseMethods.map((method) => {
      const purchaseMethod = method;
      const cheapestOffer =
        metadata.metadataByPurchaseMethod[method].cheapestOffer.id;
      const offerObj = offer.offerOptions.find(
        (offerOption) =>
          offerOption.purchaseMethod === method &&
          offerOption.id === cheapestOffer
      );
      returnOffersArrayByMethod
        .find((obj) => obj.method === purchaseMethod)
        .offers.push(offerObj);
      return method;
    });
    return offer;
  });

  filteredSimpleOffers.map((offer) => {
    let purchaseMethod = "CALL";
    if (offer.key === "TARGET_SERVICE_CARD") {
      purchaseMethod = "TARGET_SERVICE";
    } else if (offer.purchaseMethod === undefined) {
      purchaseMethod = "CLICK";
    } else if (offer.purchaseMethod) {
      purchaseMethod = offer.purchaseMethod;
    }
    returnOffersArrayByMethod
      .find((obj) => obj.method === purchaseMethod)
      .offers.push(offer);
    return offer;
  });

  returnOffersArrayByMethod.flat();
  return returnOffersArrayByMethod;
};
