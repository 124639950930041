// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * AddressTypeCheckbox
 *
 */

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { breakpoints } from "root/libs/ui-styleguide/src";
import { IntlPropType } from "root/libs/core-libs/src";
import { TYPES_OF_ADDRESSES } from "one-time-shipment/src/containers/GetQuote/constants";
import {
  CheckboxField,
  CHECKBOXFIELD_SIZES,
} from "@dhl-official/react-ui-library";

const StyledAddressTypeCheckbox = styled(CheckboxField)`
  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: 25px 5px 0px 0px;
  }

  & > label > input {
    /*! @noflip */
    left: -9999px;
    /*! @noflip */
    right: unset;
    position: absolute;
  }
`;

const COPY_ID_PREFIX = "AddressTypeCheckbox";

const AddressTypeCheckbox = ({
  getKeyInDigitalLayer,
  hasResidencialAddress,
  onChange,
  intl,
  value,
}) => {
  const addressTypeTrackingKeyPath = getKeyInDigitalLayer(
    "spotShipment.initialSet.receiverAddressType"
  );

  return (
    <React.Fragment>
      {hasResidencialAddress && (
        <StyledAddressTypeCheckbox
          name="receiverAddressType"
          dataTestid="receiverAddressType"
          size={CHECKBOXFIELD_SIZES.SMALL}
          value={TYPES_OF_ADDRESSES.RESIDENTIAL}
          onChange={onChange}
          isChecked={value === TYPES_OF_ADDRESSES.RESIDENTIAL}
          dataTracking={addressTypeTrackingKeyPath}
        >
          <span data-testid="residential-address-type-label">
            {intl.formatMessage({
              id: `${COPY_ID_PREFIX}.residentialAddress`,
            })}
          </span>
        </StyledAddressTypeCheckbox>
      )}
    </React.Fragment>
  );
};

export default AddressTypeCheckbox;

AddressTypeCheckbox.propTypes = {
  /** Translations library  */
  intl: IntlPropType.isRequired,
  hasResidencialAddress: PropTypes.bool,
  onChange: PropTypes.func,
  extraStyles: PropTypes.array,
  value: PropTypes.oneOf(Object.values(TYPES_OF_ADDRESSES)),
  getKeyInDigitalLayer: PropTypes.func,
};

AddressTypeCheckbox.defaultProps = {
  hasResidencialAddress: false,
  onChange: () => {},
  value: TYPES_OF_ADDRESSES.BUSINESS,
  extraStyles: [],
  getKeyInDigitalLayer: () => {},
};
