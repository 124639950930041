// 9fbef606107a605d69c0edbcd8029e5d

/*
 *
 * AbTestExperiment reducer
 *
 */

import { handleActions } from "redux-actions";
import {
  enableAbTesting as enableAbTestingAction,
  changeABVersion as changeABVersionAction,
} from "./actions";

import { TYPES_OF_ABTESTVERSIONS } from "./constants";

const initialState = {
  abTestRunning: false,
  showThisVersion: TYPES_OF_ABTESTVERSIONS.control,
};

const enableAbTesting = (state, { payload: abTestRunning = true }) => ({
  ...state,
  abTestRunning,
  showThisVersion: TYPES_OF_ABTESTVERSIONS.control,
});

const changeABVersion = (
  state,
  { payload: showThisVersion = TYPES_OF_ABTESTVERSIONS.control }
) => ({
  ...state,
  showThisVersion,
});

export default handleActions(
  {
    [enableAbTestingAction]: enableAbTesting,
    [changeABVersionAction]: changeABVersion,
  },
  initialState
);
// To bind this to Redux, import it in the reducers.js
