// 9fbef606107a605d69c0edbcd8029e5d

/*
 *
 * getOffers reducer
 *
 */

import sortBy from "lodash/sortBy";
import { handleActions } from "redux-actions";
import parseISO from "date-fns/parseISO";
import {
  getOffersRequestSent as getOffersRequestSentAction,
  getOffersRequestSucceeded as getOffersRequestSucceededAction,
  getOffersRequestFailed as getOffersRequestFailedAction,
  updateShippingDate as updateShippingDateAction,
  resetQuoteResults as resetQuoteResultsAction,
  sortOffers as sortOffersAction,
  localShipmentDetected as localShipmentDetectedAction,
} from "./actions";
import { makeBackendsErrorSelection } from "./selectors";
import {
  TYPES_OF_CUSTOMERS,
  TYPES_OF_OFFERS,
  GO_GREEN_PLUS,
} from "./constants";

// tmp solution until canary gets defaulted
const SORTING_ORDER = {
  offersSortDeliveryFastToSlow: "offersSortDeliveryFastToSlow",
  offersSortPriceHighToLow: "offersSortPriceHighToLow",
  offersSortPriceLowToHigh: "offersSortPriceLowToHigh",
};
// const DEFAULT_OFFER_SORT_ORDER = offerSortingOptions[1];
const DEFAULT_OFFER_SORT_ORDER = SORTING_ORDER.offersSortDeliveryFastToSlow;

const TARGET_SERVICE_CARD = "TARGET_SERVICE_CARD";

const initialState = {
  isShippingDateVisible: false,
  sortOffersBy: DEFAULT_OFFER_SORT_ORDER,
  shippingDate: "",
  typesOfCustomers: TYPES_OF_CUSTOMERS,
  staticOffers: [],
  offers: [],
  backends: [],
  metadata: {},
  options: [],
  waitingForResponse: false,
  networkError: {},
  localShipmentDetected: false,
  isSortOffersByVisible: false,
};

const resetQuoteResults = (state) => ({
  ...state,
  sortOffersBy: DEFAULT_OFFER_SORT_ORDER,
  isShippingDateVisible: initialState.isShippingDateVisible,
  offers: initialState.offers,
  staticOffers: initialState.staticOffers,
  backends: initialState.offers,
  metadata: initialState.metadata,
  options: initialState.options,
  networkError: initialState.networkError,
});

const getOffersRequestSent = (state) => ({
  ...state,
  waitingForResponse: true,
});

const sortedOffers = (offers, sortByParam) => {
  const isCarbonNeutral = (offer) =>
    offer.features?.climateNeutralShippingMode === GO_GREEN_PLUS
      ? offer.features.climateNeutralShippingMode
      : null;
  const isPromoCard = (offer) => (offer.key === TARGET_SERVICE_CARD ? 1 : 0);

  const createTempKeys = (offer) => {
    const tempOffer = { ...offer };
    tempOffer.tempPrice =
      offer.price?.amountInBaseCurrency ||
      offer.metadata?.cheapestOffer.amountInBaseCurrency;
    tempOffer.tempDeliveryTime =
      offer?.estimatedDeliveryTime ||
      offer.metadata?.fastestOffer.estimatedDeliveryTime;
    return tempOffer;
  };

  const removeTempKeys = (offer) => {
    const { tempPrice, tempDeliveryTime, ...rest } = offer;
    return rest;
  };

  const modifiedOffers = offers.map(createTempKeys);

  const sortingFunctions = {
    offersSortPriceLowToHigh: [
      "tempPrice",
      "tempDeliveryTime",
      "estimatedDeliveryTimeDisplayType",
      isCarbonNeutral,
      isPromoCard,
    ],
    offersSortPriceHighToLow: [
      (offer) => -offer.tempPrice,
      "tempDeliveryTime",
      "estimatedDeliveryTimeDisplayType",
      isCarbonNeutral,
      isPromoCard,
    ],
    offersSortDeliveryFastToSlow: [
      isCarbonNeutral,
      "tempDeliveryTime",
      "estimatedDeliveryTimeDisplayType",
      isPromoCard,
    ],
  };

  const sortingOrder =
    sortingFunctions[sortByParam] ||
    sortingFunctions.offersSortDeliveryFastToSlow;

  const newOffers = sortBy(modifiedOffers, sortingOrder).map(removeTempKeys);

  return newOffers;
};

const enableSortOffersByDropdown = (offers) => {
  let validPrice = [];

  if (offers.length > 1) {
    validPrice = offers.filter((obj) => obj.price?.amount);
  }
  return validPrice.length >= 2;
};

const getOffersRequestSucceeded = (state, { payload: data }) => {
  const { options = [], offers: originalOffers = [], staticOffers = [] } = data;
  const shippingDateOption = options.find(
    (option) => option.key === "SHIPPING_DATE"
  );

  const determineOfferAmount = (offer) => {
    const mapPrice = (price) =>
      price && "amount" in price ? price.amount : price?.billingAmount || null;

    const updatedOfferOptions =
      offer.type === TYPES_OF_OFFERS.AGGREGATED
        ? offer.offerOptions.map((offerOption) => ({
            ...offerOption,
            price: {
              ...offerOption.price,
              amountInBaseCurrency: mapPrice(offerOption.price),
            },
          }))
        : undefined;

    // Update offer amount for cheapest offer
    const cheapestOffer =
      offer.type === TYPES_OF_OFFERS.AGGREGATED
        ? {
            ...offer.metadata?.cheapestOffer,
            amountInBaseCurrency: mapPrice(
              offer.metadata?.cheapestOffer?.price
            ),
          }
        : undefined;

    const updatedOffer = {
      ...offer,
      price: { ...offer.price, amountInBaseCurrency: mapPrice(offer.price) },
      type: offer.type || TYPES_OF_OFFERS.SIMPLE,
    };

    // Include offerOptions only if it's defined
    if (updatedOfferOptions) {
      updatedOffer.offerOptions = updatedOfferOptions;
    }

    // Include metadata only if "cheapestOffer" is present (for AGGREGATED offers)
    if (cheapestOffer) {
      updatedOffer.metadata = {
        ...offer.metadata,
        cheapestOffer,
      };
    }

    return updatedOffer;
  };

  const updatedOffers = originalOffers.map(determineOfferAmount);

  /**
   * The API current doesn't return the timezone.
   *
   * We can expect a date like `2019-09-23T23:59`. Problem is if we simply `new Date` it
   * different browsers make different assumptions regarding timezone. Essentially, _Safari_
   * assumes that the date is UTC 0 whereas _Chrome_ assumes the browser's UTC.
   *
   * Safari
   * `new Date("2019-09-23T23:59").toString() === 'Tue Sep 24 2019 01:59:00 GMT+0200 (CEST)'`
   *
   * Chrome
   * `new Date("2019-09-23T23:59").toString() === 'Mon Sep 23 2019 23:59:00 GMT+0200 (CEST)'`
   *
   * The `parseISO` saves the time in the store as if it was using the user's timezone, so
   * `2019-09-23T23:59` becomes `2019-09-23T21:59:00.000Z` (in Germany).
   *
   * Safari
   * `new Date("2019-09-23T21:59:00.000Z").toString() === 'Mon Sep 23 2019 23:59:00 GMT+0200 (CEST)'`
   *
   * Chrome
   * `new Date("2019-09-23T21:59:00.000Z").toString() === 'Mon Sep 23 2019 23:59:00 GMT+0200 (CEST)'`
   *
   */
  const parseOfferDate = (date) =>
    date && date !== "null" ? parseISO(date) : undefined;

  // Since many properties need to be parsed but they have the same structure, we can do this:
  const updateProperties = (item) => ({
    ...item,
    estimatedDeliveryTime: parseOfferDate(item.estimatedDeliveryTime),
    cutOffDateTime: parseOfferDate(item.cutOffDateTime),
    pickUpDate: parseOfferDate(item.pickUpDate),
  });

  const sortedUpdatedOffers = sortedOffers(
    updatedOffers,
    state.sortOffersBy || DEFAULT_OFFER_SORT_ORDER
  ).map((offer) => {
    if (offer.type === TYPES_OF_OFFERS.AGGREGATED) {
      const updatedOfferOptions = offer.offerOptions.map(updateProperties);

      // Single BU logic
      if (offer.metadata.availablePurchaseMethods) {
        const output = {
          ...offer,
          offerOptions: updatedOfferOptions,
          metadata: {
            ...offer.metadata,
            metadataByPurchaseMethod: {
              ...offer.metadata.metadataByPurchaseMethod,
              WALK: {
                ...offer.metadata.metadataByPurchaseMethod.WALK,
                availableDeliveryTimes: offer.metadata.metadataByPurchaseMethod.WALK?.availableDeliveryTimes.map(
                  updateProperties
                ),
              },
              CLICK: {
                ...offer.metadata.metadataByPurchaseMethod.CLICK,
                availableDeliveryTimes: offer.metadata.metadataByPurchaseMethod.CLICK?.availableDeliveryTimes.map(
                  updateProperties
                ),
              },
              CALL: {
                ...offer.metadata.metadataByPurchaseMethod.CALL,
                availableDeliveryTimes: offer.metadata.metadataByPurchaseMethod.CALL?.availableDeliveryTimes.map(
                  updateProperties
                ),
              },
            },
            availableDeliveryTimes: Object.keys(
              offer.metadata?.metadataByPurchaseMethod
            ).map((method) => ({
              [method]: offer.metadata?.metadataByPurchaseMethod[
                method
              ].availableDeliveryTimes.map(updateProperties),
            })),
            fastestOffer: {
              ...offer.metadata?.fastestOffer,
              estimatedDeliveryTime: parseOfferDate(
                offer.metadata?.fastestOffer?.estimatedDeliveryTime
              ),
            },
          },
        };

        return output;
      }

      return {
        ...offer,
        offerOptions: updatedOfferOptions,
        metadata: {
          ...offer.metadata,
          availableDeliveryTimes: offer.metadata?.availableDeliveryTimes.map(
            updateProperties
          ),
          fastestOffer: {
            ...offer.metadata?.fastestOffer,
            estimatedDeliveryTime: parseOfferDate(
              offer.metadata?.fastestOffer?.estimatedDeliveryTime
            ),
          },
        },
      };
    }

    return updateProperties(offer);
  });

  return {
    ...state,
    waitingForResponse: false,
    isSortOffersByVisible: enableSortOffersByDropdown(originalOffers),
    haveTheBackendsErrors: makeBackendsErrorSelection(data.backends)(),
    offers: sortedUpdatedOffers,
    staticOffers,
    backends: data.backends,
    metadata: data.metadata,
    options: data.options,
    isShippingDateVisible: !!shippingDateOption,
    shippingDate: shippingDateOption?.value || "",
  };
};

const getOffersRequestFailed = (state, { payload: data }) => ({
  ...state,
  waitingForResponse: false,
  networkError: {
    message: data.error.message,
    code: data.error.response ? data.error.response.status : "",
    backendError: data.error.response ? data.error.response.data[0] : "",
  },
  isThereANetworkError: true,
});

const updateShippingDate = (state, { payload: { shippingDate } }) => ({
  ...state,
  shippingDate,
});

const updateOfferSortOrder = (state, { payload: { sortOffersBy } }) => {
  return {
    ...state,
    sortOffersBy,
    offers: sortedOffers(state.offers, sortOffersBy),
  };
};

const localShipmentDetected = (state, { payload: { value } }) => ({
  ...state,
  localShipmentDetected: value,
});

export default handleActions(
  {
    [getOffersRequestSentAction]: getOffersRequestSent,
    [getOffersRequestSucceededAction]: getOffersRequestSucceeded,
    [getOffersRequestFailedAction]: getOffersRequestFailed,
    [updateShippingDateAction]: updateShippingDate,
    [resetQuoteResultsAction]: resetQuoteResults,
    [sortOffersAction]: updateOfferSortOrder,
    [localShipmentDetectedAction]: localShipmentDetected,
  },
  initialState
);
