// 9fbef606107a605d69c0edbcd8029e5d

import { memo } from "react";
import { connect } from "react-redux";
import { Tracking } from "root/libs/ui-containers/src";
import { selectTrackingKey } from "one-time-shipment/src/containers/Tracking/selectors";
import Modal from "./index";

const {
  actions: { trackEvent },
} = Tracking;

const TRACKING_KEY =
  "spotShipment.freightP2PModal.freightP2PModalSubmitSuccess";

const mapStateToProps = (state) => ({
  validity: state.offersLane.validity,
  formFields: state.offersLane.freightP2PModal,
  trackingKeyPaths: {
    submitButton: selectTrackingKey(TRACKING_KEY),
  },
});

const mapDispatchToProps = { trackEvent };

export default connect(mapStateToProps, mapDispatchToProps)(memo(Modal));
