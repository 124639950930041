// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for TeaserOverlay
 *
 */

import styled from "styled-components";
import { Headline } from "root/libs/ui-components/src";
import { Text } from "@dhl-official/react-ui-library";
import { breakpoints, brandPalette } from "root/libs/ui-styleguide/src";

export const TeaserOverlayWrapper = styled.div`
  background: linear-gradient(to left, #ffe800, ${brandPalette.postYellow});
  position: fixed;
  transform: translateY(100%);
  bottom: 0;
  left: 0;
  width: 100%;
  transition: transform 0.2s ease-out;
  z-index: 999;

  &.active {
    transform: translateY(0);
  }
`;

export const ContentWrapper = styled.div`
  padding: 35px;
  margin: 0 auto;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: 34px 20px;
    display: flex;
  }
`;

export const ContentWrapperCopy = styled.div`
  margin-bottom: 20px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: 0;
    padding-right: 30px;
    width: 100%;
  }
`;

export const ContentWrapperButton = styled.div`
  text-align: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    text-align: right;
  }

  @media screen and (min-width: ${breakpoints.tablet}) and (max-width: 1070px) {
    padding-right: 40px;
  }

  button {
    width: auto;
  }
`;

export const StyledIcon = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`;

export const StyledHeadline = styled(Headline)`
  text-align: center;
  padding: 0 40px;
  margin-bottom: 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    text-align: left;
    padding: 0;
  }
`;

export const StyledText = styled(Text)`
  text-align: center;
  margin-bottom: 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    text-align: left;
  }
`;
