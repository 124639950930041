// 9fbef606107a605d69c0edbcd8029e5d

import { handleActions } from "redux-actions";
import produce from "immer";
import { performanceTrackingContract } from "root/libs/core-libs/src";
import { trackEvent as trackEventAction } from "./actions";

const initialState = {
  trackingData: {
    performance: performanceTrackingContract.v2,
  },
};

const trackEvent = (state, { payload }) => {
  const {
    uiMetrics: newUiMetrics = {},
    memoryMetrics,
    mediaResource,
    apiResource,
    otherResource,
    ...rest
  } = payload;

  return produce(state, (draftState) => {
    let { trackingData } = draftState;
    const { performance } = trackingData;
    const {
      uiMetrics: oldUiMetrics,
      mediaResourceList = [],
      apiResourceList = [],
      otherResourceList = [],
    } = performance;

    if (rest) {
      trackingData = Object.assign(trackingData, rest);
    }

    if (newUiMetrics) {
      performance.uiMetrics = Object.assign(oldUiMetrics, newUiMetrics);
    }

    if (memoryMetrics) {
      performance.memoryMetrics = memoryMetrics;
    }

    if (mediaResource) {
      mediaResourceList.push(mediaResource);
    }

    if (apiResource) {
      apiResourceList.push(apiResource);
    }

    if (otherResource) {
      otherResourceList.push(otherResource);
    }
  });
};

export default handleActions(
  {
    [trackEventAction]: trackEvent,
  },
  initialState
);
