// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * ModalSaveInformation
 *
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Text, Button, BUTTON_VARIANTS } from "@dhl-official/react-ui-library";
import { injectIntl } from "react-intl";
import styled from "styled-components";
import { trackContentInteraction } from "root/libs/core-libs/src";
import { SEGMENT_TYPES } from "one-time-shipment/src/containers/SpotShipmentPage/constants";
import {
  UTF_EVENT_CONTENT_NAMES,
  UTF_EVENT_TYPES,
  UTF_EVENT_INTERACTIONS,
  UTF_EVENT_CONTENT_POSITION,
} from "root/libs/constants/src";
import { ContentModal, Headline } from "root/libs/ui-components/src";

const CopyText = styled(Text)`
  margin-bottom: 20px;
  margin-top: 10px;
`;

const CTAWrapper = styled.div`
  > button:first-child {
    margin-right: 20px;
  }
`;

const Wrapper = styled.div``;

const COPY_ID_PREFIX = "ModalSaveInformation";
const ModalSaveInformation = (props) => {
  const { intl, trackEvent, onSave, onClose, isBusiness } = props;

  useEffect(() => {
    trackEvent({ saveInformationModalTriggered: true });
  }, []);

  const handleUtf = (attr) => {
    trackContentInteraction({
      name: UTF_EVENT_CONTENT_NAMES.SAVE_SHIPMENT,
      type: UTF_EVENT_TYPES.MODAL,
      interaction: UTF_EVENT_INTERACTIONS.CLICK,
      position: UTF_EVENT_CONTENT_POSITION.PRODUCT_LANE,
      context: isBusiness ? SEGMENT_TYPES.business : SEGMENT_TYPES.private,
      attributes: attr,
    });
  };

  const onAcceptedClick = () => {
    trackEvent({ clickedOnSaveInformationAccepted: true });
    handleUtf({ save_shipment: "yes" });
    onSave();
  };

  const onCanceledClick = () => {
    trackEvent({ clickedOnSaveInformationCancel: true });
    handleUtf({ save_shipment: "no" });
    onClose();
  };

  const getCopy = (id, values, prefix = COPY_ID_PREFIX) => {
    return intl.formatMessage({ id: `${prefix}.${id}` }, values);
  };

  return (
    <ContentModal
      {...props}
      smallCenteredModal
      shouldShowCloseButton={false}
      closeButtonAriaLabel={intl.formatMessage({ id: "SS.ContentModal.close" })}
      contentAriaLabel={getCopy("description")}
    >
      <Wrapper data-testid="save-information-modal">
        <Headline priority={2}>{getCopy("headline")}</Headline>
        <CopyText isParagraph>{getCopy("description")}</CopyText>
        <CTAWrapper>
          <Button
            type="button"
            onClick={onAcceptedClick}
            dataTestid="save-information-modal-save-btn"
          >
            {getCopy("primaryButton")}
          </Button>
          <Button
            type="button"
            dataTestid="save-information-modal-cancel-btn"
            variant={BUTTON_VARIANTS.SECONDARY}
            onClick={onCanceledClick}
          >
            {getCopy("secondaryButton")}
          </Button>
        </CTAWrapper>
      </Wrapper>
    </ContentModal>
  );
};

ModalSaveInformation.propTypes = {
  intl: PropTypes.any.isRequired,
  trackEvent: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  isBusiness: PropTypes.bool,
  onSave: PropTypes.func,
};

ModalSaveInformation.defaultProps = {
  isOpen: false,
};

export default injectIntl(ModalSaveInformation);
