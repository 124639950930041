// 9fbef606107a605d69c0edbcd8029e5d

import { createSelector } from "reselect";
import { RemoteData } from "root/libs/core-libs/src";

/**
 * Direct selector to the languageToggle state domain
 */
const selectLocalization = (state) => state.localization;

/**
 * Select the language locale
 */

const makeSelectLocale = () =>
  createSelector(selectLocalization, (localization) => localization.locale);

const selectCountry = (state) => state.localization.country;

const selectMessages = createSelector(selectLocalization, (localization) => {
  const { messages } = localization;

  if (messages.remote[localization.locale]) {
    return messages.remote[localization.locale];
  }

  return RemoteData.notAsked();
});

const selectLanguage = (state) => state.localization.language;

export {
  makeSelectLocale,
  selectCountry,
  selectLanguage,
  selectLocalization,
  selectMessages,
};
