// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * TotalSumIndicator
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { TEXT_SIZES } from "@dhl-official/react-ui-library";
import checkIcon from "icons/cx/check.svg";
import { Icon } from "root/libs/ui-components/src";
import {
  IndicatorWrapper,
  IconWrapper,
  StyledTextWrapper,
  StyledText,
} from "./styled";

const TotalSumIndicator = ({
  className,
  indicatorLabel,
  weightUnit,
  quantityValue,
  hasError,
  isWeightValid,
  dataTestid,
  id,
  totalSum,
}) => {
  const isVisible =
    !hasError && !!(quantityValue > 1) && totalSum > 0 && isWeightValid;
  return (
    <IndicatorWrapper
      isVisible={isVisible}
      hasError={hasError}
      className={className}
      data-testid={dataTestid}
      data-testvalue={totalSum}
    >
      <IconWrapper>
        <Icon
          icon={checkIcon}
          useAs="decorative"
          width={{ desktop: "10px", mobile: "10px" }}
          height={{ desktop: "10px", mobile: "10px" }}
        />
      </IconWrapper>
      <StyledTextWrapper isParagraph id={id}>
        <StyledText size={TEXT_SIZES.TINY}>{`${indicatorLabel} `}</StyledText>
        <StyledText size={TEXT_SIZES.TINY}>
          {`${totalSum} ${weightUnit}`}
        </StyledText>
      </StyledTextWrapper>
    </IndicatorWrapper>
  );
};

TotalSumIndicator.propTypes = {
  className: PropTypes.string,
  indicatorLabel: PropTypes.string.isRequired,
  quantityValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  weightUnit: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  /** This is an identifier for automation */
  dataTestid: PropTypes.string,
  id: PropTypes.string,
  totalSum: PropTypes.number,
  isWeightValid: PropTypes.bool,
};

TotalSumIndicator.defaultProps = {
  className: "",
  quantityValue: 0,
  hasError: false,
  dataTestid: undefined,
  id: undefined,
  totalSum: 0,
  isWeightValid: false,
};

export default TotalSumIndicator;
