// 9fbef606107a605d69c0edbcd8029e5d

import React from "react";
import PropTypes from "prop-types";
import { injectIntl, FormattedMessage } from "react-intl";
import { TEXT_SIZES, TEXT_WEIGHTS } from "@dhl-official/react-ui-library";
import Link from "../../Buttons/Link";
import { StyledText } from "./styled";

class FeedbackMessage extends React.PureComponent {
  static propTypes = {
    intl: PropTypes.any.isRequired,
    /** Unique ID for error messages. This is needed to link the error message via aria-describedby to the corresponding input field */
    id: PropTypes.string.isRequired,
    /** Class that allows us to extend styles of this component in a wrapper component */
    className: PropTypes.string,
    /** Object containing the content of the feedback to show */
    feedback: PropTypes.shape({
      name: PropTypes.string,
      hasError: PropTypes.bool,
      isValid: PropTypes.bool,
      feedbackMessageId: PropTypes.string,
    }).isRequired,
    /** Prefix to be concatenated before the feedbackMessageId */
    feedbackMessageIdPrefix: PropTypes.string,
    /** An object containing the key:value strings to be interpolated in the message. */
    interpolations: PropTypes.object,
    role: PropTypes.string,
    useLink: PropTypes.bool,
    /** If your message will be renderd as a link, you must provide an explanation for screen reader users to know the link will be available for them. */
    linkFeedbackMessageId: PropTypes.string,
    linkSettings: PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.string,
    }),
    onLinkClick: PropTypes.func,
    customFeedbackMessage: PropTypes.string,
    feedbackTrackingKeyPath: PropTypes.string,
    variation: PropTypes.oneOf(["error", "warning", "info", "hint"]),
    /** Boolean to center-align the message */
    centered: PropTypes.bool,
    /** Additional key to look up and display specific message variations */
    copyNamespace: PropTypes.string.isRequired,
    dataTestid: PropTypes.string,
  };

  static defaultProps = {
    className: "",
    interpolations: {},
    feedbackTrackingKeyPath: "",
    linkFeedbackMessageId: "",
    variation: "error",
    role: undefined,
    useLink: false,
    linkSettings: { value: "", title: "" },
    customFeedbackMessage: "",
    feedbackMessageIdPrefix: "",
    onLinkClick: () => {},
    centered: false,
    dataTestid: undefined,
  };

  getFormattedMessage = () => {
    const {
      intl,
      feedback,
      interpolations: values,
      feedbackMessageIdPrefix,
      copyNamespace,
    } = this.props;

    const { feedbackMessageId } = feedback;
    const feedbackMessage = `${copyNamespace}.validations.${feedbackMessageIdPrefix}${feedbackMessageId}`;

    return intl.formatMessage(
      { id: feedbackMessage, defaultMessage: feedbackMessage },
      values
    );
  };

  render() {
    const {
      id,
      dataTestid,
      customFeedbackMessage,
      feedback,
      useLink,
      role,
      linkSettings,
      feedbackTrackingKeyPath,
      variation,
      className,
      centered,
      onLinkClick,
      linkFeedbackMessageId,
      interpolations: values,
    } = this.props;

    const { feedbackMessageId } = feedback;

    const feedbackMessage = feedbackMessageId
      ? this.getFormattedMessage()
      : customFeedbackMessage;
    const renderLink = !!linkSettings && !!linkSettings.value && useLink;

    return (
      <React.Fragment>
        {renderLink && (
          <React.Fragment>
            <span
              role={role}
              id={`${variation}Message_${id}`}
              className="visually-hidden"
            >
              <FormattedMessage
                id={linkFeedbackMessageId}
                defaultMessage={linkFeedbackMessageId}
                values={values}
              />
            </span>
            <Link
              onClick={onLinkClick}
              href={linkSettings.value}
              target="_blank"
              dataTracking={feedbackTrackingKeyPath}
              centered={centered}
            >
              <StyledText
                dataTestid={
                  dataTestid ? `${variation}-${dataTestid}` : undefined
                }
                className={className}
                size={TEXT_SIZES.TINY}
                variation={variation}
                weight={TEXT_WEIGHTS[800]}
                underline
              >
                {feedbackMessage}
              </StyledText>
            </Link>
          </React.Fragment>
        )}
        {!renderLink && (
          <StyledText
            role={role}
            id={`${variation}Message_${id}`}
            dataTestid={dataTestid ? `${variation}-${dataTestid}` : undefined}
            className={className}
            variation={variation}
            size={TEXT_SIZES.TINY}
            isParagraph
          >
            {feedbackMessage}
          </StyledText>
        )}
      </React.Fragment>
    );
  }
}

export default injectIntl(FeedbackMessage);
