// 9fbef606107a605d69c0edbcd8029e5d

import styled from "styled-components";
import { breakpoints } from "root/libs/ui-styleguide/src";
import { key } from "styled-theme";
import { Text } from "@dhl-official/react-ui-library";

export const StyledSettingsComponent = styled.div`
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 20px;

  @media print {
    display: none;
  }
`;

export const SettingsContainerWrapper = styled.div`
  justify-self: left;
  display: flex;
  flex-direction: column;
  border: 1px solid ${key("colors.globalBorderColor")};
  padding: 10px;
  background-color: ${key("colors.backgroundColorInverted")};

  @media screen and (min-width: ${breakpoints.desktop}) {
    flex-direction: row;
    max-width: fit-content;
  }
`;

export const SettingsCol = styled.div`
  margin-bottom: 20px;

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-right: 20px;
    margin-bottom: 0;
  }

  legend {
    font-weight: bold;
  }
`;

export const StyledText = styled(Text)`
  word-break: break-all;
`;
