// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * RtlSupport
 */

import { connect } from "react-redux";
import RtlWrapper from "./RtlWrapper";

const mapStateToProps = (state) => ({
  enabled: state.rtlSupport.enabled,
});

export default connect(mapStateToProps)(RtlWrapper);
