// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * OfferCard
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { IntlPropType, getUtfAddOnString } from "root/libs/core-libs/src";
import {
  UTF_EVENT_CONTENT_NAMES,
  UTF_EVENT_TYPES,
  UTF_EVENT_INTERACTIONS,
  UTF_EVENT_CONTENT_POSITION,
  UTF_EVENT_CONVERSION_NAMES,
  UTF_EVENT_CONVERSION_TYPES,
  UTF_EVENT_CONVERSION_DETAIL,
} from "root/libs/constants/src";
import { Text, TEXT_SIZES } from "@dhl-official/react-ui-library";
import { SEGMENT_TYPES } from "one-time-shipment/src/containers/SpotShipmentPage/constants";
import { CountrySettings } from "root/libs/ui-containers/src";
import { IconButtonTooltip } from "root/libs/ui-components/src";
import iconCollapse from "icons/navigation/collapse.svg";
import iconExpand from "icons/navigation/expand.svg";
import WCCOfferPriceAndPaymentInfo from "one-time-shipment/src/components/Offers/WCCOfferPriceAndPaymentInfo";
import AdditionalInformation from "one-time-shipment/src/components/Offers/AdditionalInformation";
import WCCOfferCardHeader from "one-time-shipment/src/components/Offers/WCCOfferCardHeader";
import WCCOfferCardInformation from "one-time-shipment/src/components/Offers/WCCOfferCardInformation";
import QuoteValidity from "one-time-shipment/src/components/Offers/QuoteValidity";
import OfferAddOns from "one-time-shipment/src/components/Offers/OfferAddOns";
import OfferProperties from "one-time-shipment/src/components/Offers/OfferProperties";
import OfferCardDropdown from "one-time-shipment/src/components/Offers/OfferCardDropdown";
import OfferPromotedLine from "one-time-shipment/src/components/Offers/OfferPromotedLine";
import WCCOfferDeliveryInfo from "one-time-shipment/src/components/Offers/WCCOfferDeliveryInfo";
import WCCOfferCardCTA from "one-time-shipment/src/components/Offers/WCCOfferCardCTA";
import {
  StyledOfferCard,
  StyledPriceContainer,
  StyledContainer,
  StyledCTAWrapper,
  StyledExpandCollapseOffer,
  StyledAdditionalOfferDetails,
  StyledContainerGridArea,
  StyledLine,
  StyledTextAndTooltip,
  StyledEstimatedDeliveryText,
  StyledEstimatedDeliveryInfo,
} from "./styled";
import VectorBackground from "./VectorBackground";

const { withCountry } = CountrySettings;

const COPY_ID_PREFIX = "WCCOfferCard";

export default class WCCOfferCard extends React.PureComponent {
  static propTypes = {
    /** Internationalization library */
    intl: IntlPropType.isRequired,
    purchaseMethod: PropTypes.string,
    isBusiness: PropTypes.bool,
    offerIndex: PropTypes.number,
    offer: PropTypes.object,
    isHighlighted: PropTypes.bool,
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
    nativeLanguageCode: PropTypes.string,
    is12HourTimeFormat: PropTypes.bool,
    nativeDateTimeFormat: PropTypes.string,
    isExpanded: PropTypes.bool,
    expandedCardIndex: PropTypes.number,
    handleExpandCard: PropTypes.func,
    indexLength: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.WCCOfferPriceAndPaymentInfoWithCountry = withCountry(
      WCCOfferPriceAndPaymentInfo
    );
    this.OfferPropertiesWithCountry = withCountry(OfferProperties);
    this.OfferAddOnsWithCountry = withCountry(OfferAddOns);
    this.OfferPromotedLineWithCountry = withCountry(OfferPromotedLine);
    this.OfferCardDropdownWithCountry = withCountry(OfferCardDropdown);
    const initialSelectedOfferOptionId =
      props?.offer?.metadata?.metadataByPurchaseMethod[props.purchaseMethod]
        .cheapestOffer?.id || null;
    this.state = {
      // Selected OfferOption - AGGREGATED offers
      selectedOfferOptionId: initialSelectedOfferOptionId,
    };
    this.wccOfferCardRef = React.createRef();
  }

  getCopy = (id) => {
    const { intl } = this.props;
    return intl.formatMessage(
      { id },
      {
        br: <br key="br" />,
      }
    );
  };

  getSelectedOfferOption = () => {
    const { selectedOfferOptionId } = this.state;
    const {
      offer: { offerOptions },
    } = this.props;
    return (
      offerOptions.find(
        (offerOption) => offerOption.id === selectedOfferOptionId
      ) || null
    );
  };

  handleDeliveryTimeChange = (offerId) => {
    this.setState({ selectedOfferOptionId: offerId });
  };

  getPromotedProperty = (displayOffer) => {
    if (Array.isArray(displayOffer.properties)) {
      return displayOffer.properties
        .filter((property) => property.promoted === true)
        .pop();
    }
    return undefined;
  };

  showAdditionalInformation = () => {
    const displayOffer = this.getSelectedOfferOption();

    return (
      Array.isArray(displayOffer.additionalInformation) &&
      displayOffer.additionalInformation.length > 0
    );
  };

  showECSInfoMessage = () => {
    const displayOffer = this.getSelectedOfferOption();

    const { information } = displayOffer;

    return (
      information &&
      information.find((element) => element.category === "INFO") &&
      information.find(
        (element) => element.header !== "PALLET_REQUIRED_INFORMATION"
      )
    );
  };

  render() {
    const {
      intl,
      purchaseMethod,
      isBusiness,
      offerIndex,
      offer: { metadata },
      isHighlighted,
      isFirst,
      isLast,
      nativeLanguageCode,
      is12HourTimeFormat,
      nativeDateTimeFormat,
      isExpanded,
      expandedCardIndex,
      handleExpandCard,
      indexLength,
    } = this.props;
    const displayOffer = this.getSelectedOfferOption();
    const promotedProperty = this.getPromotedProperty(displayOffer);
    const additionalInfoECSMessage = this.showECSInfoMessage();
    const ariaLabelledBy = `wcc-offer-card-headline-${offerIndex} wcc-offer-card-sub-headline-${offerIndex} ${displayOffer.key}-price ${displayOffer.key}-starBehindPrice ${displayOffer.key}-payment wcc-offer-card-wrapper-more-information`;

    let finalAdditionalInfoList =
      Array.isArray(displayOffer.additionalInformation) &&
      displayOffer.additionalInformation.length > 0
        ? displayOffer.additionalInformation
        : [];

    if (additionalInfoECSMessage) {
      finalAdditionalInfoList = [
        ...finalAdditionalInfoList,
        ...[{ key: additionalInfoECSMessage.message }],
      ];
    }

    const shippingModesString = getUtfAddOnString(displayOffer.addOns);

    const deliveryInfoExists = !!intl.messages[
      `${COPY_ID_PREFIX}.${purchaseMethod}_deliveryInfo`
    ];

    return (
      <StyledOfferCard
        key={`offer-${purchaseMethod}`}
        className={`${isHighlighted ? "highlighted" : ""}`}
        isFirst={isFirst}
        isLast={isLast}
        isExpanded={isExpanded}
        expandedCardIndex={expandedCardIndex}
        offerIndex={offerIndex}
        iAmExpanded={offerIndex === expandedCardIndex}
        data-testid={`wcc-offer-card-${offerIndex}`}
        aria-label={intl.formatMessage(
          { id: `${COPY_ID_PREFIX}.screenReaderLabel_offersAmount` },
          {
            currentOfferIndex: offerIndex + 1,
            totalOffersAmount: indexLength,
          }
        )}
        tabIndex={0}
        aria-labelledby={ariaLabelledBy}
        ref={this.wccOfferCardRef}
      >
        <WCCOfferCardHeader
          isFirst={isFirst}
          isLast={isLast}
          intl={intl}
          purchaseMethod={purchaseMethod}
          isHighlighted={isHighlighted}
          expandedCardIndex={expandedCardIndex}
          offerIndex={offerIndex}
        />
        <StyledContainerGridArea
          iAmExpanded={offerIndex === expandedCardIndex}
          expandedCardIndex={expandedCardIndex}
        >
          {isHighlighted && <VectorBackground />}
          <StyledPriceContainer
            isHighlighted={isHighlighted}
            isFirst={isFirst}
            isLast={isLast}
            expandedCardIndex={expandedCardIndex}
            iAmExpanded={offerIndex === expandedCardIndex}
          >
            <this.WCCOfferPriceAndPaymentInfoWithCountry
              key={displayOffer.id}
              offerIndex={offerIndex}
              intl={intl}
              offer={displayOffer}
              isBusiness={isBusiness}
              showCurrencyIsoCodeForPrices={false}
              iNeedTheseProps={[
                "formatCurrencyWithNativeLocale",
                "isExpressAllowedOnMobile",
              ]}
              nativeLanguageCode={nativeLanguageCode}
            />
          </StyledPriceContainer>
          <StyledLine
            isHighlighted={isHighlighted}
            isLast={isLast}
            isFirst={isFirst}
            expandedCardIndex={expandedCardIndex}
            iAmExpanded={offerIndex === expandedCardIndex}
          />
          <StyledEstimatedDeliveryText
            isLast={isLast}
            isFirst={isFirst}
            isHighlighted={isHighlighted}
            expandedCardIndex={expandedCardIndex}
            size={TEXT_SIZES.SMALLPLUS}
            iAmExpanded={offerIndex === expandedCardIndex}
            id={`${displayOffer.key}-firstline`}
            isParagraph
            dataTestid={`wcc-offer-card-estimated-delivery-text-${offerIndex}`}
          >
            {this.getCopy(
              displayOffer.deliveryType === "COMMITTED"
                ? "CardAndSummary.committedDelivery"
                : "CardAndSummary.estimatedDelivery"
            )}
          </StyledEstimatedDeliveryText>
          <StyledContainer
            isFirst={isFirst}
            isLast={isLast}
            expandedCardIndex={expandedCardIndex}
            iAmExpanded={offerIndex === expandedCardIndex}
          >
            <WCCOfferDeliveryInfo
              intl={intl}
              displayOffer={displayOffer}
              offerIndex={offerIndex}
              estimatedDeliveryTime={displayOffer.estimatedDeliveryTime}
              nativeDateTimeFormat={nativeDateTimeFormat}
              estimatedDeliveryTimeDisplayType={
                displayOffer.estimatedDeliveryTimeDisplayType
              }
              is12HourTimeFormat={is12HourTimeFormat}
              nativeLanguageCode={nativeLanguageCode}
              metadataByPurchaseMethod={
                metadata?.metadataByPurchaseMethod[purchaseMethod]
              }
              offerId={displayOffer.id}
            />
          </StyledContainer>
          {deliveryInfoExists && (
            <StyledEstimatedDeliveryInfo
              isLast={isLast}
              isFirst={isFirst}
              isHighlighted={isHighlighted}
              expandedCardIndex={expandedCardIndex}
              iAmExpanded={offerIndex === expandedCardIndex}
              id={`${displayOffer.key}-delivery-info`}
              isParagraph
              dataTestid={`wcc-offer-card-estimated-delivery-info-${offerIndex}`}
              size={TEXT_SIZES.TINY}
            >
              {this.getCopy(`${COPY_ID_PREFIX}.${purchaseMethod}_deliveryInfo`)}
            </StyledEstimatedDeliveryInfo>
          )}
          <StyledCTAWrapper
            isFirst={isFirst}
            isLast={isLast}
            expandedCardIndex={expandedCardIndex}
            iAmExpanded={offerIndex === expandedCardIndex}
          >
            <WCCOfferCardCTA
              displayOffer={displayOffer}
              utfDataObject={{
                conversion: {
                  name: UTF_EVENT_CONVERSION_NAMES.BOOKING_INTENT,
                  type: UTF_EVENT_CONVERSION_TYPES.HANDOVER,
                  detail: UTF_EVENT_CONVERSION_DETAIL[purchaseMethod],
                },
                products: [{
                  id: displayOffer.id,
                  key: displayOffer.key,
                  businessUnit: displayOffer.businessUnit,
                  price: displayOffer?.price?.amount,
                  currency: displayOffer?.price?.currencyCode,
                  shippingModes: shippingModesString,
                },],
                content: {
                  name: UTF_EVENT_CONTENT_NAMES.CONTINUE_TO_BOOKING,
                  type: UTF_EVENT_TYPES.BUTTON,
                  interaction: UTF_EVENT_INTERACTIONS.CLICK,
                  position: UTF_EVENT_CONTENT_POSITION.PRODUCT_CARD,
                  context: isBusiness
                    ? SEGMENT_TYPES.business
                    : SEGMENT_TYPES.private,
                },
              }}
              {...this.props}
            />
            {purchaseMethod === "WALK" && (
              <WCCOfferCardInformation
                id={`${displayOffer.key}-wcc-information`}
                offerKey={displayOffer.key}
                businessUnit={displayOffer.businessUnit}
                purchaseMethod={purchaseMethod}
                intl={intl}
                offerIndex={displayOffer.key}
                locatorLink={displayOffer.locatorLink}
                isBusiness={isBusiness}
                copyPrefix="WCCOfferCardInformation"
                multiBu={false}
              />
            )}
            <this.OfferPromotedLineWithCountry
              offerKey={displayOffer.key}
              cutOffDateTime={displayOffer.cutOffDateTime}
              intl={intl}
              offerIndex={offerIndex}
              promotedProperty={promotedProperty}
              pickUpDate={displayOffer.pickUpDate}
              iNeedTheseProps={[
                "is12HourTimeFormat",
                "nativeLanguageCode",
                "nativeDateTimeFormat",
              ]}
            />
          </StyledCTAWrapper>
        </StyledContainerGridArea>
        {isExpanded && (
          <StyledAdditionalOfferDetails>
            <this.OfferPropertiesWithCountry
              properties={displayOffer.properties}
              offerId={displayOffer.key}
              estimatedDeliveryTime={displayOffer.estimatedDeliveryTime}
              intl={intl}
              iNeedTheseProps={["is12HourTimeFormat"]}
              variationType="WCCOfferCard"
            />
            <this.OfferAddOnsWithCountry
              trackEvent={() => {}}
              addOns={displayOffer.addOns}
              offerKey={displayOffer.key}
              intl={intl}
              iNeedTheseProps={[
                "nativeLanguageCode",
                "formatCurrencyWithNativeLocale",
              ]}
              variationType="WCCOfferCard"
            />
            {(this.showAdditionalInformation() || additionalInfoECSMessage) && (
              <AdditionalInformation
                intl={intl}
                additionalInformationList={finalAdditionalInfoList}
              />
            )}
            <StyledTextAndTooltip>
              <Text
                dataTestid={`wcc-offer-card-text-and-tooltip-${offerIndex}`}
              >
                {this.getCopy(
                  `${COPY_ID_PREFIX}.${purchaseMethod}_parcelSendingInstructionText`
                )}
              </Text>
              <IconButtonTooltip
                isFirst={isFirst}
                expandedCardIndex={expandedCardIndex}
                id={`wcc-offer-card-text-and-tooltip-${offerIndex}`}
                ariaLabel={this.getCopy(
                  `${COPY_ID_PREFIX}.${purchaseMethod}_parcelSendingInstructionText`
                )}
                name={`wcc-offer-card-text-and-tooltip-${offerIndex}`}
                iconType="info"
                tooltipContent={
                  <Text size={TEXT_SIZES.TINY}>
                    {this.getCopy(
                      `${COPY_ID_PREFIX}.${purchaseMethod}_parcelSendingTooltip`
                    )}
                  </Text>
                }
              />
            </StyledTextAndTooltip>
            <QuoteValidity
              businessUnit={displayOffer.businessUnit}
              variationType="WCCOfferCard"
              noMargin
            />
          </StyledAdditionalOfferDetails>
        )}
        <StyledExpandCollapseOffer
          dataTestid={`wcc-offer-card-expand-collapse-btn-${offerIndex}`}
          icon={expandedCardIndex === offerIndex ? iconCollapse : iconExpand}
          isFirst={isFirst}
          expandedCardIndex={expandedCardIndex}
          isExpanded={isExpanded}
          buttonSize={{
            width: { mobile: "30px", desktop: "30px" },
            height: { mobile: "30px", desktop: "30px" },
          }}
          ariaLabel={intl.formatMessage({
            id: `${COPY_ID_PREFIX}.screenReaderLabel_expandCollapse`,
          })}
          iconSize="intermediate"
          type="button"
          name={`wcc-offer-card-expand-collapse-btn-${offerIndex}`}
          onClick={() => handleExpandCard(offerIndex, this.wccOfferCardRef)}
        />
      </StyledOfferCard>
    );
  }
}
