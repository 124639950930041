// 9fbef606107a605d69c0edbcd8029e5d

export const ENABLE_CANARY_VERSION =
  "shared/CanaryVersions/ENABLE_CANARY_VERSION";

export const TYPES_OF_CANARY_VERSIONS = {
  canary1: "canary1",
  canary2: "canary2",
  canary3: "canary3",
  tara: "tara",
  wccoffers: "wccoffers",
  strikethrough: "strikethrough",
};
