// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * RtlWrapper
 */

import React from "react";
import PropTypes from "prop-types";
import { StyleSheetManager } from "styled-components";
import rtlPlugin from "stylis-plugin-rtl";

export default class RtlWrapper extends React.PureComponent {
  static propTypes = {
    children: PropTypes.element.isRequired,
    enabled: PropTypes.bool.isRequired,
  };

  render() {
    const { enabled, children } = this.props;

    return (
      <StyleSheetManager stylisPlugins={enabled ? [rtlPlugin] : []}>
        {React.Children.only(children)}
      </StyleSheetManager>
    );
  }
}
