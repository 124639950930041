// 9fbef606107a605d69c0edbcd8029e5d

import prop from "lodash/fp/prop";
import { createSelector } from "reselect";

const personalizationSelector = (state) => state.personalization;

export const selectIsRegularUser = (state) =>
  state.personalization.isRegularUser;

export const selectStoreInitialSetAction = createSelector(
  personalizationSelector,
  prop("storeInitialSetAction")
);

const makeStoreInitialSetSelector = () =>
  createSelector(personalizationSelector, (personalization) => ({
    action: personalization.storeInitialSetAction,
    analyticsUserId: personalization.analyticsUserId,
    wannaRunClientStorageAction: personalization.wannaRunClientStorageAction,
  }));

const selectFunnel = createSelector(
  personalizationSelector,
  (personalization) => personalization.funnel
);

export { personalizationSelector, makeStoreInitialSetSelector, selectFunnel };
