// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * OfferCardDropdown
 *
 */

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { DropdownField, Option } from "root/libs/ui-components/src";
import DeliveryTime from "one-time-shipment/src/components/Offers/DeliveryTime";
import { TEXT_SIZES } from "@dhl-official/react-ui-library";
import { DateTimeWrapper, EstimatedDeliveryText } from "./styled";

const COPY_ID_PREFIX = "CardAndSummary";

const OfferCardDropdown = ({
  ariaDescribe,
  ariaDescribedBy,
  availableDeliveryTimes,
  selectedOffer,
  onChange,
  dataTestid,
  label,
  ariaLabel,
  id: elemId,
  name,
  nativeLanguageCode,
  is12HourTimeFormat,
  nativeDateTimeFormat,
  intl,
  offerKey,
  variation,
}) => {
  const getCopy = (id, values, prefix = COPY_ID_PREFIX) => {
    return intl.formatMessage({ id: `${prefix}.${id}` }, values);
  };

  const handleTimeChange = (e) => {
    const newSelectedTime = e.target.value;
    onChange(newSelectedTime);
  };

  const selectedOfferOption = availableDeliveryTimes.find(
    (offer) => offer.id === selectedOffer
  );

  const DateAndTime = ({
    estimatedDeliveryTimeDisplayType,
    estimatedDeliveryTime,
    wrapOnMobile,
  }) => {
    return (
      <DeliveryTime
        variation={variation}
        wrapOnMobile={wrapOnMobile}
        estimatedDeliveryTimeDisplayType={estimatedDeliveryTimeDisplayType}
        estimatedDeliveryTime={estimatedDeliveryTime}
        intl={intl}
        nativeLanguageCode={nativeLanguageCode}
        is12HourTimeFormat={is12HourTimeFormat}
        nativeDateTimeFormat={nativeDateTimeFormat}
      />
    );
  };

  return (
    <Fragment>
      <EstimatedDeliveryText
        size={TEXT_SIZES.TINY}
        id={`${offerKey}-firstline`}
        isParagraph
      >
        {getCopy(
          selectedOfferOption.estimatedDeliveryTimeDisplayType === "ESTIMATED"
            ? "estimatedDelivery"
            : "committedDelivery"
        )}
      </EstimatedDeliveryText>

      <DateTimeWrapper
        data-testid={`${elemId}-formfield-selecteddate`}
        data-testvalue={new Date(
          selectedOfferOption.estimatedDeliveryTime
        ).toJSON()}
        id={`${offerKey}-headline`}
      >
        <DropdownField
          isBlock
          id={`${elemId}-formfield`}
          ariaLabel={ariaLabel}
          ariaDescribe={ariaDescribe}
          ariaDescribedBy={ariaDescribedBy}
          name={name}
          dataTestid={dataTestid}
          value={selectedOffer}
          onChange={handleTimeChange}
          label={label}
          placeholder={
            <DateAndTime
              id={selectedOfferOption.id}
              estimatedDeliveryTimeDisplayType={
                selectedOfferOption.estimatedDeliveryTimeDisplayType
              }
              estimatedDeliveryTime={selectedOfferOption.estimatedDeliveryTime}
            />
          }
        >
          {availableDeliveryTimes.map((time) => (
            <Option key={`wcc-offer-${time.id}`} value={time.id}>
              <DateAndTime
                wrapOnMobile
                id={time.id}
                estimatedDeliveryTimeDisplayType={
                  time.estimatedDeliveryTimeDisplayType
                }
                estimatedDeliveryTime={time.estimatedDeliveryTime}
                variation={variation}
              />
            </Option>
          ))}
        </DropdownField>
      </DateTimeWrapper>
    </Fragment>
  );
};

OfferCardDropdown.propTypes = {
  ariaDescribe: PropTypes.string,
  ariaDescribedBy: PropTypes.string,
  availableDeliveryTimes: PropTypes.array,
  selectedOffer: PropTypes.string,
  onChange: PropTypes.func,
  dataTestid: PropTypes.string,
  label: PropTypes.string,
  ariaLabel: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  nativeLanguageCode: PropTypes.string,
  is12HourTimeFormat: PropTypes.bool,
  nativeDateTimeFormat: PropTypes.string,
  intl: PropTypes.object,
  offerKey: PropTypes.string,
  variation: PropTypes.string,
};

export default OfferCardDropdown;
