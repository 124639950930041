// 9fbef606107a605d69c0edbcd8029e5d

/*
 *
 * OffersLane constants
 *
 */

export const SHOW_OFFERS_LANE = "app/OffersLane/SHOW_OFFERS_LANE";
export const HIDE_OFFERS_LANE = "app/OffersLane/HIDE_OFFERS_LANE";
export const TOGGLE_MODAL_ERROR = "app/Offerslane/TOGGLE_MODAL_ERROR";
export const TOGGLE_MODAL_SUCCESS = "app/Offerslane/TOGGLE_MODAL_SUCCESS";
export const TOGGLE_MODAL_EXPRESS = "app/OffersLane/TOGGLE_MODAL_EXPRESS";

// Freight Modal
export const TOGGLE_FREIGHT_P2P_MODAL =
  "app/Offerslane/TOGGLE_FREIGHT_P2P_MODAL";
export const CHANGE_FREIGHT_P2P_MODAL_INFORMATION =
  "app/Offerslane/CHANGE_FREIGHT_P2P_MODAL_INFORMATION";
export const VALIDATE_FREIGHT_P2P_MODAL_FORM_FIELDS =
  "app/Offerslane/VALIDATE_FREIGHT_P2P_MODAL_FORM_FIELDS";
export const RESET_FREIGHT_P2P_MODAL_STATE =
  "app/Offerslane/RESET_FREIGHT_P2P_MODAL_STATE";
export const SUBMIT_FREIGHT_P2P_MODAL =
  "app/OffersLane/SUBMIT_FREIGHT_P2P_MODAL";
export const GET_FREIGHT_P2P_MODAL_REQUEST_SENT =
  "app/OffersLane/GET_FREIGHT_P2P_MODAL_REQUEST_SENT";
export const GET_FREIGHT_P2P_MODAL_REQUEST_COMPLETED =
  "app/OffersLane/GET_FREIGHT_P2P_MODAL_REQUEST_COMPLETED";
export const GET_SELECTED_FREIGHT_OFFER_ID =
  "app/OffersLane/GET_SELECTED_FREIGHT_OFFER_ID";
export const TOGGLE_BOOKING_NOT_ALLOWED_MODAL =
  "app/OffersLane/TOGGLE_BOOKING_NOT_ALLOWED_MODAL";
export const SAVE_SHIPMENT = "app/OffersLane/SAVE_SHIPMENT";

export const deliveryTimeDisplayTypes = {
  exact: "EXACT_TIME",
  endOfDay: "END_OF_DAY",
  latestEndOfDay: "LATEST_END_OF_DAY",
};
