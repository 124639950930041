// 9fbef606107a605d69c0edbcd8029e5d

import styled, { css } from "styled-components";
import { Text } from "@dhl-official/react-ui-library";
import { breakpoints, grid } from "root/libs/ui-styleguide/src";
import {
  Headline,
  GridContainer,
  biDimemsionalCell,
  Link,
} from "root/libs/ui-components/src";
import { key } from "styled-theme";

export const StyledHeadlineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 35px 20px 0 20px;
  align-items: flex-start;

  @media screen and (min-width: ${breakpoints.phablet}) {
    align-items: center;
    margin: 100px 0 40px;
    flex-wrap: unset;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: 0;
    margin-top: 30px;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-top: 35px;
  }

  @media print {
    align-items: center;
  }
`;

export const StyledDivForPDFPrinting = styled.div`
  display: none;

  @media print {
    display: block;
    break-after: page;
  }
`;

export const StyledUnderline = styled(Link)`
  text-decoration: underline;
`;

export const OfferLaneWrapper = styled.section`
  background-color: ${key("colors.globalHighlightBackgroundColor")};
  padding: 10px 0 ${(props) => (props.isExpanded ? 0 : "40px")};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-left: 0;
    padding-right: 0;
  }

  @media print {
    width: 80%;
    max-width: 80%;
    margin: 0 10%;
    background-color: transparent;
    padding: 0;
  }
`;

export const OfferFilterWrapper = styled(GridContainer)`
  @supports (display: grid) {
    @media screen and (min-width: ${breakpoints.tablet}) {
      grid-template-columns: repeat(12, 1fr);
    }
    @media screen and (min-width: ${breakpoints.desktop}) {
      grid-template-columns: repeat(12, 73px);
    }
  }

  @media screen and (min-width: ${breakpoints.phablet}) {
    padding-top: 20px;
  }

  /* Only for IE11 */
  @media all and (-ms-high-contrast: none) {
    max-width: ${grid.container.maxWidthInnerContainer}px;
  }

  /* Only for EDGE */
  /* stylelint-disable selector-type-no-unknown */
  _:-ms-lang(x),
  _:-webkit-full-screen,
  & {
    max-width: ${grid.container.maxWidthInnerContainer}px;
  }
  /* stylelint-enable selector-type-no-unknown */
`;

export const ieShippingDateWrapper = css`
  /* Only for IE11 */
  @media all and (-ms-high-contrast: none) {
    width: 40%;
  }

  /* Only for EDGE */
  /* stylelint-disable selector-type-no-unknown */
  _:-ms-lang(x),
  _:-webkit-full-screen,
  & {
    width: 40%;
  }
  /* stylelint-enable selector-type-no-unknown */
`;

export const StyledHeadlineWrapperTara = styled.div`
  width: 100%;
  max-width: 1000px;
  padding-left: 20px;
  padding-right: 20px;

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding: 0;
  }
`;

export const ShippingDateWrapper = styled.div`
  ${biDimemsionalCell}
  ${ieShippingDateWrapper}

  /* Remove with DUIL v2  */
  i:not([id^='date-picker-validation-icon-']) {
    display: none;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    grid-column-end: span 4;
    grid-column-start: 1;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: block;
    flex-wrap: inherit;
    justify-content: inherit;
    align-items: inherit;
    grid-column: 2 / span 3;
  }

  @media print {
    display: none;
  }
`;

export const ShippingDateSelectWrapper = styled.div`
  width: 100%;

  .date-picker {
    div {
      box-sizing: content-box;

      button {
        line-height: normal;
      }
    }

    > div[role="dialog"] {
      z-index: 3;
    }
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: auto;
    min-width: 200px;
    margin-bottom: 20px;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-bottom: 25px;
  }
`;

export const StyledHeadline = styled(Headline)`
  margin-bottom: 0;
`;

export const StyledText = styled(Text)`
  margin-top: 5px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: 0;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-top: 5px;
  }

  @media print {
    display: none;
  }
`;

export const OfferWrapper = styled.div`
  margin-bottom: 50px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: 45px;
    padding: 0 ${grid.gutter.m};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-bottom: 35px;
    padding: 0 0;
    max-width: ${grid.container.maxWidthInnerContainer}px;
  }
`;

export const Disclaimer = styled.div`
  margin-top: 26px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    > * {
      max-width: 53%;
      margin: 0 auto;
      text-align: center;
    }
  }

  @media print {
    margin-top: 0;
  }
`;

export const BypassBlockWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  justify-items: center;
  flex-direction: column;
  height: 100px;
  margin-top: 100px;
  margin-bottom: 50px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: 150px 20px 70px;
    flex-direction: row;
    height: auto;
    justify-content: flex-end;

    .bypassBlock:first-child {
      margin-right: 10px;
    }
  }

  @media print {
    display: none;
  }
`;

export const VisitLaterActionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 60px 10px 0;
  justify-content: center;

  > div {
    width: 100%;
    max-width: 220px;
    margin: 0 10px 10px 10px;

    > * {
      width: 100%;
      white-space: nowrap;
    }
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    > div:first-child {
      margin: 0 0 10px 0;
    }

    > div:last-child {
      margin: 0 0 10px 0;
    }
  }

  @media print {
    display: none;
  }
`;

export const PrintDisclaimerWrapper = styled.div`
  display: none;
  text-align: center;
  width: 100%;
  margin-top: 40px;

  @media print {
    display: block;
    margin-top: 0;
  }
`;

export const SaveShipmentButtonWrapper = styled.div`
  ${biDimemsionalCell}
  padding-bottom: 30px;

  > button {
    width: 100%;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-bottom: 0;
    grid-column-end: span 4; // was 3
    grid-column-start: 10;
    text-align: end;
    padding-top: 5px;

    > button {
      width: auto;
    }
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    grid-column-start: 8; // was 9
  }

  @media print {
    display: none;
  }
`;
