// 9fbef606107a605d69c0edbcd8029e5d

/*
 *
 * UTF reducer
 *
 */

import { handleActions } from "redux-actions";
import { setPageName as setPageNameAction } from "./actions";

const initialState = {
  pageName: "",
};

const setPageName = (state, { payload: pageName = "" }) => ({
  ...state,
  pageName,
});

export default handleActions(
  {
    [setPageNameAction]: setPageName,
  },
  initialState
);
