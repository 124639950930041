// 9fbef606107a605d69c0edbcd8029e5d

import styled from "styled-components";
import { Headline } from "root/libs/ui-components/src";
import { breakpoints } from "root/libs/ui-styleguide/src";
import { key } from "styled-theme";

export const StyledFieldset = styled.fieldset`
  width: 100%;
`;

export const PresetMobileWrapper = styled.div`
  #customSizes-Content {
    margin: 0;
    width: 100%;
  }

  #defaultSizes-content {
    margin: 10px 0 -5px;
  }
`;

export const PresetDesktopWrapper = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-top: 18px;
    padding-bottom: 0;
    margin-top: 20px;
    border-top: 1px solid ${key("colors.globalBorderColor")};
  }
`;

export const StyledPresetsHeadline = styled(Headline)`
  margin-top: 0;
  margin-bottom: 8px;

  @media print {
    display: none;
  }
`;
