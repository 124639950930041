// 9fbef606107a605d69c0edbcd8029e5d

const NavigatorLib = {
  overrideLocale: (currentLocale, fallback = "nb") => {
    const localeFromURL = currentLocale.toLowerCase();

    if (localeFromURL === "no") {
      const browserLanguage = navigator?.language;
      switch (browserLanguage) {
        case "nb":
        case "nn":
          return browserLanguage;

        default:
          return fallback;
      }
    }

    return localeFromURL;
  },
};

export default NavigatorLib;
