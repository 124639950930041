// 9fbef606107a605d69c0edbcd8029e5d

// All the .variables.js files contain
// variable files that are meant to
// contain values that are used multiple times
// across the app. The should be: 1 time defined
// used all aroud. This is an
// approach to enhance maintainability

// All the .css.js files, contain little sections
// of global styles. These are critical styles that need to be in
// the head of the document.
import { createGlobalStyle } from "styled-components";

import body from "./css.body";
import fonts from "./css.fonts";

export default createGlobalStyle`
  ${({ locale }) => fonts(locale)}
  ${body}
`;
