// 9fbef606107a605d69c0edbcd8029e5d

import dates from "../date";

export const getPerfomanceAPI = () => {
  return (
    window.performance ||
    window.webkitPerformance ||
    window.msPerformance ||
    window.mozPerformance
  );
};

export const getPerformanceNow = () => {
  const performance = getPerfomanceAPI();
  return performance ? Math.round(performance.now()) : dates.getNowIsoString();
};

export const setPerformanceMark = (tag) => {
  const perf = getPerfomanceAPI();
  perf.mark(tag);
};

export const doPerformanceMeasure = (startTag, endTag) => {
  const perf = getPerfomanceAPI();
  perf.measure(startTag, endTag);
};

export const startMark = (tag) => {
  setPerformanceMark(`start_${tag}`);
};

export const endMark = (tag) => {
  setPerformanceMark(`end_${tag}`);
  doPerformanceMeasure(tag, `start_${tag}`, `end_${tag}`);
};

export const getMemoryInfo = () => {
  const { memory } = getPerfomanceAPI();
  let memoryMetrics = {};

  if (memory) {
    // memory API may have other keys and functions
    // so, just taking what we need here
    const { jsHeapSizeLimit, totalJSHeapSize, usedJSHeapSize } = memory;
    memoryMetrics = {
      jsHeapSizeLimit,
      totalJSHeapSize,
      usedJSHeapSize,
    };
  }

  return { memoryMetrics };
};
