// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * OfferCardWrapper
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { IntlPropType } from "root/libs/core-libs/src";
import { CountrySettings } from "root/libs/ui-containers/src";
import WCCMultiBUOfferCard from "one-time-shipment/src/components/Offers/WCCMultiBUOfferCard";
import WCCMultiBUOfferCardHeader from "one-time-shipment/src/components/Offers/WCCMultiBUOfferCardHeader";
import { StyledMultiBUWrapper, StyledOfferCardWrapper } from "./styled";

const { withCountry } = CountrySettings;

const COPY_ID_PREFIX = "WCCOfferCardWrapper";

export default class WCCMultiBUOfferCardWrapper extends React.PureComponent {
  static propTypes = {
    /** Internationalization library */
    intl: IntlPropType.isRequired,
    offers: PropTypes.array.isRequired,
    isBusiness: PropTypes.bool,
    indexLength: PropTypes.number,
    purchaseMethod: PropTypes.string,
    index: PropTypes.number,
    isHighlighted: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.WCCMultiBUCardWithCountry = withCountry(WCCMultiBUOfferCard);
    this.state = {
      isHeaderOpen: true,
      expandedCardIndex: null,
    };
  }

  getCopy = (id) => {
    const { intl } = this.props;
    return intl.formatMessage({ id });
  };

  handleExpandCard = (index, parentRef) => {
    let newIndex = index;
    const { expandedCardIndex } = this.state;
    if (index === expandedCardIndex) {
      newIndex = null;
    }
    this.setState({
      expandedCardIndex: newIndex,
    });

    setTimeout(() => {
      if (parentRef && parentRef.current && window.scrollY) {
        const headerOffset = 65;
        const elementPosition = parentRef.current.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.scrollY - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }, 0);
  };

  onClickHeader = () => {
    const { isHeaderOpen } = this.state;
    this.setState({ isHeaderOpen: !isHeaderOpen });
  };

  render() {
    const {
      offers,
      intl,
      isBusiness,
      indexLength,
      purchaseMethod,
      index,
      isHighlighted,
    } = this.props;

    const { isHeaderOpen } = this.state;
    const { expandedCardIndex } = this.state;

    return (
      <StyledOfferCardWrapper isHeaderOpen={isHeaderOpen}>
        <WCCMultiBUOfferCardHeader
          intl={intl}
          purchaseMethod={purchaseMethod}
          offerIndex={index}
          onClick={this.onClickHeader}
          isHeaderOpen={isHeaderOpen}
        />
        {offers.map((offer, offerIndex) => (
          <StyledMultiBUWrapper
            key={`${offer.key}-${offer.id}`}
            className="grid-maxWidthInner no-padding"
            data-testid="wcc-offer-card-multi-bu-wrapper"
          >
            <this.WCCMultiBUCardWithCountry
              key={purchaseMethod}
              indexLength={indexLength}
              isFirst={offerIndex === 0}
              expandedCardIndex={expandedCardIndex}
              isExpanded={offerIndex === expandedCardIndex}
              isLast={offerIndex === indexLength - 1}
              isHighlighted={isHighlighted}
              purchaseMethod={purchaseMethod}
              offer={offer}
              intl={intl}
              isBusiness={isBusiness}
              offerIndex={offerIndex}
              iNeedTheseProps={[
                "nativeLanguageCode",
                "is12HourTimeFormat",
                "nativeDateTimeFormat",
              ]}
              handleExpandCard={this.handleExpandCard}
            />
            <span
              id="wcc-offer-card-wrapper-more-information"
              className="visually-hidden"
            >
              {this.getCopy(
                `${COPY_ID_PREFIX}.screenReaderLabel_moreInformation`
              )}
            </span>
          </StyledMultiBUWrapper>
        ))}
      </StyledOfferCardWrapper>
    );
  }
}
