// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * OffersLaneComponent
 *
 */

import React from "react";
import PropTypes from "prop-types";
import scrollToComponent from "react-scroll-to-component";
import { Text, TEXT_SIZES } from "@dhl-official/react-ui-library";
import {
  SS,
  domain,
  COMMON,
  UTF_EVENT_PAGE_NAMES,
  UTF_EVENT_CONTENT_NAMES,
  UTF_EVENT_TYPES,
  UTF_EVENT_INTERACTIONS,
  UTF_EVENT_CONTENT_POSITION,
  UTF_PRODUCT_CARD_VARIATIONS,
  UTF_EVENT_CONVERSION_NAMES,
  UTF_EVENT_CONVERSION_TYPES,
  UTF_EVENT_CONVERSION_DETAIL,
} from "root/libs/constants/src";
import { CountrySettings, AbTesting } from "root/libs/ui-containers/src";
import memoize from "lodash/memoize";
import ModalExpress from "one-time-shipment/src/components/Modals/ModalExpress";
import ModalFreightP2P from "one-time-shipment/src/components/Modals/ModalFreightP2P/container";
import { injectIntl } from "react-intl";
import {
  IntlPropType,
  createShareCode,
  createShareUrl,
  trackEngagementAndProductInteraction,
  getUtfAddOnString,
  sortMultiBUOffersByPurchaseMethod,
} from "root/libs/core-libs/src";
import ResponsiveRender from "one-time-shipment/src/components/Layout/ResponsiveRender";
import TeaserOverlay from "one-time-shipment/src/components/Teaser/TeaserOverlay";
import { ModalError, GridContainer } from "root/libs/ui-components/src";
import PrintButton from "one-time-shipment/src/components/Buttons/PrintButton";
import ShareButton from "one-time-shipment/src/components/Buttons/ShareButton";
import SaveShipmentButton from "one-time-shipment/src/components/Buttons/SaveShipmentButton";
import OfferLaneDatePicker from "one-time-shipment/src/components/OfferLaneDatePicker";
import OfferCard from "one-time-shipment/src/components/Offers/OfferCard";
import WCCOfferCardWrapper from "one-time-shipment/src/components/Offers/WCCOfferCardWrapper";
import WCCMultiBUOfferCardWrapper from "one-time-shipment/src/components/Offers/WCCMultiBUOfferCardWrapper";
import TargetedServiceCard from "one-time-shipment/src/components/Offers/TargetedServiceCard";
import EmptyOffers from "one-time-shipment/src/components/Offers/EmptyOffers";
import OfferSort from "one-time-shipment/src/components/Offers/OfferSort/container";
import ModalSuccess from "one-time-shipment/src/components/Modals/ModalSuccess";
import BookingNotAllowed from "one-time-shipment/src/components/Modals/BookingNotAllowed/container";
import { selectTrackingKey } from "one-time-shipment/src/containers/Tracking/selectors";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CopyQuoteLinkButton from "one-time-shipment/src/components/Buttons/CopyQuoteLinkButton";
import { SEGMENT_TYPES } from "one-time-shipment/src/containers/SpotShipmentPage/constants";
import { TYPES_OF_OFFERS } from "one-time-shipment/src/containers/GetQuote/constants";

import {
  OfferLaneWrapper,
  OfferFilterWrapper,
  OfferWrapper,
  StyledHeadline,
  StyledHeadlineWrapper,
  StyledDivForPDFPrinting,
  StyledText,
  ShippingDateWrapper,
  ShippingDateSelectWrapper,
  Disclaimer,
  VisitLaterActionsWrapper,
  PrintDisclaimerWrapper,
  SaveShipmentButtonWrapper,
  StyledHeadlineWrapperTara,
  StyledUnderline,
} from "./styled";

const { withCountry } = CountrySettings;

const COPY_ID_PREFIX = "OffersLaneComponent";

const multiBU = "MULTI_BU_ONLY";
const singleBU = "SINGLE_BU_ONLY";

class OffersLaneComponent extends React.PureComponent {
  buildShareableObject = memoize(
    ({
      spotShipmentPage,
      shipmentLaneInitialSet,
      documentsOptionValue,
      originDestinationFlipped,
      tradeLaneInitialSet,
      sortOffersBy,
      selectedUnitSystem,
    }) => ({
      spotShipmentPage: {
        ...spotShipmentPage,
      },
      shipmentLane: {
        selectedUnitSystem,
        initialSet: shipmentLaneInitialSet,
      },
      tradeLane: {
        documentsOptionValue,
        originDestinationFlipped,
        initialSet: tradeLaneInitialSet,
      },
      getQuote: {
        sortOffersBy,
      },
    })
  );

  static propTypes = {
    /** Translations library */
    intl: IntlPropType.isRequired,
    offers: PropTypes.array.isRequired,
    isOffersLaneVisible: PropTypes.bool.isRequired,
    isWaitingForResponse: PropTypes.bool.isRequired,
    haveTheBackendsErrors: PropTypes.bool,
    isModalErrorVisible: PropTypes.bool,
    shippingDate: PropTypes.string.isRequired,
    isShippingDateVisible: PropTypes.bool.isRequired,
    updateShippingDate: PropTypes.func.isRequired,
    trackEvent: PropTypes.func.isRequired,
    spotShipmentTabReference: PropTypes.object,
    toggleModalError: PropTypes.func,
    toggleModalSuccess: PropTypes.func,
    retrySubmitAction: PropTypes.func,
    hasNetworkError: PropTypes.bool,
    reference: PropTypes.object,
    /** Boolean indicating the display of the Mobile Express PTP modal */
    isModalExpressVisible: PropTypes.bool,
    /** Boolean indicating the display of the Booking not allowed PTP modal */
    isModalBookingNotAllowedVisible: PropTypes.bool,
    /** Boolean indicating the display of the Freight P2P modal */
    isModalFreightP2PVisible: PropTypes.bool,
    /** Boolean indicating the display of the Success modal */
    isModalSuccessVisible: PropTypes.bool,
    /** Function to hand over for controlling the display of the Mobile Express PTP modal */
    toggleModalExpress: PropTypes.func,
    /** Function to hand over for controlling the display of the Booking not allowed PTP modal */
    toggleBookingNotAllowedModal: PropTypes.func,
    /** Function to hand over for controlling the display of the Freight P2P modal */
    toggleFreightP2PModal: PropTypes.func,
    /** Function to handle changes of input fields */
    onFreightP2PModalInformationChange: PropTypes.func,
    /** Object containing the current validity status of all form fields from Redux */
    isFormValid: PropTypes.bool.isRequired,
    /** Dispatcher function to validate the form fields */
    validateFreightP2PModalFormFields: PropTypes.func,
    /** Called during onSubmit handler to dispatch http request to the BE service */
    getFreightP2PModalRequestSent: PropTypes.func.isRequired,
    isWaitingForFreightModalResponse: PropTypes.bool.isRequired,
    enableTeaserOverlayFeature: PropTypes.bool,
    toggleRegularShipmentTeaser: PropTypes.func,
    shouldScroll: PropTypes.bool,
    resetScrolling: PropTypes.func,
    tradeLaneInitialSet: PropTypes.object,
    shipmentLaneInitialSet: PropTypes.object,
    documentsOptionValue: PropTypes.object,
    spotShipmentPage: PropTypes.object,
    originDestinationFlipped: PropTypes.bool,
    isBusiness: PropTypes.bool,
    showCurrencyIsoCodeForPrices: PropTypes.bool,
    sortOffersBy: PropTypes.string,
    selectedUnitSystem: PropTypes.string,
    backToShipmentLaneReference: PropTypes.object,
    country: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    isSortOffersByVisible: PropTypes.bool.isRequired,
    abTestRunning: PropTypes.bool,
    pageName: PropTypes.string.isRequired,
    experience: PropTypes.string,
    canaryEnabled: PropTypes.bool,
    canaryVersion: PropTypes.string,
    aggregatedOfferViewMode: PropTypes.shape({
      private: PropTypes.string,
      business: PropTypes.string,
    }),
  };

  static defaultProps = {
    spotShipmentTabReference: {},
    haveTheBackendsErrors: false,
    isModalErrorVisible: false,
    validateFreightP2PModalFormFields: () => {},
    hasNetworkError: false,
    toggleModalError: () => {},
    toggleModalSuccess: () => {},
    retrySubmitAction: () => {},
    reference: React.createRef(),
    isModalExpressVisible: false,
    isModalBookingNotAllowedVisible: false,
    isModalFreightP2PVisible: false,
    toggleModalExpress: () => {},
    toggleBookingNotAllowedModal: () => {},
    toggleFreightP2PModal: () => {},
    onFreightP2PModalInformationChange: () => {},
    enableTeaserOverlayFeature: false,
    toggleRegularShipmentTeaser: () => {},
    shouldScroll: false,
    resetScrolling: () => {},
    tradeLaneInitialSet: {},
    shipmentLaneInitialSet: {},
    documentsOptionValue: {
      private: false,
      business: false,
    },
    spotShipmentPage: {},
    originDestinationFlipped: false,
    isBusiness: false,
    isModalSuccessVisible: false,
    showCurrencyIsoCodeForPrices: false,
    selectedUnitSystem: "",
    backToShipmentLaneReference: React.createRef(),
    experience: "",
    aggregatedOfferViewMode: {
      private: "SINGLE_BU_ONLY",
      business: "MULTI_BU_ONLY",
    },
  };

  constructor(props) {
    super(props);
    const trackingKeySelector = selectTrackingKey;
    this.shipmentDateChangedTrackingKeyPath = trackingKeySelector(
      "spotShipment.interactions.offersTriggers.shipmentDateChanged"
    );
    this.downloadQuotesClickedTrackingKeyPath = trackingKeySelector(
      "spotShipment.interactions.offersTriggers.downloadQuotesClicked"
    );
    this.shareQuotesClickedTrackingKeyPath = trackingKeySelector(
      "spotShipment.interactions.offersTriggers.shareQuotesClicked"
    );
    this.shareQuotesFallbackClickedTrackingKeyPath = trackingKeySelector(
      "spotShipment.interactions.offersTriggers.shareQuotesFallbackClicked"
    );
    this.freightP2PModalCloseButtonTrackingKeyPath = trackingKeySelector(
      "spotShipment.interactions.clickedOnFreightP2PModalCloseButton"
    );
    this.frequentShipmentLink = trackingKeySelector(
      "spotShipment.interactions.clickedOnFrequentShipmentLink"
    );
    this.ModalExpressWithCountry = withCountry(ModalExpress);
    this.ModalFreightP2PWithCountry = withCountry(ModalFreightP2P);

    const {
      constants: { TYPES_OF_ABTESTVERSIONS },
    } = AbTesting;

    this.AbTestingTypes = TYPES_OF_ABTESTVERSIONS;

    this.experience = props.experience;

    this.selectedSegment = props.isBusiness
      ? SEGMENT_TYPES.business
      : SEGMENT_TYPES.private;

    this.state = {
      modalFreightP2POfferData: {
        quotationId: "",
        amount: "",
        tax: "WITHOUT",
        currencyCode: "",
        deliveryOption: "",
        deliveryType: "COMMITTED",
        originCountry: "",
        pickUpDate: "",
        destinationCountry: "",
        deliveryDate: "",
      },
    };
  }

  // The component was mounted several times instead of updating
  componentDidUpdate = () => {
    const {
      shouldScroll,
      resetScrolling,
      reference,
      isOffersLaneVisible,
      trackEvent,
      pageName,
      isWaitingForResponse,
      isBusiness,
    } = this.props;
    const { PRODUCT_LANE } = UTF_EVENT_PAGE_NAMES;

    this.selectedSegment = isBusiness
      ? SEGMENT_TYPES.business
      : SEGMENT_TYPES.private;
    if (isOffersLaneVisible) {
      if (!isWaitingForResponse) {
        trackEngagementAndProductInteraction({
          funnel: {
            name: pageName,
            type: UTF_EVENT_CONTENT_NAMES.CUSTOMER_JOURNEY,
            step: PRODUCT_LANE,
            progress: UTF_EVENT_INTERACTIONS.LOAD,
          },
          content: {
            context: this.selectedSegment,
            attributes: {
              layoutVersion: this.getLayoutVersionForTracking(),
            },
          },
          products: this.prepareOffersForTracking(),
        });
      }
      trackEvent({ quoteLaneRendered: true });
    }

    if (shouldScroll) {
      setTimeout(() => {
        scrollToComponent(reference.current, {
          align: "top",
        });
        resetScrolling();
      }, 0);
    }
  };

  areOffersEmpty = () => {
    const { offers } = this.props;
    return !offers || offers.length === 0;
  };

  getOfferById = (offerId, offers) => {
    return offers.find((obj) => obj.id === offerId);
  };

  offersForTracking = (singleOffer) => {
    const shippingModesString = getUtfAddOnString(singleOffer.addOns);

    return {
      id: singleOffer.id,
      key: singleOffer.key,
      businessUnit: singleOffer.businessUnit,
      price: singleOffer?.price?.billingAmount,
      currencyCode: singleOffer?.price?.billingCurrencyCode,
      shippingModes: shippingModesString,
    };
  };

  prepareOffersForTracking = () => {
    const { offers } = this.props;
    let returnThisArray = [];

    if (this.isWCCMultiBUAggregatedFn()) {
      const flatAggregatedOffersArray = offers
        .filter((oo) => oo.type === TYPES_OF_OFFERS.AGGREGATED)
        .map((oo) => {
          return Object.keys(oo.metadata.metadataByPurchaseMethod)
            .filter(
              (filterOffer) =>
                oo.metadata.metadataByPurchaseMethod[filterOffer]?.cheapestOffer
                  ?.id !== undefined
            )
            .map((purchaseMethod) => {
              const { id } = oo.metadata.metadataByPurchaseMethod[
                purchaseMethod
              ].cheapestOffer;
              return this.getOfferById(id, oo.offerOptions);
            });
        });

      const flatOffersArray = offers.filter(
        (off) => off.offerOptions === undefined
      );

      const allFlatOffers = [
        ...flatAggregatedOffersArray[0],
        ...flatOffersArray,
      ];

      returnThisArray = allFlatOffers.map((item) => {
        return this.offersForTracking(item);
      });
    } else if (this.isWCCSingleBUAggregatedFn()) {
      const mainArray = offers[0];
      returnThisArray = Object.keys(mainArray.metadata.metadataByPurchaseMethod)
        .filter(
          (filterOffer) =>
            mainArray.metadata.metadataByPurchaseMethod[filterOffer]
              ?.cheapestOffer?.id !== undefined
        )
        .map((purchaseMethod) => {
          const { id } = mainArray.metadata.metadataByPurchaseMethod[
            purchaseMethod
          ].cheapestOffer;
          const item = this.getOfferById(id, mainArray.offerOptions);

          return this.offersForTracking(item);
        });
    } else {
      returnThisArray = offers.map((item) => {
        return this.offersForTracking(item);
      });
    }

    return returnThisArray;
  };

  closeFreightP2PModal = () => {
    const { toggleFreightP2PModal, trackEvent } = this.props;

    trackEvent({ clickedOnFreightP2PModalCloseButton: true });

    toggleFreightP2PModal();
  };

  toggleFreightP2PModal = (freightOffer) => {
    const { toggleFreightP2PModal, trackEvent } = this.props;

    this.setState({
      modalFreightP2POfferData: freightOffer.freightModal,
    });

    trackEvent({
      freightP2PModalQuotationId: freightOffer.freightModal.quotationId,
    });

    toggleFreightP2PModal(freightOffer.freightModal.quotationId);
  };

  onFreightP2PModalInformationChange = (eventAndInputValues) => {
    const { onFreightP2PModalInformationChange } = this.props;

    const { inputValue } = eventAndInputValues;

    onFreightP2PModalInformationChange({ ...inputValue });
  };

  getCopy = (id, values, prefix = COPY_ID_PREFIX) => {
    const { intl } = this.props;
    let messageId = `${prefix}.${id}`;

    if (this.experience && this.experience !== "") {
      const experienceMessageId = `${messageId}_${this.experience}`;
      if (intl.messages[experienceMessageId]) {
        messageId = experienceMessageId;
      }
    }

    return intl.messages[messageId]
      ? intl.formatMessage({ id: messageId }, values)
      : messageId;
  };

  onSuccessSaveHandler = (savedQuoteSuccess) => {
    this.setState({
      hasSavedQuote: savedQuoteSuccess,
    });
  };

  getLayoutVersionForTracking = () => {
    const { LEGACY, SINGLE_BU, MULTI_BU } = UTF_PRODUCT_CARD_VARIATIONS;
    if (this.isWCCSingleBUAggregatedFn()) return SINGLE_BU;
    if (this.isWCCMultiBUAggregatedFn()) return MULTI_BU;
    return LEGACY;
  };

  isWCCMultiBUAggregatedFn = () => {
    const { offers, isBusiness, aggregatedOfferViewMode } = this.props;

    return (
      offers.find((offer) => offer.type === TYPES_OF_OFFERS.AGGREGATED) &&
      ((isBusiness && aggregatedOfferViewMode.business === multiBU) ||
        (!isBusiness && aggregatedOfferViewMode.private === multiBU))
    );
  };

  isWCCSingleBUAggregatedFn = () => {
    const { offers, isBusiness, aggregatedOfferViewMode } = this.props;
    return (
      offers.find((offer) => offer.type === TYPES_OF_OFFERS.AGGREGATED) &&
      ((isBusiness && aggregatedOfferViewMode.business === singleBU) ||
        (!isBusiness && aggregatedOfferViewMode.private === singleBU))
    );
  };

  render() {
    const {
      intl,
      isOffersLaneVisible,
      haveTheBackendsErrors,
      isModalErrorVisible,
      shippingDate,
      isShippingDateVisible,
      trackEvent,
      spotShipmentTabReference,
      toggleModalError,
      toggleModalSuccess,
      isWaitingForResponse,
      hasNetworkError,
      retrySubmitAction,
      reference,
      isModalExpressVisible,
      isModalBookingNotAllowedVisible,
      isModalFreightP2PVisible,
      toggleModalExpress,
      toggleBookingNotAllowedModal,
      enableTeaserOverlayFeature,
      toggleRegularShipmentTeaser,
      tradeLaneInitialSet,
      shipmentLaneInitialSet,
      spotShipmentPage,
      documentsOptionValue,
      originDestinationFlipped,
      isBusiness,
      isFormValid,
      validateFreightP2PModalFormFields,
      getFreightP2PModalRequestSent,
      isWaitingForFreightModalResponse,
      isModalSuccessVisible,
      showCurrencyIsoCodeForPrices,
      offers,
      sortOffersBy,
      selectedUnitSystem,
      country,
      backToShipmentLaneReference,
      isSortOffersByVisible,
      updateShippingDate,
      language,
      abTestRunning,
      canaryEnabled,
      canaryVersion,
    } = this.props;

    const { modalFreightP2POfferData } = this.state;
    const isWCCMultiBUAggregated = this.isWCCMultiBUAggregatedFn();

    const wccMultiBUOffersSorted = isWCCMultiBUAggregated
      ? sortMultiBUOffersByPurchaseMethod(offers)
      : null;

    const isLoadingIndicatorVisible =
      isWaitingForResponse && isOffersLaneVisible;
    const shareDataObject = this.buildShareableObject({
      spotShipmentPage,
      shipmentLaneInitialSet,
      documentsOptionValue,
      originDestinationFlipped,
      tradeLaneInitialSet,
      sortOffersBy,
      selectedUnitSystem,
    });

    const shareCode = createShareCode(shareDataObject);
    const shareUrl = createShareUrl(shareCode);

    const utfTargetServiceCard = {
      conversion: {
        name: UTF_EVENT_CONVERSION_NAMES.CONTINUE_TO_FS,
        type: UTF_EVENT_CONVERSION_TYPES.HANDOVER,
        detail: UTF_EVENT_CONVERSION_DETAIL.TARGETED_SERVICE_CARD,
      },
      content: {
        name: UTF_EVENT_CONTENT_NAMES.FS_GET_IN_CONTACT,
        type: UTF_EVENT_TYPES.BUTTON,
        interaction: UTF_EVENT_INTERACTIONS.CLICK,
        position: UTF_EVENT_CONTENT_POSITION.PRODUCT_LANE,
        context: isBusiness ? SEGMENT_TYPES.business : SEGMENT_TYPES.private,
      },
    };

    const abTestingTypesNotTara = Object.values(this.AbTestingTypes).filter(
      (type) => type !== this.AbTestingTypes.tara
    );

    const renderAbTestingHeadline = (
      type,
      key,
      headline,
      subheadline,
      additionalProps = {}
    ) => {
      const isTaraType = type === this.AbTestingTypes.tara;
      const HeadlineWrapper = isTaraType
        ? StyledHeadlineWrapperTara
        : React.Fragment;
      const headlineProps = isTaraType
        ? { left: true }
        : { centerInDesktop: true };

      return (
        <AbTesting.Component
          key={key}
          type={type}
          componentToRender={() => (
            <HeadlineWrapper>
              <StyledHeadline
                priority={2}
                headlineDisplayType="h"
                weight="800"
                {...headlineProps}
                {...additionalProps}
              >
                {headline}
              </StyledHeadline>
              <StyledText isParagraph>{subheadline}</StyledText>
            </HeadlineWrapper>
          )}
        />
      );
    };

    const renderAbTestingOfferCardComponent = (type, index, offer) => (
      <AbTesting.Component
        type={type}
        componentToRender={() => {
          return (
            <OfferCard
              intl={intl}
              offer={offer}
              showCurrencyIsoCodeForPrices={showCurrencyIsoCodeForPrices}
              trackEvent={trackEvent}
              toggleModalExpress={toggleModalExpress}
              toggleBookingNotAllowedModal={toggleBookingNotAllowedModal}
              toggleFreightP2PModal={this.toggleFreightP2PModal}
              isBusiness={isBusiness}
              index={index}
              indexLength={offers.length}
              canaryEnabled={canaryEnabled}
              canaryVersion={canaryVersion}
              abTestRunning={abTestRunning}
              abTestVersion={type}
            />
          );
        }}
      />
    );

    return (
      <section ref={reference}>
        {!isOffersLaneVisible || hasNetworkError ? null : (
          <React.Fragment>
            <StyledDivForPDFPrinting />
            <StyledHeadlineWrapper>
              {abTestRunning ? (
                <React.Fragment>
                  {abTestingTypesNotTara.map((type, index) =>
                    renderAbTestingHeadline(
                      type,
                      index,
                      this.getCopy("headline"),
                      this.getCopy("subheadline")
                    )
                  )}
                  {renderAbTestingHeadline(
                    this.AbTestingTypes.tara,
                    null,
                    isBusiness
                      ? this.getCopy("headlineTaraBusiness")
                      : this.getCopy("headlineTara"),
                    isBusiness
                      ? this.getCopy("subheadlineTaraBusiness")
                      : this.getCopy("subheadlineTara", {
                          br: <br key="key" />,
                          b: (chunks) => <strong>{chunks}</strong>,
                          businessTriggerLink: (
                            <StyledUnderline
                              href={this.getCopy("subheadlineTaraCtaLink")}
                            >
                              {this.getCopy("subheadlineTaraCtaText")}
                            </StyledUnderline>
                          ),
                        })
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <StyledHeadline
                    priority={2}
                    headlineDisplayType="h"
                    centerInDesktop
                    weight="800"
                  >
                    {this.getCopy("headline")}
                  </StyledHeadline>
                  <StyledText isParagraph>
                    {this.getCopy("subheadline")}
                  </StyledText>
                </React.Fragment>
              )}
              <span className="visually-hidden" aria-live="assertive">
                {this.getCopy("screenReaderLabel_messageOnAppear")}
              </span>
            </StyledHeadlineWrapper>
            <div
              role="feed"
              aria-busy={isLoadingIndicatorVisible ? "true" : "false"}
            >
              {isLoadingIndicatorVisible && (
                <React.Fragment>
                  <GridContainer
                    dimension="biDimensional"
                    addMaxWidth
                    halfGutterPadding
                  >
                    <ShippingDateWrapper gridColumn="2 / span 4">
                      <Text weight={200}>
                        <Skeleton width={100} />
                      </Text>

                      <ShippingDateSelectWrapper>
                        <Skeleton width={380} height={56} />
                      </ShippingDateSelectWrapper>
                    </ShippingDateWrapper>
                  </GridContainer>

                  <OfferWrapper className="grid-maxWidth">
                    <Skeleton height={167} />
                  </OfferWrapper>
                </React.Fragment>
              )}
              {!isLoadingIndicatorVisible && (
                <OfferLaneWrapper role="article">
                  {!this.areOffersEmpty() && (
                    <React.Fragment>
                      <OfferFilterWrapper
                        dimension="biDimensional"
                        addMaxWidth
                        halfGutterPadding
                      >
                        {isShippingDateVisible && (
                          <ShippingDateWrapper
                            gridColumn="2 / span 3"
                            spanTablet="5"
                          >
                            <ShippingDateSelectWrapper>
                              <OfferLaneDatePicker
                                getCopy={this.getCopy}
                                language={language}
                                shippingDate={shippingDate}
                                trackEvent={trackEvent}
                                updateShippingDate={updateShippingDate}
                              />
                            </ShippingDateSelectWrapper>
                          </ShippingDateWrapper>
                        )}
                        {isSortOffersByVisible && (
                          <OfferSort
                            intl={intl}
                            sortOffersBy={sortOffersBy}
                            segment={this.selectedSegment}
                          />
                        )}
                        <SaveShipmentButtonWrapper>
                          <SaveShipmentButton
                            country={country}
                            trackEvent={trackEvent}
                            saveData={shareDataObject}
                            isBusiness={isBusiness}
                            onSuccessSaveHandler={this.onSuccessSaveHandler}
                          />
                        </SaveShipmentButtonWrapper>
                      </OfferFilterWrapper>
                      {!isWCCMultiBUAggregated &&
                        offers.map((offer, index) => (
                          <OfferWrapper
                            key={`${offer.key}-${offer.id}`}
                            className="grid-maxWidth"
                          >
                            {offer.key === COMMON.TARGET_SERVICE_CARD_KEY ? (
                              <TargetedServiceCard
                                intl={intl}
                                trackEvent={trackEvent}
                                contentKey={offer.specialOffers}
                                utfDataObject={utfTargetServiceCard}
                                cid={COMMON.TARGET_SERVICE_CARD_CID}
                              />
                            ) : abTestRunning &&
                              offer.type !== TYPES_OF_OFFERS.AGGREGATED ? (
                              <React.Fragment>
                                {renderAbTestingOfferCardComponent(
                                  this.AbTestingTypes.control,
                                  index,
                                  offer
                                )}
                                {renderAbTestingOfferCardComponent(
                                  this.AbTestingTypes.tara,
                                  index,
                                  offer
                                )}
                              </React.Fragment>
                            ) : offer.type === TYPES_OF_OFFERS.AGGREGATED ? (
                              <WCCOfferCardWrapper
                                intl={intl}
                                offer={offer}
                                showCurrencyIsoCodeForPrices={
                                  showCurrencyIsoCodeForPrices
                                }
                                trackEvent={trackEvent}
                                toggleModalExpress={toggleModalExpress}
                                toggleBookingNotAllowedModal={
                                  toggleBookingNotAllowedModal
                                }
                                toggleFreightP2PModal={
                                  this.toggleFreightP2PModal
                                }
                                isBusiness={isBusiness}
                                index={index}
                                indexLength={offers.length}
                                canaryEnabled={canaryEnabled}
                                canaryVersion={canaryVersion}
                              />
                            ) : (
                              <OfferCard
                                intl={intl}
                                offer={offer}
                                showCurrencyIsoCodeForPrices={
                                  showCurrencyIsoCodeForPrices
                                }
                                trackEvent={trackEvent}
                                toggleModalExpress={toggleModalExpress}
                                toggleBookingNotAllowedModal={
                                  toggleBookingNotAllowedModal
                                }
                                toggleFreightP2PModal={
                                  this.toggleFreightP2PModal
                                }
                                isBusiness={isBusiness}
                                index={index}
                                indexLength={offers.length}
                                canaryEnabled={canaryEnabled}
                                canaryVersion={canaryVersion}
                              />
                            )}
                          </OfferWrapper>
                        ))}
                      {isWCCMultiBUAggregated &&
                        wccMultiBUOffersSorted.map((obj, index) => (
                          <OfferWrapper
                            key={`${obj.method}`}
                            className="grid-maxWidth"
                          >
                            {obj.method !== "TARGET_SERVICE" &&
                              obj.offers.length > 0 && (
                                <WCCMultiBUOfferCardWrapper
                                  intl={intl}
                                  offers={obj.offers}
                                  showCurrencyIsoCodeForPrices={
                                    showCurrencyIsoCodeForPrices
                                  }
                                  purchaseMethod={obj.method}
                                  trackEvent={trackEvent}
                                  toggleModalExpress={toggleModalExpress}
                                  toggleBookingNotAllowedModal={
                                    toggleBookingNotAllowedModal
                                  }
                                  toggleFreightP2PModal={
                                    this.toggleFreightP2PModal
                                  }
                                  isBusiness={isBusiness}
                                  indexLength={offers.length}
                                  canaryEnabled={canaryEnabled}
                                  canaryVersion={canaryVersion}
                                  isHighlighted={obj.isHighlighted}
                                />
                              )}
                            {obj.method === "TARGET_SERVICE" &&
                              obj.offers.map((offer, index) => (
                                <React.Fragment
                                  key={`${offer.key}-${offer.id}`}
                                >
                                  {offer.key ===
                                  COMMON.TARGET_SERVICE_CARD_KEY ? (
                                    <TargetedServiceCard
                                      intl={intl}
                                      trackEvent={trackEvent}
                                      contentKey={offer.specialOffers}
                                      utfDataObject={utfTargetServiceCard}
                                      cid={COMMON.TARGET_SERVICE_CARD_CID}
                                    />
                                  ) : (
                                    abTestRunning && (
                                      <React.Fragment>
                                        {renderAbTestingOfferCardComponent(
                                          this.AbTestingTypes.control,
                                          index,
                                          offer
                                        )}
                                        {renderAbTestingOfferCardComponent(
                                          this.AbTestingTypes.tara,
                                          index,
                                          offer
                                        )}
                                      </React.Fragment>
                                    )
                                  )}
                                </React.Fragment>
                              ))}
                          </OfferWrapper>
                        ))}
                    </React.Fragment>
                  )}
                  {this.areOffersEmpty() && (
                    <EmptyOffers
                      copyNamespace={SS}
                      haveTheBackendsErrors={haveTheBackendsErrors}
                      backToShipmentLaneReference={backToShipmentLaneReference}
                      trackEvent={trackEvent}
                      dataTracking={this.frequentShipmentLink}
                    />
                  )}

                  {enableTeaserOverlayFeature && (
                    <TeaserOverlay
                      buttonText={this.getCopy("regularShiptmentTeaserButton")}
                      headlineText={this.getCopy(
                        "regularShiptmentTeaserHeadline"
                      )}
                      copyText={this.getCopy("regularShiptmentTeaserCopy")}
                      spotShipmentTabReference={spotShipmentTabReference}
                      trackEvent={trackEvent}
                      toggleRegularShipmentTeaser={toggleRegularShipmentTeaser}
                    />
                  )}
                  {!this.areOffersEmpty() && (
                    <VisitLaterActionsWrapper className="grid-maxWidthInner">
                      <ShareButton
                        intl={intl}
                        dataTestid="share-quotes"
                        dataTracking={this.shareQuotesClickedTrackingKeyPath}
                        trackEvent={trackEvent}
                        trackingValue={{ shareQuotesClicked: true }}
                        shareUrl={shareUrl}
                        utfDataObject={{
                          name: UTF_EVENT_CONTENT_NAMES.EMAIL_QUOTES,
                          type: UTF_EVENT_TYPES.BUTTON,
                          interaction: UTF_EVENT_INTERACTIONS.CLICK,
                          position: UTF_EVENT_CONTENT_POSITION.PRODUCT_LANE,
                          context: isBusiness
                            ? SEGMENT_TYPES.business
                            : SEGMENT_TYPES.private,
                        }}
                      />
                      <CopyQuoteLinkButton
                        trackEvent={trackEvent}
                        dataTracking={
                          this.shareQuotesFallbackClickedTrackingKeyPath
                        }
                        trackingValue={{ shareQuotesFallbackClicked: true }}
                        intl={intl}
                        shareUrl={shareUrl}
                        utfDataObject={{
                          name: UTF_EVENT_CONTENT_NAMES.COPY_LINK,
                          type: UTF_EVENT_TYPES.BUTTON,
                          interaction: UTF_EVENT_INTERACTIONS.CLICK,
                          position: UTF_EVENT_CONTENT_POSITION.PRODUCT_LANE,
                          context: isBusiness
                            ? SEGMENT_TYPES.business
                            : SEGMENT_TYPES.private,
                        }}
                      />
                      <ResponsiveRender
                        match="tablet"
                        render={(isTablet) =>
                          !isTablet && (
                            <div>
                              <PrintButton
                                dataTestid="download-quotes"
                                dataTracking={
                                  this.downloadQuotesClickedTrackingKeyPath
                                }
                                trackEvent={trackEvent}
                                trackingValue={{ downloadQuotesClicked: true }}
                                utfDataObject={{
                                  name: UTF_EVENT_CONTENT_NAMES.PRINT_QUOTES,
                                  type: UTF_EVENT_TYPES.BUTTON,
                                  interaction: UTF_EVENT_INTERACTIONS.CLICK,
                                  position:
                                    UTF_EVENT_CONTENT_POSITION.PRODUCT_LANE,
                                  context: isBusiness
                                    ? SEGMENT_TYPES.business
                                    : SEGMENT_TYPES.private,
                                }}
                              />
                            </div>
                          )
                        }
                      />
                    </VisitLaterActionsWrapper>
                  )}
                </OfferLaneWrapper>
              )}
              {!isLoadingIndicatorVisible && !this.areOffersEmpty() && (
                <Disclaimer role="article" className="grid-maxWidth">
                  <Text
                    size={TEXT_SIZES.TINY}
                    ariaLabel={this.getCopy(
                      "screenReaderLabel_priceDisclaimerCopy"
                    )}
                    isParagraph
                  >
                    <Text size={TEXT_SIZES.SMALLPLUS} aria-hidden="true">
                      *&nbsp;
                    </Text>
                    {this.getCopy("priceDisclaimerCopy", {
                      br: <br key="key" />,
                    })}
                  </Text>
                  <PrintDisclaimerWrapper>
                    <Text size={TEXT_SIZES.SMALLPLUS} isParagraph>
                      {this.getCopy("printDisclaimerTerms", {
                        DOMAIN_NAME: domain.name,
                      })}
                    </Text>

                    <Text size={TEXT_SIZES.SMALLPLUS} isParagraph>
                      {this.getCopy("printDisclaimerPrivacy", {
                        DOMAIN_NAME: domain.name,
                      })}
                    </Text>
                  </PrintDisclaimerWrapper>
                </Disclaimer>
              )}
            </div>
          </React.Fragment>
        )}

        {isModalErrorVisible && (
          <ModalError
            onClose={toggleModalError}
            isOpen={isModalErrorVisible}
            onRetry={retrySubmitAction}
            isRetrying={isWaitingForResponse}
            headlineString={intl.formatMessage({
              id: "SS.ModalError.headline",
            })}
            sublineString={intl.formatMessage({
              id: "SS.ModalError.subHeadline",
            })}
            bodyContentString={intl.formatMessage({ id: "SS.ModalError.copy" })}
            retryButtonLabel={intl.formatMessage({ id: "SS.ModalError.retry" })}
            cancelButtonLabel={intl.formatMessage({
              id: "SS.ModalError.cancel",
            })}
            closeButtonLabel={intl.formatMessage({
              id: "SS.ContentModal.close",
            })}
          />
        )}
        {isModalSuccessVisible && (
          <ModalSuccess
            intl={intl}
            onClose={toggleModalSuccess}
            isOpen={isModalSuccessVisible}
          />
        )}
        {isModalExpressVisible && (
          <this.ModalExpressWithCountry
            intl={intl}
            onClose={toggleModalExpress}
            isOpen={isModalExpressVisible}
            iNeedTheseProps={["showModalExpressPhoneCta"]}
            utfDataObject={{
              conversion: {
                name: UTF_EVENT_CONVERSION_NAMES.BOOKING_INTENT,
                type: UTF_EVENT_CONVERSION_TYPES.HANDOVER,
                detail: UTF_EVENT_CONVERSION_DETAIL.EXPRESS_CTA,
              },
              content: {
                name: UTF_EVENT_CONTENT_NAMES.MOBILE_BOOKING_MODAL,
                type: UTF_EVENT_TYPES.MODAL,
                interaction: UTF_EVENT_INTERACTIONS.LOAD,
                position: UTF_EVENT_CONTENT_POSITION.PRODUCT_CARD,
                context: isBusiness
                  ? SEGMENT_TYPES.business
                  : SEGMENT_TYPES.private,
              },
            }}
          />
        )}
        {isModalBookingNotAllowedVisible && (
          <BookingNotAllowed
            intl={intl}
            onClose={toggleBookingNotAllowedModal}
            isOpen={isModalBookingNotAllowedVisible}
          />
        )}
        {isModalFreightP2PVisible && (
          <this.ModalFreightP2PWithCountry
            isOpen={isModalFreightP2PVisible}
            onClose={this.closeFreightP2PModal}
            validateFreightP2PModalFormFields={
              validateFreightP2PModalFormFields
            }
            onFreightP2PModalInformationChange={
              this.onFreightP2PModalInformationChange
            }
            getFreightP2PModalRequestSent={getFreightP2PModalRequestSent}
            offerData={modalFreightP2POfferData}
            isFormValid={isFormValid}
            isWaitingForResponse={isWaitingForFreightModalResponse}
            showCurrencyIsoCodeForPrices={showCurrencyIsoCodeForPrices}
            closeButtonDataTracking={
              this.freightP2PModalCloseButtonTrackingKeyPath
            }
            iNeedTheseProps={[
              "nativeLanguageCode",
              "formatCurrencyWithNativeLocale",
            ]}
            isBusiness={isBusiness}
          />
        )}
      </section>
    );
  }
}

export default injectIntl(OffersLaneComponent);
