// 9fbef606107a605d69c0edbcd8029e5d

/**
 * As our tool is meant to be embedded into dhl.com
 * and the "home" link to our tool is unknown to us.
 * We use the location object to know where we are and use it as a link
 * any time we need to link to home
 */
export default {
  url: window.location.href,
};
