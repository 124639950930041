// 9fbef606107a605d69c0edbcd8029e5d

/**
 * SaveShipmentButton
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { IntlPropType, createShareCode } from "root/libs/core-libs/src";
import ModalSaveInformation from "one-time-shipment/src/components/Modals/ModalSaveInformation";
import ModalSaveInformationSuccess from "one-time-shipment/src/components/Modals/ModalSaveInformationSuccess";
import { Button, BUTTON_VARIANTS } from "@dhl-official/react-ui-library";
import { COMMON } from "root/libs/constants/src";

const COPY_ID_PREFIX = "SaveShipmentButton";

const SaveShipmentButton = ({
  intl,
  country,
  saveData,
  dataTracking,
  trackEvent,
  isBusiness,
  onSuccessSaveHandler,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  function onSaveHandler() {
    window.localStorage.setItem(
      `${COMMON.SAVE_SHIPMENT_LOCAL_STORAGE}:${country}`,
      createShareCode(saveData)
    );
    setShowModal(!showModal);
    setShowSuccessModal(!showSuccessModal);
    onSuccessSaveHandler(true);
  }

  return (
    <React.Fragment>
      <Button
        name="save-shipment-btn"
        dataTestid="save-shipment-btn"
        variant={BUTTON_VARIANTS.SECONDARY}
        type="button"
        onClick={() => setShowModal(!showModal)}
        dataTracking={dataTracking}
      >
        {intl.formatMessage({ id: `${COPY_ID_PREFIX}.label` })}
      </Button>
      {showModal && (
        <ModalSaveInformation
          trackEvent={trackEvent}
          isOpen={showModal}
          onSave={onSaveHandler}
          isBusiness={isBusiness}
          onClose={() => setShowModal(!showModal)}
        />
      )}
      {showSuccessModal && (
        <ModalSaveInformationSuccess
          isOpen={showSuccessModal}
          onClose={() => setShowSuccessModal(!showSuccessModal)}
        />
      )}
    </React.Fragment>
  );
};

SaveShipmentButton.propTypes = {
  /** Internationalization library */
  intl: IntlPropType.isRequired,
  saveData: PropTypes.object.isRequired,
  dataTracking: PropTypes.string,
  trackEvent: PropTypes.func,
  isBusiness: PropTypes.bool,
  country: PropTypes.string.isRequired,
  onSuccessSaveHandler: PropTypes.func,
};

export default injectIntl(SaveShipmentButton);
