// 9fbef606107a605d69c0edbcd8029e5d

/*
 *
 * getOffers actions
 *
 */

import { createAction } from "redux-actions";
import {
  SHOW_OFFERS_LANE,
  TOGGLE_MODAL_ERROR,
  HIDE_OFFERS_LANE,
  TOGGLE_MODAL_EXPRESS,
  TOGGLE_FREIGHT_P2P_MODAL,
  CHANGE_FREIGHT_P2P_MODAL_INFORMATION,
  VALIDATE_FREIGHT_P2P_MODAL_FORM_FIELDS,
  RESET_FREIGHT_P2P_MODAL_STATE,
  TOGGLE_MODAL_SUCCESS,
  GET_FREIGHT_P2P_MODAL_REQUEST_SENT,
  GET_FREIGHT_P2P_MODAL_REQUEST_COMPLETED,
  GET_SELECTED_FREIGHT_OFFER_ID,
  TOGGLE_BOOKING_NOT_ALLOWED_MODAL,
} from "./constants";

export const showOffersLane = createAction(SHOW_OFFERS_LANE);
export const hideOffersLane = createAction(HIDE_OFFERS_LANE);
export const toggleModalError = createAction(TOGGLE_MODAL_ERROR);
export const toggleModalSuccess = createAction(TOGGLE_MODAL_SUCCESS);
export const toggleModalExpress = createAction(TOGGLE_MODAL_EXPRESS);
export const toggleBookingNotAllowedModal = createAction(
  TOGGLE_BOOKING_NOT_ALLOWED_MODAL
);
export const toggleFreightP2PModal = createAction(TOGGLE_FREIGHT_P2P_MODAL);
export const onFreightP2PModalInformationChange = createAction(
  CHANGE_FREIGHT_P2P_MODAL_INFORMATION
);
export const validateFreightP2PModalFormFields = createAction(
  VALIDATE_FREIGHT_P2P_MODAL_FORM_FIELDS
);
export const resetFreightP2PModalState = createAction(
  RESET_FREIGHT_P2P_MODAL_STATE
);
export const getFreightP2PModalRequestSent = createAction(
  GET_FREIGHT_P2P_MODAL_REQUEST_SENT
);
export const getFreightP2PModalRequestCompleted = createAction(
  GET_FREIGHT_P2P_MODAL_REQUEST_COMPLETED
);
export const selectedFreightOfferId = createAction(
  GET_SELECTED_FREIGHT_OFFER_ID
);
