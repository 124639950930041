// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * TeaserOverlay
 *
 */

import React from "react";
import scrollToComponent from "react-scroll-to-component";
import PropTypes from "prop-types";
import { Icon } from "root/libs/ui-components/src";
import { Button } from "@dhl-official/react-ui-library";
import closeIcon from "icons/cx/close.svg";
import { brandPalette } from "root/libs/ui-styleguide/src";
import { selectTrackingKey } from "one-time-shipment/src/containers/Tracking/selectors";
import {
  TeaserOverlayWrapper,
  ContentWrapper,
  ContentWrapperCopy,
  ContentWrapperButton,
  StyledIcon,
  StyledHeadline,
  StyledText,
} from "./styled";

export default class TeaserOverlay extends React.PureComponent {
  constructor(props) {
    super(props);
    this.TeaserRef = React.createRef();
    const trackingKeySelector = selectTrackingKey;
    this.regularShipmentTeaserDisplayedTrackingKeyPath = trackingKeySelector(
      "spotShipment.interactions.regularShipmentTeaserDisplayed"
    );
    this.regularShipmentTeaserButtonClickedTrackingKeyPath = trackingKeySelector(
      "spotShipment.interactions.regularShipmentTeaserButtonClicked"
    );
    this.regularShipmentTeaserClosedTrackingKeyPath = trackingKeySelector(
      "spotShipment.interactions.regularShipmentTeaserClosed"
    );
    this.state = {
      isOpen: props.isOpen,
    };
  }

  componentDidMount() {
    const { openDelay, trackEvent } = this.props;

    setTimeout(() => {
      this.setState({
        isOpen: true,
      });
    }, openDelay);

    trackEvent({ regularShipmentTeaserDisplayed: true });
  }

  teaserButtonClick = () => {
    const {
      teaserButtonClick,
      spotShipmentTabReference,
      trackEvent,
    } = this.props;

    const timeout = 1000;

    setTimeout(() => {
      teaserButtonClick({ tabIndex: 1 });
    }, timeout);

    this.onClose();
    trackEvent({ regularShipmentTeaserButtonClicked: true });

    scrollToComponent(spotShipmentTabReference.current, {
      offset: -100,
      align: "top",
    });
  };

  closeIconClick = () => {
    const { trackEvent } = this.props;

    this.onClose();
    trackEvent({ regularShipmentTeaserClosed: true });
  };

  onClose = () => {
    const { toggleRegularShipmentTeaser } = this.props;

    this.setState({
      isOpen: false,
    });

    const isRegularUser = false;
    toggleRegularShipmentTeaser({ isRegularUser });
  };

  hasButton = () => {
    const { buttonText } = this.props;

    if (buttonText) {
      return true;
    }
    return false;
  };

  render() {
    const { buttonText, headlineText, copyText } = this.props;

    const { isOpen } = this.state;

    return (
      <TeaserOverlayWrapper
        ref={this.TeaserRef}
        className={isOpen ? "active" : ""}
        data-tracking={this.regularShipmentTeaserDisplayedTrackingKeyPath}
      >
        <ContentWrapper className="grid-maxWidthInner">
          <ContentWrapperCopy>
            <StyledHeadline priority={4}>{headlineText}</StyledHeadline>
            <StyledText isParagraph>{copyText}</StyledText>
          </ContentWrapperCopy>
          {this.hasButton() && (
            <ContentWrapperButton>
              <Button
                name="button"
                type="button"
                onClick={this.teaserButtonClick}
                data-tracking={
                  this.regularShipmentTeaserButtonClickedTrackingKeyPath
                }
              >
                {buttonText}
              </Button>
            </ContentWrapperButton>
          )}
        </ContentWrapper>
        <StyledIcon>
          <Icon
            icon={closeIcon}
            label="Close"
            useAs="standalone"
            size="intermediate"
            fillColor={brandPalette.dhlRed}
            isClickable
            onClick={this.closeIconClick}
            buttonBackgroundColor="transparent"
            tabIndex="0"
            dataTracking={this.regularShipmentTeaserClosedTrackingKeyPath}
          />
        </StyledIcon>
      </TeaserOverlayWrapper>
    );
  }
}

TeaserOverlay.propTypes = {
  /** Button text. If its missing the button will not be mounted.  */
  buttonText: PropTypes.string,
  /** Headline text is required  */
  headlineText: PropTypes.string.isRequired,
  /** Copy text is required  */
  copyText: PropTypes.string.isRequired,
  /** When the teaser should display on load. Default is false.  */
  isOpen: PropTypes.bool,
  /** Delay of opening teaser after mounted. Default is 2s  */
  openDelay: PropTypes.number,
  spotShipmentTabReference: PropTypes.object,
  teaserButtonClick: PropTypes.func,
  toggleRegularShipmentTeaser: PropTypes.func,
  /** function to add values to the digitalLayer for tracking purposes.
   * Receives an object, the key is the property that will recieve the data in the digitalLayer
   * and the value is the data that it needs */
  trackEvent: PropTypes.func.isRequired,
};

TeaserOverlay.defaultProps = {
  isOpen: false,
  openDelay: 2000,
  buttonText: "",
  spotShipmentTabReference: {},
  teaserButtonClick: () => {},
  toggleRegularShipmentTeaser: () => {},
};
