// 9fbef606107a605d69c0edbcd8029e5d

/**
 * PrintButton
 */

import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { IntlPropType, trackContentInteraction } from "root/libs/core-libs/src";
import { Button, BUTTON_VARIANTS } from "@dhl-official/react-ui-library";

const COPY_ID_PREFIX = "PrintButton";

const PrintButton = (props) => {
  const { dataTestid, dataTracking, intl, utfDataObject } = props;

  function onClick() {
    const { trackEvent, trackingValue } = props;

    if (
      document &&
      document.queryCommandSupported &&
      document.queryCommandSupported("print")
    ) {
      document.execCommand("print", false, null);
    } else if (window && window.print) {
      window.print();
    }

    trackEvent(trackingValue);
    trackContentInteraction(utfDataObject);
  }

  return (
    <Button
      name="button"
      variant={BUTTON_VARIANTS.SECONDARY}
      type="button"
      dataTestid={dataTestid}
      onClick={onClick}
      dataTracking={dataTracking}
    >
      {intl.formatMessage({ id: `${COPY_ID_PREFIX}.label` })}
    </Button>
  );
};

PrintButton.propTypes = {
  intl: IntlPropType.isRequired,
  dataTestid: PropTypes.string,
  dataTracking: PropTypes.string,
  trackEvent: PropTypes.func,
  trackingValue: PropTypes.object,
  utfDataObject: PropTypes.object,
};

PrintButton.defaultProps = {
  dataTestid: undefined,
  dataTracking: "",
  trackEvent: () => {},
  trackingValue: {},
  utfDataObject: {},
};

export default injectIntl(PrintButton);
