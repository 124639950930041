// 9fbef606107a605d69c0edbcd8029e5d

import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import urlNormalizer from "./urlNormalizer";
import decodeTranslation from "../string/decodeTranslation";
import { logging } from "../devTools";
import { COMMON } from "root/libs/constants/src";

const devModeOptions = {
  API: "api",
  COPY: "copy",
  SETTINGS: "settings",
};

function isRemoteApiEnabled({ devMode }) {
  let isEnabled = true;

  try {
    if (!Object.values(devModeOptions).includes(devMode)) {
      const errorMessage = `devMode options can be one of these: ${devModeOptions.join()}. 
                            Your query param value or call in the code has a typo`;
      throw new TypeError(errorMessage, "core-libs/network/http.js", 10);
    }
    isEnabled = !window.location.search.includes(`devMode=${devMode}`);
  } catch (e) {
    logging.warn(e);
  }

  return isEnabled;
}

function getUrl({ endpoint, urls, apiBaseUri }) {
  const endpointToRequest = urls.endpoints[endpoint];

  return urlNormalizer(`${apiBaseUri}/${endpointToRequest}`);
}

const fetchCopy = (copyUrl) =>
  axios
    .get(urlNormalizer(copyUrl))
    .then(({ data }) => decodeTranslation(data.labels));

const getTranslations = (getDefault, getCopyUrl) => (locale) => {
  const useRemote = isRemoteApiEnabled({ devMode: devModeOptions.COPY });

  if (useRemote) {
    const copyPromise = new Promise((resolver) => {
      return resolver(fetchCopy(getCopyUrl(locale)));
    });

    return copyPromise.then((copy) => ({ ...copy }));
  }

  return getDefault(locale).then((copy) => decodeTranslation(copy));
};

/**
 * Generates a new UUID and removes the hyphens.
 * Used in the TradeLane to generate a correlation ID.
 * @returns {string} The generated UUID.
 */
function getCorrelationID() {
  return uuidv4().replace(/-/g, "");
}

/**
 * Generates correlation headers for a request.
 * The headers include a trace ID, a span ID, and a traceparent ID.
 * The trace ID is the first 32 characters of the UUID.
 * The span ID is the characters 32 to 48 of the UUID.
 * The traceparent ID is a combination of the trace ID and span ID.
 * The traceparent ID is used to trace the request across services.
 * The traceId and spanId are used to trace the request within a service.
 * traceparent is a W3C standard for distributed tracing.
 * X-B3-TraceId and X-B3-SpanId are legacy Springboot headers.
 * We keep both headers to support both the old Springboot version and W3C tracing.
 * @returns {object} The correlation headers.
 */
const getCorrelationHeaders = () => {
  const uuid = getCorrelationID() + getCorrelationID();
  const traceId = uuid?.substring(0, 32) || "";
  const spanId = uuid?.substring(32, 48) || "";
  const traceparent = `00-${traceId}-${spanId}-01`;

  return {
    [COMMON.CORRELATION_TRACE_ID]: traceId,
    [COMMON.CORRELATION_SPAN_ID]: spanId,
    [COMMON.TRACEPARENT]: traceparent,
  };
};

export {
  isRemoteApiEnabled,
  getUrl,
  getTranslations,
  devModeOptions,
  getCorrelationID,
  getCorrelationHeaders,
};
