// 9fbef606107a605d69c0edbcd8029e5d

import styled from "styled-components";
import { Text } from "@dhl-official/react-ui-library";
import { breakpoints } from "root/libs/ui-styleguide/src";

export const IndicatorWrapper = styled.div`
  word-break: break-all;
  display: flex;
  transition: opacity 0.4s ease-in-out;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  height: ${(props) => (props.isVisible ? "100%" : 0)};
  overflow: ${(props) => (props.hasError ? "hidden" : "visible")};

  @media screen and (min-width: ${breakpoints.desktop}) {
    width: 105px;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    width: 120px;
  }
`;

export const IconWrapper = styled.div`
  margin-right: 3px;
`;

export const StyledTextWrapper = styled(Text)`
  display: block;
`;

export const StyledText = styled(Text)`
  display: inline-block;
  padding-right: 3px;

  & + & {
    padding-right: 0;
  }
`;
