// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for PresetCard
 *
 */

import styled, { css } from "styled-components";
import { key } from "styled-theme";
import { Icon } from "root/libs/ui-components/src";
import { Card, Text } from "@dhl-official/react-ui-library";
import { breakpoints } from "root/libs/ui-styleguide/src";

const cardSize = css`
  width: calc(50% - 0.4rem);
  height: auto;
  margin-bottom: 10px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: auto;

    min-width: ${(props) =>
      props.appearance === "small" ? "17.9rem" : "20.5rem"};
    height: 7.5rem;
    margin-bottom: 0;
  }
`;

export const StyledCard = styled(Card)`
  position: relative;
  display: inline-block;
  padding: 0;
  box-shadow: 0 3px 8px 0 ${key("colors.lightBorderColor")};
  border: solid 1px ${key("colors.lighterBorderColor")};
  background-color: ${key("colors.white")};

  &:nth-child(odd) {
    margin-right: 0.7rem;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    &:not(:last-of-type) {
      margin-right: 1.2rem;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  ${cardSize}
`;

export const ButtonItself = styled.button`
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 4px;
  transition: box-shadow 0.3s ease-out;
  line-height: ${key("fonts.desktop.button.lineHeight")};
  text-align: center;
  text-decoration: none;
  color: ${key("colors.fontColor")};
  min-height: 110px;
  width: 100%;
  position: relative;
  font-weight: 700;
  padding: 0;
  font-size: ${key("fonts.mobile.button.size")};
  flex-direction: column;
  display: flex;
  align-items: center;
  min-width: inherit;

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    height: 7.5rem;
    min-height: 7.5rem;
    font-size: ${key("fonts.desktop.copy.size")};
  }

  &:hover,
  &:focus,
  &:focus-within,
  &:active {
    box-shadow: 0 2px 7px 1px ${key("colors.shadowLightHover")};
    outline: 0;
  }

  &.selected {
    border-color: ${key("colors.primary")};
    transition: opacity 0.3s ease-out;
  }

  &:not(.selected):hover {
    border-color: transparent;
  }

  &.selected + .toggleIcon {
    opacity: 1;
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    .content-icon {
      margin-bottom: 19px;

      &:not(.presetXS) {
        transform: translateX(-5%) translateY(29%);
      }
    }

    .presetXS svg {
      height: 45px;
      transform: translateY(15%);
      margin-top: 5px;
    }
  }

  .content-icon {
    width: auto;

    @media screen and (min-width: ${breakpoints.tablet}) {
      margin-left: -2px;
      margin-bottom: 10px;
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      margin-bottom: 0;
    }

    /* Fix: Pallet icons are glued to the right, this is a problem in RTL where we don't flip the icons */
    &.palletEurOne,
    &.palletEurThree,
    &.nlPaletEur1,
    &.nlPaletEur3,
    &.auStandardPallet,
    &.auExportPallet,
    &.us1Pallet,
    &.mxStandardPallet,
    &.eur1Pallet,
    &.eur3Pallet,
    &.cp3Pallet {
      /*! @noflip */
      margin-right: 3px !important;
    }
  }
`;

export const CardContent = styled.div`
  z-index: ${(props) => (props.cssZIndex ? props.cssZIndex : "0")};
  top: 0;
  box-sizing: content-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  justify-content: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    position: absolute;
    justify-content: flex-start;
    flex-wrap: inherit;
    min-width: 100%;
    height: 100%;
    min-height: auto;
    padding: 0;
  }
`;

export const BoxWrapper = styled.div`
  display: block;
  width: 100%;
  text-align: left;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-left: 4px;
    width: auto;
  }
`;

export const StyledText = styled(Text)`
  margin: 0;
  max-width: inherit;
  display: block;
  text-align: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    max-width: 12.9rem;
    text-align: inherit;
  }
`;

export const StyledTextSizes = styled(Text)`
  display: block;
  text-align: center;
  /*! @noflip */
  direction: ltr;

  @media screen and (min-width: ${breakpoints.tablet}) {
    text-align: inherit;
  }
`;

export const StyledIconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-right: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-top: 15px solid ${key("colors.primary")};
  border-left: 15px solid ${key("colors.primary")};
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s ease-out;
`;

export const StyledIcon = styled(Icon)`
  position: absolute;
  top: -12px;
  left: -11px;
  display: block;
`;
