// 9fbef606107a605d69c0edbcd8029e5d

/**
 * This is a provider of the theme property for components of react
 * as well as object literals of specific variables for direct usage in
 * in components. Normally using getTheme should be enough.
 */
import uiConfig from "../config/ui";
import dhlColors from "./dhl/vars.colors";
import dhlFonts from "./dhl/vars.fonts";

const themes = {
  dhl: {
    colors: dhlColors,
    fonts: dhlFonts,
  },
};

/**
 * Gets the object where the fonts of the theme are defined.
 * @param {String} themeName Name of the theme for which we will get the fonts.
 */
function withFonts(themeName) {
  return themes[themeName].fonts;
}

/**
 * Gets the object where the color variables of the theme are defined.
 * @param {String} themeName Name of the theme for which we will get the colors.
 */
function withColors(themeName) {
  return themes[themeName].colors;
}

/**
 * Gets an object that contains the theme to use in the components.
 * @param {Object} props properties of the component
 */
function getTheme(props) {
  return props.theme
    ? { ...themes[uiConfig.uiTheme], ...props.theme }
    : themes[uiConfig.uiTheme];
}

const theme = getTheme({});

export { getTheme, withFonts, withColors, theme, themes };
