// 9fbef606107a605d69c0edbcd8029e5d

import { createSelector } from "reselect";
import filter from "lodash/filter";
import map from "lodash/map";
import isArray from "lodash/isArray";

const validitySelector = (state, stateToGet) => state[stateToGet].validity;

/**
 * @param   array  fields   Array of objects that contains the items to iterate
 * @return  array           Array of objects with the items with errors
 */
const getInvalidFieldsFromArray = (fields) => {
  let elementsWithErrors = [];
  const validityArrays = fields.map((fieldElement) => {
    const filteredElement = filter(
      fieldElement,
      (elementObject) => elementObject.hasError === true
    );
    return filteredElement;
  });

  elementsWithErrors = filter(
    validityArrays,
    (itemWithValidityArray) => itemWithValidityArray.length !== 0
  );
  return elementsWithErrors;
};

/**
 * A state can have an object of objects (like the state of the tradeLane),
 * or an array of objects (like the shipmentLane).
 * This selector will tell us if there are errors in a state
 *
 * @return boolean  Tells if the state passed has errors
 */
const makeIsFormValidSelector = createSelector(validitySelector, (validity) => {
  let fieldsWithErrors = [];
  // Validity can be an array or an object. Therefore iterating with lodash/map that supports both
  map(validity, (field) => {
    let fieldsInvalid;

    if (isArray(field)) {
      fieldsInvalid = getInvalidFieldsFromArray(field);
    } else {
      fieldsInvalid = field.hasError === true ? field : [];
    }
    const hasFieldsWithErrors = fieldsInvalid.length !== 0;
    if (hasFieldsWithErrors) {
      fieldsWithErrors = fieldsWithErrors.concat(fieldsInvalid);
    }
  });

  return fieldsWithErrors.length === 0;
});

export { getInvalidFieldsFromArray, makeIsFormValidSelector };
