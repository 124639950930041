// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * VatInfoMessage
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { TYPES_OF_OFFERS } from "one-time-shipment/src/containers/GetQuote/constants";
import { StyledMessage, StyledMessageWCC } from "./styled";

export default class VatInfoMessage extends React.PureComponent {
  static propTypes = {
    priceBreakdownAnchor: PropTypes.func,
    priceBreakdownRef: PropTypes.string,
    className: PropTypes.string,
    offerKey: PropTypes.string,
    vatInfoVariation: PropTypes.string,
  };

  static defaultProps = {
    className: undefined,
    offerKey: "",
  };

  onClick = (e) => {
    const { priceBreakdownAnchor } = this.props;

    e.preventDefault();

    priceBreakdownAnchor();
  };

  render() {
    const {
      priceBreakdownRef,
      className,
      offerKey,
      vatInfoVariation,
    } = this.props;

    if (vatInfoVariation === TYPES_OF_OFFERS.WCC) {
      return (
        <StyledMessageWCC
          className={className}
          id={`${offerKey}-businessVat`}
          href={priceBreakdownRef}
          onClick={(e) => this.onClick(e)}
        >
          <FormattedMessage id="VatInfoMessage.taxInfo" />
        </StyledMessageWCC>
      );
    }

    return (
      <StyledMessage className={className} id={`${offerKey}-businessVat`}>
        <FormattedMessage id="VatInfoMessage.taxInfo" />
      </StyledMessage>
    );
  }
}
