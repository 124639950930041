// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * PresetSizeCardGroup Component
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { IntlPropType } from "root/libs/core-libs/src";
import PresetCard from "one-time-shipment/src/components/Forms/PresetCard";
import { selectTrackingKey } from "one-time-shipment/src/containers/Tracking/selectors";
import {
  PresetSizeCardGroupWrapper,
  GradientWrapper,
  ScrollFlexWrapper,
} from "./styled";

const COPY_ID_PREFIX = "PresetSizeCardGroup";

class PresetSizeCardGroup extends React.PureComponent {
  static propTypes = {
    /** Translations library  */
    intl: IntlPropType.isRequired,
    onPresetSelected: PropTypes.func.isRequired,
    selectedPresetSize: PropTypes.string,
    presetSizes: PropTypes.array,
    unit: PropTypes.string.isRequired,
    selectedUnitSystem: PropTypes.string.isRequired,
    itemIndex: PropTypes.number,
  };

  static defaultProps = {
    selectedPresetSize: "",
    presetSizes: [],
    itemIndex: 0,
  };

  constructor(props) {
    super(props);
    this.references = props.presetSizes.map(() => React.createRef());
    this.presetSizeTrackingKeyPath = "";

    this.trackingKeySelector = selectTrackingKey;
  }

  componentDidMount() {
    const { itemIndex } = this.props;

    setTimeout(() => {
      this.presetSizeTrackingKeyPath = this.trackingKeySelector(
        `spotShipment.initialSet.items[${itemIndex}].presetSize`
      );
    }, 0);
  }

  onPresetSelected = (selectedPreset, presetSize) => () => {
    const { onPresetSelected } = this.props;

    onPresetSelected({ ...selectedPreset, presetSize });
  };

  getCopy = (id, replaceStrObj) => {
    const { intl } = this.props;
    return intl.formatMessage({ id }, { ...replaceStrObj });
  };

  render() {
    const {
      presetSizes,
      selectedPresetSize,
      unit,
      selectedUnitSystem,
    } = this.props;

    return (
      <GradientWrapper>
        <ScrollFlexWrapper>
          <PresetSizeCardGroupWrapper>
            {presetSizes.map(
              (preset, index) =>
                !!preset[selectedUnitSystem] && (
                  <PresetCard
                    key={preset.key}
                    presetIcon={preset.svg}
                    unitLabel={this.getCopy(
                      !preset[selectedUnitSystem].height
                        ? `${COPY_ID_PREFIX}.dimensionsLabelWithoutHeight`
                        : `${COPY_ID_PREFIX}.dimensionsLabel`,
                      {
                        presetLabel: preset.label,
                        valuesLength: preset[selectedUnitSystem].length,
                        valuesWidth: preset[selectedUnitSystem].width,
                        valuesHeight: preset[selectedUnitSystem].height,
                        unitLabel: unit,
                      }
                    )}
                    appearance={presetSizes.length <= 4 ? "default" : "small"}
                    preset={preset}
                    presetSizeTrackingKeyPath={this.presetSizeTrackingKeyPath}
                    isChecked={selectedPresetSize === preset.presetSize}
                    onChange={this.onPresetSelected(preset[selectedUnitSystem], preset.presetSize)}
                    reference={this.references[index]}
                  />
                )
            )}
          </PresetSizeCardGroupWrapper>
        </ScrollFlexWrapper>
      </GradientWrapper>
    );
  }
}

export default injectIntl(PresetSizeCardGroup);
