// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for IconButton
 *
 */

import styled, { css } from "styled-components";
import { key } from "styled-theme";
import { sizes, breakpoints, colors } from "root/libs/ui-styleguide/src";
import Icon from "../../Icons/Icon";

const hoverButton = css`
  &:hover {
    outline: 1px solid transparent;
    border: 1px solid transparent;
  }
`;

const primaryButton = css`
  background-color: ${key("colors.primary")};
  transition: ease-in-out 200ms background-color;

  &:focus {
    box-shadow: 0 0 0 4px white, 0 0 0 6px ${key("colors.primary")};
  }

  /* Leave this after the focus style to prevent the box-shadow to appear on hover of a focused button */
  &:hover {
    background-color: ${key("colors.primaryHover")};
    box-shadow: unset;
  }

  &:disabled {
    color: ${colors.white};
    border: 1px solid transparent;
    background-color: ${key("colors.lightBorderColor")};
  }
`;

const secondaryButton = css`
  background-color: ${key("colors.backgroundColorInverted")};
  color: ${key("colors.primary")};
  border: 1px solid ${key("colors.primary")};

  &:focus,
  &:active {
    box-shadow: 0 0 0 4px white, 0 0 0 6px ${key("colors.primary")};
    border-width: 2px;
    padding: calc(${sizes.inputVerticalPaddingsBig} - 1px) calc(5px - 1px);
  }

  /* Leave this after the focus style to prevent the box-shadow to appear on hover of a focused button */
  &:hover {
    border-color: ${key("colors.primaryHover")};
    color: ${key("colors.primaryHover")};
    box-shadow: unset;
  }

  &:disabled {
    background-color: ${key("colors.backgroundColorInverted")};
    color: ${colors.silver};
    border-color: ${key("colors.lightBorderColor")};
  }
`;

const transparentButton = css`
  ${secondaryButton}
  background-color: transparent;

  &:focus,
  &:active {
    box-shadow: 0 0 0 4px ${key("colors.secondary")},
      0 0 0 6px ${key("colors.primary")};
    border-width: 2px;
  }
`;

const tertiaryButton = css`
  transition: box-shadow 0.3s ease-out;
  color: ${key("colors.fontColor")};
  background-color: ${key("colors.backgroundColorInverted")};
  border-color: ${key("colors.backgroundColorInverted")};
  font-weight: 800;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);

  &:focus,
  &:active {
    transition: none;
    border: 2px solid ${key("colors.fontColor")};
    padding: calc(${sizes.inputVerticalPaddingsBig} - 1px) calc(5px - 1px);
  }

  /* Leave this after the focus style to prevent the box-shadow to appear on hover of a focused button */
  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }

  &:disabled {
    color: ${colors.silver};

    svg {
      fill: ${colors.silver};
    }
  }
`;

export const StyledIconButton = styled(Icon)`
  border-radius: ${(props) => (props.variation === "circle" ? "50%" : "4px")};
  width: ${(props) => props.buttonWidth.mobile};
  height: ${(props) => props.buttonHeight.mobile};
  text-align: center;
  line-height: ${key("fonts.desktop.button.lineHeight")};
  padding: 0;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: ${(props) => props.buttonWidth.desktop};
    height: ${(props) => props.buttonHeight.desktop};
  }

  ${(props) => (props.buttonStyle === "primary" ? primaryButton : "")}
  ${(props) => (props.buttonStyle === "secondary" ? secondaryButton : "")}
  ${(props) => (props.buttonStyle === "transparent" ? transparentButton : "")}
  ${(props) => (props.buttonStyle === "tertiary" ? tertiaryButton : "")}
  ${hoverButton}
`;
