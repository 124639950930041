// 9fbef606107a605d69c0edbcd8029e5d

/**
 * This file contains the styles for DropdownField
 */
import styled, { css } from "styled-components";
import chevronDown from "icons/navigation/chevron-down.svg";
import { breakpoints } from "root/libs/ui-styleguide/src";
import Icon from "../../Icons/Icon/index";

const COMPUTED_DROPDOWN_HEIGHT = "5.2rem";
const COMPUTED_DROPDOWN_HEIGHT_MOBILE = "6.4rem";
const SPACING137X = "17.12rem";

const DISABLED_COLOR = "#545454";
const ACTIVE_ITEM = "#ebebeb";

export const TextSrOnly = styled.span`
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

// Calculates the container height minus the border
export const Container = styled.div`
  height: ${COMPUTED_DROPDOWN_HEIGHT_MOBILE};
  position: relative;
  width: ${({ isBlock }) => (isBlock ? "100%" : SPACING137X)};

  @media screen and (min-width: ${breakpoints.desktop}) {
    height: ${COMPUTED_DROPDOWN_HEIGHT};
  }

  &[data-disabled="true"] {
    color: ${DISABLED_COLOR};
    opacity: 0.4;
  }
`;

export const ContentContainer = styled.div`
  cursor: pointer;
  align-items: center;
  background: #fff;
  border: calc(1.6 * 0.063rem) solid transparent;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: space-between;
  outline: none;
  padding: 0 calc(1.6 * 1rem);
  width: 100%;
  transition: ease-in-out 200ms box-shadow;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1), 0 2px 12px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.1);

  :focus:not([data-open="true"]) {
    box-shadow: none;
    border: calc(1.6 * 0.125rem) solid
      ${css`rgba(0, 0, 0, ${(props) => (props["data-disabled"] ? 0.4 : 1)})`};
    padding: 0 calc(calc(1.6 * 1rem) - calc(1.6 * 0.063rem));
  }

  &:hover:not([data-open="true"]) {
    box-shadow: 0 2px 7px 1px #b2b2b2;
  }

  &[data-open="true"] {
    border: calc(1.6 * 0.125rem) solid
      ${css`rgba(0, 0, 0, ${(props) => (props["data-disabled"] ? 0.4 : 1)})`};
    border-bottom-color: transparent;
    border-end-end-radius: 0;
    border-end-start-radius: 0;
    padding: 0 calc(calc(1.6 * 1rem) - calc(1.6 * 0.063rem));
  }
`;

export const ArrowContainer = styled.div`
  align-items: center;
  background: #fff;
  border-color: transparent;
  box-sizing: border-box;
  display: flex;
  height: calc(1.6 * 1.5rem);
  pointer-events: none;
  width: calc(1.6 * 1.5rem);
  position: absolute;
  right: calc(1.3 * 1rem);
  top: calc((${COMPUTED_DROPDOWN_HEIGHT_MOBILE} / 2) - 1.25rem);

  @media screen and (min-width: ${breakpoints.desktop}) {
    top: calc((${COMPUTED_DROPDOWN_HEIGHT} / 2) - 1.25rem);
  }
`;

export const ArrowIcon = styled(Icon).attrs({
  icon: chevronDown,
  useAs: "decorative",
  fillColor: "black",
  width: {
    mobile: "20",
    desktop: "20",
  },
  height: {
    mobile: "20",
    desktop: "20",
  },
})`
  height: calc(1.6 * 1.25rem);
  transform: ${({ open }) => (open ? css`rotate(180deg)` : css`rotate(0deg)`)};
  width: calc(1.6 * 1.25rem);
`;

export const StyledLabel = styled.label`
  box-sizing: border-box;
  left: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  white-space: nowrap;
  height: 0;
  width: 0;
`;

export const StyledPlaceholder = styled.span`
  box-sizing: border-box;
  color: #8c8c8c;
  display: block;
  font-size: calc(1.6 * 1rem);
  left: 0;
  line-height: 1;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: relative;
  top: 0;
  white-space: nowrap;
  width: calc(100% - 1.6rem);

  @media screen and (min-width: ${breakpoints.desktop}) {
    line-height: 1.2;
  }
`;

export const HiddenInput = styled.input.attrs({
  noValidate: true,
  tabIndex: "-1",
  "aria-hidden": true,
})`
  appearance: none;
  height: 0;
  width: 0;
  border: 0;
  opacity: 0;
  position: absolute;
`;

export const LabelPlaceholderContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const ListItemBackground = styled.div`
  background: #191919;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
`;

export const OptionListItem = styled.li`
  appearance: none;
  box-sizing: border-box;
  height: calc(1.6 * 4rem);
  line-height: 1;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
  width: 100%;

  @media screen and (min-width: ${breakpoints.desktop}) {
    height: calc(1.6 * 3rem);
  }

  &.active {
    background: ${ACTIVE_ITEM} !important;
  }
`;

export const Options = styled.ul.attrs({
  tabIndex: "-1",
  role: "listbox",
})`
  list-style-type: none;
  margin: 0;
  max-height: 14.6rem;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
`;
