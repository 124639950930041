// 9fbef606107a605d69c0edbcd8029e5d

import styled, { css } from "styled-components";
import { breakpoints, grid } from "root/libs/ui-styleguide/src";

export const uniDimensionalContainer = css`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const biDimensionalContainer = css`
  @media screen and (min-width: ${breakpoints.desktop}) {
    max-width: ${grid.container.maxWidthInnerContainer}px;
  }

  @supports (display: grid) {
    max-width: auto;
    display: grid;
    grid-template-columns: repeat(${grid.columns.number}, 1fr);
    grid-gap: ${grid.gutter.s};

    @media screen and (min-width: ${breakpoints.tablet}) {
      grid-gap: ${grid.gutter.m};
      grid-template-columns: repeat(
        ${grid.columns.number},
        ${grid.columns.width.m}
      );
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      grid-gap: ${grid.gutter.l};
      grid-template-columns: repeat(
        ${grid.columns.number},
        ${grid.columns.width.l}
      );
    }
  }
`;

export const StyledGridContainer = styled.div`
  margin-bottom: ${(props) => props.bottomSpacing[0]};
  justify-content: ${(props) => props.gridJustifyContent};
  ${(props) =>
    props.gridDimension === "uniDimensional"
      ? uniDimensionalContainer
      : biDimensionalContainer};
  ${(props) =>
    props.halfGutterPadding ? "padding-left: 40px; padding-right: 40px;" : ""}

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${(props) => props.bottomSpacing[1]};
  }

  @supports (display: grid) {
    @media screen and (min-width: ${breakpoints.tablet}) {
      padding-left: ${grid.container.paddings.m};
      padding-right: ${grid.container.paddings.m};
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      padding-left: ${grid.container.paddings.l};
      padding-right: ${grid.container.paddings.l};
    }
  }

  @media print {
    & > button {
      display: none;
    }
  }
`;

export const biDimemsionalCell = css`
  @supports (display: grid) {
    grid-column-end: span
      ${(props) => (props.spanMobile ? props.spanMobile : 12)};
    grid-column-start: ${(props) =>
      props.columnStartMobile ? props.columnStartMobile : 1};

    @media screen and (min-width: ${breakpoints.tablet}) {
      grid-column-end: span
        ${(props) => (props.spanTablet ? props.spanTablet : 12)};
      grid-column-start: ${(props) =>
        props.columnStartTablet ? props.columnStartTablet : 1};
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      grid-column-end: span
        ${(props) => (props.spanDesktop ? props.spanDesktop : 5)};
      grid-column-start: ${(props) =>
        props.columnStart ? props.columnStart : 2};
      ${(props) =>
        props.gridColumn ? `grid-column: ${props.gridColumn}` : ""};
    }
  }
`;

export const containerPadding = css`
  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-left: ${grid.container.paddings.l};
    padding-right: ${grid.container.paddings.l};
  }
`;

function percent(value, maxValue) {
  return (value * 100) / maxValue;
}
const columnPlusGutter = 103;
export const cell = {
  uniDimensional: (span, startColumn = 0) => `
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 100%;
    padding-right: ${grid.container.paddings.s};
    max-width: 100%;

    @media screen and (min-width: ${breakpoints.tablet}) {
      padding-right: ${grid.gutter.l} !important;
      max-width: ${percent(
        span * columnPlusGutter,
        grid.container.maxWidthNumber
      )}%;
      margin-left: ${startColumn * columnPlusGutter}px !important;
    }
  `,
};

export const containerPaddingML = css`
  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-left: ${grid.container.paddings.m};
    padding-right: ${grid.container.paddings.m};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-left: ${grid.container.paddings.l};
    padding-right: ${grid.container.paddings.l};
  }
`;
