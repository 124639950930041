// 9fbef606107a605d69c0edbcd8029e5d

import curry from "lodash/curry";
import always from "lodash/fp/always";

const FORM_FIELD_DATA_TYPE = {
  VALID: "Valid",
  INVALID: "Invalid",
  UNCHECKED: "Unchecked",
  PRISTINE: "Pristine",
};

const NOOP = Function.prototype;

const FormField = {
  chain: (chainFun, formField) =>
    chainFun(formField && formField.value ? formField.value : { value: "" }),

  map: curry((mapFun, formField) =>
    FormField.case(
      {
        invalid: (data, feedback) => FormField.invalid(feedback, mapFun(data)),
        pristine: (data) => FormField.pristine(mapFun(data)),
        unchecked: (data) => FormField.unchecked(mapFun(data)),
        valid: (data) => FormField.valid(mapFun(data)),
      },
      formField
    )
  ),

  valid: (value) => ({
    value,
    type: FORM_FIELD_DATA_TYPE.VALID,
  }),

  invalid: curry((feedback, value) => ({
    feedback,
    value,
    type: FORM_FIELD_DATA_TYPE.INVALID,
  })),

  unchecked: (value) => ({
    value,
    type: FORM_FIELD_DATA_TYPE.UNCHECKED,
  }),

  pristine: (value) => ({
    value,
    type: FORM_FIELD_DATA_TYPE.PRISTINE,
  }),

  case: curry((caseObj, formField) => {
    const { valid, invalid, unchecked, pristine, _ = NOOP } = caseObj;

    if (valid && FormField.isValid(formField)) {
      return valid(formField.value);
    }

    if (invalid && FormField.isInvalid(formField)) {
      return invalid(formField.value, formField.feedback);
    }

    if (unchecked && FormField.isUnchecked(formField)) {
      return unchecked(formField.value);
    }

    if (pristine && FormField.isPristine(formField)) {
      return pristine(formField.value);
    }

    return _();
  }),

  getFeedbackWithDefault: curry((defaultValue, formField) =>
    FormField.case({
      invalid: (_, feedback) => feedback,
      valid: always({
        feedbackMessageId: undefined,
        hasError: false,
        isValid: true,
      }),
      _: always(defaultValue),
    })(formField)
  ),

  isValid: (formField) => formField.type === FORM_FIELD_DATA_TYPE.VALID,

  isInvalid: (formField) => formField.type === FORM_FIELD_DATA_TYPE.INVALID,

  isUnchecked: (formField) => formField.type === FORM_FIELD_DATA_TYPE.UNCHECKED,

  isPristine: (formField) => formField.type === FORM_FIELD_DATA_TYPE.PRISTINE,

  getValue: (formField) => formField.value,
};

export default FormField;
