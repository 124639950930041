// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Loading Indicator
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { StyledContent } from "./styled";

export default class LoadingIndicator extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    iconSize: PropTypes.string,
    isVisible: PropTypes.bool,
  };

  static defaultProps = {
    className: "",
    iconSize: "64px",
    isVisible: true,
  };

  render() {
    const { className, iconSize, isVisible } = this.props;

    return (
      <StyledContent
        className={className}
        iconSize={iconSize}
        isVisible={isVisible}
      >
        <svg
          width={iconSize}
          height={iconSize}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
          className="uil-ripple"
        >
          <rect
            x="0"
            y="0"
            width="100"
            height="100"
            fill="none"
            className="bk"
          />
          <g>
            {" "}
            <animate
              attributeName="opacity"
              dur="2s"
              repeatCount="indefinite"
              begin="0s"
              keyTimes="0;0.33;1"
              values="1;1;0"
            />
            <circle
              className="grey-circle"
              cx="50"
              cy="50"
              r="40"
              stroke="#afafb7"
              fill="none"
              strokeWidth="6"
              strokeLinecap="round"
            >
              <animate
                attributeName="r"
                dur="2s"
                repeatCount="indefinite"
                begin="0s"
                keyTimes="0;0.33;1"
                values="0;22;44"
              />
            </circle>
          </g>
          <g>
            <animate
              attributeName="opacity"
              dur="2s"
              repeatCount="indefinite"
              begin="0s"
              keyTimes="0;0.33;1"
              values="1;1;0"
            />
            <circle
              className="yellow-circle"
              cx="50"
              cy="50"
              r="40"
              stroke="#fc0"
              fill="none"
              strokeWidth="6"
              strokeLinecap="round"
            >
              <animate
                attributeName="r"
                dur="2s"
                repeatCount="indefinite"
                begin="1s"
                keyTimes="0;0.33;1"
                values="0;22;44"
              />
            </circle>
          </g>
        </svg>
      </StyledContent>
    );
  }
}
