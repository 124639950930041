// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * TextAndLink
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { Text } from "@dhl-official/react-ui-library";
import Link from "../../Buttons/Link";

class TextAndLink extends React.PureComponent {
  static propTypes = {
    /** The ID is needed to reference the text to the link for screen readers */
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    dataTracking: PropTypes.string,
    trackEvent: PropTypes.func,
    trackEventKey: PropTypes.string,
    copyString: PropTypes.string.isRequired,
    linkString: PropTypes.string.isRequired,
    linkUrl: PropTypes.string.isRequired,
    linkTarget: PropTypes.string,
    /** Identifier of link element for automation */
    linkDataTestid: PropTypes.string,
    /** If set will add aria-label attribute to the anchor.
     * When focussed the content will be read by screen reader if turned on.
     * This is useful to give the user more information about the link and
     * what is behind it.
     * E.g. this is a link which belongs to a description, it makes sense
     * to add the description to this prop to give this information to
     * screen reader users too.
     */
    linkAriaLabel: PropTypes.string,
    /** If set will add the role attribute to the paragraph */
    textRole: PropTypes.string,
    /** If set the text will be centered */
    center: PropTypes.bool,
    /** If set the link will shown in front of the copy */
    showLinkFirst: PropTypes.bool,
  };

  static defaultProps = {
    className: "",
    dataTracking: "",
    trackEvent: () => {},
    trackEventKey: "",
    center: false,
    linkTarget: "",
    linkDataTestid: undefined,
    linkAriaLabel: "",
    showLinkFirst: false,
    textRole: "",
  };

  onClick = () => {
    const { trackEvent, trackEventKey } = this.props;

    trackEvent({ [trackEventKey]: true });
  };

  render() {
    const {
      id,
      className,
      dataTracking,
      copyString,
      linkString,
      linkUrl,
      linkTarget,
      linkDataTestid,
      linkAriaLabel,
      center,
      textRole,
      showLinkFirst,
    } = this.props;

    const linkExtraProps = {};

    if (linkTarget) {
      linkExtraProps.target = linkTarget;
    }

    if (linkAriaLabel) {
      linkExtraProps.ariaLabel = linkAriaLabel;
    }

    const textExtraProps = {};
    if (textRole) {
      textExtraProps.role = textRole;
    }

    const linkID = `${id}-anchor`;

    return (
      <Text className={className} center={center} id={id} {...textExtraProps}>
        {!showLinkFirst && (
          <React.Fragment>
            {copyString}{" "}
            <Link
              id={linkID}
              href={linkUrl}
              linkType="inline"
              dataTracking={dataTracking}
              onClick={this.onClick}
              ariaLabelledBy={`${id} ${linkID}`}
              dataTestid={linkDataTestid}
              {...linkExtraProps}
            >
              {linkString}
            </Link>
          </React.Fragment>
        )}

        {showLinkFirst && (
          <React.Fragment>
            <Link
              id={linkID}
              href={linkUrl}
              linkType="inline"
              dataTracking={dataTracking}
              onClick={this.onClick}
              ariaLabelledBy={`${id} ${linkID}`}
              dataTestid={linkDataTestid}
              {...linkExtraProps}
            >
              {linkString}
            </Link>{" "}
            {copyString}
          </React.Fragment>
        )}
      </Text>
    );
  }
}

export default TextAndLink;
