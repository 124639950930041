// 9fbef606107a605d69c0edbcd8029e5d

import { css } from "styled-components";
import colors from "../variables/colors";

export const amazingYellow = css`
  background: ${colors.yellowSunFlower02};
  background: linear-gradient(
    to bottom,
    ${colors.yellowSunFlower} 0%,
    ${colors.yellowSunFlower02} 100%
  );
`;

export const amazingYellowHeavy = css`
  background: ${colors.yellowSunFlower02};
  background: linear-gradient(
    to bottom,
    ${colors.yellowSunFlower} 50%,
    ${colors.yellowSunFlower02} 100%
  );
`;
