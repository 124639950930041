// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * WCCOfferPriceAndPaymentInfo
 *
 */

import React from "react";
import { showCurrencyAsCode } from "root/libs/constants/src";
import { IntlPropType, GetCountryCode } from "root/libs/core-libs/src";
import PropTypes from "prop-types";
import { TYPES_OF_OFFERS } from "one-time-shipment/src/containers/GetQuote/constants";
import OfferPaymentIcons from "one-time-shipment/src/components/Offers/OfferPaymentIcons";
import VatInfoMessage from "one-time-shipment/src/components/Offers/VatInfoMessage";
import { CardPriceWrapper, CardPriceHeadline, DisclaimerStar } from "./styled";

const COPY_ID_PREFIX = "OfferPriceAndPaymentInfo";
export default class WCCOfferPriceAndPaymentInfo extends React.PureComponent {
  static propTypes = {
    /** An object that contains all the information required of an offer */
    offer: PropTypes.shape({
      businessUnit: PropTypes.string,
      /** id of the offer */
      key: PropTypes.string,
      id: PropTypes.string,
      /** An array that contains the accepted payment methods */
      acceptedPaymentMethods: PropTypes.arrayOf(PropTypes.string),
      /** Link for Book now button */
      pathToPurchaseLink: PropTypes.string,
      /** Price of the offer */
      price: PropTypes.shape({
        /** A number that contains the price
         */
        amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        /** A valid currency code */
        currencyCode: PropTypes.string,
        tax: PropTypes.string,
      }),
      /** If set will trigger the display of a special scenario card in DGF */
      specialOffers: PropTypes.string,
    }).isRequired,
    /** Internationalization library */
    intl: IntlPropType.isRequired,
    isBusiness: PropTypes.bool,
    showCurrencyIsoCodeForPrices: PropTypes.bool,
    nativeLanguageCode: PropTypes.string,
    formatCurrencyWithNativeLocale: PropTypes.bool,
    offerIndex: PropTypes.number,
  };

  static defaultProps = {
    isBusiness: false,
    showCurrencyIsoCodeForPrices: false,
    nativeLanguageCode: "",
    formatCurrencyWithNativeLocale: false,
  };

  getOfferPrice = () => {
    const { offer } = this.props;

    if (!("price" in offer)) {
      return null;
    }

    if (typeof offer.price.amount !== "undefined" && offer.price.amount > 0) {
      return offer.price.amount;
    }
    return offer.price.amount;
  };

  getOfferCurrency = () => {
    const { offer } = this.props;

    if (!("price" in offer)) {
      return null;
    }

    if (typeof offer.price.currencyCode !== "undefined") {
      return offer.price.currencyCode;
    }
    return null;
  };

  getCopy = (id, values, prefix = COPY_ID_PREFIX) => {
    const { intl } = this.props;
    return intl.formatMessage({ id: `${prefix}.${id}` }, values);
  };

  render() {
    const {
      intl,
      offer,
      isBusiness,
      showCurrencyIsoCodeForPrices,
      nativeLanguageCode,
      formatCurrencyWithNativeLocale,
      offerIndex,
    } = this.props;

    const offerPrice = this.getOfferPrice();
    const currencyCode = this.getOfferCurrency();
    const countryCode = GetCountryCode(intl.locale);
    const priceFormatLocale = formatCurrencyWithNativeLocale
      ? `${nativeLanguageCode}-${countryCode}`
      : intl.locale;
    const isCurrencyCodeAvailable =
      currencyCode !== "" && currencyCode !== null;

    return (
      <React.Fragment>
        {!(offerPrice === null || typeof offerPrice === "undefined") && (
          <React.Fragment>
            <span
              id={`${offer.key}-starBehindPrice`}
              className="visually-hidden"
            >
              {". "}
              {this.getCopy("screenReaderLabel_starBehindPrice")}
            </span>
            <CardPriceWrapper
              id={`${offer.key}-price`}
              data-testid={`wcc-offer-card-price-wrapper-${offerIndex}`}
            >
              {isBusiness && (
                <VatInfoMessage
                  offerKey={offer.key}
                  vatInfoVariation={TYPES_OF_OFFERS.WCC}
                />
              )}
              <span className="visually-hidden">,</span>
              <CardPriceHeadline
                dataTestid="offer-price"
                data-testvalue={offerPrice}
                priority={3}
                weight="700"
                bottomMargin="7px"
              >
                {showCurrencyIsoCodeForPrices &&
                  isCurrencyCodeAvailable &&
                  `${currencyCode} ${intl.formatNumber(offerPrice, {
                    minimumFractionDigits: 2,
                  })}`}
                {!showCurrencyIsoCodeForPrices &&
                  isCurrencyCodeAvailable &&
                  new Intl.NumberFormat(priceFormatLocale, {
                    style: "currency",
                    currency: currencyCode,
                    currencyDisplay: showCurrencyAsCode.includes(currencyCode)
                      ? "code"
                      : "symbol",
                  }).format(offerPrice)}
                {currencyCode === "" &&
                  intl.formatNumber(offerPrice, {
                    minimumFractionDigits: 2,
                  })}
                <DisclaimerStar aria-hidden="true">&nbsp;*</DisclaimerStar>
              </CardPriceHeadline>
            </CardPriceWrapper>
            <OfferPaymentIcons
              intl={intl}
              offer={offer}
              iconVariation={TYPES_OF_OFFERS.WCC}
              dataTestid={`wcc-offer-card-payment-methods-${offerIndex}`}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
