// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * ModalChangeBillingCountry
 *
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Text,
  Button,
  BUTTON_VARIANTS,
  AutoCompleteField,
  THEME,
  TEXT_WEIGHTS,
  TEXT_SIZES,
} from "@dhl-official/react-ui-library";
import { injectIntl } from "react-intl";
import { trackContentInteraction } from "root/libs/core-libs/src";
import { SEGMENT_TYPES } from "one-time-shipment/src/containers/SpotShipmentPage/constants";
import {
  Icon,
  Headline,
  ContentModal,
  countryAutocompleteFieldMessages,
  Flag,
} from "root/libs/ui-components/src";
import {
  countryCodes,
  UTF_EVENT_CONTENT_NAMES,
  UTF_EVENT_TYPES,
  UTF_EVENT_INTERACTIONS,
  UTF_EVENT_CONTENT_POSITION,
} from "root/libs/constants/src";
import checkIcon from "icons/cx/check.svg";
import { colors, breakpoints } from "root/libs/ui-styleguide/src";
import globeIcon from "iconsTwoColors/cx/globe.svg";
import styled from "styled-components";

const gap = "2rem";
const xsGap = "1rem";

const Content = styled.div`
  width: 100%;
  height: 100%;

  .margin-s {
    margin-bottom: 2.5rem;
  }
`;

const StyledIcon = styled(Icon)`
  margin: 0 auto;
  margin-bottom: ${gap};
`;

const StyleCheckIcon = styled(Icon)`
  display: inline-flex;
  padding: 0.55rem;
  border-radius: 100%;
  background: ${colors.greenApple};
  width: 20px;
  height: 20px;

  & > svg {
    vertical-align: baseline;
  }
`;

const CopyContainer = styled.div`
  text-align: center;
  margin: 50px 0 50px 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: 40px 0 50px 0;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin: 20px 0 30px 0;
  }
`;

const CopyText = styled(Text)`
  margin-bottom: ${(props) => (props.noGap === true ? "0px" : gap)};
  margin-top: ${(props) => (props.topGap ? gap : 0)};
  text-align: left;
`;

const AdditionalInformationList = styled.ul`
  list-style-type: none;

  & > li {
    display: flex;
    align-items: center;
    padding-bottom: ${gap};

    p {
      padding-left: ${gap};
    }
  }
`;

const AutocompleteFieldWrapper = styled.div`
  position: relative;
  width: 80%;
  margin: 0 auto;
  text-align: left;
  padding-bottom: ${gap};
`;

const StyledFlexContainer = styled.div`
  display: flex;
  text-align: left;
  padding-top: ${xsGap};
  justify-content: center;

  .create-import-cta,
  .create-import-cta-unstyled {
    padding: 0 !important;

    &:active,
    &:focus {
      box-shadow: none !important;
    }
  }

  .create-import-cta-unstyled {
    display: inline;
    text-decoration: underline;
    color: ${colors.black} !important;
  }
`;

const StyledText = styled(Text)`
  margin-right: ${THEME.getUnit("small")};
  margin-left: ${THEME.getUnit("small")};
  font-weight: ${TEXT_WEIGHTS[200]};
  font-size: ${THEME.getFontSize(TEXT_SIZES.SMALLPLUS)};
`;

const COPY_ID_PREFIX = "ModalChangeBillingCountry";

const ModalChangeBillingCountry = (props) => {
  const { intl, onClose, toggleOriginAndDestination } = props;

  const [country, setCountry] = useState({
    id: "",
    label: "",
    value: "",
  });
  const [listOfCountries, setListOfCountries] = useState([]);
  const [validation, setValidation] = useState(undefined);

  function getCopy(id) {
    return intl.formatMessage({ id });
  }

  useEffect(() => {
    const countryList = countryCodes.map((code) => {
      const countryName = intl.formatMessage(
        countryAutocompleteFieldMessages[code]
      );
      const synExists = !!intl.messages[
        countryAutocompleteFieldMessages[code].synonyms
      ];
      let syn = "";
      if (countryAutocompleteFieldMessages[code].synonyms && synExists) {
        syn = getCopy(countryAutocompleteFieldMessages[code].synonyms);
      }
      return {
        id: code,
        label: countryName,
        value: countryName,
        synonyms: syn,
      };
    }, []);
    setListOfCountries(countryList);
  }, []);

  function onChange(e) {
    const { value } = e.target;
    const countryLabel = value.toLowerCase();
    const matchedCountry = listOfCountries.find(
      (countryValue) => countryValue.label.toLowerCase() === countryLabel
    );

    setCountry({
      label: value,
      value,
    });
    if (matchedCountry) {
      setValidation({
        type: "valid",
      });
    } else {
      setValidation({
        type: "invalid",
      });
    }
  }

  function customFilter(items, val) {
    return items.filter(
      (item) =>
        item.value.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
        item.id.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
        item.synonyms.toLowerCase().indexOf(val.toLowerCase()) > -1
    );
  }

  function onOptionSelected(item) {
    setCountry(item);
    setValidation({
      type: "valid",
    });
  }

  function buildCountryURI() {
    const { changeCountryURI, changeCountryURIBusiness, isBusiness } = props;
    const countryURI = isBusiness ? changeCountryURIBusiness : changeCountryURI;
    return countryURI.replace("{country}", country.id.toLowerCase());
  }

  function changeCountryHandler() {
    const { isBusiness } = props;
    const { value } = country;
    trackContentInteraction({
      name: UTF_EVENT_CONTENT_NAMES.FORM_SUBMIT,
      type: UTF_EVENT_TYPES.BUTTON,
      interaction: UTF_EVENT_INTERACTIONS.CLICK,
      position: UTF_EVENT_CONTENT_POSITION.CHANGE_BILLING_COUNTRY_MODAL,
      context: isBusiness ? SEGMENT_TYPES.business : SEGMENT_TYPES.private,
      attributes: {
        country: value,
      },
    });
    window.location.href = buildCountryURI();
  }

  function toggleImportShipmentHandler() {
    toggleOriginAndDestination();
  }

  const messages = {
    title: getCopy(`${COPY_ID_PREFIX}.title`),
    headline: getCopy(`${COPY_ID_PREFIX}.headline`),
    introBullets: getCopy(`${COPY_ID_PREFIX}.introBullets`),
    bulletOne: intl.formatMessage(
      {
        id: `${COPY_ID_PREFIX}.bulletOne`,
      },
      {
        br: <br key="br" />,
      }
    ),
    bulletTwo: getCopy(`${COPY_ID_PREFIX}.bulletTwo`),
    message: getCopy(`${COPY_ID_PREFIX}.message`),
    countryLabel: getCopy("tradeLaneComponent.countryLabel"),
    countryEmptyOptionLabel: getCopy(
      "tradeLaneComponent.countryEmptyOptionLabel"
    ),
    ctaPrimary: getCopy(`${COPY_ID_PREFIX}.ctaPrimary`),
    ctaSecondary: getCopy(`${COPY_ID_PREFIX}.ctaSecondary`),
    tooltipContent: getCopy(`${COPY_ID_PREFIX}.tooltipContent`),
    tooltipHeading: getCopy(`${COPY_ID_PREFIX}.tooltipHeading`),
    accessibility: getCopy(`${COPY_ID_PREFIX}.accessibility`),
    ctaCreateImport: intl.formatMessage(
      {
        id: `${COPY_ID_PREFIX}.ctaCreateImport`,
      },
      {
        createImport: (
          <Button
            key="createImportCta"
            name="create-import-cta"
            dataTestid="create-import-cta"
            variant={BUTTON_VARIANTS.TEXT}
            type="button"
            className="create-import-cta-unstyled"
            onClick={toggleImportShipmentHandler}
          >
            {getCopy(`${COPY_ID_PREFIX}.ctaLink`)}
          </Button>
        ),
      }
    ),
  };

  function renderItem(item) {
    const itemStyles = {
      display: "flex",
      height: "46px",
      lineHeight: "46px",
      alignItems: "center",
      fontWeight: 200,
    };

    return (
      <div style={itemStyles}>
        <Flag country={item.id} />
        <StyledText>{item.label}</StyledText>
      </div>
    );
  }

  return (
    <ContentModal
      {...props}
      closeButtonAriaLabel={intl.formatMessage({ id: "SS.ContentModal.close" })}
      contentAriaLabel={messages.title}
      onClose={onClose}
      smallCenteredModal
      variant="bottom"
      isScrollable
    >
      <Content>
        <CopyContainer>
          <StyledIcon
            fillColor={colors.redScarlet}
            icon={globeIcon}
            useAs="decorative"
            width={{ mobile: "108px", desktop: "108px" }}
            height={{ mobile: "64px", desktop: "64px" }}
            extraStyles={[{ margin: "0 auto" }]}
          />
          <Headline
            priority={2}
            headlineDisplayType="h"
            weight="700"
            className="margin-s"
            dataTestid="change-country-title"
          >
            {messages.title}
          </Headline>
          <CopyText isParagraph dataTestid="change-country-headline">
            {messages.headline}
          </CopyText>
          <AutocompleteFieldWrapper>
            <AutoCompleteField
              options={listOfCountries}
              autoComplete="off"
              renderOption={renderItem}
              onChange={onChange}
              id="country-dropdown"
              variant={{
                type: "animated",
                label: messages.countryLabel,
                placeholder: messages.countryEmptyOptionLabel,
              }}
              validation={validation}
              onOptionSelected={onOptionSelected}
              value={country.value}
              isBlock
              isRequired
              required
              customFilter={(items, val) => customFilter(items, val)}
            />
          </AutocompleteFieldWrapper>
          <Button
            name="change-country-cta"
            dataTestid="change-country-cta"
            variant={BUTTON_VARIANTS.PRIMARY}
            type="button"
            onClick={changeCountryHandler}
            isDisabled={validation?.type !== "valid"}
          >
            {messages.ctaPrimary}
          </Button>
          <CopyText topGap isParagraph dataTestid="change-country-intro">
            {messages.introBullets}
          </CopyText>
          <AdditionalInformationList>
            <li>
              <div>
                <StyleCheckIcon
                  icon={checkIcon}
                  useAs="decorative"
                  width={{ desktop: "10px", mobile: "10px" }}
                  height={{ desktop: "10px", mobile: "10px" }}
                />
              </div>
              <CopyText
                noGap
                isParagraph
                dataTestid="change-country-bullet-one"
              >
                {messages.bulletOne}
              </CopyText>
            </li>
            <li>
              <div>
                <StyleCheckIcon
                  icon={checkIcon}
                  useAs="decorative"
                  width={{ desktop: "10px", mobile: "10px" }}
                  height={{ desktop: "10px", mobile: "10px" }}
                />
              </div>
              <CopyText
                noGap
                isParagraph
                dataTestid="change-country-bullet-two"
              >
                {messages.bulletTwo}
              </CopyText>
            </li>
          </AdditionalInformationList>
          <StyledFlexContainer>
            <CopyText noGap isParagraph>
              {messages.ctaCreateImport}
              <span className="visually-hidden">{messages.accessibility}</span>
            </CopyText>
          </StyledFlexContainer>
        </CopyContainer>
      </Content>
    </ContentModal>
  );
};

ModalChangeBillingCountry.propTypes = {
  abTestRunning: PropTypes.bool.isRequired,
  intl: PropTypes.any.isRequired,
  onClose: PropTypes.func,
  changeCountryURI: PropTypes.string,
  changeCountryURIBusiness: PropTypes.string,
  toggleOriginAndDestination: PropTypes.func.isRequired,
  isBusiness: PropTypes.bool.isRequired,
};

ModalChangeBillingCountry.defaultProps = {
  onClose: () => {},
  changeCountryURI: "/{country}-en/home/book-online.html",
  changeCountryURIBusiness: "/{country}-en/home/get-a-quote.html",
};

export default injectIntl(ModalChangeBillingCountry);
