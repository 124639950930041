// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * QuantitySelector
 *
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import floor from "lodash/floor";
import memoize from "lodash/memoize";
import { injectIntl } from "react-intl";
import { IntlPropType } from "root/libs/core-libs/src";
import QuantityField from "one-time-shipment/src/components/Forms/QuantityField";
import TotalSumIndicator from "one-time-shipment/src/components/Feedback/TotalSumIndicator";
import { SS } from "root/libs/constants/src";
import { selectTrackingKey } from "one-time-shipment/src/containers/Tracking/selectors";
import {
  QuantityContent,
  QuantityInputWrapper,
  StyledHeadline,
} from "./styled";

const COPY_ID_PREFIX = "QuantitySelector";

const QuantitySelector = ({
  intl,
  itemIndex,
  className,
  quantityName,
  feedback,
  quantityLabel,
  quantityValidationRules,
  quantityValue,
  indicatorLabel,
  unitSystem,
  selectedUnitSystem,
  weightValue,
  isWeightValid,
  ariaDescribedBy,
  shouldReplaceEmptyWithMinValue,
  onBlur,
  onQuantityChange,
}) => {
  let trackingKeyPaths = {
    quantityName: "",
  };

  useEffect(() => {
    setTimeout(() => {
      trackingKeyPaths = {
        quantityName: selectTrackingKey(
          `spotShipment.initialSet.items[${itemIndex}].quantity`
        ),
      };
    }, 0);
  }, []);

  const calculateTotalSum = memoize(
    () => floor(parseFloat(weightValue) * parseFloat(quantityValue), 2) || 0
  );

  const replaceEmptyWithMinValue = (input) => {
    /** If the user input is an empty string, exchange with minimum value instead */
    if (!quantityValue) {
      /*
        We have to update hte input value here manually as its validated before its changed
        and we need to do some default value replacements before we validate
      */
      input.value = quantityValidationRules.minValue; // eslint-disable-line no-param-reassign
      onQuantityChange(selectedUnitSystem, input);
    }
  };

  const onBlurLocal = (event) => {
    const input = event.target;

    if (shouldReplaceEmptyWithMinValue) {
      replaceEmptyWithMinValue(input);
    }

    onBlur(input);
  };

  const onQuantityChangeLocal = (event) => {
    const input = event.target;

    onQuantityChange(selectedUnitSystem, input);
  };

  const onMinusButtonClick = (event, reference) => {
    const parsedValue = parseInt(quantityValue, 10);
    const input = reference.current;

    if (
      Number.isInteger(parsedValue) &&
      parsedValue > quantityValidationRules.minValue
    ) {
      /*
        We have to update the input value here manually as its validated before its changed
        and we need to do some default value replacements before we validate
      */
      input.value = parsedValue - 1;
      onQuantityChange(selectedUnitSystem, input);
    }
  };

  const onPlusButtonClick = (event, reference) => {
    const parsedValue = parseInt(quantityValue, 10);
    const input = reference.current;

    const isValueValid =
      Number.isInteger(parsedValue) &&
      parsedValue < quantityValidationRules.maxValue;
    if (isValueValid || quantityValidationRules.maxValue === null) {
      /*
        We have to update hte input value here manually as its validated before its changed
        and we need to do some default value replacements before we validate
      */
      input.value = parsedValue + 1;
      onQuantityChange(selectedUnitSystem, input);
    }
  };

  const totalSum = calculateTotalSum();

  return (
    <QuantityContent className={className}>
      <StyledHeadline
        dataTestid={`item-${quantityName}`}
        priority={5}
        weight="700"
      >
        {quantityLabel}
      </StyledHeadline>

      <QuantityInputWrapper hasError={feedback.hasError}>
        <QuantityField
          feedback={feedback}
          interpolations={{
            minValue: quantityValidationRules.minValue,
            maxValue: quantityValidationRules.maxValue,
            fieldName: intl.formatMessage({
              id: `${COPY_ID_PREFIX}.fieldLabelErrorMessage`,
            }),
            unit: "",
          }}
          feedbackMessageIdPrefix="integers"
          name={quantityName}
          trackingKeyPaths={trackingKeyPaths.quantityName}
          onChange={onQuantityChangeLocal}
          onBlur={onBlurLocal}
          value={quantityValue || ""}
          required={quantityValidationRules.required}
          maxValue={quantityValidationRules.maxValue}
          minValue={quantityValidationRules.minValue}
          onMinusButtonClick={onMinusButtonClick}
          onPlusButtonClick={onPlusButtonClick}
          ariaLabel={quantityLabel}
          copyNamespace={SS}
          totalSumIndicatorLabel={indicatorLabel}
          totalSumIndicatorValue={totalSum}
          totalSumIndicatorUnitSystem={unitSystem[selectedUnitSystem].weight}
          ariaDescribedBy={ariaDescribedBy}
        />
      </QuantityInputWrapper>
      <TotalSumIndicator
        indicatorLabel={indicatorLabel}
        quantityValue={quantityValue === "" ? null : quantityValue}
        totalSum={totalSum}
        weightUnit={unitSystem[selectedUnitSystem].weight}
        key="sumIndicator"
        hasError={feedback.hasError}
        dataTestid="total-weight"
        isWeightValid={isWeightValid}
      />
    </QuantityContent>
  );
};

QuantitySelector.propTypes = {
  /** Internationalization library */
  intl: IntlPropType.isRequired,
  className: PropTypes.string,
  quantityName: PropTypes.string.isRequired,
  quantityLabel: PropTypes.string.isRequired,
  quantityValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** This function needs to receive the event target. This should be a valid DOM input so we can use CheckValidity in the reducer */
  onQuantityChange: PropTypes.func.isRequired,
  quantityValidationRules: PropTypes.object,
  onBlur: PropTypes.func,
  feedback: PropTypes.shape({
    name: PropTypes.string,
    hasError: PropTypes.bool,
    isValid: PropTypes.bool,
    feedbackMessageId: PropTypes.string,
  }),
  shouldReplaceEmptyWithMinValue: PropTypes.bool,
  unitSystem: PropTypes.object.isRequired,
  selectedUnitSystem: PropTypes.string.isRequired,
  indicatorLabel: PropTypes.string,
  weightValue: PropTypes.string,
  isWeightValid: PropTypes.bool,
  itemIndex: PropTypes.number,
  ariaDescribedBy: PropTypes.string,
};

QuantitySelector.defaultProps = {
  className: "",
  quantityValue: 1,
  feedback: {
    name: "",
    hasError: false,
    isValid: false,
    feedbackMessageId: "",
  },
  quantityValidationRules: {
    required: false,
    minValue: 1,
    maxValue: 99,
  },
  onBlur: () => { },
  shouldReplaceEmptyWithMinValue: false,
  indicatorLabel: "",
  weightValue: "0",
  isWeightValid: false,
  itemIndex: 0,
  ariaDescribedBy: "",
};

/* eslint-enable react/no-multi-comp */
export default injectIntl(QuantitySelector);
