// 9fbef606107a605d69c0edbcd8029e5d

/*
 *
 * Localization actions
 *
 */

import { createAction } from "redux-actions";
import {
  LOAD_COPY_TEXT_FAILURE,
  LOAD_COPY_TEXT_SUCCESS,
  LOAD_COPY_TEXT,
  SET_COPY_TEXT_FROM_STORAGE,
  SET_COUNTRY,
  SETUP_LOCALE_DATA_FAILURE,
  SETUP_LOCALE_DATA_SUCCESS,
  SETUP_LOCALE_DATA,
} from "./constants";

export const loadCopyText = createAction(LOAD_COPY_TEXT);
export const loadCopyTextFailure = createAction(LOAD_COPY_TEXT_FAILURE);
export const loadCopyTextSuccess = createAction(LOAD_COPY_TEXT_SUCCESS);
export const setCopyTextFromStorage = createAction(SET_COPY_TEXT_FROM_STORAGE);
export const setCountry = createAction(SET_COUNTRY);
export const setupLocaleData = createAction(SETUP_LOCALE_DATA);
export const setupLocaleDataFailure = createAction(SETUP_LOCALE_DATA_FAILURE);
export const setupLocaleDataSuccess = createAction(SETUP_LOCALE_DATA_SUCCESS);
