// 9fbef606107a605d69c0edbcd8029e5d

// HOTFIX - DPCJG-4943
// When 12hour = true but native language doesn't support it in the Date object
// We might get results like 00:00 PM.
// The following countries also have different results on EN and LL.
// This should be moved to the config files in individual cases especially for
// Arabic, Spanish and Asian Languages in the future
// CN = am / pm, HN = am / pm, MX = AM / PM and NG = am / pm
// Format table: https://date-fns.org/v2.28.0/docs/format
// a = AM/PM
// aaa = am/pm
// aaaa = a.m/p.m

export const dayPeriodOverwrites = {
    CN: "hh:mm aaa",
    HN: "hh:mm aaa",
    NG: "hh:mm aaa",
    MX: "hh:mm a"
}