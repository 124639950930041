// 9fbef606107a605d69c0edbcd8029e5d

import PropTypes from "prop-types";
import styled from "styled-components";
import { THEME } from "@dhl-official/react-ui-library";

import getFlagForCountry from "./flag-for-country";
import flagsImage from "./images/flags.webp";

// #region Component Styles
const Flag = styled.i.attrs((props) => ({
  "aria-hidden": true,
  style: getFlagForCountry(props.country),
}))`
  // --s: scale - default is .5 because we're using @2x files for retina displays
  // --x: pos x
  // --y: pos y
  // 1324px: sprite file dimensions
  --s: 0.5;
  background-image: url(${flagsImage});
  background-repeat: no-repeat;
  background-position: calc(var(--x) * var(--s)) calc(var(--y) * var(--s));
  border: solid 1px ${THEME.getGrayColor("400")};
  box-sizing: content-box;
  display: inline-block;
  height: calc(40px * var(--s));
  width: calc(60px * var(--s));
  background-size: calc(1324px * var(--s)) calc(1324px * var(--s));
`;
// #endregion

Flag.propTypes = {
  className: PropTypes.string,
  country: PropTypes.string.isRequired,
};

export default Flag;
