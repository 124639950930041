/**
 *
 * ModalUnitSwitchInfo
 *
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Text, Button, BUTTON_VARIANTS } from "@dhl-official/react-ui-library";
import { injectIntl } from "react-intl";
import styled from "styled-components";
import { ContentModal, Headline } from "root/libs/ui-components/src";

const Content = styled.div`
  button {
    margin-right: 10px;
  }
`;

const CopyText = styled(Text)`
  margin-bottom: 20px;
  margin-top: 10px;
`;

const COPY_ID_PREFIX = "ModalUnitSwitchInfo";

const ModalUnitSwitchInfo = (props) => {
  const { intl, onClose, unitSystem, newUnitValue, trackEvent } = props;

  useEffect(() => {
    trackEvent({ unitSelectorWarningModalTriggered: true });
  }, []);

  const onConfirmationClick = () => {
    trackEvent({ clickedOnProceedUnitSelectionChange: true });
    onClose(true);
  };

  const onCancellationClick = () => {
    trackEvent({ clickedOnCancelUnitSelectionChange: true });
    onClose(false);
  };

  const getCopy = (id, values, prefix = COPY_ID_PREFIX) => {
    return intl.formatMessage({ id: `${prefix}.${id}` }, values);
  };

  return (
    <ContentModal
      {...props}
      closeButtonAriaLabel={intl.formatMessage({ id: "SS.ContentModal.close" })}
      contentAriaLabel={getCopy("headline")}
      smallCenteredModal
    >
      <Content data-testid="modal-unit-switch-info">
        <Headline priority={2}>{getCopy("headline")}</Headline>
        <CopyText isParagraph>
          {getCopy("description1")}
          <strong>{`${unitSystem[newUnitValue].weight}/${unitSystem[newUnitValue].length}`}</strong>
          {getCopy("description2")}
        </CopyText>
        <Button type="button" onClick={onConfirmationClick}>
          {getCopy("primaryCTA")}
        </Button>
        <Button
          type="button"
          variant={BUTTON_VARIANTS.SECONDARY}
          onClick={onCancellationClick}
        >
          {getCopy("secondaryCTA")}
        </Button>
      </Content>
    </ContentModal>
  );
};

ModalUnitSwitchInfo.propTypes = {
  intl: PropTypes.any.isRequired,
  onClose: PropTypes.func,
  unitSystem: PropTypes.object.isRequired,
  newUnitValue: PropTypes.string.isRequired,
  trackEvent: PropTypes.func.isRequired,
};

ModalUnitSwitchInfo.defaultProps = {
  onClose: () => {},
};

export default injectIntl(ModalUnitSwitchInfo);
