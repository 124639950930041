// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * SpecialOfferInfo
 *
 */

import React from "react";
import PropTypes from "prop-types";
import goGreenIcon from "iconsTwoColors/cx/DPDHL_Group_GoGreen.svg";
import { TEXT_SIZES } from "@dhl-official/react-ui-library";
import { injectIntl } from "react-intl";
import { IntlPropType } from "root/libs/core-libs/src";
import { Icon } from "root/libs/ui-components/src";
import { offerFeatures } from "root/libs/constants/src";
import {
  StyledHeadline,
  StyledPromotedLine,
  StyledFirstLine,
  StyledHeadlineAddition,
  HeadlineWrapper,
  OfferFeaturesWrapper,
} from "./styled";

const COPY_ID_PREFIX = "SpecialOfferInfo";

const SpecialOfferInfo = (props) => {
  const { offer, intl, purchaseMethod, offerIndex, textSize } = props;

  const getTransportCode = (key) => {
    if (key.includes("OFR")) {
      return "OCEAN";
    }
    if (key.includes("ECS")) {
      return "ECS";
    }
    if (key.includes("AFR")) {
      return "AIR";
    }
    return "AIRANDOCEAN";
  };

  const transportCode = getTransportCode(offer.key);
  // Check if headline addition is available.
  // If yes it will be displayed
  const headlineAdditionCopyID = `${COPY_ID_PREFIX}.${offer.specialOffers}_headlineAddition`;
  const headlineAdditionExists = !!intl.messages[headlineAdditionCopyID];
  const headlineAdditionCopyText = headlineAdditionExists
    ? intl.formatMessage({
        id: headlineAdditionCopyID,
      })
    : "";
  // Check if specific headline is available for this special offer.
  // If yes it will be displayed, otherwise general BU headline is displayed.
  const headlineCopyID = `${COPY_ID_PREFIX}.${offer.specialOffers}_headline`;
  const headlineCopyText = offer.estimatedDeliveryTime
    ? intl.formatDate(offer.estimatedDeliveryTime, {
        month: "long",
        day: "numeric",
        weekday: "short",
      })
    : intl.formatMessage(
        {
          id: headlineCopyID,
        },
        {
          // Checks if `estimatedTransitTime` exists and replaces tokens
          minDays: offer?.estimatedTransitTime?.minDays || null,
          maxDays: offer?.estimatedTransitTime?.maxDays || null,
        }
      );
  // Check if first line text is available.
  // If not "CardAndSummary.estimatedDelivery" will be displayed instead.
  const firstLineCopyID = `${COPY_ID_PREFIX}.${offer.specialOffers}_firstLine`;
  const firstLineExists = !!intl.messages[firstLineCopyID];
  const firstLineCopyText = firstLineExists
    ? intl.formatMessage({
        id: firstLineCopyID,
      })
    : intl.formatMessage({
        id: "CardAndSummary.estimatedDelivery",
      });

  const prefixTestId = `special-offer`
  const headlineTestId = (purchaseMethod && offerIndex)
    ? `${prefixTestId}-headline-${offerIndex}`
    : `${prefixTestId}-headline`;
  const promotedLineTestId = (purchaseMethod && offerIndex)
    ? `${prefixTestId}-promoted-${offerIndex}` 
    : `${prefixTestId}-promoted`;
  
  const textSizeFirstLine = textSize
    ? textSize
    : TEXT_SIZES.TINY;
  return (
    <React.Fragment>
      {!!offer.price.amount && (
        <StyledFirstLine
          size={textSizeFirstLine}
          id={`${offer.key}-firstline`}
          isParagraph
        >
          {firstLineCopyText}
        </StyledFirstLine>
      )}
      <HeadlineWrapper>
        <StyledHeadline
          weight="800"
          priority={4}
          id={`${offer.key}-headline`}
          dataTestid={headlineTestId}
          data-testvalue={offer.specialOffers}
        >
          {headlineCopyID !== headlineCopyText && headlineCopyText}
          {headlineCopyID === headlineCopyText &&
            intl.formatMessage({
              id: `${COPY_ID_PREFIX}.${transportCode}_headline`,
            })}
        </StyledHeadline>
        {headlineAdditionExists &&
          headlineAdditionCopyID !== headlineAdditionCopyText && (
            <StyledHeadlineAddition weight={200} size={TEXT_SIZES.LARGE}>
              {headlineAdditionCopyText}
            </StyledHeadlineAddition>
          )}
        {!!(
          offer.features?.climateNeutralShippingMode === offerFeatures.GO_GREEN
        ) && (
          <OfferFeaturesWrapper>
            <Icon
              icon={goGreenIcon}
              data-testid="icon-gogreen"
              width={{
                mobile: "70px",
                desktop: "70px",
              }}
              height={{
                mobile: "28px",
                desktop: "28px",
              }}
              useAs="decorative"
            />
          </OfferFeaturesWrapper>
        )}
      </HeadlineWrapper>
      <StyledPromotedLine
        size={TEXT_SIZES.TINY}
        id={`${offer.key}-promoted`}
        isParagraph
        dataTestid={promotedLineTestId}
      >
        {intl.formatMessage(
          { id: `${COPY_ID_PREFIX}.${offer.specialOffers}_promoted` },
          {
            br: <br key="br" />,
          }
        )}
        <span className="visually-hidden">:</span>
      </StyledPromotedLine>
    </React.Fragment>
  );
};

SpecialOfferInfo.propTypes = {
  offer: PropTypes.shape({
    /** business unit of the offer */
    businessUnit: PropTypes.string,
    /** id of the offer */
    key: PropTypes.string,
    /** If set will trigger the display of a special scenario card in DGF */
    specialOffers: PropTypes.string,
    estimatedTransitTime: PropTypes.object,
    estimatedDeliveryTime: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    price: PropTypes.object,
    features: PropTypes.object,
  }).isRequired,
  /** Internationalization library */
  intl: IntlPropType.isRequired,
  purchaseMethod: PropTypes.string,
  offerIndex: PropTypes.string,
  textSize: PropTypes.string,
};

export default injectIntl(SpecialOfferInfo);
