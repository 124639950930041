// 9fbef606107a605d69c0edbcd8029e5d

import React from "react";
import PropTypes from "prop-types";

export default class TrackingComponent extends React.PureComponent {
  static propTypes = {
    trackingData: PropTypes.object,
    tradeLaneData: PropTypes.object.isRequired,
    shipmentLaneData: PropTypes.object.isRequired,
    freightP2PModalData: PropTypes.object.isRequired,
    options: PropTypes.array,
    offers: PropTypes.array,
    backends: PropTypes.array,
    metadata: PropTypes.object,
    spotShipmentPage: PropTypes.object,
    pushToDigitalLayer: PropTypes.func,
  };

  static defaultProps = {
    trackingData: {},
    options: [],
    offers: [],
    backends: [],
    metadata: {},
    spotShipmentPage: {},
    pushToDigitalLayer: () => {},
  };

  constructor(props) {
    super(props);
    this.dataTracking = {};
  }

  componentDidUpdate() {
    const { pushToDigitalLayer } = this.props;
    pushToDigitalLayer(this.dataTracking);
  }

  buildDigitalLayer = () => {
    const {
      trackingData,
      tradeLaneData,
      shipmentLaneData,
      freightP2PModalData,
      options,
      offers,
      backends,
      metadata,
      spotShipmentPage,
    } = this.props;

    const {
      tabIdSelected,
      idExploredOffer,
      idExploredAddOn,
      bookNowOfferClicked,
      quoteStarted,
      quoteEnded,
      clickedOnOverWeightLink,
      clickedOnDocumentsCheckboxIcon,
      clickedOnDangerousGoodsCheckboxIcon,
      clickedOnNonStackableCheckboxIcon,
      continueToShipmentClicked,
      getOffersClicked,
      documentsCheckboxChecked,
      shipmentDateChanged,
      sortingOrderChanged,
      downloadQuotesClicked,
      shareQuotesClicked,
      shareQuotesFallbackClicked,
      regularShipmentCardCTA,
      regularShipmentTeaserDisplayed,
      regularShipmentTeaserButtonClicked,
      regularShipmentTeaserClosed,
      wrongCountry,
      clickedOnImportExportSwitch,
      clickedOnSizesTabsMobile,
      systemErrorPageShown,
      clickedOnAddShipmentLaneItem,
      clickedOnRemoveShipmentLaneItem,
      clickedOnFreightP2PModalCloseButton,
      freightP2PModalSubmit,
      freightP2PModalQuotationId,
      performance,
      bookingNotAllowedModalTriggered,
      unitSelectorWarningModalTriggered,
      clickedOnProceedUnitSelectionChange,
      clickedOnCancelUnitSelectionChange,
      saveInformationModalTriggered,
      clickedOnSaveInformationCancel,
      clickedOnSaveInformationAccepted,
      shipmentInformationDetectedModalTriggered,
      clickedOnRestoreShipmentButton,
      clickedOnNewShipmentButton,
      selfSegmentSelection,
      clickedOnFrequentShipmentLink,
      shipmentLaneRendered,
      quoteLaneRendered,
    } = trackingData;

    const { selectedSegment } = spotShipmentPage;

    const {
      initialSet: { settingsDangerousGoods },
    } = shipmentLaneData;

    const spotShipmentValidity = {
      ...tradeLaneData.validity,
      ...shipmentLaneData.validity,
    };
    const digitalLayer = {
      digitalLayer: {
        gaq: {
          shippingType: tabIdSelected
            ? tabIdSelected.replace("-title", "")
            : "",
          spotShipment: {
            initialSet: {
              ...tradeLaneData.initialSet,
              settingsDangerousGoods,
              items: shipmentLaneData.initialSet.items,
            },
            selectedSegment,
            options,
            offers,
            backends,
            metadata,
            freightP2PModal: freightP2PModalData,
            interactions: {
              validationStates: {
                ...spotShipmentValidity,
              },
              clickedOnOverWeightLink,
              clickedOnDocumentsCheckboxIcon,
              clickedOnDangerousGoodsCheckboxIcon,
              clickedOnNonStackableCheckboxIcon,
              continueToShipmentClicked,
              regularShipmentCardCTA,
              offersTriggers: {
                getOffersClicked,
                documentsCheckboxChecked,
                shipmentDateChanged,
                sortingOrderChanged,
                downloadQuotesClicked,
                shareQuotesClicked,
                shareQuotesFallbackClicked,
              },
              offer: {
                idExploredOffer,
                idExploredAddOn,
                bookNowOfferClicked,
              },
              selfSegmentSelection,
              wrongCountry,
              clickedOnImportExportSwitch,
              clickedOnSizesTabsMobile,
              clickedOnAddShipmentLaneItem,
              clickedOnRemoveShipmentLaneItem,
              regularShipmentTeaserDisplayed,
              regularShipmentTeaserButtonClicked,
              regularShipmentTeaserClosed,
              clickedOnFreightP2PModalCloseButton,
              freightP2PModalSubmit,
              freightP2PModalQuotationId,
              bookingNotAllowedModalTriggered,
              unitSelectorWarningModalTriggered,
              clickedOnProceedUnitSelectionChange,
              clickedOnCancelUnitSelectionChange,
              saveInformationModalTriggered,
              clickedOnSaveInformationCancel,
              clickedOnSaveInformationAccepted,
              shipmentInformationDetectedModalTriggered,
              clickedOnRestoreShipmentButton,
              clickedOnNewShipmentButton,
              clickedOnFrequentShipmentLink,
              shipmentLaneRendered,
              quoteLaneRendered,
            },
            performance: {
              quoteStarted,
              quoteEnded, // when the user clicks on get offers
            },
          },
          performance,
          system: {
            errorPageShown: systemErrorPageShown,
          },
        },
      },
    };
    this.dataTracking = digitalLayer;
  };

  render() {
    this.buildDigitalLayer();
    return null;
  }
}
