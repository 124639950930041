// 9fbef606107a605d69c0edbcd8029e5d

const env = require("../env");

// Using this syntax because we import this file in our server/index file
// To decide whether to use the GAQ API or the local mock
module.exports = {
  urls: {
    hosts: {
      gaqTranslationsBaseUrl: env.gaqTranslationsBaseUrl,
      postalLocationBaseUrl: env.postalLocationBaseUrl,
      mainApiUrl: env.apiBaseUrl,
      quoteBaseUrl: env.quoteBaseUrl,
      root: "/",
    },
    endpoints: {
      countrySettings:
        env.countrySettingsEndpoint || "/api/configuration/v2/ots",
      freightP2PModal: env.freightP2PModalEndpoint || "/api/purchase/freight",
      locationAutocomplete:
        env.locationAutocompleteEndpoint || "/api/postalLocation",
      postalCodePlaceholder:
        env.postalCodePlaceholderEndpoint || "/api/postalLocation/countries",
      quote: env.quoteEndpoint || "/api/quote",
      noOffers: "/api/noOffers",
      errorBackends: "/api/errorBackends",
    },
  },
};
