// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * OffersLane
 *
 */

import React from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import {
  updateShippingDate,
  getOffersRequestSent,
} from "one-time-shipment/src/containers/GetQuote/actions";
import { updateDocument } from "one-time-shipment/src/containers/TradeLane/actions";
import { AbTesting, Tracking, Utf } from "root/libs/ui-containers/src";
import {
  selectTradeLaneInitialSet,
  selectDocumentsOptionValue,
} from "one-time-shipment/src/containers/TradeLane/selectors";
import { toggleRegularShipmentTeaser } from "one-time-shipment/src/containers/Personalization/actions";
import OffersLaneComponent from "one-time-shipment/src/components/Offers/OffersLaneComponent";
import { makeIsFormValidSelector } from "root/libs/core-libs/src";
import {
  toggleModalError,
  toggleModalExpress,
  toggleBookingNotAllowedModal,
  toggleFreightP2PModal,
  resetFreightP2PModalState,
  onFreightP2PModalInformationChange,
  validateFreightP2PModalFormFields,
  getFreightP2PModalRequestSent,
  toggleModalSuccess,
  selectedFreightOfferId,
} from "./actions";

const {
  actions: { trackEvent },
} = Tracking;

const {
  selectors: { selectAbTestRunning },
} = AbTesting;

const {
  selectors: { selectPageName },
} = Utf;

function OffersLane(props) {
  return <OffersLaneComponent {...props} />;
}

const mapStateToProps = (state) => ({
  offers: state.getQuote.offers ? state.getQuote.offers : [],
  sortOffersBy: state.getQuote.sortOffersBy,
  staticOffers: state.getQuote.staticOffers ? state.getQuote.staticOffers : [],
  isOffersLaneVisible: state.offersLane.isOffersLaneVisible,
  haveTheBackendsErrors: state.getQuote.haveTheBackendsErrors,
  isModalErrorVisible: state.offersLane.isModalErrorVisible,
  shippingDate: state.getQuote.shippingDate,
  isShippingDateVisible: state.getQuote.isShippingDateVisible,
  isWaitingForResponse: state.getQuote.waitingForResponse,
  hasNetworkError: !isEmpty(state.getQuote.networkError),
  isModalExpressVisible: state.offersLane.isModalExpressVisible,
  isModalBookingNotAllowedVisible:
    state.offersLane.isModalBookingNotAllowedVisible,
  enableTeaserOverlayFeature: state.personalization.isRegularUser,
  shouldScroll: state.tradeLane.shouldScrollToOffersSection,
  tradeLaneInitialSet: selectTradeLaneInitialSet(state),
  originDestinationFlipped: state.tradeLane.isOriginDestinationFlipped,
  shipmentLaneInitialSet: state.shipmentLane.initialSet,
  documentsOptionValue: selectDocumentsOptionValue(state),
  spotShipmentPage: state.spotShipmentPage,
  isModalFreightP2PVisible: state.offersLane.isFreightP2PModalOpen,
  isFormValid: makeIsFormValidSelector(state, "offersLane"),
  isWaitingForFreightModalResponse:
    state.offersLane.isWaitingForFreightModalResponse,
  isModalSuccessVisible: state.offersLane.isModalSuccessVisible,
  selectedUnitSystem: state.shipmentLane.selectedUnitSystem,
  isSortOffersByVisible: state.getQuote.isSortOffersByVisible,
  wasDataLoadedfromUrl: state.tradeLane.wasDataLoadedfromUrl,
  canaryVersion: state.canaryVersion.version,
  canaryEnabled: state.canaryVersion.enabled,
  abTestRunning: selectAbTestRunning(state),
  pageName: selectPageName(state),
  experience: state.personalization.experience,
});

const mapDispatchToProps = (dispatch) => ({
  updateShippingDate: (params) => dispatch(updateShippingDate(params)),
  updateDocument: (document) => dispatch(updateDocument(document)),
  trackEvent: (trackingData) => dispatch(trackEvent(trackingData)),
  toggleModalError: () => dispatch(toggleModalError()),
  retrySubmitAction: () => {
    dispatch(toggleModalError());
    dispatch(getOffersRequestSent());
  },
  toggleModalExpress: () => dispatch(toggleModalExpress()),
  toggleBookingNotAllowedModal: (offer) =>
    dispatch(toggleBookingNotAllowedModal(offer)),
  toggleModalSuccess: () => dispatch(toggleModalSuccess()),
  toggleFreightP2PModal: (quotationId) => {
    dispatch(resetFreightP2PModalState());
    dispatch(selectedFreightOfferId(quotationId));
    dispatch(toggleFreightP2PModal());
  },
  validateFreightP2PModalFormFields: (element) =>
    dispatch(validateFreightP2PModalFormFields(element)),
  onFreightP2PModalInformationChange: (data) =>
    dispatch(onFreightP2PModalInformationChange(data)),
  getFreightP2PModalRequestSent: (data) =>
    dispatch(getFreightP2PModalRequestSent(data)),
  toggleRegularShipmentTeaser: ({ isRegularUser }) =>
    dispatch(toggleRegularShipmentTeaser({ isRegularUser })),
});

export default connect(mapStateToProps, mapDispatchToProps)(OffersLane);
