// 9fbef606107a605d69c0edbcd8029e5d

import {
  put,
  fork,
  takeLatest,
  all,
  select,
  call,
  delay,
} from "redux-saga/effects";
import { logging } from "root/libs/core-libs/src";
import qs from "qs";
import http from "one-time-shipment/src/lib/http";
import {
  AbTesting,
  Localization,
  CanaryVersions,
  MicroFrontend,
} from "root/libs/ui-containers/src";
import { P2PLinkTypes } from "root/libs/constants/src";
import merge from "lodash/merge";
import get from "lodash/get";
import isNil from "lodash/isNil";
import { toggleModalError } from "one-time-shipment/src/containers/OffersLane/actions";
import {
  getOffersRequestSucceeded as getOffersRequestSucceededAction,
  getOffersRequestFailed as getOffersRequestFailedAction,
  getOffersRequestSent as getOffersRequestSentAction,
} from "./actions";

import {
  selectTradeLaneInitialSet,
  selectDocumentsOptionValue,
} from "../TradeLane/selectors";
import { makeShipmentLaneInitialSetSelection } from "../ShipmentLane/selectors";

import { GET_OFFERS_REQUEST_SENT, UPDATE_SHIPPING_DATE } from "./constants";

const {
  selectors: { selectAbTestVersion, selectAbTestRunning },
} = AbTesting;

const {
  selectors: { selectCanaryVersion },
} = CanaryVersions;

const {
  selectors: { selectMicroFrontendId },
} = MicroFrontend;

const {
  selectors: { makeSelectLocale, selectCountry },
} = Localization;

export function* getOffersRequestSent() {
  try {
    const spotShipmentPage = yield select((state) => state.spotShipmentPage);
    const tradeLaneInitialSet = yield select(selectTradeLaneInitialSet);

    const shipmentLaneInitialSet = yield select(
      makeShipmentLaneInitialSetSelection(spotShipmentPage.selectedSegment)()
    );

    const currentSegment = spotShipmentPage.selectedSegment;

    const language = yield select(makeSelectLocale());
    const locale = { language: language.substring(0, 2) };
    const pageCountry = yield select(selectCountry);
    const marketCountry = { marketCountry: pageCountry };
    const abTestRunning = yield select(selectAbTestRunning);
    const abTestVersion = abTestRunning && {
      abTestVersion: yield select(selectAbTestVersion),
    };
    const canaryVersionSelector = yield select(selectCanaryVersion);
    const microFrontendIdSelector = yield select(selectMicroFrontendId);

    const initialSetMerged = merge(
      {},
      tradeLaneInitialSet,
      shipmentLaneInitialSet,
      locale,
      marketCountry,
      abTestVersion,
      spotShipmentPage
    );

    const { isShippingDateVisible, shippingDate } = yield select(
      (state) => state.getQuote
    );

    const params = window.location.search;
    const isDemoMode = qs.parse(params.slice(1)).demo;

    const options = {};

    const documentsOption = yield select(selectDocumentsOptionValue);

    if (!isNil(documentsOption)) {
      options["option.DOCUMENTS"] = documentsOption[currentSegment];
    }

    // When there's no Canary version selected, we shouldn't append the header
    let canaryParam = {};
    if (!isNil(canaryVersionSelector) && canaryVersionSelector !== "") {
      canaryParam = { canaryVersion: canaryVersionSelector };
    }

    let microFrontendParam = {};
    if (!isNil(microFrontendIdSelector) && microFrontendIdSelector !== "") {
      microFrontendParam = { microFrontendId: microFrontendIdSelector };
    }

    if (isDemoMode === "true") {
      options.demo = true;
    }

    if (isShippingDateVisible) {
      options["option.SHIPPING_DATE"] = shippingDate;
    }

    yield delay(100);

    const response = yield call(http.get, "quote", {
      params: { ...initialSetMerged, ...options },
      adapter: "quote",
      ...canaryParam,
      ...microFrontendParam,
    });

    const offersArray = get(response, "data.offers", []);

    offersArray.forEach((obj, index) => {
      if (obj.pathToPurchaseLinkType === P2PLinkTypes.MODAL_FREIGHT) {
        response.data.offers[index].freightModal = {
          quotationId: obj.quotationId,
          amount: get(obj, "price.amount", 0),
          tax: get(obj, "price.tax", ""),
          currencyCode: get(obj, "price.currencyCode", ""),
          deliveryOption: obj.deliveryOption,
          deliveryType: obj.deliveryType,
          originCountry: tradeLaneInitialSet.originCountry,
          pickUpDate: obj.pickUpDate,
          destinationCountry: tradeLaneInitialSet.destinationCountry,
          deliveryDate: obj.estimatedDeliveryTime,
        };
      }
    });

    yield put(getOffersRequestSucceededAction(response.data));

    const { metadata, backends } = response.data;

    yield call(logging.logMetadata, metadata);
    yield call(logging.logBackends, backends);
  } catch (error) {
    logging.warn(error);
    yield put(toggleModalError());
    yield put(
      getOffersRequestFailedAction({
        error,
      })
    );
  }
}

/**
 * Watch for documents info update and trigger get offers requests every once in 300ms
 */
export function* updateOffers() {
  yield delay(300);
  const { isOffersLaneVisible } = yield select((state) => state.offersLane);

  /*
    Check if offers lane is visible and triger Offers request if true.
    If not, do not trigger offers request, as the shipment lane may not be valid yet
   */
  if (isOffersLaneVisible) {
    yield put(getOffersRequestSentAction());
  }
}

export function* watchFetch() {
  yield takeLatest(GET_OFFERS_REQUEST_SENT, getOffersRequestSent);
}

export function* watchShippingDate() {
  yield takeLatest(UPDATE_SHIPPING_DATE, updateOffers);
}

export default function* sagas() {
  yield all([fork(watchFetch), fork(watchShippingDate)]);
}
