// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * CanaryVersions
 */

import { connect } from "react-redux";
import Canary from "./Canary";

const mapStateToProps = (state) => ({
  enabled: state.canaryVersion.enabled,
  version: state.canaryVersion.version,
});

export default connect(mapStateToProps)(Canary);
