// 9fbef606107a605d69c0edbcd8029e5d

import { all, put, call, takeLatest, fork, select } from "redux-saga/effects";
import { logging, localStorageBridge } from "root/libs/core-libs/src";
import { setUnitSystem } from "one-time-shipment/src/containers/ShipmentLane/actions";
import {
  setDocumentsValue,
  setDomesticShippingAvailability,
} from "one-time-shipment/src/containers/TradeLane/actions";
import { makeSelectedUnitSystemSelection } from "one-time-shipment/src/containers/ShipmentLane/selectors";
import { LOAD_COUNTRY_SETTINGS } from "./constants";
import * as actions from "./actions";
import { changeSegment as changeSegmentAction } from "one-time-shipment/src/containers/SpotShipmentPage/actions";

export function* loadCountrySettings(options, action) {
  const { getCountrySettings, cacheNameSpace } = options;
  const { payload } = action;
  const { country, experience } = payload;

  try {
    const localStorageKey = !experience
      ? `${cacheNameSpace}:${country}`
      : `${cacheNameSpace}:${country}:${experience}`;
    const localCountrySettings = yield call(
      localStorageBridge.load,
      localStorage,
      localStorageKey
    );

    if (localCountrySettings) {
      yield put(
        actions.setCountrySettingsFromStorage({
          country,
          settings: JSON.parse(localCountrySettings),
        })
      );
    }

    const settings = yield call(
      getCountrySettings,
      country.toUpperCase(),
      experience
    );

    yield put(
      actions.loadCountrySettingsSuccess({ country, experience, settings })
    );

    const selectedUnitSystem = yield select(makeSelectedUnitSystemSelection());

    if (!selectedUnitSystem) {
      yield put(setUnitSystem(settings.selectedUnitSystem));
    }

    yield put(setDocumentsValue(settings.isDocumentsOnlyChecked));

    if (settings.defaultSelectedSegment !== "private") {
      yield put(
        changeSegmentAction({
          segment: {
            id: settings.defaultSelectedSegment,
          },
        })
      );
    }

    yield put(
      setDomesticShippingAvailability(settings?.isDomesticShippingAvailable)
    );

    yield call(
      localStorageBridge.save,
      localStorage,
      localStorageKey,
      settings
    );
  } catch (error) {
    yield call(logging.warn, error);
    yield put(
      actions.loadCountrySettingsFailure({
        country,
        experience,
        message: error.message,
      })
    );
  }
}

export function* watchLoadCountrySettings(options) {
  yield takeLatest(
    LOAD_COUNTRY_SETTINGS,
    loadCountrySettings.bind(null, options)
  );
}

function* sagas(options) {
  yield all([fork(watchLoadCountrySettings.bind(null, options))]);
}

export default function createSagas(getCountrySettings, cacheNameSpace) {
  return sagas.bind(null, { getCountrySettings, cacheNameSpace });
}
