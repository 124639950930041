// 9fbef606107a605d69c0edbcd8029e5d

/**
 *
 * Styles for TabPanel
 *
 */

import styled from "styled-components";

export const PanelWrapper = styled.section`
  overflow: hidden;
  display: none;
  transform-origin: top;
  opacity: 0;
  height: 0;
  position: relative;
  transition: opacity cubic-bezier(0.4, 0.18, 0.41, 0.87) 200ms;

  &.is-selected {
    opacity: 1;
    overflow: visible;
    display: block;
    height: 100%;
    padding: 0 0 ${(props) => (props.addBottomPadding ? "40px" : 0)} 0;
  }

  ${(props) => props.extraStyles};
`;
